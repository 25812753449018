import { useQuery } from '@tanstack/react-query';

import {
  GET_SERVICE_SCHEDULE,
  GET_SERVICE_SCHEDULE_BY_PROVIDER_SERVICE_ID,
  getServiceSchedule,
  getServiceScheduleById,
  getServiceSchedulesByProviderServiceId,
  SHOW_CUSTOMIZED_SCHEDULE,
} from '@mwell-healthhub/commons';

export const useGetServiceSchedule = (providerId: string, branchId?: string, search?: string) => {
  return useQuery(
    [GET_SERVICE_SCHEDULE, providerId, branchId, search],
    () => getServiceSchedule(providerId, branchId, search),
    {
      enabled: !!providerId && !!branchId && SHOW_CUSTOMIZED_SCHEDULE,
    },
  );
};

export const useGetServiceScheduleById = (id: string) => {
  return useQuery(['getServiceScheduleById', id], () => getServiceScheduleById(id), {
    enabled: !!id,
  });
};

export const useGetServiceSchedulesByProviderServiceId = (id: string) => {
  return useQuery(
    [GET_SERVICE_SCHEDULE_BY_PROVIDER_SERVICE_ID, id],
    () => getServiceSchedulesByProviderServiceId(id),
    { enabled: !!id && SHOW_CUSTOMIZED_SCHEDULE },
  );
};
