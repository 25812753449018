import { EntityType } from '@healthhub/api-lib';
import { useQuery } from '@tanstack/react-query';

import { CategoryParams, getAllEshopCategories, getEshopCategoryById } from '../../api';
import { FetchCategoriesParams, fetchCategories, getCategoryById } from '../../api/categoryService';
import {
  GET_ALL_CATEGORIES,
  GET_ALL_ESHOP_CATEGORIES,
  GET_CATEGORY,
  GET_ESHOP_CATEGORY_BY_ID,
} from '../../constants';
import { Category } from '../../types';

export const useGetAllCategories = (params?: FetchCategoriesParams) => {
  return useQuery([GET_ALL_CATEGORIES, params], async () => fetchCategories(params));
};

export const useGetCategoryQuery = (id: string, enabled?: boolean) => {
  return useQuery<EntityType>([GET_CATEGORY, id], () => getCategoryById(id), { enabled });
};

export const useGetAllEshopCategories = (params?: CategoryParams) => {
  return useQuery([GET_ALL_ESHOP_CATEGORIES, params], async () => getAllEshopCategories(params));
};

export const useGetEshopCategoryByIdQuery = (id: string, enabled?: boolean) => {
  return useQuery<Category>([GET_ESHOP_CATEGORY_BY_ID, id], () => getEshopCategoryById(id));
};
