import { ReactNode, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { ClassNameValue, twMerge } from 'tailwind-merge';

import { HIDE_NAVIGATION, getLocalStorageItem } from '@mwell-healthhub/commons';

import { useIsMobile, useResetScroll } from '../../hooks';
import DesktopHeader from '../molecules/DesktopHeader';
import Footer from '../organisms/Home/Footer';
import Navigation from '../organisms/Navigation';

type Props = {
  shouldAltHeader?: boolean;
  shouldHideFooter?: boolean;
  shouldWhiteBackground?: boolean;
  shouldHideNavigation?: boolean;
  footerContainerClassName?: string;
  children?: ReactNode;
};

const HomeLayout = (props: Props) => {
  const {
    shouldAltHeader = false,
    shouldHideFooter = false,
    shouldWhiteBackground = false,
    shouldHideNavigation = false,
    footerContainerClassName,
    children,
  } = props;
  const isMobile = useIsMobile();
  const hideNavigation = shouldHideNavigation || getLocalStorageItem(HIDE_NAVIGATION);

  useResetScroll();

  const mobileView = (
    <div className="relative mx-auto flex min-h-screen flex-col bg-white">
      {!hideNavigation && <Navigation />}
      <main
        className={twMerge(
          'flex min-h-[calc(100vh-120px)] flex-auto flex-col bg-neutral-100 pt-10',
          (hideNavigation && 'pt-0') as ClassNameValue,
        )}
      >
        {children}
      </main>
    </div>
  );

  const desktopView = (
    <div className="flex min-h-screen flex-col bg-white">
      <DesktopHeader />
      <main className={twMerge('h-full grow', !shouldWhiteBackground && 'bg-neutral-100')}>
        {children}
      </main>
      {!shouldHideFooter && <Footer containerClassName={footerContainerClassName} />}
    </div>
  );

  return isMobile ? mobileView : desktopView;
};

export default dynamic(() => Promise.resolve(HomeLayout), { ssr: false });
