export const GET_ALL_DOCTORS = 'GET_ALL_DOCTORS';
export const USER = ['user'];
export const GET_ALL_LABORATORIES_AND_BRANCHES = 'GET_ALL_LABORATORIES_AND_BRANCHES';
export const GET_DOCTOR_BY_ID = 'GET_DOCTOR_BY_ID';
export const GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID';
export const GET_APPOINTMENT_BY_ID = 'GET_APPOINTMENT_BY_ID';
export const GET_ALL_HMOS = 'GET_ALL_HMOS';
export const GET_HMO_BY_ID = 'GET_HMO_BY_ID';
export const GET_HEALTH_DATA_BY_PATIENT_ID = 'GET_HEALTH_DATA_BY_PATIENT_ID';
export const GET_APPOINTMENT_SCHEDULE = 'GET_APPOINTMENT_SCHEDULE';
export const PATIENT_VALID_IDS = 'PATIENT_VALID_IDS';
export const PLACES_SEARCH = 'PLACES_SEARCH';
export const REVERSE_GEOCODE_SEARCH = 'REVERSE_GEOCODE_SEARCH';
export const GET_APPOINTMENT_REFERRAL_FORM_SIGNED_URL = 'GET_APPOINTMENT_REFERRAL_FORM_SIGNED_URL';
export const GET_ALL_ENROLLED_HMOS = 'GET_ALL_ENROLLED_HMOS';
export const GET_MWELL_USER_HMOS = 'GET_MWELL_USER_HMOS';
