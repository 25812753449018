import { OrderStatistics } from '../types';

type Props = {
  statistics?: OrderStatistics;
};

const OrderStatistics = (props: Props) => {
  const { statistics } = props;
  const {
    totalOrdersCount,
    cancelledOrdersCount,
    completedOrdersCount,
    confirmedOrdersCount,
    pendingOrdersCount,
  } = statistics ?? {};

  return (
    <dl className="mx-auto grid grid-cols-1 gap-px rounded-lg border border-chineseWhite bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-5">
      <div className="flex flex-col items-baseline justify-between gap-y-2 rounded-t-lg bg-white px-5 py-4 sm:rounded-tl-lg sm:rounded-tr-none md:rounded-tl-lg lg:first:rounded-l-lg">
        <div className="flex">
          <div className="mr-3 h-[15px] w-[15px] rounded-md bg-blue-500" />
          <dt className="leading-1 flex flex-1 text-xs font-medium text-gray-500">
            Total No. of Orders
          </dt>
        </div>
        <dd className="flex w-full flex-1 flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
          {totalOrdersCount}
        </dd>
      </div>
      <div className="flex flex-col items-baseline justify-between gap-y-2 bg-white px-5 py-4 sm:rounded-tr-lg md:rounded-none">
        <div className="flex">
          <div className="mr-3 h-[15px] w-[15px] rounded-md bg-green-700" />
          <dt className="leading-1 flex-1 text-xs font-medium text-gray-500">
            No. of Confirmed Orders
          </dt>
        </div>
        <dd className="flex w-full flex-1  flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
          {confirmedOrdersCount}
        </dd>
      </div>
      <div className="flex flex-col items-baseline justify-between gap-y-2 bg-white px-5 py-4 sm:rounded-bl-lg md:rounded-none">
        <div className="flex">
          <div className="mr-3 h-[15px] w-[15px] rounded-md bg-yellow-600" />
          <dt className="leading-1 flex-1 text-xs font-medium text-gray-500">
            No. of Completed Orders
          </dt>
        </div>
        <dd className="flex w-full  flex-1 flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
          {completedOrdersCount}
        </dd>
      </div>
      <div className="flex flex-col items-baseline justify-between gap-y-2 bg-white px-5 py-4 sm:rounded-bl-lg md:rounded-none">
        <div className="flex">
          <div className="mr-3 h-[15px] w-[15px] rounded-md bg-gray-600" />
          <dt className="leading-1 flex-1 text-xs font-medium text-gray-500">
            No. of Pending Orders
          </dt>
        </div>
        <dd className="flex w-full  flex-1 flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
          {pendingOrdersCount}
        </dd>
      </div>
      <div className="flex flex-col items-baseline justify-between gap-y-2 rounded-b-lg bg-white px-5 py-4 sm:rounded-bl-none sm:rounded-br-lg md:rounded-br-lg lg:last:rounded-r-lg">
        <div className="flex">
          <div className="mr-3 h-[15px] w-[15px] rounded-md bg-red-600" />
          <dt className="leading-1 flex-1 text-xs font-medium text-gray-500">
            No. of Cancelled Orders
          </dt>
        </div>
        <dd className="flex w-full flex-1 flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
          {cancelledOrdersCount}
        </dd>
      </div>
    </dl>
  );
};

export default OrderStatistics;
