enum BMIClassification {
  Underweight = 'Underweight',
  NormalWeight = 'Normal Weight',
  Overweight = 'Overweight',
  ObesityClassI = 'Obesity Class I',
  ObesityClassII = 'Obesity Class II',
  ObesityClassIII = 'Obesity Class III',
}

interface BMIResult {
  bmi: number;
  classification: BMIClassification;
}

export const calculateBMI = (weight = 0, height = 0): BMIResult => {
  const heightInMeters = height / 100; // Convert height to meters
  const bmi = weight / (heightInMeters * heightInMeters);
  const roundedBMI = Number(bmi.toFixed(4));
  let classification: BMIClassification;

  if (roundedBMI < 18.5) {
    classification = BMIClassification.Underweight;
  } else if (roundedBMI < 25) {
    classification = BMIClassification.NormalWeight;
  } else if (roundedBMI < 30) {
    classification = BMIClassification.Overweight;
  } else if (roundedBMI < 35) {
    classification = BMIClassification.ObesityClassI;
  } else if (roundedBMI < 40) {
    classification = BMIClassification.ObesityClassII;
  } else {
    classification = BMIClassification.ObesityClassIII;
  }

  return { bmi: roundedBMI, classification };
};
