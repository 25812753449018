import { useEffect, useState } from 'react';

import {
  Appointment,
  ProviderClientNotes,
  ProviderClientProfile,
  User,
  UserProvider,
} from '@healthhub/api-lib';
import clsx from 'clsx';
import { Plus } from 'iconoir-react';
import isEmpty from 'lodash.isempty';
import Link from 'next/link';
import { DateObject } from 'react-multi-date-picker';
import { twMerge } from 'tailwind-merge';

import {
  Button,
  clsxMerge,
  convertDateByFormat,
  CustomDatePicker,
  getCurrentUser,
  LoadingSpinner,
  FieldTextHtmlFormatter,
  Routes,
  SearchInput,
  TableEditButton as EditButton,
  useFindProviderClientNotes,
  useGetCurrentUser,
  useRouter,
} from '@mwell-healthhub/commons';
import { GetServerSideProps } from 'next';

type Props = {
  handleShowProviderClientNoteForm?: (
    isEditingNote: boolean,
    providerClientNote?: ProviderClientNotes,
  ) => void;
  brandId?: string;
  appointment?: Appointment;
  client?: ProviderClientProfile;
  isAppointmentNotes?: boolean;
  isReferralOverview?: boolean;
  isEditable?: boolean;
  isControlsHidden?: boolean;
  isDisabled?: boolean;
};

const ProviderClientNotesList = ({
  brandId,
  client,
  handleShowProviderClientNoteForm = () => null,
  appointment,
  isAppointmentNotes = false,
  isReferralOverview = false,
  isEditable = true,
  isControlsHidden = false,
  isDisabled,
}: Props) => {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateObject[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const {
    data: providerClientNotes,
    refetch,
    isLoading: isFetching,
  } = useFindProviderClientNotes({
    startDate,
    endDate,
    query: searchQuery,
    providerClientProfileId: client?.id,
    appointmentId: appointment?.id,
    limit: 200, // To Be Updated
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleOnChangeSearch = (value?: string) => {
    if (value) {
      setSearchQuery(value);
    } else {
      setSearchQuery('');
    }
  };

  const handleClearDateRange = () => {
    setDateRange([]);
    setStartDate(undefined);
    setEndDate(undefined);
    handleSubmitDate([]);
  };

  const handleChangeDate = (date: DateObject[]) => {
    setDateRange(date);
    handleSubmitDate(date);

    if (date === null) {
      handleClearDateRange();
    }
  };

  const handleSubmitDate = (date: DateObject[]) => {
    if (!!date?.[0]?.format().length && !!date?.[1]?.format().length) {
      setDateRange(date);
      setStartDate(date[0].format());
      setEndDate(date[1].format());
    }
  };

  const handleAddNewNote = () => {
    handleShowProviderClientNoteForm(false);
  };

  const handleViewNotesHistory = () => {
    router.push(`${Routes.CLIENTS}/${client?.id}#notes`);
  };

  const handleEditNote = (providerClientNote: ProviderClientNotes) => {
    return handleShowProviderClientNoteForm(true, providerClientNote);
  };

  const circleSvg = (
    <div className="z-10 bg-neutral-50">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="12" r="5" fill="#FAFAFA" stroke="#EBECEE" strokeWidth="2" />
      </svg>
    </div>
  );

  const isCurrentProviderNote = (noteProviderId: string) => noteProviderId == brandId;

  const renderProviderClientNotes = providerClientNotes?.items?.map((providerClientNote, index) => {
    return (
      <div key={`provider-client-notes-${index}`} className="flex gap-x-4 py-4">
        <div className="flex min-w-[104px] max-w-[104px] basis-[104px] flex-col">
          <span className="text-md font-normal text-neutral-500">
            {convertDateByFormat(providerClientNote.createdAt, 'MMM dd, yyyy')}
          </span>
          {isCurrentProviderNote(providerClientNote.provider.id.toString()) ? (
            <span className="text-xs font-normal text-neutral-400">
              Added by {providerClientNote.userProvider?.firstName}{' '}
              {providerClientNote.userProvider?.lastName}
            </span>
          ) : (
            <span className="text-xs font-normal text-neutral-400">
              Added by {providerClientNote.provider.displayName}
            </span>
          )}
        </div>
        <div className="min-w-6 max-w-6 relative -mb-4 flex w-6 basis-6 flex-col items-center">
          {circleSvg}
          <div
            className={clsxMerge('absolute -top-4 bottom-0 left-1/2 -z-0 w-px bg-neutral-300', {
              'top-4': index === 0,
              'h-4': index + 1 === providerClientNotes.itemCount,
              hidden: providerClientNotes.itemCount === 1,
            })}
          ></div>
        </div>
        <div className="shrink grow">
          <div className="mb-2 flex flex-row justify-between">
            <span
              className={clsxMerge('text-md cursor-pointer font-semibold text-primary-500', {
                'pointer-events-none text-neutral-600':
                  !isCurrentProviderNote(providerClientNote.provider.id.toString()) ||
                  isReferralOverview,
              })}
              onClick={() => handleEditNote(providerClientNote)}
            >
              {providerClientNote.title}
            </span>
            {brandId === providerClientNote.provider.id.toString() && !isReferralOverview && (
              <EditButton
                showIcon={!isAppointmentNotes}
                label="Edit note"
                onClick={() => handleEditNote(providerClientNote)}
              />
            )}
          </div>
          <div className="text-sm font-normal text-neutral-600">
            <FieldTextHtmlFormatter
              key={`provider-client-notes-remarks-${index}`}
              remarks={providerClientNote.remarks}
              isTruncated
            />
          </div>
          {providerClientNote.appointment && !isAppointmentNotes && (
            <div className="mt-3 space-x-2">
              <span className="text-sm font-normal text-neutral-500">
                {`Appointment: ${convertDateByFormat(
                  providerClientNote.appointment.dateTime,
                  'MMM dd, yyyy',
                )} (${providerClientNote.appointment.bookingNumber})`}
              </span>
              {isCurrentProviderNote(providerClientNote.provider.id.toString()) && (
                <Link
                  className="text-sm font-medium text-primary-500"
                  href={Routes.APPOINTMENTS + '/' + providerClientNote.appointment.id}
                >
                  View Appointment
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    );
  });

  const renderEmptyState = (
    <div className="flex items-center justify-center">
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        isEmpty(providerClientNotes?.items) && (
          <span className="text-center text-sm font-medium text-graniteGray">
            No notes for this client.{' '}
            {!isReferralOverview && 'To add, click on the "Add New Notes" button.'}
          </span>
        )
      )}
    </div>
  );

  return (
    <>
      {!isReferralOverview && isControlsHidden && (
        <div className="mb-4 grid grid-cols-12 gap-x-4">
          <SearchInput
            hasLeftRoundedBorders
            hasRightRoundedBorders
            className={clsx('col-span-8 w-full')}
            placeholder="Search keywords, booking number"
            inputClassName="text-xs"
            value={searchQuery}
            onChange={handleOnChangeSearch}
            iconVariant="plain"
            iconSize="md"
          />
          <div className="col-span-4">
            <CustomDatePicker
              value={dateRange}
              handleOnChange={handleChangeDate}
              onClear={handleClearDateRange}
              onSubmit={handleSubmitDate}
              placeholder="Sort by date"
              isCalendarIcon
              iconColor="neutral"
              iconSize="md"
            />
          </div>
        </div>
      )}
      <div className="w-full rounded-lg border border-neutral-300 bg-neutral-50">
        <div className="flex flex-row flex-nowrap items-center justify-between border-b border-neutral-300 px-8 py-6">
          <span className="text-lg font-semibold">
            {isAppointmentNotes ? 'Appointment Notes' : 'Notes History'}
          </span>
          {!isReferralOverview && isEditable && (
            <div className="flex items-center space-x-2">
              {isAppointmentNotes && (
                <Button
                  variant="label"
                  className="!px-5"
                  onClick={handleViewNotesHistory}
                  disabled={!router.isReady || isDisabled}
                >
                  View Notes History
                </Button>
              )}
              <Button
                icon={Plus}
                variant={isAppointmentNotes ? 'secondary' : 'primary'}
                className="!px-5"
                onClick={handleAddNewNote}
                disabled={!router.isReady || isDisabled}
              >
                Add New Note
              </Button>
            </div>
          )}
        </div>
        <div className="px-8 py-[12px]">
          {isFetching || (isEmpty(providerClientNotes?.items) && renderEmptyState)}
          {renderProviderClientNotes}
        </div>
      </div>
    </>
  );
};

export default ProviderClientNotesList;
