import { Fragment, useEffect, useMemo } from 'react';

import {
  CreateServiceScheduleDtoBranchSelectionTypeEnum,
  Provider,
  ProviderService,
  ProviderServiceSchedule,
  UserOperation,
  UserProvider,
} from '@healthhub/api-lib';
import isEmpty from 'lodash.isempty';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import {
  BRAND_ADMIN_USERS,
  Checkbox,
  CustomServiceScheduleEnum,
  MultiSelectDropdown,
  MultiSelectOptionType,
  OperationUserType,
  ProviderUserType,
  formatProviderDisplayNameWithBranch,
} from '@mwell-healthhub/commons';

import { ProviderServiceCustomScheduleFormValues } from './ProviderCustomServiceScheduleForm';
import ProviderServiceHelper from '../utils/ProviderServiceHelper';

const { All, Specific } = CreateServiceScheduleDtoBranchSelectionTypeEnum;

type Props = {
  brand: Provider;
  isBranch: boolean;
  currentUser: UserOperation | UserProvider;
  providerService?: ProviderService;
  serviceSchedule?: ProviderServiceSchedule;
  assignedBranches?: Provider[];
  isEditing?: boolean;
};

function ProviderServiceCustomScheduleAvailabilityFormFields({
  currentUser,
  brand,
  isBranch,
  providerService,
  serviceSchedule,
  assignedBranches,
  isEditing = false,
}: Props) {
  const { branches: myBranches } = brand || {};
  const branches = !isEmpty(assignedBranches) ? assignedBranches : myBranches;
  const providerServiceHelper = new ProviderServiceHelper(providerService as ProviderService);
  const hasOnlyOneBranch = branches?.length === 1;
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<
    Pick<
      ProviderServiceCustomScheduleFormValues,
      | 'isAddingToExisting'
      | CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE
      | CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS
    >
  >();

  const isDisabled = hasOnlyOneBranch || isEditing;

  const isUserSuperAdmin = BRAND_ADMIN_USERS.includes(
    currentUser?.userType as ProviderUserType | OperationUserType,
  );

  const availabilityLabelsMap = {
    [All]: 'All branches',
    [Specific]: 'Only specific branches',
  };

  const selectedAvailability = watch(CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE);

  useEffect(() => {
    if (hasOnlyOneBranch) {
      setValue(CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE, Specific);
      setValue(
        CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS,
        branches?.map((branch) => {
          return {
            text: formatProviderDisplayNameWithBranch(branch),
            value: branch.id.toString(),
          } as MultiSelectOptionType;
        }) || [],
      );
    }
  }, [hasOnlyOneBranch]);

  const renderNoBranches = branches?.length === 0 && (
    <div className="bg-neutral-200 text-sm text-neutral-400">
      No branches have been added. Please add a branch first.
    </div>
  );

  const isSelectedAvailabilityInSelectedBranches = selectedAvailability === Specific;

  const mappedBranches: MultiSelectOptionType[] = useMemo(() => {
    if (!branches) {
      return [];
    }
    return branches.map((branch) => {
      return {
        text: formatProviderDisplayNameWithBranch(branch),
        value: branch.id,
      };
    }) as unknown as MultiSelectOptionType[];
  }, [branches]);

  useEffect(() => {
    if (serviceSchedule) {
      setValue(CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE, Specific);
      const selectedBranch = mappedBranches?.filter(({ value }) => {
        return value == serviceSchedule.provider.id.toString();
      });
      setValue(CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS, selectedBranch || []);
    }
  }, [serviceSchedule, mappedBranches]);

  const renderBranches = (
    <div className="py-3">
      <Controller
        name={CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <MultiSelectDropdown
            disabled={isDisabled}
            className={twMerge(
              'text-secondary-500 focus:ring-transparent',
              isDisabled && 'cursor-not-allowed text-gray-300',
            )}
            onRemove={onChange}
            onSelect={onChange}
            options={mappedBranches}
            placeholder="Select Branches"
            selectedOptions={value}
            clearLabel="Clear Selection"
          />
        )}
      />
      {renderNoBranches}
    </div>
  );

  const branchAdminAvailabilityOptions = [All, Specific];
  const superAdminAvailabilityOptions = [All, Specific];

  const availabilityOptions =
    isUserSuperAdmin && !isBranch ? superAdminAvailabilityOptions : branchAdminAvailabilityOptions;

  const renderSelectedBranchesLabel = `Only specific branches`;

  const handleChangeAvailability = (onChange: (...event: any[]) => void) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === All) {
        setValue(
          CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS,
          branches?.map((branch) => {
            return {
              text: formatProviderDisplayNameWithBranch(branch),
              value: branch.id.toString(),
            } as MultiSelectOptionType;
          }) || [],
        );
      } else {
        setValue(
          CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS,
          providerServiceHelper.availableBranchServices?.map((bs) => {
            return {
              text: formatProviderDisplayNameWithBranch(bs.provider),
              value: bs.provider.id.toString(),
            } as MultiSelectOptionType;
          }) || [],
        );
      }

      onChange(event.target.value);
    };
  };

  const renderOptions = (
    <div className="mt-1.5 flex flex-col">
      {availabilityOptions.map((option) => {
        const isAvailableInSelectedBranches =
          option === Specific && isSelectedAvailabilityInSelectedBranches;

        return (
          <Fragment key={option}>
            <div className="flex items-center">
              <Controller
                name={CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE}
                control={control}
                render={({ field }) => (
                  <input
                    type="radio"
                    {...field}
                    onChange={handleChangeAvailability(field.onChange)}
                    value={option}
                    checked={field.value === option}
                    disabled={isDisabled}
                    className={twMerge(
                      'text-secondary-500 focus:ring-transparent',
                      isDisabled && 'cursor-not-allowed text-gray-300',
                    )}
                  />
                )}
              />
              <span className="pl-3 text-sm text-neutral-900">
                {isAvailableInSelectedBranches
                  ? renderSelectedBranchesLabel
                  : availabilityLabelsMap[option]}
              </span>
            </div>
            {isAvailableInSelectedBranches && renderBranches}
          </Fragment>
        );
      })}
    </div>
  );

  return (
    <div>
      <label className="block text-sm font-normal leading-6 text-neutral-600">
        Branches
        <span className="text-red-600"> *</span>
      </label>
      {renderOptions}
      <div className="text-sm text-red-600">
        {errors?.branchSelectionType?.message || errors?.providerServiceBranchIds?.message}
      </div>
    </div>
  );
}

export default ProviderServiceCustomScheduleAvailabilityFormFields;
