import { DefaultSeoProps } from 'next-seo';

const config: DefaultSeoProps = {
  openGraph: {
    type: 'website',
    locale: 'en_US',
    siteName: 'mWell HealthHub',
    title: 'mWell HealthHub',
    description: 'mWell HealthHub - Your Digital Gateway to Everything Health and Wellness',
    url: process.env.NEXT_PUBLIC_BASE_URL,
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_CLIENT_BASE_URL}/static-assets/images/seo/og-photo2.jpg`,
        width: 1200,
        height: 630,
        alt: 'mwell HealthHub',
      },
    ],
  },
};

export default config;
