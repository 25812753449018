export enum FileTypeExtensionEnum {
  JPEG = 'jpeg',
  JPG = 'jpg',
  PDF = 'pdf',
  PNG = 'png',
  WEBP = 'webp',
  DOC = '.doc',
  DOCX = '.docx',
  TXT = '.txt',
  XLS = '.xls',
  XLSX = '.xlsx',
  GIF = '.gif',
}

export enum FileSizeLimitEnum {
  _5MB = 5242880,
  _15MB = 15728640, // 15mb
  _50MB = 52428800,
}

export enum MimeTypesEnum {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
  PNG = 'image/png',
  WEBP = 'image/webp',
}

export const EXTENSION_BY_CONTENT_TYPE = {
  [MimeTypesEnum.JPEG]: '.jpg',
  [MimeTypesEnum.JPG]: '.jpg',
  [MimeTypesEnum.PDF]: '.pdf',
  [MimeTypesEnum.PNG]: '.png',
  [MimeTypesEnum.WEBP]: '.webp',
};

export const IMAGE_FILE_TYPES = [MimeTypesEnum.JPEG, MimeTypesEnum.JPG, MimeTypesEnum.PNG];

export const IMAGE_FILE_EXTENSIONS = [
  `.${FileTypeExtensionEnum.JPEG}`,
  `.${FileTypeExtensionEnum.JPG}`,
  `.${FileTypeExtensionEnum.PNG}`,
  `.${FileTypeExtensionEnum.WEBP}`,
];

export const ACCEPTED_IMAGE_FILE_EXTENSIONS = IMAGE_FILE_EXTENSIONS.join(',');
