import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Controller, useFormContext } from 'react-hook-form';

import { clsxMerge, Radio, toTitleCase } from '../..';
import { ProviderStatusEnum, ProviderFormEnum } from '../../enums';

const { PUBLISHED, INACTIVE, UNPUBLISHED } = ProviderStatusEnum;

type StatusAttributeProps = {
  isChecked?: boolean;
  label: string;
};

type Props = {
  isDisabled?: boolean;
};

function StatusFormFields(props: Props) {
  const { isDisabled } = props;

  const { control } = useFormContext<{
    [ProviderFormEnum.STATUS]: ProviderStatusEnum;
  }>();

  const canLoginAttribute = <StatusAttribute key="can-login" isChecked label="Can log in" />;
  const cannotLoginAttribute = <StatusAttribute key="cant-login" label="Cannot log in" />;
  const visibleToClientAttribute = (
    <StatusAttribute key="visible-to-client" isChecked label="Visible to clients" />
  );
  const notVisibleToClientAttribute = (
    <StatusAttribute key="invisible-to-client" label="Not visible to clients" />
  );

  const provderStatusOptions = [
    {
      value: PUBLISHED,
      attributes: [canLoginAttribute, visibleToClientAttribute],
      disabled: isDisabled,
    },
    {
      value: UNPUBLISHED,
      attributes: [canLoginAttribute, notVisibleToClientAttribute],
    },
    {
      value: INACTIVE,
      attributes: [cannotLoginAttribute, notVisibleToClientAttribute],
      disabled: isDisabled,
    },
  ];

  return (
    <div className="flex flex-col gap-4 md:gap-5">
      <Controller
        name={ProviderFormEnum.STATUS}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            {provderStatusOptions.map((option) => (
              <div className="flex flex-col gap-1" key={option.value}>
                <Radio
                  disabled={option.disabled}
                  label={toTitleCase(option.value)}
                  labelClassName="font-medium text-sm"
                  value={option.value}
                  isChecked={value === option.value}
                  onChange={(event) => {
                    onChange(event.currentTarget.value);
                  }}
                />
                <div
                  className={clsxMerge(
                    'ml-7 flex gap-5 text-sm text-neutral-500',
                    option.disabled && 'opacity-50',
                  )}
                >
                  {option.attributes}
                </div>
              </div>
            ))}
            {error && <div className="mt-1 text-sm text-red-600">{error?.message}</div>}
          </>
        )}
      />
    </div>
  );
}

function StatusAttribute(props: Readonly<StatusAttributeProps>) {
  const Icon = props.isChecked ? CheckCircleIcon : XCircleIcon;

  return (
    <div className="flex gap-2 text-neutral-500">
      <Icon className="h-5 w-5 text-neutral-400" />
      <span>{props.label}</span>
    </div>
  );
}

export default StatusFormFields;
