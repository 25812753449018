/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CreateProductDto,
  EshopApi,
  UpdateProductDto,
  UpdateProductsDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash.get';

import HealthHubApiClient from './api-client';
import { OrderByEnum, ProductStatus } from '../enums';
import { CustomError } from '../errors';
import { EshopProduct, Pagination } from '../types';

export const productsApi = HealthHubApiClient.use(EshopApi);

export type UpdateProductParams = {
  id: string;
  updateProductDto: UpdateProductDto;
};

export type ProductParams = {
  limit?: number;
  page?: number;
  query?: string;
  orderBy?: OrderByEnum;
  status?: ProductStatus;
  merchantEmail?: string;
  categoryId?: string;
};

export async function getAllProducts(params: ProductParams): Promise<Pagination<EshopProduct>> {
  try {
    const { query, status, limit, page, merchantEmail, orderBy, categoryId } = params;
    const { data } = await productsApi.eshopControllerFindAllProducts(
      query,
      merchantEmail,
      status,
      orderBy,
      categoryId,
      limit,
      page,
    );

    return data as unknown as Pagination<EshopProduct>;
  } catch (err) {
    throw new Error('Failed to fetch products');
  }
}

export async function getProductById(
  id: string,
  config?: AxiosRequestConfig,
): Promise<EshopProduct> {
  try {
    const response = await productsApi.eshopControllerFindOneProduct(id, config);
    const data = response.data;

    return data as unknown as EshopProduct;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateProduct(params: UpdateProductParams): Promise<EshopProduct> {
  const { id, updateProductDto } = params;

  try {
    const { data } = await productsApi.eshopControllerUpdateProduct(id, updateProductDto);

    return data as unknown as EshopProduct;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to update product');
  }
}

export async function updateProducts(
  updateProductsDto: UpdateProductsDto,
): Promise<EshopProduct[]> {
  try {
    const { data } = await productsApi.eshopControllerUpdateProducts(updateProductsDto);

    return data as unknown as EshopProduct[];
  } catch (err) {
    throw new Error('Failed to update products');
  }
}

export async function createProduct(product: CreateProductDto): Promise<EshopProduct> {
  try {
    const { data } = await productsApi.eshopControllerCreateProduct(product);

    return data as unknown as EshopProduct;
  } catch (err) {
    throw new Error('Failed to create product');
  }
}

export async function deleteProduct(id: string): Promise<void> {
  try {
    await productsApi.eshopControllerDeleteProduct(id);
  } catch (err) {
    throw new Error('Failed to delete product');
  }
}
