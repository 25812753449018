import { ChangeEvent, useEffect, useState } from 'react';
import forwardRef from 'react';

import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Plus } from 'iconoir-react';

import { useDebounce } from '../../hooks';
import { AutoCompleteOptionType } from '../../types';
import { clsxMerge, filterAutoCompleteOptions } from '../../utils';
import LoadingSpinner from '../LoadingSpinner';

export type AutoCompleteProps = {
  label?: string;
  options: AutoCompleteOptionType[];
  comboBoxOptionClassName?: string;
  onSelectedItem?: (item: AutoCompleteOptionType) => void;
  onChangeText?: (text: string) => void;
  onBlur?: (searchTerm?: string) => void;
  initialValue?: string;
  placeholder?: string;
  name?: string;
  showRequiredOnLabel?: boolean;
  handleAdd?: () => void;
  showAdd?: boolean;
  inputRef?: forwardRef.MutableRefObject<HTMLInputElement | null>;
  isFetching?: boolean;
  showSkeletonLoader?: boolean;
  hideOptions?: boolean;
  bottomItem?: JSX.Element;
  containerClassName?: string;
  labelClassName?: string;
  errorMessage?: string;
  isSearchEnabled?: boolean;
  isSearchTermSelectable?: boolean;
  valueKey?: string;
  debounceTime?: number;
  disabled?: boolean;
  inputClass?: string;
};

function AutoComplete(props: AutoCompleteProps): JSX.Element {
  const {
    label,
    placeholder,
    options,
    comboBoxOptionClassName,
    onSelectedItem,
    initialValue,
    onChangeText,
    name,
    onBlur,
    showRequiredOnLabel,
    handleAdd,
    showAdd = false,
    inputRef,
    isFetching,
    hideOptions = false,
    bottomItem,
    containerClassName,
    labelClassName,
    errorMessage,
    isSearchEnabled = false,
    isSearchTermSelectable = false,
    valueKey = 'id',
    debounceTime = 300,
    disabled = false,
    inputClass,
    showSkeletonLoader,
  } = props;
  const [selectedItem, setSelectedItem] = useState<AutoCompleteOptionType | null>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const filteredOptions = isSearchEnabled
    ? filterAutoCompleteOptions(options, searchTerm || undefined)
    : options;

  const handleItemSelect = (item: AutoCompleteOptionType) => {
    setSelectedItem(item);
    onSelectedItem?.(item);
    setSearchTerm(null);
  };

  useEffect(() => {
    const item =
      selectedItem?.text && !selectedItem.id
        ? selectedItem
        : options.find((o) => `${o[valueKey as keyof AutoCompleteOptionType]}` === initialValue);
    setSelectedItem(item ?? null);
  }, [initialValue, options, selectedItem, valueKey]);

  const search = (query: string) => {
    onChangeText?.(query);
  };

  const debouncedSearch = useDebounce(search, debounceTime);

  const handleOnChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleOnBlur = () => {
    onBlur && onBlur(searchTerm ?? selectedItem?.text ?? '');
  };

  const handleAddToOptions = (event: any) => {
    event.preventDefault();
    handleAdd?.();
    setSearchTerm(null);
  };

  const selectableSearchTerm = {
    id: '',
    text: searchTerm ?? '',
    content: <span>{searchTerm}</span>,
  } satisfies AutoCompleteOptionType;

  const buttonContent = isFetching ? (
    <LoadingSpinner className="h-5 w-5" />
  ) : (
    <div
      className="flex h-5 cursor-pointer items-center justify-center rounded-md bg-primary-500 px-2 py-1 text-white"
      onClick={handleAddToOptions}
    >
      <Plus />
      <span className="text-xs">Add New</span>
    </div>
  );

  const inputClassName = clsxMerge(
    'shadow-sm w-full rounded-md border-0 py-2 pl-3 pr-10 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6',
    disabled ? 'bg-neutral-100 text-neutral-400' : 'bg-white text-neutral-600',
    inputClass,
  );

  const ComboboxOption = (option: AutoCompleteOptionType) => {
    return (
      <Combobox.Option
        key={option.id}
        value={option}
        className={({ active }) =>
          clsxMerge(
            'relative cursor-default select-none rounded-md py-2 pl-8 pr-4 hover:bg-primary-300 hover:text-white',
            active ? 'bg-primary-500 text-white' : 'text-neutral-600',
            comboBoxOptionClassName,
            !option.id && 'hidden',
          )
        }
      >
        {({ active, selected }) => (
          <>
            <div className={clsxMerge('block truncate', selected && 'font-regular')}>
              {option.content}
            </div>

            {!comboBoxOptionClassName && selected && (
              <span
                className={clsxMerge(
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  active ? 'text-white' : 'text-indigo-600',
                )}
              >
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            )}
          </>
        )}
      </Combobox.Option>
    );
  };

  const hasOptions = Boolean(filteredOptions.length) || (isSearchTermSelectable && searchTerm);

  return (
    <Combobox
      as="div"
      value={selectedItem}
      onChange={handleItemSelect}
      className={containerClassName}
      disabled={disabled}
    >
      {label && (
        <Combobox.Label
          className={clsxMerge('block text-sm leading-6 text-neutral-600', labelClassName)}
        >
          {label}
          {showRequiredOnLabel && <span className="text-red-600"> *</span>}
        </Combobox.Label>
      )}
      <div className="relative">
        <input type="hidden" name={name} value={selectedItem?.id || ''} />
        <Combobox.Input
          ref={inputRef}
          autoComplete="off"
          className={inputClassName}
          onChange={handleOnChangeText}
          onBlur={handleOnBlur}
          displayValue={(option: AutoCompleteOptionType) => option?.text}
          placeholder={placeholder}
          value={searchTerm ?? selectedItem?.text ?? ''}
        />

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {showAdd && !!searchTerm?.length && buttonContent}
          <ChevronDownIcon className="h-5 w-5 text-neutral-400" aria-hidden="true" />
        </Combobox.Button>

        {(hasOptions || bottomItem !== undefined || isFetching) && !hideOptions && (
          <Combobox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white p-3 text-base ring-1 ring-black ring-opacity-5 drop-shadow-lg focus:outline-none sm:text-sm">
            {isSearchTermSelectable && (
              <ComboboxOption key={selectableSearchTerm.id} {...selectableSearchTerm} />
            )}
            {!isFetching &&
              filteredOptions.map((option) => <ComboboxOption key={option.id} {...option} />)}
            {showSkeletonLoader && isFetching && (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="h-9 rounded-md bg-neutral-200 dark:bg-neutral-300"></div>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {bottomItem}
          </Combobox.Options>
        )}
      </div>
      {errorMessage && <p className="mt-1 text-sm text-red-600">{errorMessage}</p>}
    </Combobox>
  );
}

export default AutoComplete;
