import { useState } from 'react';

import { useCreateMessageMutation, useUpdateThreadReadByIdsArrayMutation } from './index';

type Props = {
  threadId: string;
  branchId: string;
  senderUserId: string;
  isProvider: boolean;
};

export const useMessageOperations = (props: Props) => {
  const { threadId, branchId, senderUserId, isProvider } = props;

  const [messageValue, setMessageValue] = useState('');
  const disableSendButton = !messageValue.trim();

  const { mutate: createMessage } = useCreateMessageMutation({
    onSuccess: () => {
      setMessageValue('');
    },
  });

  const { mutate: markThreadAsRead } = useUpdateThreadReadByIdsArrayMutation({});

  const handleSendMessage = () => {
    const value = messageValue.trim();
    if (!value || !threadId || !senderUserId) {
      return;
    }

    const data = {
      value,
      messageThreadId: threadId,
      branchId: branchId || '',
    };

    createMessage(data);
  };

  const handleMarkThreadAsRead = () => {
    if (isProvider && !branchId) {
      return;
    }

    markThreadAsRead({ threadId, branchId });
  };

  return {
    messageValue,
    setMessageValue,
    disableSendButton,
    handleSendMessage,
    handleMarkThreadAsRead,
  };
};
