import Badge from './Badge';
import { OrderStatusEnum } from '../../enums';
import { BadgeType } from '../../enums/badgeType';
import { toOrderStatusDisplay } from '../../utils';

type Props = {
  status: OrderStatusEnum;
};

function OrderStatusBadge(props: Props) {
  const { status } = props;

  let badgeType: BadgeType;

  switch (status) {
    case OrderStatusEnum.Completed:
      badgeType = BadgeType.GREEN;
      break;
    case OrderStatusEnum.Cancelled:
      badgeType = BadgeType.RED;
      break;
    case OrderStatusEnum.Confirmed:
    case OrderStatusEnum.Processing:
    case OrderStatusEnum.Shipped:
      badgeType = BadgeType.YELLOW;
      break;
    case OrderStatusEnum.Pending:
      badgeType = BadgeType.GREY;
      break;
  }

  return <Badge type={badgeType}>{toOrderStatusDisplay(status)}</Badge>;
}

export default OrderStatusBadge;
