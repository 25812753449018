import React from 'react';

import { ColorClasses, ColorTextClasses } from '../enums/status';
import { BadgeTypeVariant } from '../enums/variant';

type StatusBadgeProps = {
  status: string;
  type: keyof typeof ColorClasses;
  textColor?: keyof typeof ColorTextClasses;
  badgeType?: BadgeTypeVariant;
};

const badgeTypeVariant: Record<string, string> = {
  [BadgeTypeVariant.FULL]: 'rounded-full',
  [BadgeTypeVariant.MD]: 'rounded-md',
};

const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  type,
  textColor,
  badgeType = BadgeTypeVariant.FULL,
}) => {
  const badgeColorClasses = ColorClasses[type] || ColorClasses.default;
  const badgeTextColorClasses = textColor ? ColorTextClasses[textColor] : ColorTextClasses.default;

  return (
    <span
      className={`inline-flex items-center text-center capitalize ${badgeTypeVariant[badgeType]} px-1.5 py-0.5 text-xs font-normal ${badgeTextColorClasses} ${badgeColorClasses}`}
    >
      {status}
    </span>
  );
};

export default StatusBadge;
