import { useEffect, useMemo, useState } from 'react';

import {
  Appointment,
  AppointmentApprovalStatusEnum,
  AppointmentStatusEnum,
  ProviderService,
  ProviderServiceAvailabilityEnum,
  UpdateAppointmentDto,
} from '@healthhub/api-lib';
import {
  BriefcaseIcon,
  CalendarDaysIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  ShieldCheckIcon,
  UserCircleIcon,
  UserIcon,
} from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import Link from 'next/link';

import TagUpdatePopUp from './TagUpdatePopUp';
import {
  convertIsoFormatTime,
  displayAppointmentDateTime,
  displayOtherConcern,
  FieldTextHtmlFormatter,
  formatUserNameToFull,
  getAge,
  getBlobName,
  getDateWithTimeObject,
  ISO_18601_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
  MultiSelectOptionType,
  renderSexLabel,
  RescheduleModal,
  Routes,
  SexEnum,
  toast,
  truncateMiddle,
} from '../..';
import {
  useAddAppointmentTagMutation,
  useGetAllProviderTagCategoryParams,
  useMassUpdateAppointmentServices,
  useRescheduleAppointmentMutation,
  useRouter,
  useToggle,
} from '../../hooks';
import useIsMobile from '../../hooks/useIsMobile';
import { trackEvent, EventName } from '../../utils/analytics';
import { generateTagList } from '../../utils/generateOptions';
import { ImageExtension } from '../AttachmentPreview/ImageExtension';
import StatusDisplay from '../BookingTable/StatusDisplay';
import MultiSelectDropdown from '../MultiSelectDropdown';

type FilterType = {
  searchQuery?: string;
};

type Props = {
  appointment: Appointment;
  providerId?: string;
  providerServices?: ProviderService[];
  showProviderName?: boolean;
  showReschedule?: boolean;
  showActions?: boolean;
  shouldShowHmoSection?: boolean;
  isReferral?: boolean;
  appointmentReferralFormFileLink?: string;
  refetch?: () => void;
  isDisabled?: boolean;
};

const AppointmentDetails = ({
  appointment,
  providerId,
  providerServices,
  showProviderName = false,
  showReschedule = false,
  showActions = false,
  shouldShowHmoSection = true,
  isReferral = false,
  appointmentReferralFormFileLink = '',
  isDisabled = false,
  refetch,
}: Props) => {
  const router = useRouter();

  const [selectedServices, setSelectedServices] = useState<MultiSelectOptionType[]>([]);
  const [filters, setFilters] = useState<FilterType>();
  const { isOpen: isOpenRescheduleModal, handleToggle: handleRescheduleModalToggle } = useToggle();

  const isMobile = useIsMobile();

  const APOINTMENT_DATE_FORMAT = isMobile ? 'E, MMM d yyyy • h:mm a' : 'MMM d yyyy, h:mm a';

  useEffect(() => {
    if (appointment?.appointmentServices?.length) {
      const selectedServices = appointment.appointmentServices.map((service) => ({
        text: service.providerService.label,
        value: service.providerService.id,
      })) as unknown as MultiSelectOptionType[];

      setSelectedServices(selectedServices);
    }
  }, [appointment]);

  const isConfirmed = appointment.status === AppointmentStatusEnum.Confirmed;
  const isPending = appointment.status === AppointmentStatusEnum.Pending;
  const isCancelled = appointment.status === AppointmentStatusEnum.Cancelled;
  const isDeclined = appointment.approvalStatus === AppointmentApprovalStatusEnum.Declined;
  const hasProviderServices = !!providerServices?.length;
  const shouldShowReschedule = isConfirmed && showReschedule;
  const shouldShowServicesDropdown = (isConfirmed || isPending) && hasProviderServices;
  const hasOtherConcern = !!appointment.otherConcern;

  const { mutateAsync: rescheduleAppointment, isLoading: isLoadingRescheduleAppointment } =
    useRescheduleAppointmentMutation();

  const { mutateAsync: massUpdateAppointmentService } = useMassUpdateAppointmentServices({
    onSuccess: () => {
      refetch?.();
      toast({ message: 'Appointment services updated', type: 'success' });
    },
    onError: () => {
      toast({ message: 'Failed to update appointment', type: 'error' });
    },
  });

  const mappedProviderServices: MultiSelectOptionType[] = useMemo(() => {
    return providerServices?.map((providerService) => {
      const isUnavailable =
        providerService.availability === ProviderServiceAvailabilityEnum.Unavailable;
      const text = `${providerService?.label || providerService?.service?.name}`;

      return {
        text,
        value: providerService?.id,
        isDisabled: isUnavailable,
      };
    }) as unknown as MultiSelectOptionType[];
  }, [providerServices]);

  const displayServices = (appointmentServices: MultiSelectOptionType[]) => {
    if (!appointmentServices?.length && !hasOtherConcern) {
      return 'No services selected';
    }

    return appointmentServices?.map((service, index) => {
      const isLast = index === appointmentServices.length - 1;
      return (
        <span key={service.value}>
          {service.text}
          {!isLast && ',\u2006'}
        </span>
      );
    });
  };

  const appointmentProviderServices = appointment?.appointmentServices.map(
    (appointment) => appointment.providerService,
  );

  const handleSelectServices = (selectedServices: MultiSelectOptionType[]) => {
    setSelectedServices(selectedServices);
  };

  const handleRemoveSelectedServices = (selectedServices: MultiSelectOptionType[]) => {
    const updatedSelectedServices = selectedServices.filter((selectedService) => {
      return !selectedServices.some((service) => service.value === selectedService.value);
    });

    setSelectedServices(updatedSelectedServices);
  };

  const handleUpdateServicesSubmit = async () => {
    const appointmentServices = providerServices?.filter((providerService) => {
      return selectedServices.some((service) => +service.value === +providerService.id);
    });

    const toBeUpdatedServices =
      appointmentServices?.map((providerService) => {
        return {
          appointmentId: appointment.id,
          providerServiceId: providerService.id,
        };
      }) ?? [];

    if (!toBeUpdatedServices?.length) {
      return;
    }

    massUpdateAppointmentService({
      appointmentId: appointment.id,
      appointmentServiceItems: toBeUpdatedServices,
    });
  };

  const handleOpenRescheduleModal = () => {
    handleRescheduleModalToggle();
  };

  const handleRescheduleModalProceedClick = async (
    date: Date,
    time: string,
    reasonForReschedule: string,
    scheduleId?: number,
  ) => {
    try {
      const newTime = convertIsoFormatTime(time);
      const dateTime = getDateWithTimeObject(date, newTime);

      await rescheduleAppointment({
        id: appointment.id,
        rescheduleAppointmentDto: {
          dateTime: format(dateTime, ISO_18601_FORMAT),
          reasonForReschedule,
          scheduleId,
        },
      });

      trackEvent(EventName.CLICK_APPOINTMENT_RESCHEDULE, {
        appointment_booking_number: appointment?.bookingNumber,
        appointment_id: appointment?.id?.toString(),
      });

      router.replace(router.asPath);
      refetch?.();
      if (!isMobile) {
        toast({ message: 'Appointment rescheduled', type: 'success' });
      }
      handleRescheduleModalToggle();
    } catch (e) {
      if (!isMobile) {
        toast({ message: 'Something went wrong', type: 'error' });
      }
    }
  };

  // Appointment Tags

  const { mutate: addAppointmentTags } = useAddAppointmentTagMutation({
    onSuccess: () => {
      refetch?.();
    },
    onError: () => {
      if (!isMobile) {
        toast({ message: 'Failed to update appointment', type: 'error' });
      }
    },
  });

  const handleAddTags = (appointmentId: string, updateAppointmentDto: UpdateAppointmentDto) => {
    addAppointmentTags({ id: appointmentId, updateAppointmentDto });
  };

  const { data: providerTagCategories } = useGetAllProviderTagCategoryParams({
    providerId: providerId || '',
    ...filters,
  });

  const debouncedSetSearch = debounce((value) => {
    setFilters((prev) => ({ ...prev, searchQuery: value }));
  }, 300);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    debouncedSetSearch(inputValue);
  };

  const reasonDisplay = useMemo(() => {
    if (isCancelled && isDeclined) {
      return 'Reason for declining:';
    }
    if (isCancelled) {
      return 'Reason for cancellation:';
    }

    return 'Reason for reschedule:';
  }, [isCancelled, isDeclined]);

  return (
    <>
      {isOpenRescheduleModal && showReschedule && (
        <RescheduleModal
          booking={appointment}
          isLoading={isLoadingRescheduleAppointment}
          onCancelClick={handleRescheduleModalToggle}
          onProceedClick={handleRescheduleModalProceedClick}
          providerId={appointment?.provider?.id as number}
          providerServices={appointmentProviderServices}
        />
      )}
      <div className="flex w-full flex-col items-start justify-between gap-4 border border-gray-200 bg-white px-6 py-4 md:flex-row md:space-x-6 md:rounded-md md:px-8 md:py-7">
        <div className="flex w-full flex-col gap-4 md:gap-0">
          {showProviderName && (
            <h3 className="mb-2 font-semibold">
              {appointment.provider?.displayName} <span className="font-normal"> - </span>
              <span className="text-gray-500">{appointment.provider?.branch}</span>
            </h3>
          )}
          <div className="mb-4 flex w-full flex-col items-start justify-between gap-y-1 md:mb-6 md:flex-row md:items-center md:gap-y-0">
            <div className="flex w-full items-start justify-between gap-x-3 md:w-auto md:items-center md:justify-start">
              <h3 className="text-base font-semibold">
                Appointment{' '}
                <span className="block text-black md:inline-block md:text-gray-500">
                  #{appointment.bookingNumber}
                </span>
              </h3>
              <StatusDisplay
                approvalStatus={appointment.approvalStatus}
                status={appointment.status}
              />
            </div>
            <div className="hidden content-end items-center justify-between gap-x-5 md:mt-0 md:flex md:justify-start">
              <label className="sm:text-md text-sm">Tags</label>
              {showActions && (
                <TagUpdatePopUp
                  isDisabled={isDisabled}
                  handleAddTags={handleAddTags}
                  appointment={appointment}
                  handleSearch={handleSearch}
                  providerTagCategories={providerTagCategories}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col">
              <ul className="flex flex-col gap-y-3 md:gap-y-5">
                {(!!appointment?.reasonForReschedule || !!appointment?.reasonForCancellation) && (
                  <li className="flex flex-wrap items-center gap-x-1.5 text-sm sm:text-base md:gap-x-4">
                    <ExclamationCircleIcon width={20} className="text-gray-400" />
                    <div>
                      <p className="text-base text-neutral-600">{reasonDisplay}</p>
                      <p className="text-base text-neutral-500">
                        {isCancelled
                          ? appointment.reasonForCancellation
                          : appointment.reasonForReschedule}
                      </p>
                    </div>
                  </li>
                )}
                <li className="flex flex-wrap items-center justify-between gap-x-1.5 text-sm sm:text-base md:gap-x-4">
                  <div className="flex flex-row items-center gap-x-1.5 md:gap-x-4">
                    <CalendarDaysIcon width={20} className="text-gray-400" />
                    <div className="flex max-w-[300px] flex-col gap-4">
                      <span>
                        {displayAppointmentDateTime(appointment.dateTime, APOINTMENT_DATE_FORMAT)}
                      </span>
                    </div>
                  </div>
                  {shouldShowReschedule && (
                    <button
                      className="text-sm font-medium text-primary3 underline underline-offset-4"
                      onClick={handleOpenRescheduleModal}
                      disabled={isDisabled}
                    >
                      Reschedule
                    </button>
                  )}
                </li>
                <li className="flex flex-wrap items-start justify-between gap-x-1.5 text-sm sm:text-base md:gap-x-4">
                  <div className="flex flex-row items-start gap-x-1.5 md:gap-x-4">
                    <BriefcaseIcon width={20} className="mt-0.5 text-gray-400" />
                    <div className="flex max-w-[300px] flex-col md:flex-row md:flex-wrap">
                      {displayServices(selectedServices)}{' '}
                      {displayOtherConcern(
                        appointment.appointmentServices,
                        appointment.otherConcern,
                      )}
                    </div>
                  </div>
                  {shouldShowServicesDropdown && (
                    <div className="w-54">
                      <MultiSelectDropdown
                        isLinkType
                        minSelections={1}
                        disabled={isDisabled}
                        onRemove={handleRemoveSelectedServices}
                        onSelect={handleSelectServices}
                        options={mappedProviderServices}
                        selectedOptions={selectedServices}
                        placeholder="Edit Services"
                        className="border-none"
                        selectInnerClassName="p-0 font-medium"
                        clearLabel="Clear All"
                        onSubmit={handleUpdateServicesSubmit}
                      />
                    </div>
                  )}
                </li>
                <li className="flex items-start gap-x-1.5 text-sm sm:text-base md:gap-x-4">
                  <UserIcon width={20} className="mt-0.5 min-w-[20px] text-gray-400" />
                  <div className="flex flex-col gap-y-1">
                    <Link
                      href={`${Routes.CLIENTS}/${appointment.providerClientProfile.id}`}
                      className="text-sm font-medium text-primary-500 md:text-base"
                    >
                      {formatUserNameToFull(appointment.providerClientProfile)}
                    </Link>
                    {!!appointment.providerClientProfile.email && (
                      <p className="text-xs text-gray-500">
                        {appointment.providerClientProfile.email}
                      </p>
                    )}
                    {!!appointment.providerClientProfile.mobilePhoneNumber && (
                      <p className="text-xs text-gray-500">
                        {appointment.providerClientProfile.countryCode}
                        {appointment.providerClientProfile.mobilePhoneNumber}
                      </p>
                    )}
                    <p className="text-xs text-gray-500">
                      {format(
                        new Date(appointment.providerClientProfile.birthDate),
                        MONTH_DAY_YEAR_FORMAT,
                      )}{' '}
                      ({getAge(new Date(appointment.providerClientProfile.birthDate))})
                    </p>
                    <p className="text-xs text-gray-500">
                      {renderSexLabel(appointment.providerClientProfile.sex as SexEnum)}
                    </p>
                  </div>
                </li>

                {appointment.hmoSelected && shouldShowHmoSection && (
                  <li className="flex items-start gap-x-1.5 text-sm sm:text-base md:gap-x-4">
                    <ShieldCheckIcon width={20} className="min-w-[20px] text-gray-400" />
                    <div className="flex flex-col gap-y-1">
                      <span className="truncate text-sm font-medium text-primary-500 md:text-base">
                        {(appointment.hmoSelected as Record<string, string>).label}
                      </span>
                      <div className="grid max-w-xl gap-3 md:grid-cols-6">
                        <p className="text-sm text-neutral-500 md:col-span-2">Account No.:</p>
                        <p className="line-clamp-1 text-sm text-neutral-500 md:col-span-3">
                          {appointment.hmoAccountNumber}
                        </p>
                        <p />

                        <p className="text-sm text-neutral-500 md:col-span-2">
                          Letter of Authorization:
                        </p>
                        <p className="line-clamp-1 text-sm text-neutral-500 md:col-span-3">
                          {truncateMiddle(
                            getBlobName(appointment.hmoLetterOfAuthorizationUrl ?? ''),
                            25,
                          )}
                          <ImageExtension fileUrl={appointment.hmoLetterOfAuthorizationUrl} />
                        </p>
                        <Link
                          className="mb-3 text-left text-sm font-medium text-primary3 underline underline-offset-4 md:mb-0 md:text-right"
                          href={`/appointments/${appointment.id}/loa`}
                        >
                          View File
                        </Link>
                      </div>
                    </div>
                  </li>
                )}

                {providerId !== appointment.provider?.id.toString() && (
                  <li className="flex flex-wrap items-center justify-between gap-x-1.5 text-sm sm:text-base md:gap-x-4">
                    <div className="flex flex-row items-center gap-x-1.5 md:gap-x-4">
                      <UserCircleIcon width={20} className="text-gray-400" />
                      <div className="flex max-w-[300px] flex-col gap-4">
                        <span className="font-medium text-primary-500">
                          {appointment.provider?.displayName}
                        </span>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div className="hidden md:block md:w-auto">
              <div className="flex max-w-[400px] flex-wrap items-end justify-start gap-2 md:justify-end">
                {!isEmpty(appointment?.providerTags) ? (
                  generateTagList(appointment?.providerTags as [], 'Appointment')
                ) : (
                  <span className="text-sm text-neutral-400">No tags added</span>
                )}
              </div>
            </div>
          </div>
          {isReferral && (
            <div className="mt-6 flex flex-col justify-stretch gap-y-1 rounded-md bg-neutral-100 p-4 text-sm">
              <FieldTextHtmlFormatter remarks={appointment.referral?.reasonForReferral as string} />
              <span className="text-neutral-500">
                Referred by {appointment.referral?.provider.displayName}
              </span>
            </div>
          )}
          {appointmentReferralFormFileLink && (
            <Link
              className="mt-5 flex gap-x-4 text-left text-sm font-medium text-primary3 underline underline-offset-4"
              href={appointmentReferralFormFileLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DocumentTextIcon width={20} className="text-gray-400" />
              View Referral Form
            </Link>
          )}
        </div>
      </div>
      <div className="flex w-full flex-col items-start justify-between gap-3 border border-gray-200 bg-white px-6 py-4 md:hidden">
        <div className="flex w-full content-end items-center justify-between gap-x-5">
          <label className="text-md font-semibold">Tags</label>
          {showActions && (
            <TagUpdatePopUp
              handleAddTags={handleAddTags}
              appointment={appointment}
              handleSearch={handleSearch}
              providerTagCategories={providerTagCategories}
            />
          )}
        </div>
        <div className="w-auto">
          <div className="flex flex-wrap justify-start gap-2">
            {!isEmpty(appointment?.providerTags) ? (
              generateTagList(appointment?.providerTags as [], 'Appointment')
            ) : (
              <span className="text-sm text-neutral-400">No tags added</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentDetails;
