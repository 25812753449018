import React from 'react';

import dynamic from 'next/dynamic';

type Props = {
  isClickable?: boolean;
  homeUrl?: string;
  height?: number;
  width?: number;
  isInvert?: boolean;
};

const AppLogo: React.FC<Props> = (props) => {
  const { homeUrl = '/', isClickable = true, height, width, isInvert } = props;

  const logoSrc = isInvert
    ? '/static-assets/images/logo-invert.svg'
    : '/static-assets/images/logo.svg';

  const renderLogo = (
    <img
      src={logoSrc}
      alt="mWell HealthHub"
      className="sm:max-w-full"
      style={{ height: `${height}px`, width: `${width}px` }}
    />
  );

  return isClickable ? <a href={homeUrl}>{renderLogo}</a> : renderLogo;
};

export default dynamic(() => Promise.resolve(AppLogo), { ssr: false });
