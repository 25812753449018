import RepresentativeFormFields from '../../ProviderFormFields/RepresentativeFormFields';

function ProviderContactDetails() {
  return (
    <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
      <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
        Representative Information
      </span>
      <RepresentativeFormFields />
    </div>
  );
}

export default ProviderContactDetails;
