import { MedicalRecordFile } from '@healthhub/api-lib';
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  removeMedicalRecordFileById,
  uploadMedicalRecordFile,
  uploadOnboardingDocument,
} from '../../api/fileService';
import { GET_ALL_MEDICAL_RECORD_BY_APPOINTMENT_ID } from '../../constants';
import { CustomError } from '../../errors';

export const useUploadMedicalRecordFileMutation = (
  mutationOptions?: MutationOptions<
    MedicalRecordFile,
    CustomError,
    { file: File; appointmentId: string; name: string }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(uploadMedicalRecordFile, {
    ...mutationOptions,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([GET_ALL_MEDICAL_RECORD_BY_APPOINTMENT_ID]);
      mutationOptions?.onSuccess?.(...args);
    },
  });
};

export const useUploadOnboardingDocumentMutation = (
  mutationOptions?: MutationOptions<any, CustomError, File>,
) => useMutation(uploadOnboardingDocument, mutationOptions);

export const useRemoveMedicalRecordFileByIdMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, { id: string }>,
) => {
  const queryClient = useQueryClient();

  return useMutation(({ id }) => removeMedicalRecordFileById(id), {
    ...mutationOptions,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([GET_ALL_MEDICAL_RECORD_BY_APPOINTMENT_ID]);
      mutationOptions?.onSuccess?.(...args);
    },
  });
};
