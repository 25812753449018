import { useRef, useState } from 'react';

import { ArchiveBoxXMarkIcon, CalendarIcon } from '@heroicons/react/24/outline';
import isEmpty from 'lodash.isempty';
import DatePicker, { DateObject, DatePickerProps } from 'react-multi-date-picker';

import { clsxMerge } from '../utils';

enum IconSize {
  'sm' = 16,
  'md' = 20,
  'lg' = 24,
}

enum IconColor {
  'primary' = 'text-lightGray4',
  'neutral' = 'text-neutral-400',
}

type Props = {
  value: DateObject[];
  handleOnChange: (date: DateObject[]) => void;
  onClear?: () => void;
  onSubmit?: (date: DateObject[]) => void;
  placeholder?: string;
  isCalendarIcon?: boolean;
  iconSize?: 'sm' | 'md' | 'lg';
  iconColor?: 'primary' | 'neutral';
  className?: string;
  mainPosition?: 'top' | 'bottom' | 'left' | 'right';
  relativePosition?: 'start' | 'center' | 'end';
} & DatePickerProps;

function CustomDatePicker(props: Props): JSX.Element {
  const {
    value,
    handleOnChange,
    onClear,
    onSubmit,
    placeholder = 'Select Date Range',
    isCalendarIcon,
    iconSize = 'md',
    iconColor = 'primary',
    className,
    offsetY = 8,
    mainPosition = 'bottom',
    relativePosition = 'start',
  } = props;

  const datePickerRef = useRef<React.MutableRefObject<any>>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const handleOpen = () => {
    const { openCalendar, closeCalendar } = datePickerRef.current ?? ({} as any);

    if (closeCalendar && !isDatePickerOpen) {
      openCalendar();
    } else {
      closeCalendar();
    }

    if (onSubmit) {
      onSubmit(value);
    }

    setIsDatePickerOpen((prevState) => !prevState);
  };

  const handleOnChangeDatePicker = (date: DateObject[]) => {
    const { closeCalendar } = datePickerRef.current ?? ({} as any);

    if (closeCalendar && date?.length === 2) {
      closeCalendar();
    }
    setIsDatePickerOpen(false);
    handleOnChange(date);
  };

  const actionButtonsClass =
    'group relative rounded-full cursor-pointer flex items-center justify-center';
  const tooltipClass =
    'absolute left-1/2 m-4 mx-auto -translate-x-1/2 translate-y-full rounded-md bg-primary-500 px-1 text-sm text-gray-100 opacity-0 transition-opacity group-hover:opacity-90 whitespace-nowrap -bottom-5 z-[100] shadow-sm';

  const applyIcon = isCalendarIcon ? (
    <div className={actionButtonsClass} onClick={handleOpen}>
      <CalendarIcon className={IconColor[iconColor]} height={IconSize[iconSize]} />
    </div>
  ) : (
    <div className={actionButtonsClass} onClick={handleOpen}>
      <CalendarIcon className={IconColor[iconColor]} height={IconSize[iconSize]} />
    </div>
  );
  return (
    <div
      className={clsxMerge(
        'relative flex w-full flex-row items-center rounded-md border border-neutral-300 bg-white py-2 text-sm',
        className,
      )}
    >
      <DatePicker
        ref={datePickerRef}
        placeholder={placeholder}
        containerClassName="w-full pr-[60px]"
        format="MMM DD YYYY"
        inputClass="!py-0 !px-3 border border-transparent focus:border-transparent focus:ring-0 w-full text-xs md:text-sm text-neutral-600 placeholder:text-neutral-400 rounded-md"
        value={value}
        onChange={handleOnChangeDatePicker}
        rangeHover
        range
        offsetY={offsetY}
        calendarPosition={`${mainPosition}-${relativePosition}`}
      />

      <div className="absolute right-3 top-1/2 flex -translate-y-1/2 items-center gap-2">
        {onClear && !isEmpty(value) && (
          <div className={actionButtonsClass} onClick={onClear}>
            <ArchiveBoxXMarkIcon
              className={IconColor[iconColor]}
              height={IconSize[iconSize]}
              width={IconSize[iconSize]}
            />
            <span className={tooltipClass}>Clear</span>
          </div>
        )}
        {applyIcon}
      </div>
    </div>
  );
}

export default CustomDatePicker;
