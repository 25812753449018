import { useQuery } from '@tanstack/react-query';

import { getMultipleFeedback } from '../../api/feedbackService';
import { GET_MULTIPLE_FEEDBACK } from '../../constants/queryKeys';

export const useFindMultipleFeedback = (
  branchId: string,
  authorId?: string,
  startDate?: string,
  endDate?: string,
) =>
  useQuery(
    [GET_MULTIPLE_FEEDBACK, branchId, authorId, startDate, endDate],
    () => getMultipleFeedback(branchId, authorId, startDate, endDate),
    {
      enabled: !!branchId,
    },
  );
