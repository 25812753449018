import { ReactNode } from 'react';

import { clsxMerge } from '../utils';

type Props = {
  title: string;
  subtitle?: string | ReactNode;
  headerClass?: string;
};

function FormPageHeader(props: Props) {
  const { title, subtitle, headerClass } = props;

  return (
    <div className={clsxMerge('flex justify-between', headerClass)}>
      <h4 className="text-xl font-semibold md:py-2 md:text-2xl">{title}</h4>
      {subtitle && <div className="text-sm text-neutral-500">{subtitle}</div>}
    </div>
  );
}

export default FormPageHeader;
