import { useFormContext } from 'react-hook-form';

import {
  TextInput,
  ProviderFormEnum,
  MaskedTextInput,
  REGISTRATION_CHARACTER_LIMIT,
} from '../../..';
import { AddProviderType } from '../ProviderProfile';

const ProviderUserDetails = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<AddProviderType>();

  return (
    <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
      <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
        Super Admin User Information
      </span>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-6">
        <TextInput
          labelShown
          showRequiredOnLabel
          {...register(ProviderFormEnum.USER_PROVIDER_FIRST_NAME)}
          errorMessage={errors?.userProvider?.firstName?.message}
          label="First Name"
          placeholder="First Name"
        />
        <TextInput
          labelShown
          showRequiredOnLabel
          {...register(ProviderFormEnum.USER_PROVIDER_LAST_NAME)}
          errorMessage={errors?.userProvider?.lastName?.message}
          label="Last Name"
          placeholder="Last Name"
        />
      </div>
      <TextInput
        labelShown
        showRequiredOnLabel
        {...register(ProviderFormEnum.USER_PROVIDER_EMAIL)}
        errorMessage={errors?.userProvider?.email?.message}
        label="Email"
        placeholder="Email"
      />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-6">
        <MaskedTextInput
          {...register(ProviderFormEnum.USER_PROVIDER_PASSWORD)}
          labelShown
          showRequiredOnLabel
          label="Password"
          placeholder="Enter password"
          maxLength={REGISTRATION_CHARACTER_LIMIT.PASSWORD}
          errorMessage={errors?.userProvider?.password?.message}
        />
        <MaskedTextInput
          {...register(ProviderFormEnum.USER_PROVIDER_CONFIRM_PASSWORD)}
          labelShown
          showRequiredOnLabel
          label="Confirm Password"
          placeholder="Enter password"
          maxLength={REGISTRATION_CHARACTER_LIMIT.PASSWORD}
          errorMessage={errors?.userProvider?.confirmPassword?.message}
        />
      </div>
    </div>
  );
};

export default ProviderUserDetails;
