import { ReactNode } from 'react';

import get from 'lodash.get';
import { twMerge } from 'tailwind-merge';

import LoadingSpinner from './LoadingSpinner';

export type TableProps<T> = {
  actionBodyClassName?: string;
  actionHeaderClassName?: string;
  columns: TableColumn<T>[];
  containerClassName?: string;
  tableClassName?: string;
  tableHeadClassName?: string;
  data?: T[];
  hasPrimaryIndex?: boolean;
  headerClassName?: string;
  isLoading?: boolean;
  isMultiSelect?: boolean;
  selectedRows?: T[];
  noDataText?: string;
  page?: number;
  recordPerPage?: number;
  shouldShowRowCount?: boolean;
  shouldForceOverflow?: boolean;
  onRowClick?: (data: T) => void;
  onRowSelectClick?: (data: T) => void;
  onRowDeselectClick?: (data: T) => void;
  onSelectAllClick?: (data: T[]) => void;
};

export type TableColumn<T> = {
  header: ReactNode;
  key: keyof T | string;
  headerClassName?: string;
  className?: string;
  render?: (data: T, index: number) => ReactNode;
  isColumnHidden?: boolean;
};

function Table<T extends { id: string | number; isSelectEnabled?: boolean }>(props: TableProps<T>) {
  const {
    columns = [],
    containerClassName,
    tableClassName,
    tableHeadClassName,
    data = [],
    hasPrimaryIndex = true,
    headerClassName,
    isLoading,
    isMultiSelect = false,
    selectedRows = [],
    noDataText = 'No data',
    page = 1,
    recordPerPage = 25,
    shouldShowRowCount = false,
    shouldForceOverflow,
    onRowClick,
    onRowSelectClick,
    onRowDeselectClick,
    onSelectAllClick,
  } = props;

  const enabledSelectRows = data.filter((row) => row.isSelectEnabled);
  const selectedRowsIds = selectedRows
    .filter((row) => row.id.toString())
    .map((row) => row.id.toString());
  const hasRowOnClick = Boolean(onRowClick);
  const columnsLength = columns.length;
  const filteredColumns = columns.filter((column) => !column.isColumnHidden);
  const isEmpty = data.length === 0;
  const renderHeaders = filteredColumns.map((column, index) => (
    <th
      key={column.key.toString()}
      scope="col"
      className={twMerge(
        'whitespace-nowrap px-3 py-[14px] text-left text-xs font-medium leading-[16px] text-neutral-500',
        index === columnsLength - 1 && 'text-end',
        headerClassName,
        column.headerClassName,
      )}
    >
      {column.header}
    </th>
  ));

  const renderRows = data.map((row, idx) => (
    <tr
      key={row.id.toString()}
      onClick={() => onRowClick?.(row)}
      className={twMerge('cursor-pointer hover:bg-blue-50')}
    >
      {isMultiSelect && (
        <td className="px-3 py-4 align-top">
          <input
            type="checkbox"
            className="rounded-sm border border-gray-400 text-primary-400 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
            checked={selectedRowsIds.includes(row.id.toString())}
            disabled={!row.isSelectEnabled}
            onChange={(e) =>
              e.target.checked && !selectedRowsIds.includes(row.id.toString())
                ? onRowSelectClick?.(row)
                : onRowDeselectClick?.(row)
            }
          />
        </td>
      )}
      {shouldShowRowCount && (
        <td className="px-3 py-4 align-top font-normal text-graniteGray">
          {(page - 1) * recordPerPage + idx + 1}
        </td>
      )}
      {filteredColumns.map((column, index) => (
        <td
          key={`${row.id.toString()}-${column.key.toString()}`}
          className={twMerge('px-3 py-4 align-top', column.className)}
        >
          <div
            className={twMerge(
              'text-md flex font-normal tracking-[0.2px] text-neutral-500',
              index === columnsLength - 1 && 'justify-end',
              index === 0 && hasPrimaryIndex && 'font-semibold text-neutral-500',
            )}
          >
            {column?.render?.(row, idx) ?? get(row, column.key)}
          </div>
        </td>
      ))}
    </tr>
  ));

  const renderLoading = (
    <tr>
      <td
        colSpan={columnsLength}
        className="py-4 pl-4 pr-3 text-center text-sm font-medium text-neutral-500"
      >
        <div className="flex items-center justify-center">
          <LoadingSpinner />
        </div>
      </td>
    </tr>
  );

  const renderEmptyPlaceholder = (
    <tr>
      <td
        colSpan={columnsLength}
        className="px-3 py-4 text-center text-sm font-medium text-neutral-500"
      >
        {noDataText}
      </td>
    </tr>
  );

  const renderBody = isLoading ? renderLoading : isEmpty ? renderEmptyPlaceholder : renderRows;

  return (
    <div
      className={twMerge(
        'border-1 rounded-lg border border-chineseWhite bg-white',
        !shouldForceOverflow && 'overflow-auto',
        containerClassName,
      )}
    >
      <table
        className={twMerge(
          'w-full divide-y divide-gray-200 rounded-md border text-sm',
          shouldForceOverflow ? 'overflow-visible' : 'overflow-hidden',
          tableClassName,
        )}
      >
        <thead
          className={twMerge(
            'top-0 uppercase',
            !shouldForceOverflow && 'sticky',
            tableHeadClassName,
          )}
        >
          <tr>
            {isMultiSelect && (
              <th className="justify-left flex p-3">
                <input
                  type="checkbox"
                  className="rounded-sm border border-gray-400 text-primary-400 focus:ring-0"
                  checked={
                    !!enabledSelectRows.length &&
                    enabledSelectRows.every((d) => selectedRowsIds.includes(d.id.toString()))
                  }
                  onChange={(e) => onSelectAllClick?.(e.target.checked ? enabledSelectRows : [])}
                />
              </th>
            )}
            {shouldShowRowCount && <th />}
            {renderHeaders}
          </tr>
        </thead>
        <tbody className="divide-y divide-chineseWhite">{renderBody}</tbody>
      </table>
    </div>
  );
}

export default Table;
