import { useState, useEffect } from 'react';

import { StaticImageData } from 'next/image';

export function useFallbackProfileImage(
  defaultImage: StaticImageData,
  profileImageUrl?: string | null,
) {
  const [profileImage, setProfileImage] = useState<StaticImageData | string>(defaultImage);

  useEffect(() => {
    setProfileImage(profileImageUrl ?? defaultImage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImageUrl]);

  return [profileImage, setProfileImage] as const;
}
