import { useState, useEffect } from 'react';

import { useRouter } from '..';

export function useQueryParamState(
  paramKey: string,
  initialState = '',
  options: { isShallow: boolean } = {
    isShallow: false,
  },
): [string | undefined, (value?: string) => void] {
  const router = useRouter();

  const getValueFromQuery = (): string => {
    return (router.query[paramKey] as string) || initialState;
  };

  const [state, setState] = useState<string | undefined>(getValueFromQuery);

  useEffect(() => {
    const handleRouteChange = () => {
      setState(getValueFromQuery());
    };

    handleRouteChange();
  }, [router.query]);

  const setQueryParamState = (value?: string) => {
    setState(value);

    const updatedQuery = {
      ...router.query,
    };

    if (value) {
      updatedQuery[paramKey] = value;
    } else {
      delete updatedQuery[paramKey]; // Remove the key if value is empty or undefined
    }

    if (options.isShallow) {
      return router.push({ pathname: router.pathname, query: updatedQuery }, undefined, {
        shallow: true,
      });
    }

    router.push({ pathname: router.pathname, query: updatedQuery });
  };

  return [state, setQueryParamState];
}
