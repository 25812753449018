import { useQuery } from '@tanstack/react-query';

import { QuerySchedulesDto, getSchedules } from '../../api';
import { GET_LABORATORY_SCHEDULE } from '../../constants';

export const useGetSchedules = (querySchedulesDto?: QuerySchedulesDto, shouldEnable = true) => {
  return useQuery(
    [
      GET_LABORATORY_SCHEDULE,
      querySchedulesDto?.includeProviderServiceSchedule,
      querySchedulesDto?.providerServiceIds,
      querySchedulesDto?.startDate,
      querySchedulesDto?.endDate,
      querySchedulesDto?.selectedDate,
      querySchedulesDto?.day,
      querySchedulesDto?.isSpecificDate,
      querySchedulesDto?.isBlocked,
      querySchedulesDto?.isRegularSchedule,
      querySchedulesDto?.providerId,
      querySchedulesDto?.month,
    ],
    () => getSchedules(querySchedulesDto),
    {
      keepPreviousData: true, // avoid flickering
      enabled: shouldEnable && !!querySchedulesDto?.providerId,
    },
  );
};
