export enum SpecialistTypeEnum {
  Doctor,
  Pharmacist,
  Laboratory,
}

export type SelectedLabService = {
  id: number;
  name: string;
  price: number;
  schedule?: Date;
  appointmentType?: string;
};

export type AppointmentDetailsData = {
  hmo: {
    provider: number;
    idNumber: string;
    type: string;
    filename: string;
    fileUrl: string;
    fileMimeType: string;
  };
  appointmentNotes: string | null;
  philhealth: { idNumber?: number; konsultaNumber: string };
  pwdIdNumber: string;
  reason: string | undefined;
  seniorCitizenIdNumber: string;
};

export type Slot = {
  id: number;
  startTime: string;
  remainingSlots: number;
};

export type FullSlot = {
  id: number;
  remainingSlots: number;
  startMilitaryTime: string;
  startTime: string;
};

export type SelectedDateSchedule = {
  date: Date;
  timeSlots: FullSlot[];
};
