import {
  CreateServiceScheduleDto,
  ProviderServiceSchedule,
  ProviderServiceSchedulesApi,
  UpdateServiceScheduleDto,
} from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';

export const serviceScheduleApi = HealthHubApiClient.use(ProviderServiceSchedulesApi);

export type UpdateServiceScheduleParams = {
  id: string;
  updateServiceScheduleDto: UpdateServiceScheduleDto;
};

export type DeleteServiceScheduleParams = {
  id: string;
};

export type CheckServiceScheduleExistenceParams = {
  providerServiceIds: number[];
  branchIds: number[];
  days: string[];
};

export async function createServiceSchedule(
  createServiceScheduleDto: CreateServiceScheduleDto,
): Promise<void> {
  try {
    await serviceScheduleApi.providerServiceScheduleControllerCreateOrReplace(
      createServiceScheduleDto,
    );
  } catch (error) {
    throw new Error('Failed to create service schedule');
  }
}

export async function checkServiceScheduleExistence({
  providerServiceIds,
  branchIds,
  days,
}: CheckServiceScheduleExistenceParams): Promise<boolean> {
  try {
    const { data } = await serviceScheduleApi.providerServiceScheduleControllerCheckExistence(
      providerServiceIds,
      branchIds,
      days,
    );
    return data;
  } catch (error) {
    throw new Error('Failed to check service schedule existence');
  }
}

export async function getServiceSchedule(
  providerId: string,
  branchId?: string,
  search?: string,
): Promise<ProviderServiceSchedule[]> {
  try {
    const { data } = await serviceScheduleApi.providerServiceScheduleControllerFind(
      providerId,
      branchId,
      search,
    );
    return data;
  } catch (error) {
    throw new Error('Failed to get service schedules');
  }
}

export async function getServiceScheduleById(id: string): Promise<ProviderServiceSchedule> {
  try {
    const { data } = await serviceScheduleApi.providerServiceScheduleControllerFindOne(id);
    return data;
  } catch (error) {
    throw new Error('Failed to get service schedule by ID');
  }
}

export async function updateServiceSchedule({
  id,
  updateServiceScheduleDto,
}: UpdateServiceScheduleParams): Promise<void> {
  try {
    const data = {
      brandId: updateServiceScheduleDto.brandId,
      branchIds: updateServiceScheduleDto.branchIds,
      branchSelectionType: updateServiceScheduleDto.branchSelectionType,
      slots: updateServiceScheduleDto.slots,
      timeSlotIntervalInMinutes: updateServiceScheduleDto.timeSlotIntervalInMinutes,
      timeSlots: updateServiceScheduleDto.timeSlots,
    };
    await serviceScheduleApi.providerServiceScheduleControllerUpdate(id, data);
  } catch (error) {
    throw new Error('Failed to update service schedule');
  }
}

export async function deleteServiceSchedule({ id }: DeleteServiceScheduleParams): Promise<void> {
  try {
    await serviceScheduleApi.providerServiceScheduleControllerDelete(id);
  } catch (error) {
    throw new Error('Failed to delete service schedule');
  }
}

export async function getServiceSchedulesByProviderServiceId(
  id: string,
): Promise<ProviderServiceSchedule[]> {
  try {
    const { data } =
      await serviceScheduleApi.providerServiceScheduleControllerFindAllByProviderServiceId(id);

    return data;
  } catch (error) {
    throw new Error('Failed to retrive schedules');
  }
}
