import clsx from 'clsx';

import noResultsFound from '../images/no-results-found.svg';

type Props = {
  className?: string;
};

const NoResultsFound = (props: Props) => {
  const { className } = props;

  return (
    <div className={clsx('flex flex-col items-center', className)}>
      <div className="mb-5 flex h-[152px] w-[152px] items-center justify-center rounded-full bg-gray7">
        <img src={noResultsFound} alt="No results found" />
      </div>
      <p className="mb-3 text-xl font-bold text-primary1">No results found</p>
      <p className="text-sm text-gray3">
        It seems that we can't find any results based on your search.
      </p>
    </div>
  );
};

export default NoResultsFound;
