import { CreateApiFieldsDto, EshopApi, UpdateApiFieldsDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { ApiFields } from '../types';

export const apiFieldsApi = HealthHubApiClient.use(EshopApi);

export async function createApiField(createApiField: CreateApiFieldsDto): Promise<ApiFields> {
  try {
    const response = await apiFieldsApi.eshopControllerCreateApiField(createApiField);
    const data = response.data as ApiFields;

    return data;
  } catch (error) {
    throw new Error('Failed to create api field');
  }
}

export async function getApiFields(): Promise<ApiFields[]> {
  try {
    const response = await apiFieldsApi.eshopControllerGetApiFields();
    const data = response.data as ApiFields[];

    return data;
  } catch (error) {
    throw new Error('Failed to fetch api fields');
  }
}

export async function getApiFieldById(id: string, config?: AxiosRequestConfig): Promise<ApiFields> {
  try {
    const response = await apiFieldsApi.eshopControllerGetApiField(id, config);
    const data = response.data as ApiFields;

    return data;
  } catch (error) {
    throw new Error('Failed to fetch api field');
  }
}

export async function updateApiField(
  id: string,
  updateApiField: UpdateApiFieldsDto,
): Promise<ApiFields> {
  try {
    const response = await apiFieldsApi.eshopControllerUpdateApiField(id, updateApiField);
    const data = response.data as ApiFields;

    return data;
  } catch (error) {
    throw new Error('Failed to update api field');
  }
}

export async function removeApiField(id: string): Promise<void> {
  try {
    await apiFieldsApi.eshopControllerDeleteApiField(id);
  } catch (error) {
    throw new Error('Failed to remove api field');
  }
}
