export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DAYS_OF_WEEK_VALUE = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const MINUTES_IN_HOUR = 60;

export const MAX_HOUR = 5;

export const EIGHT_HOURS = 28800; // 8 hours in seconds

export const COOKIE_MAX_AGE = EIGHT_HOURS;

export const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

export type TimeIntervalType = {
  label: string;
  valueInMinutes: number;
};

export const timeIntervals: TimeIntervalType[] = [
  {
    label: 'every 10 minutes',
    valueInMinutes: 10,
  },
  {
    label: 'every 15 minutes',
    valueInMinutes: 15,
  },
  {
    label: 'every 30 minutes',
    valueInMinutes: 30,
  },
  {
    label: 'every 1 hour',
    valueInMinutes: 60,
  },
  {
    label: 'every 2 hours',
    valueInMinutes: 120,
  },
];
