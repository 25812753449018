import clsx from 'clsx';

type Props = {
  hasBorderTop?: boolean;
};

const Footer = (props: Props) => {
  const { hasBorderTop } = props;

  return (
    <footer
      className={clsx(
        'mt-auto px-2 pb-8 pt-5 md:px-20 md:pb-20',
        hasBorderTop && 'border-t-[1px] border-t-gray33',
      )}
      id="footer"
    />
  );
};

export default Footer;
