import { forwardRef, HTMLProps, ReactNode, Ref, useId } from 'react';

import { clsxMerge } from '@mwell-healthhub/commons';

export interface CheckboxProps
  extends Omit<HTMLProps<HTMLInputElement>, 'type' | 'label' | 'defaultValue'> {
  label?: ReactNode;
  labelClassName?: string;
  containerClassName?: string;
}

function Checkbox(props: CheckboxProps, forwardedRef?: Ref<HTMLInputElement>) {
  const {
    label,
    id: idProp,
    defaultValue: _,
    className,
    disabled,
    containerClassName,
    labelClassName,
    ...etcProps
  } = props as CheckboxProps & { defaultValue: unknown };
  const defaultId = useId();
  const id = idProp ?? defaultId;
  return (
    <div className={clsxMerge('relative flex items-center', containerClassName)}>
      <div className="flex h-6 items-center">
        <input
          {...etcProps}
          id={id}
          ref={forwardedRef}
          type="checkbox"
          disabled={disabled}
          className={clsxMerge(
            'h-4 w-4 appearance-none rounded-2sm text-primary1 focus:ring-primary1',
            className,
          )}
        />
      </div>
      {label && (
        <div className="ml-3 text-sm leading-6">
          <label htmlFor={id} className={clsxMerge('font-normal text-gray-900', labelClassName)}>
            {label}
          </label>
        </div>
      )}
    </div>
  );
}

export default forwardRef(Checkbox);
