import clsx from 'clsx';

import Button from '../../components/Button/Button';

type PaginationPropsType = {
  onPageChange: (page: number) => void;
  page: number;
  totalItems: number;
  perPage: number;
  isLoading?: boolean;
  isLastPage?: boolean;
  containerClass?: string;
};

const Pagination = (props: PaginationPropsType): JSX.Element => {
  const { onPageChange, containerClass, isLoading, isLastPage, perPage, page, totalItems } = props;

  const from = totalItems > 0 ? (page - 1) * perPage + 1 : 0;
  const to = page * perPage > totalItems ? totalItems : page * perPage;
  const totalPage = Math.ceil(totalItems / perPage);

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const handlePreviousPage = () => {
    if (page <= 1) return;
    handlePageChange(page - 1);
  };

  const handleNextPage = () => {
    if (page >= totalPage) return;
    handlePageChange(page + 1);
  };

  return (
    <nav
      className={clsx('flex items-center justify-between', containerClass)}
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{from}</span> to{' '}
          <span className="font-medium">{to}</span> of{' '}
          <span className="font-medium">{totalItems}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        {page > 1 ? (
          <Button
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            onClick={handlePreviousPage}
            disabled={page <= 1 || isLoading}
          >
            Previous
          </Button>
        ) : (
          <div />
        )}
        {!isLastPage && (
          <Button
            className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:border-none"
            onClick={handleNextPage}
            disabled={isLastPage || isLoading || totalItems <= to}
          >
            Next
          </Button>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
