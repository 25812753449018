import React from 'react';

import { ChevronRightIcon } from '@heroicons/react/20/solid';

import UserIconCircle from './UserIconCircle';

type Props = {
  onClick: () => void;
};

const ClientAddListItem = ({ onClick }: Props) => (
  <div
    className="flex cursor-pointer items-center justify-between rounded-lg border border-gray39 p-3"
    onClick={onClick}
  >
    <div className="flex items-center gap-3">
      <UserIconCircle bgColor="bg-lightCyan" textColor="text-cobalt" />
      <h1 className="text-sm">Add New Family & Relatives</h1>
    </div>
    <ChevronRightIcon height={20} width={20} />
  </div>
);

export default ClientAddListItem;
