/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';

import { IMAGE_FILE_EXTENSIONS } from '../../constants';
import { getExtensionByFileUrl } from '../../utils';

type Props = {
  fileUrl: string;
};

// Will only work for Admin and Specialist
export function ImageExtension(props: Readonly<Props>) {
  const { fileUrl } = props;
  const [contentType, setContentType] = useState<string>();
  const hasExtension = IMAGE_FILE_EXTENSIONS.some((fileExt) => fileUrl.includes(fileExt));

  useEffect(() => {
    if (!fileUrl || hasExtension) {
      return;
    }

    const newUrl = `${window.location.origin}/api/download?url=${encodeURIComponent(fileUrl)}`;

    getExtensionByFileUrl(newUrl).then(setContentType);
  }, [fileUrl]);

  if (hasExtension || !contentType) {
    return null;
  }

  return <>{contentType}</>;
}
