import { Provider } from '@healthhub/api-lib';

import CopyInput from './CopyInput';
import LoadingSpinner from './LoadingSpinner';
import Button from './v2/Button';
import { useGetProviderShareLinks } from '../hooks';

type Props = {
  provider: Provider;
  showTitle?: boolean;
};

export default function ProviderShareLinks(props: Readonly<Props>) {
  const { provider, showTitle } = props;

  const { data, isLoading } = useGetProviderShareLinks(provider?.slugName);

  const providerUrl = data?.providerUrl || '';
  const registrationUrl = data?.registrationUrl || '';
  const qrCodeBase64 = data?.qrCodeBase64 || '';

  const handleDownloadClick = () => {
    const anchor = document.createElement('a');
    (anchor as any).href = qrCodeBase64;
    anchor.download = `${provider?.displayName} QR Code.png`;
    anchor.click();
  };

  if (isLoading) {
    return (
      <div className="mt-8 flex w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-7 md:p-8">
      {showTitle && (
        <span className="block text-lg font-semibold leading-6 text-gray-900">Share Links</span>
      )}
      <div className="flex flex-col gap-2">
        <h2 className="text-sm text-neutral-600">Registration Form</h2>
        <CopyInput value={registrationUrl} />
      </div>
      <div className="flex flex-col gap-2">
        <h2 className="text-sm text-neutral-600">HealthHub Page</h2>
        <CopyInput value={providerUrl} />
      </div>
      <div>
        <h2 className="mb-2 text-sm">Registration Form QR Code</h2>
        <div className="flex-column flex items-center gap-2">
          <div className="inline-flex h-28 w-28 items-center justify-center rounded-lg border border-neutral-300 p-1">
            <img src={qrCodeBase64} alt="Registration Form QR Code" width={160} height={160} />
          </div>
          <Button variant="tertiary" onClick={handleDownloadClick} leftIcon="downTray">
            Download
          </Button>
        </div>
      </div>
    </div>
  );
}
