import { useMutation } from '@tanstack/react-query';

import { UseMutationOptionsType } from '@mwell-healthhub/commons';

import {
  createPatientHmo,
  deleteSavedPatientHmo,
  validatePatientHmo,
} from '../../services/hmoService';

export const useCreatePatientHmo = (mutationOptions: UseMutationOptionsType) => {
  return useMutation(createPatientHmo, mutationOptions);
};

export const useValidatePatientHmo = (mutationOptions: UseMutationOptionsType) => {
  return useMutation(validatePatientHmo, mutationOptions);
};

export const useDeleteSavedPatientHmo = (mutationOptions: UseMutationOptionsType) => {
  return useMutation(deleteSavedPatientHmo, mutationOptions);
};
