import React, { useEffect, useState } from 'react';
import { classNames } from '../..';

type Props = {
  leftLabel: string;
  rightLabel: string;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  isLeftDisabled?: boolean;
  isRightDisabled?: boolean;
};

const SwitchButton: React.FC<Props> = ({
  leftLabel,
  rightLabel,
  onLeftClick,
  onRightClick,
  isRightDisabled,
  isLeftDisabled,
}) => {
  const [isLeftActive, setIsLeftActive] = useState(true);

  useEffect(() => {
    if (isLeftDisabled) {
      setIsLeftActive(false);
    } else {
      setIsLeftActive(true);
    }
  }, [isRightDisabled, isLeftDisabled]);

  const handleLeftClick = () => {
    setIsLeftActive(true);
    onLeftClick?.();
  };

  const handleRightClick = () => {
    setIsLeftActive(false);
    onRightClick?.();
  };

  return (
    <div className="flex items-center justify-between rounded-lg bg-gray8 p-2">
      <button
        disabled={isLeftDisabled}
        className={classNames(
          'rounded-lg rounded-l px-3 py-2 disabled:cursor-not-allowed',
          isLeftActive ? 'bg-lightPrimary text-black' : 'bg-transparent text-black',
        )}
        onClick={handleLeftClick}
      >
        {leftLabel}
      </button>
      <button
        disabled={isRightDisabled}
        className={classNames(
          'rounded-lg rounded-r px-3 py-2 disabled:cursor-not-allowed',
          isLeftActive ? 'bg-transparent text-black' : 'bg-lightPrimary text-black',
        )}
        onClick={handleRightClick}
      >
        {rightLabel}
      </button>
    </div>
  );
};

export default SwitchButton;
