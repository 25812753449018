import { CreateApiFieldsDto, UpdateApiFieldsDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createApiField, removeApiField, updateApiField } from '../../api';
import { ApiFields } from '../../types';

export const useCreateApiFieldMutation = (
  mutationOptions?: MutationOptions<
    ApiFields,
    AxiosError<{ message: string }>,
    { createApiFieldDto: CreateApiFieldsDto }
  >,
) => {
  return useMutation((params) => createApiField(params.createApiFieldDto), mutationOptions);
};

export const useUpdateApiFieldsMutation = (
  mutationOptions?: MutationOptions<
    ApiFields,
    AxiosError<{ message: string }>,
    { id: string; updateApiFieldDto: UpdateApiFieldsDto }
  >,
) => {
  return useMutation(
    (params) => updateApiField(params.id, params.updateApiFieldDto),
    mutationOptions,
  );
};

export const useDeleteApiFieldMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, { id: string }>,
) => {
  return useMutation((params) => removeApiField(params.id), mutationOptions);
};
