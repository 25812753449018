import { createContext, useEffect, useReducer } from 'react';

import {
  ClientProfile,
  Provider,
  ProviderService,
  ProviderServiceSettings,
} from '@healthhub/api-lib';
import { isBefore } from 'date-fns';

import {
  AppointmentCustomFieldMetadata,
  FullSlot,
  Option,
  ProviderServiceWithDiscount,
  SHOW_CUSTOMIZED_SCHEDULE,
  Slot,
  getCurrentPhilippineDateTime,
  getLocalStorageItem,
  setLocalStorageItem,
} from '@mwell-healthhub/commons';

import { BOOKING_STEPS } from '../constants';

type State = {
  appointmentId: number | null;
  appointmentIds: string[];
  clientProfileId: string;
  clientProfileIds: number[];
  clinic: Provider | null;
  currentStep: number;
  date: string | null;
  isBookingCompleted: boolean;
  paymentStatus: BookingPaymentStatusEnum;
  isModifyingAppointmentDateTime: boolean;
  isModifyingServices: boolean;
  isReschedule: boolean;
  optionalInformation: string;
  scheduleId: number | null;
  service: ProviderServiceWithDiscount | null;
  services: ProviderServiceWithDiscount[];
  timeSlot: Slot | null;
  totalAmount: number;
  wellnessPassCode: string;
  currentHMOClientProfileIndex: number;
  hmoDetails: HMODetails[];
  hmoAccountNumber: string;
  hmoCardUrl: string;
  hmoLetterOfAuthorizationUrl: string;
  hmoSelected: Option | null;
  hmoValidIdUrl: string;
  otherConcern: string;
  serviceSettings: ProviderServiceSettings | null;
  bookingFee: number;
  reservationFee: number;
  paymentDetails: BookingPaymentDetailsPropValues | null;
  proofOfPaymentUrl: string;
  diagnosticFile: File | null;
  customFields: AppointmentCustomFieldMetadata[];
  hasServicesWithCustomSchedule: boolean;
  customSchedules: Record<number, ScheduleItem>;
  providerServiceCategories: Option[];
};

type Action = {
  payload: any;
  type: string;
};

type ProviderContextProviderProps = {
  children: React.ReactNode;
};

export enum BookingPaymentStatusEnum {
  DEFAULT = '',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type BookingPaymentDetailsPropValues = {
  transactionDate?: string;
  totalAmountDue?: number;
  paymentStatus?: string;
  paymentDate?: string;
  transactionNo?: string;
  dueDate?: string;
  paymentMethod?: string;
};

export type HMODetails = {
  hmoAccountNumber: string;
  hmoCardUrl: string;
  hmoLetterOfAuthorizationUrl: string;
  hmoSelected: Option | null;
  hmoValidIdUrl: string;
  clientProfile: ClientProfile;
};

export type ScheduleItem = {
  slot: FullSlot;
  scheduleId: number;
  date: string;
  serviceId: number;
};

export const providerSessionStorageKey = 'providerContextState';

export const providerInitialState = {
  appointmentId: null,
  appointmentIds: [],
  clientProfileId: '',
  clientProfileIds: [],
  clinic: null,
  currentStep: 1,
  date: null,
  currentHMOClientProfileIndex: 0,
  hmoDetails: [],
  hmoAccountNumber: '',
  hmoCardUrl: '',
  hmoLetterOfAuthorizationUrl: '',
  hmoSelected: null,
  hmoValidIdUrl: '',
  isBookingCompleted: false,
  paymentStatus: BookingPaymentStatusEnum.DEFAULT,
  isModifyingAppointmentDateTime: false,
  isModifyingServices: false,
  isReschedule: false,
  optionalInformation: '',
  scheduleId: null,
  service: null,
  services: [],
  timeSlot: null,
  totalAmount: 0,
  wellnessPassCode: '',
  otherConcern: '',
  serviceSettings: null,
  bookingFee: 0,
  reservationFee: 0,
  paymentDetails: null,
  proofOfPaymentUrl: '',
  diagnosticFile: null,
  customFields: [],
  hasServicesWithCustomSchedule: false,
  customSchedules: {},
  providerServiceCategories: [],
};

export const ProviderContextActions = {
  HARD_RESET: 'HARD_RESET',
  REMOVE_SERVICE: 'REMOVE_SERVICE',
  RESET_WITHOUT_STEP_CHANGE: 'RESET_WITHOUT_STEP_CHANGE',
  CANCEL_APPOINTMENT: 'CANCEL_APPOINTMENT',
  RESET: 'RESET',
  SET_APPOINTMENT_ID: 'SET_APPOINTMENT_ID',
  SET_APPOINTMENT_IDS: 'SET_APPOINTMENT_IDS',
  SET_CLIENT_PROFILE_ID: 'SET_CLIENT_PROFILE_ID',
  SET_CLIENT_PROFILE_IDS: 'SET_CLIENT_PROFILE_IDS',
  SET_CLINIC: 'SET_CLINIC',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_DATE: 'SET_DATE',
  SET_IS_BOOKING_COMPLETED: 'SET_IS_BOOKING_COMPLETED',
  SET_IS_MODIFYING_SERVICES: 'SET_IS_MODIFYING_SERVICES',
  SET_IS_MODIFYING_APPOINTMENT_DATETIME: 'SET_IS_MODIFYING_APPOINTMENT_DATETIME',
  SET_IS_RESCHEDULE: 'SET_IS_RESCHEDULE',
  SET_OPTIONAL_INFORMATION: 'SET_OPTIONAL_INFORMATION',
  SET_WELLNESS_PASSCODE: 'SET_WELLNESS_PASSCODE',
  SET_SCHEDULE_ID: 'SET_SCHEDULE_ID',
  SET_SERVICE: 'SET_SERVICE',
  SET_SERVICES: 'SET_SERVICES',
  SET_TIME_SLOT: 'SET_TIME_SLOT',
  SET_TOTAL_AMOUNT: 'SET_TOTAL_AMOUNT',
  SET_HMO_DETAILS: 'SET_HMO_DETAILS',
  SET_OTHER_CONCERN: 'SET_OTHER_CONCERN',
  SET_PAYMENT_STATUS: 'SET_PAYMENT_STATUS',
  SET_SERVICE_SETTINGS: 'SET_SERVICE_SETTINGS',
  SET_BOOKING_FEE: 'SET_BOOKING_FEE',
  SET_RESERVATION_FEE: 'SET_RESERVATION_FEE',
  SET_PAYMENT_DETAILS_ITEM: 'SET_PAYMENT_DETAILS_ITEM',
  SET_PROOF_OF_PAYMENT: 'SET_PROOF_OF_PAYMENT',
  SET_DIAGNOSTIC_FILE: 'SET_DIAGNOSTIC_FILE',
  ADD_TO_HMO_DETAILS_ARRAY: 'ADD_TO_HMO_DETAILS_ARRAY',
  INCREMENT_CURRENT_HMO_CLIENT_PROFILE_INDEX: 'INCREMENT_CURRENT_HMO_CLIENT_PROFILE_INDEX',
  RESET_HMO_CLIENT_PROFILE_INDEX: 'RESET_HMO_CLIENT_PROFILE_INDEX',
  REMOVE_HMO_CLIENT_PROFILE: 'REMOVE_HMO_CLIENT_PROFILE',
  SET_CUSTOM_FIELDS: 'SET_CUSTOM_FIELDS',
  ADD_CUSTOM_SCHEDULE: 'ADD_CUSTOM_SCHEDULE',
  SET_PROVIDER_SERVICE_CATEGORIES: 'SET_PROVIDER_SERVICE_CATEGORIES',
};

const removeService = (
  services: ProviderServiceWithDiscount[],
  service: ProviderServiceWithDiscount,
) => {
  return services.filter((s) => s.id !== service.id);
};

const getHasServicesWithCustomSchedule = (services: ProviderServiceWithDiscount[]) => {
  return services.some((service) => (service?.schedules || []).length > 0);
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ProviderContextActions.SET_SERVICES: {
      if (SHOW_CUSTOMIZED_SCHEDULE) {
        const selectedServices: ProviderService[] = action.payload;
        const hasServicesWithCustomSchedule = getHasServicesWithCustomSchedule(selectedServices);
        const shouldClearGroupBooking =
          hasServicesWithCustomSchedule && state.clientProfileIds.length > 1;

        const updatedState = {
          ...state,
          services: selectedServices,
          hasServicesWithCustomSchedule,
        };

        if (shouldClearGroupBooking) {
          return {
            ...updatedState,
            clientProfileIds: [state.clientProfileId],
            hmoDetails: [],
          };
        }

        return updatedState;
      }

      return {
        ...state,
        services: action.payload,
      };
    }
    case ProviderContextActions.SET_SERVICE:
      return {
        ...state,
        service: action.payload,
      };
    case ProviderContextActions.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case ProviderContextActions.SET_TIME_SLOT:
      return {
        ...state,
        timeSlot: action.payload,
      };
    case ProviderContextActions.SET_OPTIONAL_INFORMATION:
      return {
        ...state,
        optionalInformation: action.payload,
      };
    case ProviderContextActions.SET_WELLNESS_PASSCODE:
      return {
        ...state,
        wellnessPassCode: action.payload,
      };
    case ProviderContextActions.SET_CLINIC:
      return {
        ...state,
        clinic: action.payload,
      };
    case ProviderContextActions.REMOVE_SERVICE: {
      const updatedServices = removeService(state.services, action.payload);
      const hasServicesWithCustomSchedule = getHasServicesWithCustomSchedule(updatedServices);

      let partialState = {
        ...state,
        services: updatedServices,
        hasServicesWithCustomSchedule: getHasServicesWithCustomSchedule(updatedServices),
      };

      if (hasServicesWithCustomSchedule) {
        const updatedCustomSchedules = { ...(state.customSchedules || {}) };
        delete updatedCustomSchedules[action.payload.id];

        partialState = { ...partialState, customSchedules: updatedCustomSchedules };
      } else {
        // reset customSchedules if no services with custom schedules
        partialState = { ...partialState, customSchedules: {} };
      }

      // If other concern is removed
      if (action.payload.id === 0) {
        partialState = {
          ...partialState,
          otherConcern: '',
        };
      }

      return partialState;
    }

    case ProviderContextActions.SET_TOTAL_AMOUNT:
      return {
        ...state,
        totalAmount: action.payload,
      };
    case ProviderContextActions.SET_IS_BOOKING_COMPLETED:
      return {
        ...state,
        isBookingCompleted: action.payload,
      };
    case ProviderContextActions.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case ProviderContextActions.SET_IS_RESCHEDULE:
      return {
        ...state,
        isReschedule: action.payload,
      };
    case ProviderContextActions.SET_CLIENT_PROFILE_ID:
      return {
        ...state,
        clientProfileId: action.payload,
      };
    case ProviderContextActions.SET_CLIENT_PROFILE_IDS:
      return {
        ...state,
        clientProfileIds: action.payload.filter(Boolean),
      };
    case ProviderContextActions.SET_SCHEDULE_ID:
      return {
        ...state,
        scheduleId: action.payload,
      };
    case ProviderContextActions.SET_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: action.payload,
      };
    case ProviderContextActions.CANCEL_APPOINTMENT:
      return {
        ...providerInitialState,
        services: state.services,
      };
    case ProviderContextActions.RESET:
      return {
        ...providerInitialState,
        appointmentId: action.payload,
        clinic: state.clinic,
      };
    case ProviderContextActions.RESET_WITHOUT_STEP_CHANGE:
      return {
        ...providerInitialState,
        appointmentId: action.payload,
        clinic: state.clinic,
        currentStep: state.currentStep,
      };
    case ProviderContextActions.SET_IS_MODIFYING_SERVICES:
      return {
        ...state,
        isModifyingServices: action.payload,
      };
    case ProviderContextActions.SET_IS_MODIFYING_APPOINTMENT_DATETIME:
      return {
        ...state,
        isModifyingAppointmentDateTime: action.payload,
      };
    case ProviderContextActions.SET_HMO_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case ProviderContextActions.SET_OTHER_CONCERN:
      return {
        ...state,
        otherConcern: action.payload,
      };
    case ProviderContextActions.SET_APPOINTMENT_ID:
      return {
        ...state,
        appointmentId: action.payload,
      };
    case ProviderContextActions.SET_APPOINTMENT_IDS:
      return {
        ...state,
        appointmentIds: action.payload,
      };
    case ProviderContextActions.SET_SERVICE_SETTINGS:
      return {
        ...state,
        serviceSettings: action.payload,
      };
    case ProviderContextActions.SET_BOOKING_FEE:
      return {
        ...state,
        bookingFee: action.payload,
      };
    case ProviderContextActions.SET_RESERVATION_FEE:
      return {
        ...state,
        reservationFee: action.payload,
      };
    case ProviderContextActions.SET_PAYMENT_DETAILS_ITEM:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case ProviderContextActions.SET_PROOF_OF_PAYMENT:
      return {
        ...state,
        proofOfPaymentUrl: action.payload,
      };
    case ProviderContextActions.SET_DIAGNOSTIC_FILE:
      return {
        ...state,
        diagnosticFile: action.payload,
      };
    case ProviderContextActions.SET_CUSTOM_FIELDS:
      return {
        ...state,
        customFields: action.payload,
      };
    case ProviderContextActions.HARD_RESET:
      return providerInitialState;
    case ProviderContextActions.ADD_TO_HMO_DETAILS_ARRAY: {
      const existingClientProfileId = state.hmoDetails.findIndex(
        (detail) => detail.clientProfile.id === action.payload.clientProfile.id,
      );

      if (existingClientProfileId !== -1) {
        const updatedHmoDetails = [...state.hmoDetails];
        updatedHmoDetails[existingClientProfileId] = action.payload;

        return {
          ...state,
          hmoDetails: updatedHmoDetails,
        };
      } else {
        return {
          ...state,
          hmoDetails: [...state.hmoDetails, action.payload],
        };
      }
    }
    case ProviderContextActions.ADD_CUSTOM_SCHEDULE: {
      const { customSchedules } = state;

      const newSchedule = action.payload as ScheduleItem;

      if (!newSchedule.serviceId) {
        return state;
      }

      const updatedCustomSchedules = { ...customSchedules };

      let scheduleToUpdate = updatedCustomSchedules[newSchedule.serviceId];

      if (!scheduleToUpdate) {
        scheduleToUpdate = newSchedule;
      } else {
        scheduleToUpdate.date = newSchedule.date;
        scheduleToUpdate.scheduleId = newSchedule.scheduleId;
        scheduleToUpdate.slot = newSchedule.slot;
      }

      updatedCustomSchedules[newSchedule.serviceId] = scheduleToUpdate;

      return {
        ...state,
        scheduleId: newSchedule.scheduleId,
        date: newSchedule.date,
        customSchedules: updatedCustomSchedules,
      };
    }
    case ProviderContextActions.INCREMENT_CURRENT_HMO_CLIENT_PROFILE_INDEX:
      if (state.currentHMOClientProfileIndex < state.clientProfileIds.length - 1) {
        return {
          ...state,
          currentHMOClientProfileIndex: state.currentHMOClientProfileIndex + 1,
        };
      } else {
        return state;
      }
    case ProviderContextActions.RESET_HMO_CLIENT_PROFILE_INDEX:
      return {
        ...state,
        currentHMOClientProfileIndex: 0,
      };
    case ProviderContextActions.REMOVE_HMO_CLIENT_PROFILE:
      return {
        ...state,
        hmoDetails: state.hmoDetails.filter((detail) => detail.clientProfile.id !== action.payload),
      };
    case ProviderContextActions.SET_PROVIDER_SERVICE_CATEGORIES:
      return {
        ...state,
        providerServiceCategories: action.payload,
      };
    default:
      return state;
  }
};

export const ProviderContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: providerInitialState,
  dispatch: () => null,
});

export const ProviderContextProvider = (props: ProviderContextProviderProps) => {
  const { children } = props;

  const getState = () => {
    const storedState = getLocalStorageItem(providerSessionStorageKey) as State;

    return storedState ? storedState : providerInitialState;
  };

  const [state, dispatch] = useReducer(reducer, getState());

  useEffect(() => {
    setLocalStorageItem(providerSessionStorageKey, state);
  }, [state]);

  useEffect(() => {
    // Reset date and schedule if selected schedule already past
    if (state.date && isBefore(new Date(state.date), getCurrentPhilippineDateTime())) {
      dispatch({ type: ProviderContextActions.SET_DATE, payload: null });
      dispatch({ type: ProviderContextActions.SET_SCHEDULE_ID, payload: null });
      dispatch({
        payload: BOOKING_STEPS.APPOINTMENT_SCHEDULE,
        type: ProviderContextActions.SET_CURRENT_STEP,
      });
    }
  }, [state.date]);

  return (
    <ProviderContext.Provider value={{ state, dispatch }}>{children}</ProviderContext.Provider>
  );
};
