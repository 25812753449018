import { AppointmentStatusEnum } from '@healthhub/api-lib';

import { AppointmentListFilter } from '@mwell-healthhub/commons';

export const APPOINTMENT_FEE_IN_CENTS = 120000;
export const PROFESSIONAL_FEE_IN_CENTS = 20000;
export const APPOINTMENT_STATUS = {
  [AppointmentListFilter.All]: 'all',
  [AppointmentListFilter.Cancelled]: AppointmentStatusEnum.Cancelled,
  [AppointmentListFilter.OnGoing]: AppointmentStatusEnum.Ongoing,
  [AppointmentListFilter.Finished]: AppointmentStatusEnum.Completed,
  [AppointmentListFilter.Confirmed]: AppointmentStatusEnum.Confirmed,
  [AppointmentListFilter.Physical]: AppointmentListFilter.Physical,
  [AppointmentListFilter.TeleConsultation]: AppointmentListFilter.TeleConsultation,
  [AppointmentListFilter.Past_appointments]: AppointmentListFilter.Past_appointments,
};

export const CANCEL_REASONS = [
  {
    id: 1,
    label: 'Transportation issues',
    hasAddtlInfo: false,
  },
  {
    id: 2,
    label: 'Illness or medical emergencies',
    hasAddtlInfo: false,
  },
  {
    id: 3,
    label: 'I feel better now',
    hasAddtlInfo: false,
  },
  {
    id: 4,
    label: 'Other reasons',
    hasAddtlInfo: true,
  },
];

export const BOOKING_STEPS = {
  PROVIDER_SERVICE_LIST: 1,
  OTHER_CONCERN: 2,
  APPOINTMENT_SCHEDULE: 3,
  REVIEW: 4,
  PAYMENT: 5,
  CONFIRMED: 6,
  APPOINTMENT_DETAILS: 7,
  TRANSACTION_ITEM_DETAILS: 8,
  CANCEL: 9,
  SELECT_CLIENT: 10,
  ADD_HMO: 11,
  PAYMENT_PENDING: 12,
  MANAGE_CLIENT: 13,
};

export const MPH_BOOKING_STEPS = {
  PROVIDER_SERVICE_LIST: 1,
  OTHER_CONCERN: 2,
  APPOINTMENT_SCHEDULE: 3,
  REVIEW: 4,
  PAYMENT: 5,
  CONFIRMED: 6,
  APPOINTMENT_DETAILS: 7,
  TRANSACTION_ITEM_DETAILS: 8,
  CANCEL: 9,
  SELECT_CLIENT: 10,
  ADD_HMO: 11,
  PAYMENT_PENDING: 12,
};

export const BOOKING_FLOW_AUTHENTICATED_ROUTES = [
  BOOKING_STEPS.APPOINTMENT_SCHEDULE,
  BOOKING_STEPS.REVIEW,
  BOOKING_STEPS.PAYMENT,
  BOOKING_STEPS.SELECT_CLIENT,
];
