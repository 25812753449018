import { AnnouncementApi, CreateAnnouncementDto, UpdateAnnouncementDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';
import { DateObject } from 'react-multi-date-picker';

import HealthHubApiClient from './api-client';
import { Option } from '../types';

const announcementApi = HealthHubApiClient.use(AnnouncementApi);

export type UpdateAnnouncementParam = {
  id: string;
  updateAnnouncementDto: UpdateAnnouncementDto;
};

export type AnnouncementFiltersParam = {
  searchTerm: string;
  dateRange: DateObject[];
  target?: Option;
};

export const createAnnouncement = async (createAnnouncementDto: CreateAnnouncementDto) => {
  try {
    const { data } = await announcementApi.announcementControllerCreate(createAnnouncementDto);
    return data;
  } catch (error) {
    throw new Error('Failed to create announcement');
  }
};

export const getAllAnnouncements = async (filters?: AnnouncementFiltersParam) => {
  try {
    const transformedFilters = {
      searchTerm: filters?.searchTerm,
      dateRangeStart: filters?.dateRange[0]?.format(),
      dateRangeEnd: filters?.dateRange[1]?.format(),
      target: filters?.target?.value,
    };

    const { data } = await announcementApi.announcementControllerFindAll(
      transformedFilters.searchTerm ?? '',
      transformedFilters.dateRangeStart ?? '',
      transformedFilters.dateRangeEnd ?? '',
      transformedFilters.target ?? '',
    );

    return data;
  } catch (error) {
    throw new Error('Failed to get announcements');
  }
};

export const getAnnouncement = async (id: string) => {
  try {
    const { data } = await announcementApi.announcementControllerFindOne(id);
    return data;
  } catch (error) {
    throw new Error('Failed to get announcement');
  }
};

export const getAnnouncementById = async (id: string, config?: AxiosRequestConfig) => {
  try {
    const { data } = await announcementApi.announcementControllerFindOne(id, config);
    return data;
  } catch (error) {
    throw new Error('Failed to get announcement');
  }
};

export const updateAnnouncement = async ({
  id,
  updateAnnouncementDto,
}: UpdateAnnouncementParam) => {
  try {
    const { data } = await announcementApi.announcementControllerUpdate(id, updateAnnouncementDto);
    return data;
  } catch (error) {
    throw new Error('Failed to update announcement');
  }
};

export const deleteAnnouncement = async (id: string) => {
  try {
    const { data } = await announcementApi.announcementControllerRemove(id);
    return data;
  } catch (error) {
    throw new Error('Failed to delete announcement');
  }
};
