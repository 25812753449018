import { CreateTagDto, UpdateTagDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createTag, updateTag, deleteTag } from '../../api';
import { Tag } from '../../types';

export const useCreateTagMutation = (
  mutationOptions?: MutationOptions<Tag, AxiosError<{ message: string }>, CreateTagDto>,
) => useMutation(createTag, mutationOptions);

export const useUpdateTagMutation = (
  mutationOptions?: MutationOptions<
    Tag,
    AxiosError<{ message: string }>,
    { id: string; updateTagDto: UpdateTagDto }
  >,
) => {
  return useMutation((params) => updateTag(params), mutationOptions);
};

export const useDeleteTagMutation = (
  mutationOptions: MutationOptions<Tag, AxiosError<{ message: string }>, string>,
) => {
  return useMutation((id: string) => deleteTag(id), mutationOptions);
};
