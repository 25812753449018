import { Provider, User } from '@healthhub/api-lib';

import { formatProviderDisplayNameWithBranch } from './provider';
import ProviderHelper from './ProviderHelper';
import { formatUserNameToFull } from './user';
import {
  MessageThreadMemberTypeEnum,
  MessageThreadWithMembersAndLastMessage,
  UserType,
} from '../types';

export function formatMessageThreadMemberId(
  id?: string,
  type?: MessageThreadMemberTypeEnum,
): string {
  if (!id || !type) {
    return '';
  }

  return `${id} ${type}`;
}

export function parseFormattedMessageThreadMemberId(formattedMemberId: string): {
  id: string;
  type: MessageThreadMemberTypeEnum;
} {
  const parts = formattedMemberId.split(' ');
  const type = parts.pop() as MessageThreadMemberTypeEnum;
  const id = parts.join(' ');

  return { id, type };
}

export function getThreadName(
  thread: MessageThreadWithMembersAndLastMessage,
  senderUserId: string,
): string {
  const { type: senderType } = parseFormattedMessageThreadMemberId(senderUserId);

  if (senderType === MessageThreadMemberTypeEnum.HealthhubPatient) {
    const member: Provider = thread.members.find((member) => member.userType !== UserType.PATIENT);
    return formatProviderDisplayNameWithBranch(member);
  } else if (senderType === MessageThreadMemberTypeEnum.HealthhubProvider) {
    const member: User = thread.members.find((member) => member.userType === UserType.PATIENT);
    return formatUserNameToFull(member);
  }

  return '';
}

export function getThreadLogo(
  thread: MessageThreadWithMembersAndLastMessage,
  senderUserId: string,
): string {
  const { type: senderType } = parseFormattedMessageThreadMemberId(senderUserId);

  if (senderType === MessageThreadMemberTypeEnum.HealthhubPatient) {
    const member: Provider = thread.members.find((member) => member.userType !== UserType.PATIENT);
    const { logoUrl } = new ProviderHelper(member);
    return logoUrl || '';
  } else if (senderType === MessageThreadMemberTypeEnum.HealthhubProvider) {
    const member: User = thread.members.find((member) => member.userType === UserType.PATIENT);
    return member.displayPhotoUrl;
  }

  return '';
}

export function getBranchIdsFromMember(members: any[]): number[] {
  const providers = members.filter((member) => member.userType !== UserType.PATIENT);
  return providers ? (providers as Provider[])?.map((provider) => provider.id) : [];
}
