import { ProviderServicePriceTypeEnum } from '@healthhub/api-lib';

import { PriceType } from '../enums/price';
import { formatToPeso } from '../utils';

export class Price {
  private _priceInCents: string;
  private _types = PriceType;
  private _priceType?: PriceType | ProviderServicePriceTypeEnum;

  constructor(price: string, priceType?: PriceType | ProviderServicePriceTypeEnum) {
    this._priceInCents = price;
    this._priceType = priceType;
  }

  get priceInCents(): string {
    return this._priceInCents;
  }

  set priceInCents(price: string) {
    this._priceInCents = price;
  }

  get priceInCentsInt(): number | number[] {
    const type = this.type;

    if (type === this._types.Free) {
      return 0;
    }

    if (type === this._types.Range) {
      const [min, max] = this._priceInCents.split('-');

      return [parseInt(min), parseInt(max)];
    }

    return parseInt(this._priceInCents);
  }

  get priceInCentsInMinMax(): number[] {
    if (this.type === this._types.Range) {
      return this.priceInCentsInt as number[];
    }

    return [this.priceInCentsInt as number, this.priceInCentsInt as number];
  }

  get type(): string {
    if (this.priceInCents === '0') {
      return this._types.Free;
    }

    if (this.priceInCents?.includes('-')) {
      return this._types.Range;
    }

    return this._types.Fixed;
  }

  get priceInPesos(): string | string[] {
    const type = this.type;

    if (this._priceType) {
      if (this._priceType === this._types.Free) {
        return 'Free';
      }

      if (this._priceType === this._types.Range) {
        const [min, max] = this._priceInCents.split('-');
        return `${formatToPeso(parseInt(min))} - ${formatToPeso(parseInt(max))}`;
      }

      if (this._priceType === this._types.Hide) {
        return 'Price Upon Visit';
      }

      if (this._priceType === this._types.StartsAt) {
        return `Starts at ${formatToPeso(parseInt(this._priceInCents))}`;
      }

      return formatToPeso(parseInt(this._priceInCents));
    }

    if (type === this._types.Range) {
      const [min, max] = this._priceInCents.split('-');

      return `${formatToPeso(parseInt(min))} - ${formatToPeso(parseInt(max))}`;
    }

    if (type === this._types.Free) {
      return 'Free';
    }

    return formatToPeso(parseInt(this._priceInCents));
  }

  get priceInPesosInMinMax(): string[] {
    if (this.type === this._types.Range) {
      return this.priceInPesos as string[];
    }

    return [this.priceInPesos as string, this.priceInPesos as string];
  }

  isFree(): boolean {
    if (this._priceType) {
      return this._priceType === this._types.Free;
    }

    return this.type === this._types.Free;
  }
}
