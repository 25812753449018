import { useEffect, useState } from 'react';

import Select from './Select';
import TextInput from './TextInput';
import { CountryCodeType, COUNTRY_CODES } from '../constants';
import { clsxMerge } from '../utils';

type MobileNumberInputProps = {
  defaultCountryCode?: CountryCodeType;
  errorMessage?: string;
  label: string | React.ReactNode;
  labelClassName?: string;
  className?: string;
  showRequiredOnLabel?: boolean;
  isMobilePhoneNumberDisabled?: boolean;
  value?: string;
  placeholder?: string;
  mobileInputClassname?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCountry?: (countryCode: CountryCodeType) => void;
  onBlur?: () => void;
};

function MobileNumberInput(props: Readonly<MobileNumberInputProps>) {
  const {
    showRequiredOnLabel,
    label,
    labelClassName,
    className,
    defaultCountryCode = COUNTRY_CODES[0],
    errorMessage,
    onChangeCountry,
    isMobilePhoneNumberDisabled,
    onBlur,
    placeholder = '000 000 0000',
    mobileInputClassname = '',
    ...textInputProps
  } = props;

  const [countryCode, setCountryCode] = useState<CountryCodeType>(defaultCountryCode);
  const [selectCountryCodeOpen, setSelectCountryCodeOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (onChangeCountry) {
      onChangeCountry(countryCode);
    }
  }, [countryCode, onChangeCountry]);

  useEffect(() => {
    if (defaultCountryCode && countryCode.dial_code !== defaultCountryCode.dial_code) {
      setCountryCode(defaultCountryCode);
    }
  }, [defaultCountryCode]);

  function handleChangeCountryCode(event: React.ChangeEvent<HTMLSelectElement>) {
    event.target.blur();
    const countryCode = COUNTRY_CODES.find(({ code }) => code === event.target.value);
    setSelectCountryCodeOpen(false);
    setIsFocused(false);
    setCountryCode(countryCode as CountryCodeType);
  }

  const countryCodeOptions = COUNTRY_CODES.map(({ code, name, dial_code }: CountryCodeType) => (
    <option key={code} value={code}>
      {selectCountryCodeOpen ? `${name} (${dial_code})` : code}
    </option>
  ));

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur();
    }

    setIsFocused(false);
  };

  return (
    <div className={clsxMerge('flex w-full flex-col', className)}>
      <label
        className={clsxMerge(
          'mb-px block text-sm font-normal leading-6 text-gray-900',
          labelClassName,
        )}
      >
        {label}
        {showRequiredOnLabel && <span className="text-red-600"> *</span>}
      </label>
      <div
        className={clsxMerge('flex items-center rounded-md bg-white px-3 ring-1 ring-neutral-300', {
          'ring-primary-500': isFocused,
          'ring-red-600 focus:ring-red-600': errorMessage,
          'bg-neutral-100': isMobilePhoneNumberDisabled,
        })}
      >
        <div
          className={clsxMerge(
            'max-h-[39px] min-w-[50px] overflow-hidden',
            selectCountryCodeOpen && 'min-w-[1px]',
          )}
        >
          <Select
            disabled={isMobilePhoneNumberDisabled}
            placeholder="Country Code"
            className={clsxMerge(
              'bg-transparent p-0 text-sm text-neutral-600 ring-0 focus:ring-0 disabled:bg-neutral-100 disabled:text-neutral-400 disabled:opacity-100',
              selectCountryCodeOpen && 'w-[1px] opacity-0',
            )}
            showPlaceholder={false}
            value={countryCode.code}
            onChange={handleChangeCountryCode}
            onBlur={() => {
              setSelectCountryCodeOpen(false);
              setIsFocused(false);
            }}
            onFocus={() => {
              setSelectCountryCodeOpen(true);
              setIsFocused(true);
            }}
          >
            {countryCodeOptions}
          </Select>
        </div>
        <div
          className={clsxMerge(
            'flex items-center justify-end text-sm text-neutral-500',
            isMobilePhoneNumberDisabled && 'text-neutral-400',
          )}
        >
          {countryCode.dial_code}
        </div>
        <div className="w-2/3 flex-auto">
          <TextInput
            {...textInputProps}
            disabled={isMobilePhoneNumberDisabled}
            placeholder={placeholder}
            className={clsxMerge(
              '!h-auto pl-2 text-sm text-neutral-600 outline-none ring-0 focus:ring-0 md:text-sm',
              mobileInputClassname,
            )}
            type="tel"
            inputMode="tel"
            onBlur={handleOnBlur}
            onFocus={() => setIsFocused(true)}
          />
        </div>
      </div>
      {errorMessage && <p className="mt-1 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
}

export default MobileNumberInput;
