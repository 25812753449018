import { ElementType } from 'react';

import { CheckIcon } from '@heroicons/react/24/solid';

import Button from '../Button';
import Modal from '../Modal';

type Props = {
  isOpen: boolean;
  onClickActionButton: () => void;
  onClickSecondaryButton?: () => void;
  secondaryButtonText?: string;
  actionButtonText: string;
  isLoading?: boolean;
  title: string;
  subTitle: string;
  icon?: ElementType;
  showSecondaryButton?: boolean;
};

export function NotificationDialog(props: Props) {
  const {
    isOpen,
    isLoading,
    onClickActionButton,
    actionButtonText,
    secondaryButtonText,
    onClickSecondaryButton,
    showSecondaryButton,
    title,
    subTitle,
  } = props;
  const Icon = props.icon ?? CheckIcon;

  const isSecondaryButtonVisible =
    showSecondaryButton && onClickSecondaryButton && secondaryButtonText;

  return (
    <Modal
      open={isOpen}
      size="lg"
      className="w-[512px] max-w-[100%] p-0 px-0 pb-0 pt-0 sm:p-0 md:p-0"
    >
      <div className="flex flex-row items-baseline gap-4 p-6">
        <div className="flex items-center justify-center rounded-full bg-secondary-300 p-3">
          <Icon className="h-4 w-4 fill-primary-500 stroke-primary-500" />
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
          <div className="whitespace-pre-line text-sm text-neutral-500">{subTitle}</div>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-4 rounded-b-lg bg-gray-50 px-6 py-3">
        {isSecondaryButtonVisible && (
          <Button
            onClick={onClickSecondaryButton}
            variant="outlinedPrimary"
            className="px-4"
            type="button"
            isLoading={isLoading}
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          onClick={onClickActionButton}
          variant="primary"
          className="px-4"
          type="button"
          isLoading={isLoading}
        >
          {actionButtonText}
        </Button>
      </div>
    </Modal>
  );
}
