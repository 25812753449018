import { useQuery } from '@tanstack/react-query';

import { OTHERS_VALUE } from '@mwell-healthhub/commons';

const PSGC_API_URL = 'https://psgc.gitlab.io/api';

interface IPSGCProvince {
  code: string;
  name: string;
  regionCode: string;
  islandGroupCode: string;
}

interface IPSGCCityMunicipality extends IPSGCProvince {
  districtCode: string;
  isCapital: boolean;
  isCity: boolean;
  isMunicipality: boolean;
  oldName: string;
  provinceCode: string;
}

interface IPSGCBarangay extends IPSGCCityMunicipality {
  cityCode: string;
  code: string;
  islandGroupCode: string;
  municipalityCode: string;
  name: string;
  regionCode: string;
  subMunicipalityCode: string;
}

export const useGetPSGCProvincesByRegion = (regionCode: string) =>
  useQuery<IPSGCProvince[], Error>(
    [regionCode],
    async () => {
      const response = await fetch(`${PSGC_API_URL}/regions/${regionCode}/provinces`);

      if (!response.ok) throw new Error('Failed to fetch provinces');

      const data = await response.json();

      return data as IPSGCProvince[];
    },
    { enabled: !!regionCode },
  );

export const useGetAllPsgcProvinces = () =>
  useQuery<IPSGCProvince[], Error>(['provinces'], async () => {
    const response = await fetch(`${PSGC_API_URL}/provinces`);

    if (!response.ok) throw new Error('Failed to fetch provinces');

    const data = await response.json();

    return data as IPSGCProvince[];
  });

export const useGetPSGCCitiesMunicipalitiesByProvince = (provinceCode?: string) =>
  useQuery<IPSGCCityMunicipality[], Error>(
    [provinceCode],
    async () => {
      const response = await fetch(
        `${PSGC_API_URL}/provinces/${provinceCode}/cities-municipalities`,
      );

      if (!response.ok) throw new Error('Failed to fetch cities/municipalities');

      const data = await response.json();

      return data as IPSGCCityMunicipality[];
    },
    { enabled: !!provinceCode },
  );

export const useGetPSGCCitiesMunicipalities = () =>
  useQuery<IPSGCCityMunicipality[], Error>(['municipalities'], async () => {
    const response = await fetch(`${PSGC_API_URL}/cities-municipalities`);

    if (!response.ok) throw new Error('Failed to fetch municipalities');

    const data = await response.json();

    return data as IPSGCCityMunicipality[];
  });

export const useGetPSGCCitiesMunicipalitiesByRegion = (regionId?: string) =>
  useQuery<IPSGCCityMunicipality[], Error>(
    [regionId],
    async () => {
      const response = await fetch(`${PSGC_API_URL}/regions/${regionId}/cities-municipalities`);

      if (!response.ok) throw new Error('Failed to fetch cities/municipalities');

      const data = await response.json();

      return data as IPSGCCityMunicipality[];
    },
    { enabled: !!regionId },
  );

export const useGetAllCitiesMunicipalities = () =>
  useQuery<IPSGCCityMunicipality[], Error>(['cities-municipalities'], async () => {
    const response = await fetch(`${PSGC_API_URL}/cities-municipalities`);

    if (!response.ok) throw new Error('Failed to fetch municipalities');

    const data = await response.json();

    return data as IPSGCCityMunicipality[];
  });

export const useGetPSGCBarangaysByCitiesMunicipalities = (cityOrMunicipalityCode: string) =>
  useQuery<IPSGCBarangay[], Error>(
    ['cities-municipalities', cityOrMunicipalityCode, 'barangays'],
    async () => {
      try {
        const response = await fetch(
          `${PSGC_API_URL}/cities-municipalities/${cityOrMunicipalityCode}/barangays`,
        );

        if (!response.ok) throw new Error('Failed to fetch barangays');

        const data = await response.json();

        return data as IPSGCBarangay[];
      } catch (e) {
        return [];
      }
    },
    { enabled: !!cityOrMunicipalityCode && cityOrMunicipalityCode !== OTHERS_VALUE },
  );

export const useGetPSGCProvinces = () =>
  useQuery(
    ['provinces'],
    async () => {
      const response = await fetch(`${PSGC_API_URL}/provinces`);

      if (!response.ok) throw new Error('Failed to fetch provinces');

      const data = await response.json();

      return data as IPSGCProvince[];
    },
    {},
  );
