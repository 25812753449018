import {
  ClientProfileApi,
  UpdateProviderDto,
  ClientProfile,
  UpdateClientProfileDto,
  CreateClientProfileDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash.get';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';

export type UpdateClientProfileParams = {
  id: number;
  updateClientProfileDto: UpdateClientProfileDto;
};

export const clientProfileApi = HealthHubApiClient.use(ClientProfileApi);

export type UpdateProviderParams = {
  id: number;
  updateProviderDto: UpdateProviderDto;
};

export async function getAllClientProfile(
  params?: Record<string, string | number>,
): Promise<ClientProfile[]> {
  try {
    const { data } = await clientProfileApi.clientProfileControllerFindAll(
      (params?.userId as string) ?? '',
    );

    return data as ClientProfile[];
  } catch (err) {
    throw new Error('Failed to fetch providers');
  }
}

export async function getClientProfileById(
  id: string,
  config?: AxiosRequestConfig,
): Promise<ClientProfile> {
  try {
    const { data } = await clientProfileApi.clientProfileControllerFindOne(id, config);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getClientProfileByUserId(
  userId: string,
  config?: AxiosRequestConfig,
): Promise<ClientProfile> {
  try {
    const { data } = await clientProfileApi.clientProfileControllerFindByUserId(userId, config);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateClientProfile(
  params: UpdateClientProfileParams,
): Promise<ClientProfile> {
  const { id, updateClientProfileDto } = params;

  try {
    const { data } = await clientProfileApi.clientProfileControllerUpdate(
      id.toString(),
      updateClientProfileDto,
    );

    return data;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to update clientProfile');
  }
}

export async function createClientProfile(
  createClientProfileDto: CreateClientProfileDto,
): Promise<ClientProfile> {
  try {
    const { data } = await clientProfileApi.clientProfileControllerCreate(createClientProfileDto);

    return data;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to create clientProfile');
  }
}
