export enum SizeVariant {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum BadgeTypeVariant {
  FULL = 'full',
  MD = 'md',
}

// add more here when available in figma
export enum Variants {
  ERROR = 'error',
  PRIMARY = 'primary',
  SECONDARY_GRAY = 'secondaryGray',
  TERTIARY = 'tertiary',
}

export type VariantType = (typeof Variants)[keyof typeof Variants];
