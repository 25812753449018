type PatientProfileSidebarLinkProps = {
  targetId: string;
  children: React.ReactNode;
};

const PatientProfileSidebarLink: React.FC<PatientProfileSidebarLinkProps> = ({
  targetId,
  children,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const topOffset = targetElement.getBoundingClientRect().top + window.scrollY - 100;

      window.scrollTo({
        behavior: 'smooth',
        top: topOffset,
      });
    }
  };

  return (
    <a href="#" className="font-medium text-gray4" onClick={handleClick}>
      {children}
    </a>
  );
};

export default PatientProfileSidebarLink;
