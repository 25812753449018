import { ReactNode } from 'react';

import { BadgeType } from '../../enums/badgeType';
import { clsxMerge } from '../../utils';

type Props = {
  type?: BadgeType;
  children?: ReactNode;
  className?: string;
};

function Badge(props: Props) {
  const { type, children, className } = props;

  let bgClassColor = '';
  let textClassColor = '';

  switch (type) {
    case BadgeType.YELLOW:
      bgClassColor = 'bg-amber-100';
      textClassColor = 'text-yellow-800';
      break;
    case BadgeType.BLUE:
      bgClassColor = 'bg-blue-100';
      textClassColor = 'text-blue-500';
      break;
    case BadgeType.RED:
      bgClassColor = 'bg-red-100';
      textClassColor = 'text-red-600';
      break;
    case BadgeType.GREEN:
      bgClassColor = 'bg-green-100';
      textClassColor = 'text-green-700';
      break;
    case BadgeType.GREY:
      bgClassColor = 'bg-gray-100';
      textClassColor = 'text-slate-600';
      break;
    case BadgeType.PLAIN:
      bgClassColor = 'bg-none';
      textClassColor = 'text-slate-600';
      break;
  }

  return (
    <div
      className={clsxMerge(
        'inline-flex min-h-[24px] w-auto items-center justify-center whitespace-nowrap rounded-full px-[8px] py-[4px]',
        bgClassColor,
      )}
    >
      <div
        className={clsxMerge(
          'text-center text-xs font-medium leading-[16px] tracking-[0.3px]',
          textClassColor,
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default Badge;
