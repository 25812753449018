import {
  Appointment,
  CreateProviderClientNotesDto,
  ProviderClientNotes,
  UpdateProviderClientNotesDto,
} from '@healthhub/api-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonVariant } from './Button/Button';
import {
  TextInput,
  TextArea,
  Dropdown,
  convertObjectsToOptions,
  useCreateProviderClientNotes,
  Button,
  useUpdateProviderClientNotesById,
  convertDateByFormat,
  useDeleteProviderClientNotesById,
  ProviderClientNotesFormData,
  clsxMerge,
  ActionModal,
  Variants,
  useToggle,
  toast,
  GET_PROVIDER_CLIENT_NOTES,
  getErrorMessage,
} from '..';
import { trackEvent, EventName } from '../utils/analytics';

const providerClientNoteSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  remarks: yup.string().required('Remarks is required.'),
});

enum ProviderClientNotesEnum {
  TITLE = 'title',
  REMARKS = 'remarks',
  APPOINTMENT_ID = 'appointmentId',
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
  handleShowProviderClientNoteForm: (
    isEditing: boolean,
    providerClientNote?: ProviderClientNotes,
  ) => void;
  handleSaved?: () => void;
  providerClientProfileId?: number;
  appointmentId?: number;
  isEditing?: boolean;
  formFieldWrapperClassName?: string;
  formFieldContainerClassName?: string;
  formControlContainerClassName?: string;
  cancelButtonVariant?: ButtonVariant;
  appointments?: Appointment[];
  hideAppointments?: boolean;
  providerClientNote?: ProviderClientNotes;
};

const ProviderClientNotesForm = ({
  handleShowProviderClientNoteForm,
  handleSaved,
  providerClientProfileId,
  appointmentId,
  isEditing = false,
  className,
  formFieldWrapperClassName,
  formFieldContainerClassName,
  formControlContainerClassName,
  cancelButtonVariant = 'outlinedPrimary',
  appointments = [],
  hideAppointments = false,
  providerClientNote,
}: Props) => {
  const queryClient = useQueryClient();
  const {
    isOpen: showDeleteConfirmationModal,
    handleToggle: handleShowDeleteConfirmationModalToggle,
  } = useToggle();
  const { mutate: addProviderNote, isLoading: isAddingNote } = useCreateProviderClientNotes({
    onSuccess: (note) => {
      trackEvent(EventName.CLICK_ADD_NOTE, {
        appointment_id: note?.appointment?.id?.toString(),
        appointment_booking_number: note?.appointment?.bookingNumber,
      });

      toast({
        message: 'Note has been added.',
        type: 'success',
      });

      handleSaved?.();
      handleCloseForm();

      // Refetch provider client notes list after succesful save
      queryClient.invalidateQueries([GET_PROVIDER_CLIENT_NOTES]);
    },
    onError: (err) => {
      toast({
        message: getErrorMessage(err, 'Failed to add note.'),
        type: 'error',
      });
    },
  });

  const { mutate: updateProviderNote, isLoading: isUpdatingNote } =
    useUpdateProviderClientNotesById({
      onSuccess: (note) => {
        trackEvent(EventName.CLICK_EDIT_NOTE, {
          appointment_id: note?.appointment?.id?.toString(),
          appointment_booking_number: note?.appointment?.bookingNumber,
        });

        toast({
          message: 'Note has been updated.',
          type: 'success',
        });

        handleSaved?.();
        handleCloseForm();
      },
      onError: (err) => {
        toast({
          message: getErrorMessage(err, 'Failed to update note.'),
          type: 'error',
        });
      },
    });

  const { mutate: deleteProviderNote, isLoading: isDeletingNote } =
    useDeleteProviderClientNotesById({
      onSuccess: () => {
        trackEvent(EventName.CLICK_DELETE_NOTE, {
          appointment_id: providerClientNote?.appointment?.id?.toString(),
          appointment_booking_number: providerClientNote?.appointment?.bookingNumber,
        });

        toast({
          message: 'Note has been deleted.',
          type: 'success',
        });

        handleSaved?.();
        handleCloseForm();
      },
      onError: (err) => {
        toast({
          message: getErrorMessage(err, 'Failed to delete note.'),
          type: 'error',
        });
      },
    });

  const { control, handleSubmit } = useForm<ProviderClientNotesFormData>({
    resolver: yupResolver(
      providerClientNoteSchema,
    ) as unknown as Resolver<ProviderClientNotesFormData>,
    defaultValues: {
      appointmentId: providerClientNote?.appointment?.id || appointmentId,
      title: providerClientNote?.title,
      remarks: providerClientNote?.remarks,
    },
  });

  const handleCloseForm = () => {
    handleShowProviderClientNoteForm(false);
  };

  const handleSave = handleSubmit((providerClientNotesForm) => {
    if (isEditing) {
      if (!providerClientNote) {
        toast({
          message: 'Unable to update this note.',
          type: 'error',
        });

        return;
      }
      const providerClientNotes: UpdateProviderClientNotesDto = {
        id: providerClientNote?.id,
        appointmentId: providerClientNotesForm.appointmentId,
        title: providerClientNotesForm.title,
        remarks: providerClientNotesForm.remarks,
      };

      updateProviderNote(providerClientNotes);

      return;
    }

    const providerClientNotes: CreateProviderClientNotesDto = {
      providerClientProfileId: providerClientProfileId,
      appointmentId: providerClientNotesForm.appointmentId,
      title: providerClientNotesForm.title,
      remarks: providerClientNotesForm.remarks,
    };

    addProviderNote(providerClientNotes);
  });

  const handleConfirmDelete = () => {
    deleteProviderNote(String(providerClientNote?.id));
  };

  const deleteActionModal = (
    <ActionModal
      variant={Variants.ERROR}
      title="Delete this note?"
      description="Are you sure you want to delete this note? Once removed, it cannot be restored."
      primaryButtonText="Delete"
      isLoading={isDeletingNote}
      onClickPrimaryButton={handleConfirmDelete}
      onClickSecondaryButton={handleShowDeleteConfirmationModalToggle}
    ></ActionModal>
  );

  const isLoading = isUpdatingNote || isAddingNote || isDeletingNote;

  const mappedAppointments = convertObjectsToOptions(
    appointments?.map((appointment) => ({
      name: `${convertDateByFormat(appointment.dateTime, 'MMM dd, yyyy')} (ID ${
        appointment.bookingNumber
      })`,
      id: appointment.id,
    })),
    {
      includeNone: false,
    },
  );

  return (
    <>
      {showDeleteConfirmationModal && deleteActionModal}
      <form className={clsxMerge('space-y-8', className)} onSubmit={handleSave}>
        <div className={clsxMerge('space-y-8', formFieldWrapperClassName)}>
          <h1 className="text-xl font-bold">{isEditing ? 'Edit Note' : 'Add Note'}</h1>
          <div
            className={clsxMerge(
              'rounded-md border border-gray-200 bg-white p-7',
              formFieldContainerClassName,
            )}
          >
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-4">
                <Controller
                  name={ProviderClientNotesEnum.TITLE}
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <TextInput
                        labelShown
                        showRequiredOnLabel
                        labelElement={
                          <span className="text-sm font-normal text-neutral-600">
                            Title <span className="text-neutral-400">(e.g Medical History)</span>
                          </span>
                        }
                        name={ProviderClientNotesEnum.TITLE}
                        value={value}
                        onChange={onChange}
                      />
                      {error?.message && (
                        <p className="mt-2 text-sm text-red-600">{error?.message}</p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="col-span-4">
                <Controller
                  control={control}
                  name={ProviderClientNotesEnum.REMARKS}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <TextArea
                        labelShown
                        className="text-sm"
                        label="Remarks"
                        additionalLabel={
                          'To enter items as a list, start each line with numbers (1., 2., 3.) or dashes (-).'
                        }
                        showRequiredOnLabel
                        value={value}
                        onChange={onChange}
                      />
                      {error?.message && (
                        <p className="mt-2 text-sm text-red-600">{error?.message}</p>
                      )}
                    </>
                  )}
                />
              </div>
              {!hideAppointments && (
                <div className="col-span-4">
                  <Controller
                    name={ProviderClientNotesEnum.APPOINTMENT_ID}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Dropdown
                        hasLeftRoundedBorders
                        hasRightRoundedBorders
                        label={
                          <span className="text-sm font-normal text-neutral-600">
                            Link to Appointment <span className="text-neutral-400">(optional)</span>
                          </span>
                        }
                        placeholder="Select Appointment"
                        containerClassname="w-full"
                        options={mappedAppointments}
                        value={mappedAppointments.find(
                          (appointment) => appointment.value === (value as unknown as string),
                        )}
                        onChange={(e) => onChange(e.value)}
                        errorMessage={error?.message || ''}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={clsxMerge(
            'flex justify-end',
            { 'justify-between': isEditing },
            formControlContainerClassName,
          )}
        >
          {isEditing && (
            <Button
              className="!min-w-[136px]"
              type="button"
              variant="outlinedRed"
              size="md"
              onClick={handleShowDeleteConfirmationModalToggle}
            >
              Delete Note
            </Button>
          )}
          <div className="flex gap-2">
            <Button
              className="!min-w-[136px]"
              type="button"
              variant={cancelButtonVariant}
              size="md"
              onClick={handleCloseForm}
              disabled={isLoading}
            >
              Cancel
            </Button>
            {isEditing ? (
              <Button
                isLoading={isUpdatingNote}
                className="!min-w-[136px]"
                variant="primary"
                size="md"
                type="submit"
                disabled={isLoading}
              >
                Save
              </Button>
            ) : (
              <Button
                isLoading={isAddingNote}
                className="!min-w-[136px]"
                variant="primary"
                size="md"
                type="submit"
                disabled={isLoading}
              >
                Add Note
              </Button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ProviderClientNotesForm;
