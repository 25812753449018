import { UploadApi } from '@healthhub/api-lib';
import { isAxiosError } from 'axios';

import HealthHubApiClient from './api-client';
import { UploadType } from '../types';

export const uploadApi = HealthHubApiClient.use(UploadApi);

export async function uploadFile(file: File, path?: string) {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await uploadApi.uploadControllerUploadFile(path, {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data as unknown as UploadType;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}
