export const GET_ALL_ACCOMPLISHMENT_ACCREDITATIONS = 'GET_ALL_ACCOMPLISHMENT_ACCREDITATIONS';
export const GET_ALL_DOCTORS_NOTES = 'GET_ALL_DOCTORS_NOTES';
export const GET_ALL_E_PRESCRIPTIONS = 'GET_ALL_E_PRESCRIPTIONS';
export const GET_ALL_LAB_REQUESTS = 'GET_ALL_LAB_REQUESTS';
export const GET_ALL_MEDICAL_CERTIFICATES = 'GET_ALL_MEDICAL_CERTIFICATES';
export const GET_ALL_E_PRESCRIPTION_MEDICINE_ORDERS = 'GET_ALL_E_PRESCRIPTION_MEDICINE_ORDERS';
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS_BY_EMAIL = 'GET_ALL_NOTIFICATIONS_BY_EMAIL';
export const GET_ALL_REVIEW_OF_SYSTEMS = 'GET_ALL_REVIEW_OF_SYSTEMS';
export const GET_ALL_SPECIALTIES = 'GET_ALL_SPECIALTIES';
export const GET_ALL_SYMPTOMS = 'GET_ALL_SYMPTOMS';
export const GET_ALL_VITAL_SIGNS = 'GET_ALL_VITAL_SIGNS';
export const GET_E_PRESCRIPTION = 'GET_E_PRESCRIPTION';
export const GET_E_PRESCRIPTION_MEDICINE_ORDERS = 'GET_E_PRESCRIPTION_MEDICINE_ORDERS';
export const GET_LAB_REQUEST_BY_ID = 'GET_LAB_REQUEST_BY_ID';
export const GET_LABORATORY_AVAILABILITIES = 'GET_LABORATORY_AVAILABILITIES';
export const GET_LABORATORY_SCHEDULE = 'GET_LABORATORY_SCHEDULE';
export const GET_LABORATORY_SERVICES = 'GET_LABORATORY_SERVICES';
export const GET_LAB_TECHNICIANS = 'GET_LAB_TECHNICIANS';
export const GET_LATEST_VITAL_SIGNS = 'GET_LATEST_VITAL_SIGNS';
export const GET_MEDICAL_CERTIFICATE_BY_ID = 'GET_MEDICAL_CERTIFICATE_BY_ID';
export const GET_MESSAGE_THREAD = 'GET_MESSAGE_THREAD';
export const GET_MESSAGE_THREAD_MESSAGES = 'GET_MESSAGE_THREAD_MESSAGES';
export const GET_MESSAGE_USER_THREADS = 'GET_MESSAGE_USER_THREADS';
export const GET_NEXT_LABORATORY_AVAILABILITY = 'GET_NEXT_LABORATORY_AVAILABILITY';
export const GET_REVIEW_OF_SYSTEM_BY_ID = 'GET_REVIEW_OF_SYSTEM_BY_ID';
export const GET_ALL_USER_FILES = 'GET_ALL_USER_FILES';
export const GET_MEDICINE_HISTORY = 'GET_MEDICINE_HISTORY';
export const GET_ALL_APPOINTMENT_ASSOCIATED_DOCTORS = 'GET_ALL_APPOINTMENT_ASSOCIATED_DOCTORS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_CURRENT_USER_APPOINTMENTS = 'GET_CURRENT_USER_APPOINTMENTS';
export const GET_OCR = 'GET_OCR';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_PROVIDERS = 'GET_ALL_PROVIDERS';
export const GET_PROVIDER_BY_ID = 'GET_PROVIDER_BY_ID';
export const GET_PROVIDER_COUNTS = 'GET_PROVIDER_COUNTS';
export const GET_PROVIDER_NOTIFICATIONS = 'GET_PROVIDER_NOTIFICATIONS';
export const GET_PROVIDER_UNREAD_NOTIFICATIONS_COUNT = 'GET_PROVIDER_UNREAD_NOTIFICATIONS_COUNT';
export const GET_PROVIDER_BY_SLUG_NAME = 'GET_PROVIDER_BY_SLUG_NAME';
export const GET_ALL_PROVIDER_TYPES = 'GET_ALL_PROVIDER_TYPES';
export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_ALL_PROVIDER_SERVICE = 'GET_ALL_PROVIDER_SERVICE';
export const GET_ALL_PROVIDER_SERVICE_BY_USER_ID = 'GET_ALL_PROVIDER_SERVICE_BY_USER_ID';
export const GET_PROVIDER_SERVICE_BY_ID = 'GET_PROVIDER_SERVICE_BY_ID';
export const GET_PROVIDER_SERVICE_SETTINGS = 'GET_PROVIDER_SERVICE_SETTINGS';
export const GET_PROVIDER_SHARELINKS_BY_SLUG_NAME = 'GET_PROVIDER_SHARELINKS_BY_SLUG_NAME';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_ALL_APPOINTMENTS = 'GET_ALL_APPOINTMENTS';
export const GET_ALL_PROVIDER_CLIENT_APPOINTMENTS = 'GET_ALL_PROVIDER_CLIENT_APPOINTMENTS';
export const GET_ALL_APPOINTMENTS_FOR_REPORTS = 'GET_ALL_APPOINTMENTS_FOR_REPORTS';
export const GET_ALL_APPOINTMENTS_FOR_EXPORT = 'GET_ALL_APPOINTMENTS_FOR_EXPORT';
export const GET_ALL_PROVIDER_PATIENTS = 'GET_ALL_PROVIDER_PATIENTS';
export const GET_ALL_OPERATION_USERS = 'GET_ALL_OPERATION_USERS';
export const GET_ALL_CLIENT_PROFILE = 'GET_ALL_CLIENT_PROFILE';
export const GET_CLIENT_PROFILE_BY_ID = 'GET_CLIENT_PROFILE_BY_ID';
export const GET_CLIENT_PROFILE_BY_USER_ID = 'GET_CLIENT_PROFILE_BY_USER_ID';
export const GET_CLIENT_FAVOURITE = 'GET_CLIENT_FAVOURITE';
export const GET_PROVIDER_TAG_CATEGORY_BY_PARAMS = 'GET_PROVIDER_TAG_CATEGORY_BY_PARAMS';
export const GET_PROVIDER_CLIENT_NOTES = 'GET_PROVIDER_CLIENT_NOTES';
export const GET_PROVIDER_CLIENT_NOTES_BY_ID = 'GET_PROVIDER_CLIENT_NOTES_BY_ID';
export const GET_ALL_PROVIDER_USERS = 'GET_ALL_PROVIDER_USERS';
export const GET_ALL_BRANCHES = 'GET_ALL_BRANCHES';
export const GET_ADMIN_APPOINTMENT_SUMMARY = 'GET_ADMIN_APPOINTMENT_SUMMARY';
export const GET_ALL_PROVIDER_CLIENT_PROFILE_BY_PARAMS =
  'GET_ALL_PROVIDER_CLIENT_PROFILE_BY_PARAMS';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_ORDER_COUNTS = 'GET_ORDER_COUNTS';
export const GER_REFERRAL_BY_ID = 'GER_REFERRAL_BY_ID';
export const GET_REFERRAL_APPOINTMENTS = 'GET_REFERRAL_APPOINTMENTS';
export const GET_ALL_REFERRALS = 'GET_ALL_REFERRALS';
export const GET_PROVIDER_USERS_BY_PROVIDER_ID = 'GET_PROVIDER_USERS_BY_PROVIDER_ID';
export const GET_KEY_METRICS = 'GET_KEY_METRICS';
export const GET_ALL_BANNERS = 'GET_ALL_BANNERS';
export const GET_BANNER_BY_ID = 'GET_BANNER_BY_ID';
export const GET_ALL_VOUCHERS = 'GET_ALL_VOUCHERS';
export const GET_VOUCHER_BY_ID = 'GET_VOUCHER_BY_ID';
export const GET_VOUCHER_COUNTS = 'GET_VOUCHER_COUNTS';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const GET_ALL_ESHOP_CATEGORIES = 'GET_ALL_ESHOP_CATEGORIES';
export const GET_ESHOP_CATEGORY_BY_ID = 'GET_ESHOP_CATEGORY_BY_ID';
export const GET_ALL_ESHOP_TAGS = 'GET_ALL_ESHOP_TAGS';
export const GET_ESHOP_TAG_BY_ID = 'GET_ESHOP_TAG_BY_ID';
export const GET_ESHOP_MERCHANT_BY_ID = 'GET_ESHOP_MERCHANT_BY_ID';
export const GET_ESHOP_ADMIN_CONFIG = 'GET_ESHOP_ADMIN_CONFIG';
export const GET_GEOCODE = 'GET_GEOCODE';
export const GET_ALL_PROMOTIONS = 'GET_ALL_PROMOTIONS';
export const GET_ALL_PUBLISHED_PROMOTIONS = 'GET_ALL_PUBLISHED_PROMOTIONS';
export const GET_PROMOTION_BY_ID = 'GET_PROMOTION_BY_ID';
export const GET_ALL_ANNOUNCEMENTS = 'GET_ALL_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENT_BY_ID = 'GET_ANNOUNCEMENT_BY_ID';
export const GET_ALL_MEDICAL_RECORD_BY_APPOINTMENT_ID = 'GET_ALL_MEDICAL_RECORD_BY_APPOINTMENT_ID';
export const GET_ALL_MEDICAL_RECORD_OF_USER = 'GET_ALL_MEDICAL_RECORD_OF_USER';
export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const GET_ALL_TRANSACTION_MERCHANTS = 'GET_ALL_TRANSACTION_MERCHANTS';
export const GET_ALL_TRANSACTION_MERCHANT_PRODUCTS = 'GET_ALL_TRANSACTION_MERCHANT_PRODUCTS';
export const GET_TRANSACTION_BY_ID = 'GET_TRANSACTION_BY_ID';
export const GET_TRANSACTION_COUNTS = 'GET_TRANSACTION_COUNTS';
export const GET_DISCOUNT = 'GET_DISCOUNT';
export const GET_DISCOUNTS = 'GET_DISCOUNTS';
export const GET_MULTIPLE_FEEDBACK = 'GET_MULTIPLE_FEEDBACK';
export const GET_THREAD = 'GET_THREAD';
export const GET_MESSAGES_BY_THREAD_ID = 'GET_MESSAGES_BY_THREAD_ID';
export const GET_MEMBER_MESSAGE_THREADS = 'GET_MEMBER_MESSAGE_THREADS';
export const GET_SERVICE_SCHEDULE = 'GET_SERVICE_SCHEDULE';
export const GET_SERVICE_SCHEDULE_BY_PROVIDER_SERVICE_ID =
  'GET_SERVICE_SCHEDULE_BY_PROVIDER_SERVICE_ID';
export const GET_PROVIDER_DIRECTORY_BY_ID = 'GET_PROVIDER_DIRECTORY';
export const GET_MANY_PROVIDER_DIRECTORY = 'GET_MANY_PROVIDER_DIRECTORY';
export const GET_CLIENT_PROVIDERS_FROM_DIRECTORY = 'GET_CLIENT_PROVIDERS_FROM_DIRECTORY';
export const GET_APPOINTMENT_REFERRAL_FORM_SIGNED_URL = 'GET_APPOINTMENT_REFERRAL_FORM_SIGNED_URL';
export const EXPORT_HMO_TRANSACTIONS = 'EXPORT_HMO_TRANSACTIONS';
export const GET_PROVIDER_ONBOARDING_DOCUMENTS = 'GET_PROVIDER_ONBOARDING_DOCUMENTS';
export const GET_PROVIDER_BY_ID_V2 = 'GET_PROVIDER_BY_ID_V2';
export const GET_PROVIDER_SERVICE_CATEGORIES_V2 = 'GET_PROVIDER_SERVICE_CATEGORIES_V2';
export const GET_PROVIDER_SERVICES_V2 = 'GET_PROVIDER_SERVICES_V2';
export const GET_PROVIDER_ONBOARDING_AGREEMENT_DOCUMENTS =
  'GET_PROVIDER_ONBOARDING_AGREEMENT_DOCUMENTS';
