import { isAfter } from 'date-fns';

import { decodeJWT } from './jwt';

export function checkHasOtpTokenValueExpired(otpToken: string) {
  if (otpToken) {
    const decodedValues = decodeJWT(otpToken);

    return isAfter(new Date(), new Date(decodedValues.expiration));
  }

  return false;
}

export function checkIsCurrentUser(otpToken: string, currentUserEmail: string) {
  if (otpToken) {
    const decodedValues = decodeJWT(otpToken);

    return decodedValues.email === currentUserEmail;
  }

  return false;
}
