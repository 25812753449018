import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';
import Cookies from 'js-cookie';

import { removeLocalStorageItem } from './localStorage';
import { ACCESS_TOKEN, LocalStorageKey } from '../constants';

export const clearAuthLocalStorage = () => {
  Cookies.remove(ACCESS_TOKEN);

  Object.values(LocalStorageKey).forEach(removeLocalStorageItem);
};

export const getUserToken = async () => {
  return new Promise((resolve, _reject) => {
    try {
      const auth = getAuth();

      auth.tenantId = process.env.NEXT_PUBLIC_TENANT_ID as string;

      const unsub = onAuthStateChanged(auth, async (user) => {
        if (user) {
          const token = await getIdToken(user);

          resolve(token);
        } else {
          resolve(null);
        }

        unsub();
      });
    } catch (err) {
      resolve(null);
    }
  });
};
