import { OTHERS_VALUE, OTHERS_LABEL } from './common';
import { Option } from '../types/Option.type';

export const LOCATIONS: Option[] = [
  { value: '', label: 'All Locations' },
  { value: '1', label: 'National Capital Region (NCR)' },
  { value: '5', label: 'Region III (Central Luzon)' },
  { value: '6', label: 'Region IV-A (CALABARZON)' },
  { value: '12', label: 'Region IX (Zamboanga Peninsula)' },
  { value: '9', label: 'Region VI (Western Visayas)' },
  { value: '10', label: 'Region VII (Central Visayas)' },
  { value: '14', label: 'Region XI (Davao Region)' },
  { value: '15', label: 'Region XII (SOCCSKSARGEN)' },
  { value: '16', label: 'Region XIII (Caraga)' },
];

export const NCR_PROVINCE = {
  code: '130000000',
  name: 'Metro Manila',
  islandGroupCode: 'luzon',
};

export const metroManilaCities = [
  {
    code: '133900000',
    name: 'City of Manila',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '133900000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380600000',
  },
  {
    code: '137401000',
    name: 'City of Mandaluyong',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380500000',
  },
  {
    code: '137402000',
    name: 'City of Marikina',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380700000',
  },
  {
    code: '137403000',
    name: 'City of Pasig',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381200000',
  },
  {
    code: '137404000',
    name: 'Quezon City',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381300000',
  },
  {
    code: '137405000',
    name: 'City of San Juan',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381400000',
  },
  {
    code: '137501000',
    name: 'City of Caloocan',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380100000',
  },
  {
    code: '137502000',
    name: 'City of Malabon',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380400000',
  },
  {
    code: '137503000',
    name: 'City of Navotas',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380900000',
  },
  {
    code: '137504000',
    name: 'City of Valenzuela',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381600000',
  },
  {
    code: '137601000',
    name: 'City of Las Piñas',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380200000',
  },
  {
    code: '137602000',
    name: 'City of Makati',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380300000',
  },
  {
    code: '137603000',
    name: 'City of Muntinlupa',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380800000',
  },
  {
    code: '137604000',
    name: 'City of Parañaque',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381000000',
  },
  {
    code: '137605000',
    name: 'Pasay City',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381100000',
  },
  {
    code: '137607000',
    name: 'City of Taguig',
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381500000',
  },
  {
    code: OTHERS_VALUE,
    name: OTHERS_LABEL,
    oldName: '',
    isCapital: false,
    provinceCode: false,
    districtCode: '',
    regionCode: '',
    islandGroupCode: '',
    psgc10DigitCode: '',
  },
];

export const NEAR_ME = 'Near Me';

export const NEAR_METRO_MANILA = 'Near Metro Manila';
