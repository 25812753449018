import { useQuery } from '@tanstack/react-query';

import { TagParams, getAllTags, getTagById } from '../../api';
import { GET_ALL_ESHOP_TAGS, GET_ESHOP_TAG_BY_ID } from '../../constants';
import { Tag } from '../../types';

export const useGetAllTags = (params?: TagParams, enabled = true) => {
  return useQuery([GET_ALL_ESHOP_TAGS, params], async () => getAllTags(params ?? {}), {
    enabled,
  });
};

export const useGetTagById = (id: string, enabled = true) => {
  return useQuery<Tag>([GET_ESHOP_TAG_BY_ID, id], () => getTagById(id), {
    enabled,
  });
};
