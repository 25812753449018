export enum ScheduleTabEnum {
  OVERVIEW = 'overview',
  REGULAR = 'regular',
  OVERRIDE = 'override',
  BLOCK = 'block',
  CUSTOM = 'custom',
}

export const ScheduleTabStatusLabels: Record<string, string> = {
  [ScheduleTabEnum.OVERVIEW]: 'Overview',
  [ScheduleTabEnum.REGULAR]: 'Regular',
  [ScheduleTabEnum.OVERRIDE]: 'Specific Schedule',
  [ScheduleTabEnum.BLOCK]: 'Block Availability',
  [ScheduleTabEnum.CUSTOM]: 'Service Schedule',
};

export const ScheduleStatusDescription: Record<string, string> = {
  [ScheduleTabEnum.OVERVIEW]:
    'View the schedules per day and week. View all set regular, specific, and blocked schedules.',
  [ScheduleTabEnum.REGULAR]: 'Set default schedules for every day.',
  [ScheduleTabEnum.OVERRIDE]:
    'Set a different schedule on certain dates, it will override the regular schedule',
  [ScheduleTabEnum.BLOCK]:
    'Set unavailable schedules on certain dates and time. It overrides regular  and specific schedule.',
  [ScheduleTabEnum.CUSTOM]: 'Customize schedules for services',
};

export enum ScheduleTypeEnum {
  REGULAR = 'regular',
  OVERRIDE = 'override',
  BLOCK = 'block',
  CUSTOM = 'custom',
}
