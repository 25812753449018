import React from 'react';

import Button from './Button/Button';

type Props = {
  label?: string;
  handleLogin: () => void;
};

export default function PatientLoginCta(props: Props) {
  const {
    label = 'To proceed, you must be an mWell HealthHub member. Please log in or register.',
    handleLogin,
  } = props;

  const handleClickLogin = () => {
    handleLogin();
  };

  return (
    <div>
      <p className="text-base text-gray6">{label}</p>
      <div className="h-4"></div>
      <Button variant="secondary" onClick={handleClickLogin}>
        Login / Register
      </Button>
    </div>
  );
}
