import { createContext, useReducer } from 'react';

import { ClientProfile } from '@healthhub/api-lib';

type State = {
  clientProfiles: ClientProfile[];
  clientProfile: ClientProfile | null;
};

type Action = {
  payload: any;
  type: string;
};

type ClientProfileContextProviderProps = {
  children: React.ReactNode;
};

const initialState = {
  clientProfiles: [],
  clientProfile: null,
};

export const ClientProfileContextActions = {
  SET_CLIENT_PROFILES: 'SET_CLIENT_PROFILES',
  SET_CLIENT_PROFILE: 'SET_CLIENT_PROFILE',
  SET_CLIENT_PROFILE_FOR_UPDATE: 'SET_CLIENT_PROFILE_FOR_UPDATE',
  SET_UPDATED_CLIENT_PROFILE: 'SET_UPDATED_CLIENT_PROFILE',
  SET_ADD_CLIENT_PROFILE: 'SET_ADD_CLIENT_PROFILE',
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ClientProfileContextActions.SET_CLIENT_PROFILES:
      return {
        ...state,
        clientProfiles: action.payload,
      };
    case ClientProfileContextActions.SET_CLIENT_PROFILE:
      return {
        ...state,
        clientProfile: action.payload,
      };
    case ClientProfileContextActions.SET_UPDATED_CLIENT_PROFILE: {
      const updatedProfileIndex = state.clientProfiles.findIndex(
        (profile) => profile.id === action.payload.id,
      );

      if (updatedProfileIndex === -1) {
        // If the profile to update is not found, return the current state.
        return state;
      }

      const updatedClientProfiles = [...state.clientProfiles];
      updatedClientProfiles[updatedProfileIndex] = action.payload;

      return {
        ...state,
        clientProfiles: updatedClientProfiles,
      };
    }

    case ClientProfileContextActions.SET_ADD_CLIENT_PROFILE:
      return {
        ...state,
        clientProfiles: [...state.clientProfiles, action.payload],
      };
    default:
      return state;
  }
};

export const ClientProfileContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const ClientProfileContextProvider = (props: ClientProfileContextProviderProps) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientProfileContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientProfileContext.Provider>
  );
};
