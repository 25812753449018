import { DetailedHTMLProps, InputHTMLAttributes, createRef, useEffect } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useRouter } from '@mwell-healthhub/commons';

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  shouldFocus?: boolean;
};

const SearchField = (props: Props) => {
  const router = useRouter();
  const { query } = router;
  const inputRef = createRef<HTMLInputElement>();
  const { className, defaultValue, placeholder, shouldFocus, ...extras } = props;

  useEffect(() => {
    if (shouldFocus && router.isReady) {
      inputRef.current?.focus();
    }
  }, [shouldFocus, router.isReady]);

  function handleClear() {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    const newQuery = { ...query };

    delete newQuery.query;
    delete newQuery.location;
    delete newQuery.position;

    router.replace({
      query: newQuery,
    });
  }

  return (
    <div className={className}>
      <div className="shadow-sm relative rounded-md">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-primary-500" aria-hidden="true" />
        </div>
        <input
          className="block w-full rounded-md border-0 bg-primary-500/10 py-2 pl-10 pr-8 tracking-tight text-gray-900 ring-1 ring-inset ring-primary-500/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 focus-visible:outline-none sm:text-sm sm:leading-6"
          defaultValue={defaultValue}
          id="search"
          name="search"
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          {...extras}
        />

        {query.query && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute inset-y-0 right-0 items-center px-2"
          >
            <XMarkIcon className="h-5 w-5 text-primary-500" aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchField;
