import React from 'react';

interface MeasurementProps {
  label: string;
  measurement?: string;
  value: string;
}

const Measurement: React.FC<MeasurementProps> = ({ label, measurement, value }) => {
  return (
    <div>
      <div className="text-lg">
        <span className="mr-2 font-bold">{value}</span>
        {measurement && <span className="text-md font-bold text-gray-400">{measurement}</span>}
      </div>
      <div>
        <span className="text-gray-500">{label}</span>
      </div>
    </div>
  );
};

export default Measurement;
