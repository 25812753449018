import {
  UserOperation,
  UserOperationUserAppTypeEnum,
  UserOperationUserTypeEnum,
} from '@healthhub/api-lib';
import isEqual from 'lodash.isequal';

import { capitalize } from './common';
import { SHOW_UNIFIED_ADMIN_ACCESS } from '../constants';
import { Routes } from '../enums';
import { TransactionMerchantProduct } from '../types';

export function checkEshopAdminPage(user: UserOperation) {
  if (SHOW_UNIFIED_ADMIN_ACCESS) {
    return undefined;
  }

  if (user?.userAppType !== UserOperationUserAppTypeEnum.Eshop) {
    return {
      redirect: {
        destination: Routes.ORDERS,
        permanent: false,
      },
    };
  } else if (user?.userType === UserOperationUserTypeEnum.Finance) {
    return {
      redirect: {
        destination: Routes.TRANSACTIONS,
        permanent: false,
      },
    };
  }
}

export function getAllDuplicateIndexes(arr: any[]) {
  const duplicateIndexes: number[] = [];

  arr.forEach((obj, index) => {
    const findValue = arr.find((attr, attrIndex) => isEqual(obj, attr) && attrIndex !== index);

    if (findValue) {
      duplicateIndexes.push(index);
    }
  });

  return duplicateIndexes;
}

export function getVariantLabel(data: TransactionMerchantProduct) {
  const keys = Object.keys(data.variant?.attributeValues || {}),
    values = Object.values(data.variant?.attributeValues || {});
  const label: string[] = [];

  for (const [idx, value] of values.entries()) {
    if (!isNaN(Number(value))) {
      label.push(`${capitalize(keys[idx])}: ${Number(value)}`);
      continue;
    }

    label.push(capitalize(value as string));
  }

  return `${data.product.name} ${label.length ? `(${label.join(', ')})` : ''}`;
}
