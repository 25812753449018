import { useEffect, useRef, useState } from 'react';

import { User } from '@healthhub/api-lib';
import { GoogleAnalytics } from '@next/third-parties/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import isEmpty from 'lodash.isempty';
import { NextComponentType, NextPageContext } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import NextTopLoader from 'nextjs-toploader';
import { ToastContainer } from 'react-toastify';

import {
  ACCESS_TOKEN,
  ErrorProvider,
  FROM_MWELL_APP,
  HIDE_NAVIGATION,
  LoadingSpinner,
  USER,
  USER_ABILITIES,
  calculateAge,
  getLocalStorageItem,
  getQueryParams,
  setLocalStorageItem,
  syncCurrentUserWithMWellData,
  useGetCurrentUser,
  useRouter,
} from '@mwell-healthhub/commons';
import adjustService from '@mwell-healthhub/commons/utils/adjust';
import { trackEvent, EventName, setAnalyticsUser } from '@mwell-healthhub/commons/utils/analytics';

import 'react-toastify/dist/ReactToastify.css';
import { ShareFeedbackModal } from '../components';
import { Routes } from '../constants';
import SEO from '../constants/nextSEO.config';
import { ClientProfileContextProvider } from '../contexts/clientProfile';
import { HmoContextProvider } from '../contexts/hmoContext';
import { ProviderContextProvider } from '../contexts/provider';
import { useRemoveHover } from '../hooks';
import { useClearProviderContext } from '../hooks/useClearProviderContext';
import { checkIsMissingUserDetails, MWELL_LOGIN_PARAMS } from '../utils';
import { checkIfAutomationUser } from '../utils/automations';

import './styles.css';
type GetLayoutFunction = (page: JSX.Element) => JSX.Element;

type PageWithLayout<P = NonNullable<unknown>> = NextComponentType<NextPageContext, any, P> & {
  getLayout?: GetLayoutFunction;
};

type Props = {
  Component: PageWithLayout;
  pageProps: AppProps['pageProps'];
};

function PatientApp({ Component, pageProps }: Props) {
  const router = useRouter();
  const getLayout: GetLayoutFunction = Component.getLayout || ((page) => page);
  const activeHeading = 'HealthHub';
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const hasSyncedRef = useRef<boolean>(false);
  const { isLoading: isLoadingUser, user } = useGetCurrentUser<User>();
  const isFromMwellApp = getLocalStorageItem(FROM_MWELL_APP);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
          },
        },
      }),
  );

  const isWindowDefined = typeof window !== 'undefined';

  useEffect(() => {
    if (isWindowDefined) {
      adjustService.initializeAdjust();
    }
  }, [isWindowDefined]);

  useRemoveHover();

  useEffect(() => {
    if (!isLoadingUser && router.isReady && user.id && checkIsMissingUserDetails(user)) {
      router.replace(Routes.PROFILE_EDIT);
    }
  }, [isLoadingUser, user, router.asPath]);

  useEffect(() => {
    async function fetchThenStoreCurrentUserInLocalStorage() {
      if (user?.id) {
        setLocalStorageItem(ACCESS_TOKEN, '');
        setLocalStorageItem(USER_ABILITIES, []);
        setLocalStorageItem(USER, {
          ...user,
          main_profile_id: user.mainProfile?.id,
        });

        if (checkIfAutomationUser(user)) {
          return;
        }

        const searchParams = getQueryParams(window.location.search);
        const isLoggedInFromMwellApp = Boolean(
          searchParams.token && searchParams.refreshToken && searchParams.platform,
        );

        if (isLoggedInFromMwellApp) {
          trackEvent(EventName.CLICK_HEALTHHUB_BUTTON);

          setLocalStorageItem(HIDE_NAVIGATION, true);
          setLocalStorageItem(FROM_MWELL_APP, true);

          [...MWELL_LOGIN_PARAMS, 'path'].forEach((loginKey) => {
            delete searchParams[loginKey];
          });

          router.replace({
            pathname: window.location.pathname,
            query: searchParams,
          });
        }
      }

      setAnalyticsUser(user?.mWellUserId, {
        gender: user?.sex || '',
        age: user?.birthDate ? calculateAge(new Date(user.birthDate)) : '',
        is_from_mwell_app: isFromMwellApp || false,
        user_id: user?.mWellUserId,
        mwell_user_id: user?.mWellUserId,
      });
    }

    fetchThenStoreCurrentUserInLocalStorage().finally(() => {
      setIsInitializing(false);
    });
  }, [user]);

  useEffect(() => {
    if (!isEmpty(user) && !hasSyncedRef.current) {
      hasSyncedRef.current = true;

      syncCurrentUserWithMWellData();
    }
  }, [user]);

  useClearProviderContext();

  return (
    <>
      <DefaultSeo {...SEO} />
      <Script
        id="google-ads-script"
        async // Loads the Google Ads script asynchronously
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS}`}
      />
      <Script
        id="google-ads-config"
        strategy="afterInteractive" // Ensure it executes after initial page content is loaded, aiding performance
      >
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS}');
        `}
      </Script>

      <Script id="moengage-analytics" strategy="afterInteractive">
        {`
          const moeDataCenter = '${process.env.NEXT_PUBLIC_MOENGAGE_DATA_CENTER ?? ''}';
          const moeAppID = '${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID ?? ''}';

          !function(e,n,i,t,a,r,o,d){if(!moeDataCenter||!moeDataCenter.match(/^dc_[0-9]+$/gm))return console.error("Data center has not been passed correctly. Please follow the SDK installation instruction carefully.");var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","update_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/release/"+moeDataCenter+"/moe_webSdk.min.latest.js","Moengage");

          Moengage = moe({
            app_id: moeAppID,
            debug_logs: Number(${process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_LOGS ?? 0})
          });
        `}
      </Script>
      <Head>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static-assets/favicon-32px.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static-assets/favicon-16px.png?v=2"
        />
        <title>{activeHeading}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <ErrorProvider>
        <QueryClientProvider client={queryClient}>
          <ProviderContextProvider>
            <ClientProfileContextProvider>
              <HmoContextProvider>
                <NextTopLoader showSpinner={false} />
                {isInitializing && (
                  <div className="flex min-h-screen items-center justify-center">
                    <LoadingSpinner />
                  </div>
                )}
                <GoogleAnalytics
                  gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS as string}
                  debugMode={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_DEBUG_MODE === 'true'}
                />
                <div className={!isInitializing ? 'block' : 'hidden'}>
                  {getLayout(<Component {...pageProps} />)}
                </div>
                <ShareFeedbackModal user={user} />
                <ReactQueryDevtools initialIsOpen={false} />
                <ToastContainer hideProgressBar enableMultiContainer />
              </HmoContextProvider>
            </ClientProfileContextProvider>
          </ProviderContextProvider>
        </QueryClientProvider>
      </ErrorProvider>
    </>
  );
}

export default PatientApp;
