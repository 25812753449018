import {
  AppointmentService,
  AppointmentServicesApi,
  UpdateMassAppointmentServiceDto,
} from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';

const AppointmentServiceApi = HealthHubApiClient.use(AppointmentServicesApi);

export const massUpdateAppointmentServices = async (
  updateMassAppointmentServiceDto: UpdateMassAppointmentServiceDto,
): Promise<AppointmentService[]> => {
  try {
    const { data } = await AppointmentServiceApi.appointmentServicesControllerMassUpdate(
      updateMassAppointmentServiceDto,
    );

    return data;
  } catch (error) {
    throw new Error('Failed to mass update appointment services');
  }
};
