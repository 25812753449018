import React from 'react';

import { format } from 'date-fns';
import { UseFormRegisterReturn } from 'react-hook-form';

import TextInput from './TextInput';
import { ISO_8601_FORMAT } from '../constants/dateFormats';
import { clsxMerge } from '../utils';

type Props = {
  label?: string;
  errorMessage?: string;
  register?: UseFormRegisterReturn;
  className?: string;
};

export default function DateTimePicker(props: Props) {
  const { label, errorMessage, register, className } = props;

  // Use date-fns to format the current date and time
  const currentDateTime = format(new Date(), ISO_8601_FORMAT);

  return (
    <div className={clsxMerge('flex flex-col gap-2', className)}>
      {label && (
        <label htmlFor="date-time-picker" className="text-sm tracking-wide text-neutral-800">
          {label}
        </label>
      )}
      <TextInput
        {...register}
        type="datetime-local"
        id="date-time-picker"
        min={currentDateTime}
        className="shadow-sm focus:border-indigo-500 focus:ring-indigo-500 rounded-md border border-gray-300 p-2"
        errorMessage={errorMessage}
      />
    </div>
  );
}
