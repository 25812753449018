import isDate from 'date-fns/isDate';
import isFunction from 'lodash.isfunction';
import isObject from 'lodash.isobject';
import transform from 'lodash.transform';

type AnyObject = Record<string, any>;

export function omitDeep(obj: AnyObject, paths: string[], parentPath?: string): AnyObject {
  if (Array.isArray(obj)) {
    // Process each element in the array if it's an array
    return obj.map((item) => omitDeep(item, paths, parentPath));
  } else if (isObject(obj) && !isFunction(obj)) {
    // Process each property if it's an object
    return transform(
      obj,
      (result: AnyObject, value: any, key: string) => {
        const fullPath = parentPath ? `${parentPath}.${key}` : key;

        if (isObject(value) && !isDate(value)) {
          // Recursively call omitDeep for nested objects or arrays
          value = omitDeep(value, paths, fullPath);
        }

        // Check if the current path is part of the paths to omit
        if (!paths.includes(fullPath)) {
          result[key] = value;
        }
      },
      {},
    );
  }

  return obj;
}
