import { Fragment } from 'react';

import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

type Props = {
  active: string;
  tabs: {
    id: string;
    label: string;
    link: string;
  }[];
  color?: 'primary' | 'secondary';
};

export default function Tabs(props: Props) {
  const { active, tabs, color = 'primary' } = props;

  const defaultTabClasses =
    'flex-1 py-2 text-center text-sm text-neutral-400 md:flex-initial md:px-4 md:pt-4';

  const activeTabClasses = 'border-b-2 font-medium ';

  const TAB_VARIANT_CLASSES: Record<string, string> = {
    primary: 'text-primary-500 border-primary-500',
    secondary: 'text-secondary-500 border-secondary-500',
  };

  const variantClass = TAB_VARIANT_CLASSES[color];

  const mobileView = (
    <div className="flex justify-between">
      {tabs.map((tab) => (
        <Link
          key={tab.id}
          href={tab.link}
          className={twMerge(
            defaultTabClasses,
            active === tab.id && activeTabClasses + variantClass,
          )}
        >
          {tab.label}
        </Link>
      ))}
    </div>
  );

  const desktopView = (
    <div className="flex">
      {tabs.map((tab) => (
        <Link
          key={tab.id}
          href={tab.link}
          className={twMerge(
            'px-6 py-2 text-center text-sm text-neutral-400',
            active === tab.id && 'border-b-2 border-secondary-500 font-medium text-secondary-500',
          )}
        >
          {tab.label}
        </Link>
      ))}
    </div>
  );

  return (
    <Fragment>
      <div className="sm:hidden">{mobileView}</div>
      <div className="hidden sm:block">{desktopView}</div>
    </Fragment>
  );
}
