import { RescheduleAppointmentDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  addAppointment,
  rescheduleAppointment,
  uploadDiagnosticFile,
} from '@mwell-healthhub/commons/api';
import { UseMutationOptionsType } from '@mwell-healthhub/commons/types';

export const useUploadDiagnosticFile = (mutationOptions: UseMutationOptionsType) =>
  useMutation(uploadDiagnosticFile, mutationOptions);

export const useAddNewAppointment = (mutationOptions: UseMutationOptionsType) =>
  useMutation(addAppointment, mutationOptions);

export const useReschedule = (
  mutationOptions?: MutationOptions<
    any,
    AxiosError<{ message: string }>,
    { id: number; rescheduleDto: RescheduleAppointmentDto }
  >,
) =>
  useMutation(
    (params) =>
      rescheduleAppointment({ id: params.id, rescheduleAppointmentDto: params.rescheduleDto }),
    mutationOptions,
  );
