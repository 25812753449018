import { ReactNode } from 'react';

import { clsxMerge } from '../../utils';

export enum BadgeColorType {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
  PRIMARY = 'primary',
  CARD_SUCCESS = 'cardSuccess',
  NONE = 'none',
  FREE = 'free',
  REQUIRED = 'required',
}

type Props = {
  label: string;
  containerClassName?: string;
  type?: BadgeColorType;
  startIcon?: ReactNode;
};

const color = {
  danger: 'bg-red-100 text-red-800',
  warning: 'bg-yellow-100 text-yellow-800',
  success: 'bg-green-100 text-green-800',
  primary: 'text-primary400 bg-lightPrimary',
  cardSuccess: 'text-primary2 bg-paleGreen',
  none: 'text-gray17 bg-neutral25',
  free: 'bg-secondary-500 text-secondary-100',
  required: 'bg-amber-100 text-yellow-600',
};

export default function Badge(props: Props) {
  const { startIcon, label, containerClassName, type = 'primary' } = props;
  const colorClass = color[type];

  return (
    <span
      className={clsxMerge(
        'whitespace-nowrap rounded-2sm px-1 text-xs font-medium',
        {
          [colorClass]: colorClass,
          'flex flex-col items-center gap-x-1': !!startIcon,
        },
        containerClassName,
      )}
    >
      {startIcon}
      {label}
    </span>
  );
}
