import { PromotionApi, PromotionWithProvidersDto } from '@healthhub/api-lib';

import { CustomError } from '@mwell-healthhub/commons';
import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';

const promotionApi = HealthHubApiClient.use(PromotionApi);

export async function getPromotionById(id: string): Promise<PromotionWithProvidersDto> {
  try {
    const response = await promotionApi.promotionControllerFindOne(id);
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getAllPublishedPromotions(): Promise<PromotionWithProvidersDto[]> {
  try {
    const response = await promotionApi.promotionControllerFindAllPublishedPromotions();
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}
