import { ClientFavourite, RemoveFavouriteDto, SaveFavouriteDto } from '@healthhub/api-lib';
import { saveClientFavourite, removeClientFavourite } from '@mwell-healthhub/commons';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useSaveClientFavouriteMutation = (
  mutationOptions?: MutationOptions<
    ClientFavourite,
    AxiosError<{ message: string }>,
    SaveFavouriteDto
  >,
) => useMutation(saveClientFavourite, mutationOptions);

export const useRemoveClientFavouriteMutation = (
  mutationOptions?: MutationOptions<
    ClientFavourite,
    AxiosError<{ message: string }>,
    RemoveFavouriteDto
  >,
) => useMutation(removeClientFavourite, mutationOptions);
