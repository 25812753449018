type Props = {
  height?: number;
  width?: number;
  image: string;
  alt?: string;
};

const Icon = (props: Props) => {
  const { height, width, image, alt } = props;

  const renderLogo = (
    <img
      src={image}
      alt={alt ?? ''}
      style={{
        height: height ? `${height}px` : undefined,
        width: width ? `${width}px` : undefined,
      }}
    />
  );

  return renderLogo;
};

export default Icon;
