/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EshopApi,
  TransactionExternalPaymentStatusEnum,
  UpdateTransactionMerchantDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash.get';

import HealthHubApiClient from './api-client';
import { OrderByEnum, OrderStatusEnum, PaymentStatusEnum } from '../enums';
import { CustomError } from '../errors';
import { OrderStatistics, Pagination, TransactionMerchant } from '../types';

export const orderApi = HealthHubApiClient.use(EshopApi);

export type UpdateOrderParams = {
  id: string;
  updateTransactionDto: UpdateTransactionMerchantDto;
};

export type OrderParams = {
  limit?: number;
  page?: number;
  query?: string;
  orderBy?: OrderByEnum;
  status?: OrderStatusEnum;
  paymentStatus?: PaymentStatusEnum;
  externalPaymentStatus?: TransactionExternalPaymentStatusEnum;
  merchantId?: string;
  merchantEmail?: string;
  productId?: string;
  dateFrom?: string;
  dateTo?: string;
  deliveryDateTo?: string;
  deliveryDateFrom?: string;
};

export async function getAllOrders(params: OrderParams): Promise<Pagination<TransactionMerchant>> {
  try {
    const {
      query,
      status,
      limit,
      page,
      externalPaymentStatus,
      merchantEmail,
      merchantId,
      paymentStatus,
      orderBy,
      productId,
      dateFrom,
      dateTo,
      deliveryDateFrom,
      deliveryDateTo,
    } = params;
    const { data } = await orderApi.eshopControllerFindAllTransactionMerchants(
      query,
      merchantEmail,
      externalPaymentStatus,
      status,
      paymentStatus,
      orderBy,
      dateFrom,
      deliveryDateTo,
      deliveryDateFrom,
      dateTo,
      merchantId,
      productId,
      limit,
      page,
    );

    return data as unknown as Pagination<TransactionMerchant>;
  } catch (err) {
    throw new Error('Failed to fetch orders');
  }
}

export async function getOrderById(
  id: string,
  config?: AxiosRequestConfig,
): Promise<TransactionMerchant> {
  try {
    const response = await orderApi.eshopControllerFindOneOrder(id, config);
    const data = response.data;

    return data as unknown as TransactionMerchant;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getOrderCounts(merchantEmail = ''): Promise<OrderStatistics> {
  try {
    let data;

    if (merchantEmail) {
      const response = await orderApi.eshopControllerGetOrderCounts(merchantEmail);
      data = response.data;
    } else {
      const response = await orderApi.eshopControllerGetAllOrderCounts();
      data = response.data;
    }

    return data as unknown as OrderStatistics;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateOrderStatus(params: UpdateOrderParams): Promise<TransactionMerchant> {
  const { id, updateTransactionDto } = params;

  try {
    const { data } = await orderApi.eshopControllerUpdateOrderStatus(id, updateTransactionDto);

    return data as unknown as TransactionMerchant;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to update order');
  }
}
