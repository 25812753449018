export type RegisterUser = {
  email: string;
  password: string;
  confirmPassword: string;
};

export type FirebaseUser = {
  uid: string;
  disabled?: boolean;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
};

export type UserCredentials = {
  email: string;
  password: string;
};

export enum UserType {
  CONCIERGE = 'CONCIERGE',
  CONSULTANT = 'CONSULTANT',
  DOCTOR = 'DOCTOR',
  FINANCE = 'FINANCE',
  FULFILLMENT_CENTER_ADMIN = 'FULFILLMENT_CENTER_ADMIN',
  HOSPITAL_ADMIN = 'HOSPITAL_ADMIN',
  HOSPITALIST = 'HOSPITALIST',
  IMAGING_ADMIN = 'IMAGING_ADMIN',
  IMAGING_USER = 'IMAGING_USER',
  LABORATORY_ADMIN = 'LABORATORY_ADMIN',
  LABORATORY_USER = 'LABORATORY_USER',
  PATIENT = 'PATIENT',
  PHARMACY_ADMIN = 'PHARMACY_ADMIN',
  PHARMACY_USER = 'PHARMACY_USER',
  SECRETARY = 'SECRETARY',
  SUPER_ADMIN = 'SUPER_ADMIN',
  PROVIDER_ADMIN = 'PROVIDER_ADMIN',
  PROVIDER_DOCTOR = 'PROVIDER_DOCTOR',
  PROVIDER_STAFF = 'PROVIDER_STAFF',
  OPERATION = 'OPERATION',
}

export enum OperationUserType {
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING',
  FINANCE = 'FINANCE',
  MERCHANT = 'MERCHANT',
}

export enum ProviderUserType {
  BRANCH_ADMIN = 'BRANCH_ADMIN',
  BRAND_ADMIN = 'BRAND_ADMIN',
  ESHOP_MERCHANT = 'ESHOP_MERCHANT',
  SPECIALIST = 'SPECIALIST',
  STAFF = 'STAFF',
  SUPER_ADMIN = 'SUPER_ADMIN',
  PROVIDER_ADMIN = 'PROVIDER_ADMIN', // deprecated
}

export enum SexType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
