import { Controller, useFormContext } from 'react-hook-form';

import { TextInput, ProviderFormEnum, MobileNumberInput, CountryCodeType } from '../../';
import { findCountryCodeFromDialCode } from '../../utils/phoneNumber';

export type RepresentativeFormType = {
  id?: number;
  countryCode: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  phoneCountryCode: string;
  phoneNumber: string;
  title: string;
};

function RepresentativeFormFields() {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useFormContext<{
    representative: RepresentativeFormType;
  }>();

  const defaultCountryCode = findCountryCodeFromDialCode(
    getValues(ProviderFormEnum.REPRESENTATIVE_DIAL_CODE_MOBILE_NUMBER),
  );
  const defaultPhoneCountryCode = findCountryCodeFromDialCode(
    getValues(ProviderFormEnum.REPRESENTATIVE_DIAL_CODE_PHONE_NUMBER),
  );

  return (
    <>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:gap-6">
        <TextInput
          labelShown
          showRequiredOnLabel
          {...register(ProviderFormEnum.REPRESENTATIVE_FIRST_NAME)}
          errorMessage={errors?.representative?.firstName?.message}
          label="First Name"
          placeholder="First Name"
        />
        <TextInput
          labelShown
          {...register(ProviderFormEnum.REPRESENTATIVE_MIDDLE_NAME)}
          errorMessage={errors?.representative?.middleName?.message}
          label="Middle Name"
          placeholder="Middle Name"
        />
        <TextInput
          labelShown
          showRequiredOnLabel
          {...register(ProviderFormEnum.REPRESENTATIVE_LAST_NAME)}
          errorMessage={errors?.representative?.lastName?.message}
          label="Last Name"
          placeholder="Last Name"
        />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-4">
        <TextInput
          labelShown
          {...register(ProviderFormEnum.REPRESENTATIVE_TITLE)}
          errorMessage={errors?.representative?.title?.message}
          label="Title"
          placeholder="Title"
        />
        <TextInput
          labelShown
          showRequiredOnLabel
          {...register(ProviderFormEnum.REPRESENTATIVE_EMAIL)}
          errorMessage={errors?.representative?.email?.message}
          label="Email"
          placeholder="Email"
        />
        <div className="flex w-full flex-col">
          <Controller
            name={ProviderFormEnum.REPRESENTATIVE_MOBILE_NUMBER}
            control={control}
            render={({ field }) => (
              <MobileNumberInput
                showRequiredOnLabel
                label="Mobile Number"
                defaultCountryCode={defaultCountryCode}
                errorMessage={errors?.representative?.mobileNumber?.message}
                value={field.value}
                onChange={field.onChange}
                onChangeCountry={(countryCode: CountryCodeType) => {
                  setValue(
                    ProviderFormEnum.REPRESENTATIVE_DIAL_CODE_MOBILE_NUMBER,
                    countryCode.dial_code,
                  );
                }}
              />
            )}
          />
        </div>
        <div className="flex w-full flex-col">
          <Controller
            name={ProviderFormEnum.REPRESENTATIVE_PHONE_NUMBER}
            control={control}
            render={({ field }) => (
              <MobileNumberInput
                label="Telephone Number"
                defaultCountryCode={defaultPhoneCountryCode}
                errorMessage={errors?.representative?.phoneNumber?.message}
                value={field.value}
                onChange={field.onChange}
                onChangeCountry={(countryCode: CountryCodeType) => {
                  setValue(
                    ProviderFormEnum.REPRESENTATIVE_DIAL_CODE_PHONE_NUMBER,
                    countryCode.dial_code,
                  );
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default RepresentativeFormFields;
