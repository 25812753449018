import { HTMLProps, useEffect, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { filter, isEqual } from 'lodash';

import {
  hmoList,
  MultiSelectDropdownPopup,
  MultiSelectDropdownMobile,
  MultiSelectOptionType,
  clsxMerge,
} from '@mwell-healthhub/commons';
import useIsMobile from '@mwell-healthhub/commons/hooks/useIsMobile';

import { HmoFilterButton } from '../atoms';

const OPTION_HMO_LIST: MultiSelectOptionType[] = [...hmoList]
  .filter((hmo) => hmo.isActive)
  .sort((hmoA, hmoB) => hmoA.name.localeCompare(hmoB.name))
  .map((hmo) => ({
    text: hmo.name,
    value: hmo.id.toString(),
  }));

const HMO_FILTERS_FIELD_KEY = 'hmoFilters';

type Props = HTMLProps<HTMLDivElement> & {
  appliedHmoFilters: MultiSelectOptionType[];
  onRemoveHmoFilter: (hmo: MultiSelectOptionType) => void;
};

const HmoFilterBadges = ({ appliedHmoFilters, onRemoveHmoFilter, className }: Props) => {
  return (
    <div
      className={clsxMerge(
        'scrollbar-none -mx-6 flex grow basis-full items-center gap-1 overflow-x-auto px-6 md:mx-0 md:flex-wrap md:gap-2 md:overflow-hidden md:px-0',
        className,
      )}
    >
      <span className="hidden text-sm font-medium text-neutral-600 md:block">Filters: </span>
      {appliedHmoFilters.map((hmo, index) => {
        return (
          <span
            key={index}
            className="border-box flex w-auto flex-nowrap items-center gap-x-1 whitespace-nowrap rounded-full border border-primary-400 bg-neutral-50 py-1.5 pl-4 pr-3 text-xs font-medium text-primary-400"
          >
            {hmo.text}{' '}
            <XMarkIcon
              className="h-4 w-4 cursor-pointer text-base"
              onClick={() => onRemoveHmoFilter(hmo)}
            />
          </span>
        );
      })}
    </div>
  );
};

export default HmoFilterBadges;
