import { useEffect, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

type TooltipProps = {
  anchorId: string;
  description: string;
  isVisible: boolean;
  sidebarId: string;
  title: string;
};

function SidebarTooltip({ anchorId, title, description, isVisible, sidebarId }: TooltipProps) {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anchorElement = document.getElementById(anchorId);
    const sidebarElement = document.getElementById(sidebarId);

    if (!anchorElement || !sidebarElement) return;

    const updatePosition = () => {
      const anchorRect = anchorElement.getBoundingClientRect();
      const sidebarRect = sidebarElement.getBoundingClientRect();

      const anchorCenterY = anchorRect.top + anchorRect.height / 2;
      const gap = 20;

      setPosition({
        top: anchorCenterY + window.scrollY,
        left: sidebarRect.right + window.scrollX + gap,
      });
    };

    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(sidebarElement);

    window.addEventListener('resize', updatePosition);
    sidebarElement.addEventListener('scroll', updatePosition);
    updatePosition();

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updatePosition);
      sidebarElement.removeEventListener('scroll', updatePosition);
    };
  }, [anchorId, sidebarId, isVisible]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div
      ref={tooltipRef}
      className="shadow-lg fixed z-50 max-w-[380px] -translate-y-1/2 transform rounded-lg bg-primary-100 p-5"
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      <div
        className="absolute left-0 top-1/2 h-0 w-0 
                      -translate-x-full -translate-y-1/2 
                      transform border-b-8 
                      border-r-[12px] border-t-8
                      border-b-transparent border-r-primary-100 border-t-transparent"
      ></div>
      <h2 className="mb-1 text-lg font-semibold text-gray-900">{title}</h2>
      <p className="whitespace-pre-line text-sm font-medium text-neutral-600">{description}</p>
    </div>,
    document.body,
  );
}

export default SidebarTooltip;
