import { SHOW_ESHOP_V2 } from './featureFlags';
import { AspectRatio, DisplayType, OrderStatusEnum, TagType } from '../enums';

export const ORDER_PAYMENT_STATUS = {
  PAID: 'Paid',
  NOT_PAID: 'Not Paid',
};

export const ORDER_FULFILLMENT_STATUS = {
  FULFILLED: 'Fulfilled',
  NOT_FULFILLED: 'Not Fulfilled',
};

export const ORDER_STATUSES = SHOW_ESHOP_V2
  ? [
      { name: 'Pending', id: OrderStatusEnum.Pending },
      { name: 'Confirmed', id: OrderStatusEnum.Confirmed },
      { name: 'Processing', id: OrderStatusEnum.Processing },
      { name: 'In Transit', id: OrderStatusEnum.Shipped },
      { name: 'Completed', id: OrderStatusEnum.Completed },
      { name: 'Cancelled', id: OrderStatusEnum.Cancelled },
    ]
  : [
      { name: 'Pending', id: OrderStatusEnum.Pending },
      { name: 'Confirmed', id: OrderStatusEnum.Confirmed },
      { name: 'Completed', id: OrderStatusEnum.Completed },
      { name: 'Cancelled', id: OrderStatusEnum.Cancelled },
    ];

export const LIMITS = [
  { name: '25 Entries', id: 25 },
  { name: '50 Entries', id: 50 },
  { name: '100 Entries', id: 100 },
];

export const TAG_TYPES = [
  { name: 'Product', id: TagType.Product },
  { name: 'Merchant', id: TagType.Merchant },
];

export const DISPLAY_TYPES = [
  { name: 'Grid', id: DisplayType.Grid },
  { name: 'Horizontal', id: DisplayType.Horizontal },
  { name: 'Vertical', id: DisplayType.Vertical },
];

export const AspectRatioErrors: Record<string, string> = {
  [`ERR${AspectRatio._1_1}`]: 'Image aspect ratio should be 1:1',
  [`ERR${AspectRatio._21_9}`]: 'Image aspect ratio should be 21:9',
};

export const DUPLICATE_VARIANT_ATTRIBUTE_ERROR = 'Duplicate attributes are not allowed.';

export const API_TRANSACTION = [
  { name: 'ID', id: 'id' },
  { name: 'Total Amount', id: 'totalAmount' },
  { name: 'Fee', id: 'transactionFee' },
  { name: 'Payment Method', id: 'paymentMethod' },
  { name: 'Payment Reference Number', id: 'paymentReferenceNumber' },
];

export const API_PRODUCTS = [
  { name: 'ID', id: 'id' },
  { name: 'Name', id: 'name' },
  { name: 'SKU', id: 'productId' },
];

export const API_TRANSACTION_PRODUCT = [
  { name: 'Name', id: 'productName' },
  { name: 'Quantity', id: 'quantity' },
  { name: 'Price', id: 'price' },
  { name: 'Buy as Gift', id: 'isBuyAsGift' },
];

export const API_TRANSACTION_USER = [
  { name: 'ID', id: 'id' },
  { name: 'MWell User ID', id: 'mWellUserId' },
  { name: 'Email', id: 'email' },
  { name: 'Mobile Phone Number', id: 'mobilePhoneNumber' },
  { name: 'First Name', id: 'firstName' },
  { name: 'Last Name', id: 'lastName' },
  { name: 'Middle Name', id: 'middleName' },
  { name: 'Sex', id: 'sex' },
  { name: 'Date of Birth', id: 'birthDate' },
];

export const API_VARIANT = [
  { name: 'SKU', id: 'sku' },
  { name: 'Price', id: 'price' },
  { name: 'Attributes', id: 'attributeValues' },
];
