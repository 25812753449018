import { EntityType } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateProviderType,
  UpdateProviderType,
  createProviderType,
  updateProviderType,
  deleteProviderType,
} from '../../api/providerTypeService';

export const useCreateProviderTypeMutation = (
  mutationOptions?: MutationOptions<
    EntityType,
    AxiosError<{ message: string }>,
    CreateProviderType
  >,
) => useMutation(createProviderType, mutationOptions);

export const useUpdateProviderTypeMutation = (
  mutationOptions?: MutationOptions<
    EntityType,
    AxiosError<{ message: string }>,
    { id: string; updateProviderTypeDto: UpdateProviderType }
  >,
) => {
  return useMutation(
    (params) => updateProviderType(params.id, params.updateProviderTypeDto),
    mutationOptions,
  );
};

export const useProviderTypeDeleteMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, string>,
) => {
  return useMutation((id: string) => deleteProviderType(id), mutationOptions);
};
