import Badge from './Badge';
import { BadgeType } from '../../enums/badgeType';
import { ProviderStatusEnum } from '../../enums/provider-status';
import { toTitleCase } from '../../utils/string';

type Props = {
  status: ProviderStatusEnum;
};

function ProviderStatusBadge(props: Props) {
  const { status } = props;

  let badgeType;

  switch (status) {
    case ProviderStatusEnum.ACTIVE:
      badgeType = BadgeType.BLUE;
      break;
    case ProviderStatusEnum.PUBLISHED:
      badgeType = BadgeType.GREEN;
      break;
    case ProviderStatusEnum.UNPUBLISHED:
      badgeType = BadgeType.GREY;
      break;
    case ProviderStatusEnum.INACTIVE:
      badgeType = BadgeType.RED;
      break;
  }

  return <Badge type={badgeType}>{toTitleCase(status)}</Badge>;
}

export default ProviderStatusBadge;
