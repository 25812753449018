/* tslint:disable */
/* eslint-disable */
/**
 * mWell Health Hub API
 * mWell Health Hub API Specification
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'unitRoomFloor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'buildingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lotBlockPhaseHouse'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'municipalityCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'foreignCountryAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipCode': string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'googleMapsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'line'?: string;
    /**
     * 
     * @type {object}
     * @memberof Address
     */
    'geoLocation'?: object;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressType': AddressAddressTypeEnum;
    /**
     * 
     * @type {Region}
     * @memberof Address
     */
    'region'?: Region | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'locality'?: string;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof Address
     */
    'appointments'?: Array<Appointment> | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'deletedAt'?: string;
}

export const AddressAddressTypeEnum = {
    Mailing: 'MAILING',
    Permanent: 'PERMANENT',
    Current: 'CURRENT'
} as const;

export type AddressAddressTypeEnum = typeof AddressAddressTypeEnum[keyof typeof AddressAddressTypeEnum];

/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'locality': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'streetAddress': string;
}
/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {number}
     * @memberof Amount
     */
    'value': number;
    /**
     * 
     * @type {Details}
     * @memberof Amount
     */
    'details'?: Details;
}
/**
 * 
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'target': AnnouncementTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'targetSpecification': string;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'minAge': number;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'maxAge': number;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'sex': AnnouncementSexEnum;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'messageContent': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'redirectLink': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'scheduleAnnouncement': string;
    /**
     * 
     * @type {boolean}
     * @memberof Announcement
     */
    'hasBeenSent': boolean;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'totalNotificationsSent'?: number;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'totalNotificationsFailed'?: number;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'totalNotifications'?: number;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'deletedAt'?: string;
}

export const AnnouncementTargetEnum = {
    All: 'all',
    Clients: 'clients',
    Providers: 'providers',
    ClientsThroughTargeting: 'clientsThroughTargeting',
    ProvidersThroughTargeting: 'providersThroughTargeting'
} as const;

export type AnnouncementTargetEnum = typeof AnnouncementTargetEnum[keyof typeof AnnouncementTargetEnum];
export const AnnouncementSexEnum = {
    All: 'all',
    Male: 'male',
    Female: 'female'
} as const;

export type AnnouncementSexEnum = typeof AnnouncementSexEnum[keyof typeof AnnouncementSexEnum];

/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {number}
     * @memberof ApiKey
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'secretKey'?: string;
    /**
     * 
     * @type {Provider}
     * @memberof ApiKey
     */
    'provider': Provider;
    /**
     * 
     * @type {UserProvider}
     * @memberof ApiKey
     */
    'userProvider': UserProvider;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'dateTime': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'dateArrived': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'dateCompleted': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'bookingNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'appointmentNotes': string | null;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'checkInTime': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'reasonForReschedule': string | null;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'reasonForCancellation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'status': AppointmentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'approvalStatus': AppointmentApprovalStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'bookingFee': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Appointment
     */
    'uploads'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'messageThreadId'?: number;
    /**
     * 
     * @type {Provider}
     * @memberof Appointment
     */
    'provider'?: Provider;
    /**
     * 
     * @type {Transaction}
     * @memberof Appointment
     */
    'transaction'?: Transaction;
    /**
     * 
     * @type {Array<AppointmentService>}
     * @memberof Appointment
     */
    'appointmentServices': Array<AppointmentService>;
    /**
     * 
     * @type {Array<ProviderClientNotes>}
     * @memberof Appointment
     */
    'providerClientNotes'?: Array<ProviderClientNotes>;
    /**
     * 
     * @type {User}
     * @memberof Appointment
     */
    'user': User;
    /**
     * 
     * @type {ProviderClientProfile}
     * @memberof Appointment
     */
    'providerClientProfile': ProviderClientProfile;
    /**
     * 
     * @type {ClientProfile}
     * @memberof Appointment
     */
    'clientProfile'?: ClientProfile;
    /**
     * 
     * @type {Array<ProviderTag>}
     * @memberof Appointment
     */
    'providerTags'?: Array<ProviderTag>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof Appointment
     */
    'customSchedules'?: Array<Schedule>;
    /**
     * 
     * @type {Schedule}
     * @memberof Appointment
     */
    'schedule'?: Schedule;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    'hasReminderBeenSent': boolean;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof Appointment
     */
    'referrals'?: Array<Referral>;
    /**
     * 
     * @type {Referral}
     * @memberof Appointment
     */
    'referral'?: Referral;
    /**
     * 
     * @type {object}
     * @memberof Appointment
     */
    'hmoSelected'?: object;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'hmoAccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'hmoLetterOfAuthorizationUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'hmoCardUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'hmoValidIdUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'otherConcern'?: string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'totalDownPayment': number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'reservationFee': number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'reservationPolicyNotes': string;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    'nonRefundable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    'deductible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    'nonRefundableClientNoShowCancellation': boolean;
    /**
     * 
     * @type {Array<MedicalRecordFile>}
     * @memberof Appointment
     */
    'medicalRecordFiles'?: Array<MedicalRecordFile>;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'feedbackId'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Appointment
     */
    'discounts'?: Array<object>;
    /**
     * 
     * @type {Array<ConsultationNote>}
     * @memberof Appointment
     */
    'consultationNotes'?: Array<ConsultationNote>;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'referenceId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Appointment
     */
    'address'?: Address;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'diagnosticFileId': number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'referralFormFileId': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Appointment
     */
    'customFields'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'platform': AppointmentPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'deletedAt'?: string;
}

export const AppointmentStatusEnum = {
    Confirmed: 'confirmed',
    Ongoing: 'ongoing',
    Cancelled: 'cancelled',
    NoShow: 'no-show',
    Completed: 'completed',
    Pending: 'pending'
} as const;

export type AppointmentStatusEnum = typeof AppointmentStatusEnum[keyof typeof AppointmentStatusEnum];
export const AppointmentApprovalStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Declined: 'declined'
} as const;

export type AppointmentApprovalStatusEnum = typeof AppointmentApprovalStatusEnum[keyof typeof AppointmentApprovalStatusEnum];
export const AppointmentPlatformEnum = {
    Web: 'web',
    MwellApp: 'mwell-app',
    WebMphReferral: 'web-mph-referral',
    Partner: 'partner'
} as const;

export type AppointmentPlatformEnum = typeof AppointmentPlatformEnum[keyof typeof AppointmentPlatformEnum];

/**
 * 
 * @export
 * @interface AppointmentService
 */
export interface AppointmentService {
    /**
     * 
     * @type {number}
     * @memberof AppointmentService
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentService
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentService
     */
    'serviceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentService
     */
    'priceType': AppointmentServicePriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AppointmentService
     */
    'minAmountInCents': number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentService
     */
    'maxAmountInCents': number;
    /**
     * 
     * @type {Appointment}
     * @memberof AppointmentService
     */
    'appointment': Appointment;
    /**
     * 
     * @type {Service}
     * @memberof AppointmentService
     */
    'service': Service;
    /**
     * 
     * @type {ProviderService}
     * @memberof AppointmentService
     */
    'providerService': ProviderService;
    /**
     * 
     * @type {string}
     * @memberof AppointmentService
     */
    'bookingPaymentRequirement': AppointmentServiceBookingPaymentRequirementEnum;
    /**
     * 
     * @type {number}
     * @memberof AppointmentService
     */
    'downPaymentAmount': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof AppointmentService
     */
    'discounts': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof AppointmentService
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentService
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentService
     */
    'deletedAt'?: string;
}

export const AppointmentServicePriceTypeEnum = {
    Free: 'free',
    Range: 'range',
    Fixed: 'fixed',
    Hide: 'hide',
    StartsAt: 'startsAt'
} as const;

export type AppointmentServicePriceTypeEnum = typeof AppointmentServicePriceTypeEnum[keyof typeof AppointmentServicePriceTypeEnum];
export const AppointmentServiceBookingPaymentRequirementEnum = {
    FullPayment: 'fullPayment',
    DownPayment: 'downPayment',
    None: 'none'
} as const;

export type AppointmentServiceBookingPaymentRequirementEnum = typeof AppointmentServiceBookingPaymentRequirementEnum[keyof typeof AppointmentServiceBookingPaymentRequirementEnum];

/**
 * 
 * @export
 * @interface AuthRefreshToken
 */
export interface AuthRefreshToken {
    /**
     * 
     * @type {number}
     * @memberof AuthRefreshToken
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshToken
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshToken
     */
    'clientId': string;
    /**
     * 
     * @type {UserOperation}
     * @memberof AuthRefreshToken
     */
    'user': UserOperation;
    /**
     * 
     * @type {object}
     * @memberof AuthRefreshToken
     */
    'jsonData': object | null;
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshToken
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshToken
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshToken
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    'id': number;
    /**
     * 
     * @type {UserProvider}
     * @memberof Banner
     */
    'providerCreator'?: UserProvider;
    /**
     * 
     * @type {UserOperation}
     * @memberof Banner
     */
    'operatorCreator'?: UserOperation;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'photoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'redirectUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface BillingAddress
 */
export interface BillingAddress {
    /**
     * 
     * @type {string}
     * @memberof BillingAddress
     */
    'line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddress
     */
    'line2'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddress
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddress
     */
    'countryCode'?: string;
}
/**
 * 
 * @export
 * @interface BloodChemistryDto
 */
export interface BloodChemistryDto {
    /**
     * 
     * @type {number}
     * @memberof BloodChemistryDto
     */
    'fastingBloodSugarMgDl': number;
    /**
     * 
     * @type {number}
     * @memberof BloodChemistryDto
     */
    'uricAcidMgDl': number;
    /**
     * 
     * @type {number}
     * @memberof BloodChemistryDto
     */
    'cholesterolMgDl': number;
}
/**
 * 
 * @export
 * @interface BulkCancelDto
 */
export interface BulkCancelDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkCancelDto
     */
    'appointmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkCancelDto
     */
    'reasonForCancellation': string;
    /**
     * 
     * @type {number}
     * @memberof BulkCancelDto
     */
    'scheduleId': number;
}
/**
 * 
 * @export
 * @interface BulkRescheduleDto
 */
export interface BulkRescheduleDto {
    /**
     * 
     * @type {string}
     * @memberof BulkRescheduleDto
     */
    'dateTime': string;
    /**
     * 
     * @type {string}
     * @memberof BulkRescheduleDto
     */
    'reasonForReschedule': string;
    /**
     * 
     * @type {number}
     * @memberof BulkRescheduleDto
     */
    'slotId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkRescheduleDto
     */
    'hospitalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkRescheduleDto
     */
    'resourceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkRescheduleDto
     */
    'serviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkRescheduleDto
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BulkRescheduleDto
     */
    'isRescheduledByPatient'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkRescheduleDto
     */
    'appointmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface Buyer
 */
export interface Buyer {
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'customerSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'sex'?: BuyerSexEnum;
    /**
     * 
     * @type {Contact}
     * @memberof Buyer
     */
    'contact'?: Contact;
    /**
     * 
     * @type {ShippingAddress}
     * @memberof Buyer
     */
    'shippingAddress'?: ShippingAddress;
    /**
     * 
     * @type {BillingAddress}
     * @memberof Buyer
     */
    'billingAddress'?: BillingAddress;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'mobilePhoneNumber': string;
}

export const BuyerSexEnum = {
    F: 'F',
    M: 'M'
} as const;

export type BuyerSexEnum = typeof BuyerSexEnum[keyof typeof BuyerSexEnum];

/**
 * 
 * @export
 * @interface CheckEmailOtpVerificationValidityDto
 */
export interface CheckEmailOtpVerificationValidityDto {
    /**
     * 
     * @type {string}
     * @memberof CheckEmailOtpVerificationValidityDto
     */
    'otpToken': string;
}
/**
 * 
 * @export
 * @interface CheckoutResponse
 */
export interface CheckoutResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckoutResponse
     */
    'checkoutId': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutResponse
     */
    'redirectUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutResponse
     */
    'requestReferenceNumber': string;
}
/**
 * 
 * @export
 * @interface ClientDto
 */
export interface ClientDto {
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'smoker': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'drinker': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'birthDate': string;
}
/**
 * 
 * @export
 * @interface ClientFavourite
 */
export interface ClientFavourite {
    /**
     * 
     * @type {number}
     * @memberof ClientFavourite
     */
    'id': number;
    /**
     * 
     * @type {User}
     * @memberof ClientFavourite
     */
    'user': User;
    /**
     * 
     * @type {Array<ProviderService>}
     * @memberof ClientFavourite
     */
    'providerServices': Array<ProviderService>;
    /**
     * 
     * @type {Array<Provider>}
     * @memberof ClientFavourite
     */
    'providers'?: Array<Provider>;
    /**
     * 
     * @type {string}
     * @memberof ClientFavourite
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClientFavourite
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClientFavourite
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClientFavouriteType = {
    Provider: 'provider',
    Service: 'service'
} as const;

export type ClientFavouriteType = typeof ClientFavouriteType[keyof typeof ClientFavouriteType];


/**
 * 
 * @export
 * @interface ClientProfile
 */
export interface ClientProfile {
    /**
     * 
     * @type {number}
     * @memberof ClientProfile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'suffix': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'countryCode': string;
    /**
     * 
     * @type {User}
     * @memberof ClientProfile
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'displayPhotoUrl': string;
    /**
     * 
     * @type {Array<ProviderClientProfile>}
     * @memberof ClientProfile
     */
    'providerClientProfiles'?: Array<ProviderClientProfile>;
    /**
     * 
     * @type {User}
     * @memberof ClientProfile
     */
    'mainUser'?: User;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof ClientProfile
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {Array<MedicalRecordFile>}
     * @memberof ClientProfile
     */
    'medicalRecordFiles'?: Array<MedicalRecordFile>;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ConsultationNote
 */
export interface ConsultationNote {
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'doctorId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'bloodPressure': string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'temperatureInCelsius': number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'respiratoryRatePerMin': number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'pulseRatePerMin': number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'oxygenSaturationPercent': number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'phLevel': number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'specificGravity': number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'urobilinogenLevel': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'vitaminCLevel': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'xRayResults': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'ultrasoundResults': string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'heartRatePerMin': number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'rhythmResult': string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'fastingBloodSugarMgDl': number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'uricAcidMgDl': number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationNote
     */
    'cholesterolMgDl': number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'diagnosis': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'recommendations': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'labRequests': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'imagingRequests': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'otherDiagnostics': string;
    /**
     * 
     * @type {Array<MedicalInstruction>}
     * @memberof ConsultationNote
     */
    'medicalInstructions': Array<MedicalInstruction>;
    /**
     * 
     * @type {Appointment}
     * @memberof ConsultationNote
     */
    'appointment': Appointment;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNote
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ConsultationNoteDto
 */
export interface ConsultationNoteDto {
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'doctorId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'timestamp': string;
    /**
     * 
     * @type {VitalsDto}
     * @memberof ConsultationNoteDto
     */
    'vitals': VitalsDto;
    /**
     * 
     * @type {UrinalysisDto}
     * @memberof ConsultationNoteDto
     */
    'urinalysis': UrinalysisDto;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'xRayResults': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'ultrasoundResults': string;
    /**
     * 
     * @type {EcgDto}
     * @memberof ConsultationNoteDto
     */
    'ecg': EcgDto;
    /**
     * 
     * @type {BloodChemistryDto}
     * @memberof ConsultationNoteDto
     */
    'bloodChemistry': BloodChemistryDto;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'diagnosis': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'recommendations': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'labRequests': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'imagingRequests': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationNoteDto
     */
    'otherDiagnostics': string;
    /**
     * 
     * @type {DiagnosticRequestsDto}
     * @memberof ConsultationNoteDto
     */
    'diagnosticRequests': DiagnosticRequestsDto;
    /**
     * 
     * @type {Array<MedicalInstructionDto>}
     * @memberof ConsultationNoteDto
     */
    'medicalInstructions': Array<MedicalInstructionDto>;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreateAddressDto
 */
export interface CreateAddressDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'unitRoomFloor'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'buildingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'line'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'lotBlockPhaseHouse'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'streetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'municipalityCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'province'?: string;
    /**
     * 
     * @type {RegionDto}
     * @memberof CreateAddressDto
     */
    'region'?: RegionDto | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAddressDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAddressDto
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'addressType'?: CreateAddressDtoAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'foreignCountryAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'locality'?: string;
}

export const CreateAddressDtoAddressTypeEnum = {
    Mailing: 'MAILING',
    Permanent: 'PERMANENT',
    Current: 'CURRENT'
} as const;

export type CreateAddressDtoAddressTypeEnum = typeof CreateAddressDtoAddressTypeEnum[keyof typeof CreateAddressDtoAddressTypeEnum];

/**
 * 
 * @export
 * @interface CreateAdminUserDto
 */
export interface CreateAdminUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface CreateAllergyRecordDto
 */
export interface CreateAllergyRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'category_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'category_display': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'category_text': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'substance': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'manifestation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'criticality': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllergyRecordDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface CreateAnnouncementDto
 */
export interface CreateAnnouncementDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'target': CreateAnnouncementDtoTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'targetSpecification'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAnnouncementDto
     */
    'maxAge'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAnnouncementDto
     */
    'minAge'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'sex'?: CreateAnnouncementDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'messageContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'redirectLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'scheduleAnnouncement'?: string;
}

export const CreateAnnouncementDtoTargetEnum = {
    All: 'all',
    Clients: 'clients',
    Providers: 'providers',
    ClientsThroughTargeting: 'clientsThroughTargeting',
    ProvidersThroughTargeting: 'providersThroughTargeting'
} as const;

export type CreateAnnouncementDtoTargetEnum = typeof CreateAnnouncementDtoTargetEnum[keyof typeof CreateAnnouncementDtoTargetEnum];
export const CreateAnnouncementDtoSexEnum = {
    All: 'all',
    Male: 'male',
    Female: 'female'
} as const;

export type CreateAnnouncementDtoSexEnum = typeof CreateAnnouncementDtoSexEnum[keyof typeof CreateAnnouncementDtoSexEnum];

/**
 * 
 * @export
 * @interface CreateAnthropometricRecordDto
 */
export interface CreateAnthropometricRecordDto {
    /**
     * Date measured on
     * @type {string}
     * @memberof CreateAnthropometricRecordDto
     */
    'measurement_datetime': string;
    /**
     * Height in cm
     * @type {number}
     * @memberof CreateAnthropometricRecordDto
     */
    'height_cm': number;
    /**
     * Height in in
     * @type {number}
     * @memberof CreateAnthropometricRecordDto
     */
    'height_in': number;
    /**
     * Weight in kg
     * @type {number}
     * @memberof CreateAnthropometricRecordDto
     */
    'weight_kg': number;
    /**
     * Weight in lbs
     * @type {number}
     * @memberof CreateAnthropometricRecordDto
     */
    'weight_lbs': number;
    /**
     * Body Mass Index
     * @type {number}
     * @memberof CreateAnthropometricRecordDto
     */
    'body_mass_index': number;
}
/**
 * 
 * @export
 * @interface CreateApiFieldsDto
 */
export interface CreateApiFieldsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateApiFieldsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiFieldsDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiFieldsDto
     */
    'apiKey': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiFieldsDto
     */
    'apiKeyHeader': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateApiFieldsDto
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateApiFieldsDto
     */
    'dataParameters': object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateApiFieldsDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAppointmentDto
 */
export interface CreateAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'dateTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'providerClientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'bookingFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'transactionFee': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'paymentMethod': CreateAppointmentDtoPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'paymentOption': CreateAppointmentDtoPaymentOptionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'paymentStatus': CreateAppointmentDtoPaymentStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAppointmentDto
     */
    'serviceCategoryIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAppointmentDto
     */
    'providerServiceIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'providerId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'seniorCitizenId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'pwdId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'hmoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'hmoNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'philhealthId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'philhealthKonsultaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'paymentReferenceNumber'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateAppointmentDto
     */
    'uploads'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'appointmentNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'reasonForCancellation'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'clientProfileId'?: number;
    /**
     * 
     * @type {CreateProviderClientProfileDto}
     * @memberof CreateAppointmentDto
     */
    'providerClientProfile'?: CreateProviderClientProfileDto | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAppointmentDto
     */
    'tagIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentDto
     */
    'isCreatedByProvider'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateAppointmentDto
     */
    'hmoSelected'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'hmoAccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'hmoLetterOfAuthorizationUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'hmoCardUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'hmoValidIdUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'otherConcern'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'totalDownPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'reservationFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'reservationPolicyNotes'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentDto
     */
    'nonRefundable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentDto
     */
    'deductible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentDto
     */
    'nonRefundableClientNoShowCancellation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'totalAmountDue'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAppointmentDto
     */
    'totalAmountDueInPerson'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'totalAmountDueNow'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateAppointmentDto
     */
    'discounts'?: Array<object>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAppointmentDto
     */
    'savedAmountFromDiscount'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDto
     */
    'diagnosticFileId'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateAppointmentDto
     */
    'customFields'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'platform': CreateAppointmentDtoPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'referenceId'?: string;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof CreateAppointmentDto
     */
    'address'?: CreateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDto
     */
    'proofOfPaymentUrl'?: string;
}

export const CreateAppointmentDtoPaymentMethodEnum = {
    Later: 'pay-later',
    Now: 'pay-now',
    NowFull: 'pay-now-full'
} as const;

export type CreateAppointmentDtoPaymentMethodEnum = typeof CreateAppointmentDtoPaymentMethodEnum[keyof typeof CreateAppointmentDtoPaymentMethodEnum];
export const CreateAppointmentDtoPaymentOptionEnum = {
    Cash: 'cash',
    DebitCreditEwallet: 'debit/credit/ewallet',
    Hmo: 'hmo',
    Philhealth: 'philhealth',
    Qrph: 'qrph'
} as const;

export type CreateAppointmentDtoPaymentOptionEnum = typeof CreateAppointmentDtoPaymentOptionEnum[keyof typeof CreateAppointmentDtoPaymentOptionEnum];
export const CreateAppointmentDtoPaymentStatusEnum = {
    Paid: 'paid',
    NotPaid: 'not-paid'
} as const;

export type CreateAppointmentDtoPaymentStatusEnum = typeof CreateAppointmentDtoPaymentStatusEnum[keyof typeof CreateAppointmentDtoPaymentStatusEnum];
export const CreateAppointmentDtoPlatformEnum = {
    Web: 'web',
    MwellApp: 'mwell-app',
    WebMphReferral: 'web-mph-referral',
    Partner: 'partner'
} as const;

export type CreateAppointmentDtoPlatformEnum = typeof CreateAppointmentDtoPlatformEnum[keyof typeof CreateAppointmentDtoPlatformEnum];

/**
 * 
 * @export
 * @interface CreateAuthSessionDto
 */
export interface CreateAuthSessionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAuthSessionDto
     */
    'redirect_uri': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthSessionDto
     */
    'client_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthSessionDto
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface CreateBannerDto
 */
export interface CreateBannerDto {
    /**
     * The ID of the provider who created the banner
     * @type {number}
     * @memberof CreateBannerDto
     */
    'providerCreatorId'?: number;
    /**
     * The ID of the operator who created the banner
     * @type {number}
     * @memberof CreateBannerDto
     */
    'operatorCreatorId'?: number;
    /**
     * The title of the banner
     * @type {string}
     * @memberof CreateBannerDto
     */
    'title': string;
    /**
     * The URL of the banner photo
     * @type {string}
     * @memberof CreateBannerDto
     */
    'photoUrl': string;
    /**
     * The URL that the banner will redirect to
     * @type {string}
     * @memberof CreateBannerDto
     */
    'redirectUrl'?: string;
    /**
     * The display order of the banner
     * @type {number}
     * @memberof CreateBannerDto
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface CreateCategoryDto
 */
export interface CreateCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDto
     */
    'productType'?: CreateCategoryDtoProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDto
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCategoryDto
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDto
     */
    'defaultOrderStatus'?: CreateCategoryDtoDefaultOrderStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCategoryDto
     */
    'isActive'?: boolean;
}

export const CreateCategoryDtoProductTypeEnum = {
    Digital: 'DIGITAL',
    Emergency: 'EMERGENCY',
    Physical: 'PHYSICAL'
} as const;

export type CreateCategoryDtoProductTypeEnum = typeof CreateCategoryDtoProductTypeEnum[keyof typeof CreateCategoryDtoProductTypeEnum];
export const CreateCategoryDtoDefaultOrderStatusEnum = {
    Pending: 'pending',
    Completed: 'completed',
    Cancelled: 'cancelled',
    Processing: 'processing',
    Shipped: 'shipped',
    Confirmed: 'confirmed'
} as const;

export type CreateCategoryDtoDefaultOrderStatusEnum = typeof CreateCategoryDtoDefaultOrderStatusEnum[keyof typeof CreateCategoryDtoDefaultOrderStatusEnum];

/**
 * 
 * @export
 * @interface CreateClientProfileDto
 */
export interface CreateClientProfileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'displayPhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientProfileDto
     */
    'clientProfileType'?: CreateClientProfileDtoClientProfileTypeEnum;
}

export const CreateClientProfileDtoClientProfileTypeEnum = {
    Myself: 'Myself',
    MyChild: 'My Child',
    MyParent: 'My Parent',
    SomeoneElse: 'Someone Else'
} as const;

export type CreateClientProfileDtoClientProfileTypeEnum = typeof CreateClientProfileDtoClientProfileTypeEnum[keyof typeof CreateClientProfileDtoClientProfileTypeEnum];

/**
 * 
 * @export
 * @interface CreateDiscountDto
 */
export interface CreateDiscountDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'method': CreateDiscountDtoMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountDto
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'status'?: CreateDiscountDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'startsAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'endsAt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDiscountDto
     */
    'activeDays'?: Array<CreateDiscountDtoActiveDaysEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'activeHoursStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'activeHoursEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountDto
     */
    'categoryId': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateDiscountDto
     */
    'branchIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateDiscountDto
     */
    'serviceIds'?: Array<number>;
    /**
     * 
     * @type {object}
     * @memberof CreateDiscountDto
     */
    'metadata'?: object;
}

export const CreateDiscountDtoMethodEnum = {
    FixedPrice: 'FIXED_PRICE',
    PercentOffPrice: 'PERCENT_OFF_PRICE',
    PesoOffPrice: 'PESO_OFF_PRICE'
} as const;

export type CreateDiscountDtoMethodEnum = typeof CreateDiscountDtoMethodEnum[keyof typeof CreateDiscountDtoMethodEnum];
export const CreateDiscountDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CreateDiscountDtoStatusEnum = typeof CreateDiscountDtoStatusEnum[keyof typeof CreateDiscountDtoStatusEnum];
export const CreateDiscountDtoActiveDaysEnum = {
    Sunday: 'SUNDAY',
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY'
} as const;

export type CreateDiscountDtoActiveDaysEnum = typeof CreateDiscountDtoActiveDaysEnum[keyof typeof CreateDiscountDtoActiveDaysEnum];

/**
 * 
 * @export
 * @interface CreateEntityTypeDto
 */
export interface CreateEntityTypeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEntityTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEntityTypeDto
     */
    'type': CreateEntityTypeDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEntityTypeDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEntityTypeDto
     */
    'providerTypeId'?: number;
}

export const CreateEntityTypeDtoTypeEnum = {
    Provider: 'Provider',
    Category: 'Category'
} as const;

export type CreateEntityTypeDtoTypeEnum = typeof CreateEntityTypeDtoTypeEnum[keyof typeof CreateEntityTypeDtoTypeEnum];

/**
 * 
 * @export
 * @interface CreateFamilyHistoryRecordDto
 */
export interface CreateFamilyHistoryRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFamilyHistoryRecordDto
     */
    'relationship': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFamilyHistoryRecordDto
     */
    'condition_illness': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFamilyHistoryRecordDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFamilyHistoryRecordDto
     */
    'patientId': string;
}
/**
 * 
 * @export
 * @interface CreateFeedbackDto
 */
export interface CreateFeedbackDto {
    /**
     * 
     * @type {number}
     * @memberof CreateFeedbackDto
     */
    'appointmentId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedbackDto
     */
    'branchId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackDto
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedbackDto
     */
    'rating': number;
}
/**
 * 
 * @export
 * @interface CreateFeedbackResponse
 */
export interface CreateFeedbackResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'authorId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'entityId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedbackResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'origin': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedbackResponse
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'recipientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CreateGroupBookingDto
 */
export interface CreateGroupBookingDto {
    /**
     * 
     * @type {CreateAppointmentDto}
     * @memberof CreateGroupBookingDto
     */
    'appointment': CreateAppointmentDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGroupBookingDto
     */
    'clientProfileIds': Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateGroupBookingDto
     */
    'hmoDetails': Array<object>;
}
/**
 * 
 * @export
 * @interface CreateMedicalHistoryRecordDto
 */
export interface CreateMedicalHistoryRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMedicalHistoryRecordDto
     */
    'condition_illness': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicalHistoryRecordDto
     */
    'condition_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicalHistoryRecordDto
     */
    'hospital': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicalHistoryRecordDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateMedicationRecordDto
 */
export interface CreateMedicationRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationRecordDto
     */
    'generic_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationRecordDto
     */
    'brand_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationRecordDto
     */
    'strength_dosage_form': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationRecordDto
     */
    'signa': string;
    /**
     * 
     * @type {number}
     * @memberof CreateMedicationRecordDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationRecordDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationRecordDto
     */
    'date_prescribed': string;
}
/**
 * 
 * @export
 * @interface CreateMerchantDto
 */
export interface CreateMerchantDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantDto
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMerchantDto
     */
    'tagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantDto
     */
    'cwtRate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMerchantDto
     */
    'shippingFee'?: number;
}
/**
 * 
 * @export
 * @interface CreateMessageDto
 */
export interface CreateMessageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'messageThreadId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'branchId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'type'?: CreateMessageDtoTypeEnum;
}

export const CreateMessageDtoTypeEnum = {
    HealthhubSystemMessage: 'healthhub_system_message'
} as const;

export type CreateMessageDtoTypeEnum = typeof CreateMessageDtoTypeEnum[keyof typeof CreateMessageDtoTypeEnum];

/**
 * 
 * @export
 * @interface CreateMessageThreadDto
 */
export interface CreateMessageThreadDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMessageThreadDto
     */
    'memberIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateMultiScheduleAppointmentDto
 */
export interface CreateMultiScheduleAppointmentDto {
    /**
     * 
     * @type {CreateAppointmentDto}
     * @memberof CreateMultiScheduleAppointmentDto
     */
    'appointment': CreateAppointmentDto;
    /**
     * 
     * @type {Array<CustomSchedule>}
     * @memberof CreateMultiScheduleAppointmentDto
     */
    'schedules': Array<CustomSchedule>;
}
/**
 * 
 * @export
 * @interface CreatePartnerAppointmentDto
 */
export interface CreatePartnerAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerAppointmentDto
     */
    'dateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerAppointmentDto
     */
    'referenceId': string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CreatePartnerAppointmentDto
     */
    'address': AddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerAppointmentDto
     */
    'otherConcern': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerAppointmentDto
     */
    'appointmentNotes': string;
    /**
     * 
     * @type {ClientDto}
     * @memberof CreatePartnerAppointmentDto
     */
    'client': ClientDto;
    /**
     * 
     * @type {Array<ServiceDto>}
     * @memberof CreatePartnerAppointmentDto
     */
    'services': Array<ServiceDto>;
    /**
     * 
     * @type {Array<ConsultationNoteDto>}
     * @memberof CreatePartnerAppointmentDto
     */
    'consultationNotes': Array<ConsultationNoteDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePartnerAppointmentDto
     */
    'medicalFiles': Array<string>;
}
/**
 * 
 * @export
 * @interface CreatePatientHmoDto
 */
export interface CreatePatientHmoDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePatientHmoDto
     */
    'mwellUserId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientHmoDto
     */
    'cardNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientHmoDto
     */
    'birthDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePatientHmoDto
     */
    'hmoId': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientHmoDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientHmoDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientHmoDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientHmoDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreatePaymentDTO
 */
export interface CreatePaymentDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentDTO
     */
    'mwellUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentDTO
     */
    'hostOrigin'?: string;
    /**
     * 
     * @type {Array<Item>}
     * @memberof CreatePaymentDTO
     */
    'items': Array<Item>;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentDTO
     */
    'requestReferenceNumber'?: string;
    /**
     * 
     * @type {TotalAmount}
     * @memberof CreatePaymentDTO
     */
    'totalAmount': TotalAmount;
    /**
     * 
     * @type {Buyer}
     * @memberof CreatePaymentDTO
     */
    'buyer': Buyer;
    /**
     * 
     * @type {RedirectUrls}
     * @memberof CreatePaymentDTO
     */
    'redirectUrl': RedirectUrls;
}
/**
 * 
 * @export
 * @interface CreateProductDto
 */
export interface CreateProductDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'productId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'maxOrderQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductDto
     */
    'canBeGifted': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'merchantEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'categoryId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'vat'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'sfRate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductDto
     */
    'tagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'cwtRate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'transactionFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'shippingFee'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductDto
     */
    'isFreeShipping'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductDto
     */
    'displayFullGrid'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductDto
     */
    'variantAttributes'?: Array<string>;
    /**
     * 
     * @type {Array<CreateVariantDto>}
     * @memberof CreateProductDto
     */
    'variants'?: Array<CreateVariantDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'priority'?: number;
}
/**
 * 
 * @export
 * @interface CreatePromotionDto
 */
export interface CreatePromotionDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePromotionDto
     */
    'providerIds': Array<number>;
}
/**
 * 
 * @export
 * @interface CreateProviderBranchDto
 */
export interface CreateProviderBranchDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'officeHours': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'paymentQrCodeUrl'?: string;
    /**
     * 
     * @type {UpdateAddressDto}
     * @memberof CreateProviderBranchDto
     */
    'address'?: UpdateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderBranchDto
     */
    'parentProviderId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProviderBranchDto
     */
    'paymentMethod'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProviderBranchDto
     */
    'acceptedHmos'?: Array<string>;
    /**
     * 
     * @type {Array<CreateRepresentativeDto>}
     * @memberof CreateProviderBranchDto
     */
    'representatives'?: Array<CreateRepresentativeDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderBranchDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProviderBranchDto
     */
    'providerTypeIds': Array<number>;
    /**
     * 
     * @type {Array<CreateUserProviderDto>}
     * @memberof CreateProviderBranchDto
     */
    'userProviders'?: Array<CreateUserProviderDto>;
}
/**
 * 
 * @export
 * @interface CreateProviderClientNotesDto
 */
export interface CreateProviderClientNotesDto {
    /**
     * 
     * @type {number}
     * @memberof CreateProviderClientNotesDto
     */
    'providerClientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderClientNotesDto
     */
    'appointmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderClientNotesDto
     */
    'providerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientNotesDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientNotesDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateProviderClientProfileDto
 */
export interface CreateProviderClientProfileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'providerId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderClientProfileDto
     */
    'clientId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderClientProfileDto
     */
    'clientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderClientProfileDto
     */
    'hmoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'philhealthKonsultaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'hmoNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'reasonForVisit'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderClientProfileDto
     */
    'clientProfileType'?: CreateProviderClientProfileDtoClientProfileTypeEnum;
}

export const CreateProviderClientProfileDtoClientProfileTypeEnum = {
    Myself: 'Myself',
    MyChild: 'My Child',
    MyParent: 'My Parent',
    SomeoneElse: 'Someone Else'
} as const;

export type CreateProviderClientProfileDtoClientProfileTypeEnum = typeof CreateProviderClientProfileDtoClientProfileTypeEnum[keyof typeof CreateProviderClientProfileDtoClientProfileTypeEnum];

/**
 * 
 * @export
 * @interface CreateProviderDirectoryDto
 */
export interface CreateProviderDirectoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDirectoryDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProviderDirectoryDto
     */
    'providerTypeIds': Array<number>;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof CreateProviderDirectoryDto
     */
    'address'?: CreateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDirectoryDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDirectoryDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDirectoryDto
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDirectoryDto
     */
    'officeHours': string;
}
/**
 * 
 * @export
 * @interface CreateProviderDto
 */
export interface CreateProviderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'website'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderDto
     */
    'providerTypeId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProviderDto
     */
    'providerTypeIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'paymentQrCodeUrl'?: string;
    /**
     * 
     * @type {UpdateAddressDto}
     * @memberof CreateProviderDto
     */
    'address'?: UpdateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderDto
     */
    'parentProviderId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProviderDto
     */
    'paymentMethod'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateProviderDto
     */
    'acceptedHmos'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {Array<CreateRepresentativeDto>}
     * @memberof CreateProviderDto
     */
    'representatives'?: Array<CreateRepresentativeDto>;
    /**
     * 
     * @type {Array<CreateUserProviderDto>}
     * @memberof CreateProviderDto
     */
    'userProviders'?: Array<CreateUserProviderDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProviderDto
     */
    'isAutoApproveAppointmentEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProviderDto
     */
    'isMphProvider'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateProviderServiceDto
 */
export interface CreateProviderServiceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'availability'?: CreateProviderServiceDtoAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'procedurePreparation'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderServiceDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderServiceDto
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'specialNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'freePriceLabel'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderServiceDto
     */
    'providerId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderServiceDto
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'priceType'?: CreateProviderServiceDtoPriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderServiceDto
     */
    'serviceCategoryId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProviderServiceDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProviderServiceDto
     */
    'isFeatured'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProviderServiceDto
     */
    'providerServiceBranches'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderServiceDto
     */
    'parentProviderServiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderServiceDto
     */
    'bookingPaymentRequirement'?: CreateProviderServiceDtoBookingPaymentRequirementEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderServiceDto
     */
    'downPaymentAmount'?: number;
}

export const CreateProviderServiceDtoAvailabilityEnum = {
    Available: 'Available',
    Unavailable: 'Unavailable',
    AvailableInSelectedBranches: 'Available in selected Branches'
} as const;

export type CreateProviderServiceDtoAvailabilityEnum = typeof CreateProviderServiceDtoAvailabilityEnum[keyof typeof CreateProviderServiceDtoAvailabilityEnum];
export const CreateProviderServiceDtoPriceTypeEnum = {
    Free: 'free',
    Range: 'range',
    Fixed: 'fixed',
    Hide: 'hide',
    StartsAt: 'startsAt'
} as const;

export type CreateProviderServiceDtoPriceTypeEnum = typeof CreateProviderServiceDtoPriceTypeEnum[keyof typeof CreateProviderServiceDtoPriceTypeEnum];
export const CreateProviderServiceDtoBookingPaymentRequirementEnum = {
    FullPayment: 'fullPayment',
    DownPayment: 'downPayment',
    None: 'none'
} as const;

export type CreateProviderServiceDtoBookingPaymentRequirementEnum = typeof CreateProviderServiceDtoBookingPaymentRequirementEnum[keyof typeof CreateProviderServiceDtoBookingPaymentRequirementEnum];

/**
 * 
 * @export
 * @interface CreateProviderTagCategoryDto
 */
export interface CreateProviderTagCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderTagCategoryDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderTagCategoryDto
     */
    'providerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProviderTagCategoryDto
     */
    'tags': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateReferralDto
 */
export interface CreateReferralDto {
    /**
     * 
     * @type {number}
     * @memberof CreateReferralDto
     */
    'providerClientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralDto
     */
    'clientProfileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'suffix': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'reasonForReferral': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralDto
     */
    'appointmentId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralDto
     */
    'dateTime': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateReferralDto
     */
    'serviceCategoryIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateReferralDto
     */
    'providerServiceIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralDto
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {Array<CreateReferralFileDto>}
     * @memberof CreateReferralDto
     */
    'attachments'?: Array<CreateReferralFileDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralDto
     */
    'referredProviderId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralDto
     */
    'providerId': number;
}
/**
 * 
 * @export
 * @interface CreateReferralFileDto
 */
export interface CreateReferralFileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateReferralFileDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralFileDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferralFileDto
     */
    'mimeType': string;
}
/**
 * 
 * @export
 * @interface CreateRepresentativeDto
 */
export interface CreateRepresentativeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'phoneCountryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepresentativeDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CreateScheduleDto
 */
export interface CreateScheduleDto {
    /**
     * 
     * @type {Array<ScheduleDataDto>}
     * @memberof CreateScheduleDto
     */
    'scheduleList'?: Array<ScheduleDataDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleDto
     */
    'providerId': string;
}
/**
 * 
 * @export
 * @interface CreateServiceScheduleDto
 */
export interface CreateServiceScheduleDto {
    /**
     * 
     * @type {number}
     * @memberof CreateServiceScheduleDto
     */
    'timeSlotIntervalInMinutes': number;
    /**
     * 
     * @type {Array<TimeSlot>}
     * @memberof CreateServiceScheduleDto
     */
    'timeSlots': Array<TimeSlot>;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceScheduleDto
     */
    'slots': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateServiceScheduleDto
     */
    'branchIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceScheduleDto
     */
    'brandId': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateServiceScheduleDto
     */
    'providerServiceIds': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateServiceScheduleDto
     */
    'days': Array<CreateServiceScheduleDtoDaysEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceScheduleDto
     */
    'branchSelectionType': CreateServiceScheduleDtoBranchSelectionTypeEnum;
}

export const CreateServiceScheduleDtoDaysEnum = {
    Sunday: 'sunday',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday'
} as const;

export type CreateServiceScheduleDtoDaysEnum = typeof CreateServiceScheduleDtoDaysEnum[keyof typeof CreateServiceScheduleDtoDaysEnum];
export const CreateServiceScheduleDtoBranchSelectionTypeEnum = {
    All: 'ALL',
    Specific: 'SPECIFIC'
} as const;

export type CreateServiceScheduleDtoBranchSelectionTypeEnum = typeof CreateServiceScheduleDtoBranchSelectionTypeEnum[keyof typeof CreateServiceScheduleDtoBranchSelectionTypeEnum];

/**
 * 
 * @export
 * @interface CreateSocialHistoryRecordDto
 */
export interface CreateSocialHistoryRecordDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateSocialHistoryRecordDto
     */
    'is_smoker': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialHistoryRecordDto
     */
    'years'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialHistoryRecordDto
     */
    'pack_years'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialHistoryRecordDto
     */
    'sticks_per_day'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSocialHistoryRecordDto
     */
    'is_alcohol': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSocialHistoryRecordDto
     */
    'alcohol'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSocialHistoryRecordDto
     */
    'is_drug': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSocialHistoryRecordDto
     */
    'drug': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSocialHistoryRecordDto
     */
    'diet': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSocialHistoryRecordDto
     */
    'physical_activity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSocialHistoryRecordDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CreateSurgicalHistoryRecordDto
 */
export interface CreateSurgicalHistoryRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSurgicalHistoryRecordDto
     */
    'operation_procedure': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurgicalHistoryRecordDto
     */
    'operation_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurgicalHistoryRecordDto
     */
    'indication': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurgicalHistoryRecordDto
     */
    'hospital': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurgicalHistoryRecordDto
     */
    'remarks'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateTagDto
 */
export interface CreateTagDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    'tagType': CreateTagDtoTagTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    'displayType': CreateTagDtoDisplayTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    'displayButtonText': string;
}

export const CreateTagDtoTagTypeEnum = {
    Merchant: 'MERCHANT',
    Product: 'PRODUCT'
} as const;

export type CreateTagDtoTagTypeEnum = typeof CreateTagDtoTagTypeEnum[keyof typeof CreateTagDtoTagTypeEnum];
export const CreateTagDtoDisplayTypeEnum = {
    Grid: 'GRID',
    Horizontal: 'HORIZONTAL',
    Vertical: 'VERTICAL'
} as const;

export type CreateTagDtoDisplayTypeEnum = typeof CreateTagDtoDisplayTypeEnum[keyof typeof CreateTagDtoDisplayTypeEnum];

/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'displayPhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'mWellUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'userType': CreateUserDtoUserTypeEnum;
}

export const CreateUserDtoUserTypeEnum = {
    Admin: 'ADMIN',
    Finance: 'FINANCE',
    Marketing: 'MARKETING',
    Client: 'CLIENT',
    Patient: 'PATIENT',
    ProviderAdmin: 'PROVIDER_ADMIN',
    SuperAdmin: 'SUPER_ADMIN'
} as const;

export type CreateUserDtoUserTypeEnum = typeof CreateUserDtoUserTypeEnum[keyof typeof CreateUserDtoUserTypeEnum];

/**
 * 
 * @export
 * @interface CreateUserOperationDto
 */
export interface CreateUserOperationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'userType'?: CreateUserOperationDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOperationDto
     */
    'userAppType'?: CreateUserOperationDtoUserAppTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateUserOperationDto
     */
    'roleIds'?: Array<number>;
}

export const CreateUserOperationDtoUserTypeEnum = {
    Admin: 'ADMIN',
    Finance: 'FINANCE',
    Marketing: 'MARKETING',
    Merchant: 'MERCHANT'
} as const;

export type CreateUserOperationDtoUserTypeEnum = typeof CreateUserOperationDtoUserTypeEnum[keyof typeof CreateUserOperationDtoUserTypeEnum];
export const CreateUserOperationDtoUserAppTypeEnum = {
    HealthHub: 'HEALTH_HUB',
    Eshop: 'ESHOP'
} as const;

export type CreateUserOperationDtoUserAppTypeEnum = typeof CreateUserOperationDtoUserAppTypeEnum[keyof typeof CreateUserOperationDtoUserAppTypeEnum];

/**
 * 
 * @export
 * @interface CreateUserProviderDto
 */
export interface CreateUserProviderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'userType': CreateUserProviderDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateUserProviderDto
     */
    'branchIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateUserProviderDto
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProviderDto
     */
    'userAppType'?: CreateUserProviderDtoUserAppTypeEnum;
}

export const CreateUserProviderDtoUserTypeEnum = {
    BranchAdmin: 'BRANCH_ADMIN',
    BrandAdmin: 'BRAND_ADMIN',
    EshopMerchant: 'ESHOP_MERCHANT',
    Specialist: 'SPECIALIST',
    Staff: 'STAFF',
    SuperAdmin: 'SUPER_ADMIN',
    ProviderAdmin: 'PROVIDER_ADMIN'
} as const;

export type CreateUserProviderDtoUserTypeEnum = typeof CreateUserProviderDtoUserTypeEnum[keyof typeof CreateUserProviderDtoUserTypeEnum];
export const CreateUserProviderDtoUserAppTypeEnum = {
    HealthHub: 'HEALTH_HUB',
    Eshop: 'ESHOP'
} as const;

export type CreateUserProviderDtoUserAppTypeEnum = typeof CreateUserProviderDtoUserAppTypeEnum[keyof typeof CreateUserProviderDtoUserAppTypeEnum];

/**
 * 
 * @export
 * @interface CreateUserResponseDto
 */
export interface CreateUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponseDto
     */
    'token': string;
    /**
     * 
     * @type {SanitizedUserDto}
     * @memberof CreateUserResponseDto
     */
    'user'?: SanitizedUserDto | null;
}
/**
 * 
 * @export
 * @interface CreateVaccinationRecordDto
 */
export interface CreateVaccinationRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'vaccine_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'brand_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'administered_by': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'administered_on': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'series': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'lot_number': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'dose_mL': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'site': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'route': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'facility': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVaccinationRecordDto
     */
    'next_dose_on': string;
}
/**
 * 
 * @export
 * @interface CreateVariantDto
 */
export interface CreateVariantDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVariantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVariantDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVariantDto
     */
    'image'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateVariantDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateVariantDto
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof CreateVariantDto
     */
    'status': CreateVariantDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVariantDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateVariantDto
     */
    'attributeValues': object;
    /**
     * 
     * @type {string}
     * @memberof CreateVariantDto
     */
    'sku': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVariantDto
     */
    'isVisible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateVariantDto
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateVariantDto
     */
    'priority'?: number;
}

export const CreateVariantDtoStatusEnum = {
    Active: 'active',
    Inactive: 'inactive',
    OutOfStock: 'out_of_stock'
} as const;

export type CreateVariantDtoStatusEnum = typeof CreateVariantDtoStatusEnum[keyof typeof CreateVariantDtoStatusEnum];

/**
 * 
 * @export
 * @interface CreateVitalSignRecordDto
 */
export interface CreateVitalSignRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVitalSignRecordDto
     */
    'measurement_datetime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'temperature_c': number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'temperature_f': number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'systolic_blood_pressure': number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'diastolic_blood_pressure': number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'pulse_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'respiratory_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'pain'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'oxygen_saturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'capillary_blood_glucose_mgdl': number;
    /**
     * 
     * @type {number}
     * @memberof CreateVitalSignRecordDto
     */
    'capillary_blood_glucose_mmoll': number;
    /**
     * 
     * @type {string}
     * @memberof CreateVitalSignRecordDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface CustomCreatePatientDto
 */
export interface CustomCreatePatientDto {
    /**
     * Array of custom create patient repository DTOs
     * @type {Array<CustomCreatePatientRepositoryDto>}
     * @memberof CustomCreatePatientDto
     */
    'customCreatePatientRepositoryDto': Array<CustomCreatePatientRepositoryDto>;
}
/**
 * 
 * @export
 * @interface CustomCreatePatientRepositoryDto
 */
export interface CustomCreatePatientRepositoryDto {
    /**
     * Mwell ID
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'mwellUserId'?: string;
    /**
     * Profile Photo URL
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'profilePhotoUrl'?: string;
    /**
     * Suffix
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'suffix'?: string;
    /**
     * First Name
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'firstName': string;
    /**
     * Middle Name
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'middleName'?: string;
    /**
     * Last Name
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'lastName': string;
    /**
     * Nick Name
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'nickName'?: string;
    /**
     * Gender
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'gender': string;
    /**
     * Phone
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'phone'?: string;
    /**
     * Email
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'email'?: string;
    /**
     * Blood Type
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'bloodType'?: string;
    /**
     * Is PWD
     * @type {boolean}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'isPWD'?: boolean;
    /**
     * Nationality
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'nationality'?: string;
    /**
     * Marital Status
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'maritalStatus'?: string;
    /**
     * Ethnicity
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'ethnicity'?: string;
    /**
     * Religion
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'religion'?: string;
    /**
     * Date of Birth
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'dateOfBirth': string;
    /**
     * Date of Death
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'dateOfDeath'?: string;
    /**
     * Deleted By UID
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'deletedByUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'external_reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'external_created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomCreatePatientRepositoryDto
     */
    'external_created_by_uid'?: string;
}
/**
 * 
 * @export
 * @interface CustomSchedule
 */
export interface CustomSchedule {
    /**
     * 
     * @type {string}
     * @memberof CustomSchedule
     */
    'scheduleId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomSchedule
     */
    'serviceId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomSchedule
     */
    'dateTime': string;
}
/**
 * 
 * @export
 * @interface DeleteProviderServiceDto
 */
export interface DeleteProviderServiceDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteProviderServiceDto
     */
    'updateType': DeleteProviderServiceDtoUpdateTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteProviderServiceDto
     */
    'providerServiceBranchesToDelete'?: Array<number>;
}

export const DeleteProviderServiceDtoUpdateTypeEnum = {
    All: 'All',
    OverrideSelectedBranches: 'Override Selected Branches'
} as const;

export type DeleteProviderServiceDtoUpdateTypeEnum = typeof DeleteProviderServiceDtoUpdateTypeEnum[keyof typeof DeleteProviderServiceDtoUpdateTypeEnum];

/**
 * 
 * @export
 * @interface Details
 */
export interface Details {
    /**
     * 
     * @type {number}
     * @memberof Details
     */
    'discount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Details
     */
    'serviceCharge'?: number;
    /**
     * 
     * @type {number}
     * @memberof Details
     */
    'shippingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Details
     */
    'tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof Details
     */
    'subtotal'?: number;
}
/**
 * 
 * @export
 * @interface DiagnosticRequestsDto
 */
export interface DiagnosticRequestsDto {
    /**
     * 
     * @type {string}
     * @memberof DiagnosticRequestsDto
     */
    'labRequests': string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticRequestsDto
     */
    'imagingRequests': string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticRequestsDto
     */
    'otherDiagnostics': string;
}
/**
 * 
 * @export
 * @interface DigitalIdPatientProviderAccess
 */
export interface DigitalIdPatientProviderAccess {
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'status': DigitalIdPatientProviderAccessStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'digitalIdPatientProviderAccessId': string;
    /**
     * 
     * @type {Provider}
     * @memberof DigitalIdPatientProviderAccess
     */
    'provider': Provider;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DigitalIdPatientProviderAccess
     */
    'deletedAt'?: string;
}

export const DigitalIdPatientProviderAccessStatusEnum = {
    Denied: 'DENIED',
    Expired: 'EXPIRED',
    Granted: 'GRANTED',
    Pending: 'PENDING'
} as const;

export type DigitalIdPatientProviderAccessStatusEnum = typeof DigitalIdPatientProviderAccessStatusEnum[keyof typeof DigitalIdPatientProviderAccessStatusEnum];

/**
 * 
 * @export
 * @interface EcgDto
 */
export interface EcgDto {
    /**
     * 
     * @type {number}
     * @memberof EcgDto
     */
    'heartRatePerMin': number;
    /**
     * 
     * @type {string}
     * @memberof EcgDto
     */
    'rhythmResult': string;
}
/**
 * 
 * @export
 * @interface EntityType
 */
export interface EntityType {
    /**
     * 
     * @type {number}
     * @memberof EntityType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'type': EntityTypeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'description'?: string;
    /**
     * 
     * @type {Array<Service>}
     * @memberof EntityType
     */
    'services': Array<Service>;
    /**
     * 
     * @type {Array<ProviderService>}
     * @memberof EntityType
     */
    'providerServices': Array<ProviderService>;
    /**
     * 
     * @type {Array<Service>}
     * @memberof EntityType
     */
    'servicesWithThisProviderType': Array<Service>;
    /**
     * 
     * @type {EntityType}
     * @memberof EntityType
     */
    'providerType': EntityType;
    /**
     * 
     * @type {number}
     * @memberof EntityType
     */
    'providerTypeId': number;
    /**
     * 
     * @type {Array<Provider>}
     * @memberof EntityType
     */
    'providers'?: Array<Provider>;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'deletedAt'?: string;
}

export const EntityTypeTypeEnum = {
    Provider: 'Provider',
    Category: 'Category'
} as const;

export type EntityTypeTypeEnum = typeof EntityTypeTypeEnum[keyof typeof EntityTypeTypeEnum];

/**
 * 
 * @export
 * @interface ExchangeTokenDto
 */
export interface ExchangeTokenDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangeTokenDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeTokenDto
     */
    'client_id': string;
}
/**
 * 
 * @export
 * @interface FindAllPeAllergyResponseDto
 */
export interface FindAllPeAllergyResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeAllergyResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeAllergyRecord>}
     * @memberof FindAllPeAllergyResponseDto
     */
    'data': Array<PeAllergyRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAllergyResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeAllergyResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeAllergyResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAllergyResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeAllergyResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAllergyResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAllergyResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeAnthropometricResponseDto
 */
export interface FindAllPeAnthropometricResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeAnthropometricRecord>}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'data': Array<PeAnthropometricRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeAnthropometricResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeFamilyHistoryResponseDto
 */
export interface FindAllPeFamilyHistoryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeFamilyHistoryRecord>}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'data': Array<PeFamilyHistoryRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeFamilyHistoryResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeIdentificationDocumentResponseDto
 */
export interface FindAllPeIdentificationDocumentResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeIdentificationDocument>}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'data': Array<PeIdentificationDocument>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeIdentificationDocumentResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeMedicalHistoryResponseDto
 */
export interface FindAllPeMedicalHistoryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeMedicalHistoryRecord>}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'data': Array<PeMedicalHistoryRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicalHistoryResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeMedicationResponseDto
 */
export interface FindAllPeMedicationResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeMedicationResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeMedicationRecord>}
     * @memberof FindAllPeMedicationResponseDto
     */
    'data': Array<PeMedicationRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicationResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeMedicationResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeMedicationResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicationResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeMedicationResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicationResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeMedicationResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeSocialHistoryResponseDto
 */
export interface FindAllPeSocialHistoryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeSocialHistoryRecord>}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'data': Array<PeSocialHistoryRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSocialHistoryResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeSurgicalHistoryResponseDto
 */
export interface FindAllPeSurgicalHistoryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeSurgicalHistoryRecord>}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'data': Array<PeSurgicalHistoryRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeSurgicalHistoryResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeVaccinationResponseDto
 */
export interface FindAllPeVaccinationResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeVaccinationRecord>}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'data': Array<PeVaccinationRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVaccinationResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindAllPeVitalSignsResponseDto
 */
export interface FindAllPeVitalSignsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'current_page': number;
    /**
     * 
     * @type {Array<PeVitalSignRecord>}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'data': Array<PeVitalSignRecord>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'first_page_url': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'last_page': number;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'last_page_url': string;
    /**
     * 
     * @type {Array<PeBaseLinkType>}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'links': Array<PeBaseLinkType>;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'next_page_url': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPeVitalSignsResponseDto
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface FindFeedbackResponse
 */
export interface FindFeedbackResponse {
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'authorId': string;
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'entityId': string;
    /**
     * 
     * @type {number}
     * @memberof FindFeedbackResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'origin': string;
    /**
     * 
     * @type {number}
     * @memberof FindFeedbackResponse
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'recipientId': string;
    /**
     * 
     * @type {string}
     * @memberof FindFeedbackResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {Appointment}
     * @memberof FindFeedbackResponse
     */
    'appointment'?: Appointment;
}
/**
 * 
 * @export
 * @interface GetAdminAppointmentSummary
 */
export interface GetAdminAppointmentSummary {
    /**
     * 
     * @type {number}
     * @memberof GetAdminAppointmentSummary
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof GetAdminAppointmentSummary
     */
    'totalAppointments': number;
    /**
     * 
     * @type {number}
     * @memberof GetAdminAppointmentSummary
     */
    'totalBookingFees': number;
}
/**
 * 
 * @export
 * @interface GetMessageThreadByMemberIdsDto
 */
export interface GetMessageThreadByMemberIdsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMessageThreadByMemberIdsDto
     */
    'memberIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GetMwellUserHmoDto
 */
export interface GetMwellUserHmoDto {
    /**
     * 
     * @type {HmoResponseDto}
     * @memberof GetMwellUserHmoDto
     */
    'hmo': HmoResponseDto;
    /**
     * 
     * @type {string}
     * @memberof GetMwellUserHmoDto
     */
    'cardNumber': string;
    /**
     * 
     * @type {number}
     * @memberof GetMwellUserHmoDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface GetOCRDto
 */
export interface GetOCRDto {
    /**
     * 
     * @type {string}
     * @memberof GetOCRDto
     */
    'imageBase64'?: string;
}
/**
 * 
 * @export
 * @interface GetPatientProviderAccessResponseDto
 */
export interface GetPatientProviderAccessResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetPatientProviderAccessResponseDto
     */
    'accessToken': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPatientProviderAccessResponseDto
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {PatientProviderAccess}
     * @memberof GetPatientProviderAccessResponseDto
     */
    'externalPatientProviderAccess': PatientProviderAccess;
    /**
     * 
     * @type {DigitalIdPatientProviderAccess}
     * @memberof GetPatientProviderAccessResponseDto
     */
    'patientProviderAccess': DigitalIdPatientProviderAccess;
}
/**
 * 
 * @export
 * @interface GetProviderResponseDto
 */
export interface GetProviderResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetProviderResponseDto
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof GetProviderResponseDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetProviderResponseDto
     */
    'message': string;
    /**
     * 
     * @type {object}
     * @memberof GetProviderResponseDto
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface GetProviderUnreadNotifCountByTypeResponseDto
 */
export interface GetProviderUnreadNotifCountByTypeResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetProviderUnreadNotifCountByTypeResponseDto
     */
    'appointment'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProviderUnreadNotifCountByTypeResponseDto
     */
    'client'?: number;
}
/**
 * 
 * @export
 * @interface HmoResponseDto
 */
export interface HmoResponseDto {
    /**
     * 
     * @type {number}
     * @memberof HmoResponseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HmoResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HmoResponseDto
     */
    'logoUrl': string;
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'description'?: string;
    /**
     * 
     * @type {Amount}
     * @memberof Item
     */
    'amount'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof Item
     */
    'totalAmount': Amount;
}
/**
 * 
 * @export
 * @interface KeyMetricsDto
 */
export interface KeyMetricsDto {
    /**
     * 
     * @type {number}
     * @memberof KeyMetricsDto
     */
    'appointmentsByClients': number;
    /**
     * 
     * @type {number}
     * @memberof KeyMetricsDto
     */
    'appointmentsByProviders': number;
    /**
     * 
     * @type {number}
     * @memberof KeyMetricsDto
     */
    'totalAppointments': number;
    /**
     * 
     * @type {number}
     * @memberof KeyMetricsDto
     */
    'totalProviders': number;
    /**
     * 
     * @type {number}
     * @memberof KeyMetricsDto
     */
    'publishedProviders': number;
    /**
     * 
     * @type {number}
     * @memberof KeyMetricsDto
     */
    'unpublishedProviders': number;
    /**
     * 
     * @type {number}
     * @memberof KeyMetricsDto
     */
    'clientsCreatedByProviders': number;
}
/**
 * 
 * @export
 * @interface LoginWithEmailDto
 */
export interface LoginWithEmailDto {
    /**
     * 
     * @type {string}
     * @memberof LoginWithEmailDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithEmailDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginWithPhoneNumberDto
 */
export interface LoginWithPhoneNumberDto {
    /**
     * 
     * @type {string}
     * @memberof LoginWithPhoneNumberDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithPhoneNumberDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithPhoneNumberDto
     */
    'phoneNumberPrefix': string;
}
/**
 * 
 * @export
 * @interface MedicalInstruction
 */
export interface MedicalInstruction {
    /**
     * 
     * @type {number}
     * @memberof MedicalInstruction
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstruction
     */
    'genericName': string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstruction
     */
    'dosage': string;
    /**
     * 
     * @type {number}
     * @memberof MedicalInstruction
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstruction
     */
    'routeFrequencyDuration': string;
    /**
     * 
     * @type {ConsultationNote}
     * @memberof MedicalInstruction
     */
    'consultationNote': ConsultationNote;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstruction
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstruction
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstruction
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface MedicalInstructionDto
 */
export interface MedicalInstructionDto {
    /**
     * 
     * @type {string}
     * @memberof MedicalInstructionDto
     */
    'genericName': string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstructionDto
     */
    'dosage': string;
    /**
     * 
     * @type {number}
     * @memberof MedicalInstructionDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof MedicalInstructionDto
     */
    'routeFrequencyDuration': string;
}
/**
 * 
 * @export
 * @interface MedicalRecordFile
 */
export interface MedicalRecordFile {
    /**
     * 
     * @type {number}
     * @memberof MedicalRecordFile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecordFile
     */
    'name': string;
    /**
     * 
     * @type {ProviderClientProfile}
     * @memberof MedicalRecordFile
     */
    'providerClientProfile': ProviderClientProfile;
    /**
     * 
     * @type {ClientProfile}
     * @memberof MedicalRecordFile
     */
    'clientProfile': ClientProfile;
    /**
     * 
     * @type {User}
     * @memberof MedicalRecordFile
     */
    'user': User;
    /**
     * 
     * @type {Appointment}
     * @memberof MedicalRecordFile
     */
    'appointment': Appointment;
    /**
     * 
     * @type {any}
     * @memberof MedicalRecordFile
     */
    'file': any;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecordFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecordFile
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecordFile
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'mimeType': string;
    /**
     * 
     * @type {UserFile}
     * @memberof ModelFile
     */
    'userFile'?: UserFile;
    /**
     * 
     * @type {ReferralFile}
     * @memberof ModelFile
     */
    'referralFile'?: ReferralFile;
    /**
     * 
     * @type {MedicalRecordFile}
     * @memberof ModelFile
     */
    'medicalRecordFile'?: MedicalRecordFile;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'medium': NotificationMediumEnum;
    /**
     * 
     * @type {object}
     * @memberof Notification
     */
    'metadata': object;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'isRead': boolean;
    /**
     * 
     * @type {Provider}
     * @memberof Notification
     */
    'provider': Provider;
    /**
     * 
     * @type {UserProvider}
     * @memberof Notification
     */
    'userProvider': UserProvider;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'deletedAt'?: string;
}

export const NotificationMediumEnum = {
    Email: 'EMAIL',
    InApp: 'IN_APP',
    Sms: 'SMS',
    Mwell: 'MWELL'
} as const;

export type NotificationMediumEnum = typeof NotificationMediumEnum[keyof typeof NotificationMediumEnum];

/**
 * 
 * @export
 * @interface OnboardProviderDto
 */
export interface OnboardProviderDto {
    /**
     * 
     * @type {UpdateProviderDto}
     * @memberof OnboardProviderDto
     */
    'provider': UpdateProviderDto;
    /**
     * 
     * @type {CreateProviderBranchDto}
     * @memberof OnboardProviderDto
     */
    'branch': CreateProviderBranchDto;
}
/**
 * 
 * @export
 * @interface OnboardingAgreementDocumentsDto
 */
export interface OnboardingAgreementDocumentsDto {
    /**
     * 
     * @type {string}
     * @memberof OnboardingAgreementDocumentsDto
     */
    'merchantAgreement': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingAgreementDocumentsDto
     */
    'nda': string;
}
/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'mwellUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'pePatientId': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'universalPatientId': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'profilePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'nickName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'bloodType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    'isPWD': boolean;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'nationality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'maritalStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'ethnicity'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'religion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'dateOfDeath'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'deletedByUid'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Patient
     */
    'primaryAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Patient
     */
    'mailingAddress'?: Address;
}
/**
 * 
 * @export
 * @interface PatientProviderAccess
 */
export interface PatientProviderAccess {
    /**
     * 
     * @type {string}
     * @memberof PatientProviderAccess
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderAccess
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderAccess
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderAccess
     */
    'id': string;
    /**
     * 
     * @type {Patient}
     * @memberof PatientProviderAccess
     */
    'patient': Patient;
    /**
     * 
     * @type {Provider}
     * @memberof PatientProviderAccess
     */
    'provider': Provider;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderAccess
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderAccess
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderAccess
     */
    'status': PatientProviderAccessStatusEnum;
}

export const PatientProviderAccessStatusEnum = {
    Denied: 'DENIED',
    Expired: 'EXPIRED',
    Granted: 'GRANTED',
    Pending: 'PENDING'
} as const;

export type PatientProviderAccessStatusEnum = typeof PatientProviderAccessStatusEnum[keyof typeof PatientProviderAccessStatusEnum];

/**
 * 
 * @export
 * @interface PeAllergyRecord
 */
export interface PeAllergyRecord {
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeAllergyRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeAllergyRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'category_code': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'category_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'category_display': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'substance': string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'manifestation'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'severity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'criticality'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'verification_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'clinical_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeAllergyRecord
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof PeAllergyRecord
     */
    'is_active': number;
}
/**
 * 
 * @export
 * @interface PeAnthropometricRecord
 */
export interface PeAnthropometricRecord {
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'measurement_datetime': string;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'height_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'height_in': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'weight_kg': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'weight_lbs': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'body_mass_index': number;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'bmi_interpretation_asian': string;
    /**
     * 
     * @type {string}
     * @memberof PeAnthropometricRecord
     */
    'bmi_interpretation_WHO': string;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_head_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_neck_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_chest_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_waist_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_hip_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'waist_hip_ratio': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_mid_upper_arm_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_mid_upper_arm_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_mid_thigh_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_mid_thigh_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_calf_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'cicrumference_calf_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'length_upper_arm_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'length_upper_arm_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'length_upper_leg_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'length_upper_leg_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_subscapular_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_subscapular_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_chest_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_biceps_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_biceps_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_triceps_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_triceps_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_abdomen_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_iliac_crest_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_thigh_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_thigh_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_calf_l_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'thickness_calf_r_cm': number;
    /**
     * 
     * @type {number}
     * @memberof PeAnthropometricRecord
     */
    'is_active': number;
}
/**
 * 
 * @export
 * @interface PeBaseLinkType
 */
export interface PeBaseLinkType {
    /**
     * 
     * @type {string}
     * @memberof PeBaseLinkType
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PeBaseLinkType
     */
    'label': string;
    /**
     * 
     * @type {boolean}
     * @memberof PeBaseLinkType
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface PeFamilyHistoryRecord
 */
export interface PeFamilyHistoryRecord {
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeFamilyHistoryRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeFamilyHistoryRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'relationship': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'condition_illness': string;
    /**
     * 
     * @type {string}
     * @memberof PeFamilyHistoryRecord
     */
    'remarks': string;
}
/**
 * 
 * @export
 * @interface PeIdentificationDocument
 */
export interface PeIdentificationDocument {
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeIdentificationDocument
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeIdentificationDocument
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'type_code': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'type_text': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'type_display': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'photo_url': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'photo_filename': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'validity_start_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof PeIdentificationDocument
     */
    'validity_expiry_datetime'?: string;
}
/**
 * 
 * @export
 * @interface PeMedicalDocument
 */
export interface PeMedicalDocument {
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicalDocument
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicalDocument
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'disk': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'file_url': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'tags': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalDocument
     */
    'deleted_at': string;
}
/**
 * 
 * @export
 * @interface PeMedicalHistoryRecord
 */
export interface PeMedicalHistoryRecord {
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicalHistoryRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicalHistoryRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'condition_illness': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'condition_date': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'condition_date_estimate': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'hospital': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicalHistoryRecord
     */
    'remarks': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicalHistoryRecord
     */
    'is_active': number;
}
/**
 * 
 * @export
 * @interface PeMedicationRecord
 */
export interface PeMedicationRecord {
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicationRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicationRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'date_prescribed': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'date_prescribed_estimate': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'generic_name': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'strength_dosage_form': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'brand_name': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicationRecord
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'signa': string;
    /**
     * 
     * @type {string}
     * @memberof PeMedicationRecord
     */
    'notes': string;
    /**
     * 
     * @type {number}
     * @memberof PeMedicationRecord
     */
    'is_active': number;
}
/**
 * 
 * @export
 * @interface PeSocialHistoryRecord
 */
export interface PeSocialHistoryRecord {
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'is_smoker': number;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'sticks_per_day': number;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'years': number;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'pack_years': number;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'is_alcohol': number;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'alcohol': string;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'is_drug': number;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'drug': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'diet': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'physical_activity': string;
    /**
     * 
     * @type {string}
     * @memberof PeSocialHistoryRecord
     */
    'remarks': string;
    /**
     * 
     * @type {number}
     * @memberof PeSocialHistoryRecord
     */
    'is_active': number;
}
/**
 * 
 * @export
 * @interface PeSurgicalHistoryRecord
 */
export interface PeSurgicalHistoryRecord {
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeSurgicalHistoryRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeSurgicalHistoryRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'operation_procedure': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'operation_date': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'operation_date_estimate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'indication': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'hospital': string;
    /**
     * 
     * @type {string}
     * @memberof PeSurgicalHistoryRecord
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof PeSurgicalHistoryRecord
     */
    'is_active': number;
}
/**
 * 
 * @export
 * @interface PeVaccinationRecord
 */
export interface PeVaccinationRecord {
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeVaccinationRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeVaccinationRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'vaccine_name': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'brand_name': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'series': string;
    /**
     * 
     * @type {number}
     * @memberof PeVaccinationRecord
     */
    'dose_mL': number;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'site': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'route': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'lot_number': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'administered_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'administered_on': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'administered_on_estimate': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'facility': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'next_dose_on': string;
    /**
     * 
     * @type {string}
     * @memberof PeVaccinationRecord
     */
    'next_dose_on_estimate': string;
    /**
     * 
     * @type {number}
     * @memberof PeVaccinationRecord
     */
    'is_active': number;
}
/**
 * 
 * @export
 * @interface PeVitalSignRecord
 */
export interface PeVitalSignRecord {
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'subaccount_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'modified_by': string;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'patient_id': number;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'external_reference': string;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'external_created_by': string;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'external_created_by_uid': string;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'measurement_datetime': string;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'temperature_c': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'temperature_f': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'systolic_blood_pressure': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'diastolic_blood_pressure': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'pulse_rate': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'respiratory_rate': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'pain': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'oxygen_saturation': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'capillary_blood_glucose_mgdl': number;
    /**
     * 
     * @type {number}
     * @memberof PeVitalSignRecord
     */
    'capillary_blood_glucose_mmoll': number;
    /**
     * 
     * @type {string}
     * @memberof PeVitalSignRecord
     */
    'remarks': string;
    /**
     * 
     * @type {boolean}
     * @memberof PeVitalSignRecord
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Promotion
     */
    'isPublished': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof Promotion
     */
    'providerIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface PromotionWithProvidersDto
 */
export interface PromotionWithProvidersDto {
    /**
     * 
     * @type {Array<Provider>}
     * @memberof PromotionWithProvidersDto
     */
    'providers': Array<Provider>;
    /**
     * 
     * @type {number}
     * @memberof PromotionWithProvidersDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionWithProvidersDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionWithProvidersDto
     */
    'isPublished': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PromotionWithProvidersDto
     */
    'providerIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PromotionWithProvidersDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionWithProvidersDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionWithProvidersDto
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * 
     * @type {number}
     * @memberof Provider
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'officeHours': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'region'?: ProviderRegionEnum;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'website'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    'canWorkWithChildren': boolean;
    /**
     * 
     * @type {EntityType}
     * @memberof Provider
     */
    'providerType': EntityType;
    /**
     * 
     * @type {number}
     * @memberof Provider
     */
    'providerTypeId': number;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof Provider
     */
    'providerTypes'?: Array<EntityType>;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'paymentQrCodeUrl'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Provider
     */
    'address': Address;
    /**
     * 
     * @type {Array<string>}
     * @memberof Provider
     */
    'paymentMethod'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Provider
     */
    'acceptedHmos'?: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof Provider
     */
    'schedule'?: object;
    /**
     * 
     * @type {Array<UserProvider>}
     * @memberof Provider
     */
    'userProviders'?: Array<UserProvider>;
    /**
     * 
     * @type {Array<Representative>}
     * @memberof Provider
     */
    'representatives'?: Array<Representative>;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'status': ProviderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'onboardingStatus': ProviderOnboardingStatusEnum;
    /**
     * 
     * @type {Array<User>}
     * @memberof Provider
     */
    'patients'?: Array<User>;
    /**
     * 
     * @type {Array<ProviderService>}
     * @memberof Provider
     */
    'services'?: Array<ProviderService>;
    /**
     * 
     * @type {Array<ProviderClientProfile>}
     * @memberof Provider
     */
    'providerClientProfiles'?: Array<ProviderClientProfile>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof Provider
     */
    'schedules': Array<Schedule>;
    /**
     * 
     * @type {Array<ProviderServiceSchedule>}
     * @memberof Provider
     */
    'providerServiceSchedules': Array<ProviderServiceSchedule>;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof Provider
     */
    'appointments': Array<Appointment>;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'slugName': string;
    /**
     * 
     * @type {Array<ProviderTagCategory>}
     * @memberof Provider
     */
    'providerTagCategories': Array<ProviderTagCategory>;
    /**
     * 
     * @type {Array<ProviderTag>}
     * @memberof Provider
     */
    'providerTags': Array<ProviderTag>;
    /**
     * 
     * @type {Array<ProviderClientNotes>}
     * @memberof Provider
     */
    'providerClientNotes'?: Array<ProviderClientNotes>;
    /**
     * 
     * @type {Array<ProviderShareLink>}
     * @memberof Provider
     */
    'providerShareLinks'?: Array<ProviderShareLink>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof Provider
     */
    'providerReferrals'?: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof Provider
     */
    'providerReferredProviders'?: Array<Referral>;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof Provider
     */
    'notifications': Array<Notification>;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    'merchantAgreement': boolean;
    /**
     * 
     * @type {Provider}
     * @memberof Provider
     */
    'parentProvider': Provider | null;
    /**
     * 
     * @type {Array<Provider>}
     * @memberof Provider
     */
    'branches'?: Array<Provider>;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    'isBranch': boolean;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    'isAutoApproveAppointmentEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    'isMphProvider': boolean;
    /**
     * 
     * @type {ProviderServiceSettings}
     * @memberof Provider
     */
    'providerServiceSettings': ProviderServiceSettings;
    /**
     * 
     * @type {Array<ClientFavourite>}
     * @memberof Provider
     */
    'clientFavourites'?: Array<ClientFavourite>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Provider
     */
    'referredOnlyToBranchIds'?: Array<number>;
    /**
     * 
     * @type {Array<ApiKey>}
     * @memberof Provider
     */
    'apiKeys': Array<ApiKey>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Provider
     */
    'customFields'?: Array<object>;
    /**
     * 
     * @type {ProviderDirectorySearch}
     * @memberof Provider
     */
    'providerDirectorySearch': ProviderDirectorySearch;
    /**
     * 
     * @type {ProviderOnboardingProgression}
     * @memberof Provider
     */
    'onboardingProgression': ProviderOnboardingProgression;
    /**
     * 
     * @type {DigitalIdPatientProviderAccess}
     * @memberof Provider
     */
    'digitalIdPatientProviderAccesses': DigitalIdPatientProviderAccess;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'deletedAt'?: string;
}

export const ProviderRegionEnum = {
    BangsamoroAutonomousRegionInMuslimMindanaoBarmm: 'Bangsamoro Autonomous Region in Muslim Mindanao (BARMM)',
    CordilleraAdministrativeRegionCar: 'Cordillera Administrative Region (CAR)',
    NationalCapitalRegionNcr: 'National Capital Region (NCR)',
    RegionIIlocosRegion: 'Region I (Ilocos Region)',
    RegionIiCagayanValley: 'Region II (Cagayan Valley)',
    RegionIiiCentralLuzon: 'Region III (Central Luzon)',
    RegionIvACalabarzon: 'Region IV-A (CALABARZON)',
    RegionIvBMimaropa: 'Region IV-B (MIMAROPA)',
    RegionVBicolRegion: 'Region V (Bicol Region)',
    RegionViWesternVisayas: 'Region VI (Western Visayas)',
    RegionViiCentralVisayas: 'Region VII (Central Visayas)',
    RegionViiiEasternVisayas: 'Region VIII (Eastern Visayas)',
    RegionIxZamboangaPeninsula: 'Region IX (Zamboanga Peninsula)',
    RegionXNorthernMindanao: 'Region X (Northern Mindanao)',
    RegionXiDavaoRegion: 'Region XI (Davao Region)',
    RegionXiiSoccsksargen: 'Region XII (SOCCSKSARGEN)',
    RegionXiiiCaraga: 'Region XIII (Caraga)'
} as const;

export type ProviderRegionEnum = typeof ProviderRegionEnum[keyof typeof ProviderRegionEnum];
export const ProviderStatusEnum = {
    Published: 'PUBLISHED',
    Unpublished: 'UNPUBLISHED',
    Inactive: 'INACTIVE',
    Active: 'ACTIVE'
} as const;

export type ProviderStatusEnum = typeof ProviderStatusEnum[keyof typeof ProviderStatusEnum];
export const ProviderOnboardingStatusEnum = {
    Pending: 'PENDING',
    Directory: 'DIRECTORY',
    SelfOnboarded: 'SELF_ONBOARDED',
    Onboarded: 'ONBOARDED'
} as const;

export type ProviderOnboardingStatusEnum = typeof ProviderOnboardingStatusEnum[keyof typeof ProviderOnboardingStatusEnum];

/**
 * 
 * @export
 * @interface ProviderClientNotes
 */
export interface ProviderClientNotes {
    /**
     * 
     * @type {number}
     * @memberof ProviderClientNotes
     */
    'id': number;
    /**
     * 
     * @type {ProviderClientProfile}
     * @memberof ProviderClientNotes
     */
    'providerClientProfile': ProviderClientProfile;
    /**
     * 
     * @type {Appointment}
     * @memberof ProviderClientNotes
     */
    'appointment'?: Appointment;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderClientNotes
     */
    'provider': Provider;
    /**
     * 
     * @type {UserProvider}
     * @memberof ProviderClientNotes
     */
    'userProvider'?: UserProvider;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientNotes
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientNotes
     */
    'remarks': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientNotes
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientNotes
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientNotes
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProviderClientProfile
 */
export interface ProviderClientProfile {
    /**
     * 
     * @type {number}
     * @memberof ProviderClientProfile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'suffix': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'reasonForVisit': string;
    /**
     * 
     * @type {number}
     * @memberof ProviderClientProfile
     */
    'hmoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'hmoNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'philhealthKonsultaNumber': string;
    /**
     * 
     * @type {ClientProfile}
     * @memberof ProviderClientProfile
     */
    'clientProfile': ClientProfile;
    /**
     * 
     * @type {Array<ProviderClientNotes>}
     * @memberof ProviderClientProfile
     */
    'providerClientNotes'?: Array<ProviderClientNotes>;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderClientProfile
     */
    'provider': Provider;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof ProviderClientProfile
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ProviderClientProfile
     */
    'referralProviderClientProfiles'?: Array<Referral>;
    /**
     * 
     * @type {Array<MedicalRecordFile>}
     * @memberof ProviderClientProfile
     */
    'medicalRecordFiles'?: Array<MedicalRecordFile>;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'smoker'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'drinker'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderClientProfile
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProviderDirectorySearch
 */
export interface ProviderDirectorySearch {
    /**
     * 
     * @type {number}
     * @memberof ProviderDirectorySearch
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderDirectorySearch
     */
    'searchId': string;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderDirectorySearch
     */
    'provider': Provider;
    /**
     * 
     * @type {string}
     * @memberof ProviderDirectorySearch
     */
    'type': ProviderDirectorySearchTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof ProviderDirectorySearch
     */
    'jsonData': object;
    /**
     * 
     * @type {string}
     * @memberof ProviderDirectorySearch
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDirectorySearch
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDirectorySearch
     */
    'deletedAt'?: string;
}

export const ProviderDirectorySearchTypeEnum = {
    OsmOverpassApi: 'OSM_OVERPASS_API'
} as const;

export type ProviderDirectorySearchTypeEnum = typeof ProviderDirectorySearchTypeEnum[keyof typeof ProviderDirectorySearchTypeEnum];

/**
 * 
 * @export
 * @interface ProviderOnboardingProgression
 */
export interface ProviderOnboardingProgression {
    /**
     * 
     * @type {number}
     * @memberof ProviderOnboardingProgression
     */
    'id': number;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderOnboardingProgression
     */
    'provider': Provider;
    /**
     * 
     * @type {number}
     * @memberof ProviderOnboardingProgression
     */
    'currentStep': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'documentReviewStatus': ProviderOnboardingProgressionDocumentReviewStatusEnum;
    /**
     * 
     * @type {any}
     * @memberof ProviderOnboardingProgression
     */
    'dtiCertificateFile'?: any;
    /**
     * 
     * @type {any}
     * @memberof ProviderOnboardingProgression
     */
    'birCertificateFile'?: any;
    /**
     * 
     * @type {any}
     * @memberof ProviderOnboardingProgression
     */
    'mayorsPermitFile'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderOnboardingProgression
     */
    'hasAgreedToNDAMerchantAgreement': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'dateApproved'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'rejectionReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'dateRejected'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'resubmissionReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'dateRequestedResubmission'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'dateResubmittedDocuments'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'dateSubmittedDocuments'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderOnboardingProgression
     */
    'deletedAt'?: string;
}

export const ProviderOnboardingProgressionDocumentReviewStatusEnum = {
    ForSubmission: 'FOR_SUBMISSION',
    ForReview: 'FOR_REVIEW',
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
} as const;

export type ProviderOnboardingProgressionDocumentReviewStatusEnum = typeof ProviderOnboardingProgressionDocumentReviewStatusEnum[keyof typeof ProviderOnboardingProgressionDocumentReviewStatusEnum];

/**
 * 
 * @export
 * @interface ProviderService
 */
export interface ProviderService {
    /**
     * 
     * @type {number}
     * @memberof ProviderService
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'availability'?: ProviderServiceAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProviderService
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ProviderService
     */
    'maxPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'specialNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'freePriceLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'procedurePreparation'?: string;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderService
     */
    'provider': Provider;
    /**
     * 
     * @type {Service}
     * @memberof ProviderService
     */
    'service': Service;
    /**
     * 
     * @type {Array<AppointmentService>}
     * @memberof ProviderService
     */
    'appointmentServices': Array<AppointmentService>;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'priceType': ProviderServicePriceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderService
     */
    'hidden': boolean;
    /**
     * 
     * @type {EntityType}
     * @memberof ProviderService
     */
    'serviceCategory': EntityType;
    /**
     * 
     * @type {number}
     * @memberof ProviderService
     */
    'serviceCategoryId': number;
    /**
     * 
     * @type {ProviderService}
     * @memberof ProviderService
     */
    'parentProviderService': ProviderService | null;
    /**
     * 
     * @type {Array<ProviderService>}
     * @memberof ProviderService
     */
    'branchServices'?: Array<ProviderService>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof ProviderService
     */
    'schedules'?: Array<Schedule>;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderService
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {ProviderServiceSettings}
     * @memberof ProviderService
     */
    'providerServiceSettings': ProviderServiceSettings;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'bookingPaymentRequirement': ProviderServiceBookingPaymentRequirementEnum;
    /**
     * 
     * @type {number}
     * @memberof ProviderService
     */
    'downPaymentAmount': number;
    /**
     * 
     * @type {Array<ClientFavourite>}
     * @memberof ProviderService
     */
    'clientFavourites'?: Array<ClientFavourite>;
    /**
     * 
     * @type {Array<ProviderServiceSchedule>}
     * @memberof ProviderService
     */
    'providerServiceSchedules': Array<ProviderServiceSchedule>;
    /**
     * 
     * @type {Array<ProviderServiceSchedule>}
     * @memberof ProviderService
     */
    'parentProviderServiceSchedules': Array<ProviderServiceSchedule>;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderService
     */
    'deletedAt'?: string;
}

export const ProviderServiceAvailabilityEnum = {
    Available: 'Available',
    Unavailable: 'Unavailable',
    AvailableInSelectedBranches: 'Available in selected Branches'
} as const;

export type ProviderServiceAvailabilityEnum = typeof ProviderServiceAvailabilityEnum[keyof typeof ProviderServiceAvailabilityEnum];
export const ProviderServicePriceTypeEnum = {
    Free: 'free',
    Range: 'range',
    Fixed: 'fixed',
    Hide: 'hide',
    StartsAt: 'startsAt'
} as const;

export type ProviderServicePriceTypeEnum = typeof ProviderServicePriceTypeEnum[keyof typeof ProviderServicePriceTypeEnum];
export const ProviderServiceBookingPaymentRequirementEnum = {
    FullPayment: 'fullPayment',
    DownPayment: 'downPayment',
    None: 'none'
} as const;

export type ProviderServiceBookingPaymentRequirementEnum = typeof ProviderServiceBookingPaymentRequirementEnum[keyof typeof ProviderServiceBookingPaymentRequirementEnum];

/**
 * 
 * @export
 * @interface ProviderServiceSchedule
 */
export interface ProviderServiceSchedule {
    /**
     * 
     * @type {number}
     * @memberof ProviderServiceSchedule
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSchedule
     */
    'day': ProviderServiceScheduleDayEnum;
    /**
     * 
     * @type {number}
     * @memberof ProviderServiceSchedule
     */
    'slots': number;
    /**
     * 
     * @type {number}
     * @memberof ProviderServiceSchedule
     */
    'timeSlotIntervalInMinutes': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof ProviderServiceSchedule
     */
    'timeSlots': Array<object>;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderServiceSchedule
     */
    'provider': Provider;
    /**
     * 
     * @type {ProviderService}
     * @memberof ProviderServiceSchedule
     */
    'providerService': ProviderService;
    /**
     * 
     * @type {ProviderService}
     * @memberof ProviderServiceSchedule
     */
    'parentProviderService': ProviderService;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof ProviderServiceSchedule
     */
    'schedules': Array<Schedule>;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSchedule
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSchedule
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSchedule
     */
    'deletedAt'?: string;
}

export const ProviderServiceScheduleDayEnum = {
    Sunday: 'sunday',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday'
} as const;

export type ProviderServiceScheduleDayEnum = typeof ProviderServiceScheduleDayEnum[keyof typeof ProviderServiceScheduleDayEnum];

/**
 * 
 * @export
 * @interface ProviderServiceSettings
 */
export interface ProviderServiceSettings {
    /**
     * 
     * @type {number}
     * @memberof ProviderServiceSettings
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderServiceSettings
     */
    'requireReservationFee': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProviderServiceSettings
     */
    'reservationFee': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSettings
     */
    'reservationPolicyNotes': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderServiceSettings
     */
    'nonRefundable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderServiceSettings
     */
    'deductible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderServiceSettings
     */
    'nonRefundableClientNoShowCancellation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderServiceSettings
     */
    'newClientsOnly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderServiceSettings
     */
    'selectServicesOnly': boolean;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderServiceSettings
     */
    'provider': Provider;
    /**
     * 
     * @type {Array<ProviderService>}
     * @memberof ProviderServiceSettings
     */
    'providerServices': Array<ProviderService>;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSettings
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSettings
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderServiceSettings
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProviderShareLink
 */
export interface ProviderShareLink {
    /**
     * 
     * @type {number}
     * @memberof ProviderShareLink
     */
    'id': number;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderShareLink
     */
    'provider': Provider;
    /**
     * 
     * @type {number}
     * @memberof ProviderShareLink
     */
    'providerId': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderShareLink
     */
    'slugName': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderShareLink
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderShareLink
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderShareLink
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProviderTag
 */
export interface ProviderTag {
    /**
     * 
     * @type {number}
     * @memberof ProviderTag
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderTag
     */
    'tag': string;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderTag
     */
    'provider': Provider;
    /**
     * 
     * @type {ProviderTagCategory}
     * @memberof ProviderTag
     */
    'providerTagCategory': ProviderTagCategory;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof ProviderTag
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {string}
     * @memberof ProviderTag
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTag
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTag
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProviderTagCategory
 */
export interface ProviderTagCategory {
    /**
     * 
     * @type {number}
     * @memberof ProviderTagCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderTagCategory
     */
    'category': string;
    /**
     * 
     * @type {Provider}
     * @memberof ProviderTagCategory
     */
    'provider': Provider;
    /**
     * 
     * @type {Array<ProviderTag>}
     * @memberof ProviderTagCategory
     */
    'providerTags': Array<ProviderTag>;
    /**
     * 
     * @type {string}
     * @memberof ProviderTagCategory
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTagCategory
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTagCategory
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface RedirectUrls
 */
export interface RedirectUrls {
    /**
     * 
     * @type {string}
     * @memberof RedirectUrls
     */
    'success'?: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectUrls
     */
    'failure'?: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectUrls
     */
    'cancel'?: string;
}
/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'reasonForReferral': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'remarks': string;
    /**
     * 
     * @type {ProviderClientProfile}
     * @memberof Referral
     */
    'providerClientProfile'?: ProviderClientProfile;
    /**
     * 
     * @type {Provider}
     * @memberof Referral
     */
    'provider': Provider;
    /**
     * 
     * @type {Provider}
     * @memberof Referral
     */
    'referredProvider': Provider;
    /**
     * 
     * @type {Appointment}
     * @memberof Referral
     */
    'appointment'?: Appointment;
    /**
     * 
     * @type {Array<ReferralFile>}
     * @memberof Referral
     */
    'attachments'?: Array<ReferralFile>;
    /**
     * 
     * @type {Appointment}
     * @memberof Referral
     */
    'referredProviderAppointment'?: Appointment;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ReferralFile
 */
export interface ReferralFile {
    /**
     * 
     * @type {number}
     * @memberof ReferralFile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReferralFile
     */
    'name': string;
    /**
     * 
     * @type {Referral}
     * @memberof ReferralFile
     */
    'referral': Referral;
    /**
     * 
     * @type {any}
     * @memberof ReferralFile
     */
    'file': any;
    /**
     * 
     * @type {string}
     * @memberof ReferralFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralFile
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralFile
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenDto
 */
export interface RefreshTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    'refresh_token': string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    'client_id': string;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'code'?: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Region
     */
    'addresses'?: Array<Address>;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface RegionDto
 */
export interface RegionDto {
    /**
     * 
     * @type {number}
     * @memberof RegionDto
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface RegisterWithPhoneNumberDto
 */
export interface RegisterWithPhoneNumberDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterWithPhoneNumberDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithPhoneNumberDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithPhoneNumberDto
     */
    'phoneNumberPrefix': string;
}
/**
 * 
 * @export
 * @interface RejectSelfOnboardingDto
 */
export interface RejectSelfOnboardingDto {
    /**
     * 
     * @type {string}
     * @memberof RejectSelfOnboardingDto
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface RemoveFavouriteDto
 */
export interface RemoveFavouriteDto {
    /**
     * 
     * @type {number}
     * @memberof RemoveFavouriteDto
     */
    'id': number;
    /**
     * 
     * @type {ClientFavouriteType}
     * @memberof RemoveFavouriteDto
     */
    'type': ClientFavouriteType;
}


/**
 * 
 * @export
 * @interface Representative
 */
export interface Representative {
    /**
     * 
     * @type {number}
     * @memberof Representative
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Provider}
     * @memberof Representative
     */
    'provider': Provider | null;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface RequestPatientDataAccessResponseDto
 */
export interface RequestPatientDataAccessResponseDto {
    /**
     * 
     * @type {number}
     * @memberof RequestPatientDataAccessResponseDto
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof RequestPatientDataAccessResponseDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof RequestPatientDataAccessResponseDto
     */
    'message': string;
    /**
     * 
     * @type {object}
     * @memberof RequestPatientDataAccessResponseDto
     */
    'data'?: object | null;
}
/**
 * 
 * @export
 * @interface RequestResubmitSelfOnboardingDto
 */
export interface RequestResubmitSelfOnboardingDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestResubmitSelfOnboardingDto
     */
    'reasons': Array<string>;
}
/**
 * 
 * @export
 * @interface RescheduleAppointmentDto
 */
export interface RescheduleAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof RescheduleAppointmentDto
     */
    'dateTime': string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleAppointmentDto
     */
    'reasonForReschedule': string;
    /**
     * 
     * @type {number}
     * @memberof RescheduleAppointmentDto
     */
    'slotId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RescheduleAppointmentDto
     */
    'hospitalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RescheduleAppointmentDto
     */
    'resourceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RescheduleAppointmentDto
     */
    'serviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RescheduleAppointmentDto
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleAppointmentDto
     */
    'isRescheduledByPatient'?: boolean;
}
/**
 * 
 * @export
 * @interface ResendSsoOtpDto
 */
export interface ResendSsoOtpDto {
    /**
     * 
     * @type {string}
     * @memberof ResendSsoOtpDto
     */
    'sessionId'?: string;
}
/**
 * 
 * @export
 * @interface ResponseOcrDto
 */
export interface ResponseOcrDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrDto
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrDto
     */
    'sex': string;
}
/**
 * 
 * @export
 * @interface ResponseProviderShareLinkDto
 */
export interface ResponseProviderShareLinkDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseProviderShareLinkDto
     */
    'providerUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProviderShareLinkDto
     */
    'registrationUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProviderShareLinkDto
     */
    'qrCodeBase64': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProviderShareLinkDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SanitizedUserDto
 */
export interface SanitizedUserDto {
    /**
     * 
     * @type {string}
     * @memberof SanitizedUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SanitizedUserDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof SanitizedUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SanitizedUserDto
     */
    'userType': SanitizedUserDtoUserTypeEnum;
}

export const SanitizedUserDtoUserTypeEnum = {
    Admin: 'ADMIN',
    Finance: 'FINANCE',
    Marketing: 'MARKETING',
    Client: 'CLIENT',
    Patient: 'PATIENT',
    ProviderAdmin: 'PROVIDER_ADMIN',
    SuperAdmin: 'SUPER_ADMIN'
} as const;

export type SanitizedUserDtoUserTypeEnum = typeof SanitizedUserDtoUserTypeEnum[keyof typeof SanitizedUserDtoUserTypeEnum];

/**
 * 
 * @export
 * @interface SaveFavouriteDto
 */
export interface SaveFavouriteDto {
    /**
     * 
     * @type {number}
     * @memberof SaveFavouriteDto
     */
    'id': number;
    /**
     * 
     * @type {ClientFavouriteType}
     * @memberof SaveFavouriteDto
     */
    'type': ClientFavouriteType;
}


/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {number}
     * @memberof Schedule
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof Schedule
     */
    'slots'?: number;
    /**
     * 
     * @type {number}
     * @memberof Schedule
     */
    'bookedSlots'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Schedule
     */
    'isBlocked': boolean;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'day': ScheduleDayEnum;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'remarks'?: string;
    /**
     * 
     * @type {Provider}
     * @memberof Schedule
     */
    'provider': Provider;
    /**
     * 
     * @type {ProviderService}
     * @memberof Schedule
     */
    'providerService': ProviderService;
    /**
     * 
     * @type {ProviderServiceSchedule}
     * @memberof Schedule
     */
    'providerServiceSchedule': ProviderServiceSchedule;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof Schedule
     */
    'appointments': Array<Appointment>;
    /**
     * 
     * @type {Appointment}
     * @memberof Schedule
     */
    'appointmentsWithCustomSchedules': Appointment;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'deletedAt'?: string;
}

export const ScheduleDayEnum = {
    Sunday: 'sunday',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday'
} as const;

export type ScheduleDayEnum = typeof ScheduleDayEnum[keyof typeof ScheduleDayEnum];

/**
 * 
 * @export
 * @interface ScheduleDataDto
 */
export interface ScheduleDataDto {
    /**
     * 
     * @type {string}
     * @memberof ScheduleDataDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDataDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDataDto
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDataDto
     */
    'day': ScheduleDataDtoDayEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDataDto
     */
    'slots'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDataDto
     */
    'bookedSlots'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDataDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleDataDto
     */
    'isBlocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDataDto
     */
    'providerId'?: string;
}

export const ScheduleDataDtoDayEnum = {
    Sunday: 'sunday',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday'
} as const;

export type ScheduleDataDtoDayEnum = typeof ScheduleDataDtoDayEnum[keyof typeof ScheduleDataDtoDayEnum];

/**
 * 
 * @export
 * @interface ScheduleResponse
 */
export interface ScheduleResponse {
    /**
     * 
     * @type {number}
     * @memberof ScheduleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'day': ScheduleResponseDayEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduleResponse
     */
    'slots'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleResponse
     */
    'bookedSlots'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleResponse
     */
    'isBlocked'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScheduleResponse
     */
    'appointmentCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'startOfDayDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'dateWithTimeSlot'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleResponse
     */
    'type': ScheduleResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduleResponse
     */
    'providerServiceId'?: number;
}

export const ScheduleResponseDayEnum = {
    Sunday: 'sunday',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday'
} as const;

export type ScheduleResponseDayEnum = typeof ScheduleResponseDayEnum[keyof typeof ScheduleResponseDayEnum];
export const ScheduleResponseTypeEnum = {
    Regular: 'regular',
    Override: 'override',
    Block: 'block',
    Custom: 'custom'
} as const;

export type ScheduleResponseTypeEnum = typeof ScheduleResponseTypeEnum[keyof typeof ScheduleResponseTypeEnum];

/**
 * 
 * @export
 * @interface SelfOnboardDto
 */
export interface SelfOnboardDto {
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SelfOnboardDto
     */
    'address': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SelfOnboardDto
     */
    'providerTypeIds': Array<number>;
}
/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'description'?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof Service
     */
    'category': EntityType;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'categoryId': number;
    /**
     * 
     * @type {Array<ProviderService>}
     * @memberof Service
     */
    'providerServices': Array<ProviderService>;
    /**
     * 
     * @type {EntityType}
     * @memberof Service
     */
    'providerType': EntityType;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'providerTypeId': number;
    /**
     * 
     * @type {Array<AppointmentService>}
     * @memberof Service
     */
    'appointmentServices': Array<AppointmentService>;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ServiceDto
 */
export interface ServiceDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'availability'?: ServiceDtoAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'procedurePreparation'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceDto
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'specialNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'freePriceLabel'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceDto
     */
    'providerId': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceDto
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'priceType'?: ServiceDtoPriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceDto
     */
    'serviceCategoryId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceDto
     */
    'isFeatured'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ServiceDto
     */
    'providerServiceBranches'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ServiceDto
     */
    'parentProviderServiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'bookingPaymentRequirement'?: ServiceDtoBookingPaymentRequirementEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceDto
     */
    'downPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceDto
     */
    'serviceCategory': string;
}

export const ServiceDtoAvailabilityEnum = {
    Available: 'Available',
    Unavailable: 'Unavailable',
    AvailableInSelectedBranches: 'Available in selected Branches'
} as const;

export type ServiceDtoAvailabilityEnum = typeof ServiceDtoAvailabilityEnum[keyof typeof ServiceDtoAvailabilityEnum];
export const ServiceDtoPriceTypeEnum = {
    Free: 'free',
    Range: 'range',
    Fixed: 'fixed',
    Hide: 'hide',
    StartsAt: 'startsAt'
} as const;

export type ServiceDtoPriceTypeEnum = typeof ServiceDtoPriceTypeEnum[keyof typeof ServiceDtoPriceTypeEnum];
export const ServiceDtoBookingPaymentRequirementEnum = {
    FullPayment: 'fullPayment',
    DownPayment: 'downPayment',
    None: 'none'
} as const;

export type ServiceDtoBookingPaymentRequirementEnum = typeof ServiceDtoBookingPaymentRequirementEnum[keyof typeof ServiceDtoBookingPaymentRequirementEnum];

/**
 * 
 * @export
 * @interface ShippingAddress
 */
export interface ShippingAddress {
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'line2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'shippingType': ShippingAddressShippingTypeEnum;
}

export const ShippingAddressShippingTypeEnum = {
    Sd: 'SD',
    St: 'ST'
} as const;

export type ShippingAddressShippingTypeEnum = typeof ShippingAddressShippingTypeEnum[keyof typeof ShippingAddressShippingTypeEnum];

/**
 * 
 * @export
 * @interface SsoLoginDto
 */
export interface SsoLoginDto {
    /**
     * 
     * @type {string}
     * @memberof SsoLoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SsoLoginDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SsoLoginDto
     */
    'sessionId'?: string;
}
/**
 * 
 * @export
 * @interface SsoLogoutDto
 */
export interface SsoLogoutDto {
    /**
     * 
     * @type {string}
     * @memberof SsoLogoutDto
     */
    'refresh_token'?: string;
}
/**
 * 
 * @export
 * @interface SuggestProviderDto
 */
export interface SuggestProviderDto {
    /**
     * 
     * @type {string}
     * @memberof SuggestProviderDto
     */
    'cityName': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestProviderDto
     */
    'providerName': string;
}
/**
 * 
 * @export
 * @interface TimeSlot
 */
export interface TimeSlot {
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface TotalAmount
 */
export interface TotalAmount {
    /**
     * 
     * @type {number}
     * @memberof TotalAmount
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof TotalAmount
     */
    'currency': TotalAmountCurrencyEnum;
    /**
     * 
     * @type {Details}
     * @memberof TotalAmount
     */
    'details'?: Details;
}

export const TotalAmountCurrencyEnum = {
    Eur: 'EUR',
    Php: 'PHP',
    Usd: 'USD'
} as const;

export type TotalAmountCurrencyEnum = typeof TotalAmountCurrencyEnum[keyof typeof TotalAmountCurrencyEnum];

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'status': TransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'externalPaymentStatus'?: TransactionExternalPaymentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'totalAmountDue': number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'transactionFee': number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'quotedMinAmount': number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'quotedMaxAmount': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Transaction
     */
    'discounts'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'paymentMethod': TransactionPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'paymentOption': TransactionPaymentOptionEnum;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'philhealthId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'philhealthKonsultaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'hmoNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'hmoRegisteredDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'hmoValidUntilDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'pwdId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'seniorCitizenId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'officialReceiptNumber': number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'paymentReferenceNumber'?: string;
    /**
     * 
     * @type {object}
     * @memberof Transaction
     */
    'externalPaymentDetails'?: object;
    /**
     * 
     * @type {Appointment}
     * @memberof Transaction
     */
    'appointment'?: Appointment;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'inPersonPaymentStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'inPersonPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'inPersonTransactionNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'inPersonPaymentMethod'?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'totalAmountDueInPersonMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'totalAmountDueInPersonMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'totalAmountDueOnline'?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'proofOfPaymentUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'deletedAt'?: string;
}

export const TransactionStatusEnum = {
    Paid: 'paid',
    NotPaid: 'not-paid'
} as const;

export type TransactionStatusEnum = typeof TransactionStatusEnum[keyof typeof TransactionStatusEnum];
export const TransactionExternalPaymentStatusEnum = {
    Pending: 'PAYMENT_PENDING',
    Success: 'PAYMENT_SUCCESS',
    Failed: 'PAYMENT_FAILED',
    Expired: 'PAYMENT_EXPIRED',
    Cancelled: 'PAYMENT_CANCELLED'
} as const;

export type TransactionExternalPaymentStatusEnum = typeof TransactionExternalPaymentStatusEnum[keyof typeof TransactionExternalPaymentStatusEnum];
export const TransactionPaymentMethodEnum = {
    Later: 'pay-later',
    Now: 'pay-now',
    NowFull: 'pay-now-full'
} as const;

export type TransactionPaymentMethodEnum = typeof TransactionPaymentMethodEnum[keyof typeof TransactionPaymentMethodEnum];
export const TransactionPaymentOptionEnum = {
    Cash: 'cash',
    DebitCreditEwallet: 'debit/credit/ewallet',
    Hmo: 'hmo',
    Philhealth: 'philhealth',
    Qrph: 'qrph'
} as const;

export type TransactionPaymentOptionEnum = typeof TransactionPaymentOptionEnum[keyof typeof TransactionPaymentOptionEnum];

/**
 * 
 * @export
 * @interface UpdateAddressDto
 */
export interface UpdateAddressDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'unitRoomFloor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'line'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'buildingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'lotBlockPhaseHouse'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'streetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'municipalityCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'province'?: string;
    /**
     * 
     * @type {RegionDto}
     * @memberof UpdateAddressDto
     */
    'region'?: RegionDto | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'addressType'?: UpdateAddressDtoAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'foreignCountryAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressDto
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'googleMapsUrl'?: string;
}

export const UpdateAddressDtoAddressTypeEnum = {
    Mailing: 'MAILING',
    Permanent: 'PERMANENT',
    Current: 'CURRENT'
} as const;

export type UpdateAddressDtoAddressTypeEnum = typeof UpdateAddressDtoAddressTypeEnum[keyof typeof UpdateAddressDtoAddressTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAdminConfigDto
 */
export interface UpdateAdminConfigDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdminConfigDto
     */
    'homePageTagIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminConfigDto
     */
    'transactionFee'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateAdminConfigDto
     */
    'heroBanners'?: Array<object>;
}
/**
 * 
 * @export
 * @interface UpdateAdminUserDto
 */
export interface UpdateAdminUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAllergyRecordDto
 */
export interface UpdateAllergyRecordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'category_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'category_display'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'category_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'substance'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'manifestation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'severity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'criticality'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllergyRecordDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAnnouncementDto
 */
export interface UpdateAnnouncementDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'target'?: UpdateAnnouncementDtoTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'targetSpecification'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnouncementDto
     */
    'maxAge'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnouncementDto
     */
    'minAge'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'sex'?: UpdateAnnouncementDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'messageContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'redirectLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'scheduleAnnouncement'?: string;
}

export const UpdateAnnouncementDtoTargetEnum = {
    All: 'all',
    Clients: 'clients',
    Providers: 'providers',
    ClientsThroughTargeting: 'clientsThroughTargeting',
    ProvidersThroughTargeting: 'providersThroughTargeting'
} as const;

export type UpdateAnnouncementDtoTargetEnum = typeof UpdateAnnouncementDtoTargetEnum[keyof typeof UpdateAnnouncementDtoTargetEnum];
export const UpdateAnnouncementDtoSexEnum = {
    All: 'all',
    Male: 'male',
    Female: 'female'
} as const;

export type UpdateAnnouncementDtoSexEnum = typeof UpdateAnnouncementDtoSexEnum[keyof typeof UpdateAnnouncementDtoSexEnum];

/**
 * 
 * @export
 * @interface UpdateAnthropometricRecordDto
 */
export interface UpdateAnthropometricRecordDto {
    /**
     * Date measured on
     * @type {string}
     * @memberof UpdateAnthropometricRecordDto
     */
    'measurement_datetime'?: string;
    /**
     * Height in cm
     * @type {number}
     * @memberof UpdateAnthropometricRecordDto
     */
    'height_cm'?: number;
    /**
     * Height in in
     * @type {number}
     * @memberof UpdateAnthropometricRecordDto
     */
    'height_in'?: number;
    /**
     * Weight in kg
     * @type {number}
     * @memberof UpdateAnthropometricRecordDto
     */
    'weight_kg'?: number;
    /**
     * Weight in lbs
     * @type {number}
     * @memberof UpdateAnthropometricRecordDto
     */
    'weight_lbs'?: number;
    /**
     * Body Mass Index
     * @type {number}
     * @memberof UpdateAnthropometricRecordDto
     */
    'body_mass_index'?: number;
}
/**
 * 
 * @export
 * @interface UpdateApiFieldsDto
 */
export interface UpdateApiFieldsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateApiFieldsDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiFieldsDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiFieldsDto
     */
    'apiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiFieldsDto
     */
    'apiKeyHeader'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateApiFieldsDto
     */
    'productIds'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof UpdateApiFieldsDto
     */
    'dataParameters'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateApiFieldsDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateAppointmentDto
 */
export interface UpdateAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'dateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'providerClientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'bookingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'transactionFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'paymentMethod'?: UpdateAppointmentDtoPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'paymentOption'?: UpdateAppointmentDtoPaymentOptionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'paymentStatus'?: UpdateAppointmentDtoPaymentStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAppointmentDto
     */
    'serviceCategoryIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAppointmentDto
     */
    'providerServiceIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'providerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'seniorCitizenId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'pwdId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'hmoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'hmoNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'philhealthId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'philhealthKonsultaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'paymentReferenceNumber'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateAppointmentDto
     */
    'uploads'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'appointmentNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'reasonForCancellation'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'clientProfileId'?: number;
    /**
     * 
     * @type {CreateProviderClientProfileDto}
     * @memberof UpdateAppointmentDto
     */
    'providerClientProfile'?: CreateProviderClientProfileDto | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAppointmentDto
     */
    'tagIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentDto
     */
    'isCreatedByProvider'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateAppointmentDto
     */
    'hmoSelected'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'hmoAccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'hmoLetterOfAuthorizationUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'hmoCardUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'hmoValidIdUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'otherConcern'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'totalDownPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'reservationFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'reservationPolicyNotes'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentDto
     */
    'nonRefundable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentDto
     */
    'deductible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentDto
     */
    'nonRefundableClientNoShowCancellation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'totalAmountDue'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAppointmentDto
     */
    'totalAmountDueInPerson'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'totalAmountDueNow'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateAppointmentDto
     */
    'discounts'?: Array<object>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAppointmentDto
     */
    'savedAmountFromDiscount'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDto
     */
    'diagnosticFileId'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateAppointmentDto
     */
    'customFields'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'platform'?: UpdateAppointmentDtoPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'referenceId'?: string;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof UpdateAppointmentDto
     */
    'address'?: CreateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'proofOfPaymentUrl'?: string;
}

export const UpdateAppointmentDtoPaymentMethodEnum = {
    Later: 'pay-later',
    Now: 'pay-now',
    NowFull: 'pay-now-full'
} as const;

export type UpdateAppointmentDtoPaymentMethodEnum = typeof UpdateAppointmentDtoPaymentMethodEnum[keyof typeof UpdateAppointmentDtoPaymentMethodEnum];
export const UpdateAppointmentDtoPaymentOptionEnum = {
    Cash: 'cash',
    DebitCreditEwallet: 'debit/credit/ewallet',
    Hmo: 'hmo',
    Philhealth: 'philhealth',
    Qrph: 'qrph'
} as const;

export type UpdateAppointmentDtoPaymentOptionEnum = typeof UpdateAppointmentDtoPaymentOptionEnum[keyof typeof UpdateAppointmentDtoPaymentOptionEnum];
export const UpdateAppointmentDtoPaymentStatusEnum = {
    Paid: 'paid',
    NotPaid: 'not-paid'
} as const;

export type UpdateAppointmentDtoPaymentStatusEnum = typeof UpdateAppointmentDtoPaymentStatusEnum[keyof typeof UpdateAppointmentDtoPaymentStatusEnum];
export const UpdateAppointmentDtoPlatformEnum = {
    Web: 'web',
    MwellApp: 'mwell-app',
    WebMphReferral: 'web-mph-referral',
    Partner: 'partner'
} as const;

export type UpdateAppointmentDtoPlatformEnum = typeof UpdateAppointmentDtoPlatformEnum[keyof typeof UpdateAppointmentDtoPlatformEnum];

/**
 * 
 * @export
 * @interface UpdateAppointmentService
 */
export interface UpdateAppointmentService {
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentService
     */
    'appointmentId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentService
     */
    'providerServiceId': number;
}
/**
 * 
 * @export
 * @interface UpdateAppointmentTagsDto
 */
export interface UpdateAppointmentTagsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAppointmentTagsDto
     */
    'tagIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateBannerDto
 */
export interface UpdateBannerDto {
    /**
     * The ID of the provider who created the banner
     * @type {number}
     * @memberof UpdateBannerDto
     */
    'providerCreatorId'?: number;
    /**
     * The ID of the operator who created the banner
     * @type {number}
     * @memberof UpdateBannerDto
     */
    'operatorCreatorId'?: number;
    /**
     * The title of the banner
     * @type {string}
     * @memberof UpdateBannerDto
     */
    'title'?: string;
    /**
     * The URL of the banner photo
     * @type {string}
     * @memberof UpdateBannerDto
     */
    'photoUrl'?: string;
    /**
     * The URL that the banner will redirect to
     * @type {string}
     * @memberof UpdateBannerDto
     */
    'redirectUrl'?: string;
    /**
     * The display order of the banner
     * @type {number}
     * @memberof UpdateBannerDto
     */
    'order'?: number;
    /**
     * The ID of the banner
     * @type {string}
     * @memberof UpdateBannerDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UpdateBranchServiceAvailabilityDto
 */
export interface UpdateBranchServiceAvailabilityDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBranchServiceAvailabilityDto
     */
    'branchId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBranchServiceAvailabilityDto
     */
    'availability': UpdateBranchServiceAvailabilityDtoAvailabilityEnum;
}

export const UpdateBranchServiceAvailabilityDtoAvailabilityEnum = {
    Available: 'Available',
    Unavailable: 'Unavailable',
    AvailableInSelectedBranches: 'Available in selected Branches'
} as const;

export type UpdateBranchServiceAvailabilityDtoAvailabilityEnum = typeof UpdateBranchServiceAvailabilityDtoAvailabilityEnum[keyof typeof UpdateBranchServiceAvailabilityDtoAvailabilityEnum];

/**
 * 
 * @export
 * @interface UpdateCategoryDto
 */
export interface UpdateCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDto
     */
    'productType'?: UpdateCategoryDtoProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDto
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCategoryDto
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDto
     */
    'defaultOrderStatus'?: UpdateCategoryDtoDefaultOrderStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCategoryDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDto
     */
    'id'?: string;
}

export const UpdateCategoryDtoProductTypeEnum = {
    Digital: 'DIGITAL',
    Emergency: 'EMERGENCY',
    Physical: 'PHYSICAL'
} as const;

export type UpdateCategoryDtoProductTypeEnum = typeof UpdateCategoryDtoProductTypeEnum[keyof typeof UpdateCategoryDtoProductTypeEnum];
export const UpdateCategoryDtoDefaultOrderStatusEnum = {
    Pending: 'pending',
    Completed: 'completed',
    Cancelled: 'cancelled',
    Processing: 'processing',
    Shipped: 'shipped',
    Confirmed: 'confirmed'
} as const;

export type UpdateCategoryDtoDefaultOrderStatusEnum = typeof UpdateCategoryDtoDefaultOrderStatusEnum[keyof typeof UpdateCategoryDtoDefaultOrderStatusEnum];

/**
 * 
 * @export
 * @interface UpdateClientProfileDto
 */
export interface UpdateClientProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'displayPhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientProfileDto
     */
    'clientProfileType'?: UpdateClientProfileDtoClientProfileTypeEnum;
}

export const UpdateClientProfileDtoClientProfileTypeEnum = {
    Myself: 'Myself',
    MyChild: 'My Child',
    MyParent: 'My Parent',
    SomeoneElse: 'Someone Else'
} as const;

export type UpdateClientProfileDtoClientProfileTypeEnum = typeof UpdateClientProfileDtoClientProfileTypeEnum[keyof typeof UpdateClientProfileDtoClientProfileTypeEnum];

/**
 * 
 * @export
 * @interface UpdateDiscountDto
 */
export interface UpdateDiscountDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'method'?: UpdateDiscountDtoMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateDiscountDto
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'status'?: UpdateDiscountDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'startsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'endsAt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDiscountDto
     */
    'activeDays'?: Array<UpdateDiscountDtoActiveDaysEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'activeHoursStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'activeHoursEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    'categoryId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDiscountDto
     */
    'branchIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDiscountDto
     */
    'serviceIds'?: Array<number>;
    /**
     * 
     * @type {object}
     * @memberof UpdateDiscountDto
     */
    'metadata'?: object;
    /**
     * 
     * @type {number}
     * @memberof UpdateDiscountDto
     */
    'id': number;
}

export const UpdateDiscountDtoMethodEnum = {
    FixedPrice: 'FIXED_PRICE',
    PercentOffPrice: 'PERCENT_OFF_PRICE',
    PesoOffPrice: 'PESO_OFF_PRICE'
} as const;

export type UpdateDiscountDtoMethodEnum = typeof UpdateDiscountDtoMethodEnum[keyof typeof UpdateDiscountDtoMethodEnum];
export const UpdateDiscountDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type UpdateDiscountDtoStatusEnum = typeof UpdateDiscountDtoStatusEnum[keyof typeof UpdateDiscountDtoStatusEnum];
export const UpdateDiscountDtoActiveDaysEnum = {
    Sunday: 'SUNDAY',
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY'
} as const;

export type UpdateDiscountDtoActiveDaysEnum = typeof UpdateDiscountDtoActiveDaysEnum[keyof typeof UpdateDiscountDtoActiveDaysEnum];

/**
 * 
 * @export
 * @interface UpdateEntityTypeDto
 */
export interface UpdateEntityTypeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityTypeDto
     */
    'type'?: UpdateEntityTypeDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityTypeDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEntityTypeDto
     */
    'providerTypeId'?: number;
}

export const UpdateEntityTypeDtoTypeEnum = {
    Provider: 'Provider',
    Category: 'Category'
} as const;

export type UpdateEntityTypeDtoTypeEnum = typeof UpdateEntityTypeDtoTypeEnum[keyof typeof UpdateEntityTypeDtoTypeEnum];

/**
 * 
 * @export
 * @interface UpdateFamilyHistoryRecordDto
 */
export interface UpdateFamilyHistoryRecordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFamilyHistoryRecordDto
     */
    'relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFamilyHistoryRecordDto
     */
    'condition_illness'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFamilyHistoryRecordDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface UpdateInPersonPaymentDto
 */
export interface UpdateInPersonPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInPersonPaymentDto
     */
    'transactionNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInPersonPaymentDto
     */
    'paymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInPersonPaymentDto
     */
    'paymentMethod': string;
}
/**
 * 
 * @export
 * @interface UpdateMassAppointmentServiceDto
 */
export interface UpdateMassAppointmentServiceDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateMassAppointmentServiceDto
     */
    'appointmentId'?: number;
    /**
     * 
     * @type {Array<UpdateAppointmentService>}
     * @memberof UpdateMassAppointmentServiceDto
     */
    'appointmentServiceItems'?: Array<UpdateAppointmentService>;
}
/**
 * 
 * @export
 * @interface UpdateMedicalDocumentDto
 */
export interface UpdateMedicalDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalDocumentDto
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalDocumentDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalDocumentDto
     */
    'tags'?: UpdateMedicalDocumentDtoTagsEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalDocumentDto
     */
    'fileId': string;
}

export const UpdateMedicalDocumentDtoTagsEnum = {
    Prescription: 'Prescription',
    Ultrasound: 'Ultrasound',
    XRay: 'X-Ray',
    LaboratoryResults: 'Laboratory Results'
} as const;

export type UpdateMedicalDocumentDtoTagsEnum = typeof UpdateMedicalDocumentDtoTagsEnum[keyof typeof UpdateMedicalDocumentDtoTagsEnum];

/**
 * 
 * @export
 * @interface UpdateMedicalHistoryRecordDto
 */
export interface UpdateMedicalHistoryRecordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalHistoryRecordDto
     */
    'condition_illness'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalHistoryRecordDto
     */
    'condition_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalHistoryRecordDto
     */
    'hospital'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalHistoryRecordDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMedicationRecordDto
 */
export interface UpdateMedicationRecordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicationRecordDto
     */
    'generic_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicationRecordDto
     */
    'brand_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicationRecordDto
     */
    'strength_dosage_form'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicationRecordDto
     */
    'signa'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMedicationRecordDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicationRecordDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicationRecordDto
     */
    'date_prescribed'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMerchantDto
 */
export interface UpdateMerchantDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantDto
     */
    'email'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateMerchantDto
     */
    'tagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantDto
     */
    'cwtRate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMerchantDto
     */
    'shippingFee'?: number;
}
/**
 * 
 * @export
 * @interface UpdateOnboardingDocumentsDto
 */
export interface UpdateOnboardingDocumentsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOnboardingDocumentsDto
     */
    'birCertificateFileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOnboardingDocumentsDto
     */
    'dtiCertificateFileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOnboardingDocumentsDto
     */
    'mayorsPermitFileId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOnboardingDocumentsDto
     */
    'hasAgreedToNDAMerchantAgreement': boolean;
}
/**
 * 
 * @export
 * @interface UpdateProductDto
 */
export interface UpdateProductDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'productId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'stock'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'maxOrderQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'canBeGifted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'merchantEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'vat'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'sfRate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductDto
     */
    'tagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'cwtRate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'transactionFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'shippingFee'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'isFreeShipping'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'displayFullGrid'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductDto
     */
    'variantAttributes'?: Array<string>;
    /**
     * 
     * @type {Array<CreateVariantDto>}
     * @memberof UpdateProductDto
     */
    'variants'?: Array<CreateVariantDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'status'?: UpdateProductDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'deletedAt'?: string;
}

export const UpdateProductDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Pending: 'PENDING',
    Rejected: 'REJECTED'
} as const;

export type UpdateProductDtoStatusEnum = typeof UpdateProductDtoStatusEnum[keyof typeof UpdateProductDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateProductsDto
 */
export interface UpdateProductsDto {
    /**
     * 
     * @type {Array<UpdateProductDto>}
     * @memberof UpdateProductsDto
     */
    'products': Array<UpdateProductDto>;
}
/**
 * 
 * @export
 * @interface UpdatePromotionDto
 */
export interface UpdatePromotionDto {
    /**
     * 
     * @type {number}
     * @memberof UpdatePromotionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePromotionDto
     */
    'isPublished'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatePromotionDto
     */
    'providerIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateProviderBranchDto
 */
export interface UpdateProviderBranchDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'officeHours': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'paymentQrCodeUrl'?: string;
    /**
     * 
     * @type {UpdateAddressDto}
     * @memberof UpdateProviderBranchDto
     */
    'address'?: UpdateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderBranchDto
     */
    'parentProviderId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProviderBranchDto
     */
    'paymentMethod'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProviderBranchDto
     */
    'acceptedHmos'?: Array<string>;
    /**
     * 
     * @type {Array<CreateRepresentativeDto>}
     * @memberof UpdateProviderBranchDto
     */
    'representatives'?: Array<CreateRepresentativeDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderBranchDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderBranchDto
     */
    'status'?: UpdateProviderBranchDtoStatusEnum;
    /**
     * 
     * @type {Array<UpdateUserProviderDto>}
     * @memberof UpdateProviderBranchDto
     */
    'userProviders'?: Array<UpdateUserProviderDto>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateProviderBranchDto
     */
    'providerTypeIds': Array<number>;
}

export const UpdateProviderBranchDtoStatusEnum = {
    Published: 'PUBLISHED',
    Unpublished: 'UNPUBLISHED',
    Inactive: 'INACTIVE',
    Active: 'ACTIVE'
} as const;

export type UpdateProviderBranchDtoStatusEnum = typeof UpdateProviderBranchDtoStatusEnum[keyof typeof UpdateProviderBranchDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateProviderClientNotesDto
 */
export interface UpdateProviderClientNotesDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderClientNotesDto
     */
    'providerClientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderClientNotesDto
     */
    'appointmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderClientNotesDto
     */
    'providerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientNotesDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientNotesDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderClientNotesDto
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UpdateProviderClientProfileDto
 */
export interface UpdateProviderClientProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderClientProfileDto
     */
    'clientId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderClientProfileDto
     */
    'clientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderClientProfileDto
     */
    'hmoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'philhealthKonsultaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'hmoNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'reasonForVisit'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderClientProfileDto
     */
    'clientProfileType'?: UpdateProviderClientProfileDtoClientProfileTypeEnum;
}

export const UpdateProviderClientProfileDtoClientProfileTypeEnum = {
    Myself: 'Myself',
    MyChild: 'My Child',
    MyParent: 'My Parent',
    SomeoneElse: 'Someone Else'
} as const;

export type UpdateProviderClientProfileDtoClientProfileTypeEnum = typeof UpdateProviderClientProfileDtoClientProfileTypeEnum[keyof typeof UpdateProviderClientProfileDtoClientProfileTypeEnum];

/**
 * 
 * @export
 * @interface UpdateProviderCustomFieldsDto
 */
export interface UpdateProviderCustomFieldsDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateProviderCustomFieldsDto
     */
    'customFields': Array<object>;
}
/**
 * 
 * @export
 * @interface UpdateProviderDirectoryDto
 */
export interface UpdateProviderDirectoryDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDirectoryDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateProviderDirectoryDto
     */
    'providerTypeIds': Array<number>;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof UpdateProviderDirectoryDto
     */
    'address'?: CreateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDirectoryDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDirectoryDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDirectoryDto
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDirectoryDto
     */
    'officeHours': string;
}
/**
 * 
 * @export
 * @interface UpdateProviderDto
 */
export interface UpdateProviderDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'website'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderDto
     */
    'providerTypeId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateProviderDto
     */
    'providerTypeIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'paymentQrCodeUrl'?: string;
    /**
     * 
     * @type {UpdateAddressDto}
     * @memberof UpdateProviderDto
     */
    'address'?: UpdateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderDto
     */
    'parentProviderId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProviderDto
     */
    'paymentMethod'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateProviderDto
     */
    'acceptedHmos'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {Array<CreateRepresentativeDto>}
     * @memberof UpdateProviderDto
     */
    'representatives'?: Array<CreateRepresentativeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderDto
     */
    'isAutoApproveAppointmentEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderDto
     */
    'isMphProvider'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'status'?: UpdateProviderDtoStatusEnum;
    /**
     * 
     * @type {Array<UpdateUserProviderDto>}
     * @memberof UpdateProviderDto
     */
    'userProviders'?: Array<UpdateUserProviderDto>;
}

export const UpdateProviderDtoStatusEnum = {
    Published: 'PUBLISHED',
    Unpublished: 'UNPUBLISHED',
    Inactive: 'INACTIVE',
    Active: 'ACTIVE'
} as const;

export type UpdateProviderDtoStatusEnum = typeof UpdateProviderDtoStatusEnum[keyof typeof UpdateProviderDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateProviderServiceDto
 */
export interface UpdateProviderServiceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'availability'?: UpdateProviderServiceDtoAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'procedurePreparation'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceDto
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'specialNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'freePriceLabel'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceDto
     */
    'providerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceDto
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'priceType'?: UpdateProviderServiceDtoPriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceDto
     */
    'serviceCategoryId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceDto
     */
    'isFeatured'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateProviderServiceDto
     */
    'providerServiceBranches'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceDto
     */
    'parentProviderServiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'bookingPaymentRequirement'?: UpdateProviderServiceDtoBookingPaymentRequirementEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceDto
     */
    'downPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceDto
     */
    'updateType': UpdateProviderServiceDtoUpdateTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateProviderServiceDto
     */
    'providerServiceBranchesToOverride'?: Array<number>;
}

export const UpdateProviderServiceDtoAvailabilityEnum = {
    Available: 'Available',
    Unavailable: 'Unavailable',
    AvailableInSelectedBranches: 'Available in selected Branches'
} as const;

export type UpdateProviderServiceDtoAvailabilityEnum = typeof UpdateProviderServiceDtoAvailabilityEnum[keyof typeof UpdateProviderServiceDtoAvailabilityEnum];
export const UpdateProviderServiceDtoPriceTypeEnum = {
    Free: 'free',
    Range: 'range',
    Fixed: 'fixed',
    Hide: 'hide',
    StartsAt: 'startsAt'
} as const;

export type UpdateProviderServiceDtoPriceTypeEnum = typeof UpdateProviderServiceDtoPriceTypeEnum[keyof typeof UpdateProviderServiceDtoPriceTypeEnum];
export const UpdateProviderServiceDtoBookingPaymentRequirementEnum = {
    FullPayment: 'fullPayment',
    DownPayment: 'downPayment',
    None: 'none'
} as const;

export type UpdateProviderServiceDtoBookingPaymentRequirementEnum = typeof UpdateProviderServiceDtoBookingPaymentRequirementEnum[keyof typeof UpdateProviderServiceDtoBookingPaymentRequirementEnum];
export const UpdateProviderServiceDtoUpdateTypeEnum = {
    All: 'All',
    OverrideSelectedBranches: 'Override Selected Branches'
} as const;

export type UpdateProviderServiceDtoUpdateTypeEnum = typeof UpdateProviderServiceDtoUpdateTypeEnum[keyof typeof UpdateProviderServiceDtoUpdateTypeEnum];

/**
 * 
 * @export
 * @interface UpdateProviderServiceSettingsDto
 */
export interface UpdateProviderServiceSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'providerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'requireReservationFee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'reservationFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'reservationPolicyNotes'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'nonRefundable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'deductible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'nonRefundableClientNoShowCancellation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'newClientsOnly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'selectServicesOnly'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateProviderServiceSettingsDto
     */
    'selectedServiceIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateProviderSettingsDto
 */
export interface UpdateProviderSettingsDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateProviderSettingsDto
     */
    'acceptedHmos'?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderSettingsDto
     */
    'isAutoApproveAppointmentEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderSettingsDto
     */
    'isMphProvider'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderSettingsDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderSettingsDto
     */
    'mobilePhoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProviderTagCategoryDto
 */
export interface UpdateProviderTagCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderTagCategoryDto
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderTagCategoryDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProviderTagCategoryDto
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderTagCategoryDto
     */
    'providerTagCategoryId': string;
}
/**
 * 
 * @export
 * @interface UpdateReferralDto
 */
export interface UpdateReferralDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralDto
     */
    'providerClientProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralDto
     */
    'clientProfileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'reasonForReferral'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralDto
     */
    'appointmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferralDto
     */
    'dateTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateReferralDto
     */
    'serviceCategoryIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateReferralDto
     */
    'providerServiceIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralDto
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {Array<CreateReferralFileDto>}
     * @memberof UpdateReferralDto
     */
    'attachments'?: Array<CreateReferralFileDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralDto
     */
    'referredProviderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralDto
     */
    'providerId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateRepresentativeDto
 */
export interface UpdateRepresentativeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'phoneCountryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'mobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepresentativeDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface UpdateScheduleDto
 */
export interface UpdateScheduleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleDto
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleDto
     */
    'day': UpdateScheduleDtoDayEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduleDto
     */
    'slots'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduleDto
     */
    'bookedSlots'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleDto
     */
    'isBlocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleDto
     */
    'providerId'?: string;
}

export const UpdateScheduleDtoDayEnum = {
    Sunday: 'sunday',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday'
} as const;

export type UpdateScheduleDtoDayEnum = typeof UpdateScheduleDtoDayEnum[keyof typeof UpdateScheduleDtoDayEnum];

/**
 * 
 * @export
 * @interface UpdateServiceScheduleDto
 */
export interface UpdateServiceScheduleDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceScheduleDto
     */
    'timeSlotIntervalInMinutes'?: number;
    /**
     * 
     * @type {Array<TimeSlot>}
     * @memberof UpdateServiceScheduleDto
     */
    'timeSlots'?: Array<TimeSlot>;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceScheduleDto
     */
    'slots'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateServiceScheduleDto
     */
    'branchIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceScheduleDto
     */
    'brandId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceScheduleDto
     */
    'branchSelectionType'?: UpdateServiceScheduleDtoBranchSelectionTypeEnum;
}

export const UpdateServiceScheduleDtoBranchSelectionTypeEnum = {
    All: 'ALL',
    Specific: 'SPECIFIC'
} as const;

export type UpdateServiceScheduleDtoBranchSelectionTypeEnum = typeof UpdateServiceScheduleDtoBranchSelectionTypeEnum[keyof typeof UpdateServiceScheduleDtoBranchSelectionTypeEnum];

/**
 * 
 * @export
 * @interface UpdateSocialHistoryRecordDto
 */
export interface UpdateSocialHistoryRecordDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'is_smoker'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'years'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'pack_years'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'sticks_per_day'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'is_alcohol'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'alcohol'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'is_drug'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'drug'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'diet'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'physical_activity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialHistoryRecordDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSurgicalHistoryRecordDto
 */
export interface UpdateSurgicalHistoryRecordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSurgicalHistoryRecordDto
     */
    'operation_procedure'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurgicalHistoryRecordDto
     */
    'operation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurgicalHistoryRecordDto
     */
    'indication'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurgicalHistoryRecordDto
     */
    'hospital'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurgicalHistoryRecordDto
     */
    'remarks'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateTagDto
 */
export interface UpdateTagDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagDto
     */
    'tagType'?: UpdateTagDtoTagTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagDto
     */
    'displayType'?: UpdateTagDtoDisplayTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagDto
     */
    'displayButtonText'?: string;
}

export const UpdateTagDtoTagTypeEnum = {
    Merchant: 'MERCHANT',
    Product: 'PRODUCT'
} as const;

export type UpdateTagDtoTagTypeEnum = typeof UpdateTagDtoTagTypeEnum[keyof typeof UpdateTagDtoTagTypeEnum];
export const UpdateTagDtoDisplayTypeEnum = {
    Grid: 'GRID',
    Horizontal: 'HORIZONTAL',
    Vertical: 'VERTICAL'
} as const;

export type UpdateTagDtoDisplayTypeEnum = typeof UpdateTagDtoDisplayTypeEnum[keyof typeof UpdateTagDtoDisplayTypeEnum];

/**
 * 
 * @export
 * @interface UpdateTransactionMerchantDto
 */
export interface UpdateTransactionMerchantDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionMerchantDto
     */
    'status'?: UpdateTransactionMerchantDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionMerchantDto
     */
    'paymentStatus'?: UpdateTransactionMerchantDtoPaymentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateTransactionMerchantDto
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionMerchantDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionMerchantDto
     */
    'paymentReferenceNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTransactionMerchantDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionMerchantDto
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionMerchantDto
     */
    'deliveryLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionMerchantDto
     */
    'trackingNumber'?: string;
}

export const UpdateTransactionMerchantDtoStatusEnum = {
    Pending: 'pending',
    Completed: 'completed',
    Cancelled: 'cancelled',
    Processing: 'processing',
    Shipped: 'shipped',
    Confirmed: 'confirmed'
} as const;

export type UpdateTransactionMerchantDtoStatusEnum = typeof UpdateTransactionMerchantDtoStatusEnum[keyof typeof UpdateTransactionMerchantDtoStatusEnum];
export const UpdateTransactionMerchantDtoPaymentStatusEnum = {
    Paid: 'paid',
    NotPaid: 'not-paid'
} as const;

export type UpdateTransactionMerchantDtoPaymentStatusEnum = typeof UpdateTransactionMerchantDtoPaymentStatusEnum[keyof typeof UpdateTransactionMerchantDtoPaymentStatusEnum];

/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'displayPhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'mWellUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'userType'?: UpdateUserDtoUserTypeEnum;
}

export const UpdateUserDtoUserTypeEnum = {
    Admin: 'ADMIN',
    Finance: 'FINANCE',
    Marketing: 'MARKETING',
    Client: 'CLIENT',
    Patient: 'PATIENT',
    ProviderAdmin: 'PROVIDER_ADMIN',
    SuperAdmin: 'SUPER_ADMIN'
} as const;

export type UpdateUserDtoUserTypeEnum = typeof UpdateUserDtoUserTypeEnum[keyof typeof UpdateUserDtoUserTypeEnum];

/**
 * 
 * @export
 * @interface UpdateUserOperationDto
 */
export interface UpdateUserOperationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'userType'?: UpdateUserOperationDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOperationDto
     */
    'userAppType'?: UpdateUserOperationDtoUserAppTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserOperationDto
     */
    'roleIds'?: Array<number>;
}

export const UpdateUserOperationDtoUserTypeEnum = {
    Admin: 'ADMIN',
    Finance: 'FINANCE',
    Marketing: 'MARKETING',
    Merchant: 'MERCHANT'
} as const;

export type UpdateUserOperationDtoUserTypeEnum = typeof UpdateUserOperationDtoUserTypeEnum[keyof typeof UpdateUserOperationDtoUserTypeEnum];
export const UpdateUserOperationDtoUserAppTypeEnum = {
    HealthHub: 'HEALTH_HUB',
    Eshop: 'ESHOP'
} as const;

export type UpdateUserOperationDtoUserAppTypeEnum = typeof UpdateUserOperationDtoUserAppTypeEnum[keyof typeof UpdateUserOperationDtoUserAppTypeEnum];

/**
 * 
 * @export
 * @interface UpdateUserProviderDto
 */
export interface UpdateUserProviderDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'userType'?: UpdateUserProviderDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserProviderDto
     */
    'branchIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserProviderDto
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'userAppType'?: UpdateUserProviderDtoUserAppTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProviderDto
     */
    'fcmToken'?: string;
}

export const UpdateUserProviderDtoUserTypeEnum = {
    BranchAdmin: 'BRANCH_ADMIN',
    BrandAdmin: 'BRAND_ADMIN',
    EshopMerchant: 'ESHOP_MERCHANT',
    Specialist: 'SPECIALIST',
    Staff: 'STAFF',
    SuperAdmin: 'SUPER_ADMIN',
    ProviderAdmin: 'PROVIDER_ADMIN'
} as const;

export type UpdateUserProviderDtoUserTypeEnum = typeof UpdateUserProviderDtoUserTypeEnum[keyof typeof UpdateUserProviderDtoUserTypeEnum];
export const UpdateUserProviderDtoUserAppTypeEnum = {
    HealthHub: 'HEALTH_HUB',
    Eshop: 'ESHOP'
} as const;

export type UpdateUserProviderDtoUserAppTypeEnum = typeof UpdateUserProviderDtoUserAppTypeEnum[keyof typeof UpdateUserProviderDtoUserAppTypeEnum];

/**
 * 
 * @export
 * @interface UpdateVaccinationRecordDto
 */
export interface UpdateVaccinationRecordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'vaccine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'brand_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'administered_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'administered_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'series'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'lot_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'dose_mL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'site'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'route'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'facility'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVaccinationRecordDto
     */
    'next_dose_on'?: string;
}
/**
 * 
 * @export
 * @interface UpdateVitalSignRecordDto
 */
export interface UpdateVitalSignRecordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateVitalSignRecordDto
     */
    'measurement_datetime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'temperature_c'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'temperature_f'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'systolic_blood_pressure'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'diastolic_blood_pressure'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'pulse_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'respiratory_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'pain'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'oxygen_saturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'capillary_blood_glucose_mgdl'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVitalSignRecordDto
     */
    'capillary_blood_glucose_mmoll'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateVitalSignRecordDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface UploadDisplayPhotoDto
 */
export interface UploadDisplayPhotoDto {
    /**
     * 
     * @type {string}
     * @memberof UploadDisplayPhotoDto
     */
    'displayPhotoUrl'?: string;
}
/**
 * 
 * @export
 * @interface UrinalysisDto
 */
export interface UrinalysisDto {
    /**
     * 
     * @type {number}
     * @memberof UrinalysisDto
     */
    'phLevel': number;
    /**
     * 
     * @type {number}
     * @memberof UrinalysisDto
     */
    'specificGravity': number;
    /**
     * 
     * @type {string}
     * @memberof UrinalysisDto
     */
    'urobilinogenLevel': string;
    /**
     * 
     * @type {string}
     * @memberof UrinalysisDto
     */
    'vitaminCLevel': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'suffix': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'salt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'previousPasswords': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'loginAttempts': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLoginAttempt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userType'?: UserUserTypeEnum;
    /**
     * 
     * @type {UserVerificationCode}
     * @memberof User
     */
    'userVerificationCode': UserVerificationCode;
    /**
     * 
     * @type {Array<Provider>}
     * @memberof User
     */
    'providers'?: Array<Provider>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mWellUserId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'displayPhotoUrl': string;
    /**
     * 
     * @type {Array<ClientProfile>}
     * @memberof User
     */
    'clientProfiles'?: Array<ClientProfile>;
    /**
     * 
     * @type {ClientProfile}
     * @memberof User
     */
    'mainProfile': ClientProfile;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof User
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {Array<MedicalRecordFile>}
     * @memberof User
     */
    'medicalRecordFiles'?: Array<MedicalRecordFile>;
    /**
     * 
     * @type {ClientFavourite}
     * @memberof User
     */
    'clientFavourite'?: ClientFavourite;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedAt'?: string;
}

export const UserUserTypeEnum = {
    Admin: 'ADMIN',
    Finance: 'FINANCE',
    Marketing: 'MARKETING',
    Client: 'CLIENT',
    Patient: 'PATIENT',
    ProviderAdmin: 'PROVIDER_ADMIN',
    SuperAdmin: 'SUPER_ADMIN'
} as const;

export type UserUserTypeEnum = typeof UserUserTypeEnum[keyof typeof UserUserTypeEnum];

/**
 * 
 * @export
 * @interface UserFile
 */
export interface UserFile {
    /**
     * 
     * @type {number}
     * @memberof UserFile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'type': UserFileTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof UserFile
     */
    'file': any;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'deletedAt'?: string;
}

export const UserFileTypeEnum = {
    LaboratoryRequests: 'LABORATORY_REQUESTS',
    DiagnosticResults: 'DIAGNOSTIC_RESULTS'
} as const;

export type UserFileTypeEnum = typeof UserFileTypeEnum[keyof typeof UserFileTypeEnum];

/**
 * 
 * @export
 * @interface UserOperation
 */
export interface UserOperation {
    /**
     * 
     * @type {number}
     * @memberof UserOperation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'authUid': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'salt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserOperation
     */
    'previousPasswords': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserOperation
     */
    'loginAttempts': number;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'lastLoginAttempt': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'userType'?: UserOperationUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'userAppType'?: UserOperationUserAppTypeEnum;
    /**
     * 
     * @type {UserVerificationCode}
     * @memberof UserOperation
     */
    'userVerificationCode': UserVerificationCode;
    /**
     * 
     * @type {Array<AuthRefreshToken>}
     * @memberof UserOperation
     */
    'authRefreshTokens': Array<AuthRefreshToken>;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof UserOperation
     */
    'bannersCreated': Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserOperation
     */
    'deletedAt'?: string;
}

export const UserOperationUserTypeEnum = {
    Admin: 'ADMIN',
    Finance: 'FINANCE',
    Marketing: 'MARKETING',
    Merchant: 'MERCHANT'
} as const;

export type UserOperationUserTypeEnum = typeof UserOperationUserTypeEnum[keyof typeof UserOperationUserTypeEnum];
export const UserOperationUserAppTypeEnum = {
    HealthHub: 'HEALTH_HUB',
    Eshop: 'ESHOP'
} as const;

export type UserOperationUserAppTypeEnum = typeof UserOperationUserAppTypeEnum[keyof typeof UserOperationUserAppTypeEnum];

/**
 * 
 * @export
 * @interface UserProvider
 */
export interface UserProvider {
    /**
     * 
     * @type {number}
     * @memberof UserProvider
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'authUid': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'salt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProvider
     */
    'previousPasswords': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserProvider
     */
    'loginAttempts': number;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'lastLoginAttempt': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'userType'?: UserProviderUserTypeEnum;
    /**
     * 
     * @type {UserVerificationCode}
     * @memberof UserProvider
     */
    'userVerificationCode': UserVerificationCode;
    /**
     * 
     * @type {Provider}
     * @memberof UserProvider
     */
    'provider': Provider;
    /**
     * 
     * @type {Array<Provider>}
     * @memberof UserProvider
     */
    'assignedBranches'?: Array<Provider>;
    /**
     * 
     * @type {Array<ProviderClientNotes>}
     * @memberof UserProvider
     */
    'providerClientNotes'?: Array<ProviderClientNotes>;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'fcmToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'userAppType'?: UserProviderUserAppTypeEnum;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof UserProvider
     */
    'bannersCreated': Array<Banner>;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof UserProvider
     */
    'notifications': Array<Notification>;
    /**
     * 
     * @type {Array<ApiKey>}
     * @memberof UserProvider
     */
    'apiKeys': Array<ApiKey>;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserProvider
     */
    'deletedAt'?: string;
}

export const UserProviderUserTypeEnum = {
    BranchAdmin: 'BRANCH_ADMIN',
    BrandAdmin: 'BRAND_ADMIN',
    EshopMerchant: 'ESHOP_MERCHANT',
    Specialist: 'SPECIALIST',
    Staff: 'STAFF',
    SuperAdmin: 'SUPER_ADMIN',
    ProviderAdmin: 'PROVIDER_ADMIN'
} as const;

export type UserProviderUserTypeEnum = typeof UserProviderUserTypeEnum[keyof typeof UserProviderUserTypeEnum];
export const UserProviderUserAppTypeEnum = {
    HealthHub: 'HEALTH_HUB',
    Eshop: 'ESHOP'
} as const;

export type UserProviderUserAppTypeEnum = typeof UserProviderUserAppTypeEnum[keyof typeof UserProviderUserAppTypeEnum];

/**
 * 
 * @export
 * @interface UserRequestSmsVerificationDto
 */
export interface UserRequestSmsVerificationDto {
    /**
     * 
     * @type {string}
     * @memberof UserRequestSmsVerificationDto
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestSmsVerificationDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserSmsVerificationDto
 */
export interface UserSmsVerificationDto {
    /**
     * 
     * @type {string}
     * @memberof UserSmsVerificationDto
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UserSmsVerificationDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface UserVerificationCode
 */
export interface UserVerificationCode {
    /**
     * 
     * @type {number}
     * @memberof UserVerificationCode
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'mobilePhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'expirationDate': string;
    /**
     * 
     * @type {User}
     * @memberof UserVerificationCode
     */
    'user': User;
    /**
     * 
     * @type {UserOperation}
     * @memberof UserVerificationCode
     */
    'userOperation': UserOperation;
    /**
     * 
     * @type {UserProvider}
     * @memberof UserVerificationCode
     */
    'userProvider': UserProvider;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof UserVerificationCode
     */
    'verifyCount': number;
    /**
     * 
     * @type {number}
     * @memberof UserVerificationCode
     */
    'resendCount': number;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'cannotResendUntilDate': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'lockDate': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationCode
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface UserVerificationDto
 */
export interface UserVerificationDto {
    /**
     * 
     * @type {string}
     * @memberof UserVerificationDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface UserVerificationSendDto
 */
export interface UserVerificationSendDto {
    /**
     * 
     * @type {string}
     * @memberof UserVerificationSendDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ValidateEmailDto
 */
export interface ValidateEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateEmailDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ValidatePatientHmoDto
 */
export interface ValidatePatientHmoDto {
    /**
     * 
     * @type {string}
     * @memberof ValidatePatientHmoDto
     */
    'cardNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePatientHmoDto
     */
    'birthDate': string;
    /**
     * 
     * @type {number}
     * @memberof ValidatePatientHmoDto
     */
    'hmoId': number;
    /**
     * 
     * @type {string}
     * @memberof ValidatePatientHmoDto
     */
    'mwellUserId': string;
}
/**
 * 
 * @export
 * @interface ValueSetIdType
 */
export interface ValueSetIdType {
    /**
     * 
     * @type {number}
     * @memberof ValueSetIdType
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ValueSetIdType
     */
    'value_set_code': number;
    /**
     * 
     * @type {number}
     * @memberof ValueSetIdType
     */
    'value_id': number;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'short_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'display'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'parent_value_uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetIdType
     */
    'version'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValueSetIdType
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface VerifyOtpDto
 */
export interface VerifyOtpDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyOtpDto
     */
    'shouldRememberDevice'?: boolean;
}
/**
 * 
 * @export
 * @interface VerifyOtpNumberDto
 */
export interface VerifyOtpNumberDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpNumberDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpNumberDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpNumberDto
     */
    'phoneNumberPrefix': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpNumberDto
     */
    'otpNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpNumberDto
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface VerifyOtpNumberResponseDto
 */
export interface VerifyOtpNumberResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyOtpNumberResponseDto
     */
    'isExistingUser': boolean;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpNumberResponseDto
     */
    'refreshToken': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpNumberResponseDto
     */
    'token': string;
    /**
     * 
     * @type {SanitizedUserDto}
     * @memberof VerifyOtpNumberResponseDto
     */
    'user'?: SanitizedUserDto | null;
}
/**
 * 
 * @export
 * @interface VerifySsoOtpDto
 */
export interface VerifySsoOtpDto {
    /**
     * 
     * @type {string}
     * @memberof VerifySsoOtpDto
     */
    'otpCode': string;
    /**
     * 
     * @type {string}
     * @memberof VerifySsoOtpDto
     */
    'sessionId'?: string;
}
/**
 * 
 * @export
 * @interface VitalsDto
 */
export interface VitalsDto {
    /**
     * 
     * @type {string}
     * @memberof VitalsDto
     */
    'bloodPressure': string;
    /**
     * 
     * @type {number}
     * @memberof VitalsDto
     */
    'temperatureInCelsius': number;
    /**
     * 
     * @type {number}
     * @memberof VitalsDto
     */
    'respiratoryRatePerMin': number;
    /**
     * 
     * @type {number}
     * @memberof VitalsDto
     */
    'pulseRatePerMin': number;
    /**
     * 
     * @type {number}
     * @memberof VitalsDto
     */
    'oxygenSaturationPercent': number;
}
/**
 * 
 * @export
 * @interface WebhookRequestDto
 */
export interface WebhookRequestDto {
    /**
     * 
     * @type {string}
     * @memberof WebhookRequestDto
     */
    'data': string;
}

/**
 * AdminUsersApi - axios parameter creator
 * @export
 */
export const AdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerActivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerActivate', 'id', id)
            const localVarPath = `/api/v1/admin-users/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAdminUserDto} createAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerCreate: async (createAdminUserDto: CreateAdminUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdminUserDto' is not null or undefined
            assertParamExists('adminUserControllerCreate', 'createAdminUserDto', createAdminUserDto)
            const localVarPath = `/api/v1/admin-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerDeactivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerDeactivate', 'id', id)
            const localVarPath = `/api/v1/admin-users/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerDelete', 'id', id)
            const localVarPath = `/api/v1/admin-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerFindCurrentAdminUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin-users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerFindMany: async (query?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/admin-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdate: async (id: string, updateAdminUserDto: UpdateAdminUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerUpdate', 'id', id)
            // verify required parameter 'updateAdminUserDto' is not null or undefined
            assertParamExists('adminUserControllerUpdate', 'updateAdminUserDto', updateAdminUserDto)
            const localVarPath = `/api/v1/admin-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUsersApi - functional programming interface
 * @export
 */
export const AdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerActivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerActivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAdminUserDto} createAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerCreate(createAdminUserDto: CreateAdminUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerCreate(createAdminUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerDeactivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerDeactivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerFindCurrentAdminUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerFindCurrentAdminUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerFindMany(query?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerFindMany(query, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerUpdate(id: string, updateAdminUserDto: UpdateAdminUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerUpdate(id, updateAdminUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUsersApi - factory interface
 * @export
 */
export const AdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerActivate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminUserControllerActivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAdminUserDto} createAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerCreate(createAdminUserDto: CreateAdminUserDto, options?: any): AxiosPromise<UserOperation> {
            return localVarFp.adminUserControllerCreate(createAdminUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerDeactivate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminUserControllerDeactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminUserControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerFindCurrentAdminUser(options?: any): AxiosPromise<UserOperation> {
            return localVarFp.adminUserControllerFindCurrentAdminUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerFindMany(query?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminUserControllerFindMany(query, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerFindOne(id: string, options?: any): AxiosPromise<UserOperation> {
            return localVarFp.adminUserControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdate(id: string, updateAdminUserDto: UpdateAdminUserDto, options?: any): AxiosPromise<UserOperation> {
            return localVarFp.adminUserControllerUpdate(id, updateAdminUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUsersApi - object-oriented interface
 * @export
 * @class AdminUsersApi
 * @extends {BaseAPI}
 */
export class AdminUsersApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerActivate(id: string, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerActivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAdminUserDto} createAdminUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerCreate(createAdminUserDto: CreateAdminUserDto, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerCreate(createAdminUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerDeactivate(id: string, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerDeactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerDelete(id: string, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerFindCurrentAdminUser(options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerFindCurrentAdminUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerFindMany(query?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerFindMany(query, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAdminUserDto} updateAdminUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUserControllerUpdate(id: string, updateAdminUserDto: UpdateAdminUserDto, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUserControllerUpdate(id, updateAdminUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsControllerGetKeyMetrics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/analytics/key-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsControllerGetKeyMetrics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeyMetricsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerGetKeyMetrics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsControllerGetKeyMetrics(options?: any): AxiosPromise<KeyMetricsDto> {
            return localVarFp.analyticsControllerGetKeyMetrics(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public analyticsControllerGetKeyMetrics(options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).analyticsControllerGetKeyMetrics(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementApi - axios parameter creator
 * @export
 */
export const AnnouncementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAnnouncementDto} createAnnouncementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerCreate: async (createAnnouncementDto: CreateAnnouncementDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAnnouncementDto' is not null or undefined
            assertParamExists('announcementControllerCreate', 'createAnnouncementDto', createAnnouncementDto)
            const localVarPath = `/api/v1/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnnouncementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [target] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerFindAll: async (searchTerm?: string, startDate?: string, endDate?: string, target?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (target !== undefined) {
                localVarQueryParameter['target'] = target;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementControllerRemove', 'id', id)
            const localVarPath = `/api/v1/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAnnouncementDto} updateAnnouncementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerUpdate: async (id: string, updateAnnouncementDto: UpdateAnnouncementDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementControllerUpdate', 'id', id)
            // verify required parameter 'updateAnnouncementDto' is not null or undefined
            assertParamExists('announcementControllerUpdate', 'updateAnnouncementDto', updateAnnouncementDto)
            const localVarPath = `/api/v1/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnouncementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementApi - functional programming interface
 * @export
 */
export const AnnouncementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAnnouncementDto} createAnnouncementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementControllerCreate(createAnnouncementDto: CreateAnnouncementDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Announcement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementControllerCreate(createAnnouncementDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [target] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementControllerFindAll(searchTerm?: string, startDate?: string, endDate?: string, target?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Announcement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementControllerFindAll(searchTerm, startDate, endDate, target, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Announcement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAnnouncementDto} updateAnnouncementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementControllerUpdate(id: string, updateAnnouncementDto: UpdateAnnouncementDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Announcement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementControllerUpdate(id, updateAnnouncementDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementApi - factory interface
 * @export
 */
export const AnnouncementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAnnouncementDto} createAnnouncementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerCreate(createAnnouncementDto: CreateAnnouncementDto, options?: any): AxiosPromise<Announcement> {
            return localVarFp.announcementControllerCreate(createAnnouncementDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [target] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerFindAll(searchTerm?: string, startDate?: string, endDate?: string, target?: string, options?: any): AxiosPromise<Array<Announcement>> {
            return localVarFp.announcementControllerFindAll(searchTerm, startDate, endDate, target, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerFindOne(id: string, options?: any): AxiosPromise<Announcement> {
            return localVarFp.announcementControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.announcementControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAnnouncementDto} updateAnnouncementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerUpdate(id: string, updateAnnouncementDto: UpdateAnnouncementDto, options?: any): AxiosPromise<Announcement> {
            return localVarFp.announcementControllerUpdate(id, updateAnnouncementDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementApi - object-oriented interface
 * @export
 * @class AnnouncementApi
 * @extends {BaseAPI}
 */
export class AnnouncementApi extends BaseAPI {
    /**
     * 
     * @param {CreateAnnouncementDto} createAnnouncementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public announcementControllerCreate(createAnnouncementDto: CreateAnnouncementDto, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).announcementControllerCreate(createAnnouncementDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [target] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public announcementControllerFindAll(searchTerm?: string, startDate?: string, endDate?: string, target?: string, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).announcementControllerFindAll(searchTerm, startDate, endDate, target, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public announcementControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).announcementControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public announcementControllerRemove(id: string, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).announcementControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAnnouncementDto} updateAnnouncementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public announcementControllerUpdate(id: string, updateAnnouncementDto: UpdateAnnouncementDto, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).announcementControllerUpdate(id, updateAnnouncementDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppointmentServicesApi - axios parameter creator
 * @export
 */
export const AppointmentServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdateMassAppointmentServiceDto} updateMassAppointmentServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentServicesControllerMassUpdate: async (updateMassAppointmentServiceDto: UpdateMassAppointmentServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMassAppointmentServiceDto' is not null or undefined
            assertParamExists('appointmentServicesControllerMassUpdate', 'updateMassAppointmentServiceDto', updateMassAppointmentServiceDto)
            const localVarPath = `/api/v1/appointment-services/mass-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMassAppointmentServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentServicesApi - functional programming interface
 * @export
 */
export const AppointmentServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdateMassAppointmentServiceDto} updateMassAppointmentServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentServicesControllerMassUpdate(updateMassAppointmentServiceDto: UpdateMassAppointmentServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentServicesControllerMassUpdate(updateMassAppointmentServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppointmentServicesApi - factory interface
 * @export
 */
export const AppointmentServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentServicesApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdateMassAppointmentServiceDto} updateMassAppointmentServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentServicesControllerMassUpdate(updateMassAppointmentServiceDto: UpdateMassAppointmentServiceDto, options?: any): AxiosPromise<Array<AppointmentService>> {
            return localVarFp.appointmentServicesControllerMassUpdate(updateMassAppointmentServiceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentServicesApi - object-oriented interface
 * @export
 * @class AppointmentServicesApi
 * @extends {BaseAPI}
 */
export class AppointmentServicesApi extends BaseAPI {
    /**
     * 
     * @param {UpdateMassAppointmentServiceDto} updateMassAppointmentServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentServicesApi
     */
    public appointmentServicesControllerMassUpdate(updateMassAppointmentServiceDto: UpdateMassAppointmentServiceDto, options?: AxiosRequestConfig) {
        return AppointmentServicesApiFp(this.configuration).appointmentServicesControllerMassUpdate(updateMassAppointmentServiceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppointmentsApi - axios parameter creator
 * @export
 */
export const AppointmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppointmentTagsDto} updateAppointmentTagsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerAddAppointmentTags: async (id: string, updateAppointmentTagsDto: UpdateAppointmentTagsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerAddAppointmentTags', 'id', id)
            // verify required parameter 'updateAppointmentTagsDto' is not null or undefined
            assertParamExists('appointmentsControllerAddAppointmentTags', 'updateAppointmentTagsDto', updateAppointmentTagsDto)
            const localVarPath = `/api/v1/appointments/{id}/tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentTagsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerApproveAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerApproveAppointment', 'id', id)
            const localVarPath = `/api/v1/appointments/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkCancelDto} bulkCancelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerBulkCancel: async (bulkCancelDto: BulkCancelDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkCancelDto' is not null or undefined
            assertParamExists('appointmentsControllerBulkCancel', 'bulkCancelDto', bulkCancelDto)
            const localVarPath = `/api/v1/appointments/cancel/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkCancelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkRescheduleDto} bulkRescheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerBulkReschedule: async (bulkRescheduleDto: BulkRescheduleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkRescheduleDto' is not null or undefined
            assertParamExists('appointmentsControllerBulkReschedule', 'bulkRescheduleDto', bulkRescheduleDto)
            const localVarPath = `/api/v1/appointments/reschedule/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkRescheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppointmentDto} updateAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCancelAppointment: async (id: string, updateAppointmentDto: UpdateAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerCancelAppointment', 'id', id)
            // verify required parameter 'updateAppointmentDto' is not null or undefined
            assertParamExists('appointmentsControllerCancelAppointment', 'updateAppointmentDto', updateAppointmentDto)
            const localVarPath = `/api/v1/appointments/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCompleteAppointment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerCompleteAppointment', 'id', id)
            const localVarPath = `/api/v1/appointments/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppointmentDto} createAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCreate: async (createAppointmentDto: CreateAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppointmentDto' is not null or undefined
            assertParamExists('appointmentsControllerCreate', 'createAppointmentDto', createAppointmentDto)
            const localVarPath = `/api/v1/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateGroupBookingDto} createGroupBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCreateMany: async (createGroupBookingDto: CreateGroupBookingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGroupBookingDto' is not null or undefined
            assertParamExists('appointmentsControllerCreateMany', 'createGroupBookingDto', createGroupBookingDto)
            const localVarPath = `/api/v1/appointments/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupBookingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMultiScheduleAppointmentDto} createMultiScheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCreateMultiSchedule: async (createMultiScheduleAppointmentDto: CreateMultiScheduleAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMultiScheduleAppointmentDto' is not null or undefined
            assertParamExists('appointmentsControllerCreateMultiSchedule', 'createMultiScheduleAppointmentDto', createMultiScheduleAppointmentDto)
            const localVarPath = `/api/v1/appointments/multi-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMultiScheduleAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerDeclineAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerDeclineAppointment', 'id', id)
            const localVarPath = `/api/v1/appointments/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindAll: async (startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (providerServiceIds) {
                localVarQueryParameter['providerServiceIds'] = providerServiceIds;
            }

            if (providerTagIds) {
                localVarQueryParameter['providerTagIds'] = providerTagIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (branchIds) {
                localVarQueryParameter['branchIds'] = branchIds;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [clientId] 
         * @param {Array<string>} [statuses] 
         * @param {Array<number>} [serviceIds] 
         * @param {Array<number>} [tagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindAllForExport: async (startDate?: string, endDate?: string, clientId?: string, statuses?: Array<string>, serviceIds?: Array<number>, tagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/appointments/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (serviceIds) {
                localVarQueryParameter['serviceIds'] = serviceIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (branchIds) {
                localVarQueryParameter['branchIds'] = branchIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindAllForReport: async (startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/appointments/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (providerServiceIds) {
                localVarQueryParameter['providerServiceIds'] = providerServiceIds;
            }

            if (providerTagIds) {
                localVarQueryParameter['providerTagIds'] = providerTagIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (branchIds) {
                localVarQueryParameter['branchIds'] = branchIds;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/appointments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [query] 
         * @param {string} [providerClientProfileId] 
         * @param {string} [status] 
         * @param {string} [branchId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindProviderClientProfileAppointments: async (startDate?: string, endDate?: string, query?: string, providerClientProfileId?: string, status?: string, branchId?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/appointments/provider-client-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (providerClientProfileId !== undefined) {
                localVarQueryParameter['providerClientProfileId'] = providerClientProfileId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (branchId !== undefined) {
                localVarQueryParameter['branchId'] = branchId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerGetAdminAppointmentSummary: async (startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/appointments/admin-appointment-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (providerServiceIds) {
                localVarQueryParameter['providerServiceIds'] = providerServiceIds;
            }

            if (providerTagIds) {
                localVarQueryParameter['providerTagIds'] = providerTagIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (branchIds) {
                localVarQueryParameter['branchIds'] = branchIds;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerGetAppointmentReferralFormSignedUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerGetAppointmentReferralFormSignedUrl', 'id', id)
            const localVarPath = `/api/v1/appointments/{id}/referral-form-signed-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerNoShowAppointment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerNoShowAppointment', 'id', id)
            const localVarPath = `/api/v1/appointments/{id}/no-show`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerOngoingAppointment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerOngoingAppointment', 'id', id)
            const localVarPath = `/api/v1/appointments/{id}/ongoing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RescheduleAppointmentDto} rescheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerReschedule: async (id: number, rescheduleAppointmentDto: RescheduleAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerReschedule', 'id', id)
            // verify required parameter 'rescheduleAppointmentDto' is not null or undefined
            assertParamExists('appointmentsControllerReschedule', 'rescheduleAppointmentDto', rescheduleAppointmentDto)
            const localVarPath = `/api/v1/appointments/{id}/reschedule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rescheduleAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateInPersonPaymentDto} updateInPersonPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerUpdateInPersonPayment: async (id: string, updateInPersonPaymentDto: UpdateInPersonPaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appointmentsControllerUpdateInPersonPayment', 'id', id)
            // verify required parameter 'updateInPersonPaymentDto' is not null or undefined
            assertParamExists('appointmentsControllerUpdateInPersonPayment', 'updateInPersonPaymentDto', updateInPersonPaymentDto)
            const localVarPath = `/api/v1/appointments/{id}/in-person-payment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInPersonPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerUploadDiagnosticFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/appointments/upload/diagnostic-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentsApi - functional programming interface
 * @export
 */
export const AppointmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppointmentTagsDto} updateAppointmentTagsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerAddAppointmentTags(id: string, updateAppointmentTagsDto: UpdateAppointmentTagsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerAddAppointmentTags(id, updateAppointmentTagsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerApproveAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerApproveAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkCancelDto} bulkCancelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerBulkCancel(bulkCancelDto: BulkCancelDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerBulkCancel(bulkCancelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkRescheduleDto} bulkRescheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerBulkReschedule(bulkRescheduleDto: BulkRescheduleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerBulkReschedule(bulkRescheduleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppointmentDto} updateAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerCancelAppointment(id: string, updateAppointmentDto: UpdateAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerCancelAppointment(id, updateAppointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerCompleteAppointment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerCompleteAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppointmentDto} createAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerCreate(createAppointmentDto: CreateAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerCreate(createAppointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateGroupBookingDto} createGroupBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerCreateMany(createGroupBookingDto: CreateGroupBookingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Appointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerCreateMany(createGroupBookingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMultiScheduleAppointmentDto} createMultiScheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerCreateMultiSchedule(createMultiScheduleAppointmentDto: CreateMultiScheduleAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Appointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerCreateMultiSchedule(createMultiScheduleAppointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerDeclineAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerDeclineAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerFindAll(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerFindAll(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [clientId] 
         * @param {Array<string>} [statuses] 
         * @param {Array<number>} [serviceIds] 
         * @param {Array<number>} [tagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerFindAllForExport(startDate?: string, endDate?: string, clientId?: string, statuses?: Array<string>, serviceIds?: Array<number>, tagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Appointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerFindAllForExport(startDate, endDate, clientId, statuses, serviceIds, tagIds, sortBy, branchIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerFindAllForReport(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Appointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerFindAllForReport(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [query] 
         * @param {string} [providerClientProfileId] 
         * @param {string} [status] 
         * @param {string} [branchId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerFindProviderClientProfileAppointments(startDate?: string, endDate?: string, query?: string, providerClientProfileId?: string, status?: string, branchId?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerFindProviderClientProfileAppointments(startDate, endDate, query, providerClientProfileId, status, branchId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerGetAdminAppointmentSummary(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminAppointmentSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerGetAdminAppointmentSummary(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerGetAppointmentReferralFormSignedUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerGetAppointmentReferralFormSignedUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerNoShowAppointment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerNoShowAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerOngoingAppointment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerOngoingAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RescheduleAppointmentDto} rescheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerReschedule(id: number, rescheduleAppointmentDto: RescheduleAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerReschedule(id, rescheduleAppointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateInPersonPaymentDto} updateInPersonPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerUpdateInPersonPayment(id: string, updateInPersonPaymentDto: UpdateInPersonPaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerUpdateInPersonPayment(id, updateInPersonPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentsControllerUploadDiagnosticFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentsControllerUploadDiagnosticFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppointmentsApi - factory interface
 * @export
 */
export const AppointmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppointmentTagsDto} updateAppointmentTagsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerAddAppointmentTags(id: string, updateAppointmentTagsDto: UpdateAppointmentTagsDto, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerAddAppointmentTags(id, updateAppointmentTagsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerApproveAppointment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerApproveAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkCancelDto} bulkCancelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerBulkCancel(bulkCancelDto: BulkCancelDto, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerBulkCancel(bulkCancelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkRescheduleDto} bulkRescheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerBulkReschedule(bulkRescheduleDto: BulkRescheduleDto, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerBulkReschedule(bulkRescheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAppointmentDto} updateAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCancelAppointment(id: string, updateAppointmentDto: UpdateAppointmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerCancelAppointment(id, updateAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCompleteAppointment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerCompleteAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppointmentDto} createAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCreate(createAppointmentDto: CreateAppointmentDto, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentsControllerCreate(createAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateGroupBookingDto} createGroupBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCreateMany(createGroupBookingDto: CreateGroupBookingDto, options?: any): AxiosPromise<Array<Appointment>> {
            return localVarFp.appointmentsControllerCreateMany(createGroupBookingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMultiScheduleAppointmentDto} createMultiScheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerCreateMultiSchedule(createMultiScheduleAppointmentDto: CreateMultiScheduleAppointmentDto, options?: any): AxiosPromise<Array<Appointment>> {
            return localVarFp.appointmentsControllerCreateMultiSchedule(createMultiScheduleAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerDeclineAppointment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerDeclineAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindAll(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.appointmentsControllerFindAll(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [clientId] 
         * @param {Array<string>} [statuses] 
         * @param {Array<number>} [serviceIds] 
         * @param {Array<number>} [tagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindAllForExport(startDate?: string, endDate?: string, clientId?: string, statuses?: Array<string>, serviceIds?: Array<number>, tagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, options?: any): AxiosPromise<Array<Appointment>> {
            return localVarFp.appointmentsControllerFindAllForExport(startDate, endDate, clientId, statuses, serviceIds, tagIds, sortBy, branchIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindAllForReport(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: any): AxiosPromise<Array<Appointment>> {
            return localVarFp.appointmentsControllerFindAllForReport(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindOne(id: string, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [query] 
         * @param {string} [providerClientProfileId] 
         * @param {string} [status] 
         * @param {string} [branchId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerFindProviderClientProfileAppointments(startDate?: string, endDate?: string, query?: string, providerClientProfileId?: string, status?: string, branchId?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.appointmentsControllerFindProviderClientProfileAppointments(startDate, endDate, query, providerClientProfileId, status, branchId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [status] 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [providerTagIds] 
         * @param {string} [sortBy] 
         * @param {Array<number>} [branchIds] 
         * @param {string} [brandId] 
         * @param {string} [platform] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerGetAdminAppointmentSummary(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: any): AxiosPromise<GetAdminAppointmentSummary> {
            return localVarFp.appointmentsControllerGetAdminAppointmentSummary(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerGetAppointmentReferralFormSignedUrl(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.appointmentsControllerGetAppointmentReferralFormSignedUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerNoShowAppointment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerNoShowAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerOngoingAppointment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerOngoingAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RescheduleAppointmentDto} rescheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerReschedule(id: number, rescheduleAppointmentDto: RescheduleAppointmentDto, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentsControllerReschedule(id, rescheduleAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateInPersonPaymentDto} updateInPersonPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerUpdateInPersonPayment(id: string, updateInPersonPaymentDto: UpdateInPersonPaymentDto, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentsControllerUpdateInPersonPayment(id, updateInPersonPaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentsControllerUploadDiagnosticFile(options?: any): AxiosPromise<any> {
            return localVarFp.appointmentsControllerUploadDiagnosticFile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentsApi - object-oriented interface
 * @export
 * @class AppointmentsApi
 * @extends {BaseAPI}
 */
export class AppointmentsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {UpdateAppointmentTagsDto} updateAppointmentTagsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerAddAppointmentTags(id: string, updateAppointmentTagsDto: UpdateAppointmentTagsDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerAddAppointmentTags(id, updateAppointmentTagsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerApproveAppointment(id: string, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerApproveAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkCancelDto} bulkCancelDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerBulkCancel(bulkCancelDto: BulkCancelDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerBulkCancel(bulkCancelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkRescheduleDto} bulkRescheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerBulkReschedule(bulkRescheduleDto: BulkRescheduleDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerBulkReschedule(bulkRescheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAppointmentDto} updateAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerCancelAppointment(id: string, updateAppointmentDto: UpdateAppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerCancelAppointment(id, updateAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerCompleteAppointment(id: number, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerCompleteAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppointmentDto} createAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerCreate(createAppointmentDto: CreateAppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerCreate(createAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateGroupBookingDto} createGroupBookingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerCreateMany(createGroupBookingDto: CreateGroupBookingDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerCreateMany(createGroupBookingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMultiScheduleAppointmentDto} createMultiScheduleAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerCreateMultiSchedule(createMultiScheduleAppointmentDto: CreateMultiScheduleAppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerCreateMultiSchedule(createMultiScheduleAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerDeclineAppointment(id: string, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerDeclineAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [status] 
     * @param {string} [query] 
     * @param {string} [providerTypeId] 
     * @param {Array<number>} [providerServiceIds] 
     * @param {Array<number>} [providerTagIds] 
     * @param {string} [sortBy] 
     * @param {Array<number>} [branchIds] 
     * @param {string} [brandId] 
     * @param {string} [platform] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerFindAll(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerFindAll(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [clientId] 
     * @param {Array<string>} [statuses] 
     * @param {Array<number>} [serviceIds] 
     * @param {Array<number>} [tagIds] 
     * @param {string} [sortBy] 
     * @param {Array<number>} [branchIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerFindAllForExport(startDate?: string, endDate?: string, clientId?: string, statuses?: Array<string>, serviceIds?: Array<number>, tagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerFindAllForExport(startDate, endDate, clientId, statuses, serviceIds, tagIds, sortBy, branchIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [status] 
     * @param {string} [query] 
     * @param {string} [providerTypeId] 
     * @param {Array<number>} [providerServiceIds] 
     * @param {Array<number>} [providerTagIds] 
     * @param {string} [sortBy] 
     * @param {Array<number>} [branchIds] 
     * @param {string} [brandId] 
     * @param {string} [platform] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerFindAllForReport(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerFindAllForReport(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [query] 
     * @param {string} [providerClientProfileId] 
     * @param {string} [status] 
     * @param {string} [branchId] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerFindProviderClientProfileAppointments(startDate?: string, endDate?: string, query?: string, providerClientProfileId?: string, status?: string, branchId?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerFindProviderClientProfileAppointments(startDate, endDate, query, providerClientProfileId, status, branchId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [status] 
     * @param {string} [query] 
     * @param {string} [providerTypeId] 
     * @param {Array<number>} [providerServiceIds] 
     * @param {Array<number>} [providerTagIds] 
     * @param {string} [sortBy] 
     * @param {Array<number>} [branchIds] 
     * @param {string} [brandId] 
     * @param {string} [platform] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerGetAdminAppointmentSummary(startDate?: string, endDate?: string, status?: string, query?: string, providerTypeId?: string, providerServiceIds?: Array<number>, providerTagIds?: Array<number>, sortBy?: string, branchIds?: Array<number>, brandId?: string, platform?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerGetAdminAppointmentSummary(startDate, endDate, status, query, providerTypeId, providerServiceIds, providerTagIds, sortBy, branchIds, brandId, platform, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerGetAppointmentReferralFormSignedUrl(id: string, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerGetAppointmentReferralFormSignedUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerNoShowAppointment(id: number, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerNoShowAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerOngoingAppointment(id: number, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerOngoingAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RescheduleAppointmentDto} rescheduleAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerReschedule(id: number, rescheduleAppointmentDto: RescheduleAppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerReschedule(id, rescheduleAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateInPersonPaymentDto} updateInPersonPaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerUpdateInPersonPayment(id: string, updateInPersonPaymentDto: UpdateInPersonPaymentDto, options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerUpdateInPersonPayment(id, updateInPersonPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    public appointmentsControllerUploadDiagnosticFile(options?: AxiosRequestConfig) {
        return AppointmentsApiFp(this.configuration).appointmentsControllerUploadDiagnosticFile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xSessionToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAuthenticate: async (xSessionToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSessionToken' is not null or undefined
            assertParamExists('authControllerAuthenticate', 'xSessionToken', xSessionToken)
            const localVarPath = `/api/v1/auth/session-token/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xSessionToken != null) {
                localVarHeaderParameter['x-session-token'] = String(xSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetPublicRsaKey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/public-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAuthSessionDto} createAuthSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitiateLogin: async (createAuthSessionDto: CreateAuthSessionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthSessionDto' is not null or undefined
            assertParamExists('authControllerInitiateLogin', 'createAuthSessionDto', createAuthSessionDto)
            const localVarPath = `/api/v1/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuthSessionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SsoLoginDto} ssoLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (ssoLoginDto: SsoLoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ssoLoginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'ssoLoginDto', ssoLoginDto)
            const localVarPath = `/api/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ssoLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SsoLogoutDto} ssoLogoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (ssoLogoutDto: SsoLogoutDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ssoLogoutDto' is not null or undefined
            assertParamExists('authControllerLogout', 'ssoLogoutDto', ssoLogoutDto)
            const localVarPath = `/api/v1/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ssoLogoutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken: async (refreshTokenDto: RefreshTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenDto' is not null or undefined
            assertParamExists('authControllerRefreshToken', 'refreshTokenDto', refreshTokenDto)
            const localVarPath = `/api/v1/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendSsoOtpDto} resendSsoOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendOtp: async (resendSsoOtpDto: ResendSsoOtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendSsoOtpDto' is not null or undefined
            assertParamExists('authControllerResendOtp', 'resendSsoOtpDto', resendSsoOtpDto)
            const localVarPath = `/api/v1/auth/otp/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendSsoOtpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeTokenDto} exchangeTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyExternalToken: async (exchangeTokenDto: ExchangeTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeTokenDto' is not null or undefined
            assertParamExists('authControllerVerifyExternalToken', 'exchangeTokenDto', exchangeTokenDto)
            const localVarPath = `/api/v1/auth/code/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySsoOtpDto} verifySsoOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyOtp: async (verifySsoOtpDto: VerifySsoOtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifySsoOtpDto' is not null or undefined
            assertParamExists('authControllerVerifyOtp', 'verifySsoOtpDto', verifySsoOtpDto)
            const localVarPath = `/api/v1/auth/otp/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySsoOtpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} xSessionToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAuthenticate(xSessionToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAuthenticate(xSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetPublicRsaKey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetPublicRsaKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAuthSessionDto} createAuthSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerInitiateLogin(createAuthSessionDto: CreateAuthSessionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerInitiateLogin(createAuthSessionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SsoLoginDto} ssoLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(ssoLoginDto: SsoLoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(ssoLoginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SsoLogoutDto} ssoLogoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(ssoLogoutDto: SsoLogoutDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(ssoLogoutDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshToken(refreshTokenDto: RefreshTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefreshToken(refreshTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResendSsoOtpDto} resendSsoOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResendOtp(resendSsoOtpDto: ResendSsoOtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResendOtp(resendSsoOtpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeTokenDto} exchangeTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyExternalToken(exchangeTokenDto: ExchangeTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyExternalToken(exchangeTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifySsoOtpDto} verifySsoOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyOtp(verifySsoOtpDto: VerifySsoOtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyOtp(verifySsoOtpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} xSessionToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAuthenticate(xSessionToken: string, options?: any): AxiosPromise<string> {
            return localVarFp.authControllerAuthenticate(xSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetPublicRsaKey(options?: any): AxiosPromise<string> {
            return localVarFp.authControllerGetPublicRsaKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAuthSessionDto} createAuthSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitiateLogin(createAuthSessionDto: CreateAuthSessionDto, options?: any): AxiosPromise<string> {
            return localVarFp.authControllerInitiateLogin(createAuthSessionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SsoLoginDto} ssoLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(ssoLoginDto: SsoLoginDto, options?: any): AxiosPromise<string> {
            return localVarFp.authControllerLogin(ssoLoginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SsoLogoutDto} ssoLogoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(ssoLogoutDto: SsoLogoutDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerLogout(ssoLogoutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken(refreshTokenDto: RefreshTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRefreshToken(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendSsoOtpDto} resendSsoOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendOtp(resendSsoOtpDto: ResendSsoOtpDto, options?: any): AxiosPromise<string> {
            return localVarFp.authControllerResendOtp(resendSsoOtpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeTokenDto} exchangeTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyExternalToken(exchangeTokenDto: ExchangeTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerVerifyExternalToken(exchangeTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySsoOtpDto} verifySsoOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyOtp(verifySsoOtpDto: VerifySsoOtpDto, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerVerifyOtp(verifySsoOtpDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} xSessionToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAuthenticate(xSessionToken: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAuthenticate(xSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGetPublicRsaKey(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerGetPublicRsaKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAuthSessionDto} createAuthSessionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerInitiateLogin(createAuthSessionDto: CreateAuthSessionDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerInitiateLogin(createAuthSessionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SsoLoginDto} ssoLoginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(ssoLoginDto: SsoLoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(ssoLoginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SsoLogoutDto} ssoLogoutDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogout(ssoLogoutDto: SsoLogoutDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogout(ssoLogoutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenDto} refreshTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRefreshToken(refreshTokenDto: RefreshTokenDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRefreshToken(refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResendSsoOtpDto} resendSsoOtpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResendOtp(resendSsoOtpDto: ResendSsoOtpDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResendOtp(resendSsoOtpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeTokenDto} exchangeTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyExternalToken(exchangeTokenDto: ExchangeTokenDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyExternalToken(exchangeTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySsoOtpDto} verifySsoOtpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyOtp(verifySsoOtpDto: VerifySsoOtpDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyOtp(verifySsoOtpDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BannerApi - axios parameter creator
 * @export
 */
export const BannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBannerDto} createBannerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerCreate: async (createBannerDto: CreateBannerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBannerDto' is not null or undefined
            assertParamExists('bannerControllerCreate', 'createBannerDto', createBannerDto)
            const localVarPath = `/api/v1/banner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBannerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerFindOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bannerControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/banner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bannerControllerRemove', 'id', id)
            const localVarPath = `/api/v1/banner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBannerDto} updateBannerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerUpdate: async (id: number, updateBannerDto: UpdateBannerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bannerControllerUpdate', 'id', id)
            // verify required parameter 'updateBannerDto' is not null or undefined
            assertParamExists('bannerControllerUpdate', 'updateBannerDto', updateBannerDto)
            const localVarPath = `/api/v1/banner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBannerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBannerDto} createBannerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerControllerCreate(createBannerDto: CreateBannerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerControllerCreate(createBannerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Banner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerControllerFindOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerControllerRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBannerDto} updateBannerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerControllerUpdate(id: number, updateBannerDto: UpdateBannerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerControllerUpdate(id, updateBannerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBannerDto} createBannerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerCreate(createBannerDto: CreateBannerDto, options?: any): AxiosPromise<Banner> {
            return localVarFp.bannerControllerCreate(createBannerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerFindAll(options?: any): AxiosPromise<Array<Banner>> {
            return localVarFp.bannerControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerFindOne(id: number, options?: any): AxiosPromise<Banner> {
            return localVarFp.bannerControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerRemove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bannerControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBannerDto} updateBannerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerUpdate(id: number, updateBannerDto: UpdateBannerDto, options?: any): AxiosPromise<void> {
            return localVarFp.bannerControllerUpdate(id, updateBannerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
    /**
     * 
     * @param {CreateBannerDto} createBannerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public bannerControllerCreate(createBannerDto: CreateBannerDto, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).bannerControllerCreate(createBannerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public bannerControllerFindAll(options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).bannerControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public bannerControllerFindOne(id: number, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).bannerControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public bannerControllerRemove(id: number, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).bannerControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateBannerDto} updateBannerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public bannerControllerUpdate(id: number, updateBannerDto: UpdateBannerDto, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).bannerControllerUpdate(id, updateBannerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientFavouriteApi - axios parameter creator
 * @export
 */
export const ClientFavouriteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveFavouriteDto} saveFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFavouriteControllerCreateFavourite: async (saveFavouriteDto: SaveFavouriteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveFavouriteDto' is not null or undefined
            assertParamExists('clientFavouriteControllerCreateFavourite', 'saveFavouriteDto', saveFavouriteDto)
            const localVarPath = `/api/v1/client-favourite/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveFavouriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveFavouriteDto} removeFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFavouriteControllerDeleteFavourite: async (removeFavouriteDto: RemoveFavouriteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeFavouriteDto' is not null or undefined
            assertParamExists('clientFavouriteControllerDeleteFavourite', 'removeFavouriteDto', removeFavouriteDto)
            const localVarPath = `/api/v1/client-favourite/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeFavouriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFavouriteControllerGetFavourites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client-favourite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientFavouriteApi - functional programming interface
 * @export
 */
export const ClientFavouriteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientFavouriteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SaveFavouriteDto} saveFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientFavouriteControllerCreateFavourite(saveFavouriteDto: SaveFavouriteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientFavourite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientFavouriteControllerCreateFavourite(saveFavouriteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveFavouriteDto} removeFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientFavouriteControllerDeleteFavourite(removeFavouriteDto: RemoveFavouriteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientFavourite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientFavouriteControllerDeleteFavourite(removeFavouriteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientFavouriteControllerGetFavourites(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientFavourite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientFavouriteControllerGetFavourites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientFavouriteApi - factory interface
 * @export
 */
export const ClientFavouriteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientFavouriteApiFp(configuration)
    return {
        /**
         * 
         * @param {SaveFavouriteDto} saveFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFavouriteControllerCreateFavourite(saveFavouriteDto: SaveFavouriteDto, options?: any): AxiosPromise<ClientFavourite> {
            return localVarFp.clientFavouriteControllerCreateFavourite(saveFavouriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveFavouriteDto} removeFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFavouriteControllerDeleteFavourite(removeFavouriteDto: RemoveFavouriteDto, options?: any): AxiosPromise<ClientFavourite> {
            return localVarFp.clientFavouriteControllerDeleteFavourite(removeFavouriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFavouriteControllerGetFavourites(options?: any): AxiosPromise<ClientFavourite> {
            return localVarFp.clientFavouriteControllerGetFavourites(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientFavouriteApi - object-oriented interface
 * @export
 * @class ClientFavouriteApi
 * @extends {BaseAPI}
 */
export class ClientFavouriteApi extends BaseAPI {
    /**
     * 
     * @param {SaveFavouriteDto} saveFavouriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientFavouriteApi
     */
    public clientFavouriteControllerCreateFavourite(saveFavouriteDto: SaveFavouriteDto, options?: AxiosRequestConfig) {
        return ClientFavouriteApiFp(this.configuration).clientFavouriteControllerCreateFavourite(saveFavouriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveFavouriteDto} removeFavouriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientFavouriteApi
     */
    public clientFavouriteControllerDeleteFavourite(removeFavouriteDto: RemoveFavouriteDto, options?: AxiosRequestConfig) {
        return ClientFavouriteApiFp(this.configuration).clientFavouriteControllerDeleteFavourite(removeFavouriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientFavouriteApi
     */
    public clientFavouriteControllerGetFavourites(options?: AxiosRequestConfig) {
        return ClientFavouriteApiFp(this.configuration).clientFavouriteControllerGetFavourites(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientProfileApi - axios parameter creator
 * @export
 */
export const ClientProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateClientProfileDto} createClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerCreate: async (createClientProfileDto: CreateClientProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientProfileDto' is not null or undefined
            assertParamExists('clientProfileControllerCreate', 'createClientProfileDto', createClientProfileDto)
            const localVarPath = `/api/v1/client-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerFindAll: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerFindByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('clientProfileControllerFindByUserId', 'userId', userId)
            const localVarPath = `/api/v1/client-profile/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientProfileControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/client-profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateClientProfileDto} updateClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerUpdate: async (id: string, updateClientProfileDto: UpdateClientProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientProfileControllerUpdate', 'id', id)
            // verify required parameter 'updateClientProfileDto' is not null or undefined
            assertParamExists('clientProfileControllerUpdate', 'updateClientProfileDto', updateClientProfileDto)
            const localVarPath = `/api/v1/client-profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientProfileApi - functional programming interface
 * @export
 */
export const ClientProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateClientProfileDto} createClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProfileControllerCreate(createClientProfileDto: CreateClientProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProfileControllerCreate(createClientProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProfileControllerFindAll(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProfileControllerFindAll(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProfileControllerFindByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProfileControllerFindByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProfileControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProfileControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateClientProfileDto} updateClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProfileControllerUpdate(id: string, updateClientProfileDto: UpdateClientProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProfileControllerUpdate(id, updateClientProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientProfileApi - factory interface
 * @export
 */
export const ClientProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateClientProfileDto} createClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerCreate(createClientProfileDto: CreateClientProfileDto, options?: any): AxiosPromise<ClientProfile> {
            return localVarFp.clientProfileControllerCreate(createClientProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerFindAll(userId?: string, options?: any): AxiosPromise<Array<ClientProfile>> {
            return localVarFp.clientProfileControllerFindAll(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerFindByUserId(userId: string, options?: any): AxiosPromise<ClientProfile> {
            return localVarFp.clientProfileControllerFindByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerFindOne(id: string, options?: any): AxiosPromise<ClientProfile> {
            return localVarFp.clientProfileControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateClientProfileDto} updateClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProfileControllerUpdate(id: string, updateClientProfileDto: UpdateClientProfileDto, options?: any): AxiosPromise<ClientProfile> {
            return localVarFp.clientProfileControllerUpdate(id, updateClientProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientProfileApi - object-oriented interface
 * @export
 * @class ClientProfileApi
 * @extends {BaseAPI}
 */
export class ClientProfileApi extends BaseAPI {
    /**
     * 
     * @param {CreateClientProfileDto} createClientProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientProfileApi
     */
    public clientProfileControllerCreate(createClientProfileDto: CreateClientProfileDto, options?: AxiosRequestConfig) {
        return ClientProfileApiFp(this.configuration).clientProfileControllerCreate(createClientProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientProfileApi
     */
    public clientProfileControllerFindAll(userId?: string, options?: AxiosRequestConfig) {
        return ClientProfileApiFp(this.configuration).clientProfileControllerFindAll(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientProfileApi
     */
    public clientProfileControllerFindByUserId(userId: string, options?: AxiosRequestConfig) {
        return ClientProfileApiFp(this.configuration).clientProfileControllerFindByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientProfileApi
     */
    public clientProfileControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ClientProfileApiFp(this.configuration).clientProfileControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateClientProfileDto} updateClientProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientProfileApi
     */
    public clientProfileControllerUpdate(id: string, updateClientProfileDto: UpdateClientProfileDto, options?: AxiosRequestConfig) {
        return ClientProfileApiFp(this.configuration).clientProfileControllerUpdate(id, updateClientProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerSendReminders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks/appointment-reminders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerSendScheduledAnnouncements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks/send-scheduled-announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warmupControllerWarmup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_ah/warmup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerSendReminders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerSendReminders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerSendScheduledAnnouncements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerSendScheduledAnnouncements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warmupControllerWarmup(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warmupControllerWarmup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerSendReminders(options?: any): AxiosPromise<void> {
            return localVarFp.tasksControllerSendReminders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerSendScheduledAnnouncements(options?: any): AxiosPromise<void> {
            return localVarFp.tasksControllerSendScheduledAnnouncements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warmupControllerWarmup(options?: any): AxiosPromise<string> {
            return localVarFp.warmupControllerWarmup(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tasksControllerSendReminders(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tasksControllerSendReminders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tasksControllerSendScheduledAnnouncements(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tasksControllerSendScheduledAnnouncements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public warmupControllerWarmup(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).warmupControllerWarmup(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDApi - axios parameter creator
 * @export
 */
export const DigitalIDApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xApiSignature 
         * @param {WebhookRequestDto} webhookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerDigitalIdWebhook: async (xApiSignature: string, webhookRequestDto: WebhookRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiSignature' is not null or undefined
            assertParamExists('digitalIdControllerDigitalIdWebhook', 'xApiSignature', xApiSignature)
            // verify required parameter 'webhookRequestDto' is not null or undefined
            assertParamExists('digitalIdControllerDigitalIdWebhook', 'webhookRequestDto', webhookRequestDto)
            const localVarPath = `/api/v1/digital-id/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xApiSignature != null) {
                localVarHeaderParameter['x-api-signature'] = String(xApiSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomCreatePatientDto} customCreatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerExportProviderClients: async (customCreatePatientDto: CustomCreatePatientDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customCreatePatientDto' is not null or undefined
            assertParamExists('digitalIdControllerExportProviderClients', 'customCreatePatientDto', customCreatePatientDto)
            const localVarPath = `/api/v1/digital-id/export-provider-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customCreatePatientDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerGetFindOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdControllerGetFindOneById', 'id', id)
            const localVarPath = `/api/v1/digital-id/access/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerListAllowedPatients: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/digital-id/allowed-patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerScan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdControllerScan', 'id', id)
            const localVarPath = `/api/v1/digital-id/scan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerUpsert: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/digital-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDApi - functional programming interface
 * @export
 */
export const DigitalIDApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} xApiSignature 
         * @param {WebhookRequestDto} webhookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdControllerDigitalIdWebhook(xApiSignature: string, webhookRequestDto: WebhookRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdControllerDigitalIdWebhook(xApiSignature, webhookRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomCreatePatientDto} customCreatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdControllerExportProviderClients(customCreatePatientDto: CustomCreatePatientDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdControllerExportProviderClients(customCreatePatientDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdControllerGetFindOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientProviderAccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdControllerGetFindOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdControllerListAllowedPatients(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DigitalIdPatientProviderAccess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdControllerListAllowedPatients(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdControllerScan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPatientDataAccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdControllerScan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdControllerUpsert(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdControllerUpsert(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDApi - factory interface
 * @export
 */
export const DigitalIDApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDApiFp(configuration)
    return {
        /**
         * 
         * @param {string} xApiSignature 
         * @param {WebhookRequestDto} webhookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerDigitalIdWebhook(xApiSignature: string, webhookRequestDto: WebhookRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdControllerDigitalIdWebhook(xApiSignature, webhookRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomCreatePatientDto} customCreatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerExportProviderClients(customCreatePatientDto: CustomCreatePatientDto, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdControllerExportProviderClients(customCreatePatientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerGetFindOneById(id: string, options?: any): AxiosPromise<GetPatientProviderAccessResponseDto> {
            return localVarFp.digitalIdControllerGetFindOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerListAllowedPatients(query?: string, options?: any): AxiosPromise<Array<DigitalIdPatientProviderAccess>> {
            return localVarFp.digitalIdControllerListAllowedPatients(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerScan(id: string, options?: any): AxiosPromise<RequestPatientDataAccessResponseDto> {
            return localVarFp.digitalIdControllerScan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdControllerUpsert(options?: any): AxiosPromise<GetProviderResponseDto> {
            return localVarFp.digitalIdControllerUpsert(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDApi - object-oriented interface
 * @export
 * @class DigitalIDApi
 * @extends {BaseAPI}
 */
export class DigitalIDApi extends BaseAPI {
    /**
     * 
     * @param {string} xApiSignature 
     * @param {WebhookRequestDto} webhookRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDApi
     */
    public digitalIdControllerDigitalIdWebhook(xApiSignature: string, webhookRequestDto: WebhookRequestDto, options?: AxiosRequestConfig) {
        return DigitalIDApiFp(this.configuration).digitalIdControllerDigitalIdWebhook(xApiSignature, webhookRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomCreatePatientDto} customCreatePatientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDApi
     */
    public digitalIdControllerExportProviderClients(customCreatePatientDto: CustomCreatePatientDto, options?: AxiosRequestConfig) {
        return DigitalIDApiFp(this.configuration).digitalIdControllerExportProviderClients(customCreatePatientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDApi
     */
    public digitalIdControllerGetFindOneById(id: string, options?: AxiosRequestConfig) {
        return DigitalIDApiFp(this.configuration).digitalIdControllerGetFindOneById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDApi
     */
    public digitalIdControllerListAllowedPatients(query?: string, options?: AxiosRequestConfig) {
        return DigitalIDApiFp(this.configuration).digitalIdControllerListAllowedPatients(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDApi
     */
    public digitalIdControllerScan(id: string, options?: AxiosRequestConfig) {
        return DigitalIDApiFp(this.configuration).digitalIdControllerScan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDApi
     */
    public digitalIdControllerUpsert(options?: AxiosRequestConfig) {
        return DigitalIDApiFp(this.configuration).digitalIdControllerUpsert(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDAllergyRecordsApi - axios parameter creator
 * @export
 */
export const DigitalIDAllergyRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateAllergyRecordDto} createAllergyRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerCreateAllergy: async (patientId: string, createAllergyRecordDto: CreateAllergyRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAllergyControllerCreateAllergy', 'patientId', patientId)
            // verify required parameter 'createAllergyRecordDto' is not null or undefined
            assertParamExists('digitalIdAllergyControllerCreateAllergy', 'createAllergyRecordDto', createAllergyRecordDto)
            const localVarPath = `/api/digital-id/allergy-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllergyRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerFindAllAllergyFromPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAllergyControllerFindAllAllergyFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/allergy-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerFindOneVitalsSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdAllergyControllerFindOneVitalsSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAllergyControllerFindOneVitalsSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/allergy-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerRemoveAllergy: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdAllergyControllerRemoveAllergy', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAllergyControllerRemoveAllergy', 'patientId', patientId)
            const localVarPath = `/api/digital-id/allergy-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateAllergyRecordDto} updateAllergyRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerUpdateAllergy: async (id: string, patientId: string, updateAllergyRecordDto: UpdateAllergyRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdAllergyControllerUpdateAllergy', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAllergyControllerUpdateAllergy', 'patientId', patientId)
            // verify required parameter 'updateAllergyRecordDto' is not null or undefined
            assertParamExists('digitalIdAllergyControllerUpdateAllergy', 'updateAllergyRecordDto', updateAllergyRecordDto)
            const localVarPath = `/api/digital-id/allergy-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllergyRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDAllergyRecordsApi - functional programming interface
 * @export
 */
export const DigitalIDAllergyRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDAllergyRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateAllergyRecordDto} createAllergyRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAllergyControllerCreateAllergy(patientId: string, createAllergyRecordDto: CreateAllergyRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeAllergyRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAllergyControllerCreateAllergy(patientId, createAllergyRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAllergyControllerFindAllAllergyFromPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeAllergyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAllergyControllerFindAllAllergyFromPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAllergyControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeAllergyRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAllergyControllerFindOneVitalsSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAllergyControllerRemoveAllergy(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAllergyControllerRemoveAllergy(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateAllergyRecordDto} updateAllergyRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAllergyControllerUpdateAllergy(id: string, patientId: string, updateAllergyRecordDto: UpdateAllergyRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeAllergyRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAllergyControllerUpdateAllergy(id, patientId, updateAllergyRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDAllergyRecordsApi - factory interface
 * @export
 */
export const DigitalIDAllergyRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDAllergyRecordsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateAllergyRecordDto} createAllergyRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerCreateAllergy(patientId: string, createAllergyRecordDto: CreateAllergyRecordDto, options?: any): AxiosPromise<PeAllergyRecord> {
            return localVarFp.digitalIdAllergyControllerCreateAllergy(patientId, createAllergyRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerFindAllAllergyFromPatient(patientId: string, options?: any): AxiosPromise<FindAllPeAllergyResponseDto> {
            return localVarFp.digitalIdAllergyControllerFindAllAllergyFromPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerFindOneVitalsSigns(id: string, patientId: string, options?: any): AxiosPromise<PeAllergyRecord> {
            return localVarFp.digitalIdAllergyControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerRemoveAllergy(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdAllergyControllerRemoveAllergy(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateAllergyRecordDto} updateAllergyRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAllergyControllerUpdateAllergy(id: string, patientId: string, updateAllergyRecordDto: UpdateAllergyRecordDto, options?: any): AxiosPromise<PeAllergyRecord> {
            return localVarFp.digitalIdAllergyControllerUpdateAllergy(id, patientId, updateAllergyRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDAllergyRecordsApi - object-oriented interface
 * @export
 * @class DigitalIDAllergyRecordsApi
 * @extends {BaseAPI}
 */
export class DigitalIDAllergyRecordsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateAllergyRecordDto} createAllergyRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAllergyRecordsApi
     */
    public digitalIdAllergyControllerCreateAllergy(patientId: string, createAllergyRecordDto: CreateAllergyRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDAllergyRecordsApiFp(this.configuration).digitalIdAllergyControllerCreateAllergy(patientId, createAllergyRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAllergyRecordsApi
     */
    public digitalIdAllergyControllerFindAllAllergyFromPatient(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDAllergyRecordsApiFp(this.configuration).digitalIdAllergyControllerFindAllAllergyFromPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAllergyRecordsApi
     */
    public digitalIdAllergyControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDAllergyRecordsApiFp(this.configuration).digitalIdAllergyControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAllergyRecordsApi
     */
    public digitalIdAllergyControllerRemoveAllergy(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDAllergyRecordsApiFp(this.configuration).digitalIdAllergyControllerRemoveAllergy(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateAllergyRecordDto} updateAllergyRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAllergyRecordsApi
     */
    public digitalIdAllergyControllerUpdateAllergy(id: string, patientId: string, updateAllergyRecordDto: UpdateAllergyRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDAllergyRecordsApiFp(this.configuration).digitalIdAllergyControllerUpdateAllergy(id, patientId, updateAllergyRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDAnthropometricHistoryApi - axios parameter creator
 * @export
 */
export const DigitalIDAnthropometricHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateAnthropometricRecordDto} createAnthropometricRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord: async (patientId: string, createAnthropometricRecordDto: CreateAnthropometricRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord', 'patientId', patientId)
            // verify required parameter 'createAnthropometricRecordDto' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord', 'createAnthropometricRecordDto', createAnthropometricRecordDto)
            const localVarPath = `/api/digital-id/anthropometric-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnthropometricRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById', 'patientId', patientId)
            const localVarPath = `/api/digital-id/anthropometric-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById', 'patientId', patientId)
            const localVarPath = `/api/digital-id/anthropometric-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerFindRecordsByPatientId: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerFindRecordsByPatientId', 'patientId', patientId)
            const localVarPath = `/api/digital-id/anthropometric-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateAnthropometricRecordDto} updateAnthropometricRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId: async (id: string, patientId: string, updateAnthropometricRecordDto: UpdateAnthropometricRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId', 'patientId', patientId)
            // verify required parameter 'updateAnthropometricRecordDto' is not null or undefined
            assertParamExists('digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId', 'updateAnthropometricRecordDto', updateAnthropometricRecordDto)
            const localVarPath = `/api/digital-id/anthropometric-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnthropometricRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDAnthropometricHistoryApi - functional programming interface
 * @export
 */
export const DigitalIDAnthropometricHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDAnthropometricHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateAnthropometricRecordDto} createAnthropometricRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord(patientId: string, createAnthropometricRecordDto: CreateAnthropometricRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeAnthropometricRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord(patientId, createAnthropometricRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeAnthropometricRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAnthropometricHistoryControllerFindRecordsByPatientId(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeAnthropometricResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAnthropometricHistoryControllerFindRecordsByPatientId(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateAnthropometricRecordDto} updateAnthropometricRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId(id: string, patientId: string, updateAnthropometricRecordDto: UpdateAnthropometricRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeAnthropometricRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId(id, patientId, updateAnthropometricRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDAnthropometricHistoryApi - factory interface
 * @export
 */
export const DigitalIDAnthropometricHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDAnthropometricHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateAnthropometricRecordDto} createAnthropometricRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord(patientId: string, createAnthropometricRecordDto: CreateAnthropometricRecordDto, options?: any): AxiosPromise<PeAnthropometricRecord> {
            return localVarFp.digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord(patientId, createAnthropometricRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById(id: string, patientId: string, options?: any): AxiosPromise<PeAnthropometricRecord> {
            return localVarFp.digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerFindRecordsByPatientId(patientId: string, options?: any): AxiosPromise<FindAllPeAnthropometricResponseDto> {
            return localVarFp.digitalIdAnthropometricHistoryControllerFindRecordsByPatientId(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateAnthropometricRecordDto} updateAnthropometricRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId(id: string, patientId: string, updateAnthropometricRecordDto: UpdateAnthropometricRecordDto, options?: any): AxiosPromise<PeAnthropometricRecord> {
            return localVarFp.digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId(id, patientId, updateAnthropometricRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDAnthropometricHistoryApi - object-oriented interface
 * @export
 * @class DigitalIDAnthropometricHistoryApi
 * @extends {BaseAPI}
 */
export class DigitalIDAnthropometricHistoryApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateAnthropometricRecordDto} createAnthropometricRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAnthropometricHistoryApi
     */
    public digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord(patientId: string, createAnthropometricRecordDto: CreateAnthropometricRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDAnthropometricHistoryApiFp(this.configuration).digitalIdAnthropometricHistoryControllerCreateAnthropometricRecord(patientId, createAnthropometricRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAnthropometricHistoryApi
     */
    public digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDAnthropometricHistoryApiFp(this.configuration).digitalIdAnthropometricHistoryControllerDeleteAnthropometricRecordById(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAnthropometricHistoryApi
     */
    public digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDAnthropometricHistoryApiFp(this.configuration).digitalIdAnthropometricHistoryControllerFindOneAnthropometricRecordById(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAnthropometricHistoryApi
     */
    public digitalIdAnthropometricHistoryControllerFindRecordsByPatientId(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDAnthropometricHistoryApiFp(this.configuration).digitalIdAnthropometricHistoryControllerFindRecordsByPatientId(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateAnthropometricRecordDto} updateAnthropometricRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDAnthropometricHistoryApi
     */
    public digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId(id: string, patientId: string, updateAnthropometricRecordDto: UpdateAnthropometricRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDAnthropometricHistoryApiFp(this.configuration).digitalIdAnthropometricHistoryControllerUpdateAnthropometricRecordByPatientId(id, patientId, updateAnthropometricRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDFamilyHistoryApi - axios parameter creator
 * @export
 */
export const DigitalIDFamilyHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFamilyHistoryRecordDto} createFamilyHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerCreate: async (createFamilyHistoryRecordDto: CreateFamilyHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFamilyHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerCreate', 'createFamilyHistoryRecordDto', createFamilyHistoryRecordDto)
            const localVarPath = `/api/digital-id/family-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFamilyHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerFindAllByPatientId: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerFindAllByPatientId', 'patientId', patientId)
            const localVarPath = `/api/digital-id/family-history/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerFindOne: async (recordId: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerFindOne', 'recordId', recordId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerFindOne', 'patientId', patientId)
            const localVarPath = `/api/digital-id/family-history/{recordId}/patients/{patientId}`
                .replace(`{${"recordId"}}`, encodeURIComponent(String(recordId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerRemove: async (recordId: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerRemove', 'recordId', recordId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerRemove', 'patientId', patientId)
            const localVarPath = `/api/digital-id/family-history/{recordId}/patients/{patientId}`
                .replace(`{${"recordId"}}`, encodeURIComponent(String(recordId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {UpdateFamilyHistoryRecordDto} updateFamilyHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerUpdate: async (recordId: string, patientId: string, updateFamilyHistoryRecordDto: UpdateFamilyHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerUpdate', 'recordId', recordId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerUpdate', 'patientId', patientId)
            // verify required parameter 'updateFamilyHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdFamilyHistoryControllerUpdate', 'updateFamilyHistoryRecordDto', updateFamilyHistoryRecordDto)
            const localVarPath = `/api/digital-id/family-history/{recordId}/patients/{patientId}`
                .replace(`{${"recordId"}}`, encodeURIComponent(String(recordId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFamilyHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDFamilyHistoryApi - functional programming interface
 * @export
 */
export const DigitalIDFamilyHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDFamilyHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFamilyHistoryRecordDto} createFamilyHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdFamilyHistoryControllerCreate(createFamilyHistoryRecordDto: CreateFamilyHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeFamilyHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdFamilyHistoryControllerCreate(createFamilyHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdFamilyHistoryControllerFindAllByPatientId(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeFamilyHistoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdFamilyHistoryControllerFindAllByPatientId(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdFamilyHistoryControllerFindOne(recordId: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeFamilyHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdFamilyHistoryControllerFindOne(recordId, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdFamilyHistoryControllerRemove(recordId: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdFamilyHistoryControllerRemove(recordId, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {UpdateFamilyHistoryRecordDto} updateFamilyHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdFamilyHistoryControllerUpdate(recordId: string, patientId: string, updateFamilyHistoryRecordDto: UpdateFamilyHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeFamilyHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdFamilyHistoryControllerUpdate(recordId, patientId, updateFamilyHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDFamilyHistoryApi - factory interface
 * @export
 */
export const DigitalIDFamilyHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDFamilyHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFamilyHistoryRecordDto} createFamilyHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerCreate(createFamilyHistoryRecordDto: CreateFamilyHistoryRecordDto, options?: any): AxiosPromise<PeFamilyHistoryRecord> {
            return localVarFp.digitalIdFamilyHistoryControllerCreate(createFamilyHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerFindAllByPatientId(patientId: string, options?: any): AxiosPromise<FindAllPeFamilyHistoryResponseDto> {
            return localVarFp.digitalIdFamilyHistoryControllerFindAllByPatientId(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerFindOne(recordId: string, patientId: string, options?: any): AxiosPromise<PeFamilyHistoryRecord> {
            return localVarFp.digitalIdFamilyHistoryControllerFindOne(recordId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerRemove(recordId: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdFamilyHistoryControllerRemove(recordId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} recordId 
         * @param {string} patientId 
         * @param {UpdateFamilyHistoryRecordDto} updateFamilyHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdFamilyHistoryControllerUpdate(recordId: string, patientId: string, updateFamilyHistoryRecordDto: UpdateFamilyHistoryRecordDto, options?: any): AxiosPromise<PeFamilyHistoryRecord> {
            return localVarFp.digitalIdFamilyHistoryControllerUpdate(recordId, patientId, updateFamilyHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDFamilyHistoryApi - object-oriented interface
 * @export
 * @class DigitalIDFamilyHistoryApi
 * @extends {BaseAPI}
 */
export class DigitalIDFamilyHistoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateFamilyHistoryRecordDto} createFamilyHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDFamilyHistoryApi
     */
    public digitalIdFamilyHistoryControllerCreate(createFamilyHistoryRecordDto: CreateFamilyHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDFamilyHistoryApiFp(this.configuration).digitalIdFamilyHistoryControllerCreate(createFamilyHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDFamilyHistoryApi
     */
    public digitalIdFamilyHistoryControllerFindAllByPatientId(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDFamilyHistoryApiFp(this.configuration).digitalIdFamilyHistoryControllerFindAllByPatientId(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} recordId 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDFamilyHistoryApi
     */
    public digitalIdFamilyHistoryControllerFindOne(recordId: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDFamilyHistoryApiFp(this.configuration).digitalIdFamilyHistoryControllerFindOne(recordId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} recordId 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDFamilyHistoryApi
     */
    public digitalIdFamilyHistoryControllerRemove(recordId: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDFamilyHistoryApiFp(this.configuration).digitalIdFamilyHistoryControllerRemove(recordId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} recordId 
     * @param {string} patientId 
     * @param {UpdateFamilyHistoryRecordDto} updateFamilyHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDFamilyHistoryApi
     */
    public digitalIdFamilyHistoryControllerUpdate(recordId: string, patientId: string, updateFamilyHistoryRecordDto: UpdateFamilyHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDFamilyHistoryApiFp(this.configuration).digitalIdFamilyHistoryControllerUpdate(recordId, patientId, updateFamilyHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDIdentificationDocumentsApi - axios parameter creator
 * @export
 */
export const DigitalIDIdentificationDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {number} [page] Page number
         * @param {number} [perPage] Number of records to return
         * @param {string} [sort] Sort created at
         * @param {string} [externalReference] Filter by external_reference
         * @param {string} [externalCreatedByUid] Filter by external_created_by_uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerFindAllFromPatient: async (patientId: string, page?: number, perPage?: number, sort?: string, externalReference?: string, externalCreatedByUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerFindAllFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/identification-documents/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (externalReference !== undefined) {
                localVarQueryParameter['external_reference'] = externalReference;
            }

            if (externalCreatedByUid !== undefined) {
                localVarQueryParameter['external_created_by_uid'] = externalCreatedByUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerFindOne: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerFindOne', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerFindOne', 'patientId', patientId)
            const localVarPath = `/api/digital-id/identification-documents/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerRemove: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerRemove', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerRemove', 'patientId', patientId)
            const localVarPath = `/api/digital-id/identification-documents/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerUpdate: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerUpdate', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerUpdate', 'patientId', patientId)
            const localVarPath = `/api/digital-id/identification-documents/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerUpload: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdIdentificationDocumentsControllerUpload', 'patientId', patientId)
            const localVarPath = `/api/digital-id/identification-documents/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDIdentificationDocumentsApi - functional programming interface
 * @export
 */
export const DigitalIDIdentificationDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDIdentificationDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {number} [page] Page number
         * @param {number} [perPage] Number of records to return
         * @param {string} [sort] Sort created at
         * @param {string} [externalReference] Filter by external_reference
         * @param {string} [externalCreatedByUid] Filter by external_created_by_uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdIdentificationDocumentsControllerFindAllFromPatient(patientId: string, page?: number, perPage?: number, sort?: string, externalReference?: string, externalCreatedByUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeIdentificationDocumentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdIdentificationDocumentsControllerFindAllFromPatient(patientId, page, perPage, sort, externalReference, externalCreatedByUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdIdentificationDocumentsControllerFindOne(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeIdentificationDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdIdentificationDocumentsControllerFindOne(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdIdentificationDocumentsControllerRemove(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdIdentificationDocumentsControllerRemove(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdIdentificationDocumentsControllerUpdate(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeIdentificationDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdIdentificationDocumentsControllerUpdate(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdIdentificationDocumentsControllerUpload(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeIdentificationDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdIdentificationDocumentsControllerUpload(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDIdentificationDocumentsApi - factory interface
 * @export
 */
export const DigitalIDIdentificationDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDIdentificationDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {number} [page] Page number
         * @param {number} [perPage] Number of records to return
         * @param {string} [sort] Sort created at
         * @param {string} [externalReference] Filter by external_reference
         * @param {string} [externalCreatedByUid] Filter by external_created_by_uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerFindAllFromPatient(patientId: string, page?: number, perPage?: number, sort?: string, externalReference?: string, externalCreatedByUid?: string, options?: any): AxiosPromise<FindAllPeIdentificationDocumentResponseDto> {
            return localVarFp.digitalIdIdentificationDocumentsControllerFindAllFromPatient(patientId, page, perPage, sort, externalReference, externalCreatedByUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerFindOne(id: string, patientId: string, options?: any): AxiosPromise<PeIdentificationDocument> {
            return localVarFp.digitalIdIdentificationDocumentsControllerFindOne(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerRemove(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdIdentificationDocumentsControllerRemove(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerUpdate(id: string, patientId: string, options?: any): AxiosPromise<PeIdentificationDocument> {
            return localVarFp.digitalIdIdentificationDocumentsControllerUpdate(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdIdentificationDocumentsControllerUpload(patientId: string, options?: any): AxiosPromise<PeIdentificationDocument> {
            return localVarFp.digitalIdIdentificationDocumentsControllerUpload(patientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDIdentificationDocumentsApi - object-oriented interface
 * @export
 * @class DigitalIDIdentificationDocumentsApi
 * @extends {BaseAPI}
 */
export class DigitalIDIdentificationDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {number} [page] Page number
     * @param {number} [perPage] Number of records to return
     * @param {string} [sort] Sort created at
     * @param {string} [externalReference] Filter by external_reference
     * @param {string} [externalCreatedByUid] Filter by external_created_by_uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDIdentificationDocumentsApi
     */
    public digitalIdIdentificationDocumentsControllerFindAllFromPatient(patientId: string, page?: number, perPage?: number, sort?: string, externalReference?: string, externalCreatedByUid?: string, options?: AxiosRequestConfig) {
        return DigitalIDIdentificationDocumentsApiFp(this.configuration).digitalIdIdentificationDocumentsControllerFindAllFromPatient(patientId, page, perPage, sort, externalReference, externalCreatedByUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDIdentificationDocumentsApi
     */
    public digitalIdIdentificationDocumentsControllerFindOne(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDIdentificationDocumentsApiFp(this.configuration).digitalIdIdentificationDocumentsControllerFindOne(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDIdentificationDocumentsApi
     */
    public digitalIdIdentificationDocumentsControllerRemove(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDIdentificationDocumentsApiFp(this.configuration).digitalIdIdentificationDocumentsControllerRemove(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDIdentificationDocumentsApi
     */
    public digitalIdIdentificationDocumentsControllerUpdate(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDIdentificationDocumentsApiFp(this.configuration).digitalIdIdentificationDocumentsControllerUpdate(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDIdentificationDocumentsApi
     */
    public digitalIdIdentificationDocumentsControllerUpload(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDIdentificationDocumentsApiFp(this.configuration).digitalIdIdentificationDocumentsControllerUpload(patientId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDMedicalDocumentsApi - axios parameter creator
 * @export
 */
export const DigitalIDMedicalDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {number} [perPage] Number of records to return
         * @param {number} [page] Page number
         * @param {string} [tag] Tags
         * @param {string} [filename] Filename
         * @param {string} [type] Type
         * @param {string} [startDate] Start Date
         * @param {string} [endDate] End Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient: async (patientId: string, perPage?: number, page?: number, tag?: string, filename?: string, type?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/medical-documents/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMedicalDocumentDto} updateMedicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalDocumentsControllerUpdateMedicalDocument: async (updateMedicalDocumentDto: UpdateMedicalDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMedicalDocumentDto' is not null or undefined
            assertParamExists('digitalIdMedicalDocumentsControllerUpdateMedicalDocument', 'updateMedicalDocumentDto', updateMedicalDocumentDto)
            const localVarPath = `/api/digital-id/medical-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMedicalDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalDocumentsControllerUploadMedicalDocument: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/digital-id/medical-documents/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDMedicalDocumentsApi - functional programming interface
 * @export
 */
export const DigitalIDMedicalDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDMedicalDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {number} [perPage] Number of records to return
         * @param {number} [page] Page number
         * @param {string} [tag] Tags
         * @param {string} [filename] Filename
         * @param {string} [type] Type
         * @param {string} [startDate] Start Date
         * @param {string} [endDate] End Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient(patientId: string, perPage?: number, page?: number, tag?: string, filename?: string, type?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicalDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient(patientId, perPage, page, tag, filename, type, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateMedicalDocumentDto} updateMedicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalDocumentsControllerUpdateMedicalDocument(updateMedicalDocumentDto: UpdateMedicalDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicalDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalDocumentsControllerUpdateMedicalDocument(updateMedicalDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalDocumentsControllerUploadMedicalDocument(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalDocumentsControllerUploadMedicalDocument(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDMedicalDocumentsApi - factory interface
 * @export
 */
export const DigitalIDMedicalDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDMedicalDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {number} [perPage] Number of records to return
         * @param {number} [page] Page number
         * @param {string} [tag] Tags
         * @param {string} [filename] Filename
         * @param {string} [type] Type
         * @param {string} [startDate] Start Date
         * @param {string} [endDate] End Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient(patientId: string, perPage?: number, page?: number, tag?: string, filename?: string, type?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PeMedicalDocument> {
            return localVarFp.digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient(patientId, perPage, page, tag, filename, type, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMedicalDocumentDto} updateMedicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalDocumentsControllerUpdateMedicalDocument(updateMedicalDocumentDto: UpdateMedicalDocumentDto, options?: any): AxiosPromise<PeMedicalDocument> {
            return localVarFp.digitalIdMedicalDocumentsControllerUpdateMedicalDocument(updateMedicalDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalDocumentsControllerUploadMedicalDocument(options?: any): AxiosPromise<object> {
            return localVarFp.digitalIdMedicalDocumentsControllerUploadMedicalDocument(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDMedicalDocumentsApi - object-oriented interface
 * @export
 * @class DigitalIDMedicalDocumentsApi
 * @extends {BaseAPI}
 */
export class DigitalIDMedicalDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {number} [perPage] Number of records to return
     * @param {number} [page] Page number
     * @param {string} [tag] Tags
     * @param {string} [filename] Filename
     * @param {string} [type] Type
     * @param {string} [startDate] Start Date
     * @param {string} [endDate] End Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalDocumentsApi
     */
    public digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient(patientId: string, perPage?: number, page?: number, tag?: string, filename?: string, type?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return DigitalIDMedicalDocumentsApiFp(this.configuration).digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient(patientId, perPage, page, tag, filename, type, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMedicalDocumentDto} updateMedicalDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalDocumentsApi
     */
    public digitalIdMedicalDocumentsControllerUpdateMedicalDocument(updateMedicalDocumentDto: UpdateMedicalDocumentDto, options?: AxiosRequestConfig) {
        return DigitalIDMedicalDocumentsApiFp(this.configuration).digitalIdMedicalDocumentsControllerUpdateMedicalDocument(updateMedicalDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalDocumentsApi
     */
    public digitalIdMedicalDocumentsControllerUploadMedicalDocument(options?: AxiosRequestConfig) {
        return DigitalIDMedicalDocumentsApiFp(this.configuration).digitalIdMedicalDocumentsControllerUploadMedicalDocument(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDMedicalHistoryRecordsApi - axios parameter creator
 * @export
 */
export const DigitalIDMedicalHistoryRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateMedicalHistoryRecordDto} createMedicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerCreateMedicalHistory: async (patientId: string, createMedicalHistoryRecordDto: CreateMedicalHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerCreateMedicalHistory', 'patientId', patientId)
            // verify required parameter 'createMedicalHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerCreateMedicalHistory', 'createMedicalHistoryRecordDto', createMedicalHistoryRecordDto)
            const localVarPath = `/api/digital-id/medical-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMedicalHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/medical-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerFindOneVitalsSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerFindOneVitalsSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerFindOneVitalsSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/medical-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerRemoveMedicalHistory: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerRemoveMedicalHistory', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerRemoveMedicalHistory', 'patientId', patientId)
            const localVarPath = `/api/digital-id/medical-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateMedicalHistoryRecordDto} updateMedicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerUpdateMedicalHistory: async (id: string, patientId: string, updateMedicalHistoryRecordDto: UpdateMedicalHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerUpdateMedicalHistory', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerUpdateMedicalHistory', 'patientId', patientId)
            // verify required parameter 'updateMedicalHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdMedicalHistoryControllerUpdateMedicalHistory', 'updateMedicalHistoryRecordDto', updateMedicalHistoryRecordDto)
            const localVarPath = `/api/digital-id/medical-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMedicalHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDMedicalHistoryRecordsApi - functional programming interface
 * @export
 */
export const DigitalIDMedicalHistoryRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDMedicalHistoryRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateMedicalHistoryRecordDto} createMedicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalHistoryControllerCreateMedicalHistory(patientId: string, createMedicalHistoryRecordDto: CreateMedicalHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicalHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalHistoryControllerCreateMedicalHistory(patientId, createMedicalHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeMedicalHistoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicalHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalHistoryControllerFindOneVitalsSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalHistoryControllerRemoveMedicalHistory(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalHistoryControllerRemoveMedicalHistory(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateMedicalHistoryRecordDto} updateMedicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicalHistoryControllerUpdateMedicalHistory(id: string, patientId: string, updateMedicalHistoryRecordDto: UpdateMedicalHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicalHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicalHistoryControllerUpdateMedicalHistory(id, patientId, updateMedicalHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDMedicalHistoryRecordsApi - factory interface
 * @export
 */
export const DigitalIDMedicalHistoryRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDMedicalHistoryRecordsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateMedicalHistoryRecordDto} createMedicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerCreateMedicalHistory(patientId: string, createMedicalHistoryRecordDto: CreateMedicalHistoryRecordDto, options?: any): AxiosPromise<PeMedicalHistoryRecord> {
            return localVarFp.digitalIdMedicalHistoryControllerCreateMedicalHistory(patientId, createMedicalHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient(patientId: string, options?: any): AxiosPromise<FindAllPeMedicalHistoryResponseDto> {
            return localVarFp.digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: any): AxiosPromise<PeMedicalHistoryRecord> {
            return localVarFp.digitalIdMedicalHistoryControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerRemoveMedicalHistory(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdMedicalHistoryControllerRemoveMedicalHistory(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateMedicalHistoryRecordDto} updateMedicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicalHistoryControllerUpdateMedicalHistory(id: string, patientId: string, updateMedicalHistoryRecordDto: UpdateMedicalHistoryRecordDto, options?: any): AxiosPromise<PeMedicalHistoryRecord> {
            return localVarFp.digitalIdMedicalHistoryControllerUpdateMedicalHistory(id, patientId, updateMedicalHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDMedicalHistoryRecordsApi - object-oriented interface
 * @export
 * @class DigitalIDMedicalHistoryRecordsApi
 * @extends {BaseAPI}
 */
export class DigitalIDMedicalHistoryRecordsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateMedicalHistoryRecordDto} createMedicalHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalHistoryRecordsApi
     */
    public digitalIdMedicalHistoryControllerCreateMedicalHistory(patientId: string, createMedicalHistoryRecordDto: CreateMedicalHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDMedicalHistoryRecordsApiFp(this.configuration).digitalIdMedicalHistoryControllerCreateMedicalHistory(patientId, createMedicalHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalHistoryRecordsApi
     */
    public digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDMedicalHistoryRecordsApiFp(this.configuration).digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalHistoryRecordsApi
     */
    public digitalIdMedicalHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDMedicalHistoryRecordsApiFp(this.configuration).digitalIdMedicalHistoryControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalHistoryRecordsApi
     */
    public digitalIdMedicalHistoryControllerRemoveMedicalHistory(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDMedicalHistoryRecordsApiFp(this.configuration).digitalIdMedicalHistoryControllerRemoveMedicalHistory(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateMedicalHistoryRecordDto} updateMedicalHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicalHistoryRecordsApi
     */
    public digitalIdMedicalHistoryControllerUpdateMedicalHistory(id: string, patientId: string, updateMedicalHistoryRecordDto: UpdateMedicalHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDMedicalHistoryRecordsApiFp(this.configuration).digitalIdMedicalHistoryControllerUpdateMedicalHistory(id, patientId, updateMedicalHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDMedicationRecordsApi - axios parameter creator
 * @export
 */
export const DigitalIDMedicationRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateMedicationRecordDto} createMedicationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerCreateMedication: async (patientId: string, createMedicationRecordDto: CreateMedicationRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicationControllerCreateMedication', 'patientId', patientId)
            // verify required parameter 'createMedicationRecordDto' is not null or undefined
            assertParamExists('digitalIdMedicationControllerCreateMedication', 'createMedicationRecordDto', createMedicationRecordDto)
            const localVarPath = `/api/digital-id/medication-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMedicationRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerFindAllMedicationFromPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicationControllerFindAllMedicationFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/medication-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerFindOneVitalsSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdMedicationControllerFindOneVitalsSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicationControllerFindOneVitalsSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/medication-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerRemoveMedication: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdMedicationControllerRemoveMedication', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicationControllerRemoveMedication', 'patientId', patientId)
            const localVarPath = `/api/digital-id/medication-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateMedicationRecordDto} updateMedicationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerUpdateMedication: async (id: string, patientId: string, updateMedicationRecordDto: UpdateMedicationRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdMedicationControllerUpdateMedication', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdMedicationControllerUpdateMedication', 'patientId', patientId)
            // verify required parameter 'updateMedicationRecordDto' is not null or undefined
            assertParamExists('digitalIdMedicationControllerUpdateMedication', 'updateMedicationRecordDto', updateMedicationRecordDto)
            const localVarPath = `/api/digital-id/medication-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMedicationRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDMedicationRecordsApi - functional programming interface
 * @export
 */
export const DigitalIDMedicationRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDMedicationRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateMedicationRecordDto} createMedicationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicationControllerCreateMedication(patientId: string, createMedicationRecordDto: CreateMedicationRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicationRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicationControllerCreateMedication(patientId, createMedicationRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicationControllerFindAllMedicationFromPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeMedicationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicationControllerFindAllMedicationFromPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicationControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicationRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicationControllerFindOneVitalsSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicationControllerRemoveMedication(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicationControllerRemoveMedication(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateMedicationRecordDto} updateMedicationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdMedicationControllerUpdateMedication(id: string, patientId: string, updateMedicationRecordDto: UpdateMedicationRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeMedicationRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdMedicationControllerUpdateMedication(id, patientId, updateMedicationRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDMedicationRecordsApi - factory interface
 * @export
 */
export const DigitalIDMedicationRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDMedicationRecordsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateMedicationRecordDto} createMedicationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerCreateMedication(patientId: string, createMedicationRecordDto: CreateMedicationRecordDto, options?: any): AxiosPromise<PeMedicationRecord> {
            return localVarFp.digitalIdMedicationControllerCreateMedication(patientId, createMedicationRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerFindAllMedicationFromPatient(patientId: string, options?: any): AxiosPromise<FindAllPeMedicationResponseDto> {
            return localVarFp.digitalIdMedicationControllerFindAllMedicationFromPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerFindOneVitalsSigns(id: string, patientId: string, options?: any): AxiosPromise<PeMedicationRecord> {
            return localVarFp.digitalIdMedicationControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerRemoveMedication(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdMedicationControllerRemoveMedication(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateMedicationRecordDto} updateMedicationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdMedicationControllerUpdateMedication(id: string, patientId: string, updateMedicationRecordDto: UpdateMedicationRecordDto, options?: any): AxiosPromise<PeMedicationRecord> {
            return localVarFp.digitalIdMedicationControllerUpdateMedication(id, patientId, updateMedicationRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDMedicationRecordsApi - object-oriented interface
 * @export
 * @class DigitalIDMedicationRecordsApi
 * @extends {BaseAPI}
 */
export class DigitalIDMedicationRecordsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateMedicationRecordDto} createMedicationRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicationRecordsApi
     */
    public digitalIdMedicationControllerCreateMedication(patientId: string, createMedicationRecordDto: CreateMedicationRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDMedicationRecordsApiFp(this.configuration).digitalIdMedicationControllerCreateMedication(patientId, createMedicationRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicationRecordsApi
     */
    public digitalIdMedicationControllerFindAllMedicationFromPatient(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDMedicationRecordsApiFp(this.configuration).digitalIdMedicationControllerFindAllMedicationFromPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicationRecordsApi
     */
    public digitalIdMedicationControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDMedicationRecordsApiFp(this.configuration).digitalIdMedicationControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicationRecordsApi
     */
    public digitalIdMedicationControllerRemoveMedication(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDMedicationRecordsApiFp(this.configuration).digitalIdMedicationControllerRemoveMedication(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateMedicationRecordDto} updateMedicationRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDMedicationRecordsApi
     */
    public digitalIdMedicationControllerUpdateMedication(id: string, patientId: string, updateMedicationRecordDto: UpdateMedicationRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDMedicationRecordsApiFp(this.configuration).digitalIdMedicationControllerUpdateMedication(id, patientId, updateMedicationRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDSocialHistoryRecordsApi - axios parameter creator
 * @export
 */
export const DigitalIDSocialHistoryRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateSocialHistoryRecordDto} createSocialHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerCreateSocialHistory: async (patientId: string, createSocialHistoryRecordDto: CreateSocialHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerCreateSocialHistory', 'patientId', patientId)
            // verify required parameter 'createSocialHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerCreateSocialHistory', 'createSocialHistoryRecordDto', createSocialHistoryRecordDto)
            const localVarPath = `/api/digital-id/social-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSocialHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/social-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerFindOneVitalsSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerFindOneVitalsSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerFindOneVitalsSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/social-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerRemoveSocialHistory: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerRemoveSocialHistory', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerRemoveSocialHistory', 'patientId', patientId)
            const localVarPath = `/api/digital-id/social-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateSocialHistoryRecordDto} updateSocialHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerUpdateSocialHistory: async (id: string, patientId: string, updateSocialHistoryRecordDto: UpdateSocialHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerUpdateSocialHistory', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerUpdateSocialHistory', 'patientId', patientId)
            // verify required parameter 'updateSocialHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdSocialHistoryControllerUpdateSocialHistory', 'updateSocialHistoryRecordDto', updateSocialHistoryRecordDto)
            const localVarPath = `/api/digital-id/social-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSocialHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDSocialHistoryRecordsApi - functional programming interface
 * @export
 */
export const DigitalIDSocialHistoryRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDSocialHistoryRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateSocialHistoryRecordDto} createSocialHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSocialHistoryControllerCreateSocialHistory(patientId: string, createSocialHistoryRecordDto: CreateSocialHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeSocialHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSocialHistoryControllerCreateSocialHistory(patientId, createSocialHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeSocialHistoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSocialHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeSocialHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSocialHistoryControllerFindOneVitalsSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSocialHistoryControllerRemoveSocialHistory(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSocialHistoryControllerRemoveSocialHistory(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateSocialHistoryRecordDto} updateSocialHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSocialHistoryControllerUpdateSocialHistory(id: string, patientId: string, updateSocialHistoryRecordDto: UpdateSocialHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeSocialHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSocialHistoryControllerUpdateSocialHistory(id, patientId, updateSocialHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDSocialHistoryRecordsApi - factory interface
 * @export
 */
export const DigitalIDSocialHistoryRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDSocialHistoryRecordsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateSocialHistoryRecordDto} createSocialHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerCreateSocialHistory(patientId: string, createSocialHistoryRecordDto: CreateSocialHistoryRecordDto, options?: any): AxiosPromise<PeSocialHistoryRecord> {
            return localVarFp.digitalIdSocialHistoryControllerCreateSocialHistory(patientId, createSocialHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient(patientId: string, options?: any): AxiosPromise<FindAllPeSocialHistoryResponseDto> {
            return localVarFp.digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: any): AxiosPromise<PeSocialHistoryRecord> {
            return localVarFp.digitalIdSocialHistoryControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerRemoveSocialHistory(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdSocialHistoryControllerRemoveSocialHistory(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateSocialHistoryRecordDto} updateSocialHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSocialHistoryControllerUpdateSocialHistory(id: string, patientId: string, updateSocialHistoryRecordDto: UpdateSocialHistoryRecordDto, options?: any): AxiosPromise<PeSocialHistoryRecord> {
            return localVarFp.digitalIdSocialHistoryControllerUpdateSocialHistory(id, patientId, updateSocialHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDSocialHistoryRecordsApi - object-oriented interface
 * @export
 * @class DigitalIDSocialHistoryRecordsApi
 * @extends {BaseAPI}
 */
export class DigitalIDSocialHistoryRecordsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateSocialHistoryRecordDto} createSocialHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSocialHistoryRecordsApi
     */
    public digitalIdSocialHistoryControllerCreateSocialHistory(patientId: string, createSocialHistoryRecordDto: CreateSocialHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDSocialHistoryRecordsApiFp(this.configuration).digitalIdSocialHistoryControllerCreateSocialHistory(patientId, createSocialHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSocialHistoryRecordsApi
     */
    public digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDSocialHistoryRecordsApiFp(this.configuration).digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSocialHistoryRecordsApi
     */
    public digitalIdSocialHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDSocialHistoryRecordsApiFp(this.configuration).digitalIdSocialHistoryControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSocialHistoryRecordsApi
     */
    public digitalIdSocialHistoryControllerRemoveSocialHistory(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDSocialHistoryRecordsApiFp(this.configuration).digitalIdSocialHistoryControllerRemoveSocialHistory(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateSocialHistoryRecordDto} updateSocialHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSocialHistoryRecordsApi
     */
    public digitalIdSocialHistoryControllerUpdateSocialHistory(id: string, patientId: string, updateSocialHistoryRecordDto: UpdateSocialHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDSocialHistoryRecordsApiFp(this.configuration).digitalIdSocialHistoryControllerUpdateSocialHistory(id, patientId, updateSocialHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDSurgicalHistoryRecordsApi - axios parameter creator
 * @export
 */
export const DigitalIDSurgicalHistoryRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateSurgicalHistoryRecordDto} createSurgicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerCreateSurgicalHistory: async (patientId: string, createSurgicalHistoryRecordDto: CreateSurgicalHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerCreateSurgicalHistory', 'patientId', patientId)
            // verify required parameter 'createSurgicalHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerCreateSurgicalHistory', 'createSurgicalHistoryRecordDto', createSurgicalHistoryRecordDto)
            const localVarPath = `/api/digital-id/surgical-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurgicalHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/surgical-history-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerFindOneVitalsSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerFindOneVitalsSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerFindOneVitalsSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/surgical-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerRemoveSurgicalHistory: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerRemoveSurgicalHistory', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerRemoveSurgicalHistory', 'patientId', patientId)
            const localVarPath = `/api/digital-id/surgical-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateSurgicalHistoryRecordDto} updateSurgicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerUpdateSurgicalHistory: async (id: string, patientId: string, updateSurgicalHistoryRecordDto: UpdateSurgicalHistoryRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerUpdateSurgicalHistory', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerUpdateSurgicalHistory', 'patientId', patientId)
            // verify required parameter 'updateSurgicalHistoryRecordDto' is not null or undefined
            assertParamExists('digitalIdSurgicalHistoryControllerUpdateSurgicalHistory', 'updateSurgicalHistoryRecordDto', updateSurgicalHistoryRecordDto)
            const localVarPath = `/api/digital-id/surgical-history-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSurgicalHistoryRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDSurgicalHistoryRecordsApi - functional programming interface
 * @export
 */
export const DigitalIDSurgicalHistoryRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDSurgicalHistoryRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateSurgicalHistoryRecordDto} createSurgicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSurgicalHistoryControllerCreateSurgicalHistory(patientId: string, createSurgicalHistoryRecordDto: CreateSurgicalHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeSurgicalHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSurgicalHistoryControllerCreateSurgicalHistory(patientId, createSurgicalHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeSurgicalHistoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSurgicalHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeSurgicalHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSurgicalHistoryControllerFindOneVitalsSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSurgicalHistoryControllerRemoveSurgicalHistory(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSurgicalHistoryControllerRemoveSurgicalHistory(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateSurgicalHistoryRecordDto} updateSurgicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdSurgicalHistoryControllerUpdateSurgicalHistory(id: string, patientId: string, updateSurgicalHistoryRecordDto: UpdateSurgicalHistoryRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeSurgicalHistoryRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdSurgicalHistoryControllerUpdateSurgicalHistory(id, patientId, updateSurgicalHistoryRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDSurgicalHistoryRecordsApi - factory interface
 * @export
 */
export const DigitalIDSurgicalHistoryRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDSurgicalHistoryRecordsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateSurgicalHistoryRecordDto} createSurgicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerCreateSurgicalHistory(patientId: string, createSurgicalHistoryRecordDto: CreateSurgicalHistoryRecordDto, options?: any): AxiosPromise<PeSurgicalHistoryRecord> {
            return localVarFp.digitalIdSurgicalHistoryControllerCreateSurgicalHistory(patientId, createSurgicalHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient(patientId: string, options?: any): AxiosPromise<FindAllPeSurgicalHistoryResponseDto> {
            return localVarFp.digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: any): AxiosPromise<PeSurgicalHistoryRecord> {
            return localVarFp.digitalIdSurgicalHistoryControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerRemoveSurgicalHistory(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdSurgicalHistoryControllerRemoveSurgicalHistory(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateSurgicalHistoryRecordDto} updateSurgicalHistoryRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdSurgicalHistoryControllerUpdateSurgicalHistory(id: string, patientId: string, updateSurgicalHistoryRecordDto: UpdateSurgicalHistoryRecordDto, options?: any): AxiosPromise<PeSurgicalHistoryRecord> {
            return localVarFp.digitalIdSurgicalHistoryControllerUpdateSurgicalHistory(id, patientId, updateSurgicalHistoryRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDSurgicalHistoryRecordsApi - object-oriented interface
 * @export
 * @class DigitalIDSurgicalHistoryRecordsApi
 * @extends {BaseAPI}
 */
export class DigitalIDSurgicalHistoryRecordsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateSurgicalHistoryRecordDto} createSurgicalHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSurgicalHistoryRecordsApi
     */
    public digitalIdSurgicalHistoryControllerCreateSurgicalHistory(patientId: string, createSurgicalHistoryRecordDto: CreateSurgicalHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDSurgicalHistoryRecordsApiFp(this.configuration).digitalIdSurgicalHistoryControllerCreateSurgicalHistory(patientId, createSurgicalHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSurgicalHistoryRecordsApi
     */
    public digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDSurgicalHistoryRecordsApiFp(this.configuration).digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSurgicalHistoryRecordsApi
     */
    public digitalIdSurgicalHistoryControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDSurgicalHistoryRecordsApiFp(this.configuration).digitalIdSurgicalHistoryControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSurgicalHistoryRecordsApi
     */
    public digitalIdSurgicalHistoryControllerRemoveSurgicalHistory(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDSurgicalHistoryRecordsApiFp(this.configuration).digitalIdSurgicalHistoryControllerRemoveSurgicalHistory(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateSurgicalHistoryRecordDto} updateSurgicalHistoryRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDSurgicalHistoryRecordsApi
     */
    public digitalIdSurgicalHistoryControllerUpdateSurgicalHistory(id: string, patientId: string, updateSurgicalHistoryRecordDto: UpdateSurgicalHistoryRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDSurgicalHistoryRecordsApiFp(this.configuration).digitalIdSurgicalHistoryControllerUpdateSurgicalHistory(id, patientId, updateSurgicalHistoryRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDVaccinationRecordsApi - axios parameter creator
 * @export
 */
export const DigitalIDVaccinationRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateVaccinationRecordDto} createVaccinationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerCreateVaccination: async (patientId: string, createVaccinationRecordDto: CreateVaccinationRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerCreateVaccination', 'patientId', patientId)
            // verify required parameter 'createVaccinationRecordDto' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerCreateVaccination', 'createVaccinationRecordDto', createVaccinationRecordDto)
            const localVarPath = `/api/digital-id/vaccination-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVaccinationRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerFindAllVaccinationFromPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerFindAllVaccinationFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/vaccination-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerFindOneVitalsSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerFindOneVitalsSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerFindOneVitalsSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/vaccination-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerRemoveVaccination: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerRemoveVaccination', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerRemoveVaccination', 'patientId', patientId)
            const localVarPath = `/api/digital-id/vaccination-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateVaccinationRecordDto} updateVaccinationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerUpdateVaccination: async (id: string, patientId: string, updateVaccinationRecordDto: UpdateVaccinationRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerUpdateVaccination', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerUpdateVaccination', 'patientId', patientId)
            // verify required parameter 'updateVaccinationRecordDto' is not null or undefined
            assertParamExists('digitalIdVaccinationControllerUpdateVaccination', 'updateVaccinationRecordDto', updateVaccinationRecordDto)
            const localVarPath = `/api/digital-id/vaccination-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVaccinationRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDVaccinationRecordsApi - functional programming interface
 * @export
 */
export const DigitalIDVaccinationRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDVaccinationRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateVaccinationRecordDto} createVaccinationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVaccinationControllerCreateVaccination(patientId: string, createVaccinationRecordDto: CreateVaccinationRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeVaccinationRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVaccinationControllerCreateVaccination(patientId, createVaccinationRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVaccinationControllerFindAllVaccinationFromPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeVaccinationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVaccinationControllerFindAllVaccinationFromPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVaccinationControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeVaccinationRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVaccinationControllerFindOneVitalsSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVaccinationControllerRemoveVaccination(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVaccinationControllerRemoveVaccination(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateVaccinationRecordDto} updateVaccinationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVaccinationControllerUpdateVaccination(id: string, patientId: string, updateVaccinationRecordDto: UpdateVaccinationRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeVaccinationRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVaccinationControllerUpdateVaccination(id, patientId, updateVaccinationRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDVaccinationRecordsApi - factory interface
 * @export
 */
export const DigitalIDVaccinationRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDVaccinationRecordsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateVaccinationRecordDto} createVaccinationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerCreateVaccination(patientId: string, createVaccinationRecordDto: CreateVaccinationRecordDto, options?: any): AxiosPromise<PeVaccinationRecord> {
            return localVarFp.digitalIdVaccinationControllerCreateVaccination(patientId, createVaccinationRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerFindAllVaccinationFromPatient(patientId: string, options?: any): AxiosPromise<FindAllPeVaccinationResponseDto> {
            return localVarFp.digitalIdVaccinationControllerFindAllVaccinationFromPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerFindOneVitalsSigns(id: string, patientId: string, options?: any): AxiosPromise<PeVaccinationRecord> {
            return localVarFp.digitalIdVaccinationControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerRemoveVaccination(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdVaccinationControllerRemoveVaccination(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateVaccinationRecordDto} updateVaccinationRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVaccinationControllerUpdateVaccination(id: string, patientId: string, updateVaccinationRecordDto: UpdateVaccinationRecordDto, options?: any): AxiosPromise<PeVaccinationRecord> {
            return localVarFp.digitalIdVaccinationControllerUpdateVaccination(id, patientId, updateVaccinationRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDVaccinationRecordsApi - object-oriented interface
 * @export
 * @class DigitalIDVaccinationRecordsApi
 * @extends {BaseAPI}
 */
export class DigitalIDVaccinationRecordsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateVaccinationRecordDto} createVaccinationRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVaccinationRecordsApi
     */
    public digitalIdVaccinationControllerCreateVaccination(patientId: string, createVaccinationRecordDto: CreateVaccinationRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDVaccinationRecordsApiFp(this.configuration).digitalIdVaccinationControllerCreateVaccination(patientId, createVaccinationRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVaccinationRecordsApi
     */
    public digitalIdVaccinationControllerFindAllVaccinationFromPatient(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDVaccinationRecordsApiFp(this.configuration).digitalIdVaccinationControllerFindAllVaccinationFromPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVaccinationRecordsApi
     */
    public digitalIdVaccinationControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDVaccinationRecordsApiFp(this.configuration).digitalIdVaccinationControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVaccinationRecordsApi
     */
    public digitalIdVaccinationControllerRemoveVaccination(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDVaccinationRecordsApiFp(this.configuration).digitalIdVaccinationControllerRemoveVaccination(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateVaccinationRecordDto} updateVaccinationRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVaccinationRecordsApi
     */
    public digitalIdVaccinationControllerUpdateVaccination(id: string, patientId: string, updateVaccinationRecordDto: UpdateVaccinationRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDVaccinationRecordsApiFp(this.configuration).digitalIdVaccinationControllerUpdateVaccination(id, patientId, updateVaccinationRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDValueSetsApi - axios parameter creator
 * @export
 */
export const DigitalIDValueSetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdValueSetsControllerGetValueSetIds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/value-sets/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDValueSetsApi - functional programming interface
 * @export
 */
export const DigitalIDValueSetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDValueSetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdValueSetsControllerGetValueSetIds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValueSetIdType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdValueSetsControllerGetValueSetIds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDValueSetsApi - factory interface
 * @export
 */
export const DigitalIDValueSetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDValueSetsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdValueSetsControllerGetValueSetIds(options?: any): AxiosPromise<Array<ValueSetIdType>> {
            return localVarFp.digitalIdValueSetsControllerGetValueSetIds(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDValueSetsApi - object-oriented interface
 * @export
 * @class DigitalIDValueSetsApi
 * @extends {BaseAPI}
 */
export class DigitalIDValueSetsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDValueSetsApi
     */
    public digitalIdValueSetsControllerGetValueSetIds(options?: AxiosRequestConfig) {
        return DigitalIDValueSetsApiFp(this.configuration).digitalIdValueSetsControllerGetValueSetIds(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalIDVitalSignsRecordsApi - axios parameter creator
 * @export
 */
export const DigitalIDVitalSignsRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateVitalSignRecordDto} createVitalSignRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerCreateVitalSigns: async (patientId: string, createVitalSignRecordDto: CreateVitalSignRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerCreateVitalSigns', 'patientId', patientId)
            // verify required parameter 'createVitalSignRecordDto' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerCreateVitalSigns', 'createVitalSignRecordDto', createVitalSignRecordDto)
            const localVarPath = `/api/digital-id/vital-sign-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVitalSignRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerFindAllVitalSignsFromPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerFindAllVitalSignsFromPatient', 'patientId', patientId)
            const localVarPath = `/api/digital-id/vital-sign-records/patients/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerFindOneVitalsSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerFindOneVitalsSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerFindOneVitalsSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/vital-sign-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerRemoveVitalSigns: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerRemoveVitalSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerRemoveVitalSigns', 'patientId', patientId)
            const localVarPath = `/api/digital-id/vital-sign-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateVitalSignRecordDto} updateVitalSignRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerUpdateVitalSigns: async (id: string, patientId: string, updateVitalSignRecordDto: UpdateVitalSignRecordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerUpdateVitalSigns', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerUpdateVitalSigns', 'patientId', patientId)
            // verify required parameter 'updateVitalSignRecordDto' is not null or undefined
            assertParamExists('digitalIdVitalSignsControllerUpdateVitalSigns', 'updateVitalSignRecordDto', updateVitalSignRecordDto)
            const localVarPath = `/api/digital-id/vital-sign-records/{id}/patients/{patientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVitalSignRecordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalIDVitalSignsRecordsApi - functional programming interface
 * @export
 */
export const DigitalIDVitalSignsRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalIDVitalSignsRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateVitalSignRecordDto} createVitalSignRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVitalSignsControllerCreateVitalSigns(patientId: string, createVitalSignRecordDto: CreateVitalSignRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeVitalSignRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVitalSignsControllerCreateVitalSigns(patientId, createVitalSignRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVitalSignsControllerFindAllVitalSignsFromPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPeVitalSignsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVitalSignsControllerFindAllVitalSignsFromPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVitalSignsControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeVitalSignRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVitalSignsControllerFindOneVitalsSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVitalSignsControllerRemoveVitalSigns(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVitalSignsControllerRemoveVitalSigns(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateVitalSignRecordDto} updateVitalSignRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalIdVitalSignsControllerUpdateVitalSigns(id: string, patientId: string, updateVitalSignRecordDto: UpdateVitalSignRecordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeVitalSignRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalIdVitalSignsControllerUpdateVitalSigns(id, patientId, updateVitalSignRecordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalIDVitalSignsRecordsApi - factory interface
 * @export
 */
export const DigitalIDVitalSignsRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalIDVitalSignsRecordsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {CreateVitalSignRecordDto} createVitalSignRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerCreateVitalSigns(patientId: string, createVitalSignRecordDto: CreateVitalSignRecordDto, options?: any): AxiosPromise<PeVitalSignRecord> {
            return localVarFp.digitalIdVitalSignsControllerCreateVitalSigns(patientId, createVitalSignRecordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerFindAllVitalSignsFromPatient(patientId: string, options?: any): AxiosPromise<FindAllPeVitalSignsResponseDto> {
            return localVarFp.digitalIdVitalSignsControllerFindAllVitalSignsFromPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerFindOneVitalsSigns(id: string, patientId: string, options?: any): AxiosPromise<PeVitalSignRecord> {
            return localVarFp.digitalIdVitalSignsControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerRemoveVitalSigns(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.digitalIdVitalSignsControllerRemoveVitalSigns(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {UpdateVitalSignRecordDto} updateVitalSignRecordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalIdVitalSignsControllerUpdateVitalSigns(id: string, patientId: string, updateVitalSignRecordDto: UpdateVitalSignRecordDto, options?: any): AxiosPromise<PeVitalSignRecord> {
            return localVarFp.digitalIdVitalSignsControllerUpdateVitalSigns(id, patientId, updateVitalSignRecordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalIDVitalSignsRecordsApi - object-oriented interface
 * @export
 * @class DigitalIDVitalSignsRecordsApi
 * @extends {BaseAPI}
 */
export class DigitalIDVitalSignsRecordsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {CreateVitalSignRecordDto} createVitalSignRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVitalSignsRecordsApi
     */
    public digitalIdVitalSignsControllerCreateVitalSigns(patientId: string, createVitalSignRecordDto: CreateVitalSignRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDVitalSignsRecordsApiFp(this.configuration).digitalIdVitalSignsControllerCreateVitalSigns(patientId, createVitalSignRecordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVitalSignsRecordsApi
     */
    public digitalIdVitalSignsControllerFindAllVitalSignsFromPatient(patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDVitalSignsRecordsApiFp(this.configuration).digitalIdVitalSignsControllerFindAllVitalSignsFromPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVitalSignsRecordsApi
     */
    public digitalIdVitalSignsControllerFindOneVitalsSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDVitalSignsRecordsApiFp(this.configuration).digitalIdVitalSignsControllerFindOneVitalsSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVitalSignsRecordsApi
     */
    public digitalIdVitalSignsControllerRemoveVitalSigns(id: string, patientId: string, options?: AxiosRequestConfig) {
        return DigitalIDVitalSignsRecordsApiFp(this.configuration).digitalIdVitalSignsControllerRemoveVitalSigns(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {UpdateVitalSignRecordDto} updateVitalSignRecordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalIDVitalSignsRecordsApi
     */
    public digitalIdVitalSignsControllerUpdateVitalSigns(id: string, patientId: string, updateVitalSignRecordDto: UpdateVitalSignRecordDto, options?: AxiosRequestConfig) {
        return DigitalIDVitalSignsRecordsApiFp(this.configuration).digitalIdVitalSignsControllerUpdateVitalSigns(id, patientId, updateVitalSignRecordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountApi - axios parameter creator
 * @export
 */
export const DiscountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDiscountDto} createDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCreateDiscount: async (createDiscountDto: CreateDiscountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDiscountDto' is not null or undefined
            assertParamExists('discountControllerCreateDiscount', 'createDiscountDto', createDiscountDto)
            const localVarPath = `/api/v1/discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDiscountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerGetDiscountById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerGetDiscountById', 'id', id)
            const localVarPath = `/api/v1/discount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE'} [method] 
         * @param {'Upcoming' | 'Ongoing' | 'Ended'} [status] 
         * @param {'ACTIVE' | 'INACTIVE'} [publishStatus] 
         * @param {Array<number>} [partnerIds] 
         * @param {Array<number>} [productIds] 
         * @param {Array<number>} [categoryIds] 
         * @param {'healthhub'} [platform] 
         * @param {string} [startsAt] 
         * @param {string} [endsAt] 
         * @param {string} [sortBy] 
         * @param {string} [activeHoursStart] 
         * @param {string} [activeHoursEnd] 
         * @param {Array<number>} [discountIds] 
         * @param {boolean} [includeProvider] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerList: async (name?: string, method?: 'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE', status?: 'Upcoming' | 'Ongoing' | 'Ended', publishStatus?: 'ACTIVE' | 'INACTIVE', partnerIds?: Array<number>, productIds?: Array<number>, categoryIds?: Array<number>, platform?: 'healthhub', startsAt?: string, endsAt?: string, sortBy?: string, activeHoursStart?: string, activeHoursEnd?: string, discountIds?: Array<number>, includeProvider?: boolean, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (method !== undefined) {
                localVarQueryParameter['method'] = method;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (publishStatus !== undefined) {
                localVarQueryParameter['publishStatus'] = publishStatus;
            }

            if (partnerIds) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds;
            }

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (startsAt !== undefined) {
                localVarQueryParameter['startsAt'] = (startsAt as any instanceof Date) ?
                    (startsAt as any).toISOString() :
                    startsAt;
            }

            if (endsAt !== undefined) {
                localVarQueryParameter['endsAt'] = (endsAt as any instanceof Date) ?
                    (endsAt as any).toISOString() :
                    endsAt;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (activeHoursStart !== undefined) {
                localVarQueryParameter['activeHoursStart'] = activeHoursStart;
            }

            if (activeHoursEnd !== undefined) {
                localVarQueryParameter['activeHoursEnd'] = activeHoursEnd;
            }

            if (discountIds) {
                localVarQueryParameter['discountIds'] = discountIds;
            }

            if (includeProvider !== undefined) {
                localVarQueryParameter['includeProvider'] = includeProvider;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerPing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discount/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerPublishDiscount: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerPublishDiscount', 'id', id)
            const localVarPath = `/api/v1/discount/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUnpublishDiscount: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerUnpublishDiscount', 'id', id)
            const localVarPath = `/api/v1/discount/{id}/unpublish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscountDto} updateDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUpdateDiscount: async (id: string, updateDiscountDto: UpdateDiscountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerUpdateDiscount', 'id', id)
            // verify required parameter 'updateDiscountDto' is not null or undefined
            assertParamExists('discountControllerUpdateDiscount', 'updateDiscountDto', updateDiscountDto)
            const localVarPath = `/api/v1/discount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDiscountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountApi - functional programming interface
 * @export
 */
export const DiscountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDiscountDto} createDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerCreateDiscount(createDiscountDto: CreateDiscountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerCreateDiscount(createDiscountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerGetDiscountById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerGetDiscountById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE'} [method] 
         * @param {'Upcoming' | 'Ongoing' | 'Ended'} [status] 
         * @param {'ACTIVE' | 'INACTIVE'} [publishStatus] 
         * @param {Array<number>} [partnerIds] 
         * @param {Array<number>} [productIds] 
         * @param {Array<number>} [categoryIds] 
         * @param {'healthhub'} [platform] 
         * @param {string} [startsAt] 
         * @param {string} [endsAt] 
         * @param {string} [sortBy] 
         * @param {string} [activeHoursStart] 
         * @param {string} [activeHoursEnd] 
         * @param {Array<number>} [discountIds] 
         * @param {boolean} [includeProvider] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerList(name?: string, method?: 'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE', status?: 'Upcoming' | 'Ongoing' | 'Ended', publishStatus?: 'ACTIVE' | 'INACTIVE', partnerIds?: Array<number>, productIds?: Array<number>, categoryIds?: Array<number>, platform?: 'healthhub', startsAt?: string, endsAt?: string, sortBy?: string, activeHoursStart?: string, activeHoursEnd?: string, discountIds?: Array<number>, includeProvider?: boolean, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerList(name, method, status, publishStatus, partnerIds, productIds, categoryIds, platform, startsAt, endsAt, sortBy, activeHoursStart, activeHoursEnd, discountIds, includeProvider, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerPing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerPing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerPublishDiscount(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerPublishDiscount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerUnpublishDiscount(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerUnpublishDiscount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscountDto} updateDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerUpdateDiscount(id: string, updateDiscountDto: UpdateDiscountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerUpdateDiscount(id, updateDiscountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountApi - factory interface
 * @export
 */
export const DiscountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDiscountDto} createDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCreateDiscount(createDiscountDto: CreateDiscountDto, options?: any): AxiosPromise<object> {
            return localVarFp.discountControllerCreateDiscount(createDiscountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerGetDiscountById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.discountControllerGetDiscountById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE'} [method] 
         * @param {'Upcoming' | 'Ongoing' | 'Ended'} [status] 
         * @param {'ACTIVE' | 'INACTIVE'} [publishStatus] 
         * @param {Array<number>} [partnerIds] 
         * @param {Array<number>} [productIds] 
         * @param {Array<number>} [categoryIds] 
         * @param {'healthhub'} [platform] 
         * @param {string} [startsAt] 
         * @param {string} [endsAt] 
         * @param {string} [sortBy] 
         * @param {string} [activeHoursStart] 
         * @param {string} [activeHoursEnd] 
         * @param {Array<number>} [discountIds] 
         * @param {boolean} [includeProvider] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerList(name?: string, method?: 'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE', status?: 'Upcoming' | 'Ongoing' | 'Ended', publishStatus?: 'ACTIVE' | 'INACTIVE', partnerIds?: Array<number>, productIds?: Array<number>, categoryIds?: Array<number>, platform?: 'healthhub', startsAt?: string, endsAt?: string, sortBy?: string, activeHoursStart?: string, activeHoursEnd?: string, discountIds?: Array<number>, includeProvider?: boolean, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.discountControllerList(name, method, status, publishStatus, partnerIds, productIds, categoryIds, platform, startsAt, endsAt, sortBy, activeHoursStart, activeHoursEnd, discountIds, includeProvider, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerPing(options?: any): AxiosPromise<object> {
            return localVarFp.discountControllerPing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerPublishDiscount(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.discountControllerPublishDiscount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUnpublishDiscount(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.discountControllerUnpublishDiscount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscountDto} updateDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUpdateDiscount(id: string, updateDiscountDto: UpdateDiscountDto, options?: any): AxiosPromise<object> {
            return localVarFp.discountControllerUpdateDiscount(id, updateDiscountDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountApi - object-oriented interface
 * @export
 * @class DiscountApi
 * @extends {BaseAPI}
 */
export class DiscountApi extends BaseAPI {
    /**
     * 
     * @param {CreateDiscountDto} createDiscountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerCreateDiscount(createDiscountDto: CreateDiscountDto, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerCreateDiscount(createDiscountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerGetDiscountById(id: string, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerGetDiscountById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE'} [method] 
     * @param {'Upcoming' | 'Ongoing' | 'Ended'} [status] 
     * @param {'ACTIVE' | 'INACTIVE'} [publishStatus] 
     * @param {Array<number>} [partnerIds] 
     * @param {Array<number>} [productIds] 
     * @param {Array<number>} [categoryIds] 
     * @param {'healthhub'} [platform] 
     * @param {string} [startsAt] 
     * @param {string} [endsAt] 
     * @param {string} [sortBy] 
     * @param {string} [activeHoursStart] 
     * @param {string} [activeHoursEnd] 
     * @param {Array<number>} [discountIds] 
     * @param {boolean} [includeProvider] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerList(name?: string, method?: 'FIXED_PRICE' | 'PERCENT_OFF_PRICE' | 'PESO_OFF_PRICE', status?: 'Upcoming' | 'Ongoing' | 'Ended', publishStatus?: 'ACTIVE' | 'INACTIVE', partnerIds?: Array<number>, productIds?: Array<number>, categoryIds?: Array<number>, platform?: 'healthhub', startsAt?: string, endsAt?: string, sortBy?: string, activeHoursStart?: string, activeHoursEnd?: string, discountIds?: Array<number>, includeProvider?: boolean, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerList(name, method, status, publishStatus, partnerIds, productIds, categoryIds, platform, startsAt, endsAt, sortBy, activeHoursStart, activeHoursEnd, discountIds, includeProvider, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerPing(options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerPublishDiscount(id: string, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerPublishDiscount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerUnpublishDiscount(id: string, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerUnpublishDiscount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDiscountDto} updateDiscountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerUpdateDiscount(id: string, updateDiscountDto: UpdateDiscountDto, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerUpdateDiscount(id, updateDiscountDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EntityTypeApi - axios parameter creator
 * @export
 */
export const EntityTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEntityTypeDto} createEntityTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerCreate: async (createEntityTypeDto: CreateEntityTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEntityTypeDto' is not null or undefined
            assertParamExists('entityTypeControllerCreate', 'createEntityTypeDto', createEntityTypeDto)
            const localVarPath = `/api/v1/entity-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEntityTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'Provider' | 'Category'} type 
         * @param {boolean} [includeRelation] 
         * @param {string} [search] 
         * @param {number} [providerTypeId] 
         * @param {string} [providerTypeIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerFindAll: async (type: 'Provider' | 'Category', includeRelation?: boolean, search?: string, providerTypeId?: number, providerTypeIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('entityTypeControllerFindAll', 'type', type)
            const localVarPath = `/api/v1/entity-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (includeRelation !== undefined) {
                localVarQueryParameter['includeRelation'] = includeRelation;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (providerTypeIds !== undefined) {
                localVarQueryParameter['providerTypeIds'] = providerTypeIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('entityTypeControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/entity-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('entityTypeControllerRemove', 'id', id)
            const localVarPath = `/api/v1/entity-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEntityTypeDto} updateEntityTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerUpdate: async (id: string, updateEntityTypeDto: UpdateEntityTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('entityTypeControllerUpdate', 'id', id)
            // verify required parameter 'updateEntityTypeDto' is not null or undefined
            assertParamExists('entityTypeControllerUpdate', 'updateEntityTypeDto', updateEntityTypeDto)
            const localVarPath = `/api/v1/entity-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEntityTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityTypeApi - functional programming interface
 * @export
 */
export const EntityTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEntityTypeDto} createEntityTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityTypeControllerCreate(createEntityTypeDto: CreateEntityTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityTypeControllerCreate(createEntityTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'Provider' | 'Category'} type 
         * @param {boolean} [includeRelation] 
         * @param {string} [search] 
         * @param {number} [providerTypeId] 
         * @param {string} [providerTypeIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityTypeControllerFindAll(type: 'Provider' | 'Category', includeRelation?: boolean, search?: string, providerTypeId?: number, providerTypeIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityTypeControllerFindAll(type, includeRelation, search, providerTypeId, providerTypeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityTypeControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityTypeControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityTypeControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityTypeControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEntityTypeDto} updateEntityTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityTypeControllerUpdate(id: string, updateEntityTypeDto: UpdateEntityTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityTypeControllerUpdate(id, updateEntityTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EntityTypeApi - factory interface
 * @export
 */
export const EntityTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEntityTypeDto} createEntityTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerCreate(createEntityTypeDto: CreateEntityTypeDto, options?: any): AxiosPromise<EntityType> {
            return localVarFp.entityTypeControllerCreate(createEntityTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'Provider' | 'Category'} type 
         * @param {boolean} [includeRelation] 
         * @param {string} [search] 
         * @param {number} [providerTypeId] 
         * @param {string} [providerTypeIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerFindAll(type: 'Provider' | 'Category', includeRelation?: boolean, search?: string, providerTypeId?: number, providerTypeIds?: string, options?: any): AxiosPromise<Array<EntityType>> {
            return localVarFp.entityTypeControllerFindAll(type, includeRelation, search, providerTypeId, providerTypeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerFindOne(id: string, options?: any): AxiosPromise<EntityType> {
            return localVarFp.entityTypeControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.entityTypeControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEntityTypeDto} updateEntityTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityTypeControllerUpdate(id: string, updateEntityTypeDto: UpdateEntityTypeDto, options?: any): AxiosPromise<EntityType> {
            return localVarFp.entityTypeControllerUpdate(id, updateEntityTypeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntityTypeApi - object-oriented interface
 * @export
 * @class EntityTypeApi
 * @extends {BaseAPI}
 */
export class EntityTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateEntityTypeDto} createEntityTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTypeApi
     */
    public entityTypeControllerCreate(createEntityTypeDto: CreateEntityTypeDto, options?: AxiosRequestConfig) {
        return EntityTypeApiFp(this.configuration).entityTypeControllerCreate(createEntityTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'Provider' | 'Category'} type 
     * @param {boolean} [includeRelation] 
     * @param {string} [search] 
     * @param {number} [providerTypeId] 
     * @param {string} [providerTypeIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTypeApi
     */
    public entityTypeControllerFindAll(type: 'Provider' | 'Category', includeRelation?: boolean, search?: string, providerTypeId?: number, providerTypeIds?: string, options?: AxiosRequestConfig) {
        return EntityTypeApiFp(this.configuration).entityTypeControllerFindAll(type, includeRelation, search, providerTypeId, providerTypeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTypeApi
     */
    public entityTypeControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return EntityTypeApiFp(this.configuration).entityTypeControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTypeApi
     */
    public entityTypeControllerRemove(id: string, options?: AxiosRequestConfig) {
        return EntityTypeApiFp(this.configuration).entityTypeControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateEntityTypeDto} updateEntityTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTypeApi
     */
    public entityTypeControllerUpdate(id: string, updateEntityTypeDto: UpdateEntityTypeDto, options?: AxiosRequestConfig) {
        return EntityTypeApiFp(this.configuration).entityTypeControllerUpdate(id, updateEntityTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EshopApi - axios parameter creator
 * @export
 */
export const EshopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateApiFieldsDto} createApiFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateApiField: async (createApiFieldsDto: CreateApiFieldsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createApiFieldsDto' is not null or undefined
            assertParamExists('eshopControllerCreateApiField', 'createApiFieldsDto', createApiFieldsDto)
            const localVarPath = `/api/v1/Eshop/api-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createApiFieldsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCategoryDto} createCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateCategory: async (createCategoryDto: CreateCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategoryDto' is not null or undefined
            assertParamExists('eshopControllerCreateCategory', 'createCategoryDto', createCategoryDto)
            const localVarPath = `/api/v1/Eshop/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMerchantDto} createMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateMerchant: async (createMerchantDto: CreateMerchantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMerchantDto' is not null or undefined
            assertParamExists('eshopControllerCreateMerchant', 'createMerchantDto', createMerchantDto)
            const localVarPath = `/api/v1/Eshop/merchant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMerchantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateProduct: async (createProductDto: CreateProductDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductDto' is not null or undefined
            assertParamExists('eshopControllerCreateProduct', 'createProductDto', createProductDto)
            const localVarPath = `/api/v1/Eshop/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTagDto} createTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateTag: async (createTagDto: CreateTagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagDto' is not null or undefined
            assertParamExists('eshopControllerCreateTag', 'createTagDto', createTagDto)
            const localVarPath = `/api/v1/Eshop/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteApiField: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerDeleteApiField', 'id', id)
            const localVarPath = `/api/v1/Eshop/api-fields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerDeleteCategory', 'id', id)
            const localVarPath = `/api/v1/Eshop/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteProduct: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerDeleteProduct', 'id', id)
            const localVarPath = `/api/v1/Eshop/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteTag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerDeleteTag', 'id', id)
            const localVarPath = `/api/v1/Eshop/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [isMwellCategory] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllCategories: async (query?: string, isMwellCategory?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (isMwellCategory !== undefined) {
                localVarQueryParameter['isMwellCategory'] = isMwellCategory;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllOrders: async (query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (merchantEmail !== undefined) {
                localVarQueryParameter['merchantEmail'] = merchantEmail;
            }

            if (externalPaymentStatus !== undefined) {
                localVarQueryParameter['externalPaymentStatus'] = externalPaymentStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (deliveryDateTo !== undefined) {
                localVarQueryParameter['deliveryDateTo'] = (deliveryDateTo as any instanceof Date) ?
                    (deliveryDateTo as any).toISOString() :
                    deliveryDateTo;
            }

            if (deliveryDateFrom !== undefined) {
                localVarQueryParameter['deliveryDateFrom'] = (deliveryDateFrom as any instanceof Date) ?
                    (deliveryDateFrom as any).toISOString() :
                    deliveryDateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED'} [status] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [categoryId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllProducts: async (query?: string, merchantEmail?: string, status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED', orderBy?: 'ASC' | 'DESC', categoryId?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (merchantEmail !== undefined) {
                localVarQueryParameter['merchantEmail'] = merchantEmail;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [tagType] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllTags: async (query?: string, tagType?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (tagType !== undefined) {
                localVarQueryParameter['tagType'] = tagType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllTransactionMerchantProducts: async (query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/transaction-merchant-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (merchantEmail !== undefined) {
                localVarQueryParameter['merchantEmail'] = merchantEmail;
            }

            if (externalPaymentStatus !== undefined) {
                localVarQueryParameter['externalPaymentStatus'] = externalPaymentStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (deliveryDateTo !== undefined) {
                localVarQueryParameter['deliveryDateTo'] = (deliveryDateTo as any instanceof Date) ?
                    (deliveryDateTo as any).toISOString() :
                    deliveryDateTo;
            }

            if (deliveryDateFrom !== undefined) {
                localVarQueryParameter['deliveryDateFrom'] = (deliveryDateFrom as any instanceof Date) ?
                    (deliveryDateFrom as any).toISOString() :
                    deliveryDateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllTransactionMerchants: async (query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/transaction-merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (merchantEmail !== undefined) {
                localVarQueryParameter['merchantEmail'] = merchantEmail;
            }

            if (externalPaymentStatus !== undefined) {
                localVarQueryParameter['externalPaymentStatus'] = externalPaymentStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (deliveryDateTo !== undefined) {
                localVarQueryParameter['deliveryDateTo'] = (deliveryDateTo as any instanceof Date) ?
                    (deliveryDateTo as any).toISOString() :
                    deliveryDateTo;
            }

            if (deliveryDateFrom !== undefined) {
                localVarQueryParameter['deliveryDateFrom'] = (deliveryDateFrom as any instanceof Date) ?
                    (deliveryDateFrom as any).toISOString() :
                    deliveryDateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerFindOneCategory', 'id', id)
            const localVarPath = `/api/v1/Eshop/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneMerchantByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('eshopControllerFindOneMerchantByEmail', 'email', email)
            const localVarPath = `/api/v1/Eshop/merchants/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerFindOneOrder', 'id', id)
            const localVarPath = `/api/v1/Eshop/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneProduct: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerFindOneProduct', 'id', id)
            const localVarPath = `/api/v1/Eshop/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneTag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerFindOneTag', 'id', id)
            const localVarPath = `/api/v1/Eshop/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetAdminConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/admin-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetAllOrderCounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/order-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetAllTransactionCounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/transaction-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetApiField: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerGetApiField', 'id', id)
            const localVarPath = `/api/v1/Eshop/api-fields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetApiFields: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/api-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} merchantEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetOrderCounts: async (merchantEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantEmail' is not null or undefined
            assertParamExists('eshopControllerGetOrderCounts', 'merchantEmail', merchantEmail)
            const localVarPath = `/api/v1/Eshop/order-statistics/{merchantEmail}`
                .replace(`{${"merchantEmail"}}`, encodeURIComponent(String(merchantEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} merchantEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetTransactionCounts: async (merchantEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantEmail' is not null or undefined
            assertParamExists('eshopControllerGetTransactionCounts', 'merchantEmail', merchantEmail)
            const localVarPath = `/api/v1/Eshop/transaction-statistics/{merchantEmail}`
                .replace(`{${"merchantEmail"}}`, encodeURIComponent(String(merchantEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminConfigDto} updateAdminConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateAdminConfig: async (id: string, updateAdminConfigDto: UpdateAdminConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerUpdateAdminConfig', 'id', id)
            // verify required parameter 'updateAdminConfigDto' is not null or undefined
            assertParamExists('eshopControllerUpdateAdminConfig', 'updateAdminConfigDto', updateAdminConfigDto)
            const localVarPath = `/api/v1/Eshop/admin-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateApiFieldsDto} updateApiFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateApiField: async (id: string, updateApiFieldsDto: UpdateApiFieldsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerUpdateApiField', 'id', id)
            // verify required parameter 'updateApiFieldsDto' is not null or undefined
            assertParamExists('eshopControllerUpdateApiField', 'updateApiFieldsDto', updateApiFieldsDto)
            const localVarPath = `/api/v1/Eshop/api-fields/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApiFieldsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Eshop/categories-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDto} updateCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateCategory: async (id: string, updateCategoryDto: UpdateCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerUpdateCategory', 'id', id)
            // verify required parameter 'updateCategoryDto' is not null or undefined
            assertParamExists('eshopControllerUpdateCategory', 'updateCategoryDto', updateCategoryDto)
            const localVarPath = `/api/v1/Eshop/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateMerchantDto} updateMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateMerchant: async (id: string, updateMerchantDto: UpdateMerchantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerUpdateMerchant', 'id', id)
            // verify required parameter 'updateMerchantDto' is not null or undefined
            assertParamExists('eshopControllerUpdateMerchant', 'updateMerchantDto', updateMerchantDto)
            const localVarPath = `/api/v1/Eshop/merchant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMerchantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {UpdateMerchantDto} updateMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateMerchantByEmail: async (email: string, updateMerchantDto: UpdateMerchantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('eshopControllerUpdateMerchantByEmail', 'email', email)
            // verify required parameter 'updateMerchantDto' is not null or undefined
            assertParamExists('eshopControllerUpdateMerchantByEmail', 'updateMerchantDto', updateMerchantDto)
            const localVarPath = `/api/v1/Eshop/merchant/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMerchantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTransactionMerchantDto} updateTransactionMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateOrderStatus: async (id: string, updateTransactionMerchantDto: UpdateTransactionMerchantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerUpdateOrderStatus', 'id', id)
            // verify required parameter 'updateTransactionMerchantDto' is not null or undefined
            assertParamExists('eshopControllerUpdateOrderStatus', 'updateTransactionMerchantDto', updateTransactionMerchantDto)
            const localVarPath = `/api/v1/Eshop/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransactionMerchantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateProduct: async (id: string, updateProductDto: UpdateProductDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerUpdateProduct', 'id', id)
            // verify required parameter 'updateProductDto' is not null or undefined
            assertParamExists('eshopControllerUpdateProduct', 'updateProductDto', updateProductDto)
            const localVarPath = `/api/v1/Eshop/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProductsDto} updateProductsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateProducts: async (updateProductsDto: UpdateProductsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProductsDto' is not null or undefined
            assertParamExists('eshopControllerUpdateProducts', 'updateProductsDto', updateProductsDto)
            const localVarPath = `/api/v1/Eshop/products-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTagDto} updateTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateTag: async (id: string, updateTagDto: UpdateTagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eshopControllerUpdateTag', 'id', id)
            // verify required parameter 'updateTagDto' is not null or undefined
            assertParamExists('eshopControllerUpdateTag', 'updateTagDto', updateTagDto)
            const localVarPath = `/api/v1/Eshop/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EshopApi - functional programming interface
 * @export
 */
export const EshopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EshopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateApiFieldsDto} createApiFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerCreateApiField(createApiFieldsDto: CreateApiFieldsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerCreateApiField(createApiFieldsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCategoryDto} createCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerCreateCategory(createCategoryDto: CreateCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerCreateCategory(createCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMerchantDto} createMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerCreateMerchant(createMerchantDto: CreateMerchantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerCreateMerchant(createMerchantDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerCreateProduct(createProductDto: CreateProductDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerCreateProduct(createProductDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateTagDto} createTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerCreateTag(createTagDto: CreateTagDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerCreateTag(createTagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerDeleteApiField(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerDeleteApiField(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerDeleteCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerDeleteCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerDeleteProduct(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerDeleteProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerDeleteTag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerDeleteTag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [isMwellCategory] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindAllCategories(query?: string, isMwellCategory?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindAllCategories(query, isMwellCategory, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindAllOrders(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindAllOrders(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED'} [status] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [categoryId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindAllProducts(query?: string, merchantEmail?: string, status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED', orderBy?: 'ASC' | 'DESC', categoryId?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindAllProducts(query, merchantEmail, status, orderBy, categoryId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [tagType] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindAllTags(query?: string, tagType?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindAllTags(query, tagType, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindAllTransactionMerchantProducts(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindAllTransactionMerchantProducts(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindAllTransactionMerchants(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindAllTransactionMerchants(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindOneCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindOneCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindOneMerchantByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindOneMerchantByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindOneOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindOneOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindOneProduct(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindOneProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerFindOneTag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerFindOneTag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerGetAdminConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerGetAdminConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerGetAllOrderCounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerGetAllOrderCounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerGetAllTransactionCounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerGetAllTransactionCounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerGetApiField(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerGetApiField(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerGetApiFields(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerGetApiFields(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} merchantEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerGetOrderCounts(merchantEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerGetOrderCounts(merchantEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} merchantEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerGetTransactionCounts(merchantEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerGetTransactionCounts(merchantEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminConfigDto} updateAdminConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateAdminConfig(id: string, updateAdminConfigDto: UpdateAdminConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateAdminConfig(id, updateAdminConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateApiFieldsDto} updateApiFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateApiField(id: string, updateApiFieldsDto: UpdateApiFieldsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateApiField(id, updateApiFieldsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDto} updateCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateCategory(id: string, updateCategoryDto: UpdateCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateCategory(id, updateCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateMerchantDto} updateMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateMerchant(id: string, updateMerchantDto: UpdateMerchantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateMerchant(id, updateMerchantDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {UpdateMerchantDto} updateMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateMerchantByEmail(email: string, updateMerchantDto: UpdateMerchantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateMerchantByEmail(email, updateMerchantDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTransactionMerchantDto} updateTransactionMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateOrderStatus(id: string, updateTransactionMerchantDto: UpdateTransactionMerchantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateOrderStatus(id, updateTransactionMerchantDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateProduct(id: string, updateProductDto: UpdateProductDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateProduct(id, updateProductDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateProductsDto} updateProductsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateProducts(updateProductsDto: UpdateProductsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateProducts(updateProductsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTagDto} updateTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopControllerUpdateTag(id: string, updateTagDto: UpdateTagDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopControllerUpdateTag(id, updateTagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EshopApi - factory interface
 * @export
 */
export const EshopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EshopApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateApiFieldsDto} createApiFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateApiField(createApiFieldsDto: CreateApiFieldsDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerCreateApiField(createApiFieldsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCategoryDto} createCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateCategory(createCategoryDto: CreateCategoryDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerCreateCategory(createCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMerchantDto} createMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateMerchant(createMerchantDto: CreateMerchantDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerCreateMerchant(createMerchantDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateProduct(createProductDto: CreateProductDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerCreateProduct(createProductDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTagDto} createTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerCreateTag(createTagDto: CreateTagDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerCreateTag(createTagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteApiField(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.eshopControllerDeleteApiField(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteCategory(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.eshopControllerDeleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteProduct(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.eshopControllerDeleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerDeleteTag(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.eshopControllerDeleteTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [isMwellCategory] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllCategories(query?: string, isMwellCategory?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindAllCategories(query, isMwellCategory, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllOrders(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindAllOrders(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED'} [status] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [categoryId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllProducts(query?: string, merchantEmail?: string, status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED', orderBy?: 'ASC' | 'DESC', categoryId?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindAllProducts(query, merchantEmail, status, orderBy, categoryId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [tagType] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllTags(query?: string, tagType?: string, limit?: number, page?: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.eshopControllerFindAllTags(query, tagType, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllTransactionMerchantProducts(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindAllTransactionMerchantProducts(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [merchantEmail] 
         * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
         * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
         * @param {'paid' | 'not-paid'} [paymentStatus] 
         * @param {'ASC' | 'DESC'} [orderBy] 
         * @param {string} [dateFrom] 
         * @param {string} [deliveryDateTo] 
         * @param {string} [deliveryDateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [merchantId] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindAllTransactionMerchants(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindAllTransactionMerchants(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneCategory(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindOneCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneMerchantByEmail(email: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindOneMerchantByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneOrder(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindOneOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneProduct(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindOneProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerFindOneTag(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerFindOneTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetAdminConfig(options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerGetAdminConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetAllOrderCounts(options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerGetAllOrderCounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetAllTransactionCounts(options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerGetAllTransactionCounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetApiField(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerGetApiField(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetApiFields(options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerGetApiFields(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} merchantEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetOrderCounts(merchantEmail: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerGetOrderCounts(merchantEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} merchantEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerGetTransactionCounts(merchantEmail: string, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerGetTransactionCounts(merchantEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminConfigDto} updateAdminConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateAdminConfig(id: string, updateAdminConfigDto: UpdateAdminConfigDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateAdminConfig(id, updateAdminConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateApiFieldsDto} updateApiFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateApiField(id: string, updateApiFieldsDto: UpdateApiFieldsDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateApiField(id, updateApiFieldsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateCategories(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.eshopControllerUpdateCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDto} updateCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateCategory(id: string, updateCategoryDto: UpdateCategoryDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateCategory(id, updateCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateMerchantDto} updateMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateMerchant(id: string, updateMerchantDto: UpdateMerchantDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateMerchant(id, updateMerchantDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {UpdateMerchantDto} updateMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateMerchantByEmail(email: string, updateMerchantDto: UpdateMerchantDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateMerchantByEmail(email, updateMerchantDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTransactionMerchantDto} updateTransactionMerchantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateOrderStatus(id: string, updateTransactionMerchantDto: UpdateTransactionMerchantDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateOrderStatus(id, updateTransactionMerchantDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateProduct(id: string, updateProductDto: UpdateProductDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateProduct(id, updateProductDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProductsDto} updateProductsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateProducts(updateProductsDto: UpdateProductsDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.eshopControllerUpdateProducts(updateProductsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTagDto} updateTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopControllerUpdateTag(id: string, updateTagDto: UpdateTagDto, options?: any): AxiosPromise<object> {
            return localVarFp.eshopControllerUpdateTag(id, updateTagDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EshopApi - object-oriented interface
 * @export
 * @class EshopApi
 * @extends {BaseAPI}
 */
export class EshopApi extends BaseAPI {
    /**
     * 
     * @param {CreateApiFieldsDto} createApiFieldsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerCreateApiField(createApiFieldsDto: CreateApiFieldsDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerCreateApiField(createApiFieldsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCategoryDto} createCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerCreateCategory(createCategoryDto: CreateCategoryDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerCreateCategory(createCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMerchantDto} createMerchantDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerCreateMerchant(createMerchantDto: CreateMerchantDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerCreateMerchant(createMerchantDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProductDto} createProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerCreateProduct(createProductDto: CreateProductDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerCreateProduct(createProductDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTagDto} createTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerCreateTag(createTagDto: CreateTagDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerCreateTag(createTagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerDeleteApiField(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerDeleteApiField(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerDeleteCategory(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerDeleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerDeleteProduct(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerDeleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerDeleteTag(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerDeleteTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {string} [isMwellCategory] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindAllCategories(query?: string, isMwellCategory?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindAllCategories(query, isMwellCategory, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {string} [merchantEmail] 
     * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
     * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
     * @param {'paid' | 'not-paid'} [paymentStatus] 
     * @param {'ASC' | 'DESC'} [orderBy] 
     * @param {string} [dateFrom] 
     * @param {string} [deliveryDateTo] 
     * @param {string} [deliveryDateFrom] 
     * @param {string} [dateTo] 
     * @param {string} [merchantId] 
     * @param {string} [productId] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindAllOrders(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindAllOrders(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {string} [merchantEmail] 
     * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED'} [status] 
     * @param {'ASC' | 'DESC'} [orderBy] 
     * @param {string} [categoryId] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindAllProducts(query?: string, merchantEmail?: string, status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'REJECTED', orderBy?: 'ASC' | 'DESC', categoryId?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindAllProducts(query, merchantEmail, status, orderBy, categoryId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {string} [tagType] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindAllTags(query?: string, tagType?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindAllTags(query, tagType, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {string} [merchantEmail] 
     * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
     * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
     * @param {'paid' | 'not-paid'} [paymentStatus] 
     * @param {'ASC' | 'DESC'} [orderBy] 
     * @param {string} [dateFrom] 
     * @param {string} [deliveryDateTo] 
     * @param {string} [deliveryDateFrom] 
     * @param {string} [dateTo] 
     * @param {string} [merchantId] 
     * @param {string} [productId] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindAllTransactionMerchantProducts(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindAllTransactionMerchantProducts(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {string} [merchantEmail] 
     * @param {'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED'} [externalPaymentStatus] 
     * @param {'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed'} [status] 
     * @param {'paid' | 'not-paid'} [paymentStatus] 
     * @param {'ASC' | 'DESC'} [orderBy] 
     * @param {string} [dateFrom] 
     * @param {string} [deliveryDateTo] 
     * @param {string} [deliveryDateFrom] 
     * @param {string} [dateTo] 
     * @param {string} [merchantId] 
     * @param {string} [productId] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindAllTransactionMerchants(query?: string, merchantEmail?: string, externalPaymentStatus?: 'PAYMENT_PENDING' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILED' | 'PAYMENT_EXPIRED' | 'PAYMENT_CANCELLED', status?: 'pending' | 'completed' | 'cancelled' | 'processing' | 'shipped' | 'confirmed', paymentStatus?: 'paid' | 'not-paid', orderBy?: 'ASC' | 'DESC', dateFrom?: string, deliveryDateTo?: string, deliveryDateFrom?: string, dateTo?: string, merchantId?: string, productId?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindAllTransactionMerchants(query, merchantEmail, externalPaymentStatus, status, paymentStatus, orderBy, dateFrom, deliveryDateTo, deliveryDateFrom, dateTo, merchantId, productId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindOneCategory(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindOneCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindOneMerchantByEmail(email: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindOneMerchantByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindOneOrder(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindOneOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindOneProduct(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindOneProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerFindOneTag(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerFindOneTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerGetAdminConfig(options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerGetAdminConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerGetAllOrderCounts(options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerGetAllOrderCounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerGetAllTransactionCounts(options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerGetAllTransactionCounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerGetApiField(id: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerGetApiField(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerGetApiFields(options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerGetApiFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} merchantEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerGetOrderCounts(merchantEmail: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerGetOrderCounts(merchantEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} merchantEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerGetTransactionCounts(merchantEmail: string, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerGetTransactionCounts(merchantEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAdminConfigDto} updateAdminConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateAdminConfig(id: string, updateAdminConfigDto: UpdateAdminConfigDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateAdminConfig(id, updateAdminConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateApiFieldsDto} updateApiFieldsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateApiField(id: string, updateApiFieldsDto: UpdateApiFieldsDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateApiField(id, updateApiFieldsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateCategories(options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCategoryDto} updateCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateCategory(id: string, updateCategoryDto: UpdateCategoryDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateCategory(id, updateCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateMerchantDto} updateMerchantDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateMerchant(id: string, updateMerchantDto: UpdateMerchantDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateMerchant(id, updateMerchantDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {UpdateMerchantDto} updateMerchantDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateMerchantByEmail(email: string, updateMerchantDto: UpdateMerchantDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateMerchantByEmail(email, updateMerchantDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateTransactionMerchantDto} updateTransactionMerchantDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateOrderStatus(id: string, updateTransactionMerchantDto: UpdateTransactionMerchantDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateOrderStatus(id, updateTransactionMerchantDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProductDto} updateProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateProduct(id: string, updateProductDto: UpdateProductDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateProduct(id, updateProductDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProductsDto} updateProductsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateProducts(updateProductsDto: UpdateProductsDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateProducts(updateProductsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateTagDto} updateTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EshopApi
     */
    public eshopControllerUpdateTag(id: string, updateTagDto: UpdateTagDto, options?: AxiosRequestConfig) {
        return EshopApiFp(this.configuration).eshopControllerUpdateTag(id, updateTagDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerCreateFeedback: async (createFeedbackDto: CreateFeedbackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFeedbackDto' is not null or undefined
            assertParamExists('feedbackControllerCreateFeedback', 'createFeedbackDto', createFeedbackDto)
            const localVarPath = `/api/v1/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFeedbackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} branchId 
         * @param {string} [authorId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerGetMultipleFeedback: async (branchId: string, authorId?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('feedbackControllerGetMultipleFeedback', 'branchId', branchId)
            const localVarPath = `/api/v1/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (branchId !== undefined) {
                localVarQueryParameter['branchId'] = branchId;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerCreateFeedback(createFeedbackDto: CreateFeedbackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFeedbackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackControllerCreateFeedback(createFeedbackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} branchId 
         * @param {string} [authorId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerGetMultipleFeedback(branchId: string, authorId?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindFeedbackResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackControllerGetMultipleFeedback(branchId, authorId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerCreateFeedback(createFeedbackDto: CreateFeedbackDto, options?: any): AxiosPromise<CreateFeedbackResponse> {
            return localVarFp.feedbackControllerCreateFeedback(createFeedbackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} branchId 
         * @param {string} [authorId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerGetMultipleFeedback(branchId: string, authorId?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<FindFeedbackResponse>> {
            return localVarFp.feedbackControllerGetMultipleFeedback(branchId, authorId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @param {CreateFeedbackDto} createFeedbackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerCreateFeedback(createFeedbackDto: CreateFeedbackDto, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackControllerCreateFeedback(createFeedbackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} branchId 
     * @param {string} [authorId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerGetMultipleFeedback(branchId: string, authorId?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackControllerGetMultipleFeedback(branchId, authorId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFindAllMedicalRecordOfUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/medical-record/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFindAllMedicalRecordsByAppointmentId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesControllerFindAllMedicalRecordsByAppointmentId', 'id', id)
            const localVarPath = `/api/v1/files/medical-record/appointment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerRemoveMedicalRecordFileById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesControllerRemoveMedicalRecordFileById', 'id', id)
            const localVarPath = `/api/v1/files/medical-record/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadMedicalRecordFile: async (appointmentId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('filesControllerUploadMedicalRecordFile', 'appointmentId', appointmentId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('filesControllerUploadMedicalRecordFile', 'name', name)
            const localVarPath = `/api/v1/files/upload/medical-record`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadOnboardingDocumentFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/onboarding-document/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerFindAllMedicalRecordOfUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedicalRecordFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerFindAllMedicalRecordOfUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerFindAllMedicalRecordsByAppointmentId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedicalRecordFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerFindAllMedicalRecordsByAppointmentId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerRemoveMedicalRecordFileById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerRemoveMedicalRecordFileById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerUploadMedicalRecordFile(appointmentId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalRecordFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerUploadMedicalRecordFile(appointmentId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerUploadOnboardingDocumentFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerUploadOnboardingDocumentFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFindAllMedicalRecordOfUser(options?: any): AxiosPromise<Array<MedicalRecordFile>> {
            return localVarFp.filesControllerFindAllMedicalRecordOfUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFindAllMedicalRecordsByAppointmentId(id: string, options?: any): AxiosPromise<Array<MedicalRecordFile>> {
            return localVarFp.filesControllerFindAllMedicalRecordsByAppointmentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerRemoveMedicalRecordFileById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.filesControllerRemoveMedicalRecordFileById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadMedicalRecordFile(appointmentId: string, name: string, options?: any): AxiosPromise<MedicalRecordFile> {
            return localVarFp.filesControllerUploadMedicalRecordFile(appointmentId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadOnboardingDocumentFile(options?: any): AxiosPromise<any> {
            return localVarFp.filesControllerUploadOnboardingDocumentFile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerFindAllMedicalRecordOfUser(options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerFindAllMedicalRecordOfUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerFindAllMedicalRecordsByAppointmentId(id: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerFindAllMedicalRecordsByAppointmentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerRemoveMedicalRecordFileById(id: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerRemoveMedicalRecordFileById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appointmentId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerUploadMedicalRecordFile(appointmentId: string, name: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerUploadMedicalRecordFile(appointmentId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerUploadOnboardingDocumentFile(options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerUploadOnboardingDocumentFile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HmoTransactionsApi - axios parameter creator
 * @export
 */
export const HmoTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {number} [hmoId] 
         * @param {string} [doctorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoTransactionsControllerExportHmoTransactions: async (month?: number, year?: number, hmoId?: number, doctorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/hmo-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (hmoId !== undefined) {
                localVarQueryParameter['hmoId'] = hmoId;
            }

            if (doctorId !== undefined) {
                localVarQueryParameter['doctorId'] = doctorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HmoTransactionsApi - functional programming interface
 * @export
 */
export const HmoTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HmoTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {number} [hmoId] 
         * @param {string} [doctorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hmoTransactionsControllerExportHmoTransactions(month?: number, year?: number, hmoId?: number, doctorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hmoTransactionsControllerExportHmoTransactions(month, year, hmoId, doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HmoTransactionsApi - factory interface
 * @export
 */
export const HmoTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HmoTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {number} [hmoId] 
         * @param {string} [doctorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoTransactionsControllerExportHmoTransactions(month?: number, year?: number, hmoId?: number, doctorId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.hmoTransactionsControllerExportHmoTransactions(month, year, hmoId, doctorId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HmoTransactionsApi - object-oriented interface
 * @export
 * @class HmoTransactionsApi
 * @extends {BaseAPI}
 */
export class HmoTransactionsApi extends BaseAPI {
    /**
     * 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {number} [hmoId] 
     * @param {string} [doctorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmoTransactionsApi
     */
    public hmoTransactionsControllerExportHmoTransactions(month?: number, year?: number, hmoId?: number, doctorId?: string, options?: AxiosRequestConfig) {
        return HmoTransactionsApiFp(this.configuration).hmoTransactionsControllerExportHmoTransactions(month, year, hmoId, doctorId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HmosApi - axios parameter creator
 * @export
 */
export const HmosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} mwellUserId 
         * @param {string} userHmoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerDelete: async (mwellUserId: string, userHmoId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mwellUserId' is not null or undefined
            assertParamExists('hmoControllerDelete', 'mwellUserId', mwellUserId)
            // verify required parameter 'userHmoId' is not null or undefined
            assertParamExists('hmoControllerDelete', 'userHmoId', userHmoId)
            const localVarPath = `/api/v1/hmos/user/{mwellUserId}/{userHmoId}`
                .replace(`{${"mwellUserId"}}`, encodeURIComponent(String(mwellUserId)))
                .replace(`{${"userHmoId"}}`, encodeURIComponent(String(userHmoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] Optional name filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerGetAllEnrolledHmos: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/hmos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mwellUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerGetMwellUserHmos: async (mwellUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mwellUserId' is not null or undefined
            assertParamExists('hmoControllerGetMwellUserHmos', 'mwellUserId', mwellUserId)
            const localVarPath = `/api/v1/hmos/user/{mwellUserId}`
                .replace(`{${"mwellUserId"}}`, encodeURIComponent(String(mwellUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePatientHmoDto} createPatientHmoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerSavePatientHmo: async (createPatientHmoDto: CreatePatientHmoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPatientHmoDto' is not null or undefined
            assertParamExists('hmoControllerSavePatientHmo', 'createPatientHmoDto', createPatientHmoDto)
            const localVarPath = `/api/v1/hmos/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPatientHmoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidatePatientHmoDto} validatePatientHmoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerValidatePatientHmo: async (validatePatientHmoDto: ValidatePatientHmoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validatePatientHmoDto' is not null or undefined
            assertParamExists('hmoControllerValidatePatientHmo', 'validatePatientHmoDto', validatePatientHmoDto)
            const localVarPath = `/api/v1/hmos/validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePatientHmoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HmosApi - functional programming interface
 * @export
 */
export const HmosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HmosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} mwellUserId 
         * @param {string} userHmoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hmoControllerDelete(mwellUserId: string, userHmoId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hmoControllerDelete(mwellUserId, userHmoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] Optional name filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hmoControllerGetAllEnrolledHmos(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HmoResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hmoControllerGetAllEnrolledHmos(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mwellUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hmoControllerGetMwellUserHmos(mwellUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMwellUserHmoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hmoControllerGetMwellUserHmos(mwellUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePatientHmoDto} createPatientHmoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hmoControllerSavePatientHmo(createPatientHmoDto: CreatePatientHmoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hmoControllerSavePatientHmo(createPatientHmoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidatePatientHmoDto} validatePatientHmoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hmoControllerValidatePatientHmo(validatePatientHmoDto: ValidatePatientHmoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hmoControllerValidatePatientHmo(validatePatientHmoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HmosApi - factory interface
 * @export
 */
export const HmosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HmosApiFp(configuration)
    return {
        /**
         * 
         * @param {string} mwellUserId 
         * @param {string} userHmoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerDelete(mwellUserId: string, userHmoId: string, options?: any): AxiosPromise<object> {
            return localVarFp.hmoControllerDelete(mwellUserId, userHmoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] Optional name filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerGetAllEnrolledHmos(name?: string, options?: any): AxiosPromise<Array<HmoResponseDto>> {
            return localVarFp.hmoControllerGetAllEnrolledHmos(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mwellUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerGetMwellUserHmos(mwellUserId: string, options?: any): AxiosPromise<Array<GetMwellUserHmoDto>> {
            return localVarFp.hmoControllerGetMwellUserHmos(mwellUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePatientHmoDto} createPatientHmoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerSavePatientHmo(createPatientHmoDto: CreatePatientHmoDto, options?: any): AxiosPromise<object> {
            return localVarFp.hmoControllerSavePatientHmo(createPatientHmoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidatePatientHmoDto} validatePatientHmoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hmoControllerValidatePatientHmo(validatePatientHmoDto: ValidatePatientHmoDto, options?: any): AxiosPromise<object> {
            return localVarFp.hmoControllerValidatePatientHmo(validatePatientHmoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HmosApi - object-oriented interface
 * @export
 * @class HmosApi
 * @extends {BaseAPI}
 */
export class HmosApi extends BaseAPI {
    /**
     * 
     * @param {string} mwellUserId 
     * @param {string} userHmoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmosApi
     */
    public hmoControllerDelete(mwellUserId: string, userHmoId: string, options?: AxiosRequestConfig) {
        return HmosApiFp(this.configuration).hmoControllerDelete(mwellUserId, userHmoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] Optional name filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmosApi
     */
    public hmoControllerGetAllEnrolledHmos(name?: string, options?: AxiosRequestConfig) {
        return HmosApiFp(this.configuration).hmoControllerGetAllEnrolledHmos(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mwellUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmosApi
     */
    public hmoControllerGetMwellUserHmos(mwellUserId: string, options?: AxiosRequestConfig) {
        return HmosApiFp(this.configuration).hmoControllerGetMwellUserHmos(mwellUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePatientHmoDto} createPatientHmoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmosApi
     */
    public hmoControllerSavePatientHmo(createPatientHmoDto: CreatePatientHmoDto, options?: AxiosRequestConfig) {
        return HmosApiFp(this.configuration).hmoControllerSavePatientHmo(createPatientHmoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidatePatientHmoDto} validatePatientHmoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmosApi
     */
    public hmoControllerValidatePatientHmo(validatePatientHmoDto: ValidatePatientHmoDto, options?: AxiosRequestConfig) {
        return HmosApiFp(this.configuration).hmoControllerValidatePatientHmo(validatePatientHmoDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageThreadsApi - axios parameter creator
 * @export
 */
export const MessageThreadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerCreateMessage: async (createMessageDto: CreateMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMessageDto' is not null or undefined
            assertParamExists('messageThreadControllerCreateMessage', 'createMessageDto', createMessageDto)
            const localVarPath = `/api/v1/message-threads/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMessageThreadDto} createMessageThreadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerCreateMessageThread: async (createMessageThreadDto: CreateMessageThreadDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMessageThreadDto' is not null or undefined
            assertParamExists('messageThreadControllerCreateMessageThread', 'createMessageThreadDto', createMessageThreadDto)
            const localVarPath = `/api/v1/message-threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageThreadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetMemberMessageThreads: async (branchId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('messageThreadControllerGetMemberMessageThreads', 'branchId', branchId)
            const localVarPath = `/api/v1/message-threads/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (branchId !== undefined) {
                localVarQueryParameter['branchId'] = branchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetMessageThreadByMemberIdsDto} getMessageThreadByMemberIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetMessageThreadByMemberIds: async (getMessageThreadByMemberIdsDto: GetMessageThreadByMemberIdsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getMessageThreadByMemberIdsDto' is not null or undefined
            assertParamExists('messageThreadControllerGetMessageThreadByMemberIds', 'getMessageThreadByMemberIdsDto', getMessageThreadByMemberIdsDto)
            const localVarPath = `/api/v1/message-threads/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMessageThreadByMemberIdsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetMessagesByThreadId: async (threadId: string, branchId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('messageThreadControllerGetMessagesByThreadId', 'threadId', threadId)
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('messageThreadControllerGetMessagesByThreadId', 'branchId', branchId)
            const localVarPath = `/api/v1/message-threads/{threadId}/messages`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (branchId !== undefined) {
                localVarQueryParameter['branchId'] = branchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetThread: async (threadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('messageThreadControllerGetThread', 'threadId', threadId)
            const localVarPath = `/api/v1/message-threads/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerUpdateThreadReadByIdsArray: async (threadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('messageThreadControllerUpdateThreadReadByIdsArray', 'threadId', threadId)
            const localVarPath = `/api/v1/message-threads/{threadId}/read`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageThreadsApi - functional programming interface
 * @export
 */
export const MessageThreadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageThreadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageThreadControllerCreateMessage(createMessageDto: CreateMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageThreadControllerCreateMessage(createMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMessageThreadDto} createMessageThreadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageThreadControllerCreateMessageThread(createMessageThreadDto: CreateMessageThreadDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageThreadControllerCreateMessageThread(createMessageThreadDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageThreadControllerGetMemberMessageThreads(branchId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageThreadControllerGetMemberMessageThreads(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetMessageThreadByMemberIdsDto} getMessageThreadByMemberIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageThreadControllerGetMessageThreadByMemberIds(getMessageThreadByMemberIdsDto: GetMessageThreadByMemberIdsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageThreadControllerGetMessageThreadByMemberIds(getMessageThreadByMemberIdsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageThreadControllerGetMessagesByThreadId(threadId: string, branchId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageThreadControllerGetMessagesByThreadId(threadId, branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageThreadControllerGetThread(threadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageThreadControllerGetThread(threadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageThreadControllerUpdateThreadReadByIdsArray(threadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageThreadControllerUpdateThreadReadByIdsArray(threadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageThreadsApi - factory interface
 * @export
 */
export const MessageThreadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageThreadsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerCreateMessage(createMessageDto: CreateMessageDto, options?: any): AxiosPromise<object> {
            return localVarFp.messageThreadControllerCreateMessage(createMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMessageThreadDto} createMessageThreadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerCreateMessageThread(createMessageThreadDto: CreateMessageThreadDto, options?: any): AxiosPromise<object> {
            return localVarFp.messageThreadControllerCreateMessageThread(createMessageThreadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetMemberMessageThreads(branchId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.messageThreadControllerGetMemberMessageThreads(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetMessageThreadByMemberIdsDto} getMessageThreadByMemberIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetMessageThreadByMemberIds(getMessageThreadByMemberIdsDto: GetMessageThreadByMemberIdsDto, options?: any): AxiosPromise<object> {
            return localVarFp.messageThreadControllerGetMessageThreadByMemberIds(getMessageThreadByMemberIdsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetMessagesByThreadId(threadId: string, branchId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.messageThreadControllerGetMessagesByThreadId(threadId, branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerGetThread(threadId: string, options?: any): AxiosPromise<object> {
            return localVarFp.messageThreadControllerGetThread(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageThreadControllerUpdateThreadReadByIdsArray(threadId: string, options?: any): AxiosPromise<void> {
            return localVarFp.messageThreadControllerUpdateThreadReadByIdsArray(threadId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageThreadsApi - object-oriented interface
 * @export
 * @class MessageThreadsApi
 * @extends {BaseAPI}
 */
export class MessageThreadsApi extends BaseAPI {
    /**
     * 
     * @param {CreateMessageDto} createMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageThreadsApi
     */
    public messageThreadControllerCreateMessage(createMessageDto: CreateMessageDto, options?: AxiosRequestConfig) {
        return MessageThreadsApiFp(this.configuration).messageThreadControllerCreateMessage(createMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMessageThreadDto} createMessageThreadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageThreadsApi
     */
    public messageThreadControllerCreateMessageThread(createMessageThreadDto: CreateMessageThreadDto, options?: AxiosRequestConfig) {
        return MessageThreadsApiFp(this.configuration).messageThreadControllerCreateMessageThread(createMessageThreadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} branchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageThreadsApi
     */
    public messageThreadControllerGetMemberMessageThreads(branchId: string, options?: AxiosRequestConfig) {
        return MessageThreadsApiFp(this.configuration).messageThreadControllerGetMemberMessageThreads(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetMessageThreadByMemberIdsDto} getMessageThreadByMemberIdsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageThreadsApi
     */
    public messageThreadControllerGetMessageThreadByMemberIds(getMessageThreadByMemberIdsDto: GetMessageThreadByMemberIdsDto, options?: AxiosRequestConfig) {
        return MessageThreadsApiFp(this.configuration).messageThreadControllerGetMessageThreadByMemberIds(getMessageThreadByMemberIdsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} branchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageThreadsApi
     */
    public messageThreadControllerGetMessagesByThreadId(threadId: string, branchId: string, options?: AxiosRequestConfig) {
        return MessageThreadsApiFp(this.configuration).messageThreadControllerGetMessagesByThreadId(threadId, branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageThreadsApi
     */
    public messageThreadControllerGetThread(threadId: string, options?: AxiosRequestConfig) {
        return MessageThreadsApiFp(this.configuration).messageThreadControllerGetThread(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageThreadsApi
     */
    public messageThreadControllerUpdateThreadReadByIdsArray(threadId: string, options?: AxiosRequestConfig) {
        return MessageThreadsApiFp(this.configuration).messageThreadControllerUpdateThreadReadByIdsArray(threadId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationControllerMarkAsRead', 'id', id)
            const localVarPath = `/api/v1/notification/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerMarkAsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerMarkAsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAsRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerMarkAsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationControllerMarkAsRead(id: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationControllerMarkAsRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnersApi - axios parameter creator
 * @export
 */
export const PartnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePartnerAppointmentDto} createPartnerAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerControllerCreatePartnerAppointment: async (createPartnerAppointmentDto: CreatePartnerAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPartnerAppointmentDto' is not null or undefined
            assertParamExists('partnerControllerCreatePartnerAppointment', 'createPartnerAppointmentDto', createPartnerAppointmentDto)
            const localVarPath = `/api/v1/partners/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPartnerAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersApi - functional programming interface
 * @export
 */
export const PartnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePartnerAppointmentDto} createPartnerAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerControllerCreatePartnerAppointment(createPartnerAppointmentDto: CreatePartnerAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerControllerCreatePartnerAppointment(createPartnerAppointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnersApi - factory interface
 * @export
 */
export const PartnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePartnerAppointmentDto} createPartnerAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerControllerCreatePartnerAppointment(createPartnerAppointmentDto: CreatePartnerAppointmentDto, options?: any): AxiosPromise<Appointment> {
            return localVarFp.partnerControllerCreatePartnerAppointment(createPartnerAppointmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnersApi - object-oriented interface
 * @export
 * @class PartnersApi
 * @extends {BaseAPI}
 */
export class PartnersApi extends BaseAPI {
    /**
     * 
     * @param {CreatePartnerAppointmentDto} createPartnerAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnerControllerCreatePartnerAppointment(createPartnerAppointmentDto: CreatePartnerAppointmentDto, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).partnerControllerCreatePartnerAppointment(createPartnerAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePaymentDTO} createPaymentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerCreateCheckout: async (createPaymentDTO: CreatePaymentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentDTO' is not null or undefined
            assertParamExists('paymentControllerCreateCheckout', 'createPaymentDTO', createPaymentDTO)
            const localVarPath = `/api/v1/payment/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerOnPaymentExpiredHook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment/webhook/expired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerOnPaymentFailedHook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment/webhook/failed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerOnPaymentSuccessHook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment/webhook/success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentDTO} createPaymentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerCreateCheckout(createPaymentDTO: CreatePaymentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerCreateCheckout(createPaymentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerOnPaymentExpiredHook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerOnPaymentExpiredHook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerOnPaymentFailedHook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerOnPaymentFailedHook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerOnPaymentSuccessHook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerOnPaymentSuccessHook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentDTO} createPaymentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerCreateCheckout(createPaymentDTO: CreatePaymentDTO, options?: any): AxiosPromise<CheckoutResponse> {
            return localVarFp.paymentControllerCreateCheckout(createPaymentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerOnPaymentExpiredHook(options?: any): AxiosPromise<Transaction> {
            return localVarFp.paymentControllerOnPaymentExpiredHook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerOnPaymentFailedHook(options?: any): AxiosPromise<Transaction> {
            return localVarFp.paymentControllerOnPaymentFailedHook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerOnPaymentSuccessHook(options?: any): AxiosPromise<Transaction> {
            return localVarFp.paymentControllerOnPaymentSuccessHook(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @param {CreatePaymentDTO} createPaymentDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerCreateCheckout(createPaymentDTO: CreatePaymentDTO, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentControllerCreateCheckout(createPaymentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerOnPaymentExpiredHook(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentControllerOnPaymentExpiredHook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerOnPaymentFailedHook(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentControllerOnPaymentFailedHook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerOnPaymentSuccessHook(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentControllerOnPaymentSuccessHook(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionApi - axios parameter creator
 * @export
 */
export const PromotionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePromotionDto} createPromotionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreate: async (createPromotionDto: CreatePromotionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPromotionDto' is not null or undefined
            assertParamExists('promotionControllerCreate', 'createPromotionDto', createPromotionDto)
            const localVarPath = `/api/v1/promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPromotionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindAllPublishedPromotions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/promotion/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/promotion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerRemove', 'id', id)
            const localVarPath = `/api/v1/promotion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePromotionDto} updatePromotionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdate: async (id: string, updatePromotionDto: UpdatePromotionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerUpdate', 'id', id)
            // verify required parameter 'updatePromotionDto' is not null or undefined
            assertParamExists('promotionControllerUpdate', 'updatePromotionDto', updatePromotionDto)
            const localVarPath = `/api/v1/promotion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePromotionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionApi - functional programming interface
 * @export
 */
export const PromotionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePromotionDto} createPromotionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerCreate(createPromotionDto: CreatePromotionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerCreate(createPromotionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Promotion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerFindAllPublishedPromotions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromotionWithProvidersDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerFindAllPublishedPromotions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionWithProvidersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePromotionDto} updatePromotionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdate(id: string, updatePromotionDto: UpdatePromotionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdate(id, updatePromotionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionApi - factory interface
 * @export
 */
export const PromotionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePromotionDto} createPromotionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreate(createPromotionDto: CreatePromotionDto, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerCreate(createPromotionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindAll(options?: any): AxiosPromise<Array<Promotion>> {
            return localVarFp.promotionControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindAllPublishedPromotions(options?: any): AxiosPromise<Array<PromotionWithProvidersDto>> {
            return localVarFp.promotionControllerFindAllPublishedPromotions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindOne(id: string, options?: any): AxiosPromise<PromotionWithProvidersDto> {
            return localVarFp.promotionControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.promotionControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePromotionDto} updatePromotionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdate(id: string, updatePromotionDto: UpdatePromotionDto, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdate(id, updatePromotionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionApi - object-oriented interface
 * @export
 * @class PromotionApi
 * @extends {BaseAPI}
 */
export class PromotionApi extends BaseAPI {
    /**
     * 
     * @param {CreatePromotionDto} createPromotionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerCreate(createPromotionDto: CreatePromotionDto, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerCreate(createPromotionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerFindAll(options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerFindAllPublishedPromotions(options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerFindAllPublishedPromotions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerRemove(id: string, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdatePromotionDto} updatePromotionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerUpdate(id: string, updatePromotionDto: UpdatePromotionDto, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerUpdate(id, updatePromotionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerApproveOnboarding: async (id: number, updateProviderDto: UpdateProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerApproveOnboarding', 'id', id)
            // verify required parameter 'updateProviderDto' is not null or undefined
            assertParamExists('providerControllerApproveOnboarding', 'updateProviderDto', updateProviderDto)
            const localVarPath = `/api/v1/provider/{id}/onboarding-progression/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProviderDto} createProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreate: async (createProviderDto: CreateProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderDto' is not null or undefined
            assertParamExists('providerControllerCreate', 'createProviderDto', createProviderDto)
            const localVarPath = `/api/v1/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProviderBranchDto} createProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreateBranch: async (createProviderBranchDto: CreateProviderBranchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderBranchDto' is not null or undefined
            assertParamExists('providerControllerCreateBranch', 'createProviderBranchDto', createProviderBranchDto)
            const localVarPath = `/api/v1/provider/branch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreateOnboardingSampleAppointment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider/onboarding-progression/appointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<string>} [providerTypeIds] 
         * @param {string} [providerId] 
         * @param {'BRAND' | 'BRANCH'} [listType] 
         * @param {'yes' | 'no'} [fetchingReferrals] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {Array<string>} [hmoFilters] 
         * @param {'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE'} [status] 
         * @param {string} [isMphProvider] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindAll: async (query?: string, providerTypeId?: string, providerTypeIds?: Array<string>, providerId?: string, listType?: 'BRAND' | 'BRANCH', fetchingReferrals?: 'yes' | 'no', longitude?: number, latitude?: number, hmoFilters?: Array<string>, status?: 'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE', isMphProvider?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (providerTypeIds) {
                localVarQueryParameter['providerTypeIds'] = providerTypeIds;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (listType !== undefined) {
                localVarQueryParameter['listType'] = listType;
            }

            if (fetchingReferrals !== undefined) {
                localVarQueryParameter['fetchingReferrals'] = fetchingReferrals;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (hmoFilters) {
                localVarQueryParameter['hmoFilters'] = hmoFilters;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isMphProvider !== undefined) {
                localVarQueryParameter['isMphProvider'] = isMphProvider;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindNotifications: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerFindNotifications', 'id', id)
            const localVarPath = `/api/v1/provider/{id}/notifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} [excludes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindOne: async (id: number, excludes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (excludes) {
                localVarQueryParameter['excludes'] = excludes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindOneBySlugName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('providerControllerFindOneBySlugName', 'name', name)
            const localVarPath = `/api/v1/provider/slug/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [query] 
         * @param {string} [sex] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindPatients: async (id: number, query?: string, sex?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerFindPatients', 'id', id)
            const localVarPath = `/api/v1/provider/{id}/patients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindProviderUsers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerFindProviderUsers', 'id', id)
            const localVarPath = `/api/v1/provider/{id}/provider-users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindUnreadNotificationCountByType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerFindUnreadNotificationCountByType', 'id', id)
            const localVarPath = `/api/v1/provider/{id}/notifications/unread/count-by-type`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerGetOnboardingAgreements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider/onboarding-progression/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerGetProviderCounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerGetProviderOnboardingDocuments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider/onboarding-progression/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerMarkAllNotificationsAsRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider/notifications/mark-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProviderBranchDto} createProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerOnboardBranch: async (id: string, createProviderBranchDto: CreateProviderBranchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerOnboardBranch', 'id', id)
            // verify required parameter 'createProviderBranchDto' is not null or undefined
            assertParamExists('providerControllerOnboardBranch', 'createProviderBranchDto', createProviderBranchDto)
            const localVarPath = `/api/v1/provider/{id}/onboard/branch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OnboardProviderDto} onboardProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerOnboardProvider: async (id: string, onboardProviderDto: OnboardProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerOnboardProvider', 'id', id)
            // verify required parameter 'onboardProviderDto' is not null or undefined
            assertParamExists('providerControllerOnboardProvider', 'onboardProviderDto', onboardProviderDto)
            const localVarPath = `/api/v1/provider/{id}/onboard/provider`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onboardProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RejectSelfOnboardingDto} rejectSelfOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerRejectOnboarding: async (id: number, rejectSelfOnboardingDto: RejectSelfOnboardingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerRejectOnboarding', 'id', id)
            // verify required parameter 'rejectSelfOnboardingDto' is not null or undefined
            assertParamExists('providerControllerRejectOnboarding', 'rejectSelfOnboardingDto', rejectSelfOnboardingDto)
            const localVarPath = `/api/v1/provider/{id}/onboarding-progression/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectSelfOnboardingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RequestResubmitSelfOnboardingDto} requestResubmitSelfOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerResubmitOnboarding: async (id: number, requestResubmitSelfOnboardingDto: RequestResubmitSelfOnboardingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerResubmitOnboarding', 'id', id)
            // verify required parameter 'requestResubmitSelfOnboardingDto' is not null or undefined
            assertParamExists('providerControllerResubmitOnboarding', 'requestResubmitSelfOnboardingDto', requestResubmitSelfOnboardingDto)
            const localVarPath = `/api/v1/provider/{id}/onboarding-progression/resubmit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestResubmitSelfOnboardingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SelfOnboardDto} selfOnboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerSelfOnboard: async (selfOnboardDto: SelfOnboardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selfOnboardDto' is not null or undefined
            assertParamExists('providerControllerSelfOnboard', 'selfOnboardDto', selfOnboardDto)
            const localVarPath = `/api/v1/provider/self-onboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selfOnboardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SuggestProviderDto} suggestProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerSuggestProvider: async (suggestProviderDto: SuggestProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'suggestProviderDto' is not null or undefined
            assertParamExists('providerControllerSuggestProvider', 'suggestProviderDto', suggestProviderDto)
            const localVarPath = `/api/v1/provider/suggest-provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerToggleAutoApproveAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerToggleAutoApproveAppointment', 'id', id)
            const localVarPath = `/api/v1/provider/{id}/toggle-auto-approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdate: async (id: number, updateProviderDto: UpdateProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerUpdate', 'id', id)
            // verify required parameter 'updateProviderDto' is not null or undefined
            assertParamExists('providerControllerUpdate', 'updateProviderDto', updateProviderDto)
            const localVarPath = `/api/v1/provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderBranchDto} updateProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateBranch: async (id: number, updateProviderBranchDto: UpdateProviderBranchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerUpdateBranch', 'id', id)
            // verify required parameter 'updateProviderBranchDto' is not null or undefined
            assertParamExists('providerControllerUpdateBranch', 'updateProviderBranchDto', updateProviderBranchDto)
            const localVarPath = `/api/v1/provider/branch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderBranchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateMerchantAgreement: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerUpdateMerchantAgreement', 'id', id)
            const localVarPath = `/api/v1/provider/{id}/merchant-agreement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateOnboardingProgression: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider/onboarding-progression/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOnboardingDocumentsDto} updateOnboardingDocumentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateOnboardingProgressionDocuments: async (updateOnboardingDocumentsDto: UpdateOnboardingDocumentsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOnboardingDocumentsDto' is not null or undefined
            assertParamExists('providerControllerUpdateOnboardingProgressionDocuments', 'updateOnboardingDocumentsDto', updateOnboardingDocumentsDto)
            const localVarPath = `/api/v1/provider/onboarding-progression/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOnboardingDocumentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderCustomFieldsDto} updateProviderCustomFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateProviderCustomFields: async (id: number, updateProviderCustomFieldsDto: UpdateProviderCustomFieldsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerUpdateProviderCustomFields', 'id', id)
            // verify required parameter 'updateProviderCustomFieldsDto' is not null or undefined
            assertParamExists('providerControllerUpdateProviderCustomFields', 'updateProviderCustomFieldsDto', updateProviderCustomFieldsDto)
            const localVarPath = `/api/v1/provider/{id}/settings/custom-fields`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderCustomFieldsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderSettingsDto} updateProviderSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateProviderSettings: async (id: number, updateProviderSettingsDto: UpdateProviderSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerUpdateProviderSettings', 'id', id)
            // verify required parameter 'updateProviderSettingsDto' is not null or undefined
            assertParamExists('providerControllerUpdateProviderSettings', 'updateProviderSettingsDto', updateProviderSettingsDto)
            const localVarPath = `/api/v1/provider/{id}/settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerV2ControllerFindOne', 'id', id)
            const localVarPath = `/api/v2/provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindOneBySlugName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('providerV2ControllerFindOneBySlugName', 'name', name)
            const localVarPath = `/api/v2/provider/slug/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindServiceCategories: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerV2ControllerFindServiceCategories', 'id', id)
            const localVarPath = `/api/v2/provider/{id}/service-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [query] 
         * @param {string} [serviceCategoryId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindServices: async (id: number, query?: string, serviceCategoryId?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerV2ControllerFindServices', 'id', id)
            const localVarPath = `/api/v2/provider/{id}/services`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (serviceCategoryId !== undefined) {
                localVarQueryParameter['serviceCategoryId'] = serviceCategoryId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerApproveOnboarding(id: number, updateProviderDto: UpdateProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerApproveOnboarding(id, updateProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProviderDto} createProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerCreate(createProviderDto: CreateProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerCreate(createProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProviderBranchDto} createProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerCreateBranch(createProviderBranchDto: CreateProviderBranchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerCreateBranch(createProviderBranchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerCreateOnboardingSampleAppointment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerCreateOnboardingSampleAppointment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<string>} [providerTypeIds] 
         * @param {string} [providerId] 
         * @param {'BRAND' | 'BRANCH'} [listType] 
         * @param {'yes' | 'no'} [fetchingReferrals] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {Array<string>} [hmoFilters] 
         * @param {'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE'} [status] 
         * @param {string} [isMphProvider] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerFindAll(query?: string, providerTypeId?: string, providerTypeIds?: Array<string>, providerId?: string, listType?: 'BRAND' | 'BRANCH', fetchingReferrals?: 'yes' | 'no', longitude?: number, latitude?: number, hmoFilters?: Array<string>, status?: 'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE', isMphProvider?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerFindAll(query, providerTypeId, providerTypeIds, providerId, listType, fetchingReferrals, longitude, latitude, hmoFilters, status, isMphProvider, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerFindNotifications(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerFindNotifications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} [excludes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerFindOne(id: number, excludes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerFindOne(id, excludes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerFindOneBySlugName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerFindOneBySlugName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [query] 
         * @param {string} [sex] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerFindPatients(id: number, query?: string, sex?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerFindPatients(id, query, sex, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerFindProviderUsers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerFindProviderUsers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerFindUnreadNotificationCountByType(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviderUnreadNotifCountByTypeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerFindUnreadNotificationCountByType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerGetOnboardingAgreements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingAgreementDocumentsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerGetOnboardingAgreements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerGetProviderCounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerGetProviderCounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerGetProviderOnboardingDocuments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderOnboardingProgression>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerGetProviderOnboardingDocuments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerMarkAllNotificationsAsRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerMarkAllNotificationsAsRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProviderBranchDto} createProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerOnboardBranch(id: string, createProviderBranchDto: CreateProviderBranchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerOnboardBranch(id, createProviderBranchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {OnboardProviderDto} onboardProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerOnboardProvider(id: string, onboardProviderDto: OnboardProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerOnboardProvider(id, onboardProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RejectSelfOnboardingDto} rejectSelfOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerRejectOnboarding(id: number, rejectSelfOnboardingDto: RejectSelfOnboardingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerRejectOnboarding(id, rejectSelfOnboardingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RequestResubmitSelfOnboardingDto} requestResubmitSelfOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerResubmitOnboarding(id: number, requestResubmitSelfOnboardingDto: RequestResubmitSelfOnboardingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerResubmitOnboarding(id, requestResubmitSelfOnboardingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SelfOnboardDto} selfOnboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerSelfOnboard(selfOnboardDto: SelfOnboardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerSelfOnboard(selfOnboardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SuggestProviderDto} suggestProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerSuggestProvider(suggestProviderDto: SuggestProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerSuggestProvider(suggestProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerToggleAutoApproveAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerToggleAutoApproveAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdate(id: number, updateProviderDto: UpdateProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdate(id, updateProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderBranchDto} updateProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdateBranch(id: number, updateProviderBranchDto: UpdateProviderBranchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdateBranch(id, updateProviderBranchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdateMerchantAgreement(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdateMerchantAgreement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdateOnboardingProgression(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdateOnboardingProgression(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateOnboardingDocumentsDto} updateOnboardingDocumentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdateOnboardingProgressionDocuments(updateOnboardingDocumentsDto: UpdateOnboardingDocumentsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdateOnboardingProgressionDocuments(updateOnboardingDocumentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderCustomFieldsDto} updateProviderCustomFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdateProviderCustomFields(id: number, updateProviderCustomFieldsDto: UpdateProviderCustomFieldsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdateProviderCustomFields(id, updateProviderCustomFieldsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderSettingsDto} updateProviderSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdateProviderSettings(id: number, updateProviderSettingsDto: UpdateProviderSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdateProviderSettings(id, updateProviderSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerV2ControllerFindOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerV2ControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerV2ControllerFindOneBySlugName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerV2ControllerFindOneBySlugName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerV2ControllerFindServiceCategories(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerV2ControllerFindServiceCategories(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [query] 
         * @param {string} [serviceCategoryId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerV2ControllerFindServices(id: number, query?: string, serviceCategoryId?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerV2ControllerFindServices(id, query, serviceCategoryId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerApproveOnboarding(id: number, updateProviderDto: UpdateProviderDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerApproveOnboarding(id, updateProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProviderDto} createProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreate(createProviderDto: CreateProviderDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerCreate(createProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProviderBranchDto} createProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreateBranch(createProviderBranchDto: CreateProviderBranchDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerCreateBranch(createProviderBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreateOnboardingSampleAppointment(options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerCreateOnboardingSampleAppointment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {string} [providerTypeId] 
         * @param {Array<string>} [providerTypeIds] 
         * @param {string} [providerId] 
         * @param {'BRAND' | 'BRANCH'} [listType] 
         * @param {'yes' | 'no'} [fetchingReferrals] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {Array<string>} [hmoFilters] 
         * @param {'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE'} [status] 
         * @param {string} [isMphProvider] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindAll(query?: string, providerTypeId?: string, providerTypeIds?: Array<string>, providerId?: string, listType?: 'BRAND' | 'BRANCH', fetchingReferrals?: 'yes' | 'no', longitude?: number, latitude?: number, hmoFilters?: Array<string>, status?: 'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE', isMphProvider?: string, limit?: number, page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerFindAll(query, providerTypeId, providerTypeIds, providerId, listType, fetchingReferrals, longitude, latitude, hmoFilters, status, isMphProvider, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindNotifications(id: number, options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.providerControllerFindNotifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} [excludes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindOne(id: number, excludes?: Array<string>, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerFindOne(id, excludes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindOneBySlugName(name: string, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerFindOneBySlugName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [query] 
         * @param {string} [sex] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindPatients(id: number, query?: string, sex?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.providerControllerFindPatients(id, query, sex, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindProviderUsers(id: number, options?: any): AxiosPromise<Array<UserProvider>> {
            return localVarFp.providerControllerFindProviderUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerFindUnreadNotificationCountByType(id: number, options?: any): AxiosPromise<GetProviderUnreadNotifCountByTypeResponseDto> {
            return localVarFp.providerControllerFindUnreadNotificationCountByType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerGetOnboardingAgreements(options?: any): AxiosPromise<OnboardingAgreementDocumentsDto> {
            return localVarFp.providerControllerGetOnboardingAgreements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerGetProviderCounts(options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerGetProviderCounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerGetProviderOnboardingDocuments(options?: any): AxiosPromise<ProviderOnboardingProgression> {
            return localVarFp.providerControllerGetProviderOnboardingDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerMarkAllNotificationsAsRead(options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerMarkAllNotificationsAsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProviderBranchDto} createProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerOnboardBranch(id: string, createProviderBranchDto: CreateProviderBranchDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerOnboardBranch(id, createProviderBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {OnboardProviderDto} onboardProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerOnboardProvider(id: string, onboardProviderDto: OnboardProviderDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerOnboardProvider(id, onboardProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RejectSelfOnboardingDto} rejectSelfOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerRejectOnboarding(id: number, rejectSelfOnboardingDto: RejectSelfOnboardingDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerRejectOnboarding(id, rejectSelfOnboardingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RequestResubmitSelfOnboardingDto} requestResubmitSelfOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerResubmitOnboarding(id: number, requestResubmitSelfOnboardingDto: RequestResubmitSelfOnboardingDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerResubmitOnboarding(id, requestResubmitSelfOnboardingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SelfOnboardDto} selfOnboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerSelfOnboard(selfOnboardDto: SelfOnboardDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerSelfOnboard(selfOnboardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SuggestProviderDto} suggestProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerSuggestProvider(suggestProviderDto: SuggestProviderDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerSuggestProvider(suggestProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerToggleAutoApproveAppointment(id: string, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerToggleAutoApproveAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdate(id: number, updateProviderDto: UpdateProviderDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerUpdate(id, updateProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderBranchDto} updateProviderBranchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateBranch(id: number, updateProviderBranchDto: UpdateProviderBranchDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerUpdateBranch(id, updateProviderBranchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateMerchantAgreement(id: number, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerUpdateMerchantAgreement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateOnboardingProgression(options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerUpdateOnboardingProgression(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOnboardingDocumentsDto} updateOnboardingDocumentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateOnboardingProgressionDocuments(updateOnboardingDocumentsDto: UpdateOnboardingDocumentsDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerUpdateOnboardingProgressionDocuments(updateOnboardingDocumentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderCustomFieldsDto} updateProviderCustomFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateProviderCustomFields(id: number, updateProviderCustomFieldsDto: UpdateProviderCustomFieldsDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerUpdateProviderCustomFields(id, updateProviderCustomFieldsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProviderSettingsDto} updateProviderSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateProviderSettings(id: number, updateProviderSettingsDto: UpdateProviderSettingsDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerControllerUpdateProviderSettings(id, updateProviderSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindOne(id: number, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerV2ControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindOneBySlugName(name: string, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerV2ControllerFindOneBySlugName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindServiceCategories(id: number, options?: any): AxiosPromise<Array<EntityType>> {
            return localVarFp.providerV2ControllerFindServiceCategories(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [query] 
         * @param {string} [serviceCategoryId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerV2ControllerFindServices(id: number, query?: string, serviceCategoryId?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.providerV2ControllerFindServices(id, query, serviceCategoryId, limit, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {UpdateProviderDto} updateProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerApproveOnboarding(id: number, updateProviderDto: UpdateProviderDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerApproveOnboarding(id, updateProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProviderDto} createProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerCreate(createProviderDto: CreateProviderDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerCreate(createProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProviderBranchDto} createProviderBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerCreateBranch(createProviderBranchDto: CreateProviderBranchDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerCreateBranch(createProviderBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerCreateOnboardingSampleAppointment(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerCreateOnboardingSampleAppointment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {string} [providerTypeId] 
     * @param {Array<string>} [providerTypeIds] 
     * @param {string} [providerId] 
     * @param {'BRAND' | 'BRANCH'} [listType] 
     * @param {'yes' | 'no'} [fetchingReferrals] 
     * @param {number} [longitude] 
     * @param {number} [latitude] 
     * @param {Array<string>} [hmoFilters] 
     * @param {'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE'} [status] 
     * @param {string} [isMphProvider] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerFindAll(query?: string, providerTypeId?: string, providerTypeIds?: Array<string>, providerId?: string, listType?: 'BRAND' | 'BRANCH', fetchingReferrals?: 'yes' | 'no', longitude?: number, latitude?: number, hmoFilters?: Array<string>, status?: 'PUBLISHED' | 'UNPUBLISHED' | 'INACTIVE' | 'ACTIVE', isMphProvider?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerFindAll(query, providerTypeId, providerTypeIds, providerId, listType, fetchingReferrals, longitude, latitude, hmoFilters, status, isMphProvider, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerFindNotifications(id: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerFindNotifications(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} [excludes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerFindOne(id: number, excludes?: Array<string>, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerFindOne(id, excludes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerFindOneBySlugName(name: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerFindOneBySlugName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [query] 
     * @param {string} [sex] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerFindPatients(id: number, query?: string, sex?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerFindPatients(id, query, sex, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerFindProviderUsers(id: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerFindProviderUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerFindUnreadNotificationCountByType(id: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerFindUnreadNotificationCountByType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerGetOnboardingAgreements(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerGetOnboardingAgreements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerGetProviderCounts(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerGetProviderCounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerGetProviderOnboardingDocuments(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerGetProviderOnboardingDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerMarkAllNotificationsAsRead(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerMarkAllNotificationsAsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateProviderBranchDto} createProviderBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerOnboardBranch(id: string, createProviderBranchDto: CreateProviderBranchDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerOnboardBranch(id, createProviderBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {OnboardProviderDto} onboardProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerOnboardProvider(id: string, onboardProviderDto: OnboardProviderDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerOnboardProvider(id, onboardProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RejectSelfOnboardingDto} rejectSelfOnboardingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerRejectOnboarding(id: number, rejectSelfOnboardingDto: RejectSelfOnboardingDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerRejectOnboarding(id, rejectSelfOnboardingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RequestResubmitSelfOnboardingDto} requestResubmitSelfOnboardingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerResubmitOnboarding(id: number, requestResubmitSelfOnboardingDto: RequestResubmitSelfOnboardingDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerResubmitOnboarding(id, requestResubmitSelfOnboardingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SelfOnboardDto} selfOnboardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerSelfOnboard(selfOnboardDto: SelfOnboardDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerSelfOnboard(selfOnboardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuggestProviderDto} suggestProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerSuggestProvider(suggestProviderDto: SuggestProviderDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerSuggestProvider(suggestProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerToggleAutoApproveAppointment(id: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerToggleAutoApproveAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateProviderDto} updateProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerUpdate(id: number, updateProviderDto: UpdateProviderDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerUpdate(id, updateProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateProviderBranchDto} updateProviderBranchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerUpdateBranch(id: number, updateProviderBranchDto: UpdateProviderBranchDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerUpdateBranch(id, updateProviderBranchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerUpdateMerchantAgreement(id: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerUpdateMerchantAgreement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerUpdateOnboardingProgression(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerUpdateOnboardingProgression(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOnboardingDocumentsDto} updateOnboardingDocumentsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerUpdateOnboardingProgressionDocuments(updateOnboardingDocumentsDto: UpdateOnboardingDocumentsDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerUpdateOnboardingProgressionDocuments(updateOnboardingDocumentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateProviderCustomFieldsDto} updateProviderCustomFieldsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerUpdateProviderCustomFields(id: number, updateProviderCustomFieldsDto: UpdateProviderCustomFieldsDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerUpdateProviderCustomFields(id, updateProviderCustomFieldsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateProviderSettingsDto} updateProviderSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerControllerUpdateProviderSettings(id: number, updateProviderSettingsDto: UpdateProviderSettingsDto, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerControllerUpdateProviderSettings(id, updateProviderSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerV2ControllerFindOne(id: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerV2ControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerV2ControllerFindOneBySlugName(name: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerV2ControllerFindOneBySlugName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerV2ControllerFindServiceCategories(id: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerV2ControllerFindServiceCategories(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [query] 
     * @param {string} [serviceCategoryId] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerV2ControllerFindServices(id: number, query?: string, serviceCategoryId?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerV2ControllerFindServices(id, query, serviceCategoryId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderClientNotesApi - axios parameter creator
 * @export
 */
export const ProviderClientNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProviderClientNotesDto} createProviderClientNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerCreate: async (createProviderClientNotesDto: CreateProviderClientNotesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderClientNotesDto' is not null or undefined
            assertParamExists('providerClientNotesControllerCreate', 'createProviderClientNotesDto', createProviderClientNotesDto)
            const localVarPath = `/api/v1/provider-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderClientNotesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerClientNotesControllerDelete', 'id', id)
            const localVarPath = `/api/v1/provider-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerClientNotesControllerFind', 'id', id)
            const localVarPath = `/api/v1/provider-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [query] 
         * @param {number} [providerClientProfileId] 
         * @param {number} [appointmentId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerFindProviderClientNotes: async (startDate?: string, endDate?: string, query?: string, providerClientProfileId?: number, appointmentId?: number, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (providerClientProfileId !== undefined) {
                localVarQueryParameter['providerClientProfileId'] = providerClientProfileId;
            }

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProviderClientNotesDto} updateProviderClientNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerUpdate: async (updateProviderClientNotesDto: UpdateProviderClientNotesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProviderClientNotesDto' is not null or undefined
            assertParamExists('providerClientNotesControllerUpdate', 'updateProviderClientNotesDto', updateProviderClientNotesDto)
            const localVarPath = `/api/v1/provider-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderClientNotesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderClientNotesApi - functional programming interface
 * @export
 */
export const ProviderClientNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderClientNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProviderClientNotesDto} createProviderClientNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientNotesControllerCreate(createProviderClientNotesDto: CreateProviderClientNotesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderClientNotes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientNotesControllerCreate(createProviderClientNotesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientNotesControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientNotesControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientNotesControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderClientNotes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientNotesControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [query] 
         * @param {number} [providerClientProfileId] 
         * @param {number} [appointmentId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientNotesControllerFindProviderClientNotes(startDate?: string, endDate?: string, query?: string, providerClientProfileId?: number, appointmentId?: number, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientNotesControllerFindProviderClientNotes(startDate, endDate, query, providerClientProfileId, appointmentId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateProviderClientNotesDto} updateProviderClientNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientNotesControllerUpdate(updateProviderClientNotesDto: UpdateProviderClientNotesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderClientNotes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientNotesControllerUpdate(updateProviderClientNotesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderClientNotesApi - factory interface
 * @export
 */
export const ProviderClientNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderClientNotesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProviderClientNotesDto} createProviderClientNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerCreate(createProviderClientNotesDto: CreateProviderClientNotesDto, options?: any): AxiosPromise<ProviderClientNotes> {
            return localVarFp.providerClientNotesControllerCreate(createProviderClientNotesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerClientNotesControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerFind(id: string, options?: any): AxiosPromise<ProviderClientNotes> {
            return localVarFp.providerClientNotesControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [query] 
         * @param {number} [providerClientProfileId] 
         * @param {number} [appointmentId] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerFindProviderClientNotes(startDate?: string, endDate?: string, query?: string, providerClientProfileId?: number, appointmentId?: number, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.providerClientNotesControllerFindProviderClientNotes(startDate, endDate, query, providerClientProfileId, appointmentId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProviderClientNotesDto} updateProviderClientNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientNotesControllerUpdate(updateProviderClientNotesDto: UpdateProviderClientNotesDto, options?: any): AxiosPromise<ProviderClientNotes> {
            return localVarFp.providerClientNotesControllerUpdate(updateProviderClientNotesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderClientNotesApi - object-oriented interface
 * @export
 * @class ProviderClientNotesApi
 * @extends {BaseAPI}
 */
export class ProviderClientNotesApi extends BaseAPI {
    /**
     * 
     * @param {CreateProviderClientNotesDto} createProviderClientNotesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientNotesApi
     */
    public providerClientNotesControllerCreate(createProviderClientNotesDto: CreateProviderClientNotesDto, options?: AxiosRequestConfig) {
        return ProviderClientNotesApiFp(this.configuration).providerClientNotesControllerCreate(createProviderClientNotesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientNotesApi
     */
    public providerClientNotesControllerDelete(id: string, options?: AxiosRequestConfig) {
        return ProviderClientNotesApiFp(this.configuration).providerClientNotesControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientNotesApi
     */
    public providerClientNotesControllerFind(id: string, options?: AxiosRequestConfig) {
        return ProviderClientNotesApiFp(this.configuration).providerClientNotesControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [query] 
     * @param {number} [providerClientProfileId] 
     * @param {number} [appointmentId] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientNotesApi
     */
    public providerClientNotesControllerFindProviderClientNotes(startDate?: string, endDate?: string, query?: string, providerClientProfileId?: number, appointmentId?: number, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderClientNotesApiFp(this.configuration).providerClientNotesControllerFindProviderClientNotes(startDate, endDate, query, providerClientProfileId, appointmentId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProviderClientNotesDto} updateProviderClientNotesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientNotesApi
     */
    public providerClientNotesControllerUpdate(updateProviderClientNotesDto: UpdateProviderClientNotesDto, options?: AxiosRequestConfig) {
        return ProviderClientNotesApiFp(this.configuration).providerClientNotesControllerUpdate(updateProviderClientNotesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderClientProfileApi - axios parameter creator
 * @export
 */
export const ProviderClientProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProviderClientProfileDto} createProviderClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerCreate: async (createProviderClientProfileDto: CreateProviderClientProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderClientProfileDto' is not null or undefined
            assertParamExists('providerClientProfileControllerCreate', 'createProviderClientProfileDto', createProviderClientProfileDto)
            const localVarPath = `/api/v1/provider-client-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderClientProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerFindAllByProviderParams: async (providerId?: string, query?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-client-profile/provider-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerClientProfileControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/provider-client-profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerClientProfileControllerRemove', 'id', id)
            const localVarPath = `/api/v1/provider-client-profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderClientProfileDto} updateProviderClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerUpdate: async (id: string, updateProviderClientProfileDto: UpdateProviderClientProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerClientProfileControllerUpdate', 'id', id)
            // verify required parameter 'updateProviderClientProfileDto' is not null or undefined
            assertParamExists('providerClientProfileControllerUpdate', 'updateProviderClientProfileDto', updateProviderClientProfileDto)
            const localVarPath = `/api/v1/provider-client-profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderClientProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerValidateEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('providerClientProfileControllerValidateEmail', 'email', email)
            const localVarPath = `/api/v1/provider-client-profile/validate-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderClientProfileApi - functional programming interface
 * @export
 */
export const ProviderClientProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderClientProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProviderClientProfileDto} createProviderClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientProfileControllerCreate(createProviderClientProfileDto: CreateProviderClientProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderClientProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientProfileControllerCreate(createProviderClientProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientProfileControllerFindAllByProviderParams(providerId?: string, query?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderClientProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientProfileControllerFindAllByProviderParams(providerId, query, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientProfileControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderClientProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientProfileControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientProfileControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientProfileControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderClientProfileDto} updateProviderClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientProfileControllerUpdate(id: string, updateProviderClientProfileDto: UpdateProviderClientProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderClientProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientProfileControllerUpdate(id, updateProviderClientProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerClientProfileControllerValidateEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerClientProfileControllerValidateEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderClientProfileApi - factory interface
 * @export
 */
export const ProviderClientProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderClientProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProviderClientProfileDto} createProviderClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerCreate(createProviderClientProfileDto: CreateProviderClientProfileDto, options?: any): AxiosPromise<ProviderClientProfile> {
            return localVarFp.providerClientProfileControllerCreate(createProviderClientProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerFindAllByProviderParams(providerId?: string, query?: string, limit?: number, options?: any): AxiosPromise<Array<ProviderClientProfile>> {
            return localVarFp.providerClientProfileControllerFindAllByProviderParams(providerId, query, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerFindOne(id: string, options?: any): AxiosPromise<ProviderClientProfile> {
            return localVarFp.providerClientProfileControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerClientProfileControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderClientProfileDto} updateProviderClientProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerUpdate(id: string, updateProviderClientProfileDto: UpdateProviderClientProfileDto, options?: any): AxiosPromise<ProviderClientProfile> {
            return localVarFp.providerClientProfileControllerUpdate(id, updateProviderClientProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerClientProfileControllerValidateEmail(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerClientProfileControllerValidateEmail(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderClientProfileApi - object-oriented interface
 * @export
 * @class ProviderClientProfileApi
 * @extends {BaseAPI}
 */
export class ProviderClientProfileApi extends BaseAPI {
    /**
     * 
     * @param {CreateProviderClientProfileDto} createProviderClientProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientProfileApi
     */
    public providerClientProfileControllerCreate(createProviderClientProfileDto: CreateProviderClientProfileDto, options?: AxiosRequestConfig) {
        return ProviderClientProfileApiFp(this.configuration).providerClientProfileControllerCreate(createProviderClientProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [providerId] 
     * @param {string} [query] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientProfileApi
     */
    public providerClientProfileControllerFindAllByProviderParams(providerId?: string, query?: string, limit?: number, options?: AxiosRequestConfig) {
        return ProviderClientProfileApiFp(this.configuration).providerClientProfileControllerFindAllByProviderParams(providerId, query, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientProfileApi
     */
    public providerClientProfileControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ProviderClientProfileApiFp(this.configuration).providerClientProfileControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientProfileApi
     */
    public providerClientProfileControllerRemove(id: string, options?: AxiosRequestConfig) {
        return ProviderClientProfileApiFp(this.configuration).providerClientProfileControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProviderClientProfileDto} updateProviderClientProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientProfileApi
     */
    public providerClientProfileControllerUpdate(id: string, updateProviderClientProfileDto: UpdateProviderClientProfileDto, options?: AxiosRequestConfig) {
        return ProviderClientProfileApiFp(this.configuration).providerClientProfileControllerUpdate(id, updateProviderClientProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderClientProfileApi
     */
    public providerClientProfileControllerValidateEmail(email: string, options?: AxiosRequestConfig) {
        return ProviderClientProfileApiFp(this.configuration).providerClientProfileControllerValidateEmail(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderDirectoryApi - axios parameter creator
 * @export
 */
export const ProviderDirectoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerApprove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerDirectoryControllerApprove', 'id', id)
            const localVarPath = `/api/v1/provider-directory/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProviderDirectoryDto} createProviderDirectoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerCreate: async (createProviderDirectoryDto: CreateProviderDirectoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderDirectoryDto' is not null or undefined
            assertParamExists('providerDirectoryControllerCreate', 'createProviderDirectoryDto', createProviderDirectoryDto)
            const localVarPath = `/api/v1/provider-directory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderDirectoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerDirectoryControllerDelete', 'id', id)
            const localVarPath = `/api/v1/provider-directory/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [providerTypeId] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerFindMany: async (searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-directory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [providerTypeId] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerFindManyForClient: async (searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-directory/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerDirectoryControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/provider-directory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderDirectoryDto} updateProviderDirectoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerUpdate: async (id: string, updateProviderDirectoryDto: UpdateProviderDirectoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerDirectoryControllerUpdate', 'id', id)
            // verify required parameter 'updateProviderDirectoryDto' is not null or undefined
            assertParamExists('providerDirectoryControllerUpdate', 'updateProviderDirectoryDto', updateProviderDirectoryDto)
            const localVarPath = `/api/v1/provider-directory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderDirectoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderDirectoryApi - functional programming interface
 * @export
 */
export const ProviderDirectoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderDirectoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerDirectoryControllerApprove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerDirectoryControllerApprove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProviderDirectoryDto} createProviderDirectoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerDirectoryControllerCreate(createProviderDirectoryDto: CreateProviderDirectoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerDirectoryControllerCreate(createProviderDirectoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerDirectoryControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerDirectoryControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [providerTypeId] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerDirectoryControllerFindMany(searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerDirectoryControllerFindMany(searchQuery, providerTypeId, longitude, latitude, status, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [providerTypeId] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerDirectoryControllerFindManyForClient(searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerDirectoryControllerFindManyForClient(searchQuery, providerTypeId, longitude, latitude, status, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerDirectoryControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerDirectoryControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderDirectoryDto} updateProviderDirectoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerDirectoryControllerUpdate(id: string, updateProviderDirectoryDto: UpdateProviderDirectoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerDirectoryControllerUpdate(id, updateProviderDirectoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderDirectoryApi - factory interface
 * @export
 */
export const ProviderDirectoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderDirectoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerApprove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerDirectoryControllerApprove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProviderDirectoryDto} createProviderDirectoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerCreate(createProviderDirectoryDto: CreateProviderDirectoryDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerDirectoryControllerCreate(createProviderDirectoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerDirectoryControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [providerTypeId] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerFindMany(searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.providerDirectoryControllerFindMany(searchQuery, providerTypeId, longitude, latitude, status, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [providerTypeId] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerFindManyForClient(searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.providerDirectoryControllerFindManyForClient(searchQuery, providerTypeId, longitude, latitude, status, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerFindOne(id: string, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerDirectoryControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderDirectoryDto} updateProviderDirectoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerDirectoryControllerUpdate(id: string, updateProviderDirectoryDto: UpdateProviderDirectoryDto, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerDirectoryControllerUpdate(id, updateProviderDirectoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderDirectoryApi - object-oriented interface
 * @export
 * @class ProviderDirectoryApi
 * @extends {BaseAPI}
 */
export class ProviderDirectoryApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderDirectoryApi
     */
    public providerDirectoryControllerApprove(id: string, options?: AxiosRequestConfig) {
        return ProviderDirectoryApiFp(this.configuration).providerDirectoryControllerApprove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProviderDirectoryDto} createProviderDirectoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderDirectoryApi
     */
    public providerDirectoryControllerCreate(createProviderDirectoryDto: CreateProviderDirectoryDto, options?: AxiosRequestConfig) {
        return ProviderDirectoryApiFp(this.configuration).providerDirectoryControllerCreate(createProviderDirectoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderDirectoryApi
     */
    public providerDirectoryControllerDelete(id: string, options?: AxiosRequestConfig) {
        return ProviderDirectoryApiFp(this.configuration).providerDirectoryControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchQuery] 
     * @param {string} [providerTypeId] 
     * @param {number} [longitude] 
     * @param {number} [latitude] 
     * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderDirectoryApi
     */
    public providerDirectoryControllerFindMany(searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderDirectoryApiFp(this.configuration).providerDirectoryControllerFindMany(searchQuery, providerTypeId, longitude, latitude, status, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchQuery] 
     * @param {string} [providerTypeId] 
     * @param {number} [longitude] 
     * @param {number} [latitude] 
     * @param {'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED'} [status] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderDirectoryApi
     */
    public providerDirectoryControllerFindManyForClient(searchQuery?: string, providerTypeId?: string, longitude?: number, latitude?: number, status?: 'PENDING' | 'DIRECTORY' | 'SELF_ONBOARDED' | 'ONBOARDED', limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderDirectoryApiFp(this.configuration).providerDirectoryControllerFindManyForClient(searchQuery, providerTypeId, longitude, latitude, status, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderDirectoryApi
     */
    public providerDirectoryControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ProviderDirectoryApiFp(this.configuration).providerDirectoryControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProviderDirectoryDto} updateProviderDirectoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderDirectoryApi
     */
    public providerDirectoryControllerUpdate(id: string, updateProviderDirectoryDto: UpdateProviderDirectoryDto, options?: AxiosRequestConfig) {
        return ProviderDirectoryApiFp(this.configuration).providerDirectoryControllerUpdate(id, updateProviderDirectoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderServiceSchedulesApi - axios parameter creator
 * @export
 */
export const ProviderServiceSchedulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [branchIds] 
         * @param {Array<string>} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerCheckExistence: async (providerServiceIds?: Array<number>, branchIds?: Array<number>, days?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/provider-service-schedules/check-existence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerServiceIds) {
                localVarQueryParameter['providerServiceIds'] = providerServiceIds;
            }

            if (branchIds) {
                localVarQueryParameter['branchIds'] = branchIds;
            }

            if (days) {
                localVarQueryParameter['days'] = days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateServiceScheduleDto} createServiceScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerCreateOrReplace: async (createServiceScheduleDto: CreateServiceScheduleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceScheduleDto' is not null or undefined
            assertParamExists('providerServiceScheduleControllerCreateOrReplace', 'createServiceScheduleDto', createServiceScheduleDto)
            const localVarPath = `/api/provider-service-schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceScheduleControllerDelete', 'id', id)
            const localVarPath = `/api/provider-service-schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [branchId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerFind: async (brandId: string, branchId?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('providerServiceScheduleControllerFind', 'brandId', brandId)
            const localVarPath = `/api/provider-service-schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }

            if (branchId !== undefined) {
                localVarQueryParameter['branchId'] = branchId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} providerServiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerFindAllByProviderServiceId: async (providerServiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerServiceId' is not null or undefined
            assertParamExists('providerServiceScheduleControllerFindAllByProviderServiceId', 'providerServiceId', providerServiceId)
            const localVarPath = `/api/provider-service-schedules/provider-service/{providerServiceId}`
                .replace(`{${"providerServiceId"}}`, encodeURIComponent(String(providerServiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceScheduleControllerFindOne', 'id', id)
            const localVarPath = `/api/provider-service-schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceScheduleDto} updateServiceScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerUpdate: async (id: string, updateServiceScheduleDto: UpdateServiceScheduleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceScheduleControllerUpdate', 'id', id)
            // verify required parameter 'updateServiceScheduleDto' is not null or undefined
            assertParamExists('providerServiceScheduleControllerUpdate', 'updateServiceScheduleDto', updateServiceScheduleDto)
            const localVarPath = `/api/provider-service-schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderServiceSchedulesApi - functional programming interface
 * @export
 */
export const ProviderServiceSchedulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderServiceSchedulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [branchIds] 
         * @param {Array<string>} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceScheduleControllerCheckExistence(providerServiceIds?: Array<number>, branchIds?: Array<number>, days?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceScheduleControllerCheckExistence(providerServiceIds, branchIds, days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateServiceScheduleDto} createServiceScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceScheduleControllerCreateOrReplace(createServiceScheduleDto: CreateServiceScheduleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderServiceSchedule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceScheduleControllerCreateOrReplace(createServiceScheduleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceScheduleControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceScheduleControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [branchId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceScheduleControllerFind(brandId: string, branchId?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderServiceSchedule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceScheduleControllerFind(brandId, branchId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} providerServiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceScheduleControllerFindAllByProviderServiceId(providerServiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderServiceSchedule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceScheduleControllerFindAllByProviderServiceId(providerServiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceScheduleControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderServiceSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceScheduleControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceScheduleDto} updateServiceScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceScheduleControllerUpdate(id: string, updateServiceScheduleDto: UpdateServiceScheduleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderServiceSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceScheduleControllerUpdate(id, updateServiceScheduleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderServiceSchedulesApi - factory interface
 * @export
 */
export const ProviderServiceSchedulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderServiceSchedulesApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [providerServiceIds] 
         * @param {Array<number>} [branchIds] 
         * @param {Array<string>} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerCheckExistence(providerServiceIds?: Array<number>, branchIds?: Array<number>, days?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.providerServiceScheduleControllerCheckExistence(providerServiceIds, branchIds, days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateServiceScheduleDto} createServiceScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerCreateOrReplace(createServiceScheduleDto: CreateServiceScheduleDto, options?: any): AxiosPromise<Array<ProviderServiceSchedule>> {
            return localVarFp.providerServiceScheduleControllerCreateOrReplace(createServiceScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerServiceScheduleControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [branchId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerFind(brandId: string, branchId?: string, search?: string, options?: any): AxiosPromise<Array<ProviderServiceSchedule>> {
            return localVarFp.providerServiceScheduleControllerFind(brandId, branchId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} providerServiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerFindAllByProviderServiceId(providerServiceId: string, options?: any): AxiosPromise<Array<ProviderServiceSchedule>> {
            return localVarFp.providerServiceScheduleControllerFindAllByProviderServiceId(providerServiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerFindOne(id: string, options?: any): AxiosPromise<ProviderServiceSchedule> {
            return localVarFp.providerServiceScheduleControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceScheduleDto} updateServiceScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceScheduleControllerUpdate(id: string, updateServiceScheduleDto: UpdateServiceScheduleDto, options?: any): AxiosPromise<ProviderServiceSchedule> {
            return localVarFp.providerServiceScheduleControllerUpdate(id, updateServiceScheduleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderServiceSchedulesApi - object-oriented interface
 * @export
 * @class ProviderServiceSchedulesApi
 * @extends {BaseAPI}
 */
export class ProviderServiceSchedulesApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [providerServiceIds] 
     * @param {Array<number>} [branchIds] 
     * @param {Array<string>} [days] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSchedulesApi
     */
    public providerServiceScheduleControllerCheckExistence(providerServiceIds?: Array<number>, branchIds?: Array<number>, days?: Array<string>, options?: AxiosRequestConfig) {
        return ProviderServiceSchedulesApiFp(this.configuration).providerServiceScheduleControllerCheckExistence(providerServiceIds, branchIds, days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateServiceScheduleDto} createServiceScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSchedulesApi
     */
    public providerServiceScheduleControllerCreateOrReplace(createServiceScheduleDto: CreateServiceScheduleDto, options?: AxiosRequestConfig) {
        return ProviderServiceSchedulesApiFp(this.configuration).providerServiceScheduleControllerCreateOrReplace(createServiceScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSchedulesApi
     */
    public providerServiceScheduleControllerDelete(id: string, options?: AxiosRequestConfig) {
        return ProviderServiceSchedulesApiFp(this.configuration).providerServiceScheduleControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {string} [branchId] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSchedulesApi
     */
    public providerServiceScheduleControllerFind(brandId: string, branchId?: string, search?: string, options?: AxiosRequestConfig) {
        return ProviderServiceSchedulesApiFp(this.configuration).providerServiceScheduleControllerFind(brandId, branchId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} providerServiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSchedulesApi
     */
    public providerServiceScheduleControllerFindAllByProviderServiceId(providerServiceId: string, options?: AxiosRequestConfig) {
        return ProviderServiceSchedulesApiFp(this.configuration).providerServiceScheduleControllerFindAllByProviderServiceId(providerServiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSchedulesApi
     */
    public providerServiceScheduleControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ProviderServiceSchedulesApiFp(this.configuration).providerServiceScheduleControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateServiceScheduleDto} updateServiceScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSchedulesApi
     */
    public providerServiceScheduleControllerUpdate(id: string, updateServiceScheduleDto: UpdateServiceScheduleDto, options?: AxiosRequestConfig) {
        return ProviderServiceSchedulesApiFp(this.configuration).providerServiceScheduleControllerUpdate(id, updateServiceScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderServiceSettingsApi - axios parameter creator
 * @export
 */
export const ProviderServiceSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceSettingsControllerFindOne: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/service-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProviderServiceSettingsDto} updateProviderServiceSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceSettingsControllerUpdate: async (updateProviderServiceSettingsDto: UpdateProviderServiceSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProviderServiceSettingsDto' is not null or undefined
            assertParamExists('providerServiceSettingsControllerUpdate', 'updateProviderServiceSettingsDto', updateProviderServiceSettingsDto)
            const localVarPath = `/api/v1/service-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderServiceSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderServiceSettingsApi - functional programming interface
 * @export
 */
export const ProviderServiceSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderServiceSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceSettingsControllerFindOne(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderServiceSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceSettingsControllerFindOne(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateProviderServiceSettingsDto} updateProviderServiceSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceSettingsControllerUpdate(updateProviderServiceSettingsDto: UpdateProviderServiceSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderServiceSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceSettingsControllerUpdate(updateProviderServiceSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderServiceSettingsApi - factory interface
 * @export
 */
export const ProviderServiceSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderServiceSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceSettingsControllerFindOne(options?: any): AxiosPromise<ProviderServiceSettings> {
            return localVarFp.providerServiceSettingsControllerFindOne(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProviderServiceSettingsDto} updateProviderServiceSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceSettingsControllerUpdate(updateProviderServiceSettingsDto: UpdateProviderServiceSettingsDto, options?: any): AxiosPromise<ProviderServiceSettings> {
            return localVarFp.providerServiceSettingsControllerUpdate(updateProviderServiceSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderServiceSettingsApi - object-oriented interface
 * @export
 * @class ProviderServiceSettingsApi
 * @extends {BaseAPI}
 */
export class ProviderServiceSettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSettingsApi
     */
    public providerServiceSettingsControllerFindOne(options?: AxiosRequestConfig) {
        return ProviderServiceSettingsApiFp(this.configuration).providerServiceSettingsControllerFindOne(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProviderServiceSettingsDto} updateProviderServiceSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServiceSettingsApi
     */
    public providerServiceSettingsControllerUpdate(updateProviderServiceSettingsDto: UpdateProviderServiceSettingsDto, options?: AxiosRequestConfig) {
        return ProviderServiceSettingsApiFp(this.configuration).providerServiceSettingsControllerUpdate(updateProviderServiceSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderServicesApi - axios parameter creator
 * @export
 */
export const ProviderServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProviderServiceDto} createProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerCreate: async (createProviderServiceDto: CreateProviderServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderServiceDto' is not null or undefined
            assertParamExists('providerServiceControllerCreate', 'createProviderServiceDto', createProviderServiceDto)
            const localVarPath = `/api/v1/provider-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [providerTypeId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerFindAll: async (providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [providerTypeId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerFindAllByUser: async (providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-services/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds;
            }

            if (providerTypeId !== undefined) {
                localVarQueryParameter['providerTypeId'] = providerTypeId;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/provider-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteProviderServiceDto} deleteProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerRemove: async (id: string, deleteProviderServiceDto: DeleteProviderServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceControllerRemove', 'id', id)
            // verify required parameter 'deleteProviderServiceDto' is not null or undefined
            assertParamExists('providerServiceControllerRemove', 'deleteProviderServiceDto', deleteProviderServiceDto)
            const localVarPath = `/api/v1/provider-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteProviderServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderServiceDto} updateProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerUpdate: async (id: string, updateProviderServiceDto: UpdateProviderServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceControllerUpdate', 'id', id)
            // verify required parameter 'updateProviderServiceDto' is not null or undefined
            assertParamExists('providerServiceControllerUpdate', 'updateProviderServiceDto', updateProviderServiceDto)
            const localVarPath = `/api/v1/provider-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBranchServiceAvailabilityDto} updateBranchServiceAvailabilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerUpdateBranchAvailability: async (id: string, updateBranchServiceAvailabilityDto: UpdateBranchServiceAvailabilityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceControllerUpdateBranchAvailability', 'id', id)
            // verify required parameter 'updateBranchServiceAvailabilityDto' is not null or undefined
            assertParamExists('providerServiceControllerUpdateBranchAvailability', 'updateBranchServiceAvailabilityDto', updateBranchServiceAvailabilityDto)
            const localVarPath = `/api/v1/provider-services/{id}/branch-availability`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBranchServiceAvailabilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerUpdateHidden: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerServiceControllerUpdateHidden', 'id', id)
            const localVarPath = `/api/v1/provider-services/{id}/update-hidden`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderServicesApi - functional programming interface
 * @export
 */
export const ProviderServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProviderServiceDto} createProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerCreate(createProviderServiceDto: CreateProviderServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerCreate(createProviderServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [providerTypeId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerFindAll(providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerFindAll(providerId, providerIds, providerTypeId, query, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [providerTypeId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerFindAllByUser(providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerFindAllByUser(providerId, providerIds, providerTypeId, query, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteProviderServiceDto} deleteProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerRemove(id: string, deleteProviderServiceDto: DeleteProviderServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerRemove(id, deleteProviderServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderServiceDto} updateProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerUpdate(id: string, updateProviderServiceDto: UpdateProviderServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerUpdate(id, updateProviderServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBranchServiceAvailabilityDto} updateBranchServiceAvailabilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerUpdateBranchAvailability(id: string, updateBranchServiceAvailabilityDto: UpdateBranchServiceAvailabilityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerUpdateBranchAvailability(id, updateBranchServiceAvailabilityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerServiceControllerUpdateHidden(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerServiceControllerUpdateHidden(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderServicesApi - factory interface
 * @export
 */
export const ProviderServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderServicesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProviderServiceDto} createProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerCreate(createProviderServiceDto: CreateProviderServiceDto, options?: any): AxiosPromise<ProviderService> {
            return localVarFp.providerServiceControllerCreate(createProviderServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [providerTypeId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerFindAll(providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options?: any): AxiosPromise<Array<ProviderService>> {
            return localVarFp.providerServiceControllerFindAll(providerId, providerIds, providerTypeId, query, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [providerTypeId] 
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerFindAllByUser(providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.providerServiceControllerFindAllByUser(providerId, providerIds, providerTypeId, query, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerFindOne(id: string, options?: any): AxiosPromise<ProviderService> {
            return localVarFp.providerServiceControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteProviderServiceDto} deleteProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerRemove(id: string, deleteProviderServiceDto: DeleteProviderServiceDto, options?: any): AxiosPromise<object> {
            return localVarFp.providerServiceControllerRemove(id, deleteProviderServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderServiceDto} updateProviderServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerUpdate(id: string, updateProviderServiceDto: UpdateProviderServiceDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerServiceControllerUpdate(id, updateProviderServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBranchServiceAvailabilityDto} updateBranchServiceAvailabilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerUpdateBranchAvailability(id: string, updateBranchServiceAvailabilityDto: UpdateBranchServiceAvailabilityDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerServiceControllerUpdateBranchAvailability(id, updateBranchServiceAvailabilityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerServiceControllerUpdateHidden(id: string, options?: any): AxiosPromise<ProviderService> {
            return localVarFp.providerServiceControllerUpdateHidden(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderServicesApi - object-oriented interface
 * @export
 * @class ProviderServicesApi
 * @extends {BaseAPI}
 */
export class ProviderServicesApi extends BaseAPI {
    /**
     * 
     * @param {CreateProviderServiceDto} createProviderServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerCreate(createProviderServiceDto: CreateProviderServiceDto, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerCreate(createProviderServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [providerId] 
     * @param {Array<string>} [providerIds] 
     * @param {string} [providerTypeId] 
     * @param {string} [query] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerFindAll(providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerFindAll(providerId, providerIds, providerTypeId, query, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [providerId] 
     * @param {Array<string>} [providerIds] 
     * @param {string} [providerTypeId] 
     * @param {string} [query] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerFindAllByUser(providerId?: string, providerIds?: Array<string>, providerTypeId?: string, query?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerFindAllByUser(providerId, providerIds, providerTypeId, query, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {DeleteProviderServiceDto} deleteProviderServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerRemove(id: string, deleteProviderServiceDto: DeleteProviderServiceDto, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerRemove(id, deleteProviderServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProviderServiceDto} updateProviderServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerUpdate(id: string, updateProviderServiceDto: UpdateProviderServiceDto, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerUpdate(id, updateProviderServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateBranchServiceAvailabilityDto} updateBranchServiceAvailabilityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerUpdateBranchAvailability(id: string, updateBranchServiceAvailabilityDto: UpdateBranchServiceAvailabilityDto, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerUpdateBranchAvailability(id, updateBranchServiceAvailabilityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderServicesApi
     */
    public providerServiceControllerUpdateHidden(id: string, options?: AxiosRequestConfig) {
        return ProviderServicesApiFp(this.configuration).providerServiceControllerUpdateHidden(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderTagCategoryApi - axios parameter creator
 * @export
 */
export const ProviderTagCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProviderTagCategoryDto} createProviderTagCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerCreate: async (createProviderTagCategoryDto: CreateProviderTagCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderTagCategoryDto' is not null or undefined
            assertParamExists('providerTagCategoryControllerCreate', 'createProviderTagCategoryDto', createProviderTagCategoryDto)
            const localVarPath = `/api/v1/provider-tag-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderTagCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerFindAllByParams: async (providerId?: string, searchQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-tag-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerTagCategoryControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/provider-tag-category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerTagCategoryControllerRemove', 'id', id)
            const localVarPath = `/api/v1/provider-tag-category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderTagCategoryDto} updateProviderTagCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerUpdate: async (id: string, updateProviderTagCategoryDto: UpdateProviderTagCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerTagCategoryControllerUpdate', 'id', id)
            // verify required parameter 'updateProviderTagCategoryDto' is not null or undefined
            assertParamExists('providerTagCategoryControllerUpdate', 'updateProviderTagCategoryDto', updateProviderTagCategoryDto)
            const localVarPath = `/api/v1/provider-tag-category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderTagCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderTagCategoryApi - functional programming interface
 * @export
 */
export const ProviderTagCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderTagCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProviderTagCategoryDto} createProviderTagCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagCategoryControllerCreate(createProviderTagCategoryDto: CreateProviderTagCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderTagCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagCategoryControllerCreate(createProviderTagCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagCategoryControllerFindAllByParams(providerId?: string, searchQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderTagCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagCategoryControllerFindAllByParams(providerId, searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagCategoryControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderTagCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagCategoryControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagCategoryControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagCategoryControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderTagCategoryDto} updateProviderTagCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagCategoryControllerUpdate(id: string, updateProviderTagCategoryDto: UpdateProviderTagCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderTagCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagCategoryControllerUpdate(id, updateProviderTagCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderTagCategoryApi - factory interface
 * @export
 */
export const ProviderTagCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderTagCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProviderTagCategoryDto} createProviderTagCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerCreate(createProviderTagCategoryDto: CreateProviderTagCategoryDto, options?: any): AxiosPromise<ProviderTagCategory> {
            return localVarFp.providerTagCategoryControllerCreate(createProviderTagCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerFindAllByParams(providerId?: string, searchQuery?: string, options?: any): AxiosPromise<Array<ProviderTagCategory>> {
            return localVarFp.providerTagCategoryControllerFindAllByParams(providerId, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerFindOne(id: string, options?: any): AxiosPromise<ProviderTagCategory> {
            return localVarFp.providerTagCategoryControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerTagCategoryControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderTagCategoryDto} updateProviderTagCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagCategoryControllerUpdate(id: string, updateProviderTagCategoryDto: UpdateProviderTagCategoryDto, options?: any): AxiosPromise<ProviderTagCategory> {
            return localVarFp.providerTagCategoryControllerUpdate(id, updateProviderTagCategoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderTagCategoryApi - object-oriented interface
 * @export
 * @class ProviderTagCategoryApi
 * @extends {BaseAPI}
 */
export class ProviderTagCategoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateProviderTagCategoryDto} createProviderTagCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderTagCategoryApi
     */
    public providerTagCategoryControllerCreate(createProviderTagCategoryDto: CreateProviderTagCategoryDto, options?: AxiosRequestConfig) {
        return ProviderTagCategoryApiFp(this.configuration).providerTagCategoryControllerCreate(createProviderTagCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [providerId] 
     * @param {string} [searchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderTagCategoryApi
     */
    public providerTagCategoryControllerFindAllByParams(providerId?: string, searchQuery?: string, options?: AxiosRequestConfig) {
        return ProviderTagCategoryApiFp(this.configuration).providerTagCategoryControllerFindAllByParams(providerId, searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderTagCategoryApi
     */
    public providerTagCategoryControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ProviderTagCategoryApiFp(this.configuration).providerTagCategoryControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderTagCategoryApi
     */
    public providerTagCategoryControllerRemove(id: string, options?: AxiosRequestConfig) {
        return ProviderTagCategoryApiFp(this.configuration).providerTagCategoryControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProviderTagCategoryDto} updateProviderTagCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderTagCategoryApi
     */
    public providerTagCategoryControllerUpdate(id: string, updateProviderTagCategoryDto: UpdateProviderTagCategoryDto, options?: AxiosRequestConfig) {
        return ProviderTagCategoryApiFp(this.configuration).providerTagCategoryControllerUpdate(id, updateProviderTagCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferralsApi - axios parameter creator
 * @export
 */
export const ReferralsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateReferralDto} createReferralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerCreate: async (createReferralDto: CreateReferralDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReferralDto' is not null or undefined
            assertParamExists('referralsControllerCreate', 'createReferralDto', createReferralDto)
            const localVarPath = `/api/referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReferralDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} providerId 
         * @param {'incoming' | 'outgoing'} [status] 
         * @param {string} [providerClientProfileId] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [middleName] 
         * @param {string} [sex] 
         * @param {string} [birthDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerFindAll: async (providerId: number, status?: 'incoming' | 'outgoing', providerClientProfileId?: string, firstName?: string, lastName?: string, middleName?: string, sex?: string, birthDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('referralsControllerFindAll', 'providerId', providerId)
            const localVarPath = `/api/referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (providerClientProfileId !== undefined) {
                localVarQueryParameter['providerClientProfileId'] = providerClientProfileId;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (middleName !== undefined) {
                localVarQueryParameter['middleName'] = middleName;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (birthDate !== undefined) {
                localVarQueryParameter['birthDate'] = (birthDate as any instanceof Date) ?
                    (birthDate as any).toISOString() :
                    birthDate;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('referralsControllerFindOne', 'id', id)
            const localVarPath = `/api/referrals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('referralsControllerRemove', 'id', id)
            const localVarPath = `/api/referrals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateReferralDto} updateReferralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerUpdate: async (id: string, updateReferralDto: UpdateReferralDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('referralsControllerUpdate', 'id', id)
            // verify required parameter 'updateReferralDto' is not null or undefined
            assertParamExists('referralsControllerUpdate', 'updateReferralDto', updateReferralDto)
            const localVarPath = `/api/referrals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReferralDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralsApi - functional programming interface
 * @export
 */
export const ReferralsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateReferralDto} createReferralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralsControllerCreate(createReferralDto: CreateReferralDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralsControllerCreate(createReferralDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} providerId 
         * @param {'incoming' | 'outgoing'} [status] 
         * @param {string} [providerClientProfileId] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [middleName] 
         * @param {string} [sex] 
         * @param {string} [birthDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralsControllerFindAll(providerId: number, status?: 'incoming' | 'outgoing', providerClientProfileId?: string, firstName?: string, lastName?: string, middleName?: string, sex?: string, birthDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Referral>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralsControllerFindAll(providerId, status, providerClientProfileId, firstName, lastName, middleName, sex, birthDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Referral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralsControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateReferralDto} updateReferralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralsControllerUpdate(id: string, updateReferralDto: UpdateReferralDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Referral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralsControllerUpdate(id, updateReferralDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferralsApi - factory interface
 * @export
 */
export const ReferralsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateReferralDto} createReferralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerCreate(createReferralDto: CreateReferralDto, options?: any): AxiosPromise<object> {
            return localVarFp.referralsControllerCreate(createReferralDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} providerId 
         * @param {'incoming' | 'outgoing'} [status] 
         * @param {string} [providerClientProfileId] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [middleName] 
         * @param {string} [sex] 
         * @param {string} [birthDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerFindAll(providerId: number, status?: 'incoming' | 'outgoing', providerClientProfileId?: string, firstName?: string, lastName?: string, middleName?: string, sex?: string, birthDate?: string, options?: any): AxiosPromise<Array<Referral>> {
            return localVarFp.referralsControllerFindAll(providerId, status, providerClientProfileId, firstName, lastName, middleName, sex, birthDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerFindOne(id: string, options?: any): AxiosPromise<Referral> {
            return localVarFp.referralsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.referralsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateReferralDto} updateReferralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralsControllerUpdate(id: string, updateReferralDto: UpdateReferralDto, options?: any): AxiosPromise<Referral> {
            return localVarFp.referralsControllerUpdate(id, updateReferralDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferralsApi - object-oriented interface
 * @export
 * @class ReferralsApi
 * @extends {BaseAPI}
 */
export class ReferralsApi extends BaseAPI {
    /**
     * 
     * @param {CreateReferralDto} createReferralDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApi
     */
    public referralsControllerCreate(createReferralDto: CreateReferralDto, options?: AxiosRequestConfig) {
        return ReferralsApiFp(this.configuration).referralsControllerCreate(createReferralDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} providerId 
     * @param {'incoming' | 'outgoing'} [status] 
     * @param {string} [providerClientProfileId] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [middleName] 
     * @param {string} [sex] 
     * @param {string} [birthDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApi
     */
    public referralsControllerFindAll(providerId: number, status?: 'incoming' | 'outgoing', providerClientProfileId?: string, firstName?: string, lastName?: string, middleName?: string, sex?: string, birthDate?: string, options?: AxiosRequestConfig) {
        return ReferralsApiFp(this.configuration).referralsControllerFindAll(providerId, status, providerClientProfileId, firstName, lastName, middleName, sex, birthDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApi
     */
    public referralsControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ReferralsApiFp(this.configuration).referralsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApi
     */
    public referralsControllerRemove(id: string, options?: AxiosRequestConfig) {
        return ReferralsApiFp(this.configuration).referralsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateReferralDto} updateReferralDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApi
     */
    public referralsControllerUpdate(id: string, updateReferralDto: UpdateReferralDto, options?: AxiosRequestConfig) {
        return ReferralsApiFp(this.configuration).referralsControllerUpdate(id, updateReferralDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegionApi - axios parameter creator
 * @export
 */
export const RegionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'true' | 'false'} [hasHospitals] 
         * @param {'true' | 'false'} [hasLabs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionControllerFindAll: async (hasHospitals?: 'true' | 'false', hasLabs?: 'true' | 'false', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/region`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hasHospitals !== undefined) {
                localVarQueryParameter['hasHospitals'] = hasHospitals;
            }

            if (hasLabs !== undefined) {
                localVarQueryParameter['hasLabs'] = hasLabs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionApi - functional programming interface
 * @export
 */
export const RegionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'true' | 'false'} [hasHospitals] 
         * @param {'true' | 'false'} [hasLabs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionControllerFindAll(hasHospitals?: 'true' | 'false', hasLabs?: 'true' | 'false', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionControllerFindAll(hasHospitals, hasLabs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionApi - factory interface
 * @export
 */
export const RegionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionApiFp(configuration)
    return {
        /**
         * 
         * @param {'true' | 'false'} [hasHospitals] 
         * @param {'true' | 'false'} [hasLabs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionControllerFindAll(hasHospitals?: 'true' | 'false', hasLabs?: 'true' | 'false', options?: any): AxiosPromise<Array<Region>> {
            return localVarFp.regionControllerFindAll(hasHospitals, hasLabs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegionApi - object-oriented interface
 * @export
 * @class RegionApi
 * @extends {BaseAPI}
 */
export class RegionApi extends BaseAPI {
    /**
     * 
     * @param {'true' | 'false'} [hasHospitals] 
     * @param {'true' | 'false'} [hasLabs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public regionControllerFindAll(hasHospitals?: 'true' | 'false', hasLabs?: 'true' | 'false', options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).regionControllerFindAll(hasHospitals, hasLabs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RepresentativeApi - axios parameter creator
 * @export
 */
export const RepresentativeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRepresentativeDto} createRepresentativeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerCreate: async (createRepresentativeDto: CreateRepresentativeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRepresentativeDto' is not null or undefined
            assertParamExists('representativeControllerCreate', 'createRepresentativeDto', createRepresentativeDto)
            const localVarPath = `/api/v1/representative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepresentativeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/representative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('representativeControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/representative/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('representativeControllerRemove', 'id', id)
            const localVarPath = `/api/v1/representative/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRepresentativeDto} updateRepresentativeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerUpdate: async (id: string, updateRepresentativeDto: UpdateRepresentativeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('representativeControllerUpdate', 'id', id)
            // verify required parameter 'updateRepresentativeDto' is not null or undefined
            assertParamExists('representativeControllerUpdate', 'updateRepresentativeDto', updateRepresentativeDto)
            const localVarPath = `/api/v1/representative/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepresentativeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepresentativeApi - functional programming interface
 * @export
 */
export const RepresentativeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepresentativeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRepresentativeDto} createRepresentativeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async representativeControllerCreate(createRepresentativeDto: CreateRepresentativeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Representative>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.representativeControllerCreate(createRepresentativeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async representativeControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Representative>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.representativeControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async representativeControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Representative>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.representativeControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async representativeControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.representativeControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRepresentativeDto} updateRepresentativeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async representativeControllerUpdate(id: string, updateRepresentativeDto: UpdateRepresentativeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Representative>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.representativeControllerUpdate(id, updateRepresentativeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepresentativeApi - factory interface
 * @export
 */
export const RepresentativeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepresentativeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRepresentativeDto} createRepresentativeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerCreate(createRepresentativeDto: CreateRepresentativeDto, options?: any): AxiosPromise<Representative> {
            return localVarFp.representativeControllerCreate(createRepresentativeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerFindAll(options?: any): AxiosPromise<Array<Representative>> {
            return localVarFp.representativeControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerFindOne(id: string, options?: any): AxiosPromise<Representative> {
            return localVarFp.representativeControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.representativeControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRepresentativeDto} updateRepresentativeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        representativeControllerUpdate(id: string, updateRepresentativeDto: UpdateRepresentativeDto, options?: any): AxiosPromise<Representative> {
            return localVarFp.representativeControllerUpdate(id, updateRepresentativeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepresentativeApi - object-oriented interface
 * @export
 * @class RepresentativeApi
 * @extends {BaseAPI}
 */
export class RepresentativeApi extends BaseAPI {
    /**
     * 
     * @param {CreateRepresentativeDto} createRepresentativeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepresentativeApi
     */
    public representativeControllerCreate(createRepresentativeDto: CreateRepresentativeDto, options?: AxiosRequestConfig) {
        return RepresentativeApiFp(this.configuration).representativeControllerCreate(createRepresentativeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepresentativeApi
     */
    public representativeControllerFindAll(options?: AxiosRequestConfig) {
        return RepresentativeApiFp(this.configuration).representativeControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepresentativeApi
     */
    public representativeControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return RepresentativeApiFp(this.configuration).representativeControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepresentativeApi
     */
    public representativeControllerRemove(id: string, options?: AxiosRequestConfig) {
        return RepresentativeApiFp(this.configuration).representativeControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRepresentativeDto} updateRepresentativeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepresentativeApi
     */
    public representativeControllerUpdate(id: string, updateRepresentativeDto: UpdateRepresentativeDto, options?: AxiosRequestConfig) {
        return RepresentativeApiFp(this.configuration).representativeControllerUpdate(id, updateRepresentativeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScheduleApi - axios parameter creator
 * @export
 */
export const ScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateScheduleDto} createScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerCreateSchedule: async (createScheduleDto: CreateScheduleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createScheduleDto' is not null or undefined
            assertParamExists('scheduleControllerCreateSchedule', 'createScheduleDto', createScheduleDto)
            const localVarPath = `/api/v1/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [selectedDate] 
         * @param {'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'} [day] 
         * @param {boolean} [isSpecificDate] 
         * @param {boolean} [isBlocked] 
         * @param {boolean} [isRegularSchedule] 
         * @param {boolean} [includeProviderServiceSchedule] 
         * @param {string} [providerId] 
         * @param {'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'} [month] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerFindSchedulesByProviderId: async (startDate?: string, endDate?: string, selectedDate?: string, day?: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday', isSpecificDate?: boolean, isBlocked?: boolean, isRegularSchedule?: boolean, includeProviderServiceSchedule?: boolean, providerId?: string, month?: 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december', providerServiceIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (selectedDate !== undefined) {
                localVarQueryParameter['selectedDate'] = (selectedDate as any instanceof Date) ?
                    (selectedDate as any).toISOString() :
                    selectedDate;
            }

            if (day !== undefined) {
                localVarQueryParameter['day'] = day;
            }

            if (isSpecificDate !== undefined) {
                localVarQueryParameter['isSpecificDate'] = isSpecificDate;
            }

            if (isBlocked !== undefined) {
                localVarQueryParameter['isBlocked'] = isBlocked;
            }

            if (isRegularSchedule !== undefined) {
                localVarQueryParameter['isRegularSchedule'] = isRegularSchedule;
            }

            if (includeProviderServiceSchedule !== undefined) {
                localVarQueryParameter['includeProviderServiceSchedule'] = includeProviderServiceSchedule;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (providerServiceIds) {
                localVarQueryParameter['providerServiceIds'] = providerServiceIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerRemoveSchedule: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduleControllerRemoveSchedule', 'id', id)
            const localVarPath = `/api/v1/schedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} scheduleId 
         * @param {UpdateScheduleDto} updateScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerUpdateSchedule: async (scheduleId: string, updateScheduleDto: UpdateScheduleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('scheduleControllerUpdateSchedule', 'scheduleId', scheduleId)
            // verify required parameter 'updateScheduleDto' is not null or undefined
            assertParamExists('scheduleControllerUpdateSchedule', 'updateScheduleDto', updateScheduleDto)
            const localVarPath = `/api/v1/schedule/{scheduleId}`
                .replace(`{${"scheduleId"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduleApi - functional programming interface
 * @export
 */
export const ScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateScheduleDto} createScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleControllerCreateSchedule(createScheduleDto: CreateScheduleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleControllerCreateSchedule(createScheduleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [selectedDate] 
         * @param {'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'} [day] 
         * @param {boolean} [isSpecificDate] 
         * @param {boolean} [isBlocked] 
         * @param {boolean} [isRegularSchedule] 
         * @param {boolean} [includeProviderServiceSchedule] 
         * @param {string} [providerId] 
         * @param {'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'} [month] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleControllerFindSchedulesByProviderId(startDate?: string, endDate?: string, selectedDate?: string, day?: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday', isSpecificDate?: boolean, isBlocked?: boolean, isRegularSchedule?: boolean, includeProviderServiceSchedule?: boolean, providerId?: string, month?: 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december', providerServiceIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleControllerFindSchedulesByProviderId(startDate, endDate, selectedDate, day, isSpecificDate, isBlocked, isRegularSchedule, includeProviderServiceSchedule, providerId, month, providerServiceIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleControllerRemoveSchedule(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleControllerRemoveSchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} scheduleId 
         * @param {UpdateScheduleDto} updateScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleControllerUpdateSchedule(scheduleId: string, updateScheduleDto: UpdateScheduleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleControllerUpdateSchedule(scheduleId, updateScheduleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScheduleApi - factory interface
 * @export
 */
export const ScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateScheduleDto} createScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerCreateSchedule(createScheduleDto: CreateScheduleDto, options?: any): AxiosPromise<void> {
            return localVarFp.scheduleControllerCreateSchedule(createScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [selectedDate] 
         * @param {'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'} [day] 
         * @param {boolean} [isSpecificDate] 
         * @param {boolean} [isBlocked] 
         * @param {boolean} [isRegularSchedule] 
         * @param {boolean} [includeProviderServiceSchedule] 
         * @param {string} [providerId] 
         * @param {'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'} [month] 
         * @param {Array<number>} [providerServiceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerFindSchedulesByProviderId(startDate?: string, endDate?: string, selectedDate?: string, day?: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday', isSpecificDate?: boolean, isBlocked?: boolean, isRegularSchedule?: boolean, includeProviderServiceSchedule?: boolean, providerId?: string, month?: 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december', providerServiceIds?: Array<number>, options?: any): AxiosPromise<Array<ScheduleResponse>> {
            return localVarFp.scheduleControllerFindSchedulesByProviderId(startDate, endDate, selectedDate, day, isSpecificDate, isBlocked, isRegularSchedule, includeProviderServiceSchedule, providerId, month, providerServiceIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerRemoveSchedule(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.scheduleControllerRemoveSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} scheduleId 
         * @param {UpdateScheduleDto} updateScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleControllerUpdateSchedule(scheduleId: string, updateScheduleDto: UpdateScheduleDto, options?: any): AxiosPromise<Schedule> {
            return localVarFp.scheduleControllerUpdateSchedule(scheduleId, updateScheduleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScheduleApi - object-oriented interface
 * @export
 * @class ScheduleApi
 * @extends {BaseAPI}
 */
export class ScheduleApi extends BaseAPI {
    /**
     * 
     * @param {CreateScheduleDto} createScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public scheduleControllerCreateSchedule(createScheduleDto: CreateScheduleDto, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).scheduleControllerCreateSchedule(createScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [selectedDate] 
     * @param {'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'} [day] 
     * @param {boolean} [isSpecificDate] 
     * @param {boolean} [isBlocked] 
     * @param {boolean} [isRegularSchedule] 
     * @param {boolean} [includeProviderServiceSchedule] 
     * @param {string} [providerId] 
     * @param {'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'} [month] 
     * @param {Array<number>} [providerServiceIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public scheduleControllerFindSchedulesByProviderId(startDate?: string, endDate?: string, selectedDate?: string, day?: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday', isSpecificDate?: boolean, isBlocked?: boolean, isRegularSchedule?: boolean, includeProviderServiceSchedule?: boolean, providerId?: string, month?: 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december', providerServiceIds?: Array<number>, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).scheduleControllerFindSchedulesByProviderId(startDate, endDate, selectedDate, day, isSpecificDate, isBlocked, isRegularSchedule, includeProviderServiceSchedule, providerId, month, providerServiceIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public scheduleControllerRemoveSchedule(id: string, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).scheduleControllerRemoveSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} scheduleId 
     * @param {UpdateScheduleDto} updateScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public scheduleControllerUpdateSchedule(scheduleId: string, updateScheduleDto: UpdateScheduleDto, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).scheduleControllerUpdateSchedule(scheduleId, updateScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadControllerUploadFile: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadControllerUploadFile(path?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadControllerUploadFile(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadControllerUploadFile(path?: string, options?: any): AxiosPromise<object> {
            return localVarFp.uploadControllerUploadFile(path, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @param {string} [path] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadControllerUploadFile(path?: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadControllerUploadFile(path, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserOperationsApi - axios parameter creator
 * @export
 */
export const UserOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerActivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userOperationControllerActivate', 'id', id)
            const localVarPath = `/api/v1/operation-users/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerDeactivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userOperationControllerDeactivate', 'id', id)
            const localVarPath = `/api/v1/operation-users/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userOperationControllerDelete', 'id', id)
            const localVarPath = `/api/v1/operation-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerFindCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operation-users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userOperationControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/operation-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT'} [userType] 
         * @param {'HEALTH_HUB' | 'ESHOP'} [userAppType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerGetAll: async (userType?: 'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT', userAppType?: 'HEALTH_HUB' | 'ESHOP', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operation-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }

            if (userAppType !== undefined) {
                localVarQueryParameter['userAppType'] = userAppType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserOperationDto} createUserOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerRegisterUser: async (createUserOperationDto: CreateUserOperationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserOperationDto' is not null or undefined
            assertParamExists('userOperationControllerRegisterUser', 'createUserOperationDto', createUserOperationDto)
            const localVarPath = `/api/v1/operation-users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserOperationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserOperationDto} updateUserOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerUpdate: async (id: string, updateUserOperationDto: UpdateUserOperationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userOperationControllerUpdate', 'id', id)
            // verify required parameter 'updateUserOperationDto' is not null or undefined
            assertParamExists('userOperationControllerUpdate', 'updateUserOperationDto', updateUserOperationDto)
            const localVarPath = `/api/v1/operation-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserOperationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateEmailDto} validateEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerValidateEmail: async (validateEmailDto: ValidateEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateEmailDto' is not null or undefined
            assertParamExists('userOperationControllerValidateEmail', 'validateEmailDto', validateEmailDto)
            const localVarPath = `/api/v1/operation-users/validate-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserOperationsApi - functional programming interface
 * @export
 */
export const UserOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerActivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerActivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerDeactivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerDeactivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerFindCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerFindCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT'} [userType] 
         * @param {'HEALTH_HUB' | 'ESHOP'} [userAppType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerGetAll(userType?: 'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT', userAppType?: 'HEALTH_HUB' | 'ESHOP', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOperation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerGetAll(userType, userAppType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserOperationDto} createUserOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerRegisterUser(createUserOperationDto: CreateUserOperationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerRegisterUser(createUserOperationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserOperationDto} updateUserOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerUpdate(id: string, updateUserOperationDto: UpdateUserOperationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerUpdate(id, updateUserOperationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateEmailDto} validateEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOperationControllerValidateEmail(validateEmailDto: ValidateEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOperationControllerValidateEmail(validateEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserOperationsApi - factory interface
 * @export
 */
export const UserOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserOperationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerActivate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userOperationControllerActivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerDeactivate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userOperationControllerDeactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userOperationControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerFindCurrentUser(options?: any): AxiosPromise<UserOperation> {
            return localVarFp.userOperationControllerFindCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerFindOne(id: string, options?: any): AxiosPromise<UserOperation> {
            return localVarFp.userOperationControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT'} [userType] 
         * @param {'HEALTH_HUB' | 'ESHOP'} [userAppType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerGetAll(userType?: 'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT', userAppType?: 'HEALTH_HUB' | 'ESHOP', options?: any): AxiosPromise<Array<UserOperation>> {
            return localVarFp.userOperationControllerGetAll(userType, userAppType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserOperationDto} createUserOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerRegisterUser(createUserOperationDto: CreateUserOperationDto, options?: any): AxiosPromise<object> {
            return localVarFp.userOperationControllerRegisterUser(createUserOperationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserOperationDto} updateUserOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerUpdate(id: string, updateUserOperationDto: UpdateUserOperationDto, options?: any): AxiosPromise<UserOperation> {
            return localVarFp.userOperationControllerUpdate(id, updateUserOperationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateEmailDto} validateEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOperationControllerValidateEmail(validateEmailDto: ValidateEmailDto, options?: any): AxiosPromise<void> {
            return localVarFp.userOperationControllerValidateEmail(validateEmailDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserOperationsApi - object-oriented interface
 * @export
 * @class UserOperationsApi
 * @extends {BaseAPI}
 */
export class UserOperationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerActivate(id: string, options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerActivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerDeactivate(id: string, options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerDeactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerDelete(id: string, options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerFindCurrentUser(options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerFindCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT'} [userType] 
     * @param {'HEALTH_HUB' | 'ESHOP'} [userAppType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerGetAll(userType?: 'ADMIN' | 'FINANCE' | 'MARKETING' | 'MERCHANT', userAppType?: 'HEALTH_HUB' | 'ESHOP', options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerGetAll(userType, userAppType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserOperationDto} createUserOperationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerRegisterUser(createUserOperationDto: CreateUserOperationDto, options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerRegisterUser(createUserOperationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateUserOperationDto} updateUserOperationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerUpdate(id: string, updateUserOperationDto: UpdateUserOperationDto, options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerUpdate(id, updateUserOperationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateEmailDto} validateEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOperationsApi
     */
    public userOperationControllerValidateEmail(validateEmailDto: ValidateEmailDto, options?: AxiosRequestConfig) {
        return UserOperationsApiFp(this.configuration).userOperationControllerValidateEmail(validateEmailDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserProvidersApi - axios parameter creator
 * @export
 */
export const UserProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerActivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userProviderControllerActivate', 'id', id)
            const localVarPath = `/api/v1/provider-users/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerDeactivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userProviderControllerDeactivate', 'id', id)
            const localVarPath = `/api/v1/provider-users/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerFindCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userProviderControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/provider-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isEshopMerchantsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerGetAll: async (isEshopMerchantsOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/provider-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isEshopMerchantsOnly !== undefined) {
                localVarQueryParameter['isEshopMerchantsOnly'] = isEshopMerchantsOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserProviderDto} createUserProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerRegisterUser: async (createUserProviderDto: CreateUserProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserProviderDto' is not null or undefined
            assertParamExists('userProviderControllerRegisterUser', 'createUserProviderDto', createUserProviderDto)
            const localVarPath = `/api/v1/provider-users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserProviderDto} updateUserProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerUpdate: async (id: string, updateUserProviderDto: UpdateUserProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userProviderControllerUpdate', 'id', id)
            // verify required parameter 'updateUserProviderDto' is not null or undefined
            assertParamExists('userProviderControllerUpdate', 'updateUserProviderDto', updateUserProviderDto)
            const localVarPath = `/api/v1/provider-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateEmailDto} validateEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerValidateEmail: async (validateEmailDto: ValidateEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateEmailDto' is not null or undefined
            assertParamExists('userProviderControllerValidateEmail', 'validateEmailDto', validateEmailDto)
            const localVarPath = `/api/v1/provider-users/validate-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProvidersApi - functional programming interface
 * @export
 */
export const UserProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerActivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerActivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerDeactivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerDeactivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerFindCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerFindCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isEshopMerchantsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerGetAll(isEshopMerchantsOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerGetAll(isEshopMerchantsOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserProviderDto} createUserProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerRegisterUser(createUserProviderDto: CreateUserProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerRegisterUser(createUserProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserProviderDto} updateUserProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerUpdate(id: string, updateUserProviderDto: UpdateUserProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerUpdate(id, updateUserProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateEmailDto} validateEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProviderControllerValidateEmail(validateEmailDto: ValidateEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProviderControllerValidateEmail(validateEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProvidersApi - factory interface
 * @export
 */
export const UserProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerActivate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userProviderControllerActivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerDeactivate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userProviderControllerDeactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerFindCurrentUser(options?: any): AxiosPromise<UserProvider> {
            return localVarFp.userProviderControllerFindCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerFindOne(id: string, options?: any): AxiosPromise<UserProvider> {
            return localVarFp.userProviderControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isEshopMerchantsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerGetAll(isEshopMerchantsOnly?: boolean, options?: any): AxiosPromise<Array<UserProvider>> {
            return localVarFp.userProviderControllerGetAll(isEshopMerchantsOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserProviderDto} createUserProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerRegisterUser(createUserProviderDto: CreateUserProviderDto, options?: any): AxiosPromise<object> {
            return localVarFp.userProviderControllerRegisterUser(createUserProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserProviderDto} updateUserProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerUpdate(id: string, updateUserProviderDto: UpdateUserProviderDto, options?: any): AxiosPromise<UserProvider> {
            return localVarFp.userProviderControllerUpdate(id, updateUserProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateEmailDto} validateEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProviderControllerValidateEmail(validateEmailDto: ValidateEmailDto, options?: any): AxiosPromise<void> {
            return localVarFp.userProviderControllerValidateEmail(validateEmailDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProvidersApi - object-oriented interface
 * @export
 * @class UserProvidersApi
 * @extends {BaseAPI}
 */
export class UserProvidersApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerActivate(id: string, options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerActivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerDeactivate(id: string, options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerDeactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerFindCurrentUser(options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerFindCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isEshopMerchantsOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerGetAll(isEshopMerchantsOnly?: boolean, options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerGetAll(isEshopMerchantsOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserProviderDto} createUserProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerRegisterUser(createUserProviderDto: CreateUserProviderDto, options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerRegisterUser(createUserProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateUserProviderDto} updateUserProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerUpdate(id: string, updateUserProviderDto: UpdateUserProviderDto, options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerUpdate(id, updateUserProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateEmailDto} validateEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProvidersApi
     */
    public userProviderControllerValidateEmail(validateEmailDto: ValidateEmailDto, options?: AxiosRequestConfig) {
        return UserProvidersApiFp(this.configuration).userProviderControllerValidateEmail(validateEmailDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserVerificationApi - axios parameter creator
 * @export
 */
export const UserVerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckEmailOtpVerificationValidityDto} checkEmailOtpVerificationValidityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerCheckEmailOtpVerificationValidity: async (checkEmailOtpVerificationValidityDto: CheckEmailOtpVerificationValidityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkEmailOtpVerificationValidityDto' is not null or undefined
            assertParamExists('userVerificationControllerCheckEmailOtpVerificationValidity', 'checkEmailOtpVerificationValidityDto', checkEmailOtpVerificationValidityDto)
            const localVarPath = `/api/v1/user-verification/email/otp/verification/validity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEmailOtpVerificationValidityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerCheckVerification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-verification/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerSendEmailOtp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-verification/email/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRequestSmsVerificationDto} userRequestSmsVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerSendSmsVerificationOtp: async (userRequestSmsVerificationDto: UserRequestSmsVerificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestSmsVerificationDto' is not null or undefined
            assertParamExists('userVerificationControllerSendSmsVerificationOtp', 'userRequestSmsVerificationDto', userRequestSmsVerificationDto)
            const localVarPath = `/api/v1/user-verification/send/sms/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestSmsVerificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {UserVerificationSendDto} userVerificationSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerSendVerificationEmail: async (type: string, userVerificationSendDto: UserVerificationSendDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('userVerificationControllerSendVerificationEmail', 'type', type)
            // verify required parameter 'userVerificationSendDto' is not null or undefined
            assertParamExists('userVerificationControllerSendVerificationEmail', 'userVerificationSendDto', userVerificationSendDto)
            const localVarPath = `/api/v1/user-verification/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userVerificationSendDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserVerificationDto} userVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerVerifyCode: async (userVerificationDto: UserVerificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userVerificationDto' is not null or undefined
            assertParamExists('userVerificationControllerVerifyCode', 'userVerificationDto', userVerificationDto)
            const localVarPath = `/api/v1/user-verification/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userVerificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOtpDto} verifyOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerVerifyEmailOtp: async (verifyOtpDto: VerifyOtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyOtpDto' is not null or undefined
            assertParamExists('userVerificationControllerVerifyEmailOtp', 'verifyOtpDto', verifyOtpDto)
            const localVarPath = `/api/v1/user-verification/email/otp/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyOtpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSmsVerificationDto} userSmsVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerVerifySmsOtpCode: async (userSmsVerificationDto: UserSmsVerificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSmsVerificationDto' is not null or undefined
            assertParamExists('userVerificationControllerVerifySmsOtpCode', 'userSmsVerificationDto', userSmsVerificationDto)
            const localVarPath = `/api/v1/user-verification/verify/sms/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSmsVerificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserVerificationApi - functional programming interface
 * @export
 */
export const UserVerificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserVerificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckEmailOtpVerificationValidityDto} checkEmailOtpVerificationValidityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerCheckEmailOtpVerificationValidity(checkEmailOtpVerificationValidityDto: CheckEmailOtpVerificationValidityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerCheckEmailOtpVerificationValidity(checkEmailOtpVerificationValidityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerCheckVerification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerCheckVerification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerSendEmailOtp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerSendEmailOtp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserRequestSmsVerificationDto} userRequestSmsVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerSendSmsVerificationOtp(userRequestSmsVerificationDto: UserRequestSmsVerificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerSendSmsVerificationOtp(userRequestSmsVerificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {UserVerificationSendDto} userVerificationSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerSendVerificationEmail(type: string, userVerificationSendDto: UserVerificationSendDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerSendVerificationEmail(type, userVerificationSendDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserVerificationDto} userVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerVerifyCode(userVerificationDto: UserVerificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerVerifyCode(userVerificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyOtpDto} verifyOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerVerifyEmailOtp(verifyOtpDto: VerifyOtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerVerifyEmailOtp(verifyOtpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSmsVerificationDto} userSmsVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerificationControllerVerifySmsOtpCode(userSmsVerificationDto: UserSmsVerificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerificationControllerVerifySmsOtpCode(userSmsVerificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserVerificationApi - factory interface
 * @export
 */
export const UserVerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserVerificationApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckEmailOtpVerificationValidityDto} checkEmailOtpVerificationValidityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerCheckEmailOtpVerificationValidity(checkEmailOtpVerificationValidityDto: CheckEmailOtpVerificationValidityDto, options?: any): AxiosPromise<object> {
            return localVarFp.userVerificationControllerCheckEmailOtpVerificationValidity(checkEmailOtpVerificationValidityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerCheckVerification(options?: any): AxiosPromise<boolean> {
            return localVarFp.userVerificationControllerCheckVerification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerSendEmailOtp(options?: any): AxiosPromise<void> {
            return localVarFp.userVerificationControllerSendEmailOtp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRequestSmsVerificationDto} userRequestSmsVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerSendSmsVerificationOtp(userRequestSmsVerificationDto: UserRequestSmsVerificationDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.userVerificationControllerSendSmsVerificationOtp(userRequestSmsVerificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {UserVerificationSendDto} userVerificationSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerSendVerificationEmail(type: string, userVerificationSendDto: UserVerificationSendDto, options?: any): AxiosPromise<void> {
            return localVarFp.userVerificationControllerSendVerificationEmail(type, userVerificationSendDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserVerificationDto} userVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerVerifyCode(userVerificationDto: UserVerificationDto, options?: any): AxiosPromise<void> {
            return localVarFp.userVerificationControllerVerifyCode(userVerificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyOtpDto} verifyOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerVerifyEmailOtp(verifyOtpDto: VerifyOtpDto, options?: any): AxiosPromise<void> {
            return localVarFp.userVerificationControllerVerifyEmailOtp(verifyOtpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSmsVerificationDto} userSmsVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerificationControllerVerifySmsOtpCode(userSmsVerificationDto: UserSmsVerificationDto, options?: any): AxiosPromise<void> {
            return localVarFp.userVerificationControllerVerifySmsOtpCode(userSmsVerificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserVerificationApi - object-oriented interface
 * @export
 * @class UserVerificationApi
 * @extends {BaseAPI}
 */
export class UserVerificationApi extends BaseAPI {
    /**
     * 
     * @param {CheckEmailOtpVerificationValidityDto} checkEmailOtpVerificationValidityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerCheckEmailOtpVerificationValidity(checkEmailOtpVerificationValidityDto: CheckEmailOtpVerificationValidityDto, options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerCheckEmailOtpVerificationValidity(checkEmailOtpVerificationValidityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerCheckVerification(options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerCheckVerification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerSendEmailOtp(options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerSendEmailOtp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRequestSmsVerificationDto} userRequestSmsVerificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerSendSmsVerificationOtp(userRequestSmsVerificationDto: UserRequestSmsVerificationDto, options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerSendSmsVerificationOtp(userRequestSmsVerificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {UserVerificationSendDto} userVerificationSendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerSendVerificationEmail(type: string, userVerificationSendDto: UserVerificationSendDto, options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerSendVerificationEmail(type, userVerificationSendDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserVerificationDto} userVerificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerVerifyCode(userVerificationDto: UserVerificationDto, options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerVerifyCode(userVerificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyOtpDto} verifyOtpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerVerifyEmailOtp(verifyOtpDto: VerifyOtpDto, options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerVerifyEmailOtp(verifyOtpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSmsVerificationDto} userSmsVerificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserVerificationApi
     */
    public userVerificationControllerVerifySmsOtpCode(userSmsVerificationDto: UserSmsVerificationDto, options?: AxiosRequestConfig) {
        return UserVerificationApiFp(this.configuration).userVerificationControllerVerifySmsOtpCode(userSmsVerificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'cancelled' | 'no_feedback' | 'past' | 'upcoming'} status 
         * @param {string} sortBy 
         * @param {string} isNextAppointmentOnly 
         * @param {string} platform 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindCurrentUserAppointments: async (status: 'cancelled' | 'no_feedback' | 'past' | 'upcoming', sortBy: string, isNextAppointmentOnly: string, platform: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('userControllerFindCurrentUserAppointments', 'status', status)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('userControllerFindCurrentUserAppointments', 'sortBy', sortBy)
            // verify required parameter 'isNextAppointmentOnly' is not null or undefined
            assertParamExists('userControllerFindCurrentUserAppointments', 'isNextAppointmentOnly', isNextAppointmentOnly)
            // verify required parameter 'platform' is not null or undefined
            assertParamExists('userControllerFindCurrentUserAppointments', 'platform', platform)
            const localVarPath = `/api/v1/users/me/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (isNextAppointmentOnly !== undefined) {
                localVarQueryParameter['isNextAppointmentOnly'] = isNextAppointmentOnly;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOCRDto} getOCRDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetOCR: async (getOCRDto: GetOCRDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOCRDto' is not null or undefined
            assertParamExists('userControllerGetOCR', 'getOCRDto', getOCRDto)
            const localVarPath = `/api/v1/users/ocr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOCRDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slugName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetShareLinks: async (slugName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slugName' is not null or undefined
            assertParamExists('userControllerGetShareLinks', 'slugName', slugName)
            const localVarPath = `/api/v1/users/{slugName}/share-links`
                .replace(`{${"slugName"}}`, encodeURIComponent(String(slugName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginAutomatically: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/login/automations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginWithEmailDto} loginWithEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginUser: async (loginWithEmailDto: LoginWithEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithEmailDto' is not null or undefined
            assertParamExists('userControllerLoginUser', 'loginWithEmailDto', loginWithEmailDto)
            const localVarPath = `/api/v1/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginVerifyOtpNumber: async (verifyOtpNumberDto: VerifyOtpNumberDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyOtpNumberDto' is not null or undefined
            assertParamExists('userControllerLoginVerifyOtpNumber', 'verifyOtpNumberDto', verifyOtpNumberDto)
            const localVarPath = `/api/v1/users/login/phone-number/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyOtpNumberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mwellAccessToken 
         * @param {string} mwellPlatform 
         * @param {string} mwellRefreshToken 
         * @param {string} mwellEnv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginWithMWellAccessToken: async (mwellAccessToken: string, mwellPlatform: string, mwellRefreshToken: string, mwellEnv: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mwellAccessToken' is not null or undefined
            assertParamExists('userControllerLoginWithMWellAccessToken', 'mwellAccessToken', mwellAccessToken)
            // verify required parameter 'mwellPlatform' is not null or undefined
            assertParamExists('userControllerLoginWithMWellAccessToken', 'mwellPlatform', mwellPlatform)
            // verify required parameter 'mwellRefreshToken' is not null or undefined
            assertParamExists('userControllerLoginWithMWellAccessToken', 'mwellRefreshToken', mwellRefreshToken)
            // verify required parameter 'mwellEnv' is not null or undefined
            assertParamExists('userControllerLoginWithMWellAccessToken', 'mwellEnv', mwellEnv)
            const localVarPath = `/api/v1/users/login/mwell-access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mwellAccessToken != null) {
                localVarHeaderParameter['mwell-access-token'] = String(mwellAccessToken);
            }

            if (mwellPlatform != null) {
                localVarHeaderParameter['mwell-platform'] = String(mwellPlatform);
            }

            if (mwellRefreshToken != null) {
                localVarHeaderParameter['mwell-refresh-token'] = String(mwellRefreshToken);
            }

            if (mwellEnv != null) {
                localVarHeaderParameter['mwell-env'] = String(mwellEnv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginWithPhoneNumberDto} loginWithPhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginWithPhoneNumber: async (loginWithPhoneNumberDto: LoginWithPhoneNumberDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithPhoneNumberDto' is not null or undefined
            assertParamExists('userControllerLoginWithPhoneNumber', 'loginWithPhoneNumberDto', loginWithPhoneNumberDto)
            const localVarPath = `/api/v1/users/login/phone-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithPhoneNumberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRegisterVerifyOtpNumber: async (verifyOtpNumberDto: VerifyOtpNumberDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyOtpNumberDto' is not null or undefined
            assertParamExists('userControllerRegisterVerifyOtpNumber', 'verifyOtpNumberDto', verifyOtpNumberDto)
            const localVarPath = `/api/v1/users/register/phone-number/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyOtpNumberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterWithPhoneNumberDto} registerWithPhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRegisterWithPhoneNumber: async (registerWithPhoneNumberDto: RegisterWithPhoneNumberDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerWithPhoneNumberDto' is not null or undefined
            assertParamExists('userControllerRegisterWithPhoneNumber', 'registerWithPhoneNumberDto', registerWithPhoneNumberDto)
            const localVarPath = `/api/v1/users/register/phone-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerWithPhoneNumberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mwellAccessToken 
         * @param {string} mwellRefreshToken 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSaveUserDetails: async (mwellAccessToken: string, mwellRefreshToken: string, createUserDto: CreateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mwellAccessToken' is not null or undefined
            assertParamExists('userControllerSaveUserDetails', 'mwellAccessToken', mwellAccessToken)
            // verify required parameter 'mwellRefreshToken' is not null or undefined
            assertParamExists('userControllerSaveUserDetails', 'mwellRefreshToken', mwellRefreshToken)
            // verify required parameter 'createUserDto' is not null or undefined
            assertParamExists('userControllerSaveUserDetails', 'createUserDto', createUserDto)
            const localVarPath = `/api/v1/users/register/phone-number/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mwellAccessToken != null) {
                localVarHeaderParameter['mwell-access-token'] = String(mwellAccessToken);
            }

            if (mwellRefreshToken != null) {
                localVarHeaderParameter['mwell-refresh-token'] = String(mwellRefreshToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSyncCurrentUserWithMWellData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateCurrentUser: async (updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdateCurrentUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadDisplayPhotoDto} uploadDisplayPhotoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUploadDisplayPhoto: async (uploadDisplayPhotoDto: UploadDisplayPhotoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadDisplayPhotoDto' is not null or undefined
            assertParamExists('userControllerUploadDisplayPhoto', 'uploadDisplayPhotoDto', uploadDisplayPhotoDto)
            const localVarPath = `/api/v1/users/me/display-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadDisplayPhotoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'cancelled' | 'no_feedback' | 'past' | 'upcoming'} status 
         * @param {string} sortBy 
         * @param {string} isNextAppointmentOnly 
         * @param {string} platform 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindCurrentUserAppointments(status: 'cancelled' | 'no_feedback' | 'past' | 'upcoming', sortBy: string, isNextAppointmentOnly: string, platform: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindCurrentUserAppointments(status, sortBy, isNextAppointmentOnly, platform, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetOCRDto} getOCRDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetOCR(getOCRDto: GetOCRDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOcrDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetOCR(getOCRDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slugName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetShareLinks(slugName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProviderShareLinkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetShareLinks(slugName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLoginAutomatically(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLoginAutomatically(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginWithEmailDto} loginWithEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLoginUser(loginWithEmailDto: LoginWithEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLoginUser(loginWithEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLoginVerifyOtpNumber(verifyOtpNumberDto: VerifyOtpNumberDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyOtpNumberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLoginVerifyOtpNumber(verifyOtpNumberDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mwellAccessToken 
         * @param {string} mwellPlatform 
         * @param {string} mwellRefreshToken 
         * @param {string} mwellEnv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLoginWithMWellAccessToken(mwellAccessToken: string, mwellPlatform: string, mwellRefreshToken: string, mwellEnv: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLoginWithMWellAccessToken(mwellAccessToken, mwellPlatform, mwellRefreshToken, mwellEnv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginWithPhoneNumberDto} loginWithPhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLoginWithPhoneNumber(loginWithPhoneNumberDto: LoginWithPhoneNumberDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLoginWithPhoneNumber(loginWithPhoneNumberDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerRegisterVerifyOtpNumber(verifyOtpNumberDto: VerifyOtpNumberDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyOtpNumberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerRegisterVerifyOtpNumber(verifyOtpNumberDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterWithPhoneNumberDto} registerWithPhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerRegisterWithPhoneNumber(registerWithPhoneNumberDto: RegisterWithPhoneNumberDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerRegisterWithPhoneNumber(registerWithPhoneNumberDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mwellAccessToken 
         * @param {string} mwellRefreshToken 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSaveUserDetails(mwellAccessToken: string, mwellRefreshToken: string, createUserDto: CreateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSaveUserDetails(mwellAccessToken, mwellRefreshToken, createUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSyncCurrentUserWithMWellData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSyncCurrentUserWithMWellData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateCurrentUser(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateCurrentUser(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadDisplayPhotoDto} uploadDisplayPhotoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUploadDisplayPhoto(uploadDisplayPhotoDto: UploadDisplayPhotoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUploadDisplayPhoto(uploadDisplayPhotoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindCurrentUser(options?: any): AxiosPromise<User> {
            return localVarFp.userControllerFindCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'cancelled' | 'no_feedback' | 'past' | 'upcoming'} status 
         * @param {string} sortBy 
         * @param {string} isNextAppointmentOnly 
         * @param {string} platform 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindCurrentUserAppointments(status: 'cancelled' | 'no_feedback' | 'past' | 'upcoming', sortBy: string, isNextAppointmentOnly: string, platform: string, limit?: number, page?: number, options?: any): AxiosPromise<object> {
            return localVarFp.userControllerFindCurrentUserAppointments(status, sortBy, isNextAppointmentOnly, platform, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOCRDto} getOCRDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetOCR(getOCRDto: GetOCRDto, options?: any): AxiosPromise<ResponseOcrDto> {
            return localVarFp.userControllerGetOCR(getOCRDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slugName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetShareLinks(slugName: string, options?: any): AxiosPromise<ResponseProviderShareLinkDto> {
            return localVarFp.userControllerGetShareLinks(slugName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginAutomatically(options?: any): AxiosPromise<object> {
            return localVarFp.userControllerLoginAutomatically(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginWithEmailDto} loginWithEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginUser(loginWithEmailDto: LoginWithEmailDto, options?: any): AxiosPromise<object> {
            return localVarFp.userControllerLoginUser(loginWithEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginVerifyOtpNumber(verifyOtpNumberDto: VerifyOtpNumberDto, options?: any): AxiosPromise<VerifyOtpNumberResponseDto> {
            return localVarFp.userControllerLoginVerifyOtpNumber(verifyOtpNumberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mwellAccessToken 
         * @param {string} mwellPlatform 
         * @param {string} mwellRefreshToken 
         * @param {string} mwellEnv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginWithMWellAccessToken(mwellAccessToken: string, mwellPlatform: string, mwellRefreshToken: string, mwellEnv: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerLoginWithMWellAccessToken(mwellAccessToken, mwellPlatform, mwellRefreshToken, mwellEnv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginWithPhoneNumberDto} loginWithPhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginWithPhoneNumber(loginWithPhoneNumberDto: LoginWithPhoneNumberDto, options?: any): AxiosPromise<object> {
            return localVarFp.userControllerLoginWithPhoneNumber(loginWithPhoneNumberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLogout(options?: any): AxiosPromise<boolean> {
            return localVarFp.userControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRegisterVerifyOtpNumber(verifyOtpNumberDto: VerifyOtpNumberDto, options?: any): AxiosPromise<VerifyOtpNumberResponseDto> {
            return localVarFp.userControllerRegisterVerifyOtpNumber(verifyOtpNumberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterWithPhoneNumberDto} registerWithPhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRegisterWithPhoneNumber(registerWithPhoneNumberDto: RegisterWithPhoneNumberDto, options?: any): AxiosPromise<object> {
            return localVarFp.userControllerRegisterWithPhoneNumber(registerWithPhoneNumberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mwellAccessToken 
         * @param {string} mwellRefreshToken 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSaveUserDetails(mwellAccessToken: string, mwellRefreshToken: string, createUserDto: CreateUserDto, options?: any): AxiosPromise<CreateUserResponseDto> {
            return localVarFp.userControllerSaveUserDetails(mwellAccessToken, mwellRefreshToken, createUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSyncCurrentUserWithMWellData(options?: any): AxiosPromise<void> {
            return localVarFp.userControllerSyncCurrentUserWithMWellData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateCurrentUser(updateUserDto: UpdateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerUpdateCurrentUser(updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadDisplayPhotoDto} uploadDisplayPhotoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUploadDisplayPhoto(uploadDisplayPhotoDto: UploadDisplayPhotoDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUploadDisplayPhoto(uploadDisplayPhotoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerFindCurrentUser(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerFindCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'cancelled' | 'no_feedback' | 'past' | 'upcoming'} status 
     * @param {string} sortBy 
     * @param {string} isNextAppointmentOnly 
     * @param {string} platform 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerFindCurrentUserAppointments(status: 'cancelled' | 'no_feedback' | 'past' | 'upcoming', sortBy: string, isNextAppointmentOnly: string, platform: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerFindCurrentUserAppointments(status, sortBy, isNextAppointmentOnly, platform, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOCRDto} getOCRDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetOCR(getOCRDto: GetOCRDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetOCR(getOCRDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slugName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetShareLinks(slugName: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetShareLinks(slugName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerLoginAutomatically(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerLoginAutomatically(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginWithEmailDto} loginWithEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerLoginUser(loginWithEmailDto: LoginWithEmailDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerLoginUser(loginWithEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerLoginVerifyOtpNumber(verifyOtpNumberDto: VerifyOtpNumberDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerLoginVerifyOtpNumber(verifyOtpNumberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mwellAccessToken 
     * @param {string} mwellPlatform 
     * @param {string} mwellRefreshToken 
     * @param {string} mwellEnv 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerLoginWithMWellAccessToken(mwellAccessToken: string, mwellPlatform: string, mwellRefreshToken: string, mwellEnv: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerLoginWithMWellAccessToken(mwellAccessToken, mwellPlatform, mwellRefreshToken, mwellEnv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginWithPhoneNumberDto} loginWithPhoneNumberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerLoginWithPhoneNumber(loginWithPhoneNumberDto: LoginWithPhoneNumberDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerLoginWithPhoneNumber(loginWithPhoneNumberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerLogout(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyOtpNumberDto} verifyOtpNumberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerRegisterVerifyOtpNumber(verifyOtpNumberDto: VerifyOtpNumberDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerRegisterVerifyOtpNumber(verifyOtpNumberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterWithPhoneNumberDto} registerWithPhoneNumberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerRegisterWithPhoneNumber(registerWithPhoneNumberDto: RegisterWithPhoneNumberDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerRegisterWithPhoneNumber(registerWithPhoneNumberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mwellAccessToken 
     * @param {string} mwellRefreshToken 
     * @param {CreateUserDto} createUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerSaveUserDetails(mwellAccessToken: string, mwellRefreshToken: string, createUserDto: CreateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerSaveUserDetails(mwellAccessToken, mwellRefreshToken, createUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerSyncCurrentUserWithMWellData(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerSyncCurrentUserWithMWellData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateCurrentUser(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUpdateCurrentUser(updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadDisplayPhotoDto} uploadDisplayPhotoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUploadDisplayPhoto(uploadDisplayPhotoDto: UploadDisplayPhotoDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUploadDisplayPhoto(uploadDisplayPhotoDto, options).then((request) => request(this.axios, this.basePath));
    }
}


