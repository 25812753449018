import { HTMLProps } from 'react';

import { Address } from '@healthhub/api-lib';
import { MapIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import { formatCompleteAddress } from '@mwell-healthhub/commons';

type Props = HTMLProps<HTMLAnchorElement> & {
  address?: Address;
  iconDimensions?: string;
};

export default function ViewInMapButton(props: Props) {
  const { address, iconDimensions = 'h-5 w-5', className = '' } = props;

  const { googleMapsUrl } = address ?? {};
  const formattedAddress = formatCompleteAddress(address);

  if (!googleMapsUrl) {
    return null;
  }

  const getMapUrl = () => {
    if (googleMapsUrl) {
      return googleMapsUrl;
    }

    const isIosDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const baseURL = isIosDevice ? 'http://maps.apple.com' : 'https://google.com/maps';
    const addressQuery = isIosDevice
      ? `/?address=${encodeURIComponent(formattedAddress)}`
      : `/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;

    return `${baseURL}${addressQuery}`;
  };

  const mapUrl = getMapUrl();

  return (
    <a
      href={mapUrl}
      className={clsx(
        'flex items-center justify-center gap-1.5 whitespace-nowrap rounded-md px-3 py-2 text-xs font-medium text-primary-500 outline outline-1 outline-primary-500',
        className,
      )}
    >
      <MapIcon className={`${iconDimensions} text-primary-500`} />
      View in Map
    </a>
  );
}
