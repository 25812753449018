/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateScheduleDto, UpdateScheduleDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createSchedule, removeScheduleById, updateSchedule } from '../../api';

export const useCreateSchedule = (
  mutationOptions: MutationOptions<
    any,
    AxiosError<{ message: string }>,
    { scheduleData: CreateScheduleDto }
  >,
) => {
  return useMutation(({ scheduleData }) => createSchedule(scheduleData), mutationOptions);
};

export const useRemoveScheduleById = (
  mutationOptions: MutationOptions<
    any,
    AxiosError<{ message: string }>,
    { scheduleId: string; providerId?: string }
  >,
) => {
  return useMutation(
    ({ scheduleId, providerId }) => removeScheduleById(scheduleId, providerId),
    mutationOptions,
  );
};

export const useUpdateScheduleMutation = (
  mutationOptions: MutationOptions<
    any,
    AxiosError<{ message: string }>,
    {
      scheduleId: string;
      scheduleData: UpdateScheduleDto;
    }
  >,
) => {
  return useMutation(
    ({ scheduleId, scheduleData }) => updateSchedule(scheduleId, scheduleData),
    mutationOptions,
  );
};
