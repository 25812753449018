import { CreateProviderServiceDto, UpdateProviderServiceDto } from '@healthhub/api-lib';

import { restrictedCharactersMap } from '../constants/restrictedCharactersMap';

type ProviderServiceDTO = CreateProviderServiceDto | UpdateProviderServiceDto;

// Workaround to bypass Reblaze CORS issue
export function encodeProviderServiceDtoValue(
  createProviderServiceDto: CreateProviderServiceDto,
  key: keyof CreateProviderServiceDto = 'description',
) {
  (createProviderServiceDto[key] as string) =
    (createProviderServiceDto[key] as string)?.replace(/-/gi, '[dash]') ?? '';

  return createProviderServiceDto;
}

export function decodeProviderServiceDtoValue(
  createProviderServiceDto: CreateProviderServiceDto,
  key: keyof CreateProviderServiceDto = 'description',
) {
  (createProviderServiceDto[key] as string) =
    (createProviderServiceDto[key] as string)?.replace(/\[dash\]/gi, '-') ?? '';

  return createProviderServiceDto;
}

export function normalizeProviderQuerySearchValue(query: string) {
  return query.replace(/\s[^a-zA-Z0-9]?(and|or)\b/gi, '') ?? '';
}

export function encodeDtoValue<T extends Record<string, any>>(dtoObj: T): T {
  const encodeObj = Object.fromEntries(
    Object.entries(dtoObj).map(([key, value]) => [
      key,
      typeof value === 'string'
        ? value.replace(/and /gi, '[[&]]').replace(/or /gi, '[[/]]')
        : value,
    ]),
  );
  return { ...dtoObj, ...encodeObj } as T;
}

export function encodeHtmlDtoValue<T extends Record<string, any>>(dtoObj: T): T {
  const encodeObj = Object.fromEntries(
    Object.entries(dtoObj).map(([key, value]) => [
      key,
      typeof value === 'string'
        ? value
            .replace(/and /gi, '[[&]]')
            .replace(/or /gi, '[[/]]')
            .replace(/<\/?[^>]+>/gi, (match) => {
              if (match.startsWith('</')) {
                return `[[/${match.slice(2, -1)}]]`;
              } else if (match.endsWith('/>')) {
                return `[[${match.slice(1, -2).trim()}/]]`;
              } else {
                return `[[${match.slice(1, -1).trim()}]]`;
              }
            })
        : value,
    ]),
  );
  return { ...dtoObj, ...encodeObj } as T;
}

export function decodeDtoValue<T extends Record<string, any>>(dtoObj: T): T {
  const decodedObj = Object.fromEntries(
    Object.entries(dtoObj).map(([key, value]) => [
      key,
      typeof value === 'string'
        ? value.replace(/\[\[&\]\]/gi, 'and ').replace(/\[\[\/\]\]/gi, 'or ')
        : value,
    ]),
  );
  return { ...dtoObj, ...decodedObj } as T;
}

export function decodeHtmlDtoValue<T extends Record<string, any>>(dtoObj: T): T {
  const decodedObj = Object.fromEntries(
    Object.entries(dtoObj).map(([key, value]) => [
      key,
      typeof value === 'string'
        ? value
            .replace(/\[\[&\]\]/gi, 'and ')
            .replace(/\[\[\/\]\]/gi, 'or ')
            .replace(/\[\[\/?([^\]]+)\]\]/g, (match) => {
              if (match.startsWith('[[/')) {
                return `</${match.slice(3, -2)}>`;
              } else if (match.endsWith('/]]')) {
                return `<${match.slice(2, -3).trim()} />`;
              } else {
                return `<${match.slice(2, -2).trim()}>`;
              }
            })
        : value,
    ]),
  );
  return { ...dtoObj, ...decodedObj } as T;
}

export function encodeMappedDtoValue<T extends ProviderServiceDTO>(providerServiceDTO: T): T {
  const key = 'description';
  const value = providerServiceDTO[key] as string;

  let encodedValue = '';
  for (const char of value) {
    encodedValue += restrictedCharactersMap[char] || char;
  }

  encodedValue = encodedValue.replace(/\band\b/gi, '[[&]]').replace(/\bor\b/gi, '[[/]]');

  (providerServiceDTO[key] as string) = encodedValue;

  return providerServiceDTO;
}

export function decodeProviderServiceDescription(description = '') {
  if (!description) {
    return '';
  }
  // Create a reversed map where the encoded values map to their original characters
  const reversedMap = Object.fromEntries(
    Object.entries(restrictedCharactersMap).map(([key, value]) => [value, key]),
  );

  description = description.split('[[&]]').join('and').split('[[/]]').join('or');

  for (const encodedChar in reversedMap) {
    const originalChar = reversedMap[encodedChar];
    // Replace each occurrence of the encoded value with the original character
    description = description.split(encodedChar).join(originalChar);
  }

  return description;
}
