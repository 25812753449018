import { ProviderService } from '@healthhub/api-lib';

import {
  FullSlot,
  LoadingSpinner,
  TimeSlots,
  getDateWithTimeObject,
  useGetSelectedDateSchedule,
} from '@mwell-healthhub/commons';

type Props = {
  providerId?: number;
  providerServices?: ProviderService[];
  selectedDate: Date;
  shouldShowNumRemainingSlots?: boolean;
  onSelectDate?: (date: Date, scheduleId?: number) => void;
  onSelectTimeSlot: (timeSlot: FullSlot, date?: Date) => void;
};

export default function TimeBlocks(props: Props) {
  const {
    providerId,
    providerServices,
    selectedDate,
    shouldShowNumRemainingSlots = false,
    onSelectDate,
    onSelectTimeSlot,
  } = props;

  const { disabledTimeSlots, isGetSchedulesLoading, selectedDateSchedule } =
    useGetSelectedDateSchedule(providerId as number, selectedDate, providerServices);

  const handleSelect = (date: Date, slot?: FullSlot) => {
    if (!slot) {
      return;
    }

    const dateWithTimeObject = getDateWithTimeObject(date, slot.startTime);

    onSelectTimeSlot(slot, dateWithTimeObject);
    onSelectDate?.(dateWithTimeObject, slot.id);
  };

  if (isGetSchedulesLoading) {
    return (
      <div className="flex h-24 w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <TimeSlots
      disabledTimeSlots={disabledTimeSlots}
      isLoading={isGetSchedulesLoading}
      schedule={selectedDateSchedule}
      selectedDate={selectedDate}
      shouldShowNumRemainingSlots={shouldShowNumRemainingSlots}
      onSelect={handleSelect}
    />
  );
}
