import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import CsvDownloader from 'react-csv-downloader';
import { FaSpinner } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';

type Props = Readonly<{
  title?: string;
  csvData?: Array<any>;
  isLoading: boolean;
  headers?: {
    label: string;
    key: string;
  }[];
  containerClassName?: string;
  hasData?: boolean;
  setShouldFetch: Dispatch<SetStateAction<boolean>>;
  shouldFetch: boolean;
  onClick?: () => void;
  buttonTitle?: string;
}>;

function Reports(props: Props): JSX.Element {
  const csvRef = useRef<any>(null);
  const {
    title,
    csvData,
    isLoading,
    headers,
    containerClassName,
    hasData,
    setShouldFetch,
    shouldFetch,
    onClick,
    buttonTitle = 'Export',
  } = props;

  useEffect(() => {
    if (!isLoading && !!csvData?.length && shouldFetch) {
      csvRef.current?.handleClick();
      onClick?.();
      setShouldFetch(false);
    }
  }, [csvData, isLoading]);

  const handleDownload = () => {
    setShouldFetch(true);
  };

  const isDownloadable = !isLoading && csvData && csvData.length > 0;
  const isDownloadableText = hasData ? buttonTitle : 'No data to export';
  const buttonText = isLoading ? 'Fetching Data...' : isDownloadableText;

  const columns = headers?.map((header) => ({
    id: header.key,
    displayName: header.label,
  }));

  return (
    <div className={twMerge('relative', containerClassName)}>
      <Button
        disabled={isLoading}
        onClick={handleDownload}
        icon={isLoading ? undefined : ArrowDownTrayIcon}
        variant={hasData ? 'outlinedPrimary' : 'disabled'}
      >
        <div className="flex items-center space-x-2">
          {isLoading ? <FaSpinner className="h-4 w-4 animate-spin self-center text-white" /> : ''}
          <span className="whitespace-nowrap">{buttonText}</span>
        </div>
      </Button>

      {isDownloadable && (
        <CsvDownloader
          className="hidden"
          columns={columns}
          datas={csvData as any}
          extension=".csv"
          filename={title as string}
          ref={csvRef}
          separator=","
          wrapColumnChar={`"`}
        />
      )}
    </div>
  );
}

export default Reports;
