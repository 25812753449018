export enum ProviderOnboardingStepEnum {
  // phase 1
  SETUP_ACCOUNT = 0,
  ADD_SERVICE = 1,
  ADD_BRANCH = 2,
  SET_SCHEDULE = 3,
  VIEW_APPOINTMENT = 4,
  GUIDED_TUTORIAL_DONE = 5,

  // phase 2
  SUBMIT_DOCUMENTS = 6,
  SUBMITTED_DOCUMENTS = 7,
  SET_PROFILE = 8,

  // phase 3
  AWAITING_APPROVAL = 9,
  APPROVED = 10,
  DONE = 11,
}

export enum DocumentReviewStatusEnum {
  FOR_SUBMISSION = 'FOR_SUBMISSION',
  FOR_REVIEW = 'FOR_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
