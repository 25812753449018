import { useEffect, useMemo, useState } from 'react';

import { MinusCircleIcon, PlusIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';

import {
  API_PRODUCTS,
  API_TRANSACTION,
  API_TRANSACTION_PRODUCT,
  API_TRANSACTION_USER,
  API_VARIANT,
} from '../../constants';
import { ProductStatus, Routes } from '../../enums';
import {
  useCreateApiFieldMutation,
  useGetAllProductsQuery,
  useUpdateApiFieldsMutation,
} from '../../hooks';
import { ApiFields, EshopProduct, MultiSelectOptionType, Option } from '../../types';
import { convertObjectsToOptions } from '../../utils';
import { apiFieldsSchema } from '../../validators';
import Button from '../Button';
import Dropdown from '../Dropdown';
import FormPageLayout from '../FormPageLayout';
import MultiSelectDropdown from '../MultiSelectDropdown';
import TextInput from '../TextInput';
import toast from '../toast';

type Props = {
  apiField?: ApiFields;
};

function ApiFieldsForm(props: Props) {
  const { apiField } = props;
  const isEditing = !!apiField;
  const router = useRouter();

  const [search, setSearch] = useState(apiField ? `${apiField.productIds.join(',')},` : '');
  const { data: products, isLoading: isProductsLoading } = useGetAllProductsQuery({
    query: search,
    limit: 5,
    page: 1,
  });
  const { mutate: createApiField } = useCreateApiFieldMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const { mutate: updateApiField } = useUpdateApiFieldsMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { items } = products || {};

  function handleSuccess() {
    const message = `API ${isEditing ? 'updated' : 'created'}`;

    toast({ message: message, type: 'success' });
    router.push(Routes.API_FIELDS);
    reset();
  }

  function handleError(error: Error) {
    toast({ message: error.message, type: 'error' });
  }

  const [selectedTags, setSelectedTags] = useState<MultiSelectOptionType[]>([]);
  const [storedItems, setStoredItems] = useState<EshopProduct[]>([]);
  const productSearchptions: MultiSelectOptionType[] = useMemo(() => {
    return storedItems
      ?.filter((product) => product.status !== ProductStatus.Inactive)
      .map((product) => ({
        text: `${product?.name} - ${product.productId}${
          product.variants?.length ? ' (variant)' : ''
        }`,
        value: product.id,
      })) as unknown as MultiSelectOptionType[];
  }, [storedItems]);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  useEffect(() => {
    if (items?.length) {
      const variantItems: EshopProduct[] = [];

      items
        .filter((item) => item.variants?.length)
        .forEach((item) => {
          item.variants?.forEach((variant) => {
            variantItems.push({
              ...item,
              id: variant.id || '',
              productId: variant.sku || item.productId,
              name: `${item.name} ${Object.values(variant.attributeValues).join(' ')}`,
              variants: [variant],
            });
          });
        });

      const uniqueItems = [...storedItems, ...items, ...variantItems].filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id),
      );
      setStoredItems(uniqueItems);
    }
  }, [items]);

  useEffect(() => {
    if (storedItems?.length && apiField && isInitialLoad) {
      for (const id of apiField.productIds) {
        const product = storedItems.find((product) => product.id === id);
        if (product) {
          setSelectedTags((prev) => [
            ...prev,
            {
              text: `${product.name} - ${product.productId}${
                product.variants?.length ? ' (variant)' : ''
              }`,
              value: product.id,
            },
          ]);
        }
      }
      setIsInitialLoad(false);
    }
  }, [storedItems]);

  const formMethods = useForm({
    resolver: yupResolver(apiFieldsSchema) as unknown as any,
    defaultValues: {
      name: apiField?.name ?? '',
      url: apiField?.url ?? '',
      apiKey: apiField?.apiKey ?? '',
      apiKeyHeader: apiField?.apiKeyHeader ?? '',
      productIds: apiField?.productIds ?? [],
      isActive: apiField?.isActive ? 1 : 2,
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
  } = formMethods;

  const onSubmit = (data: any) => {
    const dataParameters = mutableDataParams.reduce((acc, param) => {
      acc[param.key] = param.value;
      return acc;
    }, {} as any);
    const productIds = selectedTags.map((tag) => tag.value);

    const payload = {
      ...data,
      dataParameters,
      productIds,
      isActive: data.isActive === 1,
    };

    if (isEditing) {
      updateApiField({ id: apiField.id, updateApiFieldDto: payload });
    } else {
      createApiField({ createApiFieldDto: payload });
    }
  };

  const [mutableDataParams, setMutableDataParams] = useState(
    Object.keys(apiField?.dataParameters ?? []).map((key) => ({
      key,
      value: apiField?.dataParameters[key],
    })),
  );

  const fieldObjects = [
    {
      id: 'transaction',
      name: 'Transaction',
    },
    {
      id: 'transactionMerchantProduct',
      name: 'Transaction Product',
    },
    {
      id: 'product',
      name: 'Product',
    },
    {
      id: 'user',
      name: 'User',
    },
    {
      id: 'variant',
      name: 'Variant',
    },
    {
      id: 'others',
      name: 'Others',
    },
  ];

  const fieldOptions = convertObjectsToOptions(fieldObjects, {
    includeNone: false,
  });

  const transactionOptions = convertObjectsToOptions(API_TRANSACTION, {
    includeNone: false,
  });

  const transactionProductOptions = convertObjectsToOptions(API_TRANSACTION_PRODUCT, {
    includeNone: false,
  });

  const productOptions = convertObjectsToOptions(API_PRODUCTS, {
    includeNone: false,
  });

  const userOptions = convertObjectsToOptions(API_TRANSACTION_USER, {
    includeNone: false,
  });

  const variantOptions = convertObjectsToOptions(API_VARIANT, {
    includeNone: false,
  });

  const handleBack = () => {
    router.replace(Routes.API_FIELDS);
  };

  const addNewParameter = () => {
    setMutableDataParams([...mutableDataParams, { key: '', value: '' }]);
  };

  const removeParameter = (index: number) => {
    const newMutableDataParams = mutableDataParams.filter((_, i) => i !== index);
    setMutableDataParams(newMutableDataParams);
  };

  const onMultiSelectChange = (search: string) => {
    setSearch(search);
  };

  const activeStatusOptions = convertObjectsToOptions(
    [
      { id: 1, name: 'Active' },
      { id: 2, name: 'Inactive' },
    ],
    {
      includeNone: false,
    },
  );

  const formTitle = `${isEditing ? 'Edit' : 'Add a new'} API Field`;
  const submitButtonText = isEditing ? 'Save' : `Add API`;

  return (
    <FormPageLayout
      isLoading={false}
      title={formTitle}
      submitButtonText={submitButtonText}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleBack}
      onClickBackButton={handleBack}
    >
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <TextInput
          {...register('name')}
          showRequiredOnLabel
          labelShown
          label="API Name"
          errorMessage={errors?.name?.message as string}
        />
        <MultiSelectDropdown
          placeholder="Select Products"
          pillVariant="light"
          label="Product"
          options={productSearchptions}
          selectedOptions={selectedTags}
          onSelect={setSelectedTags}
          onRemove={setSelectedTags}
          onChangeText={onMultiSelectChange}
        />
      </div>
      <TextInput
        {...register('url')}
        showRequiredOnLabel
        labelShown
        label="URL"
        errorMessage={errors?.url?.message as string}
      />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <TextInput
          {...register('apiKeyHeader')}
          showRequiredOnLabel
          labelShown
          label="API Key Header"
          errorMessage={errors?.apiKeyHeader?.message as string}
        />
        <TextInput
          {...register('apiKey')}
          showRequiredOnLabel
          labelShown
          label="API Key"
          errorMessage={errors?.apiKey?.message as string}
        />
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <Controller
          name="isActive"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            const handleTypeSelection = (type: Option) => {
              onChange(type.value);
            };

            return (
              <div className="relative flex w-full flex-col gap-1">
                <label className="text-sm font-normal text-neutral-600">Status</label>
                <Dropdown
                  hasLeftRoundedBorders
                  hasRightRoundedBorders
                  isLoading={false}
                  placeholder="Select API Status"
                  containerClassname="w-full"
                  className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                  options={activeStatusOptions}
                  value={activeStatusOptions.find(
                    (activeStatus) => value && activeStatus.value === value,
                  )}
                  onChange={handleTypeSelection}
                />
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            );
          }}
        />
      </div>
      <div>Data Parameters</div>
      {mutableDataParams.map((dataParam, index) => (
        <div className="grid grid-cols-1 items-end gap-6 md:grid-cols-4">
          <Controller
            control={control}
            name="name"
            render={({ field }) => {
              const data = dataParam.value?.split('.') ?? [];
              const value1 = data.length > 1 ? data[0] : '';
              const value2 = data.length > 1 ? data[1] : '';

              let valueOptions: Option[] = [];

              switch (value1) {
                case 'transaction':
                  valueOptions = transactionOptions;
                  break;
                case 'transactionMerchantProduct':
                  valueOptions = transactionProductOptions;
                  break;
                case 'product':
                  valueOptions = productOptions;
                  break;
                case 'user':
                  valueOptions = userOptions;
                  break;
                case 'variant':
                  valueOptions = variantOptions;
                  break;
                default:
                  valueOptions = [];
                  break;
              }

              const onChangeValue = (key: string, value: string) => {
                const newMutableDataParams = mutableDataParams.map((param, i) => {
                  if (i === index) {
                    return {
                      ...param,
                      [key]: value,
                    };
                  }
                  return param;
                });
                setMutableDataParams(newMutableDataParams);
              };

              return (
                <>
                  <TextInput
                    showRequiredOnLabel
                    labelShown
                    label="Key"
                    value={dataParam.key}
                    onChange={(e) => onChangeValue('key', e.target.value)}
                  />
                  <Dropdown
                    hasLeftRoundedBorders
                    hasRightRoundedBorders
                    isLoading={false}
                    placeholder="Select Type"
                    containerClassname="w-full"
                    className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                    options={fieldOptions}
                    value={fieldOptions.find(
                      (fieldOption) => value1 && fieldOption.value === value1,
                    )}
                    onChange={(option) => onChangeValue('value', `${option.value}.`)}
                  />
                  {value1 && value1 !== 'others' && (
                    <Dropdown
                      hasLeftRoundedBorders
                      hasRightRoundedBorders
                      isLoading={false}
                      placeholder="Select Type"
                      containerClassname="w-full"
                      className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                      options={valueOptions}
                      value={valueOptions.find(
                        (valueOption) => value2 && valueOption.value === value2,
                      )}
                      onChange={(option) => onChangeValue('value', `${value1}.${option.value}`)}
                    />
                  )}
                  {value1 && value1 === 'others' && (
                    <TextInput
                      showRequiredOnLabel
                      labelShown
                      label="Value"
                      value={value2}
                      onChange={(e) => onChangeValue('value', `${value1}.${e.target.value}`)}
                    />
                  )}
                  <Button variant="error" className="w-36" onClick={() => removeParameter(index)}>
                    <MinusCircleIcon height={18} width={18} className="mr-2" /> Remove
                  </Button>
                </>
              );
            }}
          />
        </div>
      ))}
      <Button
        variant="primary"
        className="flex gap-2 px-[14px] py-[10px] font-normal"
        onClick={addNewParameter}
      >
        <PlusIcon height={18} width={18} className="mr-2" /> Add New Parameter
      </Button>
    </FormPageLayout>
  );
}

export default ApiFieldsForm;
