import { Banner } from '@healthhub/api-lib';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { fetchBanners, getBannerById } from '../../api/bannerService';
import { GET_ALL_BANNERS, GET_BANNER_BY_ID } from '../../constants/queryKeys';

export const useFetchBanners = () => {
  return useQuery<Banner[], AxiosError>([GET_ALL_BANNERS], () => fetchBanners());
};

export const useGetBannerById = (id: number) => {
  return useQuery<Banner, AxiosError>([GET_BANNER_BY_ID, { id }], () => getBannerById(id));
};
