import currency from 'currency.js';

import { getAge } from './common';

const DISCOUNT = 0.2;
//The computation below (instead of usual 1 - DISCOUNT) is necessary to avoid floating point errors
//Ex. in javascript,  1 - 0.8 results to 0.19999999999999996
const DISCOUNTED_PRICE = Number((1 - DISCOUNT).toFixed(2));
const SENIOR_CITIZEN_AGE = 60;

export const computeDiscountedPrice = (price: number, hasDiscount: boolean) => {
  if (hasDiscount) {
    return price * DISCOUNTED_PRICE;
  }

  return price;
};

export const computeDiscount = (price: number, hasDiscount: boolean) => {
  if (hasDiscount) {
    return price * DISCOUNT;
  }

  return 0;
};

export const hasDiscount = (birthday: Date, pwdId?: string, seniorCitizenId?: string) => {
  const patientAge = getAge(birthday);
  const isSenior = patientAge >= SENIOR_CITIZEN_AGE;

  return !!pwdId || (isSenior && !!seniorCitizenId);
};

export const calculateWithSeniorDiscount = (price: number) => {
  const discountedPrice = currency(price).multiply(0.8);
  return discountedPrice.value;
};

export const calculateDiscount = (price: number, discountedPrice: number) => {
  const discount = currency(price).subtract(discountedPrice);
  return discount.value;
};
