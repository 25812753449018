import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type Props = {
  isDisabled?: boolean;
  isToggled?: boolean;
  onToggle?: (isToggled: boolean) => void;
};

const ToggleButton = (props: Props) => {
  const { isDisabled = false, isToggled, onToggle } = props;

  function handleToggle() {
    if (isDisabled) {
      return;
    }

    onToggle?.(!isToggled);
  }

  const icon = isToggled ? (
    <CheckIcon className="h-[12px] w-[12px] text-pictonBlue" />
  ) : (
    <XMarkIcon className="h-[12px] w-[12px] text-gray-400" />
  );

  return (
    <div
      className={clsx(
        'relative h-[24px] w-[44px] rounded-full transition-colors duration-300',
        { 'bg-pictonBlue': isToggled, 'bg-gray-200': !isToggled },
        { 'cursor-not-allowed opacity-50': isDisabled, 'cursor-pointer': !isDisabled },
      )}
      onClick={handleToggle}
    >
      <div
        className={clsx(
          'absolute top-0.5 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-white transition-transform duration-300',
          { 'left-[22px]': isToggled, 'left-0.5': !isToggled },
        )}
      >
        {icon}
      </div>
    </div>
  );
};

export default ToggleButton;
