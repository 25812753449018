import { XMarkIcon } from '@heroicons/react/20/solid';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';

import { hmoList } from '../constants';

import { Checkbox, MultiSelectDropdown, MultiSelectOptionType } from '@mwell-healthhub/commons';

type SaveHmoPartners = {
  acceptedHmos: MultiSelectOptionType[];
};

type Props = {
  control: Control<SaveHmoPartners, any>;
  setValue: UseFormSetValue<SaveHmoPartners>;
  shouldShowHmoCheckBoxOption?: boolean;
};

const OPTION_HMO_LIST: MultiSelectOptionType[] = [...hmoList]
  .filter((hmo) => hmo.isActive)
  .sort((hmoA, hmoB) => hmoA.name.localeCompare(hmoB.name))
  .map((hmo) => ({
    text: hmo.name,
    value: hmo.id.toString(),
  }));

const ACCEPTED_HMOS_FIELD_KEY = 'acceptedHmos';

export default function ProviderHmoPartners(props: Readonly<Props>) {
  const { control, setValue, shouldShowHmoCheckBoxOption = false } = props;
  const selectedHmos: MultiSelectOptionType[] = useWatch({
    control,
    name: ACCEPTED_HMOS_FIELD_KEY,
  });

  const handleRemove = (hmoValueToRemove: string) => {
    setValue(
      ACCEPTED_HMOS_FIELD_KEY,
      selectedHmos.filter((hmo) => hmo.value !== hmoValueToRemove),
      { shouldDirty: true, shouldTouch: true },
    );
  };

  const handleToggle = (hmo: MultiSelectOptionType) => {
    const isHmoSelected = selectedHmos.some((selectedHmo) => selectedHmo.value === hmo.value);
    if (isHmoSelected) {
      const updatedHmos = selectedHmos.filter((selectedHmo) => selectedHmo.value !== hmo.value);
      setValue(ACCEPTED_HMOS_FIELD_KEY, updatedHmos, { shouldDirty: true, shouldTouch: true });
    } else {
      setValue(ACCEPTED_HMOS_FIELD_KEY, [...selectedHmos, hmo], {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };
  return (
    <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-7 md:p-8">
      <span className="block text-lg font-semibold leading-6 text-gray-900">
        HMO/Health Insurance Partners
      </span>
      {!shouldShowHmoCheckBoxOption ? (
        <Controller
          name={ACCEPTED_HMOS_FIELD_KEY}
          control={control}
          render={({ field: { value, onChange } }) => (
            <MultiSelectDropdown
              showSelectedCount
              placeholder="Select your HMO/Health Insurance Partners"
              options={OPTION_HMO_LIST}
              selectedOptions={value}
              onSelect={onChange}
              onRemove={onChange}
              selectInnerClassName="bg-white border border-black border-opacity-20"
            />
          )}
        />
      ) : (
        <div className="flex flex-col gap-2">
          <span className="text-sm  text-neutral-600">Select HMO/Health Insurance Partners</span>
          <div className="grid flex-col gap-2 gap-x-3 md:grid-cols-2">
            {OPTION_HMO_LIST.map((hmo) => (
              <Checkbox
                key={hmo.value}
                label={hmo.text}
                checked={selectedHmos.some((selectedHmo) => selectedHmo.value === hmo.value)}
                onChange={() => handleToggle(hmo)}
                className=" text-secondary-500 focus:ring-secondary-500"
              />
            ))}
          </div>
        </div>
      )}
      {selectedHmos.length !== 0 && !shouldShowHmoCheckBoxOption && (
        <div className="grid gap-2 md:grid-cols-3">
          {selectedHmos.map((hmo) => (
            <div key={hmo.value} className="flex items-center justify-between py-2">
              <span className="text-sm text-neutral-600">{hmo.text}</span>
              <button onClick={() => handleRemove(hmo.value)}>
                <XMarkIcon className="h-6 w-6 text-neutral-400" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
