import React, { HTMLProps, useEffect, useRef, useState } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';
import { UseFormReturn } from 'react-hook-form';

import {
  Button,
  FILE_EXCEEDS_10MB_MESSAGE,
  FileUpload,
  ImageUploaderType,
  LoadingSpinner,
  checkImageSizeExceeds,
  clsxMerge,
  uploadFile,
} from '@mwell-healthhub/commons';
import { PhotoIcon } from '@heroicons/react/20/solid';
import isEmpty from 'lodash.isempty';

export type FileUrl = {
  file?: File;
  url?: string;
};

type Props = {
  name: string;
  fileUrl: FileUrl;
  validDimensions?: { width: number; height: number };
  onFileImageChange: (name: string | any, fileUrl: FileUrl) => void;
} & HTMLProps<HTMLDivElement>;

export default function ImageUploader(props: Props) {
  const { onFileImageChange, name, validDimensions, fileUrl, className } = props;

  const [imagePreview, setImagePreview] = useState<string>();
  const [isUpdatingImage, setIsUpdatingImage] = useState<boolean>(false);
  const uploadFileImageRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!fileUrl.file) {
      setImagePreview(undefined);
      return;
    }

    const localImageUrl = URL.createObjectURL(fileUrl.file);
    setImagePreview(localImageUrl);

    return () => URL.revokeObjectURL(localImageUrl);
  }, [fileUrl.file]);

  const validateImageDimensions = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        const isValid = validDimensions?.width === width && validDimensions?.height === height;
        resolve(isValid);
      };
      img.onerror = () => resolve(false);
    });
  };

  const handlePhotoChange =
    (onFileImageChange: (name: string, fileUrl: FileUrl) => void) => async (files: File[]) => {
      // setIsUpdatingImage(true);
      if (!files[0]) {
        return;
      }

      if (checkImageSizeExceeds(files[0].size)) {
        // setError('file', {
        //   type: 'max',
        //   message: FILE_EXCEEDS_10MB_MESSAGE,
        // });

        setIsUpdatingImage(false);
        return;
      }

      if (!isEmpty(validDimensions)) {
        const isValidDimensions = await validateImageDimensions(files[0]);
        if (!isValidDimensions) {
          // setError('file', {
          //   type: 'dimension',
          //   message: 'Image must be 1035x517 pixels.',
          // });

          setIsUpdatingImage(false);
          return;
        }
      }

      // clearErrors('file');

      setIsUpdatingImage(false);
      onFileImageChange(name, { file: files[0], url: fileUrl.url });
    };

  const handleChangeImage = () => {
    uploadFileImageRef.current?.click();
  };

  const loader = (
    <div className="flex h-full w-full items-center justify-center">
      <LoadingSpinner />
    </div>
  );

  const hasFileValue = fileUrl.file || fileUrl.url;

  const renderFileValue = imagePreview ? (
    <img className="h-full w-full rounded-lg object-cover object-center" src={imagePreview} />
  ) : (
    <img className="h-full w-full rounded-lg object-cover object-center" src={fileUrl.url} />
  );

  return (
    <div
      className={clsxMerge(
        'relative flex h-[200px] w-full flex-col items-center justify-center rounded-lg md:h-[250px]',
        !(fileUrl.url || fileUrl.file) && 'border-2 border-dashed border-neutral-300 px-6 py-8',
        className,
      )}
    >
      {isUpdatingImage ? (
        loader
      ) : (
        <>
          {hasFileValue ? (
            renderFileValue
          ) : (
            <PhotoIcon width={56} height={56} className="text-neutral-300" />
          )}
          <Button
            variant="outlinedPrimary"
            className={clsxMerge(
              'h-fit !p-0 font-medium',
              fileUrl.url && 'absolute bottom-3 left-3',
            )}
          >
            <FileUpload
              label={fileUrl.url ? 'Change' : 'Upload File'}
              labelClassName={clsxMerge('!ml-0 py-2 px-3', fileUrl.url && 'px-5')}
              onFileChange={handlePhotoChange(onFileImageChange)}
            />
          </Button>
        </>
      )}
    </div>
  );
}
