import {
  CreateProviderTagCategoryDto,
  ProviderTagCategory,
  UpdateProviderTagCategoryDto,
} from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  createProviderTagCategory,
  removeProviderTagCategory,
  updateProviderTagCategory,
} from '../../api/providerTagCategoryService';

export const useAddProviderTagCategoryMutation = (
  mutationOptions?: MutationOptions<
    ProviderTagCategory,
    AxiosError<{ message: string }>,
    CreateProviderTagCategoryDto
  >,
) => {
  return useMutation(createProviderTagCategory, mutationOptions);
};

export const useUpdateProviderTagCategoryMutation = (
  mutationOptions?: MutationOptions<
    ProviderTagCategory,
    AxiosError<{ message: string }>,
    UpdateProviderTagCategoryDto
  >,
) => {
  return useMutation(updateProviderTagCategory, mutationOptions);
};

export const useDeleteProviderTagCategoryMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, string>,
) => useMutation(removeProviderTagCategory, mutationOptions);
