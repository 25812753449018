import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

import { AppLogo, Footer } from '@mwell-healthhub/commons';

import { useIsMobile } from '../../hooks';
import bottomVector from '../../public/static-assets/images/auth-bottom-vector.svg';
import topVector from '../../public/static-assets/images/auth-top-vector.svg';

type PropsType = {
  children?: React.ReactNode;
  shouldHideFooter?: boolean;
  shouldHideLogo?: boolean;
  shouldHideVectors?: boolean;
  shouldShowOnboardingLogo?: boolean;
  shouldShowOnboardingLayout?: boolean;
  noPaddingTopAndBottom?: boolean;
};

export default function AuthLayout(props: PropsType) {
  const {
    children,
    shouldHideFooter = false,
    shouldHideLogo = false,
    shouldHideVectors = false,
    shouldShowOnboardingLogo = false,
    shouldShowOnboardingLayout = false,
    noPaddingTopAndBottom,
  } = props;

  const isMobile = useIsMobile();

  return (
    <>
      <div
        className={twMerge(
          'relative box-border min-h-screen w-full bg-neutral-100',
          noPaddingTopAndBottom ? '' : 'md:flex-row',
        )}
      >
        {shouldShowOnboardingLogo && (
          <div className="z-10 flex h-fit w-full items-center justify-center bg-neutral-50 py-6">
            <AppLogo isClickable={true} height={40} width={211} />
          </div>
        )}
        <div
          className={twMerge(
            'relative mx-auto flex w-full flex-col items-center justify-start overflow-hidden md:max-w-none md:gap-16 md:px-8 lg:gap-4',
            shouldShowOnboardingLayout
              ? ''
              : 'h-full min-h-screen bg-neutral-50 md:justify-center lg:flex-row',
            noPaddingTopAndBottom ? '' : 'py-6',
          )}
        >
          {!shouldHideVectors && (
            <>
              <Image
                src={topVector}
                alt={'topVector'}
                className="absolute right-[-65%] top-0 z-0 md:right-[-20%] md:top-[-5%] lg:right-[-2%]"
              />
              <Image
                src={bottomVector}
                alt={'bottomVector'}
                className="absolute left-[-50%] top-[74%] z-0 md:left-[-25%] lg:left-0"
              />
            </>
          )}

          {!shouldHideLogo && (
            <div className="z-10 mb-10 h-fit w-full max-w-md px-6 md:mb-0 md:w-fit md:max-w-none">
              <AppLogo
                isClickable={true}
                height={isMobile ? 24 : 54}
                width={isMobile ? 211 : 475}
              />
            </div>
          )}
          <div className="z-10 w-full flex-1 px-6 md:max-w-3xl md:flex-initial">{children}</div>
        </div>
      </div>
      {!shouldHideFooter && <Footer />}
    </>
  );
}
