import React from 'react';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { toast as baseToast } from 'react-toastify';

type Props = {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  alertPosition?: 'top-right' | 'top-center';
};

const toast = ({ message, type, alertPosition = 'top-right' }: Props) => {
  let icon: React.ReactNode;
  let backgroundColor: string;

  switch (type) {
    case 'success':
      icon = <CheckCircleIcon height={32} width={32} className="text-green-700" />;
      backgroundColor = 'bg-green-50';
      break;
    case 'error':
      icon = <XCircleIcon height={32} width={32} className="text-red-700" />;
      backgroundColor = 'bg-red-50';
      break;
    case 'info':
      icon = <InformationCircleIcon height={32} width={32} className="text-blue-700" />;
      backgroundColor = 'bg-blue-50';
      break;
    case 'warning':
      icon = <ExclamationCircleIcon height={32} width={32} className="text-yellow-800" />;
      backgroundColor = 'bg-yellow-50';
      break;
    default:
      icon = null;
      backgroundColor = 'bg-white';
  }

  baseToast(
    <div className={'flex items-center justify-between'}>
      <div className="flex items-center gap-3">
        <div>{icon}</div>
        <p>{message}</p>
      </div>
      <button onClick={() => baseToast.dismiss()} className="focus:outline-none">
        <XMarkIcon height={24} width={24} />
      </button>
    </div>,
    {
      position: alertPosition,
      closeButton: false,
      className: `${backgroundColor} rounded-lg px-2`,
    },
  );
};

export default toast;
