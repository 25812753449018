import React from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const BottomMenu = ({ isOpen, onClose }: Props) => {
  const renderBackdrop = isOpen ? (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 bg-black opacity-40" />
  ) : null;

  return (
    <>
      {renderBackdrop}
      <div
        className={`fixed bottom-0 left-0 right-0 flex transform flex-col bg-transparent p-4 transition-transform duration-300 ${
          isOpen ? 'mb-6 translate-y-0' : 'translate-y-full'
        } z-50`}
      >
        <div className="mb-2 flex w-full flex-col rounded-xl bg-white">
          <h3 className="border-b border-gray-200 py-5 text-center text-xs font-semibold leading-3 text-gray-400">
            Edit Profile Picture
          </h3>
          <button
            onClick={onClose}
            className="border-b border-gray-200 py-4 text-xl font-normal leading-6 text-primary-600"
          >
            Camera
          </button>
          <button onClick={onClose} className="py-4 text-xl font-normal leading-6 text-primary-600">
            Photos
          </button>
        </div>

        <button
          onClick={onClose}
          className="rounded-xl bg-white py-4 text-xl font-normal font-semibold leading-6 text-primary-600"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default BottomMenu;
