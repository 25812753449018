import { UserVerificationApi } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { NotificationTemplateEnum } from '../enums';

const apiClient = HealthHubApiClient.use(UserVerificationApi);

export async function sendVerificationEmail(
  email: string,
): Promise<{ email: string; status: string }> {
  try {
    const { data } = await apiClient.userVerificationControllerSendVerificationEmail(
      NotificationTemplateEnum.PATIENT_REGISTRATION_VERIFICATION,
      {
        email,
      },
    );

    return data as unknown as {
      email: string;
      status: string;
    };
  } catch (error) {
    throw new Error('Failed to send verification email');
  }
}

export async function verifyCode({ code, email }: { code: string; email: string }): Promise<void> {
  try {
    await apiClient.userVerificationControllerVerifyCode({
      code,
      email,
    });
  } catch (error) {
    throw new Error('Failed to verify code');
  }
}

export async function checkVerification(options?: AxiosRequestConfig<unknown>): Promise<boolean> {
  try {
    const { data } = await apiClient.userVerificationControllerCheckVerification(options);

    return data as unknown as boolean;
  } catch (error) {
    throw new Error('Failed to check verification code');
  }
}
