import { ProviderClientProfile, User, UserOperation, UserProvider } from '@healthhub/api-lib';

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const concatenateNames = (
  lastName: string,
  firstName: string,
  middleName?: string,
  isFirstNameFirst = false,
) => {
  // Check if middle name is provided
  if (middleName) {
    return `${lastName}, ${firstName} ${middleName}`;
  }

  if (isFirstNameFirst) {
    return `${firstName} ${middleName} ${lastName}`;
  }

  // If no middle name provided, exclude it from the concatenation
  return `${lastName}, ${firstName}`;
};

export const concatenateWithCommas = (labels: string[]) => {
  return labels?.join(', ');
};

export const formatYears = (numYears: number) => {
  if (numYears === 1) {
    return `${numYears} year`;
  } else {
    return `${numYears} years`;
  }
};

export const getAge = (date: Date) => {
  const currentDate = new Date();
  const birthDate = new Date(date);

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Check if the birthday hasn't occurred yet this year
  const currentMonth = currentDate.getMonth();
  const birthMonth = birthDate.getMonth();
  if (
    birthMonth > currentMonth ||
    (birthMonth === currentMonth && birthDate.getDate() > currentDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const currencyFormat = (value: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
  }).format(value);

export const currencyAmount = (value: number): number => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
  });

  const parts = formatter.formatToParts(value);
  let roundedAmount = '';

  for (const part of parts) {
    if (part.type === 'integer' || part.type === 'decimal' || part.type === 'fraction') {
      roundedAmount += part.value;
    }
  }

  return parseFloat(roundedAmount);
};

export const fileToDataUrl = async (file: File) => {
  const promise = new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = (event) => {
      const base64 = event?.target?.result as string;
      resolve(base64);
    };

    fileReader.readAsDataURL(file);
  });

  return promise;
};

export const filesToDataURLs = (files: File[], callback: (base64Strings: string[]) => void) => {
  if (!files.length) return;
  const base64Strings: string[] = [];

  files.forEach((file) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function (evt: ProgressEvent<FileReader>) {
      const base64 = evt?.target?.result as string;
      base64Strings.push(base64);

      if (base64Strings.length === files.length) {
        callback(base64Strings);
      }
    };
  });
};

export const urlToFile = async (url: string, filename: string, mimeType: string) => {
  const res = await fetch(url);
  const buffer = await res.arrayBuffer();

  return new File([buffer], filename, { type: mimeType });
};

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = Buffer.from(arr[1], 'base64').toString('binary');
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const formatAddress = (address: any) => {
  if (!address) {
    return '';
  }

  const { barangay = '', municipalityCity = '', province = '', country = '' } = address;

  return `${barangay ?? ''} ${municipalityCity ?? ''} ${province ?? ''} ${country ?? ''}`.trim();
};

export const formatPatientName = (patient: ProviderClientProfile): string => {
  return `${patient?.firstName || ''} ${patient?.lastName || ''}`.trim();
};

export const convertToTitleCase = (input = '') => {
  // Split the input string into an array of words
  const words = input.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1),
  );

  // Join the capitalized words back into a single string
  const result = capitalizedWords.join(' ');

  return result;
};

export function formatUsernameLastNameFirst(user: User | UserOperation | UserProvider): string {
  return `${user?.lastName}, ${user?.firstName}`;
}

export function formatUsername(user: User | UserOperation | UserProvider): string {
  return [user?.firstName, user?.lastName].filter(Boolean).join(' ');
}

export function formatPhoneNumber(countryCode: string, remainingDigits: string) {
  // Remove any non-digit characters from the remaining digits
  let digitsOnly = remainingDigits.replace(/\D/g, '');

  // Support 09 and 9 as the first digit for +63 PH country code
  if (digitsOnly.startsWith('09')) {
    digitsOnly = `${digitsOnly.slice(1)}`;
  }

  // Check if the remaining digits form a valid 10-digit number
  if (digitsOnly.length !== 10) {
    return 'Invalid phone number';
  }

  // Format the phone number with spaces
  const formattedNumber = `${countryCode} ${digitsOnly.slice(0, 3)} ${digitsOnly.slice(
    3,
    6,
  )} ${digitsOnly.slice(6)}`;

  return formattedNumber;
}

export function ensureDecimalPlaces(input?: string | number, minDecimalCount = 4): string {
  if (!input) {
    return '';
  }

  const stringified = String(input);
  const num = parseFloat(stringified);
  const decimalCount = (stringified.split('.')[1] || '').length;

  return decimalCount < minDecimalCount ? num.toFixed(minDecimalCount) : stringified;
}

export function isAlphanumeric(str: string): boolean {
  const alphanumericRegex = /^[a-z0-9]+$/i;
  return alphanumericRegex.test(str);
}
