import { useState, useEffect, useCallback } from 'react';

import { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } from '../utils';

// Custom event for synchronizing across the app
const storageChangeEvent = new Event('sessionStorageChange');

function useSessionStorage<T>(
  key: string,
  initialValue?: T,
): [T | undefined, (value: T | ((val: T | undefined) => T)) => void, () => void] {
  // Get the initial value from session storage or use the provided initialValue
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    const item = getSessionStorageItem<T>(key);
    return item !== null ? item : initialValue;
  });

  // Update session storage and dispatch custom event
  const updateStorage = useCallback(
    (newValue: T | undefined) => {
      if (newValue !== undefined) {
        setSessionStorageItem(key, newValue);
      } else {
        removeSessionStorageItem(key);
      }
      window.dispatchEvent(storageChangeEvent);
    },
    [key],
  );

  // Update local state when session storage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const newValue = getSessionStorageItem<T>(key);
      setStoredValue(newValue !== null ? newValue : initialValue);
    };

    // Listen for our custom event
    window.addEventListener('sessionStorageChange', handleStorageChange);

    // Also listen for storage events from other tabs/windows
    window.addEventListener('storage', (event) => {
      if (event.storageArea === sessionStorage && event.key === key) {
        handleStorageChange();
      }
    });

    return () => {
      window.removeEventListener('sessionStorageChange', handleStorageChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, initialValue]);

  // Setter function
  const setValue = useCallback(
    (value: T | ((val: T | undefined) => T)) => {
      setStoredValue((prevValue) => {
        const newValue = value instanceof Function ? value(prevValue) : value;
        updateStorage(newValue);
        return newValue;
      });
    },
    [updateStorage],
  );

  // Remove function
  const remove = useCallback(() => {
    updateStorage(undefined);
    setStoredValue(undefined);
  }, [updateStorage]);

  return [storedValue, setValue, remove];
}

export default useSessionStorage;
