import { CreateUserProviderDto, UpdateUserProviderDto, UserProvider } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';

import {
  createUserProvider,
  activateProviderUser,
  deactivateProviderUser,
  updateUserProvider,
} from '../../api/userProviderService';
import { CustomError } from '../../errors';

export const useCreateUserProviderMutation = (
  mutationOptions: MutationOptions<UserProvider, CustomError, CreateUserProviderDto>,
) => {
  return useMutation(createUserProvider, mutationOptions);
};

export const useUpdateUserProviderMutation = (
  mutationOptions: MutationOptions<
    UserProvider,
    CustomError,
    { id: number; user: UpdateUserProviderDto }
  >,
) => {
  return useMutation(
    (params: { id: number; user: UpdateUserProviderDto }) =>
      updateUserProvider(params.id.toString(), params.user),
    mutationOptions,
  );
};

export const useDeactivateUserProviderMutation = (
  mutationOptions?: MutationOptions<void, CustomError, number>,
) => {
  return useMutation(deactivateProviderUser, mutationOptions);
};

export const useActivateUserProviderMutation = (
  mutationOptions?: MutationOptions<void, CustomError, number>,
) => {
  return useMutation(activateProviderUser, mutationOptions);
};
