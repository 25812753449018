import Badge from './Badge';
import { BadgeType } from '../../enums/badgeType';
import { ProviderOnboardingStatusEnum } from '../../enums/provider-status';
import { toTitleCase } from '../../utils/string';

type Props = {
  status: ProviderOnboardingStatusEnum;
};

function ProviderOnboardingStatusBadge(props: Props) {
  const { status } = props;

  let badgeType;
  let label;

  switch (status) {
    case ProviderOnboardingStatusEnum.DIRECTORY:
      badgeType = BadgeType.BLUE;
      label = 'Approved';
      break;
    case ProviderOnboardingStatusEnum.ONBOARDED:
      badgeType = BadgeType.GREEN;
      label = 'Onboarded';
      break;
    default:
      badgeType = BadgeType.GREY;
      label = 'Awaiting Approval';
  }

  return <Badge type={badgeType}>{toTitleCase(label)}</Badge>;
}

export default ProviderOnboardingStatusBadge;
