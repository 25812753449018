export const SHOW_DISCOUNTS = true;
export const SHOW_ESHOP_FINANCE = true;
export const SHOW_FAVOURITES = true;
export const SHOW_FEEDBACK_FEATURE = true;
export const SHOW_FEEDBACK_CLIENT_FEATURE = false;
export const SHOW_MEDICAL_RECORDS = true;
export const SHOW_OPERATIONS_PORTAL_MARKETING_PAGE = true;
export const SHOW_PROVIDER_ADDRESS_DISTANCE = false;
export const SHOW_PROVIDER_SERVICE_SETTINGS = true;
export const SHOW_REFERRAL = true;
export const SHOW_SETTINGS = true;
export const SHOW_SMS_NOTIFICATION_CONFIG = true;
export const SHOW_MESSAGE_THREADS = true;
export const SHOW_ESHOP_TAGS = true;
export const SHOW_ESHOP_V2 = true;
export const SHOW_PAYMENT_QR_CODE = true;
export const SHOW_DISBURSEMENT = false;
export const SHOW_GROUP_BOOKING = true;
export const SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD = true;
export const SHOW_ESHOP_PRODUCT_VARIANT = true;
export const SHOW_PROVIDER_CUSTOM_FIELDS = true;
export const SHOW_DIGITAL_IDS = false;
export const SHOW_EDIT_DIGITAL_IDS = false;
export const SHOW_VIEW_DIGITAL_IDS = false;
export const SHOW_REMOVE_DIGITAL_IDS = false;
export const SHOW_CUSTOMIZED_SCHEDULE = true;
export const SHOW_SSO = false;
export const SHOW_PROVIDER_DIRECTORY = true;
export const SHOW_PROVIDER_PROFILE_V2 = true;
export const SHOW_SELF_ONBOARDING = true;
export const SHOW_MPH_REFERRAL = false;
export const SHOW_UNIFIED_ADMIN_ACCESS = false && SHOW_SSO;
