export enum NotificationTemplateEnum {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  APPOINTMENT_BOOKED = 'APPOINTMENT_BOOKED',
  APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
  APPOINTMENT_NO_SHOW = 'APPOINTMENT_NO_SHOW',
  APPOINTMENT_RESCHEDULED = 'APPOINTMENT_RESCHEDULED',
  APPOINTMENT_AWAITING_APPROVAL = 'APPOINTMENT_AWAITING_APPROVAL',
  APPOINTMENT_DECLINED = 'APPOINTMENT_DECLINED',
  CLIENT_REGISTER_QR_CODE = 'CLIENT_REGISTER_QR_CODE',
  FEEDBACK = 'FEEDBACK',
  MESSAGE = 'MESSAGE',
  PATIENT_REGISTRATION_VERIFICATION = 'PATIENT_REGISTRATION_VERIFICATION',
  SUGGEST_PROVIDER = 'SUGGEST_PROVIDER',
  USER_INVITE = 'USER_INVITE',
  USER_OTP = 'USER_OTP',
  USER_RESET_PASSWORD = 'USER_RESET_PASSWORD',
  REFERRED_TO_PROVIDER_REFERRAL = 'REFERRED_TO_PROVIDER_REFERRAL',
  REFERRED_TO_PROVIDER_BOOKED_APPOINTMENT = 'REFERRED_TO_PROVIDER_BOOKED_APPOINTMENT',
  PRODUCT_ADDED = 'PRODUCT_ADDED',
  MPH_APPOINTMENT_BOOKED = 'MPH_APPOINTMENT_BOOKED',
  PROVIDER_TO_MPH_REFERRAL = 'PROVIDER_TO_MPH_REFERRAL',
  PROVIDER_ONBOARDED = 'PROVIDER_ONBOARDED',
  PROVIDER_RESUBMIT_DOCUMENTS = 'PROVIDER_RESUBMIT_DOCUMENTS',
  PROVIDER_DECLINED = 'PROVIDER_DECLINED',
  DIGITAL_ID_ACCESS_REQUEST = 'DIGITAL_ID_ACCESS_REQUEST',
}

export enum NotificationMedium {
  EMAIL = 'EMAIL',
  IN_APP = 'IN_APP',
  SMS = 'SMS',
  MWELL = 'MWELL',
}
