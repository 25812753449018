import { useEffect, useMemo, useState } from 'react';

import { ProviderService } from '@healthhub/api-lib';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isSameDay,
  parse,
  startOfToday,
  startOfWeek,
} from 'date-fns';

import {
  CALENDAR_DATE_FORMAT,
  FULL_MONTH_YEAR_FORMAT,
  ISO_18601_FORMAT,
  LoadingSpinner,
  MONTH_YEAR_FORMAT,
  MonthEnum,
  SHOW_CUSTOMIZED_SCHEDULE,
  SINGLE_DAY_FORMAT,
  WEEKDAY_FORMAT,
  formatDateToMonth,
  useGetSchedules,
} from '@mwell-healthhub/commons';

import {
  clsxMerge,
  getCommonSchedules,
  getDaysWithSchedule,
  getNextAvailableSchedule,
  getStartOfTodayUTCPhilippineTime,
} from '../../utils';

type Props = {
  selectedDate: Date;
  selectedDateString: string | null;
  providerId: number;
  onSelectDate: (date: Date, scheduleId?: number) => void;
  className?: string;
  providerServices?: ProviderService[];
};

export default function Calendar(props: Props) {
  const {
    providerId,
    providerServices = [],
    selectedDate,
    selectedDateString,
    onSelectDate,
    className = '',
  } = props;

  const today = getStartOfTodayUTCPhilippineTime();

  const [currMonth, setCurrMonth] = useState(() => format(today, MONTH_YEAR_FORMAT));
  const [currentSelectedMonth, setCurrentSelectedMonth] = useState<MonthEnum>(
    formatDateToMonth(selectedDate),
  );

  const [month, year] = currMonth.split('-');

  const { data: monthSchedules, isLoading: isGetSchedulesLoading } = useGetSchedules({
    month: currentSelectedMonth,
    providerId,
    selectedDate: format(new Date(`${month} ${new Date().getDate()}, ${year}`), ISO_18601_FORMAT),
    providerServiceIds: providerServices.map(({ id }) => id),
  });

  const schedules = useMemo(() => {
    if (SHOW_CUSTOMIZED_SCHEDULE && providerServices?.length > 0) {
      return getCommonSchedules(monthSchedules || [], providerServices);
    }

    return monthSchedules;
  }, [monthSchedules]);

  const daysWithSchedules = getDaysWithSchedule(schedules);
  const nextAvailableDate = daysWithSchedules.find((date) => !isBefore(new Date(date), today));
  const { nextAvailableScheduleDate, scheduleId } =
    getNextAvailableSchedule(schedules, nextAvailableDate) ?? {};

  useEffect(() => {
    const month = format(selectedDate, MONTH_YEAR_FORMAT);
    setCurrMonth(month);
    setCurrentSelectedMonth(formatDateToMonth(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (!selectedDateString && nextAvailableScheduleDate && scheduleId) {
      onSelectDate(nextAvailableScheduleDate, scheduleId);
    }
  }, [nextAvailableScheduleDate, scheduleId]);

  const selectedMonth = parse(currMonth, MONTH_YEAR_FORMAT, new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(selectedMonth),
    end: endOfWeek(endOfMonth(selectedMonth)),
  });

  const getPrevMonth = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const firstDayOfPrevMonth = add(selectedMonth, { months: -1 });
    setCurrMonth(format(firstDayOfPrevMonth, MONTH_YEAR_FORMAT));
    setCurrentSelectedMonth(formatDateToMonth(firstDayOfPrevMonth));
  };

  const getNextMonth = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const firstDayOfNextMonth = add(selectedMonth, { months: 1 });
    setCurrMonth(format(firstDayOfNextMonth, MONTH_YEAR_FORMAT));
    setCurrentSelectedMonth(formatDateToMonth(firstDayOfNextMonth));
  };

  const renderDays = daysInMonth.map((day, dayIdx) => {
    const isCurrentMonth = format(day, MONTH_YEAR_FORMAT) === currMonth;
    const isSelected = isSameDay(day, selectedDate);
    const isToday = isSameDay(day, today);
    const isPastToday = isBefore(day, today);
    const hasSchedule = daysWithSchedules.includes(format(day, CALENDAR_DATE_FORMAT));
    const isDisabled = !isCurrentMonth || !hasSchedule;
    const formattedDay = format(day, SINGLE_DAY_FORMAT);
    const formatWeekDay = format(day, WEEKDAY_FORMAT).toLowerCase();

    const handleSelectDate = () => {
      if (isDisabled) {
        return;
      }

      if (isPastToday) {
        return;
      }

      onSelectDate(day);
    };

    const dayLabelClassName = clsxMerge(
      'mx-auto flex h-7 w-7 items-center justify-center rounded-full border border-neutral-300 text-sm text-primary-500',
      isToday && 'font-semibold',
      isSelected && 'border-primary-500 bg-primary-500 text-chineseWhite',
      isDisabled && 'cursor-not-allowed border-none text-primary-200',
      isPastToday && 'cursor-not-allowed border-none text-chineseWhite',
    );

    return (
      <button
        key={day.toString()}
        type="button"
        className="py-1.5 focus:z-10"
        onClick={handleSelectDate}
        data-cy={`${!isDisabled && 'active'}-${formatWeekDay}-date`}
      >
        <time dateTime={day.toISOString()} className={dayLabelClassName}>
          {formattedDay}
        </time>
      </button>
    );
  });

  const formattedSelectedMonth = format(selectedMonth, FULL_MONTH_YEAR_FORMAT);

  if (isGetSchedulesLoading && !providerId) {
    return null;
  }

  const calendarDisplay = (
    <div
      className={clsxMerge(
        'mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9',
        className,
      )}
    >
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={getPrevMonth}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">{formattedSelectedMonth}</div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={getNextMonth}
          data-cy="next-month-button"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg text-sm">{renderDays}</div>
    </div>
  );

  if (isGetSchedulesLoading) {
    return (
      <div className="relative">
        <div className="absolute flex h-full w-full items-center justify-center bg-white bg-opacity-10">
          <LoadingSpinner />
        </div>
        {calendarDisplay}
      </div>
    );
  }

  return calendarDisplay;
}
