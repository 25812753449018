export function ensureStringArray(arrayOrString: undefined | string | string[]) {
  return ensureArray<string>(arrayOrString);
}

export function ensureArray<T>(potentialArray: undefined | T | T[]): T[] {
  if (!potentialArray) {
    return [];
  }

  return Array.isArray(potentialArray) ? potentialArray : [potentialArray];
}
