import PaymentQrCodeFormField from '../PaymentQrCodeFormField';

function BranchPaymentMethods() {
  return (
    <div className="mb-5 flex flex-col items-center gap-4 sm:flex-row">
      <PaymentQrCodeFormField />
    </div>
  );
}

export default BranchPaymentMethods;
