import { FirebaseApp, FirebaseOptions, getApp, getApps, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import { clientConfig, messageServiceConfig } from '../config/clientConfig';

const getFirebaseApp = (options: FirebaseOptions) =>
  (!getApps().length ? initializeApp(options) : getApp()) as FirebaseApp;

export const useFirebaseAuth = () => {
  const getFirebaseAuth = (tenantId: string | null = null) => {
    const auth = getAuth(getFirebaseApp(clientConfig));

    auth.tenantId = tenantId;

    if (process.env.NEXT_PUBLIC_EMULATOR_HOST) {
      // https://stackoverflow.com/questions/73605307/firebase-auth-emulator-fails-intermittently-with-auth-emulator-config-failed
      (auth as unknown as any)._canInitEmulator = true;

      connectAuthEmulator(auth, process.env.NEXT_PUBLIC_EMULATOR_HOST, {
        disableWarnings: true,
      });
    }

    return auth;
  };

  return { getFirebaseAuth };
};

export const useMessageServiceFirestore = () => {
  const messageServiceApp = initializeApp(messageServiceConfig, 'messageService');
  const firestore = getFirestore(messageServiceApp);

  return { firestore };
};
