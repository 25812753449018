import { Fragment } from 'react';

import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

type Props = {
  containerClassName?: string;
  isMobileViewOnly?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  shouldShowBackButton?: boolean;
  title: string | React.ReactNode;
  onLeftIconClick?: () => void;
};

const NavigationTitle = (props: Props) => {
  const {
    leftIcon,
    rightIcon,
    title,
    onLeftIconClick,
    containerClassName,
    isMobileViewOnly,
    shouldShowBackButton = true,
  } = props;

  const leftIconDisplay = leftIcon || (
    <div onClick={onLeftIconClick}>
      <ChevronLeftIcon className="h-6 w-6" />
    </div>
  );

  const rightIconDisplay = rightIcon || <div className="h-6 w-6" />;

  const mobileView = shouldShowBackButton ? (
    <div
      className={twMerge(
        'flex cursor-pointer items-center p-4 py-3',
        containerClassName ?? 'relative bg-white shadow-topNav',
      )}
    >
      {leftIconDisplay}
      <h3 className="flex-auto truncate px-2 text-center text-lg font-medium text-primary-500">
        {title}
      </h3>
      {rightIconDisplay}
    </div>
  ) : (
    <div
      className={twMerge(
        'flex cursor-pointer items-center p-4 py-3 pb-6',
        containerClassName ?? 'bg-white',
      )}
    >
      <h3 className="flex-auto truncate px-2 text-center text-lg font-medium text-primary-500">
        {title}
      </h3>
    </div>
  );

  const desktopView = shouldShowBackButton ? (
    <div
      className={twMerge(
        'flex cursor-pointer items-center p-4 py-3 pb-6',
        containerClassName ?? 'bg-white',
      )}
      onClick={onLeftIconClick}
    >
      {leftIconDisplay}
      <h3 className="truncate px-2 text-center text-xl font-semibold">{title}</h3>
      {rightIconDisplay}
    </div>
  ) : (
    <div
      className={twMerge(
        'flex cursor-pointer items-center p-4 py-3 pb-6',
        containerClassName ?? 'bg-white',
      )}
    >
      <h3 className="truncate px-2 text-center text-xl font-semibold">{title}</h3>
      {rightIconDisplay}
    </div>
  );

  return (
    <Fragment>
      <div className="sm:hidden">{mobileView}</div>
      <div className="hidden sm:block">{isMobileViewOnly ? mobileView : desktopView}</div>
    </Fragment>
  );
};

export default NavigationTitle;
