import { Fragment } from 'react';

import {
  Provider,
  ProviderService,
  ProviderServiceAvailabilityEnum,
  UserOperation,
  UserProvider,
} from '@healthhub/api-lib';
import { Controller, useFormContext } from 'react-hook-form';

import {
  BRAND_ADMIN_USERS,
  Checkbox,
  OperationUserType,
  ProviderUserType,
  formatProviderDisplayNameWithBranch,
} from '@mwell-healthhub/commons';

import { ProviderServiceFormValues } from './ProviderServiceForm';
import ProviderServiceHelper from '../utils/ProviderServiceHelper';

const { Available, AvailableInSelectedBranches, Unavailable } = ProviderServiceAvailabilityEnum;

type Props = {
  provider: Provider;
  isBranch: boolean;
  currentUser: UserOperation | UserProvider;
  providerService?: ProviderService;
};

function ProviderServiceAvailabilityFormFields(props: Props) {
  const { currentUser, provider, isBranch, providerService } = props;
  const { branches: myBranches } = provider || {};
  const providerServiceHelper = new ProviderServiceHelper(providerService as ProviderService);

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<
    Pick<ProviderServiceFormValues, 'availability' | 'providerServiceBranchIds'>
  >();

  const isUserSuperAdmin = BRAND_ADMIN_USERS.includes(
    currentUser?.userType as ProviderUserType | OperationUserType,
  );

  const availabilityLabelsMap = {
    [Available]: isBranch ? 'Available' : 'Available in all branches',
    [AvailableInSelectedBranches]: 'Available in selected branches',
    [Unavailable]: 'Unavailable',
  };

  const selectedAvailability = watch('availability');

  const renderProviderAssignedBranches = (field: any) =>
    myBranches?.map((provider) => {
      return (
        <Checkbox
          key={`${provider.id}-branch`}
          className="text-secondary-500"
          checked={field?.value?.includes(provider.id)}
          onChange={(e) => {
            const branchServices = field?.value || [];
            const isChecked = (e.target as HTMLInputElement).checked;
            const updatedBranches = isChecked
              ? [...branchServices, provider.id]
              : branchServices.filter((id: number) => id !== provider.id);

            setValue('providerServiceBranchIds', updatedBranches);
          }}
          containerClassName="w-100% md:w-50% lg:w-40% xl:w-30% 2xl:w-20%"
          label={
            <div className="text-neutral-900">{formatProviderDisplayNameWithBranch(provider)}</div>
          }
        />
      );
    });

  const renderNoBranches = myBranches?.length === 0 && (
    <div className="bg-neutral-200 text-sm text-neutral-400">
      No branches have been added. Please add a branch first.
    </div>
  );

  const isSelectedAvailabilityInSelectedBranches =
    selectedAvailability === AvailableInSelectedBranches;

  const renderBranches = (
    <div className="px-7 py-3">
      <Controller
        name="providerServiceBranchIds"
        control={control}
        render={({ field }) => (
          <div className="flex flex-wrap">{renderProviderAssignedBranches(field)}</div>
        )}
      />
      {renderNoBranches}
    </div>
  );

  const branchAdminAvailabilityOptions = [Available, Unavailable];
  const superAdminAvailabilityOptions = [Available, AvailableInSelectedBranches, Unavailable];

  const availabilityOptions =
    isUserSuperAdmin && !isBranch ? superAdminAvailabilityOptions : branchAdminAvailabilityOptions;

  const providerSelectedBranchIdsCount = watch('providerServiceBranchIds')?.length || 0;
  const renderSelectedCount = `${
    providerSelectedBranchIdsCount > 0 ? `(${providerSelectedBranchIdsCount} Selected)` : ''
  }`;

  const renderSelectedBranchesLabel = `Available in selected branches ${renderSelectedCount}`;

  const handleChangeAvailability = (onChange: (...event: any[]) => void) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === Available) {
        setValue('providerServiceBranchIds', myBranches?.map((branch) => branch.id));
      } else {
        setValue(
          'providerServiceBranchIds',
          providerServiceHelper.availableBranchServices?.map((bs) => bs.provider.id) || [],
        );
      }

      onChange(event.target.value);
    };
  };

  const handleSelectAll = () => {
    const allBranchIds = myBranches?.map((branch) => branch.id);
    setValue('providerServiceBranchIds', allBranchIds, { shouldValidate: true });
  };

  const clearSelectedBranches = () => {
    setValue('providerServiceBranchIds', [], { shouldValidate: true });
  };

  const renderOptions = (
    <div className="mt-1.5 flex flex-col">
      {availabilityOptions.map((option) => {
        const isAvailableInSelectedBranches =
          option === AvailableInSelectedBranches && isSelectedAvailabilityInSelectedBranches;

        return (
          <Fragment key={option}>
            <div>
              <Controller
                name="availability"
                control={control}
                render={({ field }) => (
                  <input
                    type="radio"
                    {...field}
                    onChange={handleChangeAvailability(field.onChange)}
                    value={option}
                    className="text-secondary-500 focus:ring-transparent"
                    checked={field.value === option}
                  />
                )}
              />
              <span className="pl-3 text-sm text-neutral-900">
                {isAvailableInSelectedBranches
                  ? renderSelectedBranchesLabel
                  : availabilityLabelsMap[option]}
              </span>

              {isAvailableInSelectedBranches && !!myBranches && myBranches.length > 0 && (
                <div className="float-right flex gap-2">
                  <button
                    className="font-poppins text-sm font-normal normal-case leading-5 tracking-wide text-primary-500 underline"
                    onClick={handleSelectAll}
                    type="button"
                  >
                    Select all
                  </button>
                  <button
                    className="font-poppins text-sm font-normal normal-case leading-5 tracking-wide text-primary-500 underline"
                    onClick={clearSelectedBranches}
                    type="button"
                  >
                    Clear all
                  </button>
                </div>
              )}
            </div>
            {isAvailableInSelectedBranches && renderBranches}
          </Fragment>
        );
      })}
    </div>
  );

  return (
    <div>
      <label className="block text-sm font-normal leading-6 text-neutral-600">
        {isBranch ? 'Service Availability' : 'Branch Availability'}
        <span className="text-red-600"> *</span>
      </label>
      {renderOptions}
      <div className="text-sm text-red-600">
        {errors?.availability?.message || errors?.providerServiceBranchIds?.message}
      </div>
    </div>
  );
}

export default ProviderServiceAvailabilityFormFields;
