import { useState, useEffect } from 'react';

interface TimerOptions {
  startSeconds?: number;
  triggerSwitch?: boolean;
}

const useTimeLeftTimer = ({ startSeconds = 0, triggerSwitch = false }: TimerOptions): string => {
  const [seconds, setSeconds] = useState(0);
  const shouldDecrement = seconds >= 0;

  useEffect(() => {
    setSeconds(startSeconds);

    const interval = setInterval(() => {
      if (shouldDecrement) {
        setSeconds((prevSeconds) => Math.max(0, prevSeconds - 1));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startSeconds, triggerSwitch]);

  return format(seconds);
};

const format = (seconds: number): string => {
  const timeUnits = ['h', 'm', 's'];
  const timeValues = [3600, 60, 1].map((unit) => Math.floor(seconds / unit) % 60);

  const formattedTime = timeValues
    .map((value, index) => {
      if (value > 0) {
        const timeUnit = timeUnits[index];

        return `${value}${timeUnit} `;
      }

      return '';
    })
    .join('');

  return `${formattedTime || '0s'}`;
};

export default useTimeLeftTimer;
