import { TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type Props = {
  className?: string;
  onClick: () => void;
};

function TableDeleteButton(props: Readonly<Props>) {
  const { className, onClick } = props;

  return (
    <button
      type="button"
      className={clsx(
        'relative inline-flex items-center text-sm font-medium text-primary-500 focus:z-10',
        className,
      )}
      onClick={onClick}
    >
      <TrashIcon height={20} width={20} className="mr-2" />
      Remove
    </button>
  );
}

export default TableDeleteButton;
