import { ReactNode, useRef, useState } from 'react';

import clsx from 'clsx'; // Import clsx

import useOutsideClick from '../../hooks/useOutsideClick';
import DownArrow from '../../images/down-arrow.svg';
import { Option } from '../../types';
import InputLoadingSpinner from '../InputLoadingSpinner';

type Props = {
  className?: string;
  containerClassname?: string;
  icon?: ReactNode;
  options: Option[];
  values?: Option[];
  label: string;
  hasLeftRoundedBorders?: boolean;
  hasRightRoundedBorders?: boolean;
  hasScroll?: boolean;
  width?: number;
  isLoading?: boolean;
  containerShouldFollowBtnWidth?: boolean;
  onSelect: (value: Option) => void;
};

const MultipleSelect = (props: Props) => {
  const {
    className,
    containerClassname,
    icon,
    options,
    label,
    values,
    width,
    onSelect,
    hasRightRoundedBorders = true,
    hasLeftRoundedBorders = true,
    hasScroll = true,
    isLoading = false,
    containerShouldFollowBtnWidth = false,
  } = props;

  const updatedWidth = width ? `w-[${width}px]` : 'w-full';
  const containerWidth = containerShouldFollowBtnWidth ? updatedWidth : 'w-full';
  const overflow = hasScroll ? 'overflow-y-scroll' : 'overflow-hidden';

  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOutsideClick(containerRef, () => {
    setIsOpen(false);
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: Option) => {
    onSelect(option);
  };

  const containerClasses = clsx(
    'shadow-lg absolute left-0 z-10 mt-2 max-h-96 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
    containerWidth,
    overflow,
  );

  return (
    <div
      className={clsx('relative inline-block text-left', containerClassname && containerClassname)}
      ref={containerRef}
    >
      <div className="h-full">
        <button
          type="button"
          className={clsx(
            `inline-flex h-12 items-center justify-start border border-solid border-hawkesBlue bg-white px-3 py-2 text-sm text-gray10 hover:bg-gray-50 focus-visible:outline-none ${updatedWidth}`,
            hasLeftRoundedBorders && 'rounded-l-md',
            hasRightRoundedBorders && 'rounded-r-md',
            className,
          )}
          id="menu-button"
          aria-haspopup="true"
          onClick={handleClick}
          disabled={isLoading}
        >
          {icon}
          {values?.length ? (
            <div className="mr-2 inline-block w-full truncate text-left text-base md:text-sm">
              {values?.map((value) => value?.label).join(',')}
            </div>
          ) : (
            <div className="mr-2 inline-block w-full truncate text-left text-base md:text-sm">
              {label}
            </div>
          )}
          <img
            src={DownArrow}
            width={12}
            height={8}
            alt="Down arrow"
            className={`${isOpen && 'rotate-180'}`}
          />
        </button>
      </div>
      {isLoading && <InputLoadingSpinner />}
      {isOpen && (
        <div
          aria-labelledby="menu-button"
          aria-orientation="vertical"
          className={containerClasses}
          role="menu"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            {options.map((option, index) => {
              const isSelected = values?.some((value) => value.value === option.value);

              return (
                <div
                  key={option.value}
                  className={clsx(
                    'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer hover:bg-lightGray',
                    isSelected && 'bg-lightPrimary',
                  )}
                  role="menuitem"
                  tabIndex={-1}
                  id={`menu-item-${index}`}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleSelect;
