import React from 'react';

import { useRouter } from '../hooks';
import { classNames } from '../utils';

type SimpleTabType = {
  label: string;
  path: string;
};

type Props = {
  className?: string;
  tabs: SimpleTabType[];
};

const SimpleTabs: React.FC<Props> = (props: Props) => {
  const { className, tabs } = props;
  const router = useRouter();

  const onClickTab = (tab: SimpleTabType) => {
    router.push(tab.path);
  };

  return (
    <div className={className}>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex flex-wrap md:space-x-2 lg:space-x-3" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.label}
                className={classNames(
                  router.asPath.split('?')[0] === tab.path
                    ? 'border-cobalt text-cobalt'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                )}
                onClick={() => onClickTab(tab)}
              >
                <span>{tab.label}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SimpleTabs;
