// TODO find a way to import tailwind config file and destructure the colors here
export const colors = {
  primary1: '#0D4C93',
  primary2: '#02407A',
  primary3: '#035099',
  primary4: '#01101F',
  lightPrimary: '#DCEEFE',
  lightGray: '#F1F5F9',
  gray1: '#333333',
  gray2: '#5A5454',
  gray3: '#646A71',
  gray4: '#8B909A',
  gray16: '#364452',
  midgrey: '#6A7075',
};
