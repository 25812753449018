import { TransactionPaymentOptionEnum } from '@healthhub/api-lib';

import { DATE_TIME_WITH_DOT } from '../../constants/dateFormats';
import { PAYMENT_VIA } from '../../constants/payments';
import { convertDateByFormat } from '../../utils';
import Button from '../Button';

type Props = {
  date?: string;
  method?: string;
  receiptNumber?: string;
  status: string;
  totalAmount: string;
  proofOfPaymentUrl?: string;
};

function PreAppointmentPaymentDetails(props: Props) {
  const { totalAmount, date = '-', status, method, receiptNumber = '-', proofOfPaymentUrl } = props;

  const paymentDate = date ? convertDateByFormat(date, DATE_TIME_WITH_DOT) : '-';

  const proofOfPayment =
    method === TransactionPaymentOptionEnum.Qrph && proofOfPaymentUrl ? (
      <Button
        variant="plainNoOutline"
        className="h-auto p-0 text-sm font-medium underline"
        onClick={() => window.open(proofOfPaymentUrl, '_blank')}
      >
        View Proof of Payment
      </Button>
    ) : (
      ''
    );

  return (
    <div className="flex flex-col gap-y-4 pt-6">
      <div className="flex justify-between">
        <span className="text-sm font-medium text-neutral-600 md:text-base">
          Pre-appointment Payment
        </span>
        <span className="text-sm font-medium text-neutral-600 md:text-base">{totalAmount}</span>
      </div>
      <div className="flex flex-col gap-y-2 rounded-lg bg-neutral-100 px-4 py-3">
        <div className="flex justify-between">
          <span className="text-sm font-semibold">Payment Details</span>
          {proofOfPayment}
        </div>
        <div className="flex justify-between">
          <span className="text-sm">Payment Date</span>
          <span className="text-sm">{paymentDate}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm">Transaction No.</span>
          <span className="text-sm">{receiptNumber}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm">Payment Method</span>
          <span className="text-sm">{PAYMENT_VIA[method as TransactionPaymentOptionEnum]}</span>
        </div>
      </div>
    </div>
  );
}

export default PreAppointmentPaymentDetails;
