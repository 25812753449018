export enum BranchSelectionEnum {
  ALL = 'ALL',
  SPECIFIC = 'SPECIFIC',
}

export enum CustomServiceScheduleEnum {
  PROVIDER_ID = 'providerId',
  BRANCH_SELECTION_TYPE = 'branchSelectionType',
  SERVICES = 'services',
  SLOTS = 'slots',
  TIMESLOT_INTERVAL_IN_MINUTES = 'timeSlotIntervalInMinutes',
  PROVIDER_SERVICE_BRANCH_IDS = 'providerServiceBranchIds',
  PROVIDER_SERVICES_IDS = 'providerServiceIds',
  TIMESLOTS = 'timeSlots',
  DAYS = 'days',
}
