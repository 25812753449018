import { CreateDiscountDto, UpdateDiscountDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createDiscount, publishDiscount, unpublishDiscount, updateDiscount } from '../../api';
import { IDiscount } from '../../types';

export const useCreateDiscountMutation = (
  mutationOptions: MutationOptions<IDiscount, AxiosError<{ message: string }>, CreateDiscountDto>,
) => useMutation(createDiscount, mutationOptions);

export const useUpdateDiscountMutation = (
  mutationOptions: MutationOptions<IDiscount, AxiosError<{ message: string }>, UpdateDiscountDto>,
) => useMutation(updateDiscount, mutationOptions);

export const usePublishDiscountMutation = (
  mutationOptions: MutationOptions<IDiscount, AxiosError<{ message: string }>, string>,
) => useMutation(publishDiscount, mutationOptions);

export const useUnpublishDiscountMutation = (
  mutationOptions: MutationOptions<IDiscount, AxiosError<{ message: string }>, string>,
) => useMutation(unpublishDiscount, mutationOptions);
