/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { DISPLAY_TYPES, TAG_TYPES } from '../../constants';
import { DisplayType, Routes } from '../../enums';
import { useCreateTagMutation, useRouter, useUpdateTagMutation } from '../../hooks';
import { Option, Tag } from '../../types';
import { convertObjectsToOptions } from '../../utils';
import { tagSchema } from '../../validators';
import Dropdown from '../Dropdown';
import FormPageLayout from '../FormPageLayout';
import ProductImageUploader from '../product/ProductImageUploader';
import TextInput from '../TextInput';
import toast from '../toast';

type Props = {
  tag?: Tag;
};

function TagForm(props: Props) {
  const { tag } = props;
  const router = useRouter();
  const isEditing = !!tag;

  const formMethods = useForm({
    resolver: yupResolver(tagSchema) as unknown as any,
    defaultValues: {
      name: tag?.name,
      imageUrl: tag?.imageUrl,
      tagType: tag?.tagType,
      displayType: tag?.displayType,
      displayButtonText: tag?.displayButtonText ?? 'Explore',
    },
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
    watch,
  } = formMethods;

  const { mutate: addTag, isLoading: isSaving } = useCreateTagMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: updateTag, isLoading: isUpdating } = useUpdateTagMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const onSubmit = (data: any) => {
    const payload = {
      name: data.name,
      imageUrl: data.imageUrl,
      tagType: data.tagType,
      displayType: data.displayType,
      displayButtonText: data.displayButtonText,
    };

    if (isEditing) {
      updateTag({ updateTagDto: payload, id: tag.id });
    } else {
      addTag(payload);
    }
  };

  const tagTypesOptions = convertObjectsToOptions(TAG_TYPES, {
    includeNone: false,
  });
  const displayTypesOptions = convertObjectsToOptions(DISPLAY_TYPES, {
    includeNone: false,
  });

  function handleSuccess() {
    const message = `Tag ${isEditing ? 'updated' : 'created'}`;

    toast({ message: message, type: 'success' });
    router.replace(Routes.TAGS);
    reset();
  }

  function handleError(error: Error) {
    toast({ message: error.message, type: 'error' });
  }

  const handleBannerImageChange = (newPhotoUrl: string) => {
    formMethods.setValue('imageUrl', newPhotoUrl, { shouldDirty: true });
  };

  const isLoading = isSaving || isUpdating;
  const formTitle = `${isEditing ? 'Edit' : 'Add a new'} Tag`;
  const submitButtonText = isEditing ? 'Save' : `Add Tag`;

  const handleBack = () => {
    router.replace(Routes.TAGS);
  };

  const isGrid = watch('displayType') === DisplayType.Grid;

  return (
    <FormPageLayout
      isLoading={isLoading}
      title={formTitle}
      submitButtonText={submitButtonText}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleBack}
      onClickBackButton={handleBack}
    >
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <TextInput
          {...register('name')}
          showRequiredOnLabel
          labelShown
          label="Tag Name"
          errorMessage={errors?.name?.message as string}
        />
        <div className="gap-1">
          {
            <Controller
              name="tagType"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                const handleTagTypeSelection = (tagType: Option) => {
                  onChange(tagType.value);
                };

                return (
                  <div className="relative flex w-full flex-col">
                    <label className="text-sm font-normal text-neutral-600">
                      Tag Type <span className="text-red-600"> *</span>
                    </label>
                    <Dropdown
                      hasLeftRoundedBorders
                      hasRightRoundedBorders
                      placeholder="Select Tag Type"
                      containerClassname="w-full"
                      className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                      options={tagTypesOptions}
                      value={tagTypesOptions.find(
                        (tagTypesOption) => value && tagTypesOption.value === value,
                      )}
                      onChange={handleTagTypeSelection}
                    />
                    {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                  </div>
                );
              }}
            />
          }
        </div>
        <TextInput
          {...register('displayButtonText')}
          showRequiredOnLabel={!isGrid}
          labelShown
          label="Button Text"
          errorMessage={errors?.displayButtonText?.message as string}
        />
        <div className="gap-1">
          {
            <Controller
              name="displayType"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                const handleDisplayTypeSelection = (displayType: Option) => {
                  onChange(displayType.value);
                };

                return (
                  <div className="relative flex w-full flex-col">
                    <label className="text-sm font-normal text-neutral-600">
                      Display Type <span className="text-red-600"> *</span>
                    </label>
                    <Dropdown
                      hasLeftRoundedBorders
                      hasRightRoundedBorders
                      placeholder="Select Display Type"
                      containerClassname="w-full"
                      className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                      options={displayTypesOptions}
                      value={displayTypesOptions.find(
                        (displayTypesOption) => value && displayTypesOption.value === value,
                      )}
                      onChange={handleDisplayTypeSelection}
                    />
                    {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                  </div>
                );
              }}
            />
          }
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-6">
        <Controller
          name="imageUrl"
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <div className="relative flex w-full flex-col">
              <label className="mt-5 text-sm font-normal tracking-wide text-neutral-600">
                Image
              </label>
              <ProductImageUploader
                value={value}
                onChange={handleBannerImageChange}
                formMethods={formMethods}
              />
              {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
            </div>
          )}
        />
      </div>
    </FormPageLayout>
  );
}

export default TagForm;
