import { Button, Modal } from '@mwell-healthhub/commons';
import { ButtonVariant } from '@mwell-healthhub/components/Button/Button';

type Props<T> = {
  data: T;
  isOpen: boolean;
  title: string;
  description: string;
  isLoading?: boolean;
  toggle: () => void;
  onClickConfirm: (params: T) => void;
  confirmButtonText?: string;
  confirmButtonVariant?: ButtonVariant;
};

const ConfirmationModal = <T,>(props: Props<T>) => {
  const {
    data,
    isOpen,
    title,
    description,
    isLoading,
    toggle,
    confirmButtonText,
    confirmButtonVariant = 'primary',
    onClickConfirm,
  } = props;

  const handleConfirmClick = () => {
    onClickConfirm(data);
  };

  return (
    <Modal open={isOpen} setOpen={toggle}>
      <div className="pb-3">
        You are about to <span className="font-bold">&quot;{title}&quot;</span> this appointment
      </div>
      <div className="pb-3 text-sm text-gray-500">{description}</div>
      <div className="flex items-center justify-end gap-8">
        <div className="hover:cursor-pointer" onClick={toggle}>
          Cancel
        </div>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          variant={confirmButtonVariant}
          onClick={handleConfirmClick}
        >
          {confirmButtonText || 'Confirm'}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
