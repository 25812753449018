import { ProviderOnboardingStepEnum } from '../enums/provider-onboarding.enum';
import { OnboardingStep } from '../types/provider-onboarding.type';

export const SETUP_ACCOUNT_STEP: OnboardingStep = {
  step: ProviderOnboardingStepEnum.SETUP_ACCOUNT,
  title: 'Set up account',
  description: 'Access your very own Provider Portal with your email and password',
};

export const ADD_SERVICE_STEP: OnboardingStep = {
  step: ProviderOnboardingStepEnum.ADD_SERVICE,
  title: 'Add service',
  description: 'Show clients what you have to offer ',
  videoUrl: 'https://www.youtube.com/watch?v=TjvMP-K7sMs',
  tooltip: {
    title: 'Add your first service',
    description:
      'You can easily access features from this menu. Select the “Services” tab to open it.',
  },
  completedDialog: {
    title: 'Service added',
    description:
      'Good job! You have successfully added your first service. You can add more services once all tutorials have been completed.',
  },
};

export const ADD_BRANCH_STEP: OnboardingStep = {
  step: ProviderOnboardingStepEnum.ADD_BRANCH,
  title: 'Add a branch',
  description: 'Keep track of all your branches in one place',
  videoUrl: 'https://www.youtube.com/watch?v=olzHmOLNZEM',
  tooltip: {
    title: 'Convenient access to your branches',
    description:
      'HealthHub clients book appointments with a branch. As Super Admin, you can easily add and manage your branches (if multiple). Select the “Branches” tab to open it.',
  },
  completedDialog: {
    title: 'Branch added',
    description:
      'You have successfully added your first branch. You can add more branches (if you have more) once all tutorials have been completed.',
  },
};

export const SET_SCHEDULE_STEP: OnboardingStep = {
  step: ProviderOnboardingStepEnum.SET_SCHEDULE,
  title: 'Set schedule',
  description: 'So clients can book their own appointments',
  videoUrl: 'https://www.youtube.com/watch?v=jrnig80D0ng',
  tooltip: {
    title: 'Adjust your schedule anytime',
    description:
      'HealthHub clients book appointments with a branch. As Super Admin, you can easily add and manage your branches (if multiple). Select the “Branches” tab to open it.',
  },
  completedDialog: {
    title: 'Schedule set',
    description:
      'Excellent! You have successfully set your schedule. You’re one step closer to becoming a pro. You can continue setting this up once all tutorials have been completed.',
  },
};

export const VIEW_APPOINTMENT_STEP: OnboardingStep = {
  step: ProviderOnboardingStepEnum.VIEW_APPOINTMENT,
  title: 'View appointment',
  description: 'Quickly manage appointments with ease',
  videoUrl: 'https://www.youtube.com/watch?v=Jwp5pltr7hs',
  tooltip: {
    title: 'Easily manage appointments',
    description:
      "Let's practice with a sample appointment. You'll receive appointments once you are a published Provider. Select the “Appointments” tab to open it.",
  },
  completedDialog: {
    title: 'Appointment managed',
    description: 'Fantastic job! You are now ready to manage your incoming appointments.',
  },
};

export const SET_PROFILE_STEP: OnboardingStep = {
  step: ProviderOnboardingStepEnum.SET_PROFILE,
  title: 'Check your profile',
  description: `As a 'thank you' for your time and interest, we're giving you extra access to your profile. 

You may prepare the rest of your profile while we review your documents. Select the “Profile” tab to open it.`,
  tooltip: {
    title: 'Check your profile',
    description: `As a 'thank you' for your time and interest, we're giving you extra access to your profile. 

You may prepare the rest of your profile while we review your documents. Select the “Profile” tab to open it.” tab to open it.`,
  },
  completedDialog: {
    title: 'Profile complete!',
    description: `Your profile has been successfully completed.

Feel free to to watch the tutorials again or continue exploring the available features by selecting the tabs on the left.`,
  },
};

export const PROVIDER_ONBOARDING_STEPS: OnboardingStep[] = [
  SETUP_ACCOUNT_STEP,
  ADD_SERVICE_STEP,
  ADD_BRANCH_STEP,
  SET_SCHEDULE_STEP,
  VIEW_APPOINTMENT_STEP,
  SET_PROFILE_STEP,
];
