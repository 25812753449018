import { Appointment, AppointmentService } from '@healthhub/api-lib';

import { displayOtherConcern } from './appointment';

export const filterDisplayServices = (
  appointmentServices: AppointmentService[],
  delimiter?: string,
) => {
  return appointmentServices
    ?.map(({ providerService }) => providerService?.label || providerService?.service?.name)
    .join(delimiter || ', ');
};

export const displayServicesAndOtherConcerns = (appointment: Appointment, delimiter?: string) => {
  return [
    filterDisplayServices(appointment.appointmentServices, delimiter),
    displayOtherConcern(appointment.appointmentServices, appointment.otherConcern),
  ]
    .filter(Boolean)
    .join(' ');
};
