export enum AddressType {
  MAILING = 'MAILING',
  PERMANENT = 'PERMANENT',
  CURRENT = 'CURRENT',
}

export enum AddressFormEnum {
  LINE = 'line',
  UNIT_ROOM_FLOOR = 'unitRoomFloor',
  BUILDING_NAME = 'buildingName',
  LOT_BLOCK_PHASE_HOUSE = 'lotBlockPhaseHouse',
  STREET_NAME = 'streetName',
  SUBDIVISION = 'subdivision',
  BARANGAY = 'barangay',
  OTHER_BARANGAY = 'otherBarangay',
  MUNICIPALITY_CITY = 'municipalityCity',
  OTHER_MUNICIPALITY_CITY = 'otherMunicipalityCity',
  PROVINCE = 'province',
  REGION = 'region',
  COUNTRY = 'country',
  ZIP_CODE = 'zipCode',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  COORDINATES = 'coordinates',
  GOOGLE_MAPS_URL = 'googleMapsUrl',
}
