import { Appointment, AppointmentService, ProviderService } from '@healthhub/api-lib';
import currency from 'currency.js';
import isEmpty from 'lodash.isempty';

import { BookingPaymentRequirement, PriceType } from '../enums';
import { Price } from '../libs/price';

export function priceToInt(money: string | number): number {
  return currency(money).intValue;
}

export function intToPrice(int: number): number {
  return currency(int, { fromCents: true, precision: 2 }).value;
}

export function formatToPeso(int: number): string {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });

  const price = intToPrice(int);

  return formatter.format(price);
}

export function formatToDecimal(int: number, decimal = 2): string {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'decimal',
    maximumFractionDigits: decimal,
  });

  const price = intToPrice(int);

  return formatter.format(price);
}

export function formatToPesoWithoutTrailingZeros(int: number): string {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const price = intToPrice(int);

  return formatter.format(price);
}

export function parsePesoToNumber(formattedPeso: string): number {
  const numericString = formattedPeso.replace(/[^\d.-]/g, '');
  return parseFloat(numericString);
}

export function plainFormatToPeso(int: number) {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });

  return formatter.format(int);
}

export function addInt(value1: number, value2: number) {
  return currency(value1).add(value2).value;
}

export function subtractInt(value1: number, value2: number) {
  return currency(value1).subtract(value2).value;
}

export function multiplyInt(value1: number, value2: number) {
  return currency(value1).multiply(value2).value;
}

export function getServiceStringedPrice(service: ProviderService | AppointmentService) {
  const { minPrice, maxPrice } =
    'maxPrice' in service
      ? { minPrice: service.price, maxPrice: service.maxPrice }
      : { minPrice: service.minAmountInCents, maxPrice: service.maxAmountInCents };

  if (maxPrice) {
    return `${minPrice}-${maxPrice}`;
  }

  if (!minPrice) {
    return '0';
  }

  return minPrice.toString();
}

export function reduceToTotalRangePrice(acc: number[], providerService: Record<string, any>) {
  const [accMin, accMax] = acc;
  const stringedPrice = getServiceStringedPrice(providerService as any);
  const price = new Price(stringedPrice);
  const [min, max] = price.priceInCentsInMinMax;

  return [accMin + min, accMax + max];
}

export const providerServicesRequiresPayment = (services: ProviderService[]) => {
  return services?.some((service) => {
    return (
      service.bookingPaymentRequirement &&
      service.bookingPaymentRequirement !== BookingPaymentRequirement.NONE
    );
  });
};

export const serviceHasPriceRange = (
  services: ProviderService[] | AppointmentService[],
): boolean => {
  return services.some((service: ProviderService | AppointmentService) => {
    const price = new Price(getServiceStringedPrice(service), service.priceType);
    return price.type === PriceType.Range && service.priceType === PriceType.Range;
  });
};
export const serviceHasPriceStartsAt = (
  service: ProviderService[] | AppointmentService[],
): boolean => {
  return service.some((service) => {
    return service.priceType === PriceType.StartsAt;
  });
};

export const serviceHasPriceUponVisit = (
  service: ProviderService[] | AppointmentService[],
): boolean => {
  return service.some((service) => {
    return service.priceType === PriceType.Hide;
  });
};

export const getFormattedServicePrice = (providerService: ProviderService) => {
  if (isEmpty(providerService)) {
    return null || undefined;
  }
  const stringedPrice = getServiceStringedPrice(providerService);
  const newPrice = new Price(stringedPrice, providerService.priceType);
  const formattedPrice = newPrice.priceInPesos;
  return formattedPrice;
};
