import { Appointment } from '@healthhub/api-lib';
import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import {
  GetProviderClientProfileAppointmentsDto,
  fetchAppointmentById,
  fetchProviderClientProfileAppointments,
  getAdminAppointmentSummary,
  getAllAppointments,
  getAllAppointmentsForReporting,
  getAllAppointmentsForExport,
  GetAppointmentsForExportDto,
  getAppointmentReferralFormSignedUrl,
} from '../../api';
import {
  GET_ADMIN_APPOINTMENT_SUMMARY,
  GET_ALL_APPOINTMENTS,
  GET_ALL_APPOINTMENTS_FOR_EXPORT,
  GET_ALL_APPOINTMENTS_FOR_REPORTS,
  GET_ALL_PROVIDER_CLIENT_APPOINTMENTS,
  GET_APPOINTMENT,
  GET_APPOINTMENT_REFERRAL_FORM_SIGNED_URL,
} from '../../constants/queryKeys';

export function useGetAllAppointments({
  endDate,
  startDate,
  status,
  query,
  limit,
  page,
  providerTypeId,
  providerServiceIds,
  providerTagIds,
  sortBy,
  branchIds,
  brandId,
  platform,
}: {
  endDate?: string;
  query?: string;
  startDate?: string;
  status?: string;
  limit?: number;
  page?: number;
  providerTypeId?: string;
  providerServiceIds?: number[];
  providerTagIds?: number[];
  sortBy?: string;
  branchIds?: number[];
  brandId?: string;
  platform?: string;
}) {
  return useQuery(
    [
      GET_ALL_APPOINTMENTS,
      {
        status,
        startDate,
        endDate,
        query,
        limit,
        page,
        providerTypeId,
        providerServiceIds,
        providerTagIds,
        sortBy,
        branchIds,
        brandId,
        platform,
      },
    ],
    () =>
      getAllAppointments(
        startDate,
        endDate,
        status,
        query,
        limit,
        page,
        providerTypeId,
        providerServiceIds,
        providerTagIds,
        sortBy,
        branchIds,
        brandId,
        platform,
      ),
  );
}

export function useGetAllAppointmentsForExport(
  {
    startDate,
    endDate,
    clientId,
    statuses,
    serviceIds,
    tagIds,
    sortBy,
    branchIds,
  }: GetAppointmentsForExportDto,
  shouldFetch: boolean,
) {
  return useQuery(
    [
      GET_ALL_APPOINTMENTS_FOR_EXPORT,
      { startDate, endDate, clientId, statuses, serviceIds, tagIds, sortBy, branchIds },
    ],
    () =>
      getAllAppointmentsForExport({
        startDate,
        endDate,
        clientId,
        statuses,
        serviceIds,
        tagIds,
        sortBy,
        branchIds,
      }),
    {
      enabled: shouldFetch,
    },
  );
}

export function useAppointmentById(params: { id: string; config?: AxiosRequestConfig<any> }) {
  const { id, config } = params;
  return useQuery(
    [GET_ALL_PROVIDER_CLIENT_APPOINTMENTS, { id, config }],
    () => fetchAppointmentById(params.id, params.config),
    { enabled: !!params.id.length },
  );
}

export function useProviderClientProfileAppointments(
  params: GetProviderClientProfileAppointmentsDto,
) {
  const { endDate, providerClientProfileId, query, startDate, status, branchId } = params;
  return useQuery(
    [
      GET_ALL_PROVIDER_CLIENT_APPOINTMENTS,
      { status, startDate, endDate, query, providerClientProfileId, branchId },
    ],
    () => fetchProviderClientProfileAppointments(params),
    { enabled: !!providerClientProfileId },
  );
}

export function useGetAllAppointmentsForReport(
  shouldFetch: boolean,
  {
    endDate,
    startDate,
    status,
    query,
    providerTypeId,
    brandId,
  }: {
    endDate?: string;
    query?: string;
    startDate?: string;
    status?: string;
    providerTypeId?: string;
    brandId?: string;
  },
) {
  return useQuery(
    [
      GET_ALL_APPOINTMENTS_FOR_REPORTS,
      { status, startDate, endDate, query, providerTypeId, brandId },
    ],
    () =>
      getAllAppointmentsForReporting(startDate, endDate, status, query, providerTypeId, brandId),
    {
      enabled: shouldFetch,
    },
  );
}

export function useGetAppointment(id: string) {
  return useQuery([GET_APPOINTMENT, { id }], () => fetchAppointmentById(id));
}

export function useGetAdminAppointmentSummary({
  startDate,
  endDate,
  status,
  query,
  providerTypeId,
  brandId,
}: {
  startDate: string;
  endDate: string;
  status?: string;
  query?: string;
  providerTypeId?: string;
  brandId?: string;
}) {
  return useQuery(
    [GET_ADMIN_APPOINTMENT_SUMMARY, startDate, endDate, status, query, providerTypeId],
    () => getAdminAppointmentSummary(startDate, endDate, status, query, providerTypeId, brandId),
  );
}

export const useGetAppointmentReferralFormSignedUrl = (
  id?: number,
  referralFormFileId?: number,
  enabled?: boolean,
) =>
  useQuery(
    [GET_APPOINTMENT_REFERRAL_FORM_SIGNED_URL, id, referralFormFileId],
    () => getAppointmentReferralFormSignedUrl(id, referralFormFileId),
    { enabled },
  );
