import { CreateMerchantDto, EshopApi, UpdateMerchantDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { Merchant } from '../types';

export const merchantApi = HealthHubApiClient.use(EshopApi);

export type UpdateMerchantParams = {
  id: string;
  updateMerchantDto: UpdateMerchantDto;
};

export async function getMerchantByEmail(
  email: string,
  config?: AxiosRequestConfig,
): Promise<Merchant> {
  try {
    const response = await merchantApi.eshopControllerFindOneMerchantByEmail(email, config);
    const data = response.data;

    return data as unknown as Merchant;
  } catch (error) {
    throw new Error('Failed to fetch merchant');
  }
}

export async function createMerchant(createMerchantDto: CreateMerchantDto): Promise<Merchant> {
  try {
    const response = await merchantApi.eshopControllerCreateMerchant(createMerchantDto);
    const data = response.data;

    return data as unknown as Merchant;
  } catch (error) {
    throw new Error('Failed to create merchant');
  }
}

export async function updateMerchant(params: UpdateMerchantParams): Promise<Merchant> {
  const { id, updateMerchantDto } = params;

  try {
    const response = await merchantApi.eshopControllerUpdateMerchant(id, updateMerchantDto);
    const data = response.data;

    return data as unknown as Merchant;
  } catch (error) {
    throw new Error('Failed to update merchant');
  }
}

export async function updateMerchantByEmail(
  email: string,
  updateMerchantDto: UpdateMerchantDto,
  config?: AxiosRequestConfig,
): Promise<Merchant> {
  try {
    const response = await merchantApi.eshopControllerUpdateMerchantByEmail(
      email,
      updateMerchantDto,
      config,
    );
    const data = response.data;

    return data as unknown as Merchant;
  } catch (error) {
    throw new Error('Failed to update merchant');
  }
}
