import { Provider, UpdateProviderServiceDtoUpdateTypeEnum } from '@healthhub/api-lib';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Checkbox,
  Modal,
  classNames,
  formatProviderDisplayNameWithBranch,
} from '@mwell-healthhub/commons';
import { Button } from '@mwell-healthhub/commons/components/v2';

import { ProviderServiceFormValues } from './ProviderServiceForm';

type UserDesignationModalProps = {
  branches?: Provider[];
  onClose: () => void;
  onSubmit: (newService: ProviderServiceFormValues) => void;
};

const { All, OverrideSelectedBranches } = UpdateProviderServiceDtoUpdateTypeEnum;

export default function ProviderServiceEditActionModal(props: UserDesignationModalProps) {
  const { branches, onClose, onSubmit } = props;
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ProviderServiceFormValues>();
  const providerServiceBranches = watch('providerServiceBranchIds');
  const branchesToOverrideList = branches?.filter(
    (branch) => providerServiceBranches?.includes(branch.id),
  );

  const confirmSubmission = (newService: ProviderServiceFormValues) => {
    onSubmit(newService);
  };

  const renderProviderAssignedBranches = (field: any) =>
    branchesToOverrideList?.map((provider) => {
      return (
        <Checkbox
          key={`${provider.id}-branch-to-override`}
          className="text-secondary-500"
          checked={field?.value?.includes(provider.id)}
          onChange={(e) => {
            const branchServices = field?.value || [];
            const isChecked = (e.target as HTMLInputElement).checked;
            const updatedBranches = isChecked
              ? [...branchServices, provider.id]
              : branchServices.filter((id: number) => id !== provider.id);

            setValue('providerServiceBranchesToOverride', updatedBranches);
          }}
          label={
            <div className="text-neutral-500">{formatProviderDisplayNameWithBranch(provider)}</div>
          }
        />
      );
    });

  const renderBranches = watch('updateType') === OverrideSelectedBranches && (
    <div className="px-7">
      <Controller
        name="providerServiceBranchesToOverride"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col">{renderProviderAssignedBranches(field)}</div>
        )}
      />
    </div>
  );

  return (
    <Modal open size="lg">
      <form>
        <p className="mb-2 text-lg font-semibold text-gray-900">
          Confirm service information and pricing updates?
        </p>
        <p className="text-sm font-normal leading-6 text-neutral-500">
          You&apos;ve made changes to this service. How would you like to apply these changes?
        </p>
        <div className="mt-5 pr-2">
          <div className={classNames('mt-1.5 flex flex-col gap-5')}>
            <div className="flex items-center">
              <Controller
                name="updateType"
                control={control}
                render={({ field }) => (
                  <input
                    type="radio"
                    {...field}
                    value={All}
                    className="text-secondary-500 focus:ring-transparent"
                    checked={field.value === All}
                  />
                )}
              />
              <span className="pl-3 text-sm text-neutral-900">
                Apply changes to all selected branches
              </span>
            </div>
            <div className="flex items-center">
              <Controller
                name="updateType"
                control={control}
                render={({ field }) => (
                  <input
                    type="radio"
                    {...field}
                    value={OverrideSelectedBranches}
                    className="text-secondary-500 focus:ring-transparent"
                    checked={field.value === OverrideSelectedBranches}
                  />
                )}
              />
              <span className="pl-3 text-sm text-neutral-900">
                Choose branch to apply changes to
              </span>
            </div>
            {renderBranches}
            <div className="text-sm text-red-600">
              {errors?.providerServiceBranchesToOverride?.message}
            </div>
          </div>
        </div>
        <div className="text-sm text-red-600">{errors?.updateType?.message}</div>
        <div className="-mb-2 mt-5">
          <div className="bg-gray-90 mt-6 flex items-center justify-end gap-2">
            <Button variant="secondaryGray" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(e) => handleSubmit(confirmSubmission)(e)}>
              Confirm Changes
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
