import { useEffect } from 'react';

import useIsMobile from './useIsMobile';

const useRemoveHover = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      const elements = document.querySelectorAll('[class*="hover:"]');
      elements.forEach((element) => {
        const classes = Array.from(element.classList);
        const filteredClasses = classes.filter((classItem) => !classItem.startsWith('hover:'));
        element.setAttribute('class', filteredClasses.join(' '));
      });
    }
  }, [isMobile]);
};

export default useRemoveHover;
