export type DoctorsPaginatedResponse = {
  page: number;
  data: Doctor[];
  count: number;
  limit: number;
};

interface HospitalMaster {
  id: number;
  health_facility_code_short: string;
  facility_name: string;
  street: string;
  building_name: string;
  region_name: string;
  province: string;
  city_municipality: string;
  barangay: string;
  zip_code: string;
  landline_number: string;
  landline_number2: string;
  email?: string;
  level?: string;
  sec?: string;
  doh_id?: string;
  is_active: number;
  created_by_id?: string;
  updated_by_id?: string;
  created_at: string;
  updated_at: string;
}

export type HospitalAffiliation = {
  id: number;
  md_id: string;
  health_facility_code_short: string;
  is_active: number;
  created_by_id: string;
  updated_by_id?: any;
  deleted_by_id?: any;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
  hospital_master: HospitalMaster;
};

export type Doctor = {
  id: number;
  md_id: string;
  md_code?: string;
  sex_code?: string;
  sex_text: string;
  sex_display: string;
  marital_code?: string;
  marital_text?: string;
  marital_display?: string;
  religion_code?: string;
  religion_text?: string;
  religion_display?: string;
  race_code?: string;
  race_text?: string;
  race_display?: string;
  date_of_birth: string;
  deceased: boolean;
  date_of_death?: string;
  deleted: boolean;
  deleted_reason?: string;
  deleted_by_uid?: string;
  deleted_by_did?: string;
  merged: number;
  active_md_id?: string;
  patient_id?: string;
  user_id: string;
  e_signature_renamed?: string;
  profile_photo_renamed?: string;
  employee_id?: string;
  is_active: number;
  created_by_id?: string;
  modified_by_id?: string;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
  primary_name: PrimaryName;
  specialties_string: string;
  profile_photo_url?: string;
  e_signature_url?: string;
  user: User;
  names: PrimaryName[];
  specialties: Specialty[];
  prc_license: PRCLicense;
  subspecialties: Specialty[];
  hospital_affiliations: HospitalAffiliation[];
};

type PRCLicense = {
  id: number;
  id_type_code: string;
  id_type_text: string;
  id_type_display: string;
  md_id: string;
  type_code: string;
  type_text: string;
  type_display: string;
  number: string;
  issued_code?: string;
  issued_text?: string;
  issued_display?: string;
  validity_granted_datetime?: string;
  validity_start_datetime?: string;
  validity_renewal_datetime?: string;
  validity_expiry_datetime: string;
  validity_is_renewable: boolean;
  validity_is_expired: boolean;
  status_code?: string;
  status_text?: string;
  status_display?: string;
  notes?: string;
  attachment?: string;
  is_active: number;
  created_by_id?: string;
  modified_by_id?: string;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
  validated_at?: string;
  validated_by_id?: string;
  is_verified: number;
};

type Specialty = {
  id: number;
  md_id: string;
  type_code: string;
  type_text: string;
  type_display: string;
  specialty_code: string;
  specialty_text: string;
  specialty_display: string;
  parent_specialty_code?: string;
  parent_specialty_text?: string;
  parent_specialty_display?: string;
  status_code?: string;
  status_text?: string;
  status_display?: string;
  training_id?: string;
  certification_id?: string;
  notes?: string;
  attachment?: string;
  is_active: number;
  created_by_id: string;
  modified_by_id?: string;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
};

export enum AccountStatus {
  ACTIVE = 'active',
  DISAPPROVED = 'deactivated',
  PENDING = 'pending',
}

type User = {
  id: string;
  communication_user_id?: string;
  communication_token?: string;
  sub?: string;
  customer_id?: string;
  card_token?: string;
  role_name: string;
  primary_user_id?: string;
  prefix?: string;
  first: string;
  middle?: string;
  last: string;
  suffix?: string;
  sex?: string;
  date_of_birth?: string;
  email: string;
  email_verified_at?: string;
  pp_tc_accepted_at?: string;
  contact_no?: string;
  contact_no_verified_at?: string;
  social_photo?: string;
  is_active: number;
  onboarding_route: string;
  password_system_generated: number;
  is_mwell_plus: number;
  is_featured: number;
  is_pinned: number;
  account_status: AccountStatus;
  joined_mwell_at: string;
  logged_in_at?: string;
  created_by_id?: string;
  modified_by_id?: string;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
  google_id?: string;
  languages_spoken?: string;
  is_new_user: number;
  sa_accepted_at?: string;
  is_validated: number;
  display: string;
  full: string;
  professional: string;
};

type PrimaryName = {
  id: number;
  md_id: string;
  type_code: string;
  type_text: string;
  type_display: string;
  prefix: string;
  first: string;
  middle?: string;
  last: string;
  suffix?: string;
  degrees?: string;
  is_default: boolean;
  is_primary: boolean;
  created_at: string;
  updated_at: string;
  display: string;
  full: string;
  professional: string;
};
