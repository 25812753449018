import {
  ChangeEvent,
  FocusEvent,
  HTMLProps,
  Ref,
  forwardRef,
  useId,
  useRef,
  useState,
} from 'react';

import { clsxMerge } from '../../utils';

export interface DateInputProps extends Omit<HTMLProps<HTMLInputElement>, 'type'> {
  containerClassName?: string;
  errorMessage?: string;
  floaterHelperText?: string;
  label?: string;
  labelClassName?: string;
  labelElement?: JSX.Element;
  labelShown?: boolean;
  showRequiredOnLabel?: boolean;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function DateInput(props: DateInputProps, forwardedRef?: Ref<HTMLInputElement>) {
  const [isEmpty, setIsEmpty] = useState(true);
  const {
    className = '',
    containerClassName,
    disabled,
    errorMessage,
    floaterHelperText,
    id: idProp,
    label,
    labelClassName,
    labelElement,
    labelShown = false,
    placeholder,
    showRequiredOnLabel = false,
    title,
    type = 'date',
    onFocus,
    onBlur,
    onChange,
    ...etcProps
  } = props;
  const defaultId = useId();
  const id = idProp ?? defaultId;
  const defaultRef = useRef<HTMLInputElement>(null);
  const ref = forwardedRef ?? defaultRef;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e);
    setIsEmpty((e.currentTarget.value ?? '') === '');
  }

  function handleFocus(e: FocusEvent<HTMLInputElement>) {
    onFocus?.(e);
    setIsEmpty(false);
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    onBlur?.(e);
    setIsEmpty((e.currentTarget.value ?? '') === '');
  }

  const commonClassNames = 'w-full sm:text-sm sm:leading-6';

  return (
    <div className={containerClassName}>
      <label
        htmlFor={id}
        className={clsxMerge(
          'block text-sm font-medium !leading-6 text-gray-900',
          {
            'sr-only': !labelShown,
          },
          labelClassName,
        )}
      >
        {label || labelElement}
        {showRequiredOnLabel && <span className="text-red-500">*</span>}
      </label>
      <div className="relative">
        <input
          {...etcProps}
          ref={ref}
          id={id}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type}
          title={title ?? label}
          disabled={disabled}
          className={clsxMerge(
            commonClassNames,
            'shadow-sm block h-12 rounded-md border-0 px-3 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary1 disabled:opacity-50',
            className,
            { 'ring-red-600 focus:ring-red-600': errorMessage },
          )}
          placeholder={placeholder}
          value={etcProps.value}
        />
        {floaterHelperText && (
          <small className="absolute left-[115px] top-[10px] text-gray-400">
            {floaterHelperText}
          </small>
        )}
        {isEmpty && !etcProps.value && !etcProps.defaultValue && (
          <span
            className={clsxMerge(
              commonClassNames,
              'fow pointer-events-none absolute left-0 top-0 h-full items-center py-2 pl-3 pr-10',
              {
                'opacity-50': disabled,
              },
            )}
          >
            <span className="flex h-full min-w-[100px] items-center bg-white text-gray-400">
              {placeholder}
            </span>
          </span>
        )}
      </div>
      {errorMessage && <div className="mt-1 text-sm text-red-600">{errorMessage}</div>}
    </div>
  );
}

export default forwardRef(DateInput);
