import { ReactNode } from 'react';

import { Cancel } from 'iconoir-react';

import { Button, Modal } from '@mwell-healthhub/commons';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

type Props = {
  isOpen: boolean;
  children?: ReactNode;
  onSave: () => void;
  toggle: () => void;
};

const ConfirmRemoveServiceScheduleModal = (props: Props) => {
  const { isOpen, toggle, onSave, children } = props;

  const handleConfirm = () => {
    onSave();
  };

  return (
    <Modal open={isOpen} setOpen={toggle} size="lg">
      <div className="flex">
        <ExclamationTriangleIcon className="mr-3 h-10 w-10 rounded-full bg-red-100 p-2 text-red-500" />
        <div className="mt-1 flex-1">
          <div className="flex justify-between text-xl font-bold text-gray17">
            <div className="self-stretch font-['Poppins'] text-lg font-semibold leading-7 text-gray-900">
              Delete this service schedule day?
            </div>
            <Cancel onClick={toggle} className="cursor-pointer" />
          </div>
          <div>
            <span className="text-sm font-normal leading-tight tracking-tight text-gray-500">
              Are you sure you want to delete the day of this service schedule?
            </span>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="mt-6 flex items-center justify-end gap-2">
          <Button variant="plainNoOutline" onClick={toggle}>
            Cancel
          </Button>
          <Button variant="error" onClick={handleConfirm}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmRemoveServiceScheduleModal;
