import { Provider } from '@healthhub/api-lib';
import { Controller, useFormContext } from 'react-hook-form';

import { CountryCodeType } from '@mwell-healthhub/commons';

import { ProviderFormEnum } from '../../../enums';
import { convertObjectsToOptions, findCountryCodeFromDialCode } from '../../../utils';
import Dropdown from '../../Dropdown';
import MobileNumberInput from '../../MobileNumberInput';
import BannerFormField from '../../ProviderFormFields/BannerFormField';
import LogoFormField from '../../ProviderFormFields/LogoFormField';
import TextInput from '../../TextInput';
import { AddBranchType } from '../BranchForm';

type Props = {
  providers?: Provider[];
  showProviderSelection?: boolean;
  onChangeBrand?: (provider?: Provider) => void;
};

function BranchDetails(props: Props) {
  const { providers = [], showProviderSelection = false, onChangeBrand } = props;

  const {
    formState: { errors },
    register,
    watch,
    control,
    getValues,
    setValue,
  } = useFormContext<AddBranchType>();

  const providerOptions = convertObjectsToOptions(providers, {
    includeNone: false,
  });

  const defaultPhoneCountryCode = findCountryCodeFromDialCode(
    getValues(ProviderFormEnum.COUNTRY_CODE) as string,
  );

  return (
    <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
      <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
        Branch Information
      </span>
      <div
        className={`grid grid-cols-1 gap-4 md:gap-6 ${
          showProviderSelection ? 'sm:grid-cols-2' : 'sm:grid-cols-1'
        }`}
      >
        {showProviderSelection && (
          <Controller
            name={ProviderFormEnum.PARENT_PROVIDER_ID}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Dropdown
                hasLeftRoundedBorders
                hasRightRoundedBorders
                showRequiredOnLabel
                label="Provider"
                options={providerOptions}
                placeholder="Select Provider"
                value={providerOptions.find((category) => category.value === value)}
                onChange={(e) => {
                  onChangeBrand?.(providers?.find((provider) => provider.id === e.value));
                  onChange(e.value);
                }}
                errorMessage={errors?.parentProviderId?.message || ''}
              />
            )}
          />
        )}
        <TextInput
          labelShown
          showRequiredOnLabel
          {...register(ProviderFormEnum.DISPLAY_NAME)}
          errorMessage={errors?.displayName?.message}
          label="Display Name"
        />
      </div>
      <div className="grid grid-cols-2 gap-6">
        <Controller
          name={ProviderFormEnum.MOBILE_PHONE_NUMBER}
          control={control}
          render={({ field }) => (
            <MobileNumberInput
              label={
                <>
                  Phone Number <small>(for SMS Notification)</small>
                </>
              }
              defaultCountryCode={defaultPhoneCountryCode}
              errorMessage={errors?.mobilePhoneNumber?.message}
              value={field.value}
              onChange={field.onChange}
              onChangeCountry={(countryCode: CountryCodeType) => {
                setValue(ProviderFormEnum.COUNTRY_CODE, countryCode.dial_code);
              }}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-6">
        <TextInput
          labelShown
          showRequiredOnLabel
          {...register(ProviderFormEnum.BRANCH)}
          errorMessage={errors?.branch?.message}
          label="Branch Name"
        />
        <TextInput
          labelShown
          showRequiredOnLabel
          containerClassName="col-span-2 md:col-span-1"
          {...register(ProviderFormEnum.OFFICE_HOURS)}
          errorMessage={errors?.officeHours?.message}
          label="Office Hours"
          placeholder="ex. Mon - Fri, 9am - 5pm"
        />
      </div>
      <div className="mb-5 flex flex-col items-center gap-4 sm:flex-row">
        <LogoFormField />
      </div>
      <div className="mb-0 flex flex-col items-center gap-4 sm:flex-row">
        <BannerFormField />
      </div>
    </div>
  );
}

export default BranchDetails;
