import { ClientProfile, CreateClientProfileDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  UpdateClientProfileParams,
  createClientProfile,
  updateClientProfile,
} from '../../api/clientProfileService';

export const useCreateClientProfile = (
  mutationOptions?: MutationOptions<
    ClientProfile,
    AxiosError<{ message: string }>,
    CreateClientProfileDto
  >,
) => useMutation(createClientProfile, mutationOptions);

export const useUpdateClientProfile = (
  mutationOptions?: MutationOptions<
    ClientProfile,
    AxiosError<{ message: string }>,
    UpdateClientProfileParams
  >,
) => useMutation(updateClientProfile, mutationOptions);
