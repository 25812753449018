import fileTypeInfo from 'magic-bytes.js';

import { FileSizeLimitEnum, FileTypeExtensionEnum } from '../constants';

export function convertBytesToMegabytes(bytes: number): number {
  return bytes / 1048576;
}

export function formatBytesToMegabytes(bytes: number): string {
  return `${convertBytesToMegabytes(bytes)} MB`;
}

type FileValidationConfig = {
  acceptedFileTypes?: FileTypeExtensionEnum[];
  maxSizeLimit?: number;
};

export async function validateFiles(files: File[], config?: FileValidationConfig) {
  const {
    acceptedFileTypes = [
      FileTypeExtensionEnum.JPG,
      FileTypeExtensionEnum.JPEG,
      FileTypeExtensionEnum.PNG,
    ],
    maxSizeLimit = FileSizeLimitEnum._50MB,
  } = config || {};
  const readPromises = [];

  for (const file of files) {
    const promise = new Promise<File>((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onloadend = (event) => {
        if (event.target === null) return;

        const arrayBuffer = event.target.result as ArrayBuffer;
        const bytes = new Uint8Array(arrayBuffer);
        const fileType = fileTypeInfo(bytes);

        if (!fileType || fileType.length === 0 || !fileType[0]?.typename) {
          reject('This file is not supported.');
          return;
        }

        const isFileSizeValid = file.size <= maxSizeLimit;
        const isFileTypeSupported = acceptedFileTypes.includes(
          fileType[0].typename as FileTypeExtensionEnum,
        );

        if (isFileSizeValid && isFileTypeSupported) {
          resolve(file);
          return;
        } else {
          reject('This file is not supported.');
          return;
        }
      };

      fileReader.readAsArrayBuffer(file);
    });

    readPromises.push(promise);
  }

  return Promise.all(readPromises);
}

export const getBlobName = (name: string) => {
  const urlParts = name.split('/');

  return urlParts[urlParts.length - 1];
};

export const getFileExtension = (file: { originalname: string }) => {
  const filename = file.originalname;
  const extension = filename.split('.').pop() as string;

  return `.${extension.toLowerCase()}`;
};

export const checkImageSizeExceeds = (imageSize: number, sizeLimit = 10) => {
  const maxSize = sizeLimit * 1024 * 1024;
  const marginOfError = 0.03; // 3%

  const sizeWithMargin = maxSize - maxSize * marginOfError;

  if (imageSize > sizeWithMargin) {
    return true;
  }

  return false;
};
