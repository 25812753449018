import { Option } from '../types/Option.type';

export const SUFFIXES: string[] = [
  'Jr.',
  'Sr.',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
  'XIV',
  'XV',
  'XVI',
  'XVII',
  'XVIII',
  'XIX',
  'XX',
];

export const SUFFIX_OPTIONS: Option[] = [
  { value: '', label: 'None' },
  ...SUFFIXES.map((suffix) => ({ value: suffix, label: suffix })),
];

export const TITLE_OPTIONS: string[] = ['Mr.', 'Ms.', 'Mrs.', 'Dr.'];
