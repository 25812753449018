import {
  CreateMessageDto,
  CreateMessageThreadDto,
  GetMessageThreadByMemberIdsDto,
} from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  createMessage,
  createMessageThread,
  getMessageThreadByMemberIds,
  updateThreadReadByIdsArray,
} from '../../api';
import { MessageThread } from '../../types';

type UpdateThreadReadVariables = {
  threadId: string;
  branchId?: string;
};

export const useCreateMessageThreadMutation = (
  mutationOptions: MutationOptions<
    MessageThread,
    AxiosError<{ message: string }>,
    CreateMessageThreadDto
  >,
) => {
  return useMutation<MessageThread, AxiosError<{ message: string }>, CreateMessageThreadDto>(
    createMessageThread,
    mutationOptions,
  );
};

export const useCreateMessageMutation = (
  mutationOptions: MutationOptions<unknown, AxiosError<{ message: string }>, CreateMessageDto>,
) => {
  return useMutation<unknown, AxiosError<{ message: string }>, CreateMessageDto>(
    createMessage,
    mutationOptions,
  );
};

export const useGetMessageThreadByMemberIdsMutation = (
  mutationOptions: MutationOptions<
    unknown,
    AxiosError<{ message: string }>,
    GetMessageThreadByMemberIdsDto
  >,
) => {
  return useMutation<unknown, AxiosError<{ message: string }>, GetMessageThreadByMemberIdsDto>(
    getMessageThreadByMemberIds,
    mutationOptions,
  );
};

export const useUpdateThreadReadByIdsArrayMutation = (
  mutationOptions: MutationOptions<
    unknown,
    AxiosError<{ message: string }>,
    UpdateThreadReadVariables
  >,
) => {
  return useMutation<unknown, AxiosError<{ message: string }>, UpdateThreadReadVariables>(
    // Destructure the variables to pass them correctly to the service function
    ({ threadId, branchId }) => updateThreadReadByIdsArray(threadId, branchId),
    mutationOptions,
  );
};
