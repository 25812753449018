import { twMerge } from 'tailwind-merge';

type Props = {
  isSelected: boolean;
};

export default function RadioButton(props: Props) {
  const { isSelected = false } = props;

  const selectorClass = twMerge(
    'h-4 w-4 rounded-full',
    isSelected ? 'border-4 border-primary-500' : 'border border-neutral-300',
  );

  return <div className={selectorClass} />;
}
