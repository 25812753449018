import { Fragment, ReactNode, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Cancel as CancelIcon, Menu as MenuIcon } from 'iconoir-react';

import AppLogo from '../../components/AppLogo';
import { useRouter, useToggle } from '../../hooks';
import { GroupedRoute } from '../../types';
import { classNames } from '../../utils/classNames';

type Props = {
  navbar: ReactNode;
  content?: ReactNode;
  routes: GroupedRoute<React.ElementType>[];
  sidebarTitle?: string;
  footer?: ReactNode;
};

const CommonLayout = (props: Props) => {
  const labRequestRoute = '/lab-requests';
  const { routes, navbar, content, sidebarTitle, footer } = props;

  const [isRendered, setIsRendered] = useState(false);

  const { isOpen, handleToggle } = useToggle(false);
  const router = useRouter();
  const currentLocation = router.pathname;

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const handleRedirectTo = (path: string) => {
    router.push(path);
  };

  if (!isRendered) {
    return null;
  }

  const getIsCurrentLocation = (path: string) => currentLocation.includes(path) && path !== '/';

  const defaultWhitespace = 'px-6 md:px-8 lg:px-20';
  const currentPage = router.pathname;

  let LabPageWhitespace = defaultWhitespace;

  if (currentPage === labRequestRoute) {
    LabPageWhitespace = 'px-6 md:px-16 lg:px-16';
  }

  const displayNavigations = routes.map((route, index) => {
    if (!route.hasPermission) return null;

    return (
      <li key={index}>
        <ul className="-mx-2 space-y-1">
          <div
            className={clsx('border-t border-gray18 pb-2', {
              hidden: index === 0 || route.title,
            })}
          />
          {route.routes.map((item) => {
            if (!item.hasPermission) return null;

            return (
              <li key={item.name}>
                <button
                  className={classNames(
                    getIsCurrentLocation(item.path)
                      ? 'w-full bg-lightPrimary text-primary2'
                      : 'w-full text-gray4 hover:bg-lightPrimary  hover:text-primary2',
                    'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                  )}
                  onClick={() => handleRedirectTo(item.path)}
                >
                  <item.icon className="h-6 w-6 shrink-0 text-primary2" />
                  {item.name}
                </button>
              </li>
            );
          })}
        </ul>
      </li>
    );
  });

  const displaySidenavContent = (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
      <div className="flex shrink-0 items-center pt-8">
        <AppLogo />
      </div>

      <nav className="flex flex-1 flex-col">
        {sidebarTitle && <p className="mb-2 text-xl font-medium text-gray-900">{sidebarTitle}</p>}
        <ul className="flex flex-1 flex-col gap-y-3">{displayNavigations}</ul>
      </nav>
    </div>
  );

  const displayMobileView = (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={handleToggle}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" className="-m-2.5 p-2.5" onClick={handleToggle}>
                    <span className="sr-only">Close sidebar</span>
                    <CancelIcon className="h-6 w-6 text-white" />
                  </button>
                </div>
              </Transition.Child>
              {displaySidenavContent}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );

  const displayMenuButton = (
    <div className="flex items-center gap-6">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={handleToggle}>
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />
    </div>
  );

  return (
    <div>
      {displayMobileView}

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {displaySidenavContent}
      </div>

      <div className="flex min-h-screen flex-col lg:pl-72">
        <div className="sticky top-0 z-40 flex">
          <div className="flex shrink-0 items-center gap-x-4 bg-lightGray px-4 sm:gap-x-6 sm:px-6 sm:py-6 lg:px-8">
            {displayMenuButton}
          </div>
          <div className="flex-grow bg-lightGray">{navbar}</div>
        </div>
        <main className="flex-1 bg-lightGray pb-10">
          <div className={LabPageWhitespace}>{content}</div>
        </main>
      </div>
      {footer && <div className="relative z-50">{footer}</div>}
    </div>
  );
};

export default CommonLayout;
