import { UpdateAdminConfigDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { updateAdminConfig } from '../../api';
import { AdminConfig } from '../../types';

export const useUpdateAdminConfigMutation = (
  mutationOptions?: MutationOptions<
    AdminConfig,
    AxiosError<{ message: string }>,
    { id: string; updateAdminConfigDto: UpdateAdminConfigDto }
  >,
) => {
  return useMutation((params) => updateAdminConfig(params), mutationOptions);
};
