import {
  CreateScheduleDto,
  ScheduleApi,
  ScheduleResponse,
  UpdateScheduleDto,
} from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { WeekDayEnum } from '../enums/common';
import { MonthEnum } from '../enums/date';

const scheduleApi = HealthHubApiClient.use(ScheduleApi);

export type QuerySchedulesDto = {
  day?: WeekDayEnum;
  endDate?: string;
  isBlocked?: boolean;
  isRegularSchedule?: boolean;
  isSpecificDate?: boolean;
  includeProviderServiceSchedule?: boolean;
  month?: MonthEnum;
  providerId?: number;
  providerServiceIds?: number[];
  selectedDate?: string;
  startDate?: string;
};

export async function createSchedule(schedule: CreateScheduleDto) {
  try {
    const { data } = await scheduleApi.scheduleControllerCreateSchedule(schedule);

    return data;
  } catch (error) {
    throw new Error('Failed to create schedule');
  }
}

export async function removeScheduleById(id: string, providerId?: string) {
  try {
    const { data } = await scheduleApi.scheduleControllerRemoveSchedule(id);

    return data;
  } catch (error) {
    throw new Error('Failed to remove  schedule');
  }
}

export async function updateSchedule(scheduleId: string, schedule: UpdateScheduleDto) {
  try {
    const { data } = await scheduleApi.scheduleControllerUpdateSchedule(scheduleId, schedule);

    return data;
  } catch (error) {
    throw new Error('Failed to create schedule');
  }
}

export const getSchedules = async (params?: QuerySchedulesDto): Promise<ScheduleResponse[]> => {
  try {
    // Delay allow users to see the schedules is fetched and updated upon selecting a date or time
    await new Promise((resolve) => setTimeout(resolve, 300));

    const response = await scheduleApi.scheduleControllerFindSchedulesByProviderId(
      params?.startDate,
      params?.endDate,
      params?.selectedDate,
      params?.day,
      params?.isSpecificDate || undefined,
      params?.isBlocked || undefined,
      params?.isRegularSchedule || undefined,
      params?.includeProviderServiceSchedule || undefined,
      params?.providerId?.toString(),
      params?.month,
      params?.providerServiceIds || undefined,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch schedules');
  }
};
