import { useEffect, useState } from 'react';

import { User } from '@healthhub/api-lib';
import isEmpty from 'lodash.isempty';

import { LocalStorageKey, getLocalStorageItem } from '@mwell-healthhub/commons';

export const useGetUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const currentUser: User | null = getLocalStorageItem(LocalStorageKey.USER);

    if (!isEmpty(currentUser)) {
      setUser(currentUser);
      setIsUserLoggedIn(!!currentUser);
    }
  }, []); // TODO: Maybe add to useEffect hook [localStorage.getItem(LocalStorageKey.USER)]

  return { user, isUserLoggedIn };
};
