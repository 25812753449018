import { GetOCRDto } from '@healthhub/api-lib';
import { useQuery } from '@tanstack/react-query';

import { GetUserAppointmentsDto, fetchCurrentUserAppointments, getOCR } from '../../api';
import { GET_OCR, GET_CURRENT_USER_APPOINTMENTS } from '../../constants';

export function useGetUserAppointments(params: GetUserAppointmentsDto) {
  return useQuery(
    [GET_CURRENT_USER_APPOINTMENTS, params.status, params.sortBy, params.platform],
    async () => fetchCurrentUserAppointments(params),
  );
}

export function useGetOCR(params: GetOCRDto) {
  return useQuery([GET_OCR, params], async () => getOCR(params));
}
