import { useEffect, useRef, useState } from 'react';

import { clsxMerge } from '../utils';

type ListItem = {
  line: string;
  type: 'ul' | 'ol' | 'p' | 'button';
};

type Section = ListItem[][];

type Props = {
  remarks: string;
  isTruncated?: boolean;
  lineClamp?: string;
  className?: string;
};

const FieldTextHtmlFormatter = (props: Props): JSX.Element => {
  const { remarks, isTruncated = false, lineClamp = 'line-clamp-5', className } = props;
  const [showAll, setShowAll] = useState<boolean>(false);
  const [isClamped, setIsClamped] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (contentRef.current && isTruncated) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { lineHeight, height } = window.getComputedStyle(entry.target);
          const numLines = Math.floor(parseInt(height, 10) / parseFloat(lineHeight));
          setIsClamped(numLines >= 5);
        }
      });

      observer.observe(contentRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const sections =
    remarks?.split('\n\n').map((section) => {
      const lines: string[] = section.split('\n');

      let listType: 'ul' | 'ol' | '' = '';
      const sectionItems: Section = [[]];

      lines.forEach((line) => {
        if (/^\d+\.\s/.test(line)) {
          if (listType !== 'ol') {
            sectionItems.push([]);
            listType = 'ol';
          }
          sectionItems[sectionItems.length - 1].push({
            line: line.replace(/^\d+\.\s/, ''),
            type: 'ol',
          });
        } else if (/^[*-]\s/.test(line)) {
          if (listType !== 'ul') {
            sectionItems.push([]);
            listType = 'ul';
          }
          sectionItems[sectionItems.length - 1].push({
            line: line.replace(/^[*-]\s/, ''),
            type: 'ul',
          });
        } else {
          sectionItems.push([{ line, type: 'p' }]);
          listType = '';
        }
      });

      return sectionItems;
    }) ?? [];

  return (
    <>
      {sections.map((section, sectionIndex) => (
        <div key={`section-wrapper-${sectionIndex}`}>
          <div
            key={`section-${sectionIndex}`}
            ref={contentRef}
            className={clsxMerge(
              'text-neutral-600',
              !showAll && isTruncated ? lineClamp : 'line-clamp-none',
              className,
            )}
          >
            {section.map((items, index) => {
              let content = null;
              switch (items[0]?.type) {
                case 'ol':
                  content = (
                    <ol
                      key={`ol-${sectionIndex}-${index}`}
                      className="ml-5 list-outside list-decimal"
                    >
                      {items.map((item, itemIndex) => (
                        <li key={`li-${sectionIndex}-${index}-${itemIndex}`} className="pl-2">
                          {item.line}
                        </li>
                      ))}
                    </ol>
                  );
                  break;
                case 'ul':
                  content = (
                    <ul key={`ul-${sectionIndex}-${index}`} className="ml-7 list-outside list-disc">
                      {items.map((item, itemIndex) => (
                        <li key={`li-${sectionIndex}-${index}-${itemIndex}`}>{item.line}</li>
                      ))}
                    </ul>
                  );
                  break;
                case 'p':
                  content = (
                    <p key={`p-${sectionIndex}-${index}`}>
                      {items.map((item, itemIndex) => item.line)}
                    </p>
                  );
                  break;
                default:
                  break;
              }
              return content;
            })}
          </div>
          {isClamped && isTruncated && (
            <button
              key={`button-${sectionIndex}`}
              className="text-sm font-normal text-primary-500 underline"
              onClick={handleShowMore}
            >
              {!showAll ? 'Show More' : 'Show Less'}
            </button>
          )}
        </div>
      ))}
    </>
  );
};

export default FieldTextHtmlFormatter;
