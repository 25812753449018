import { useCallback, useState } from 'react';

export const useToggle = (initialValue = false) => {
  const [isOpen, setOpen] = useState<boolean>(initialValue);

  const handleToggle = useCallback(() => {
    setOpen((previousValue) => !previousValue);
  }, []);

  const handleToggleOpen = useCallback((value: boolean) => {
    setOpen(value);
  }, []);

  return { isOpen, handleToggle, handleToggleOpen };
};
