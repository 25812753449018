import { Address, Provider } from '@healthhub/api-lib';

export function sanitizeProvider(provider?: Provider) {
  const {
    canWorkWithChildren,
    code,
    createdAt,
    deletedAt,
    id,
    providerType,
    region,
    schedule,
    services,
    updatedAt,
    userProviders,
    ...rest
  } = provider ?? {};

  return rest;
}

export function sanitizeAddress(address?: Address) {
  const { id, createdAt, updatedAt, deletedAt, ...rest } = address ?? {};

  return rest;
}

export function formatProviderDisplayNameWithBranch(provider?: Provider) {
  const name = [
    provider?.displayName ? provider.displayName : provider?.name,
    provider?.branch ? `(${provider.branch})` : '',
  ];

  return name.join(' ');
}
