import { twMerge } from 'tailwind-merge';

import { AppLogo } from '@mwell-healthhub/commons';

type Props = {
  className?: string;
};

const Navigation = (props: Props) => {
  const { className } = props;

  return (
    <nav
      className={twMerge(
        'fixed z-10 flex w-full justify-center bg-white p-4 md:relative md:max-w-none md:py-6',
        className,
      )}
    >
      <AppLogo height={24} />
    </nav>
  );
};

export default Navigation;
