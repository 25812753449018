import { HTMLProps, useMemo } from 'react';

import { clsxMerge } from '../utils';

interface Props extends HTMLProps<HTMLDivElement> {
  currentPage: number;
  totalPages?: number;
  setCurrentPage: (page: number) => void;
  itemsPerPage?: number;
  totalItems?: number;
  itemsCount?: number;
}

const PAGE_ITEMS_TO_RENDER_LENGTH = 5;

const Pagination = ({
  currentPage,
  totalPages,
  setCurrentPage,
  itemsPerPage,
  totalItems,
  className,
}: Props) => {
  const { pagesToRender, startPage, endPage } = useMemo(() => {
    if (!totalPages) {
      return { pagesToRender: [], startPage: 1, endPage: 1 };
    }

    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > PAGE_ITEMS_TO_RENDER_LENGTH) {
      if (currentPage <= Math.floor(PAGE_ITEMS_TO_RENDER_LENGTH / 2)) {
        endPage = PAGE_ITEMS_TO_RENDER_LENGTH;
      } else if (currentPage > totalPages - Math.floor(PAGE_ITEMS_TO_RENDER_LENGTH / 2)) {
        startPage = totalPages - PAGE_ITEMS_TO_RENDER_LENGTH + 1;
      } else {
        startPage = currentPage - Math.floor(PAGE_ITEMS_TO_RENDER_LENGTH / 2);
        endPage = currentPage + Math.floor(PAGE_ITEMS_TO_RENDER_LENGTH / 2);
      }
    }
    return {
      pagesToRender: Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage),
      startPage,
      endPage,
    };
  }, [currentPage, totalPages]);

  const renderPageNumbers = pagesToRender.map((pageNumber: number) => (
    <button
      key={pageNumber}
      onClick={() => setCurrentPage(pageNumber)}
      className={`px-[14px] py-2 text-sm font-semibold ${
        currentPage === pageNumber
          ? 'rounded-lg border-gray-400 bg-white text-gray16'
          : 'text-primary3'
      }`}
    >
      {pageNumber}
    </button>
  ));

  if (!totalPages || !itemsPerPage || !totalItems) {
    return null;
  }

  return (
    <div className={clsxMerge('mt-6 flex items-center justify-between', className)}>
      {/* <div>TODO fix pagination label "showing x out of x"</div> */}
      <div className="flex gap-1">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className={`p-2 text-sm font-bold ${
            currentPage === 1 ? 'text-gray-400' : 'text-primary3'
          }`}
        >
          &lt;
        </button>
        {startPage !== 1 && <span className="pt-0.5 text-primary3">&hellip;</span>}
        {renderPageNumbers}
        {endPage < totalPages && <span className="pt-0.5 text-primary3">&hellip;</span>}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`p-2 text-sm font-semibold ${
            currentPage === totalPages ? 'text-gray-400' : 'text-primary3'
          }`}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Pagination;
