import React from 'react';

import { AddToCart, CloudDownload, GoogleDocs, ShareIos, Trash } from 'iconoir-react';

type Props = {
  handleOrderOnline?: () => void;
  handleViewDocument?: () => void;
  handleDownloadDocument?: () => void;
  handleShareDocument?: () => void;
  handleDeleteDocument?: () => void;
};

export default function FileActions(props: Props) {
  const {
    handleOrderOnline,
    handleViewDocument,
    handleDeleteDocument,
    handleDownloadDocument,
    handleShareDocument,
  } = props;

  return (
    <div className="flex items-center gap-4">
      {handleOrderOnline && (
        <AddToCart className="cursor-pointer text-base text-primary5" onClick={handleOrderOnline} />
      )}
      {handleViewDocument && (
        <GoogleDocs
          className="cursor-pointer text-base text-primary5"
          onClick={handleViewDocument}
        />
      )}
      {handleDownloadDocument && (
        <CloudDownload
          className="cursor-pointer text-base text-primary5"
          onClick={handleDownloadDocument}
        />
      )}
      {handleShareDocument && (
        <ShareIos
          className="cursor-pointer text-base text-primary5"
          onClick={handleShareDocument}
        />
      )}
      {handleDeleteDocument && (
        <Trash className="cursor-pointer text-base text-red-600" onClick={handleDeleteDocument} />
      )}
    </div>
  );
}
