import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import { NavArrowDown as NavArrowDownIcon } from 'iconoir-react';

import { ProviderUserType } from '../types';
import { classNames } from '../utils/classNames';

type NavigationRoute = {
  name: string;
  path: string;
  className?: string;
  icon?: string;
};

type Props = {
  name: string;
  navigationRoutes?: NavigationRoute[];
  profilePictureUrl?: string;
  onSignOut?: () => void;
  userEmail: string;
  hasNotification?: boolean;
  showProfilePicture?: boolean;
  notificationBell?: React.ReactNode;
  children?: React.ReactNode;
  userType?: string;
  userTypeLabel?: string;
};

const UserNavProfile = (props: Props) => {
  const {
    name,
    userType,
    userTypeLabel,
    profilePictureUrl,
    navigationRoutes,
    hasNotification = true,
    showProfilePicture = true,
    notificationBell,
    onSignOut,
  } = props;

  const avatar = profilePictureUrl ? (
    <img
      className="h-7 w-7 rounded-full bg-gray-50 object-cover"
      src={profilePictureUrl}
      width={28}
      height={28}
      alt="user-profile"
    />
  ) : (
    <div className="flex items-center gap-2 rounded-full border border-neutral-300 p-1">
      <UserIcon className="h-6 w-6 text-neutral-500" />
    </div>
  );

  const renderAvatar = showProfilePicture && avatar;
  const isBrandAdmin = userType === ProviderUserType.BRAND_ADMIN;

  return (
    <div className="flex items-center gap-x-4" id="user-nav-profile">
      {props.children}
      {!isBrandAdmin && hasNotification && notificationBell && (
        <>
          {notificationBell}
          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />
        </>
      )}
      <Menu as="div" className="relative">
        <Menu.Button className="flex items-center gap-2.5 pr-4">
          <span className="sr-only">Open user menu</span>
          {renderAvatar}
          <div className="flex flex-col text-right">
            <span className="hidden text-sm font-medium text-gray-900 lg:block" aria-hidden="true">
              {name}
            </span>
            {userTypeLabel && (
              <span
                className="font-regular hidden text-sm text-gray-400 lg:block"
                aria-hidden="true"
              >
                {userTypeLabel}
              </span>
            )}
          </div>
          <NavArrowDownIcon className="hidden h-5 w-5 text-gray-400 md:block" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="shadow-lg  absolute right-0 z-50 mt-2.5 w-fit origin-top-right rounded-md bg-white py-2 ring-1 ring-gray-900/5 focus:outline-none">
            {navigationRoutes?.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <a
                    href={item.path}
                    className={classNames(
                      active ? 'bg-gray-50' : '',
                      `block   whitespace-nowrap  px-5 text-sm leading-6  text-gray-900 ${
                        item?.className ?? ''
                      }`,
                    )}
                  >
                    {item.icon && (
                      <div className="h-4 w-4">
                        <img alt={item.name} width={40} height={40} src={item.icon} />
                      </div>
                    )}
                    {item.name}
                  </a>
                )}
              </Menu.Item>
            ))}
            <Menu.Item key="name">
              <div className="block whitespace-nowrap px-5 py-2 text-sm font-medium leading-6 text-gray-900 hover:cursor-pointer hover:bg-gray-50 lg:hidden">
                {name}
              </div>
            </Menu.Item>
            <Menu.Item key="signout">
              <div
                className="block whitespace-nowrap px-5 py-2 text-sm leading-6 text-gray-900 hover:cursor-pointer hover:bg-gray-50 "
                onClick={onSignOut}
              >
                Sign out
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default UserNavProfile;
