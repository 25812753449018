import clsx from 'clsx';

import NextIcon from '../images/next.svg';
import PreviousIcon from '../images/previous.svg';
import { Pagination } from '../types';

type Props<T> = {
  isLoading?: boolean;
  paginatedData?: Pagination<T>;
  onNext: () => void;
  onPrevious: () => void;
};

export default function SimplePagination<T>(props: Props<T>) {
  const { isLoading, onNext, onPrevious, paginatedData } = props;

  const {
    currentPage,
    itemCount,
    items,
    itemsPerPage,
    nextPage,
    previousPage,
    totalItems,
    totalPages,
  } = paginatedData ?? {};

  const hasNextPage = nextPage !== null;
  const hasPreviousPage = previousPage !== null;

  return (
    <div className="mx-auto w-full max-w-7xl px-4 sm:px-6  lg:px-8">
      <div className="my-8 flex w-full items-center justify-between">
        <p className="text-sm font-light text-gray5">
          Showing <span className="font-bold">{itemCount}</span> of{' '}
          <span className="font-bold">{totalItems}</span> results
        </p>
        <div className="flex">
          <button
            onClick={onPrevious}
            disabled={!hasPreviousPage}
            className={clsx(
              'flex h-10 w-10 items-center justify-center rounded-l-md border border-neutral50 bg-white text-sm text-neutral-900',
              {
                'cursor-not-allowed': !hasPreviousPage,
                'hover:bg-lightPrimary': hasPreviousPage,
              },
            )}
          >
            <img alt="Previous" src={PreviousIcon} width={6} height={12} />
          </button>
          <button
            disabled
            className="flex h-10 w-10 cursor-not-allowed items-center justify-center border border-neutral50 bg-white text-sm text-neutral-900"
          >
            ...
          </button>
          <button
            onClick={onNext}
            disabled={!hasNextPage}
            className={clsx(
              'flex h-10 w-10 items-center justify-center rounded-r-md border border-neutral50 bg-white text-sm text-neutral-900',
              {
                'cursor-not-allowed': !hasNextPage,
                'hover:bg-lightPrimary': hasNextPage,
              },
            )}
          >
            <img alt="Next" src={NextIcon} width={6} height={12} />
          </button>
        </div>
      </div>
    </div>
  );
}
