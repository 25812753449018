import clsx from 'clsx';
import { Minus, Plus } from 'iconoir-react';

type Props = {
  className?: string;
  isForMedicineOrder?: boolean;
  disabled?: boolean;
  quantity: number;
  onAdd: () => void;
  onSubtract: () => void;
  onChange?: (quantity: number) => void;
};

const QuantityButton = (props: Props) => {
  const {
    className,
    isForMedicineOrder = false,
    disabled = false,
    quantity,
    onAdd,
    onSubtract,
    onChange,
  } = props;

  const handleAdd = () => {
    if (disabled) {
      return;
    }

    onAdd();
  };

  const handleSubtract = () => {
    if (quantity === 0 || disabled) {
      return;
    }

    onSubtract();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = Number(e.target.value);
    onChange?.(newQuantity);
  };

  return (
    <div
      className={clsx(
        'flex ',
        {
          'h-10 w-full items-center justify-around rounded-lg border border-healeeBlue bg-white px-2 py-2 sm:px-0':
            isForMedicineOrder,
        },
        className,
      )}
    >
      <div
        data-testid="subtract-button"
        className={
          isForMedicineOrder
            ? 'flex h-[19px] w-[19px] items-center justify-center border-0 bg-transparent text-healeeBlue hover:cursor-pointer hover:rounded-full hover:bg-healeeBlue hover:text-white'
            : 'flex h-[24px] w-[24px] items-center justify-center rounded-[4px] border border-neutral50 bg-transparent text-neutral500 hover:cursor-pointer hover:border-primary400 hover:bg-primary400 hover:text-white'
        }
        onClick={handleSubtract}
      >
        <Minus width={24} height={24} />
      </div>
      <input
        data-testid="quantity-input"
        disabled={disabled}
        type="number"
        inputMode="numeric"
        min={0}
        value={quantity}
        className={clsx(
          'mx-1 flex h-[24px] w-16 items-center border-none px-1 text-center text-sm [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
          {
            'h-8 w-12 rounded-sm bg-transparent px-4 py-[6px]': isForMedicineOrder,
          },
        )}
        onChange={handleOnChange}
      />
      <div
        data-testid="add-button"
        className={
          isForMedicineOrder
            ? 'flex h-[19px] w-[19px] items-center justify-center border-0 bg-transparent text-healeeBlue hover:cursor-pointer hover:rounded-full hover:bg-healeeBlue hover:text-white'
            : 'flex h-[24px] w-[24px] items-center justify-center rounded-[4px] border border-neutral50 bg-transparent text-neutral500 hover:cursor-pointer hover:border-primary400 hover:bg-primary400 hover:text-white'
        }
        onClick={handleAdd}
      >
        <Plus width={24} height={24} />
      </div>
    </div>
  );
};

export default QuantityButton;
