import {
  ProviderServiceAvailabilityEnum,
  UpdateProviderServiceDtoUpdateTypeEnum,
} from '@healthhub/api-lib';
import * as yup from 'yup';

import { FREE_DISCLAIMER_MAX_LENGTH, SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD } from '../constants';
import {
  ALLOWED_CHARS_IN_FRONT_END,
  ALPHANUMERIC_SPECIAL_PATTERN,
  ALPHANUMERIC_SPECIAL_PATTERN_WITH_NEWLINE,
} from '../constants/regex';
import { BookingPaymentRequirement, PriceType } from '../enums';

const allowedChars = '@:;\'"/%!?#$%^&()-=+.,';

const getDescriptionValidationSchema = (flag: boolean) => {
  return yup
    .string()
    .matches(
      flag ? ALLOWED_CHARS_IN_FRONT_END : ALPHANUMERIC_SPECIAL_PATTERN,
      flag
        ? 'Invalid characters detected'
        : `Invalid description format. Only letters, numbers, and the following characters are allowed: ${allowedChars}`,
    )
    .required('Service Description is required');
};

export const providerServiceSchema = yup.object().shape({
  availability: yup.string().required('Service Availability is required'),
  serviceCategoryId: yup.string().required('Service Category is required'),
  description: getDescriptionValidationSchema(SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD),
  label: yup
    .string()
    .matches(
      ALPHANUMERIC_SPECIAL_PATTERN,
      `Invalid name format. Only letters, numbers, and the following characters are allowed: ${allowedChars}`,
    )
    .required('Service Name is required'),
  procedurePreparation: yup
    .string()
    .matches(
      ALPHANUMERIC_SPECIAL_PATTERN_WITH_NEWLINE,
      `Invalid special instructions format. Only letters, numbers, and the following characters are allowed: ${allowedChars}`,
    )
    .nullable(),
  specialNotes: yup
    .string()
    .test({
      name: 'specialNotes',
      test: function (value) {
        if (value && value.trim().length > 0) {
          return this.parent.specialNotes.match(ALPHANUMERIC_SPECIAL_PATTERN_WITH_NEWLINE);
        }

        return true;
      },
      message: `Invalid special notes format. Only letters, numbers, and the following characters are allowed:  ${allowedChars}`,
    })
    .nullable(), // Allow null, undefined, and empty string,
  providerServiceBranchIds: yup
    .array()
    .test(
      'availability',
      'Please select at least one branch.',
      function (providerServiceBranchIds) {
        const availability = this.parent.availability;
        if (availability === ProviderServiceAvailabilityEnum.AvailableInSelectedBranches) {
          return (providerServiceBranchIds?.length || 0) > 0;
        } else {
          return true;
        }
      },
    ),
  providerServiceBranchesToOverride: yup
    .array()
    .test(
      'updateType',
      'Please select at least one branch.',
      function (providerServiceBranchesToOverride) {
        const { updateType, isEditing, isCurrentUserSuperAdmin } = this.parent;

        if (
          isCurrentUserSuperAdmin &&
          updateType === UpdateProviderServiceDtoUpdateTypeEnum.OverrideSelectedBranches &&
          isEditing
        ) {
          return (providerServiceBranchesToOverride?.length || 0) > 0;
        } else {
          return true;
        }
      },
    ),
});

export function dynamicProviderServiceSchema(priceType: PriceType) {
  if (priceType === PriceType.StartsAt) {
    return providerServiceSchema.shape({
      price: yup
        .number()
        .typeError(
          'Price should contain numbers only. Please remove any letters or special characters.',
        )
        .positive('Please enter a valid price')
        .required('Price is required'),
    });
  }

  if (priceType === PriceType.Fixed) {
    return providerServiceSchema.shape({
      price: yup
        .number()
        .typeError(
          'Price should contain numbers only. Please remove any letters or special characters.',
        )
        .positive('Please enter a valid price')
        .required('Price is required'),
      bookingPaymentRequirement: yup
        .mixed<BookingPaymentRequirement>()
        .oneOf(
          [...Object.values(BookingPaymentRequirement), undefined],
          'Invalid booking payment requirement',
        )
        .optional(),
      downPaymentAmount: yup.number().when('bookingPaymentRequirement', {
        is: (bookingPaymentRequirement: BookingPaymentRequirement | undefined) =>
          bookingPaymentRequirement === BookingPaymentRequirement.DOWNPAYMENT,
        then: (schema) =>
          schema
            .typeError('Down payment amount should contain numbers only.')
            .positive('Please enter a valid down payment amount')
            .required('Down payment amount is required when down payment is selected')
            .test(
              'less-than-price',
              'Down payment must be less than the service price',
              function (value) {
                return this.parent.price === undefined || value < this.parent.price;
              },
            ),
        otherwise: (schema) => schema.optional(),
      }),
    });
  }

  if (priceType === PriceType.Range) {
    return providerServiceSchema.shape({
      price: yup
        .number()
        .typeError('Minimum price should contain numbers only.')
        .positive('Please enter a valid minimum price')
        .required('Minimum Price is required'),
      maxPrice: yup
        .number()
        .typeError('Maximum price should contain numbers only.')
        .positive('Please enter a valid maximum price')
        .required('Maximum Price is required'),
    });
  }

  if (priceType === PriceType.Free) {
    return providerServiceSchema.shape({
      freePriceLabel: yup
        .string()
        .matches(ALPHANUMERIC_SPECIAL_PATTERN, {
          message: `Invalid format. Only letters, numbers, and the following characters are allowed: ${allowedChars}`,
        })
        .max(
          FREE_DISCLAIMER_MAX_LENGTH,
          'Kindly limit your disclaimer to fewer than 50 characters.',
        )
        .nullable(),
    });
  }

  return providerServiceSchema;
}
