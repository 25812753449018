import { UserProvider } from '@healthhub/api-lib';
import { PlusIcon } from '@heroicons/react/24/outline';

import {
  ProviderUserType,
  formatUsernameLastNameFirst,
  Table,
  TableEditButton,
  ProviderUserTypeLabels,
} from '@mwell-healthhub/commons';
import { TableColumn } from '@mwell-healthhub/commons/components/Table';
import Button from '@mwell-healthhub/components/Button';
import ProviderUserAccessToggle from './ProviderUserAccessToggle';

type Props = {
  onAddUser?: () => void;
  onEditUser?: (user: UserProvider) => void;
  isLoadingUsers: boolean;
  users?: UserProvider[];
  canGiveProviderUserAccess: boolean;
  canRemoveProviderUserAccess: boolean;
  canUpdateProviderUser: boolean;
  canCreateProviderUser: boolean;
};

function ProviderUserTable(props: Props) {
  const {
    users,
    isLoadingUsers,
    canGiveProviderUserAccess,
    canRemoveProviderUserAccess,
    canUpdateProviderUser,
    canCreateProviderUser,
    onAddUser,
    onEditUser,
  } = props;

  const handleActionEdit = (rowData: UserProvider) => () => {
    onEditUser?.(rowData);
  };

  function handleAddUser() {
    onAddUser?.();
  }

  const columns: TableColumn<UserProvider>[] = [
    {
      header: 'Name',
      key: 'name',
      className: 'w-[100px]',
      render: (data) => formatUsernameLastNameFirst(data),
    },
    {
      header: 'Email',
      key: 'email',
      className: 'w-[300px]',
    },
    {
      header: 'Designation',
      key: 'userType',
      className: 'w-[100px]',
      render: (data) => ProviderUserTypeLabels[data?.userType as unknown as ProviderUserType],
    },
    {
      header: 'Branch',
      key: 'assignedBranches',
      className: 'w-[100px]',
      render: (data) => data?.assignedBranches?.map((branch) => branch.branch).join(', ') || '-',
    },
    {
      header: 'User Access',
      key: 'userAccess',
      className: 'w-[50px]',
      render: (data) => <ProviderUserAccessToggle user={data} />,
      isColumnHidden: !canGiveProviderUserAccess && !canRemoveProviderUserAccess,
    },
    {
      header: 'Action',
      key: 'action',
      className: 'w-[60px]',
      render: (data) => !data?.deletedAt && <TableEditButton onClick={handleActionEdit(data)} />,
      isColumnHidden: !canUpdateProviderUser,
    },
  ];

  return (
    <>
      <div className="mb-6 mt-4 flex flex-row justify-between sm:flex sm:items-center">
        <div>
          <h1 className="text-4 text-2xl font-semibold">Users</h1>
        </div>
        {canCreateProviderUser && (
          <Button
            variant="primary"
            className="flex gap-2 px-[14px] py-[10px] font-normal"
            onClick={handleAddUser}
          >
            <PlusIcon height={18} width={18} className="mr-2" /> Add User
          </Button>
        )}
      </div>
      <Table
        data={users ?? []}
        columns={columns}
        isLoading={isLoadingUsers}
        noDataText="No users added. All your users will show up here"
      />
    </>
  );
}

export default ProviderUserTable;
