import { Provider, ProviderDirectoryApi } from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { GetManyProviderDirectoryParams } from './providerDirectoryService';
import { Pagination } from '../types';

export const providerDirectoryApi = HealthHubApiClient.use(ProviderDirectoryApi);

export async function getProvidersFromDirectory(params: GetManyProviderDirectoryParams) {
  try {
    const { data } = await providerDirectoryApi.providerDirectoryControllerFindManyForClient(
      params.searchQuery,
      params.providerTypeId,
      params.longitude,
      params.latitude,
      undefined,
      params.limit,
      params.page,
    );

    return data as Pagination<Provider>;
  } catch (err) {
    throw new Error('Failed to fetch client provider directories');
  }
}
