import clsx from 'clsx';

import Select from './Select/Select';
import { useGetAllRegions } from '../hooks';
import { sortByKey } from '../utils';

const DROPDOWN_HEIGHT = {
  sm: 'h-9',
  md: 'h-10',
  lg: 'h-12',
};

type Props = {
  value?: number;
  height?: 'sm' | 'md' | 'lg';
  dropdownClassName?: string;
  labelShown?: boolean;
  showRequiredOnLabel?: boolean;
  onChange: (value?: number) => void;
  disabled?: boolean;
};

const RegionDropdown = (props: Props) => {
  const {
    value,
    height = 'lg',
    labelShown = false,
    showRequiredOnLabel = false,
    onChange,
    disabled,
  } = props;

  const { data: regions = [], isLoading, isError } = useGetAllRegions();
  const mappedRegions = regions?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const sortedRegions = sortByKey(mappedRegions, 'label');

  const renderDropdown = (
    <Select
      placeholder="Select a region"
      value={value}
      className={clsx(
        'border-none ring-1 ring-neutral-300 focus:ring-inset focus:ring-primary-500',
        DROPDOWN_HEIGHT[height],
      )}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
        onChange(Number(event.target.value))
      }
      disabled={disabled}
    >
      {sortedRegions.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );

  if (!labelShown) {
    return renderDropdown;
  }

  return (
    <div className="flex flex-col">
      <span className="mb-1.5 block text-sm leading-6 text-gray-900">
        Region {showRequiredOnLabel && <span className="text-red-500">*</span>}
      </span>
      {renderDropdown}
    </div>
  );
};

export default RegionDropdown;
