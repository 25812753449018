import { setLocalStorageItem, setSessionStorageItem, useRouter } from '@mwell-healthhub/commons';

import { providerInitialState, providerSessionStorageKey } from '../contexts/provider';

const PROVIDER_PAGE = 'provider';

export const useClearProviderContext = () => {
  const { pathname, query } = useRouter();

  const isProviderPage = pathname.includes(PROVIDER_PAGE);
  const isToBeRedirectedToProvider = (query.redirect ?? '').includes(PROVIDER_PAGE);

  if (!isProviderPage && !isToBeRedirectedToProvider) {
    setSessionStorageItem(providerSessionStorageKey, providerInitialState);
    setLocalStorageItem(providerSessionStorageKey, providerInitialState);
  }
};
