import { CreateMerchantDto, UpdateMerchantDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createMerchant, updateMerchantByEmail } from '../../api';
import { Merchant } from '../../types';

export const useCreateMerchantMutation = (
  mutationOptions?: MutationOptions<Merchant, AxiosError<{ message: string }>, CreateMerchantDto>,
) => useMutation(createMerchant, mutationOptions);

export const useUpdateMerchantMutation = (
  mutationOptions?: MutationOptions<
    Merchant,
    AxiosError<{ message: string }>,
    { email: string; updateMerchantDto: UpdateMerchantDto }
  >,
) => {
  return useMutation(
    (params) => updateMerchantByEmail(params.email, params.updateMerchantDto),
    mutationOptions,
  );
};
