import { BannerApi, CreateBannerDto, UpdateBannerDto } from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';

export const bannerApi = HealthHubApiClient.use(BannerApi);

export type BannerUpdateParams = {
  id: number;
  updateBannerDto: UpdateBannerDto;
};

export async function fetchBanners() {
  try {
    const { data } = await bannerApi.bannerControllerFindAll();

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getBannerById(id: number) {
  try {
    const { data } = await bannerApi.bannerControllerFindOne(+id);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createBanner(createBanner: CreateBannerDto) {
  try {
    const { data } = await bannerApi.bannerControllerCreate(createBanner);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateBanner({ id, updateBannerDto }: BannerUpdateParams) {
  try {
    const { data } = await bannerApi.bannerControllerUpdate(id, updateBannerDto);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function deleteBanner(id: number) {
  try {
    await bannerApi.bannerControllerRemove(+id);
  } catch (error) {
    throw new CustomError(error);
  }
}
