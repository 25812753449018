import { useContext, useMemo, useState } from 'react';

import { Provider, ProviderServiceSettings } from '@healthhub/api-lib';

import {
  PaymentTypeEnum,
  SHOW_PAYMENT_QR_CODE,
  applicableReservationFee,
  getAppointmentPaymentDetails,
  getCanPayNow,
  getIsPayNowRequired,
} from '@mwell-healthhub/commons';

import { ProviderContext } from '../contexts/provider';

type Props = {
  provider: Provider;
  bookingFee: number;
  transactionFee: number;
};

const { PAY_NOW, PAY_LATER, PAY_NOW_FULL } = PaymentTypeEnum;

export function usePaymentDetails(props: Props) {
  const { provider, transactionFee, bookingFee } = props;
  const [paymentMethod, setPaymentMethod] = useState<PaymentTypeEnum | undefined>();
  const { state } = useContext(ProviderContext);

  const { clientProfileIds, services, hasServicesWithCustomSchedule } = state;
  const serviceSettings = state?.serviceSettings as ProviderServiceSettings;

  const hasProviderQr = !!provider.paymentQrCodeUrl;
  const isPayNowEnabled =
    hasProviderQr &&
    SHOW_PAYMENT_QR_CODE &&
    getCanPayNow(services) &&
    !hasServicesWithCustomSchedule;

  const isFullPaymentNow = useMemo(() => {
    const isPayNow = paymentMethod === PAY_NOW_FULL;
    const canPayNow = getCanPayNow(services);

    return isPayNow && canPayNow && isPayNowEnabled;
  }, [paymentMethod, services, isPayNowEnabled]);

  const reservationFee = useMemo(() => {
    return applicableReservationFee(serviceSettings, services) ?? 0;
  }, [serviceSettings, services]);

  const appointmentPaymentDetails = getAppointmentPaymentDetails({
    bookingFee,
    isFullPaymentNow,
    services,
    transactionFee,
    reservationFee,
    numberOfClients: clientProfileIds?.length,
    deductible: serviceSettings?.deductible ?? false,
    hasProviderQr,
  });

  const { totalAmount = 0, totalDownPayment = 0 } = appointmentPaymentDetails;

  const isPayNowRequired = useMemo(() => {
    const isPayNowRequired = getIsPayNowRequired(services, reservationFee) && isPayNowEnabled;

    if (isPayNowRequired) {
      setPaymentMethod(PAY_NOW);
    } else {
      setPaymentMethod(PAY_LATER);
    }

    return isPayNowRequired;
  }, [services, reservationFee, isPayNowEnabled]);

  const paymentOptionItems = useMemo(() => {
    let paymentOptionItems = [];

    const payNowOnlyOptions = [
      { value: PAY_NOW, label: 'Pay Now' },
      { value: PAY_NOW_FULL, label: 'Pay Now Full' },
    ];

    const payNowOptionalOptions = [
      { value: PAY_LATER, label: 'Pay Later' },
      { value: PAY_NOW_FULL, label: 'Pay Now' }, // label is intentional
    ];

    paymentOptionItems = isPayNowRequired ? payNowOnlyOptions : payNowOptionalOptions;

    const isDownPaymentEqualTotalAmount =
      totalDownPayment > 0 && totalDownPayment === totalAmount && !isFullPaymentNow;

    if (isDownPaymentEqualTotalAmount) {
      paymentOptionItems = [{ value: PAY_NOW, label: 'Pay Now' }];
    }

    return paymentOptionItems;
  }, [isPayNowRequired, totalDownPayment, totalAmount, isFullPaymentNow]);

  return {
    isFullPaymentNow,
    isPayNowRequired,
    isPayNowEnabled,
    paymentMethod,
    setPaymentMethod,
    paymentOptionItems,
    ...appointmentPaymentDetails,
  };
}
