export enum TagType {
  Merchant = 'MERCHANT',
  Product = 'PRODUCT',
}

export enum DisplayType {
  Grid = 'GRID',
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
}
