// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Appointment,
  Transaction,
  TransactionPaymentMethodEnum,
  TransactionPaymentOptionEnum,
  UserOperationUserTypeEnum,
  UserProviderUserTypeEnum,
} from '@healthhub/api-lib';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import {
  ActionModal,
  DATE_TIME_WITH_DOT,
  GET_ALL_PROVIDER_CLIENT_APPOINTMENTS,
  IDiscount,
  ISO_18601_FORMAT,
  InPersonPaymentStatus,
  PAYMENT_VIA,
  PaymentTypeEnum,
  SHOW_DISCOUNTS,
  SHOW_PROVIDER_SERVICE_SETTINGS,
  Variants,
  toast,
  useToggle,
  useUpdateInPersonPaymentMutation,
} from '@mwell-healthhub/commons';
import { getDiscountBadgeLabel } from '@mwell-healthhub/commons/utils/DiscountHelper';

import { Price } from '../../libs/price';
import {
  convertDateByFormat,
  getAppointmentPaymentDetails,
  getServiceStringedPrice,
} from '../../utils';
import Button from '../Button';
import ExternalPaymentStatusBadge from '../ExternalPaymentStatusBadge';
import UpdatePaymentDetailsModal, {
  AppointmentInPersonPaymentDetails,
  InPersonPaymentMethod,
} from '../UpdatePaymentDetailsModal';
import PreAppointmentPaymentDetails from './PreAppointmentPaymentDetails';

type Props = Readonly<{
  booking: Appointment;
  userType?: UserOperationUserTypeEnum | UserProviderUserTypeEnum;
}>;

export default function PaymentDetails({ booking, userType }: Props): JSX.Element {
  const queryClient = useQueryClient();
  const transaction = booking.transaction;
  const discounts: IDiscount[] | undefined = (booking.discounts as IDiscount[]) || [];
  const paymentMethodAndEWallet = transaction?.inPersonPaymentMethod?.split(': ');
  const paymentMethod = paymentMethodAndEWallet
    ? (paymentMethodAndEWallet[0] as InPersonPaymentMethod)
    : InPersonPaymentMethod.Cash;
  const eWallet =
    paymentMethodAndEWallet && paymentMethodAndEWallet.length > 1 ? paymentMethodAndEWallet[1] : '';
  const inPersonPaymentDetails: AppointmentInPersonPaymentDetails = {
    paymentDate: transaction?.inPersonPaymentDate
      ? new Date(transaction?.inPersonPaymentDate)
      : new Date(),
    transactionNo: transaction?.inPersonTransactionNo ?? '',
    paymentMethod,
    eWallet,
  };
  const externalPaymentDetails = transaction?.externalPaymentDetails as any;
  const { isOpen: isOpenMarkAsPaidModal, handleToggle: handleMarkAsPaidModal } = useToggle();
  const { isOpen: isOpenPaymentDetailsModal, handleToggle: handleOpenPaymentDetailsModal } =
    useToggle();

  const { mutateAsync: updateInPersonPayment, isLoading: isUpdatingInPersonPayment } =
    useUpdateInPersonPaymentMutation({
      onSuccess: () => {
        if (isOpenPaymentDetailsModal) {
          handleOpenPaymentDetailsModal();
        }
        queryClient.invalidateQueries([GET_ALL_PROVIDER_CLIENT_APPOINTMENTS]);
      },
      onError: () => {
        toast({ message: 'Failed to update payment details', type: 'error' });
      },
    });

  const {
    totalAmountDueNow,
    formattedBookingFee,
    formattedTotalAmountDueInPerson,
    formattedTotalAmountDue,
    formattedTransactionFee,
    formattedReservationFee,
    formattedSubtotalAmount,
    formattedTotalDownPayment,
    formattedTotalAmountDueNow,
    formattedSubtotalOriginalAmount,
  } = getAppointmentPaymentDetails({
    services: booking.appointmentServices,
    bookingFee: booking.bookingFee,
    transactionFee: 0,
    reservationFee: booking.reservationFee as number,
    deductible: booking.deductible as boolean,
    downPayment: booking.totalDownPayment,
    hasProviderQr: !!booking?.provider?.paymentQrCodeUrl,
  });

  const servicesList = booking.appointmentServices.map((appointmentService) => {
    const { id, providerService } = appointmentService;
    const stringedPrice = getServiceStringedPrice(appointmentService);
    const newPrice = new Price(stringedPrice, appointmentService.priceType);

    return (
      <div className="flex justify-between" key={id}>
        <span className="text-sm text-neutral-500 md:text-base">{providerService.label}</span>
        <span className="text-sm text-neutral-600 md:text-base">{newPrice.priceInPesos}</span>
      </div>
    );
  });

  const handleMarkAsPaid = () => {
    handleMarkAsPaidModal();
    handleUpdateInPersonPayment();
  };

  const handleUpdateInPersonPayment = (data?: AppointmentInPersonPaymentDetails) => {
    const paymentMethod =
      data?.paymentMethod === 'EWallet'
        ? `${data?.paymentMethod}: ${data?.eWallet}`
        : data?.paymentMethod;
    const updateData: { id: string; updateInPersonPaymentDto: any } = {
      id: booking.id.toString(),
      updateInPersonPaymentDto: data
        ? {
            paymentDate: data?.paymentDate ? format(data?.paymentDate, ISO_18601_FORMAT) : '',
            paymentMethod: paymentMethod ?? '',
            transactionNumber: data?.transactionNo ?? '',
          }
        : {},
    };
    updateInPersonPayment(updateData);
  };

  const renderInPersonPaymentStatus =
    transaction?.inPersonPaymentStatus === InPersonPaymentStatus.PAID ? (
      <ExternalPaymentStatusBadge paymentStatus="paid" />
    ) : (
      <ExternalPaymentStatusBadge paymentStatus="pending" />
    );

  const renderMarkAsPaidButton =
    userType && userType in UserProviderUserTypeEnum ? (
      <Button
        variant="plainNoOutline"
        className="h-auto p-0 text-sm font-medium underline"
        onClick={handleMarkAsPaidModal}
      >
        Mark as Paid
      </Button>
    ) : (
      renderInPersonPaymentStatus
    );

  const userProviderTypes = Object.values(UserProviderUserTypeEnum);
  const isProviderUser = userType && userProviderTypes.includes(userType as any);

  // this is to make it backwards compatible for appointments made before SHOW_PROVIDER_SERVICE_SETTINGS was flagged true
  const showNewPaymentBreakdown =
    [PaymentTypeEnum.PAY_NOW, PaymentTypeEnum.PAY_NOW_FULL].includes(
      transaction?.paymentMethod as PaymentTypeEnum,
    ) && SHOW_PROVIDER_SERVICE_SETTINGS;

  const preAppointmentPaymentDetails = {
    totalAmount: formattedTotalAmountDueNow,
    date: externalPaymentDetails?.paymentDetails?.paymentAt ?? transaction?.createdAt,
    status: externalPaymentDetails?.paymentStatus ?? transaction?.status,
    method: externalPaymentDetails?.paymentDetails?.maskedCardNumber ?? transaction?.paymentOption,
    receiptNumber: externalPaymentDetails?.receiptNumber,
    proofOfPaymentUrl: transaction?.proofOfPaymentUrl,
  };

  return (
    <>
      {isOpenMarkAsPaidModal && (
        <ActionModal
          onClickSecondaryButton={handleMarkAsPaidModal}
          onClickPrimaryButton={handleMarkAsPaid}
          title="Mark this appointment as Paid?"
          primaryButtonText="Proceed"
          description={
            <span className="text-sm text-neutral-500">
              Are you sure you want to mark this appointment as paid?
            </span>
          }
          variant={Variants.PRIMARY}
        ></ActionModal>
      )}
      {isOpenPaymentDetailsModal && (
        <UpdatePaymentDetailsModal
          isOpen={isOpenPaymentDetailsModal}
          onUpdate={handleUpdateInPersonPayment}
          toggle={handleOpenPaymentDetailsModal}
          isEditing={Boolean(transaction?.inPersonPaymentMethod)}
          isUpdating={isUpdatingInPersonPayment}
          inPersonPayment={inPersonPaymentDetails}
        />
      )}
      <div className="flex flex-col justify-between gap-6 md:flex-row lg:flex-col lg:gap-8 xl:flex-row">
        <div className="w-full rounded-md border border-neutral-300 bg-white p-8">
          <h3 className="text-base font-semibold md:text-lg">Payment Summary</h3>
          <div className="divide-y divide-neutral-300">
            <div className="space-y-3 pb-4 pt-6">{servicesList}</div>
            <div className="space-y-3 py-4">
              <div className="flex justify-between">
                <span className="text-sm font-semibold text-neutral-600 md:text-base">
                  Total Service Fee
                </span>
                <span className="text-sm font-medium text-neutral-600 md:text-base">
                  {formattedSubtotalOriginalAmount}
                </span>
              </div>
              {SHOW_DISCOUNTS && discounts?.length > 0 && (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-neutral-500">Discounts applied</p>
                  </div>
                  {discounts?.map((discount: IDiscount) => (
                    <div
                      key={discount?.id}
                      className="font-regular flex items-center justify-between text-sm text-green-700"
                    >
                      <span>
                        {getDiscountBadgeLabel(discount)} • {discount?.name}
                      </span>
                      <span>- {new Price(discount?.savedAmount || '0').priceInPesos}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="space-y-3 py-4">
              <div className="flex justify-between">
                <span className="text-sm font-semibold text-neutral-600 md:text-base">
                  Subtotal
                </span>
                <span className="text-sm font-medium text-neutral-600 md:text-base">
                  {formattedSubtotalAmount}
                </span>
              </div>
              {showNewPaymentBreakdown && (
                <div className="flex justify-between">
                  <span className="text-sm text-neutral-500 md:text-base">Reservation fee</span>
                  <span className="text-sm text-neutral-600 md:text-base">
                    {formattedReservationFee}
                  </span>
                </div>
              )}
              <div className="mb-2 flex justify-between">
                <span className="text-sm text-neutral-500 md:text-base">Booking fee</span>
                <span className="text-sm text-neutral-600 md:text-base">{formattedBookingFee}</span>
              </div>
            </div>
            <div className="space-y-3 pt-4">
              <div className="flex justify-between">
                <span className="text-sm font-semibold text-neutral-600 md:text-base">
                  Total amount due
                </span>
                <span className="text-sm font-medium text-neutral-600 md:text-base">
                  {formattedTotalAmountDue}
                </span>
              </div>
              {showNewPaymentBreakdown && (
                <>
                  <div className="flex justify-between">
                    <span className="text-sm text-neutral-500 md:text-base">Down payment</span>
                    <span className="text-sm text-neutral-600 md:text-base">
                      {formattedTotalDownPayment}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm font-semibold text-neutral-500 md:text-base">
                      Pre-appointment Payment
                    </span>
                    <span className="text-sm font-medium text-neutral-600 md:text-base">
                      {formattedTotalAmountDueNow}
                    </span>
                  </div>
                </>
              )}
              <div className="flex justify-between">
                <span className="text-sm font-semibold text-neutral-500 md:text-base">
                  Payable at Appointment
                </span>
                <span className="text-sm font-medium text-neutral-600 md:text-base">
                  {formattedTotalAmountDueInPerson}
                </span>
              </div>
            </div>
          </div>
        </div>
        {showNewPaymentBreakdown && (
          <div className="w-full rounded-md border border-neutral-300 bg-white p-8">
            <h3 className="text-base font-semibold md:text-lg">Payment Required</h3>
            <div className="divide-y divide-neutral-300">
              <div className="flex flex-col gap-y-4 py-6">
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-neutral-600 md:text-base">
                    Payable at Appointment
                  </span>
                  <span className="text-sm font-medium text-neutral-600 md:text-base">
                    {formattedTotalAmountDueInPerson}
                  </span>
                </div>
                <div className="flex flex-col gap-y-2 rounded-lg bg-neutral-100 px-4 py-3">
                  <div className="flex justify-between">
                    <span className="text-sm font-semibold">Payment Details</span>
                    {isProviderUser ? (
                      <Button
                        variant="plainNoOutline"
                        className="h-auto p-0 text-sm font-medium underline"
                        onClick={handleOpenPaymentDetailsModal}
                      >
                        {transaction?.inPersonPaymentMethod
                          ? 'Edit Payment Details'
                          : 'Add Payment Details'}
                      </Button>
                    ) : null}
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm">Payment Date</span>
                    <span className="text-sm">
                      {transaction?.inPersonPaymentDate
                        ? convertDateByFormat(transaction?.inPersonPaymentDate, DATE_TIME_WITH_DOT)
                        : '-'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm">Transaction No.</span>
                    <span className="text-sm">{transaction?.inPersonTransactionNo ?? '-'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm">Payment Method</span>
                    <span className="text-sm">{transaction?.inPersonPaymentMethod ?? '-'}</span>
                  </div>
                </div>
              </div>
              <PreAppointmentPaymentDetails {...preAppointmentPaymentDetails} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
