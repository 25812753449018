import AttachmentUploader from './AttachmentUploader';
import { PROOF_OF_PAYMENT_PATH } from '../constants/common';
import { FileTypeExtensionEnum } from '../constants/file';
import { getBlobName } from '../utils/file';

type Props = {
  proofOfPaymentUrl?: string;
  paymentQrCodeUrl?: string;
  onChangeProofOfPayment?: (proofOfPaymentUrl: string) => void;
};

const acceptedFileTypes = [
  FileTypeExtensionEnum.JPG,
  FileTypeExtensionEnum.JPEG,
  FileTypeExtensionEnum.PNG,
];

function QrCodePayment(props: Props) {
  const { proofOfPaymentUrl, paymentQrCodeUrl, onChangeProofOfPayment } = props;

  function handleChangeProofOfPayment(proofOfPaymentUrl: string) {
    onChangeProofOfPayment?.(proofOfPaymentUrl);
  }

  const handleClick = () => {
    window.open(paymentQrCodeUrl, '_blank');
  };

  return (
    <div className="flex flex-col gap-3 text-sm">
      <div className="flex flex-col">
        <p className="mb-4 text-base font-medium text-primary-500">How to pay:</p>
        <div className="flex flex-row items-center gap-2">
          <div className="inline-flex h-5 w-5 items-center justify-center rounded-[100px] bg-primary-300">
            <div className="font-semibold leading-none tracking-wide text-white">1</div>
          </div>
          <div className="font-medium">Scan QR code</div>
        </div>
        <div className="mb-2 ml-7 mt-1 text-neutral-500">
          Scan or upload the QR code below with your preferred bank or e-wallet app
        </div>
        <div className="relative ml-7 w-fit rounded-lg border border-neutral-300 p-[10px]">
          <img
            className="block h-full w-44 items-center justify-center rounded-lg object-contain object-center md:w-64"
            src={paymentQrCodeUrl}
            height="100%"
            width="100%"
            onClick={handleClick}
            alt="paymentQrCode"
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-2">
          <div className="inline-flex h-5 w-5 items-center justify-center rounded-[100px] bg-primary-300">
            <div className="font-semibold leading-none tracking-wide text-white">2</div>
          </div>
          <div className="font-medium">Upload proof of payment</div>
        </div>
        <div className="ml-8 mt-2">
          <AttachmentUploader
            acceptedFileTypes={acceptedFileTypes}
            placeholder="Click to upload File"
            label="Proof of Payment"
            filePath={PROOF_OF_PAYMENT_PATH + '/'}
            onChange={handleChangeProofOfPayment}
            value={getBlobName(proofOfPaymentUrl ?? '')}
            showLabel={false}
          />
        </div>
      </div>
    </div>
  );
}

export default QrCodePayment;
