import { ComponentType, Dispatch, createContext, useReducer } from 'react';

import { GetMwellUserHmoDto, User } from '@healthhub/api-lib';
import dynamic from 'next/dynamic';

import { checkIsMissingUserDetails } from '../utils';

const AddHmoOverview = dynamic(() => import('../components/molecules/hmo/AddHmoOverview'), {
  ssr: false,
});
const MyHmoOverview = dynamic(() => import('../components/molecules/hmo/MyHmoOverview'), {
  ssr: false,
});
const ViewHmoOverview = dynamic(() => import('../components/molecules/hmo/ViewHmoOverview'), {
  ssr: false,
});
const AllHmoOverview = dynamic(() => import('../components/molecules/hmo/AllHmo'), {
  ssr: false,
});

export const hmoComponentsMap = {
  addHmoPage: AddHmoOverview,
  myHmoPage: MyHmoOverview,
  viewHmoPage: ViewHmoOverview,
  allHmo: AllHmoOverview,
};

type FormState = {
  user: User | undefined;
  isEmptyList: boolean;
  isDisabledSaveHMO: boolean;
  addHmoFormFields: AddHmoFormFieldsType;
  isProfileNeedUpdate: boolean;
  isInvalidHmoPolicy: boolean;
  showDeleteHmoModal: boolean;
  HmoPageToRender: ComponentType<any>;
  selectedHmo: GetMwellUserHmoDto | undefined;
  searchQuery: string;
};

export enum AddHmoFormEnum {
  HMO_ID = 'hmoId',
  CARD_NUMBER = 'cardNumber',
  NICKNAME = 'nickname',
  IS_HMO_HOLDER = 'isHmoHolder',
  BIRTHDATE = 'birthdate',
  HMO_HOLDER_FIRST_NAME = 'hmoHolderFirstName',
  HMO_HOLDER_LAST_NAME = 'hmoHolderLastName',
}

export type AddHmoFormFieldsType = {
  [AddHmoFormEnum.HMO_ID]: string;
  [AddHmoFormEnum.CARD_NUMBER]: string;
};

export const initialAddHmoFormFieldState: AddHmoFormFieldsType = {
  [AddHmoFormEnum.HMO_ID]: '',
  [AddHmoFormEnum.CARD_NUMBER]: '',
};

const initialState: FormState = {
  user: undefined,
  isEmptyList: true,
  isDisabledSaveHMO: true,
  addHmoFormFields: initialAddHmoFormFieldState,
  isProfileNeedUpdate: false,
  isInvalidHmoPolicy: false,
  showDeleteHmoModal: false,
  HmoPageToRender: hmoComponentsMap['myHmoPage'],
  selectedHmo: undefined,
  searchQuery: '',
};

type HMOAction = {
  type: string;
  payload?: any;
};

type ContextProps = {
  state: FormState;
  dispatch: Dispatch<HMOAction>;
};

export enum HmoActionEnum {
  OPEN_MODAL_INVALID_HMO_POLICY = 'OPEN_MODAL_INVALID_HMO_POLICY',
  CLOSE_MODAL_INVALID_HMO_POLICY = 'CLOSE_MODAL_INVALID_HMO_POLICY',
  ENABLE_SAVE_HMO_BUTTON = 'ENABLE_SAVE_HMO_BUTTON',
  DISABLE_SAVE_HMO_BUTTON = 'DISABLE_SAVE_HMO_BUTTON',
  SHOW_DELETE_HMO_MODAL = 'SHOW_DELETE_HMO_MODAL',
  CLOSE_DELETE_HMO_MODAL = 'CLOSE_DELETE_HMO_MODAL',
  SET_USER = 'SET_USER',
  LOAD_STATE = 'LOAD_STATE',
  SET_PAGE_TO_RENDER = 'SET_PAGE_TO_RENDER',
  UPDATE_STATE = 'UPDATE_STATE',
  SET_VIEW_HMO = 'SET_VIEW_HMO',
  SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
  RESET_TO_INITIAL_HMO_PAGE = 'RESET_TO_INITIAL_HMO_PAGE',
}

const reducerFunction = (state: FormState, action: HMOAction): FormState => {
  switch (action.type) {
    case HmoActionEnum.UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case HmoActionEnum.OPEN_MODAL_INVALID_HMO_POLICY:
      return {
        ...state,
        isInvalidHmoPolicy: true,
      };
    case HmoActionEnum.CLOSE_MODAL_INVALID_HMO_POLICY:
      return {
        ...state,
        isInvalidHmoPolicy: false,
      };
    case HmoActionEnum.ENABLE_SAVE_HMO_BUTTON:
      return {
        ...state,
        isDisabledSaveHMO: false,
      };
    case HmoActionEnum.DISABLE_SAVE_HMO_BUTTON:
      return {
        ...state,
        isDisabledSaveHMO: true,
      };
    case HmoActionEnum.SHOW_DELETE_HMO_MODAL:
      return {
        ...state,
        showDeleteHmoModal: true,
      };
    case HmoActionEnum.CLOSE_DELETE_HMO_MODAL:
      return {
        ...state,
        showDeleteHmoModal: false,
      };
    case HmoActionEnum.SET_USER: {
      const user: User = action.payload;

      return {
        ...state,
        isProfileNeedUpdate: user && checkIsMissingUserDetails(user),
        user,
      };
    }

    case HmoActionEnum.RESET_TO_INITIAL_HMO_PAGE: {
      return {
        ...state,
        HmoPageToRender: hmoComponentsMap['myHmoPage'],
        isInvalidHmoPolicy: false,
      };
    }

    case HmoActionEnum.SET_PAGE_TO_RENDER: {
      return {
        ...state,
        HmoPageToRender: action.payload,
      };
    }

    case HmoActionEnum.SET_VIEW_HMO: {
      return {
        ...state,
        selectedHmo: action.payload,
      };
    }

    case HmoActionEnum.SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      };
    }

    default:
      return state;
  }
};

export const hmoSessionStorageKey = 'hmoContextState';

export const HmoContext = createContext<ContextProps | undefined>(undefined);

export const HmoContextProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducerFunction, initialState);

  const value = { state, dispatch };

  return <HmoContext.Provider value={value}>{children}</HmoContext.Provider>;
};
