import { useEffect, useState } from 'react';

import { Appointment, ProviderClientNotes, ProviderClientProfile } from '@healthhub/api-lib';
import isEmpty from 'lodash.isempty';
import Link from 'next/link';
import { DateObject } from 'react-multi-date-picker';

import {
  clsxMerge,
  convertDateByFormat,
  LoadingSpinner,
  FieldTextHtmlFormatter,
  TableEditButton as EditButton,
  useFindProviderClientNotes,
  useRouter,
} from '@mwell-healthhub/commons';

type Props = {
  client?: ProviderClientProfile;
  appointment?: Appointment;
  isAppointmentNotes?: boolean;
};

const ReferralClientNotesList = ({ client, appointment, isAppointmentNotes = false }: Props) => {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateObject[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const {
    data: providerClientNotes,
    refetch,
    isLoading: isFetching,
  } = useFindProviderClientNotes({
    startDate,
    endDate,
    query: searchQuery,
    providerClientProfileId: client?.id,
    appointmentId: appointment?.id,
    limit: 200, // To Be Updated
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const circleSvg = (
    <div className="z-10 bg-neutral-50">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="12" r="5" fill="#FAFAFA" stroke="#EBECEE" strokeWidth="2" />
      </svg>
    </div>
  );

  const renderProviderClientNotes = providerClientNotes?.items?.map((providerClientNote, index) => {
    return (
      <div key={`provider-client-notes-${index}`} className="flex gap-x-4 py-4">
        <div className="flex min-w-[104px] max-w-[104px] basis-[104px] flex-col">
          <span className="text-md font-normal text-neutral-500">
            {convertDateByFormat(providerClientNote.createdAt, 'MMM dd, yyyy')}
          </span>
          {providerClientNote.userProvider && (
            <span className="mt-4 text-xs font-normal text-neutral-400">
              Added by {providerClientNote.userProvider?.firstName}{' '}
              {providerClientNote.userProvider?.lastName}
            </span>
          )}
        </div>
        <div className="min-w-6 max-w-6 relative -mb-4 flex w-6 basis-6 flex-col items-center">
          {circleSvg}
          <div
            className={clsxMerge('absolute -top-4 bottom-0 left-1/2 -z-0 w-px bg-neutral-300', {
              'top-4': index === 0,
              'h-4': index + 1 === providerClientNotes.itemCount,
              hidden: providerClientNotes.itemCount === 1,
            })}
          ></div>
        </div>
        <div className="shrink grow">
          <div className="mb-2 flex flex-row justify-between">
            <span
              className={clsxMerge('text-md cursor-pointer font-semibold text-primary-500', {
                'pointer-events-none text-neutral-600': !providerClientNote.userProvider,
              })}
              onClick={() => {}}
            >
              {providerClientNote.title}
            </span>
            <EditButton showIcon={false} label="View note" onClick={() => {}} />
          </div>
          <div className="text-sm font-normal text-neutral-600">
            <FieldTextHtmlFormatter
              key={`provider-client-notes-remarks-${index}`}
              remarks={providerClientNote.remarks}
            />
          </div>
        </div>
      </div>
    );
  });

  const renderEmptyState = (
    <div className="flex items-center justify-center">
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        isEmpty(providerClientNotes?.items) && (
          <span className="text-center text-sm font-medium text-graniteGray">
            No notes for this client.
          </span>
        )
      )}
    </div>
  );

  return (
    <>
      <div className="mt-4 w-full rounded-lg border border-neutral-300 bg-neutral-50">
        <div className="flex flex-row flex-nowrap items-center justify-between border-b border-neutral-300 px-5 py-6">
          <span className="text-lg font-semibold">Notes History</span>
        </div>
        <div className="px-5 py-[12px]">
          {isFetching || (isEmpty(providerClientNotes?.items) && renderEmptyState)}
          {renderProviderClientNotes}
        </div>
      </div>
    </>
  );
};

export default ReferralClientNotesList;
