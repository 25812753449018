// Workaround since JWT library does not work in middleware
export function decodeJWT(token: string) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = Buffer.from(base64, 'base64').toString('utf-8');
    const decodedData = JSON.parse(decodedPayload);

    return decodedData;
  } catch (error) {
    console.log('error', error);

    return null;
  }
}
