import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';

import Button from './Button';
import toast from './toast';
import { useDebounce } from '../hooks/useDebounce';
import { MultiSelectOptionType } from '../types';
import { clsxMerge } from '../utils';

type Props = {
  className?: string;
  isLinkType?: boolean;
  disabledText?: string;
  dropdownClassName?: string;
  caretClassName?: string;
  categoryHeaderClassName?: string;
  isCategoryHeaderSelectable?: boolean;
  isHideOptions?: boolean;
  label?: string;
  options: MultiSelectOptionType[];
  placeholder?: string;
  placeHolderClassName?: string;
  selectedOptions?: MultiSelectOptionType[];
  selectInnerClassName?: string;
  showRequiredOnLabel?: boolean;
  showSelectedCount?: boolean;
  clearLabel?: string;
  maxSelections?: number;
  minSelections?: number;
  onRemove: (updatedSelections: MultiSelectOptionType[]) => void;
  onSelect: (updatedSelections: MultiSelectOptionType[]) => void;
  onChangeText?: (text: string) => void;
  onSubmit?: () => void;
  onClearAll?: () => void;
  isSingleSelect?: boolean; // temporary until we have a component like this but single select
  pillVariant?: 'light' | 'gray';
  disabled?: boolean;
  errorMessage?: string;
  optionsContainerClassname?: string;
};

const MultiSelectDropdown = (props: Props) => {
  const {
    selectedOptions = [],
    maxSelections,
    minSelections,
    isSingleSelect = false,
    isLinkType,
    selectInnerClassName,
    disabledText = '',
    categoryHeaderClassName,
    isCategoryHeaderSelectable = false,
    isHideOptions = false,
    dropdownClassName,
    caretClassName,
    onRemove,
    onSelect,
    options = [],
    placeholder = 'Select an option',
    placeHolderClassName = '',
    onChangeText,
    className,
    label,
    showRequiredOnLabel,
    showSelectedCount,
    clearLabel = 'Clear',
    onSubmit,
    onClearAll,
    pillVariant = 'light',
    disabled = false,
    errorMessage,
    optionsContainerClassname = '',
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOptionClick = (option: MultiSelectOptionType) => {
    const isAlreadySelected = selectedOptions.some((selected) => selected.value === option.value);
    let updatedOptions: MultiSelectOptionType[];
    const isMinSelectionLimitReached =
      minSelections !== undefined && selectedOptions.length <= minSelections;
    const isMaxSelectionLimitReached =
      maxSelections !== undefined && selectedOptions.length >= maxSelections;

    if (isAlreadySelected) {
      if (isMinSelectionLimitReached) {
        toast({ message: `You must select at least ${minSelections} options`, type: 'error' });
        return;
      } else {
        updatedOptions = selectedOptions.filter((selected) => selected.value !== option.value);
      }
    } else if (isSingleSelect) {
      updatedOptions = [option];
      setIsOpen(false);
    } else if (isMaxSelectionLimitReached) {
      toast({ message: `You can only select up to ${maxSelections} options`, type: 'error' });
      return;
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    onSelect(updatedOptions);
    setSearchText('');
  };

  const handleRemoveOption = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: MultiSelectOptionType,
  ) => {
    event.stopPropagation();
    onRemove(selectedOptions.filter((selectedOption) => selectedOption.value !== option.value));
  };

  const search = (query: string) => {
    onChangeText?.(query);
  };

  const debouncedSearch = useDebounce(search, 300);

  const handleOnChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
    setSearchText(event.target.value);
  };

  const filteredOptions = options?.filter(
    (option) => option.text?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const groupOptionsByOptGroup = (
    options: MultiSelectOptionType[],
  ): { [key: string]: MultiSelectOptionType[] } => {
    const groupedOptions: { [key: string]: MultiSelectOptionType[] } = {};
    // Group the options by their optGroup property if it exists
    options.forEach((option) => {
      const optGroup = option.optGroup || 'default';
      if (!groupedOptions[optGroup]) {
        groupedOptions[optGroup] = [];
      }
      groupedOptions[optGroup].push(option);
    });
    return groupedOptions;
  };

  const groupedOptions = groupOptionsByOptGroup(filteredOptions);

  const selectableOptionClassName =
    'flex cursor-pointer items-center space-x-2 rounded-lg px-4 py-2.5 text-sm hover:bg-neutral-200';

  function getOptionClassName(option: MultiSelectOptionType) {
    return clsxMerge(selectableOptionClassName, option.isDisabled && 'cursor-not-allowed');
  }

  function getOptionContent(option: MultiSelectOptionType, disabledText: string) {
    if (option?.rightContent) {
      return (
        <div className="flex grow items-center">
          <div className={clsxMerge('grow', option.isDisabled && `text-gray-400`)}>
            {option?.text}
            {option.isDisabled && <small className="ml-1.5">{disabledText}</small>}
          </div>
          <div>{option?.rightContent}</div>
        </div>
      );
    } else {
      return (
        <div className={clsxMerge('grow', option.isDisabled && `text-gray-400`)}>
          {option.text}
          {option.isDisabled && <small className="ml-1.5">{disabledText}</small>}
        </div>
      );
    }
  }

  function handleCategoryClick(options: MultiSelectOptionType[], isSelectAll: boolean) {
    let updatedOptions: MultiSelectOptionType[];
    if (isSelectAll) {
      updatedOptions = [...options, ...selectedOptions];
    } else {
      updatedOptions = updatedOptions = selectedOptions.filter(
        (selectedOption) => !options.some((option) => option.value === selectedOption.value),
      );
    }
    onSelect(updatedOptions);
  }

  const renderFilteredOptions = Object.keys(groupedOptions).map((category) => {
    if (category !== 'default') {
      const isAllOptionsSelected = groupedOptions[category].every((option) =>
        selectedOptions.map((selectedOption) => selectedOption.value).includes(option.value),
      );
      const handleSelectCategory = isCategoryHeaderSelectable
        ? () => handleCategoryClick(groupedOptions[category], !isAllOptionsSelected)
        : undefined;
      return (
        <div key={category}>
          <button
            type="button"
            disabled={!isCategoryHeaderSelectable}
            className={clsxMerge(
              'flex w-full items-center justify-between px-4 py-2.5 text-left text-xs font-semibold leading-none tracking-tight text-gray-400',
              {
                ['text-sm text-neutral-600 ' + selectableOptionClassName]:
                  isCategoryHeaderSelectable,
              },
              categoryHeaderClassName,
            )}
            onClick={handleSelectCategory}
          >
            {category}
            {isAllOptionsSelected && isCategoryHeaderSelectable && (
              <CheckIcon
                className={clsxMerge('collapse h-5 w-5 text-green-700', {
                  visible: isAllOptionsSelected,
                })}
              />
            )}
          </button>
          {!isHideOptions && groupedOptions[category].map((option) => renderOption(option))}
          <hr className="my-2 text-neutral-300" />
        </div>
      );
    } else {
      return groupedOptions[category].map((option) => renderOption(option));
    }
  });

  function renderOption(option: MultiSelectOptionType) {
    const isSelected = selectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    const handleSelect = option.isDisabled ? undefined : () => handleOptionClick(option);

    return (
      <div key={option.value} className={getOptionClassName(option)} onClick={handleSelect}>
        {getOptionContent(option, disabledText)}
        {isSelected && (
          <CheckIcon
            className={clsxMerge('collapse h-5 w-5 text-green-700', { visible: isSelected })}
          />
        )}
      </div>
    );
  }

  return (
    <div className={clsxMerge('relative h-full w-full', className)} ref={dropdownRef}>
      {label && (
        <label className="font-regular neutral-600 block text-sm leading-6">
          {label}
          {showRequiredOnLabel && <span className="text-red-600"> *</span>}
        </label>
      )}
      <div
        className={clsxMerge(
          'relative inline-flex h-auto min-h-[40px] w-full items-center justify-between rounded-md border-none bg-white px-3 py-2 text-sm text-neutral-600 ring-1 ring-neutral-300',
          {
            'ring-cyan-600': isOpen || (showSelectedCount && selectedOptions.length > 0),
            'min-h-0 ring-0': isLinkType,
            'pointer-events-none': disabled,
          },
          selectInnerClassName,
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {(selectedOptions.length === 0 || isLinkType) && (
          <span
            className={clsxMerge(
              'text-neutral-400',
              placeHolderClassName,
              isLinkType && 'cursor-pointer text-primary3 underline underline-offset-4 ring-0',
            )}
          >
            {placeholder}
          </span>
        )}
        <div className="flex flex-grow flex-wrap gap-1">
          {selectedOptions.length > 0 && showSelectedCount ? (
            <span
              className={clsxMerge(
                'text-neutral-400',
                selectedOptions.length > 0 ? 'text-cyan-600' : '',
                isLinkType && 'cursor-pointer text-primary3 underline underline-offset-4 ring-0',
              )}
            >{`${placeholder} (${selectedOptions.length})`}</span>
          ) : (
            !isLinkType &&
            selectedOptions.map((option) => (
              <div
                key={option.value}
                className={clsxMerge(
                  'box-border flex w-fit flex-row items-center gap-x-1 rounded-full border border-solid border-neutral-200 px-3 py-1.5 text-sm leading-[14px]',
                  {
                    'border-neutral-200 bg-neutral-200': pillVariant === 'gray',
                    'text-gray-400': option.isDisabled,
                    'border-none px-0': isSingleSelect,
                    'bg-gray-100': disabled,
                  },
                )}
              >
                <span>{option.selectedDisplayText || option.text}</span>
                {!isSingleSelect && (
                  <button
                    disabled={disabled}
                    onClick={(event) => handleRemoveOption(event, option)}
                  >
                    <XMarkIcon className="h-[18px] w-[18px] text-neutral-400" />
                  </button>
                )}
              </div>
            ))
          )}
        </div>
        <div
          className={clsxMerge(
            'absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2',
            (showSelectedCount && isOpen) || (showSelectedCount && selectedOptions.length > 0)
              ? 'rotate-180'
              : '',
            isLinkType && 'hidden',
            caretClassName,
          )}
        >
          <ChevronDownIcon
            className={clsxMerge(
              'text-neutral-400',
              showSelectedCount && (isOpen || selectedOptions.length > 0) && 'text-[#00B5D8]',
            )}
          />
        </div>
      </div>

      {isOpen && (
        <div
          className={clsxMerge(
            'absolute z-10 mt-2 w-full min-w-[276px] rounded-md border border-solid border-neutral-300 bg-white px-2 pb-1 pt-2 shadow-dropdown',
            dropdownClassName,
          )}
        >
          <input
            disabled={disabled}
            type="text"
            className="sticky top-0 mb-2 w-full rounded-lg border border-solid border-neutral-300 px-3 py-2 text-sm focus:border-primary-500"
            placeholder="Search..."
            value={searchText}
            onChange={handleOnChangeText}
          />
          <div className={clsxMerge('max-h-60 overflow-y-auto', optionsContainerClassname)}>
            {renderFilteredOptions}
          </div>
          {!isSingleSelect && (
            <div className="flex items-center justify-end space-x-2 p-2">
              {!minSelections && (
                <Button
                  disabled={disabled}
                  onClick={() => {
                    onClearAll?.();

                    if (onClearAll) {
                      setIsOpen(false);
                    }

                    onRemove([]);
                  }}
                  variant="label"
                >
                  {clearLabel}
                </Button>
              )}
              <Button
                disabled={disabled}
                onClick={() => {
                  onSubmit?.();
                  setIsOpen(false);
                }}
                variant="primary"
              >
                Done
              </Button>
            </div>
          )}
        </div>
      )}
      {errorMessage && <p className="mt-1 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};

export default MultiSelectDropdown;
