import { FormEventHandler } from 'react';

import { UseFormRegister } from 'react-hook-form';

import Button from './Button';
import ForgotPasswordLink from './ForgotPasswordLink';
import MaskedTextInput from './MaskedTextInput/MaskedTextInput';
import TextInput from './TextInput/TextInput';

type LoginData = {
  email: string;
  password: string;
};

export interface AuthFormProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<LoginData>;
}

function AuthForm(props: AuthFormProps) {
  const { register, onSubmit } = props;

  return (
    <form method="POST" onSubmit={onSubmit}>
      <fieldset className="contents">
        <legend className="sr-only">Login Form</legend>
        <div className="mb-[40px] flex flex-col gap-4 md:gap-4">
          <TextInput
            {...register('email', { required: true })}
            type="text"
            id="email"
            placeholder="Enter email address"
            label="Email address"
            autoComplete="username"
            labelShown={true}
            labelSize="md"
            labelFontWeight="md"
            labelClassName="mb-1.5"
          />
          <div>
            <MaskedTextInput
              {...register('password', { required: true })}
              placeholder="Enter password"
              autoComplete="current-password"
              label="Password"
              labelShown={true}
              labelSize="md"
              labelFontWeight="md"
              id="trebuchet"
            />
            <div className="mt-4 hidden text-right">
              <ForgotPasswordLink textFontWeight="sm" />
            </div>
          </div>
        </div>
        <div>
          <div className="mt-7 flex gap-4">
            <div className="w-full">
              <Button type="submit" variant="primary" size="lg" className="w-full font-medium">
                Sign in
              </Button>

              <div className="mx-auto mt-5 max-w-sm text-center text-sm leading-6 text-neutral-500">
                By proceeding, you agree with{' '}
                <a
                  className="font-semibold text-primary-500"
                  href="https://www.mwell.com.ph/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  mWell Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  className="font-semibold text-primary-500"
                  href="https://www.mwell.com.ph/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  mWell Privacy Policy
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default AuthForm;
