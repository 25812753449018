import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';

import { ImageExtension } from './ImageExtension';
import { downloadFileByUrl, getBlobName, truncateMiddle } from '../../utils';
import Button from '../Button';

type Props = {
  imageSrc: string;
  title: string;
};

export default function AttachmentPreview(props: Readonly<Props>) {
  const { imageSrc, title } = props;
  const fileName = getBlobName(imageSrc);

  function handleDownload() {
    const newUrl = `${window.location.origin}/api/download?url=${encodeURIComponent(imageSrc)}`;

    downloadFileByUrl(newUrl, fileName);
  }

  return (
    <div className="w-full">
      <div className="mb-8 flex w-full">
        <div className="flex-1">
          <h2 className="text-2xl font-semibold">{title}</h2>
          <p className="line-clamp-1 text-sm text-neutral-500">
            {truncateMiddle(fileName, 40)}
            <ImageExtension fileUrl={imageSrc} />
          </p>
        </div>
        <Button
          data-testid="add-button"
          variant="primary"
          onClick={handleDownload}
          icon={ArrowDownTrayIcon}
        >
          Download Document
        </Button>
      </div>

      <div className="w-full rounded-md border border-neutral-300 bg-white p-4 md:p-8">
        <img alt="Attachment Preview" className="mx-auto max-w-full text-center" src={imageSrc} />
      </div>
    </div>
  );
}
