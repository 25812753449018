import { forwardRef, HTMLProps, ReactNode, Ref, useId } from 'react';

import { clsxMerge } from '../../utils';

export interface RadioProps
  extends Omit<HTMLProps<HTMLInputElement>, 'type' | 'label' | 'defaultValue'> {
  isChecked?: boolean;
  label?: ReactNode;
  labelClassName?: string;
}

function Radio(props: RadioProps, forwardedRef?: Ref<HTMLInputElement>) {
  const {
    label,
    isChecked = false,
    id: idProp,
    defaultValue: _,
    className,
    labelClassName,
    disabled,
    ...etcProps
  } = props as RadioProps & { defaultValue: unknown };
  const defaultId = useId();
  const id = idProp ?? defaultId;

  const disabledLabel = disabled ? 'text-gray-500 opacity-50' : 'text-gray-900';

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          {...etcProps}
          id={id}
          ref={forwardedRef}
          type="radio"
          disabled={disabled}
          checked={isChecked}
          className={clsxMerge(
            'h-4 w-4 rounded-full border-gray-300 text-primary-500 focus:ring-primary-500',
            className,
          )}
        />
      </div>
      {label && (
        <div className="ml-3 text-sm leading-6">
          <label
            htmlFor={id}
            className={clsxMerge(`font-normal text-neutral-900 ${disabledLabel}`, labelClassName)}
          >
            {label}
          </label>
        </div>
      )}
    </div>
  );
}

export default forwardRef(Radio);
