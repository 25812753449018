import { useState } from 'react';

import {
  Appointment,
  AppointmentApprovalStatusEnum,
  AppointmentStatusEnum,
  CreateMessageThreadDto,
  GetMessageThreadByMemberIdsDto,
} from '@healthhub/api-lib';
import {
  ArrowPathIcon,
  CheckCircleIcon,
  CheckIcon,
  TrashIcon,
  XMarkIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline';
import { RemoveSquare, TransitionRight, ChatBubble } from 'iconoir-react';

import CancelModal from './CancelModal';
import DeclineModal from './DeclineModal';
import { LOGIN_TO_SEND_MESSAGE, SHOW_MESSAGE_THREADS } from '../../constants';
import { ProviderOnboardingStepEnum, Routes } from '../../enums';
import {
  useApproveAppointmentMutation,
  useCancelAppointmentMutation,
  useCompleteAppointmentMutation,
  useCreateMessageThreadMutation,
  useDeclineAppointmentMutation,
  useGetMessageThreadByMemberIdsMutation,
  useNoShowAppointmentMutation,
  useOngoingAppointmentMutation,
  useRouter,
  useToggle,
} from '../../hooks';
import useIsMobile from '../../hooks/useIsMobile';
import { MessageThread, MessageThreadMemberTypeEnum } from '../../types';
import { formatMessageThreadMemberId } from '../../utils';
import { trackEvent, EventName } from '../../utils/analytics';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import toast from '../toast';

type Props = {
  booking: Appointment;
  bookingFollowUpUrl?: string;
  refetch?: () => void;
  userCanViewMessageThreads?: boolean;
  isOnboarding?: boolean;
  handleFinishOnboardingProgressionActionStep?: (step: ProviderOnboardingStepEnum) => void;
};

const BookingStatusButton = (props: Props) => {
  const {
    booking,
    bookingFollowUpUrl,
    refetch,
    userCanViewMessageThreads,
    handleFinishOnboardingProgressionActionStep,
    isOnboarding,
  } = props;

  const provider = booking.provider;

  const isMphProvider = provider?.isBranch
    ? provider?.parentProvider?.isMphProvider
    : provider?.isMphProvider;

  const patientId = booking.providerClientProfile?.clientProfile?.user?.mWellUserId;
  const canChat = !!patientId && !!booking.provider;
  const [isMessageButtonLoading, setIsMessageButtonLoading] = useState(false);

  const router = useRouter();
  const isMobile = useIsMobile();
  const { mutateAsync: ongoingAppointment } = useOngoingAppointmentMutation();
  const { mutateAsync: noShowAppointment } = useNoShowAppointmentMutation();
  const { isOpen: isOpenCancelModal, handleToggle: handleCancelModalToggle } = useToggle();
  const { isOpen: isOpenConfirmArriveModal, handleToggle: handleConfirmArriveModalToggle } =
    useToggle();
  const { isOpen: isOpenConfirmNoShowModal, handleToggle: handleConfirmNoShowModalToggle } =
    useToggle();
  const {
    isOpen: isOpenDeclineAppointmentModal,
    handleToggle: handleDeclineAppointmentModalToggle,
  } = useToggle();
  const { mutateAsync: cancelAppointment, isLoading: isLoadingCancelAppointment } =
    useCancelAppointmentMutation();
  const { mutateAsync: completeAppointment, isLoading: isCompleteAppointmentLoading } =
    useCompleteAppointmentMutation();
  const { mutateAsync: approveAppointment, isLoading: isApprovingAppointment } =
    useApproveAppointmentMutation();
  const { mutateAsync: declineAppointment, isLoading: isDecliningAppointment } =
    useDeclineAppointmentMutation();

  const isNotYetApproved = booking.approvalStatus === AppointmentApprovalStatusEnum.Pending;
  const isApproved = booking.approvalStatus === AppointmentApprovalStatusEnum.Approved;

  const isConfirmed = isApproved && booking.status === AppointmentStatusEnum.Confirmed;
  const isPending = booking.status === AppointmentStatusEnum.Pending;
  const isOngoing = booking.status === AppointmentStatusEnum.Ongoing;
  const isNoShow = booking.status === AppointmentStatusEnum.NoShow;
  const isCancelled = booking.status === AppointmentStatusEnum.Cancelled;

  const displayApproveAndDeclineButtons = isNotYetApproved && !isCancelled;

  const { mutate: getMessageThreadByMemberIds } = useGetMessageThreadByMemberIdsMutation({
    onSuccess: (messageThread) => {
      if (messageThread && booking.provider) {
        router.push(
          `${Routes.MESSAGES}?threadId=${(messageThread as MessageThread).id}&branchId=${
            booking.provider.id
          }`,
        );
      } else if (booking) {
        handleCreateMessageThread();
      }
    },
  });

  const { mutate: createMessageThread } = useCreateMessageThreadMutation({
    onSuccess: (messageThread) => {
      if (messageThread && booking.provider) {
        router.push(
          `${Routes.MESSAGES}?threadId=${(messageThread as MessageThread).id}&branchId=${
            booking.provider.id
          }`,
        );
      }
    },
  });

  const handleOngoingAppointment = async () => {
    try {
      await ongoingAppointment(booking.id);
      handleConfirmArriveModalToggle();

      trackEvent(EventName.CLICK_APPOINTMENT_ONGOING, {
        appointment_booking_number: booking?.bookingNumber,
        appointment_id: booking?.id?.toString(),
      });

      refetch?.();
      router.replace(router.asPath);
      toast({ message: 'Appointment ongoing', type: 'success' });
    } catch (e) {
      toast({ message: 'Something went wrong.', type: 'error' });
    }
  };

  const handleNoShowAppointment = async () => {
    try {
      await noShowAppointment(booking.id);
      handleConfirmNoShowModalToggle();

      trackEvent(EventName.CLICK_APPOINTMENT_NO_SHOW, {
        appointment_booking_number: booking?.bookingNumber,
        appointment_id: booking?.id?.toString(),
      });

      handleFinishOnboardingProgressionActionStep?.(ProviderOnboardingStepEnum.VIEW_APPOINTMENT);

      refetch?.();
      toast({ message: 'Appointment status updated', type: 'success' });
      router.replace(router.asPath);
    } catch (e) {
      toast({ message: 'Something went wrong.', type: 'error' });
    }
  };

  const handleCompleteAppointment = async () => {
    try {
      await completeAppointment(booking.id);

      trackEvent(EventName.CLICK_APPOINTMENT_MARK_AS_COMPLETE, {
        appointment_booking_number: booking?.bookingNumber,
        appointment_id: booking?.id?.toString(),
      });

      handleFinishOnboardingProgressionActionStep?.(ProviderOnboardingStepEnum.VIEW_APPOINTMENT);

      refetch?.();
      router.replace(router.asPath);
      if (!isMobile) {
        toast({ message: 'Appointment completed', type: 'success' });
      }
    } catch (e) {
      if (!isMobile) {
        toast({ message: 'Something went wrong', type: 'error' });
      }
    }
  };

  const handleOpenCancelModal = () => {
    handleCancelModalToggle();
  };

  const handleCancelModalProceedClick = async (reason: string) => {
    try {
      await cancelAppointment({
        id: booking.id,
        appointmentDto: {
          reasonForCancellation: reason,
          scheduleId: booking?.schedule?.id,
        },
      });

      trackEvent(EventName.CLICK_APPOINTMENT_CANCEL, {
        appointment_booking_number: booking?.bookingNumber,
        appointment_id: booking?.id?.toString(),
      });

      handleFinishOnboardingProgressionActionStep?.(ProviderOnboardingStepEnum.VIEW_APPOINTMENT);

      refetch?.();
      router.replace(router.asPath);
      toast({ message: 'Appointment cancelled', type: 'success' });
      handleCancelModalToggle();
    } catch (e) {
      toast({ message: 'Something went wrong.', type: 'error' });
    }
  };

  async function handleApproveAppointment() {
    await approveAppointment(booking.id);

    refetch?.();
    router.replace(router.asPath);
    toast({ message: 'Appointment approved', type: 'success' });
  }

  const handleDeclineAppointment = async (reason: string) => {
    try {
      await cancelAppointment({
        id: booking.id,
        appointmentDto: {
          reasonForCancellation: reason,
          scheduleId: booking?.schedule?.id,
        },
      });
      await declineAppointment(booking.id);

      trackEvent(EventName.CLICK_APPOINTMENT_DECLINE, {
        appointment_booking_number: booking?.bookingNumber,
        appointment_id: booking?.id?.toString(),
      });

      refetch?.();
      router.replace(router.asPath);
      toast({ message: 'Appointment declined', type: 'success' });
      handleDeclineAppointmentModalToggle();
    } catch (e) {
      toast({ message: 'Something went wrong.', type: 'error' });
    }
  };

  const handleChatButtonClick = () => {
    setIsMessageButtonLoading(true);
    if (!booking.provider || !canChat) {
      toast({
        message: LOGIN_TO_SEND_MESSAGE,
        type: 'error',
      });
      setIsMessageButtonLoading(false);
      return;
    }

    const data: GetMessageThreadByMemberIdsDto = {
      memberIds: [
        formatMessageThreadMemberId(
          booking.provider.id.toString(),
          MessageThreadMemberTypeEnum.HealthhubProvider,
        ),
        formatMessageThreadMemberId(patientId, MessageThreadMemberTypeEnum.HealthhubPatient),
      ],
    };

    getMessageThreadByMemberIds(data);
  };

  const handleCreateMessageThread = async () => {
    if (!booking.provider || !canChat) {
      toast({
        message: 'Unable to create message thread. Please login and try again.',
        type: 'error',
      });
      setIsMessageButtonLoading(false);
      return;
    }

    const data: CreateMessageThreadDto = {
      memberIds: [
        formatMessageThreadMemberId(
          booking.provider.id.toString(),
          MessageThreadMemberTypeEnum.HealthhubProvider,
        ),
        formatMessageThreadMemberId(patientId, MessageThreadMemberTypeEnum.HealthhubPatient),
      ],
    };

    createMessageThread(data);
  };

  const renderReferralButton = booking.status === 'completed' && !isMphProvider && (
    <Button
      disabled={booking.status !== 'completed'}
      className="h-12"
      variant="outlinedPrimary"
      onClick={() => router.push(`${Routes.REFERRALS_ADD}?appointmentId=${booking.id}`)}
      icon={ArrowsRightLeftIcon}
    >
      Refer to Provider
    </Button>
  );

  return (
    <>
      {isOpenCancelModal && (
        <CancelModal
          isLoading={isLoadingCancelAppointment}
          onCancelClick={handleCancelModalToggle}
          onProceedClick={handleCancelModalProceedClick}
        />
      )}
      {isOpenConfirmArriveModal && (
        <ConfirmationModal
          data={booking}
          isOpen={true}
          title="Mark as Arrived"
          description="Are you sure you want to mark this as arrived?"
          toggle={handleConfirmArriveModalToggle}
          onClickConfirm={handleOngoingAppointment}
        />
      )}
      {isOpenConfirmNoShowModal && (
        <ConfirmationModal
          data={booking}
          isOpen={true}
          title="Mark as No-show"
          description="Are you sure you want to mark this as no-show?"
          toggle={handleConfirmNoShowModalToggle}
          onClickConfirm={handleNoShowAppointment}
        />
      )}
      {isOpenDeclineAppointmentModal && (
        <DeclineModal
          isLoading={isLoadingCancelAppointment}
          onCancelClick={handleDeclineAppointmentModalToggle}
          onProceedClick={handleDeclineAppointment}
        />
      )}
      <div className="mt-2 flex flex-col gap-y-3 md:mt-0 md:flex-row md:gap-x-2">
        {displayApproveAndDeclineButtons && (
          <>
            <Button
              variant="primary"
              onClick={handleApproveAppointment}
              className="h-12 gap-2"
              isLoading={isApprovingAppointment}
              disabled={isApprovingAppointment || isDecliningAppointment}
            >
              <CheckIcon height={20} width={20} />
              Confirm
            </Button>
            <Button
              variant="outlinedRed"
              onClick={handleDeclineAppointmentModalToggle}
              className="h-12 gap-2"
              isLoading={isDecliningAppointment}
              disabled={isApprovingAppointment || isDecliningAppointment}
            >
              <XMarkIcon height={20} width={20} />
              Decline
            </Button>
          </>
        )}
        {(isConfirmed || isPending) && isApproved && (
          <>
            <Button
              className="h-12 md:mb-0"
              icon={TransitionRight}
              variant="primary"
              onClick={handleConfirmArriveModalToggle}
            >
              Arrived
            </Button>
            {SHOW_MESSAGE_THREADS && userCanViewMessageThreads && canChat && (
              <Button
                className="h-12 md:mb-0"
                icon={ChatBubble}
                variant="secondary"
                onClick={handleChatButtonClick}
                isLoading={isMessageButtonLoading}
              >
                Chat
              </Button>
            )}
            <Button
              className="h-12 md:mb-0"
              icon={RemoveSquare}
              variant="outlinedPrimary"
              onClick={handleConfirmNoShowModalToggle}
            >
              No-show
            </Button>
            <Button
              className="h-12"
              icon={TrashIcon}
              onClick={handleOpenCancelModal}
              variant="outlinedRed"
            >
              Cancel
            </Button>
          </>
        )}
        {isOngoing && (
          <Button
            className="h-12"
            variant="successPrimary"
            onClick={handleCompleteAppointment}
            isLoading={isCompleteAppointmentLoading}
            disabled={isCompleteAppointmentLoading}
            icon={CheckCircleIcon}
          >
            Mark as Complete
          </Button>
        )}
        {!displayApproveAndDeclineButtons && !!bookingFollowUpUrl && (
          <Button
            disabled={isConfirmed || isOngoing || isOnboarding}
            className="h-12"
            variant="primary"
            onClick={() => router.push(bookingFollowUpUrl)}
            icon={ArrowPathIcon}
          >
            {isNoShow || isCancelled ? 'Rebook Appointment' : 'Book Follow-up'}
          </Button>
        )}
        {renderReferralButton}
      </div>
    </>
  );
};

export default BookingStatusButton;
