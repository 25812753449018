import { OrderStatusEnum } from '../enums';

export function toOrderStatusDisplay(status: OrderStatusEnum): string {
  let badgeText = '';

  switch (status) {
    case OrderStatusEnum.Completed:
      badgeText = 'Completed';
      break;
    case OrderStatusEnum.Cancelled:
      badgeText = 'Cancelled';
      break;
    case OrderStatusEnum.Confirmed:
      badgeText = 'Confirmed';
      break;
    case OrderStatusEnum.Processing:
      badgeText = 'Processing';
      break;
    case OrderStatusEnum.Shipped:
      badgeText = 'In Transit';
      break;
    case OrderStatusEnum.Pending:
      badgeText = 'Pending';
      break;
  }

  return badgeText;
}
