import { ReactNode } from 'react';

import clsx from 'clsx';

type Props = {
  shouldHideMenu?: boolean;
  leftContent?: ReactNode;
  centerContent?: ReactNode;
  rightContent?: ReactNode;
  onClickMenu?: () => void;
  className?: string;
  hasBorderButton?: boolean;
  isBgWhite?: boolean;
};

const NavBar = (props: Props) => {
  const {
    leftContent,
    rightContent,
    centerContent,
    className,
    hasBorderButton = true,
    isBgWhite = true,
  } = props;

  return (
    <div
      className={clsx(
        'sticky top-0 z-40 flex shrink-0 items-center px-3 py-3',
        isBgWhite ? 'bg-white' : 'bg-lightGray',
        hasBorderButton && 'mb-8 border-b-[1px] border-b-gray33',
        className,
      )}
    >
      <div>{leftContent}</div>
      <div className="flex flex-grow justify-center">{centerContent}</div>
      <div className="flex justify-end gap-x-4 self-stretch lg:gap-x-6">{rightContent}</div>
    </div>
  );
};

export default NavBar;
