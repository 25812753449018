'use client';

import React, { useEffect, useRef, useState } from 'react'; // Added useRef

import { UserUserTypeEnum } from '@healthhub/api-lib';
import { EyeIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { doc, onSnapshot } from 'firebase/firestore';
import { twMerge } from 'tailwind-merge';

import MessageDisplay from './MessageDisplay';
import { useMessageServiceFirestore } from '../../auth/firebase';
import { Routes } from '../../enums';
import { useGetMessagesByThreadIdQuery, useMessageOperations, useRouter } from '../../hooks';
import useIsMobile from '../../hooks/useIsMobile';
import {
  MessageThread,
  MessageThreadMemberTypeEnum,
  MessageThreadWithMembersAndLastMessage,
  MessagingCollections,
} from '../../types';
import { getThreadLogo, getThreadName } from '../../utils';
import Button from '../Button';
import LoadingSpinner from '../LoadingSpinner';
import TextInput from '../TextInput';

type Props = {
  senderUserId: string;
  selectedThread: MessageThread;
  className?: string;
  branchId?: string;
  isProvider?: boolean;
  isBranch?: boolean;
};

const MessagesSection = (props: Props) => {
  const router = useRouter();
  const { firestore } = useMessageServiceFirestore();
  const {
    senderUserId,
    selectedThread,
    className,
    branchId = '',
    isProvider = false,
    isBranch,
  } = props;
  const thread = selectedThread as MessageThreadWithMembersAndLastMessage;
  const threadId = thread.id;
  const {
    data: messages,
    refetch: refetchMessages,
    isInitialLoading,
  } = useGetMessagesByThreadIdQuery(threadId, branchId, isProvider);
  const nameDisplay = getThreadName(thread, senderUserId);
  const logoDisplay = getThreadLogo(thread, senderUserId);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const {
    messageValue,
    setMessageValue,
    disableSendButton,
    handleSendMessage,
    handleMarkThreadAsRead,
  } = useMessageOperations({ threadId, branchId, senderUserId, isProvider });

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.currentTarget;
    const atBottom = target.scrollHeight - target.scrollTop === target.clientHeight;

    if (atBottom) {
      handleMarkThreadAsRead();
    }
  };

  useEffect(() => {
    const refetchTriggersDocRef = doc(firestore, MessagingCollections.RefetchTriggers, threadId);

    const unsubscribe = onSnapshot(refetchTriggersDocRef, (snapshot) => {
      refetchMessages();
      setShouldScrollToBottom(true);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId]);

  useEffect(() => {
    if (!isInitialLoading && messages?.length && shouldScrollToBottom) {
      setShouldScrollToBottom(false);
      scrollToBottom();
    }
  }, [isInitialLoading, messages, shouldScrollToBottom]);

  useEffect(() => {
    if (!messagesEndRef.current) {
      return;
    }

    const hasVerticalOverflow =
      messagesEndRef.current.scrollHeight > messagesEndRef.current.clientHeight;

    if (!hasVerticalOverflow) {
      handleMarkThreadAsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesEndRef.current, messages]);

  const patientMember = thread.members.find(
    (member) => member.userType === UserUserTypeEnum.Patient,
  );
  const mainUserProviderClientProfileId =
    patientMember?.mainProfile?.providerClientProfiles?.[0]?.id;
  const displayViewClientButton = isProvider && !!mainUserProviderClientProfileId;

  const handleViewClient = () => {
    if (mainUserProviderClientProfileId) {
      router.push(`${Routes.CLIENTS}/${mainUserProviderClientProfileId}`);
    }
  };

  const isMobile = useIsMobile();

  const handleBack = () => {
    router.push(Routes.MESSAGES);
  };

  if (isMobile) {
    return (
      <div className="relative flex h-[100dvh] flex-col">
        <div
          className="top-0 flex w-screen cursor-pointer items-center p-4 py-3 shadow-topNav"
          onClick={handleBack}
        >
          <div>
            <ChevronLeftIcon className="h-6 w-6" />
          </div>
          <h3 className="truncate px-2 text-center text-lg font-medium text-primary-500">
            {nameDisplay}
          </h3>
        </div>
        {isInitialLoading && (
          <div className="mt-2 flex w-full justify-center">
            <LoadingSpinner />
          </div>
        )}
        <div
          className="h-full overflow-y-auto bg-neutral-100 p-4"
          ref={messagesEndRef}
          onScroll={handleScroll}
        >
          {!isInitialLoading &&
            messages?.map((message, index) => {
              let isSentByMe = message.senderUserId === senderUserId;

              if (isProvider && !isBranch) {
                isSentByMe = message.senderUserId.includes(
                  MessageThreadMemberTypeEnum.HealthhubPatient,
                )
                  ? false
                  : true;
              }

              return (
                <MessageDisplay
                  key={message.id}
                  message={message}
                  isSentByMe={isSentByMe}
                  logoDisplay={logoDisplay}
                  senderUserId={senderUserId}
                  prevMessage={messages[index - 1]}
                  nextMessage={messages[index + 1]}
                />
              );
            })}
        </div>
        <div className="bottom-0 flex w-full items-center gap-4 border-t border-gray-100 bg-white p-4 shadow-bottom-nav">
          <TextInput
            containerClassName="w-full"
            placeholder="Message"
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
            disabled={isInitialLoading}
          />
          <div>
            <Button disabled={disableSendButton} variant="primary" onClick={handleSendMessage}>
              <PaperAirplaneIcon height={20} width={20} color="white" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={twMerge('relative flex-1 border-l border-gray-100', className)}>
      <div className="sticky top-0 hidden w-full items-center justify-between border-b border-gray-100 bg-white p-4 sm:flex">
        <div className="flex items-center gap-2">
          <img src={logoDisplay} alt="Avatar" className="h-8 w-8 rounded-full" />
          <div className="truncate font-semibold">{nameDisplay}</div>
        </div>
        {displayViewClientButton && (
          <Button
            size="sm"
            className="rounded-md"
            variant="outlinedPrimary"
            onClick={handleViewClient}
          >
            <EyeIcon height={20} width={20} className="mr-2" />
            View Client
          </Button>
        )}
      </div>
      {isInitialLoading && (
        <div className="mt-2 flex w-full justify-center">
          <LoadingSpinner />
        </div>
      )}
      <div
        className="h-[75vh] overflow-y-auto bg-neutral-100 p-1 sm:p-2 md:p-4"
        ref={messagesEndRef}
        onScroll={handleScroll}
      >
        {!isInitialLoading &&
          messages?.map((message, index) => {
            let isSentByMe = message.senderUserId === senderUserId;

            if (isProvider && !isBranch) {
              isSentByMe = message.senderUserId.includes(
                MessageThreadMemberTypeEnum.HealthhubPatient,
              )
                ? false
                : true;
            }

            return (
              <MessageDisplay
                key={message.id}
                message={message}
                isSentByMe={isSentByMe}
                logoDisplay={logoDisplay}
                senderUserId={senderUserId}
                prevMessage={messages[index - 1]}
                nextMessage={messages[index + 1]}
              />
            );
          })}
      </div>
      <div className="sticky inset-x-0 bottom-0 flex w-full items-center gap-4 border-t border-gray-100 bg-white p-4">
        <TextInput
          containerClassName="w-full"
          placeholder="Message"
          value={messageValue}
          onChange={(e) => setMessageValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
          disabled={isInitialLoading}
        />
        <div>
          <Button disabled={disableSendButton} variant="primary" onClick={handleSendMessage}>
            <PaperAirplaneIcon height={20} width={20} color="white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MessagesSection;
