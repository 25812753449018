/* eslint-disable @nx/enforce-module-boundaries */
import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import Navigation from '../organisms/Navigation';

type Props = {
  children?: ReactNode;
  hideLogo?: boolean;
};

const QRCodeLayout = (props: Props) => {
  const { children, hideLogo } = props;
  const shouldHideNavigation = hideLogo;

  return (
    <>
      <div className="fixed left-0 top-0 z-0 h-full w-full bg-neutral-100"></div>
      <div className="z-1 relative mx-auto flex flex-col">
        {!shouldHideNavigation && <Navigation className="max-w-none md:fixed" />}
        <main className={twMerge('flex flex-auto flex-col pt-10', shouldHideNavigation && 'pt-0')}>
          {children}
        </main>
      </div>
    </>
  );
};

export default QRCodeLayout;
