// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Appointment,
  AppointmentService,
  AppointmentServicePriceTypeEnum,
  ProviderServiceSettings,
} from '@healthhub/api-lib';
import uniqBy from 'lodash.uniqby';

import {
  serviceHasPriceUponVisit,
  serviceHasPriceStartsAt,
  serviceHasPriceRange,
  getServiceStringedPrice,
  formatToPesoWithoutTrailingZeros,
} from './price';
import ProviderServiceHelper, { ProviderServiceWithDiscount } from './ProviderServiceHelper';
import { SHOW_PROVIDER_SERVICE_SETTINGS, defaultSpecialNotes } from '../constants';
import { BookingPaymentRequirement, PriceType } from '../enums';
import { Price } from '../libs/price';

interface StatusMap {
  [key: string]: 'info' | 'warn' | 'success' | 'danger';
}

export type GetAppointmentPaymentDetailsOptions = {
  services: any[];
  bookingFee?: number;
  transactionFee?: number;
  reservationFee?: number;
  deductible?: boolean;
  downPayment?: number;
  numberOfClients?: number;
  isFullPaymentNow?: boolean;
  hasProviderQr?: boolean;
};

export const displayOtherConcern = (
  appointmentServices: AppointmentService[] = [],
  otherConcern = '',
) => {
  if (appointmentServices.length && otherConcern) {
    return `and Other Concern`;
  }

  // Other concern only
  if (otherConcern) {
    return `Other Concern`;
  }

  return '';
};

const getOtherConcern = () =>
  ({
    id: 0,
    serviceName: 'Other Concern',
    priceType: AppointmentServicePriceTypeEnum.Hide,
    price: 0,
    minAmountInCents: 0,
    maxAmountInCents: 0,
    providerService: {
      specialNotes: '',
      label: 'Other Concern',
    },
  }) as AppointmentService;

export const getServices = (appointment?: Appointment) => {
  const services = appointment?.appointmentServices ?? [];

  if (appointment?.otherConcern) {
    return [...services, getOtherConcern()];
  }

  return services;
};

export const getStatusBadgeType = (status: string) => {
  const statusMap: StatusMap = {
    Started: 'info',
    Open: 'warn',
    Upcoming: 'info',
    Ongoing: 'warn',
    NoShow: 'danger',
    Completed: 'success',
    Cancelled: 'danger',
  };

  return statusMap[status] || 'info';
};

export function getAppointmentLabels(dataCount: number): string {
  const minCount = 1;
  return dataCount > minCount ? 'appointments' : 'appointment';
}

export function getDiagnosticOrderRequestLabels(dataCount: number): string {
  const minCount = 1;
  return dataCount > minCount ? 'diagnostic order requests' : 'diagnostic order request';
}

export function getReferralLabels(dataCount: number): string {
  const minCount = 1;
  return dataCount > minCount ? 'referrals' : 'referral';
}

export function getIsPayNowRequired(services: any[], reservationFee = 0) {
  const hasDownpayment = getHasDownpayment(services);

  return hasDownpayment || reservationFee > 0;
}

export function getCanPayNow(services: any[]) {
  return services.every((service) => {
    return service.priceType === PriceType.Fixed;
  });
}

export const applicableReservationFee = (
  serviceSettings?: ProviderServiceSettings,
  services?: any[],
) => {
  if (serviceSettings?.requireReservationFee && serviceSettings?.selectServicesOnly) {
    const servicesWithReservationFee = serviceSettings?.providerServices?.map(
      (service) => service.id,
    );
    const hasServiceWithReservationFee = services?.some(
      ({ parentProviderService }) =>
        servicesWithReservationFee?.find((id) => parentProviderService?.id === id),
    );
    return hasServiceWithReservationFee ? serviceSettings.reservationFee : 0;
  }
  return serviceSettings?.requireReservationFee ? serviceSettings?.reservationFee : 0;
};

export function getHasDownpayment(services: any[]) {
  return services.some(
    (service) =>
      SHOW_PROVIDER_SERVICE_SETTINGS &&
      service.bookingPaymentRequirement !== BookingPaymentRequirement.NONE,
  );
}

export const getAppointmentPaymentDetails: any = (params: GetAppointmentPaymentDetailsOptions) => {
  const {
    services,
    bookingFee = 0,
    transactionFee = 0,
    deductible = false,
    downPayment,
    numberOfClients = 1,
    isFullPaymentNow,
    hasProviderQr,
  } = params;

  let { reservationFee = 0 } = params;

  const hasPriceUponVisit = serviceHasPriceUponVisit(services);
  const hasPriceStartsAt = serviceHasPriceStartsAt(services);
  const hasPriceRange = serviceHasPriceRange(services);

  const totalAppointmentServicesOriginalAmount = services.reduce((acc, service) => {
    return acc + service.price;
  }, 0);

  // with discount if it has. feature flag ready
  const totalAppointmentServicesAmount = services.reduce((acc, service) => {
    const price = new ProviderServiceHelper(service).price?.priceInCentsInt;

    return acc + (Array.isArray(price) ? price?.[0] : price);
  }, 0);

  const totalRangeOriginalAmount = services.reduce(
    (acc, service) => {
      const [accMin, accMax] = acc;
      const stringedPrice = getServiceStringedPrice(service);
      const price = new Price(stringedPrice);
      const [min, max] = price.priceInCentsInMinMax;
      return [accMin + min, accMax + max];
    },
    [0, 0],
  );

  // with discount if it has. feature flag ready
  const totalRangeAmount = services.reduce(
    (acc, service) => {
      const [accMin, accMax] = acc;
      const { price } = new ProviderServiceHelper(service);
      const [min, max] = price?.priceInCentsInMinMax || [0, 0];

      return [accMin + min, accMax + max];
    },
    [0, 0],
  );

  const hasDownpayment = !!downPayment || downPayment === 0;

  // with discount if it has. feature flag ready
  const totalDownPayment = hasDownpayment
    ? downPayment
    : services
        .filter(
          (service) =>
            (SHOW_PROVIDER_SERVICE_SETTINGS &&
              service.bookingPaymentRequirement !== BookingPaymentRequirement.NONE &&
              hasProviderQr) ||
            isFullPaymentNow,
        )
        .reduce((acc, service) => {
          if (isFullPaymentNow) {
            const price = new ProviderServiceHelper(service).price?.priceInCentsInt;

            return acc + (Array.isArray(price) ? price?.[0] : price);
          }

          if (service.bookingPaymentRequirement === BookingPaymentRequirement.DOWNPAYMENT) {
            return acc + service.downPaymentAmount;
          } else {
            const price = new ProviderServiceHelper(service).price?.priceInCentsInt;

            return acc + (Array.isArray(price) ? price?.[0] : price);
          }
        }, 0);

  if (!SHOW_PROVIDER_SERVICE_SETTINGS || !hasProviderQr) {
    reservationFee = 0;
  }

  const formattedBookingFee = formatToPesoWithoutTrailingZeros(bookingFee);
  const formattedTransactionFee = formatToPesoWithoutTrailingZeros(transactionFee);
  const formattedReservationFee = formatToPesoWithoutTrailingZeros(reservationFee);
  const formattedTotalDownPayment = formatToPesoWithoutTrailingZeros(totalDownPayment);
  const totalAmountDueNow =
    (reservationFee + totalDownPayment + bookingFee + transactionFee) * numberOfClients;
  const formattedTotalAmountDueNow = formatToPesoWithoutTrailingZeros(totalAmountDueNow);

  let totalAmountDue: number | number[] =
    totalAppointmentServicesAmount + bookingFee + transactionFee + reservationFee;
  let totalAmountDueInPerson: number | number[] = totalAppointmentServicesAmount - totalDownPayment;
  if (deductible) {
    totalAmountDueInPerson -= reservationFee;
  }
  if (numberOfClients) {
    totalAmountDueInPerson *= numberOfClients;
    (totalAmountDue as number) *= numberOfClients;
  }
  let formattedSubtotalAmount: string = formatToPesoWithoutTrailingZeros(
    totalAppointmentServicesAmount,
  );
  let formattedSubtotalOriginalAmount: string = formatToPesoWithoutTrailingZeros(
    totalAppointmentServicesOriginalAmount,
  );
  let formattedTotalAmountDue: string = formatToPesoWithoutTrailingZeros(totalAmountDue as any);
  let formattedTotalAmountDueInPerson: string =
    formatToPesoWithoutTrailingZeros(totalAmountDueInPerson);
  let savedAmountFromDiscount = (
    totalAppointmentServicesOriginalAmount - totalAppointmentServicesAmount
  ).toString();

  if (hasPriceRange) {
    totalAmountDueInPerson = [
      (totalRangeAmount[0] + bookingFee + transactionFee - totalDownPayment) * numberOfClients,
      (totalRangeAmount[1] + bookingFee + transactionFee - totalDownPayment) * numberOfClients,
    ];
    formattedSubtotalAmount = `${formatToPesoWithoutTrailingZeros(
      totalRangeAmount[0],
    )} — ${formatToPesoWithoutTrailingZeros(totalRangeAmount[1])}`;
    formattedSubtotalOriginalAmount = `${formatToPesoWithoutTrailingZeros(
      totalRangeOriginalAmount[0],
    )} — ${formatToPesoWithoutTrailingZeros(totalRangeOriginalAmount[1])}`;
    formattedTotalAmountDue = `${formatToPesoWithoutTrailingZeros(
      (totalRangeAmount[0] + totalAmountDueNow) * numberOfClients,
    )} — ${formatToPesoWithoutTrailingZeros(totalRangeAmount[1] + totalAmountDueNow)}`;
    formattedTotalAmountDueInPerson = `${formatToPesoWithoutTrailingZeros(
      (totalAmountDueInPerson as number[])[0],
    )} — ${formatToPesoWithoutTrailingZeros((totalAmountDueInPerson as number[])[1])}`;

    savedAmountFromDiscount = `${totalRangeOriginalAmount[0] - totalRangeAmount[0]} - ${
      totalRangeOriginalAmount[1] - totalRangeAmount[1]
    }`;
  } else if (hasPriceStartsAt) {
    formattedTotalAmountDueInPerson = `Starts At ${formattedTotalAmountDueInPerson}`;
  }

  if (hasPriceUponVisit) {
    formattedTotalAmountDue = `${formattedTotalAmountDue} +`;
    formattedTotalAmountDueInPerson = `${formattedTotalAmountDueInPerson} +`;
  }

  const appointmentSpecialNotes = uniqBy(
    services
      .map((service) => {
        const isProviderService = 'specialNotes' in service;
        const isAppointmentService = 'providerService' in service;
        if (isProviderService) {
          return service.specialNotes;
        } else if (isAppointmentService) {
          return service.providerService.specialNotes;
        }
      })
      .filter((note) => Object.values(defaultSpecialNotes).includes(note ?? '')),
    (note) => note,
  );

  formattedSubtotalAmount = addSuffixOrPrefixToFormattedPrice(formattedSubtotalAmount, {
    hasPriceUponVisit,
    hasPriceStartsAt,
    hasPriceRange,
  });

  formattedSubtotalOriginalAmount = addSuffixOrPrefixToFormattedPrice(
    formattedSubtotalOriginalAmount,
    {
      hasPriceUponVisit,
      hasPriceStartsAt,
      hasPriceRange,
    },
  );

  const discounts = groupProviderServiceDiscounts(services);
  const savedAmountFromDiscountPrice = new Price(savedAmountFromDiscount);
  const formattedSavedAmountFromDiscount = savedAmountFromDiscountPrice.priceInPesos;
  const hasSavedAmount = parseInt(savedAmountFromDiscountPrice.priceInCents) > 0;

  return {
    totalAmount: hasPriceRange ? totalRangeAmount : totalAppointmentServicesAmount,
    bookingFee,
    transactionFee,
    totalAmountDueNow,
    totalAmountDueInPerson,
    totalAmountDue,
    totalDownPayment,
    reservationFee,
    formattedSubtotalAmount,
    formattedTotalAmountDue,
    formattedTotalAmountDueNow,
    formattedTotalDownPayment,
    formattedReservationFee,
    formattedBookingFee,
    formattedTransactionFee,
    formattedTotalAmountDueInPerson,
    appointmentSpecialNotes,
    hasPriceUponVisit,
    savedAmountFromDiscount,
    formattedSavedAmountFromDiscount,
    formattedSubtotalOriginalAmount,
    hasSavedAmount,
    discounts,
  };
};

export function addSuffixOrPrefixToFormattedPrice(
  formattedValue: string,
  {
    hasPriceUponVisit = false,
    hasPriceStartsAt = false,
    hasPriceRange = false,
  }: {
    hasPriceUponVisit: boolean;
    hasPriceStartsAt: boolean;
    hasPriceRange: boolean;
  },
) {
  if (hasPriceStartsAt && !hasPriceRange) {
    formattedValue = `Starts At ${formattedValue}`;
  }

  if (hasPriceUponVisit) {
    if (formattedValue === '₱0') {
      formattedValue = 'Price upon visit';
    } else {
      formattedValue = `${formattedValue} +`;
    }
  }

  return formattedValue;
}

export function groupProviderServiceDiscounts(services: ProviderServiceWithDiscount[]) {
  const hasPriceRange = serviceHasPriceRange(services);
  const priceType = hasPriceRange ? PriceType.Range : PriceType.Fixed;

  const discounts = Array.from(
    services
      .reduce((acc, service) => {
        const { savedAmountFromDiscount } = new ProviderServiceHelper(service);
        const discount = service?.discounts?.[0];

        if (!discount) return acc;

        if (acc.has(discount?.id)) {
          const existingDiscount = acc.get(discount.id);
          const existingDiscountSavedAmount = new Price(
            existingDiscount.savedAmount || '0',
            priceType,
          );

          if (hasPriceRange) {
            const newSavedAmount = `${
              existingDiscountSavedAmount.priceInCentsInMinMax[0] +
              savedAmountFromDiscount.priceInCentsInMinMax[0]
            } - ${
              existingDiscountSavedAmount.priceInCentsInMinMax[1] +
              savedAmountFromDiscount.priceInCentsInMinMax[1]
            }`;

            acc.set(discount.id, {
              ...discount,
              savedAmount: newSavedAmount,
            });
          } else {
            const newSavedAmount =
              parseInt(existingDiscountSavedAmount.priceInCents) +
              parseInt(savedAmountFromDiscount.priceInCents);

            acc.set(discount.id, {
              ...discount,
              savedAmount: newSavedAmount.toString(),
            });
          }
        } else {
          acc.set(discount.id, {
            ...discount,
            savedAmount: savedAmountFromDiscount.priceInCents,
          });
        }

        return acc;
      }, new Map())
      .values(),
  );

  return discounts;
}
