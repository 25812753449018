import { ProviderService } from '@healthhub/api-lib';

import Badge from './v2/Badge';
import { BadgeType } from '../enums/badgeType';
import ProviderServiceHelper from '../utils/ProviderServiceHelper';

type Props = {
  providerService?: ProviderService;
};

function ProviderServiceAvailabilityLabel(props: Props) {
  const { providerService } = props;

  const { availability } = new ProviderServiceHelper(providerService);

  return <Badge type={BadgeType.PLAIN}>{availability}</Badge>;
}

export default ProviderServiceAvailabilityLabel;
