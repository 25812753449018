import { Appointment, ProviderClientNotes } from '@healthhub/api-lib';
import { Button, Modal } from '@mwell-healthhub/commons';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  onRemove: () => void;
  isLoading: boolean;
};

const ProviderClientMedicalRecordsModal = (props: Props) => {
  const { isOpen, toggle, onRemove, isLoading } = props;

  return (
    <Modal open={isOpen} setOpen={toggle} className="!p-0" size="lg">
      <div className="flex flex-col gap-4 p-6 font-normal">
        <div className="text-lg font-semibold">Remove this record?</div>
        <div className="text-md flex flex-col gap-2 text-gray-500">
          Are you sure you want to remove this medical record from your client's appointment
          details?
        </div>
        <div className="flex justify-end gap-4">
          <Button variant="outlined" onClick={toggle} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="error" onClick={onRemove} disabled={isLoading}>
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProviderClientMedicalRecordsModal;
