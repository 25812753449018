export enum OCREnum {
  ADDRESS = 'ADDRESS',
  DATE_OF_BIRTH = 'DATE OF BIRTH',
  DEPARTMENT_OF_TRANSPORTATION = 'DEPARTMENT OF TRANSPORTATION',
  GIVEN_NAME = 'GIVEN NAME',
  HEIGHT = 'HEIGHT',
  LAND_TRANSPORTATION_OFFICE = 'LAND TRANSPORTATION OFFICE',
  LASTNAME_FIRSTNAME_MIDDLENAME = 'LAST NAME. FIRST NAME. MIDDLE NAME',
  MIDDLE_NAME = 'MIDDLE NAME',
  NATIONAL_SEX = 'NATIONALITY SEX',
  SURNAME = 'SURNAME',
  UNIFIED_MULTI_PURPOSE_ID = 'UNIFIED MULTI-PURPOSE ID',
  WEIGHT = 'WEIGHT',
}
