import { DetailedHTMLProps, InputHTMLAttributes, createRef, useEffect } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { useRouter } from '@mwell-healthhub/commons';

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  shouldFocus?: boolean;
  roundClass?: string | 'rounded-full';
  height?: string;
  iconClass?: string;
  inputBgColor?: string;
  ringColor?: string;
  fontSizeClasses?: string;
};

const SearchField = (props: Props) => {
  const router = useRouter();
  const { query } = router;
  const inputRef = createRef<HTMLInputElement>();
  const {
    className,
    defaultValue,
    placeholder,
    shouldFocus = false,
    roundClass,
    height,
    iconClass = 'text-neutral-400',
    inputBgColor = '',
    ringColor = 'ring-neutral-300',
    fontSizeClasses = 'md:text-base text-sm',
    ...extras
  } = props;

  useEffect(() => {
    if (shouldFocus && router.isReady) {
      inputRef.current?.focus();
    }
  }, [shouldFocus, router.isReady]);

  function handleClear() {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    router.replace({
      query: { ...query, query: '' },
    });
  }

  return (
    <div className={className}>
      <div className={twMerge('shadow-sm relative', roundClass)}>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className={twMerge('h-5 w-5', iconClass)} aria-hidden="true" />
        </div>
        <input
          className={twMerge(
            'block w-full border-0 py-2 pl-10 pr-3 leading-6 tracking-tight text-neutral-600 ring-1 ring-inset placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-500/80 focus-visible:outline-none md:py-2.5 md:pr-5',
            roundClass,
            height,
            inputBgColor,
            ringColor,
            fontSizeClasses,
          )}
          defaultValue={defaultValue}
          id="search"
          name="search"
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          {...extras}
        />

        {query.query && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute inset-y-0 right-0 items-center px-2"
          >
            <XMarkIcon className="h-5 w-5 text-neutral-400" aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchField;
