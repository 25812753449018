import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { Routes } from '../enums';

interface ForgotPasswordLinkProps {
  text?: string;
  textColor?: string;
  textSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  textFontWeight?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

function ForgotPasswordLink(props: ForgotPasswordLinkProps) {
  const { text, textColor = 'primary3', textFontWeight = 'md', textSize = 'sm' } = props;

  const textSizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  const fontWeights = {
    xs: 'font-light',
    sm: 'font-normal',
    md: 'font-medium',
    lg: 'font-semibold',
    xl: 'font-bold',
  };
  return (
    <Link
      href={Routes.FORGOT_PASSWORD}
      className={twMerge('text-' + textColor, textSizes[textSize], fontWeights[textFontWeight])}
    >
      {text || `Forgot Password?`}
    </Link>
  );
}

export default ForgotPasswordLink;
