import React from 'react';

type Props = {
  isAbsolute?: boolean;
};

export default function InputLoadingSpinner(props: Props): JSX.Element {
  const { isAbsolute = true } = props;

  return (
    <div
      className={`pointer-events-none inset-y-0 right-1/2 flex items-center px-2 text-white ${
        isAbsolute && 'absolute'
      }`}
    >
      <svg
        className="h-5 w-5 animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6s-6-2.691-6-6H6c0 2.21 1.791 4 4 4z"
        ></path>
      </svg>
    </div>
  );
}
