import {
  CreateMessageDto,
  CreateMessageThreadDto,
  GetMessageThreadByMemberIdsDto,
  MessageThreadsApi,
} from '@healthhub/api-lib';
import { AxiosError } from 'axios';

import HealthHubApiClient from './api-client';
import { Message, MessageThread } from '../types';

export const messageThreadApi = HealthHubApiClient.use(MessageThreadsApi);

export async function createMessageThread(
  createMessageThreadDto: CreateMessageThreadDto,
): Promise<MessageThread> {
  try {
    const { data } =
      await messageThreadApi.messageThreadControllerCreateMessageThread(createMessageThreadDto);

    return data as MessageThread;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function createMessage(createMessageDto: CreateMessageDto) {
  try {
    const { data } = await messageThreadApi.messageThreadControllerCreateMessage(createMessageDto);

    return data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function getMemberMessageThreads(branchId: string): Promise<MessageThread[]> {
  try {
    const { data } =
      await messageThreadApi.messageThreadControllerGetMemberMessageThreads(branchId);

    return data as MessageThread[];
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function getThread(threadId: string): Promise<MessageThread> {
  try {
    const { data } = await messageThreadApi.messageThreadControllerGetThread(threadId);

    return data as unknown as MessageThread;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function getMessagesByThreadId(
  threadId: string,
  branchId: string,
): Promise<Message[]> {
  try {
    const { data } = await messageThreadApi.messageThreadControllerGetMessagesByThreadId(
      threadId,
      branchId,
    );

    return data as Message[];
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function getMessageThreadByMemberIds(
  getMessageThreadByMemberIdsDto: GetMessageThreadByMemberIdsDto,
) {
  try {
    const { data } = await messageThreadApi.messageThreadControllerGetMessageThreadByMemberIds(
      getMessageThreadByMemberIdsDto,
    );

    return data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function updateThreadReadByIdsArray(threadId: string, branchId?: string) {
  const payload = branchId ? { branchId } : undefined;

  try {
    await messageThreadApi.messageThreadControllerUpdateThreadReadByIdsArray(threadId, {
      data: payload,
    });
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}
