import {
  CreatePatientHmoDto,
  HmoResponseDto,
  HmosApi,
  ValidatePatientHmoDto,
} from '@healthhub/api-lib';

import { CustomError } from '@mwell-healthhub/commons';
import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';

const hmosApi = HealthHubApiClient.use(HmosApi);

type DeletePatientHmoParams = {
  mwellUserId: string;
  userHmoId: string;
};

export async function getAllEnrolledHmos(name?: string): Promise<HmoResponseDto[]> {
  try {
    const response = await hmosApi.hmoControllerGetAllEnrolledHmos(name);

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getMwellUserHmos(mwellUserId: string) {
  try {
    const { data } = await hmosApi.hmoControllerGetMwellUserHmos(mwellUserId);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createPatientHmo(createPatientHmo: CreatePatientHmoDto) {
  try {
    const { data } = await hmosApi.hmoControllerSavePatientHmo(createPatientHmo);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function validatePatientHmo(validatePatientHmo: ValidatePatientHmoDto) {
  try {
    const { data } = await hmosApi.hmoControllerValidatePatientHmo(validatePatientHmo);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function deleteSavedPatientHmo({ mwellUserId, userHmoId }: DeletePatientHmoParams) {
  try {
    const { data } = await hmosApi.hmoControllerDelete(mwellUserId, userHmoId);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}
