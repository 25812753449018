import {
  ChangeEvent,
  HTMLProps,
  MouseEvent,
  Ref,
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import { clsxMerge } from '../../utils';

export interface MaskedTextInputProps extends Omit<HTMLProps<HTMLInputElement>, 'type'> {
  label?: string;
  labelSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  labelFontWeight?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  labelShown?: boolean;
  labelClassName?: string;
  containerClassName?: string;
  errorMessage?: string;
  showRequiredOnLabel?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function MaskedTextInput(props: MaskedTextInputProps, forwardedRef?: Ref<HTMLInputElement>) {
  const {
    label,
    labelShown = false,
    labelSize = 'sm',
    labelFontWeight = 'sm',
    labelClassName = '',
    containerClassName = '',
    disabled,
    className = '',
    title,
    id: idProp,
    errorMessage,
    showRequiredOnLabel = false,
    ...etcProps
  } = props;
  const defaultId = useId();
  const id = idProp ?? defaultId;
  const [masked, setMasked] = useState(true);
  const defaultRef = useRef<HTMLInputElement>(null);
  const ref = forwardedRef ?? defaultRef;

  function toggleMasked(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setMasked((oldMasked) => !oldMasked);
    if (typeof ref === 'object' && ref?.current) {
      ref.current.focus();
    }
  }

  function keepInputFocused(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (typeof ref === 'object' && ref?.current) {
      ref.current.focus();
      ref.current.dataset[
        'selection'
      ] = `${ref.current.selectionDirection}:${ref.current.selectionStart}:${ref.current.selectionEnd}`;
    }
  }

  function preserveSelection(ref: Ref<HTMLInputElement>) {
    if (typeof ref === 'object' && ref?.current) {
      const selectionData = ref.current.dataset['selection'] ?? 'forward:0:0';
      const [direction, startRaw, endRaw] = selectionData.split(':');
      ref.current.selectionDirection = direction as HTMLInputElement['selectionDirection'];
      ref.current.selectionStart = Number(startRaw);
      ref.current.selectionEnd = Number(endRaw);
      ref.current.dataset['selection'] = undefined;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      preserveSelection(ref);
    });
  }, [ref, masked]);

  const fontWeights = {
    xs: 'light',
    sm: 'normal',
    md: 'medium',
    lg: 'semibold',
    xl: 'bold',
  };

  return (
    <div className={containerClassName}>
      <label
        htmlFor={id}
        className={clsxMerge(
          `block text-${labelSize} font-${fontWeights[labelFontWeight]} mb-1.5 leading-6 text-gray-900`,
          {
            'sr-only': !labelShown,
          },
          labelClassName,
        )}
      >
        {label}
        {showRequiredOnLabel && <span className="text-red-600"> *</span>}
      </label>
      <div className="relative flex items-center">
        <input
          {...etcProps}
          ref={ref}
          id={id}
          type={masked ? 'password' : 'text'}
          title={title ?? label}
          disabled={disabled}
          className={clsxMerge(
            'pr-15 block h-10 w-full flex-auto rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-primary-500 disabled:opacity-50 sm:text-sm sm:leading-6',
            className,
            {
              'ring-red-600 focus:ring-red-600': errorMessage,
            },
          )}
        />
        <button
          type="button"
          onMouseDown={keepInputFocused}
          onClick={toggleMasked}
          disabled={disabled}
          className="absolute right-0 top-0 flex aspect-square h-full items-center justify-center disabled:opacity-50"
          title={masked ? 'Click to show value.' : 'Click to hide value.'}
        >
          {masked ? (
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#939FA3"
              aria-label="Show"
            >
              <path
                d="M3 13c3.6-8 14.4-8 18 0M12 17a3 3 0 110-6 3 3 0 010 6z"
                stroke="#939FA3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#939FA3"
              aria-label="Hide"
            >
              <path
                d="M19.5 16l-2.475-3.396M12 17.5V14M4.5 16l2.469-3.388M3 8c3.6 8 14.4 8 18 0"
                stroke="#939FA3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </div>
      {errorMessage && <div className="mt-1 text-sm text-red-600">{errorMessage}</div>}
    </div>
  );
}

export default forwardRef(MaskedTextInput);
