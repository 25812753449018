import React from 'react';

import { Button } from '@mwell-healthhub/commons';

type Props = {
  actionButtonLabel?: string;
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationModal: React.FC<Props> = (props: Props) => {
  const { actionButtonLabel = 'Confirm', title, onConfirm, onCancel } = props;

  const handleConfirm = async () => {
    onConfirm();
  };

  return (
    <div className="fixed inset-0 left-1/2 z-50 flex w-full -translate-x-1/2 items-center justify-center p-6">
      <div className="fixed inset-0 bg-gray-900 opacity-50" />
      <div className="relative z-10 w-full max-w-md rounded-lg bg-white p-4 md:p-8">
        <h3 className="text-xl font-medium text-gray-900">{title}</h3>
        <div className="mt-4 flex flex-col gap-3">
          <Button variant="primary" className="h-[52px] md:h-full" onClick={handleConfirm}>
            {actionButtonLabel}
          </Button>
          <Button variant="secondary" className="h-[52px] md:h-full" onClick={onCancel}>
            Keep Appointment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
