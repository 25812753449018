import { Square2StackIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';

import { TransactionMerchant } from '../../types';
import {
  formatFullDateTime,
  formatToHumanReadable,
  formatToPeso,
  toTitleCase,
  truncateMiddle,
} from '../../utils';
import Button from '../Button';
import Modal from '../Modal';
import toast from '../toast';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  transactionMerchant: TransactionMerchant;
};

function ViewTransactionModal(props: Props) {
  const { transactionMerchant, isOpen, onClose } = props;

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          message: 'ID copied to clipboard!',
          type: 'success',
        });
      })
      .catch(() => {
        toast({
          message: 'Failed to copy ID.',
          type: 'error',
        });
      });
  };

  const eshopRevenue =
    transactionMerchant?.transactionMerchantProducts?.reduce(
      (acc, transactionMerchantProduct) => acc + transactionMerchantProduct?.eshopRevenue,
      0,
    ) ?? 0;
  const eshopOVat =
    transactionMerchant?.transactionMerchantProducts?.reduce(
      (acc, transactionMerchantProduct) => acc + transactionMerchantProduct?.eshopOVat,
      0,
    ) ?? 0;
  const sfRates =
    transactionMerchant?.transactionMerchantProducts
      ?.map((transactionMerchantProduct) => `${transactionMerchantProduct?.product?.sfRate * 100}%`)
      ?.join(', ') ?? '';
  const mWellSFNet =
    transactionMerchant?.transactionMerchantProducts?.reduce(
      (acc, transactionMerchantProduct) => acc + transactionMerchantProduct?.mWellSFNet,
      0,
    ) ?? 0;
  const sfOVat =
    transactionMerchant?.transactionMerchantProducts?.reduce(
      (acc, transactionMerchantProduct) => acc + transactionMerchantProduct?.sfOVat,
      0,
    ) ?? 0;
  const cwtRates = `${(transactionMerchant?.merchant.cwtRate ?? 0) * 100}%`;
  const cwt =
    transactionMerchant?.transactionMerchantProducts?.reduce(
      (acc, transactionMerchantProduct) => acc + transactionMerchantProduct?.cwt,
      0,
    ) ?? 0;
  const mWellCommission =
    transactionMerchant?.transactionMerchantProducts?.reduce(
      (acc, transactionMerchantProduct) => acc + transactionMerchantProduct?.mWellCommission,
      0,
    ) ?? 0;

  return (
    <Modal className="overflow-y-auto" open={isOpen} size="md" type="rightDrawer" onClose={onClose}>
      <div className="flex flex-col gap-6 text-base text-neutral-600">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <span className="text-xl font-semibold text-black">Transaction Details</span>
            <Button
              variant="label"
              className="h-fit !p-0 text-neutral-400"
              icon={XMarkIcon}
              onClick={onClose}
            />
          </div>
          <div className="flex items-center">
            <span className="text-lg">{transactionMerchant?.transaction?.id}</span>
            <Button
              variant="label"
              className="h-fit !p-0 text-primary-500"
              icon={Square2StackIcon}
              onClick={() => handleCopyToClipboard(transactionMerchant?.transaction?.id)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 rounded-md border border-neutral-300 p-[14px]">
          <div className="flex flex-col gap-2 ">
            <div className="flex justify-between">
              <span>User Name</span>
              <span>
                {transactionMerchant?.transaction?.buyerDetails?.firstName}{' '}
                {transactionMerchant?.transaction?.buyerDetails?.middleName}{' '}
                {transactionMerchant?.transaction?.buyerDetails?.lastName}
              </span>
            </div>
            <div className="flex justify-between">
              <span>Merchant</span>
              <span>{transactionMerchant?.merchant?.username}</span>
            </div>
            <div className="flex justify-between">
              <span>Payment method</span>
              <span>{toTitleCase(transactionMerchant?.transaction?.paymentMethod)}</span>
            </div>
            <div className="flex justify-between">
              <span>Transaction date</span>
              <span>
                {formatFullDateTime(new Date(transactionMerchant?.transaction?.createdAt))}
              </span>
            </div>
          </div>
          <div className="flex justify-between rounded-lg bg-neutral-100 px-4 py-3 font-semibold">
            <span>Total amount</span>
            <span>{formatToPeso(transactionMerchant?.transaction?.totalAmount)}</span>
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <span className="text-lg font-semibold">Order</span>
            <div className="flex justify-between">
              <span className="text-neutral-500">Order Number</span>
              <span>{transactionMerchant?.orderId}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-neutral-500">Order Status</span>
              <span>{toTitleCase(transactionMerchant?.status)}</span>
            </div>
            {transactionMerchant?.shippingDate && (
              <div className="flex justify-between">
                <span className="text-neutral-500">Delivery Date</span>
                <span>{formatToHumanReadable(new Date(transactionMerchant?.shippingDate))}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <span className="text-lg font-semibold">Merchant</span>
              {/* TODO: add merchant page */}
              {/* <Link
                className="flex h-fit !p-0 text-primary-500 text-sm font-medium whitespace-nowrap gap-2"
                href={`${Routes.MERCHANTS}/${transactionMerchant?.merchant?.id}`}
                target="_blank"
              >
                View Merchant
                <ArrowTopRightOnSquareIcon className="h-5 w-5"/>
              </Link> */}
            </div>
            <div className="flex justify-between">
              <span className="text-neutral-500">Merchant ID</span>
              <span>{truncateMiddle(transactionMerchant?.merchant?.id, 20)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-neutral-500">Merchant Name</span>
              <span>{transactionMerchant?.merchant?.username}</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <span className="text-lg font-semibold">Products</span>
            {transactionMerchant?.transactionMerchantProducts?.map((transactionMerchantProduct) => (
              <div key={transactionMerchantProduct?.id} className="flex flex-col gap-4">
                <div className="flex justify-between">
                  <span className="font-semibold">{transactionMerchantProduct?.product?.name}</span>
                  {/* TODO: add product page */}
                  {/* <Link
                    className="flex h-fit !p-0 text-primary-500 text-sm font-medium whitespace-nowrap gap-2"
                    href={`${Routes.PRODUCTS}/${transactionMerchantProduct?.product?.id}`}
                    target="_blank"
                  >
                    View Product
                    <ArrowTopRightOnSquareIcon className="h-5 w-5"/>
                  </Link> */}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between">
                    <span className="text-neutral-500">Product ID</span>
                    <span>
                      {truncateMiddle(
                        transactionMerchantProduct.variant?.id ??
                          transactionMerchantProduct.product.id,
                        20,
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-neutral-500">Vatable</span>
                    <span>{transactionMerchantProduct?.product?.isVatable ? 'Y' : 'N'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-neutral-500">Product App Price</span>
                    <span>
                      {formatToPeso(
                        transactionMerchantProduct.variant?.price ??
                          transactionMerchantProduct.product.price,
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-4">
            <span className="text-lg font-semibold">Payment</span>
            <div className="flex flex-col gap-3 border-b border-b-neutral-300 pb-4">
              <div className="flex justify-between">
                <span className="text-neutral-500">Payment ID</span>
                <span>
                  {truncateMiddle(
                    transactionMerchant?.transaction?.paymentReferenceNumber ?? '',
                    20,
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">Payment Method</span>
                <span>{toTitleCase(transactionMerchant?.transaction?.paymentMethod)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">Payment Status</span>
                <span>{toTitleCase(transactionMerchant?.transaction?.paymentStatus)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">Payment Amount</span>
                <span>{formatToPeso(transactionMerchant?.transaction?.totalAmount)}</span>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex justify-between">
                <span className="text-neutral-500">eShop Revenue</span>
                <span>{formatToPeso(eshopRevenue)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">eShop O-VAT</span>
                <span>{formatToPeso(eshopOVat)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">SF Rates</span>
                <span>{sfRates}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">MWELL SF, net</span>
                <span>{formatToPeso(mWellSFNet)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">SF O-VAT</span>
                <span>{formatToPeso(sfOVat)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">CWT Rate</span>
                <span>{cwtRates}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">CWT</span>
                <span>{formatToPeso(cwt)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-neutral-500">MWELL Commission</span>
                <span>{formatToPeso(mWellCommission)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ViewTransactionModal;
