export enum Region {
  'BARMM' = 'Bangsamoro Autonomous Region in Muslim Mindanao (BARMM)',
  'CAR' = 'Cordillera Administrative Region (CAR)',
  'NCR' = 'National Capital Region (NCR)',
  'I' = 'Region I (Ilocos Region)',
  'II' = 'Region II (Cagayan Valley)',
  'III' = 'Region III (Central Luzon)',
  'IV-A' = 'Region IV-A (CALABARZON)',
  'IV-B' = 'Region IV-B (MIMAROPA)',
  'V' = 'Region V (Bicol Region)',
  'VI' = 'Region VI (Western Visayas)',
  'VII' = 'Region VII (Central Visayas)',
  'VIII' = 'Region VIII (Eastern Visayas)',
  'IX' = 'Region IX (Zamboanga Peninsula)',
  'X' = 'Region X (Northern Mindanao)',
  'XI' = 'Region XI (Davao Region)',
  'XII' = 'Region XII (SOCCSKSARGEN)',
  'XIII' = 'Region XIII (Caraga)',
}
