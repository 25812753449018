import { Address } from '@healthhub/api-lib';

export function formatCompleteAddress(address?: Address) {
  const { line, barangay, country, municipalityCity, province, region, zipCode } = address ?? {};

  const { name: regionName } = region ?? {};

  const components = [line, barangay, municipalityCity, province, regionName, country]
    .filter(Boolean)
    .join(', ');

  return `${components} ${zipCode ? '- ' + zipCode : ''}`;
}

export const parseLatLongString = (latLongString: string) => {
  const [latitude, longitude] = latLongString
    .split(',')
    .map((coordinate) => Number(coordinate.trim()));
  return { latitude, longitude };
};

export const formatAddressToCityAndProvinceOnly = (address?: Address) => {
  const { municipalityCity, province } = address ?? {};
  return [municipalityCity, province].filter(Boolean).join(', ');
};
