import { CreateFeedbackDto, CreateFeedbackResponse } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createFeedback } from '../../api';

export const useCreateFeedbackMutation = (
  mutationOptions: MutationOptions<
    CreateFeedbackResponse,
    AxiosError<{ message: string }>,
    CreateFeedbackDto
  >,
) => useMutation(createFeedback, mutationOptions);
