import { HTMLProps, useEffect, useRef } from 'react';

import { CalendarIcon } from '@heroicons/react/20/solid';
import DatePicker, { DateObject, DatePickerRef } from 'react-multi-date-picker';

import { DATE_PICKER_DATE_FORMAT } from '../constants';
import { clsxMerge } from '../utils';

interface Props extends HTMLProps<HTMLDivElement> {
  dateValue: Date;
  minDate?: number | string | Date | DateObject;
  maxDate?: number | string | Date | DateObject;
  dateFormat?: string;
  onDateChange: (date: Date) => void;
  errorMessage?: string;
}

export default function DatePickerSingle({
  dateValue,
  onDateChange,
  minDate,
  maxDate,
  dateFormat = DATE_PICKER_DATE_FORMAT,
  className,
  id,
  disabled,
  errorMessage,
}: Props) {
  const datePickerRef = useRef<DatePickerRef>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        datePickerRef.current?.closeCalendar();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <div
        id={id}
        className={clsxMerge(
          'flex w-full flex-row flex-nowrap items-center gap-x-3 rounded-md border border-neutral-300 bg-neutral-50 px-3 py-2',
          className,
        )}
      >
        <DatePicker
          disabled={disabled}
          ref={datePickerRef}
          value={dateValue}
          format={dateFormat}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date, { input, isTyping }) => {
            const dateObj = date as DateObject;
            if (!isTyping) {
              return onDateChange(dateObj.toDate());
            }

            const inputEl = input as HTMLInputElement;

            const strings = inputEl.value.split('/');
            const numbers = strings.map(Number);
            const [year, month, day] = numbers;

            if (inputEl.value && numbers.some((number) => isNaN(number))) {
              return false;
            }

            if (month > 12 || month < 0) {
              return false;
            }

            if (dateObj && 'day' in dateObj && (day < 0 || day > dateObj.day)) {
              return false;
            }
            if (strings.some((val) => val.startsWith('00'))) {
              return false;
            }

            onDateChange(dateObj.toDate());
          }}
          containerClassName="w-full"
          inputClass="border-none text-sm text-neutral-600 h-auto w-full p-0 box-border outline-none focus:outline-none focus:ring-0"
        />
        <CalendarIcon
          width={20}
          height={20}
          className="text-neutral-400"
          onClick={() => {
            datePickerRef?.current?.openCalendar?.();
          }}
        />
      </div>
      {errorMessage && <div className="mt-1 text-sm text-red-600">{errorMessage}</div>}
    </div>
  );
}
