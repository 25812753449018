import { HTMLProps } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';

import { Button, clsxMerge } from '@mwell-healthhub/commons';
import useIsMobile from '@mwell-healthhub/commons/hooks/useIsMobile';

type Props = HTMLProps<HTMLButtonElement> & {
  hmoFiltersCount?: number;
  onClick: () => void;
};

export const HmoFilterButton = ({ hmoFiltersCount, onClick, className }: Props) => {
  const isMobile = useIsMobile();

  const hasHmoFilters = isMobile ? (
    <span className="flex items-center gap-x-1">
      HMO
      <span className="ms-2 inline-flex h-full w-full min-w-[24px] items-center justify-center rounded-full bg-primary-500 font-normal text-neutral-50">
        {hmoFiltersCount}
      </span>
    </span>
  ) : (
    <span className="flex items-center gap-x-1">
      HMO
      <span className="ms-2 inline-flex h-full w-full min-w-[24px] items-center justify-center rounded-full bg-primary-500 font-normal text-neutral-50">
        {hmoFiltersCount}
      </span>
    </span>
  );

  const noHmoFilters = isMobile ? <span className="text-neutral-500 ">HMO</span> : <span>HMO</span>;
  return (
    <Button
      variant="secondaryGray"
      className={clsxMerge(
        isMobile
          ? 'w-fit whitespace-nowrap rounded-full border-neutral-500 py-2 pl-4 pr-3 font-normal text-neutral-400 md:h-full md:w-auto md:items-center md:gap-x-4 md:rounded-lg md:px-4 md:py-3.5 md:text-base'
          : 'w-fit whitespace-nowrap rounded-full py-2 pl-4 pr-3 font-normal text-neutral-400 md:h-full md:w-auto md:items-center md:gap-x-4 md:rounded-lg md:px-4 md:py-3.5 md:text-base',
        {
          'border-primary-500 text-primary-500': !!hmoFiltersCount,
        },
        className,
      )}
      onClick={onClick}
    >
      {hmoFiltersCount ? hasHmoFilters : noHmoFilters}
      {!hmoFiltersCount && <AdjustmentsHorizontalIcon className="ml-1 h-5 w-5" />}
    </Button>
  );
};

export default HmoFilterButton;
