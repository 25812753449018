export enum ExternalPaymentStatusEnum {
  PENDING_TOKEN = 'PENDING_TOKEN',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  FOR_AUTHENTICATION = 'FOR_AUTHENTICATION',
  AUTHENTICATING = 'AUTHENTICATING',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_FAILED = 'AUTH_FAILED',
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  VOIDED = 'VOIDED',
  REFUNDED = 'REFUNDED',
}
