import { createApiClient, setTraceId } from '@healthhub/api-client';
import { AxiosError, AxiosRequestConfig } from 'axios';

const removeTrailingSlash = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url);

export const API_BASE_URL = removeTrailingSlash(
  process.env.NEXT_PUBLIC_API_BASE_URL ??
    process.env.HEALTHHUB_API_BASE_URL ??
    'http://localhost:3333',
);

export function interceptErrorResponse(error: AxiosError) {
  console.error(error);

  return Promise.reject(error);
}

function interceptAuthRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  // By default all cookies will be included in the request
  config.withCredentials = true;

  setTraceId(config);

  return config;
}

const HealthHubApiClient = createApiClient(API_BASE_URL || '', interceptAuthRequest);

export default HealthHubApiClient;
