import * as yup from 'yup';

import { passwordSchema } from './password';
import { alphaNumericCharactersAndRequiredValidation, multiSelectSchema } from './provider-profile';
import { ALPHANUMERIC_NAME_PATTERN, REQUIRED_FIELD } from '../constants';
import { getMobilePhoneNumberValidationRule, mobilePhoneNumberSchema } from '../utils/phoneNumber';

export type FirstStepFormDataType = yup.InferType<typeof firstStepSchema>;
export type SecondStepFormDataType = yup.InferType<typeof secondStepSchema>;
export type ThirdStepFormDataType = yup.InferType<typeof thirdStepSchema>;

export const firstStepSchema = yup.object().shape({
  name: yup
    .string()
    .matches(ALPHANUMERIC_NAME_PATTERN, 'Invalid name format.')
    .required(REQUIRED_FIELD),
  providerTypes: yup
    .array()
    .of(multiSelectSchema)
    .min(1, 'At least one provider type must be selected')
    .required('At least one provider type must be selected'),
  address: yup
    .string()
    .matches(ALPHANUMERIC_NAME_PATTERN, 'Invalid address format.')
    .required(REQUIRED_FIELD),
  title: yup
    .string()
    .matches(ALPHANUMERIC_NAME_PATTERN, 'Invalid role format.')
    .required(REQUIRED_FIELD),
});

export const secondStepSchema = yup.object().shape({
  firstName: alphaNumericCharactersAndRequiredValidation,
  lastName: alphaNumericCharactersAndRequiredValidation,
  countryCode: yup.string().required(''),
  mobileNumber: mobilePhoneNumberSchema
    .required()
    .when('countryCode', (countryCode) => getMobilePhoneNumberValidationRule(countryCode[0])),
});

export const thirdStepSchema = yup.object().shape(
  {
    isEditing: yup.boolean(),
    email: yup.string().email('Invalid email address').required(REQUIRED_FIELD),
    ...passwordSchema,
  },
  [['password', 'password']],
);
