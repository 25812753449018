import {
  CreateProviderDirectoryDto,
  Provider,
  ProviderDirectoryApi,
  UpdateProviderDirectoryDto,
} from '@healthhub/api-lib';
import { AxiosError, AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { ProviderOnboardingStatusEnum } from '../enums/provider-status';
import { Pagination } from '../types';

export const providerDirectoryApi = HealthHubApiClient.use(ProviderDirectoryApi);

export type GetManyProviderDirectoryParams = {
  latitude?: number;
  limit?: number;
  longitude?: number;
  page?: number;
  providerTypeId?: string;
  searchQuery?: string;
  status?: ProviderOnboardingStatusEnum;
};

export async function getProviderDirectories(params: GetManyProviderDirectoryParams) {
  try {
    const { data } = await providerDirectoryApi.providerDirectoryControllerFindMany(
      params.searchQuery,
      params.providerTypeId,
      params.longitude,
      params.latitude,
      params.status,
      params.limit,
      params.page,
    );

    return data as Pagination<Provider>;
  } catch (err) {
    throw new Error('Failed to fetch provider directories');
  }
}

export async function getProviderDirectory(
  id: string,
  config?: AxiosRequestConfig,
): Promise<Provider> {
  try {
    const { data } = await providerDirectoryApi.providerDirectoryControllerFindOne(id, config);

    return data;
  } catch (err) {
    throw new Error('Failed to fetch provider directory');
  }
}

export async function approveProvider(id: string): Promise<void> {
  try {
    await providerDirectoryApi.providerDirectoryControllerApprove(id);
  } catch (err) {
    throw new Error('Failed to approve provider');
  }
}

export async function deleteProvider(id: string): Promise<void> {
  try {
    await providerDirectoryApi.providerDirectoryControllerDelete(id);
  } catch (err) {
    throw new Error('Failed to delete provider');
  }
}

export async function updateProviderDirectory(
  id: string,
  dto: UpdateProviderDirectoryDto,
): Promise<Provider> {
  try {
    const { data } = await providerDirectoryApi.providerDirectoryControllerUpdate(id, dto);

    return data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw error.response?.data.message;
    }
    throw new Error('Failed to update provider directory');
  }
}

export async function createProviderDirectory(dto: CreateProviderDirectoryDto): Promise<Provider> {
  try {
    const { data } = await providerDirectoryApi.providerDirectoryControllerCreate(dto);

    return data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw error.response?.data.message;
    }
    throw new Error('Failed to create provider directory');
  }
}
