export type BreadcrumbType = {
  name: string;
  path: string;
};

export enum AppointmentListFilter {
  All = 'all',
  Confirmed = 'confirmed',
  OnGoing = 'ongoing',
  Finished = 'finished',
  Physical = 'physical',
  TeleConsultation = 'tele-consult',
  Cancelled = 'cancelled',
  Past_appointments = 'Past Appointments',
}

export type TabType = {
  label: string;
  value: any;
  count?: number;
  content?: React.ReactElement;
  isHidden?: boolean;
  isDisabled?: boolean;
};

export type ScheduleType = Record<string, string[]>;
