/* eslint-disable @next/next/no-img-element */
import { User } from 'iconoir-react';

import { Avatar, SizeVariant } from '@mwell-healthhub/commons';

const IMAGE_SIZE = {
  sm: 50,
  md: 64,
  lg: 100,
};

//Sizes should be specified instead of doing it like this h-[${imageSize}px] w-[${imageSize}px]
//Because the classes do not reflect and it make the image shrink
const IMAGE_CLASS = {
  sm: 'h-[50px] w-[50px]',
  md: 'h-[64px] w-[64px]',
  lg: 'h-[100px] w-[100px]',
};

type Props = {
  imageUrl?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
};

const DoctorImage = (props: Props) => {
  const { imageUrl, size = 'md', className } = props;
  const imageSize = IMAGE_SIZE[size];

  return imageUrl ? (
    <img
      className={`${IMAGE_CLASS[size]} flex-shrink-0 rounded-full bg-gray-300 ${className} object-cover object-top`}
      src={imageUrl}
      alt="image"
      width={imageSize}
      height={imageSize}
    />
  ) : (
    <Avatar size={size as SizeVariant}>
      <User className={`h-10 w-10 ${className}`} />
    </Avatar>
  );
};

export default DoctorImage;
