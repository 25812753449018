import React from 'react';

import { TabType } from '../../types';
import { classNames } from '../../utils';

interface Props {
  tab: TabType;
  isActive: boolean;
  onClick: () => void;
}

const TabWithBackgroundItem: React.FC<Props> = (props: Props) => {
  const { tab, isActive, onClick } = props;
  const { label, count } = tab;

  return (
    <button
      className={classNames(
        isActive ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
        'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
      )}
      onClick={onClick}
    >
      <span>{label}</span>
      {count ? (
        <span
          className={classNames(
            isActive ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
            'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
          )}
        >
          {count}
        </span>
      ) : null}
    </button>
  );
};

export default TabWithBackgroundItem;
