import get from 'lodash.get';

import { AutoCompleteOptionType, Option } from '../types';

export function convertStringsToOptions(strings: string[]): Option[] {
  const options: Option[] = [
    {
      value: 0,
      label: 'None',
    },
  ];

  for (const str of strings) {
    options.push({
      value: str,
      label: str,
    });
  }

  return options;
}

export function convertObjectsToOptions(
  objects: { name: string; id: string | number }[],
  { includeNone = true, noneCaption = 'None' } = {},
): Option[] {
  const options: Option[] = [];

  if (includeNone) {
    options.push({
      value: 0,
      label: noneCaption,
    });
  }

  for (const obj of objects) {
    options.push({
      value: obj.id,
      label: obj.name,
    });
  }

  return options;
}

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function filterAutoCompleteOptions(
  list: AutoCompleteOptionType[],
  searchTerm?: string,
): AutoCompleteOptionType[] {
  if (searchTerm && searchTerm.length) {
    const escapedSearchTerm = escapeRegExp(searchTerm);
    const regex = new RegExp(escapedSearchTerm, 'i');
    return list.filter((item: AutoCompleteOptionType) => {
      return regex.test(item.text);
    });
  }
  return list;
}

export function generateAutoCompleteOptions(
  list: Record<string, any>[],
  { textKey = 'name', contentKey = 'name', idKey = 'id' },
): AutoCompleteOptionType[] {
  return list.map((item: Record<string, any>) => ({
    content: get(item, contentKey),
    id: get(item, idKey),
    text: get(item, textKey),
  }));
}
