import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Region } from '@healthhub/api-lib';
import { Control, Controller, FieldValues, useFormContext } from 'react-hook-form';

import { NCR_PROVINCE, OTHERS_LABEL, OTHERS_VALUE, metroManilaCities } from '../../constants';
import { AddressFormEnum } from '../../enums';
import {
  useGetAllRegions,
  useGetPSGCBarangaysByCitiesMunicipalities,
  useGetPSGCCitiesMunicipalitiesByProvince,
  useGetPSGCCitiesMunicipalitiesByRegion,
  useGetPSGCProvincesByRegion,
} from '../../hooks';
import { AutoCompleteOptionType, Option } from '../../types';
import { escapeRegExp, sortByKey } from '../../utils';
import AutoComplete from '../AutoComplete';
import Button from '../Button';
import Dropdown from '../Dropdown';
import RegionDropdown from '../RegionDropdown';
import TextInput from '../TextInput';
import TextInputController from '../TextInputController';

const NCRRegionId = 14;
const metroManilaProvice = 'Metro Manila';

export type AddressFormType = {
  barangay: string;
  coordinates: string;
  country: string;
  googleMapsUrl?: string;
  line: string;
  municipalityCity: string;
  otherBarangay?: string;
  otherMunicipalityCity?: string;
  province: string;
  region: number;
};

type Address = {
  address: AddressFormType;
};

type Props = {
  isViewOnly?: boolean;
  showCoordinates?: boolean;
};

function AddressFormFields(props: Props) {
  const { showCoordinates = false, isViewOnly = false } = props;
  const sortKey = 'label';
  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<Address>();
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);

  const selectedRegionId = watch(`address.${AddressFormEnum.REGION}`);
  const selectedProvinceLabel = watch(`address.${AddressFormEnum.PROVINCE}`);
  const selectedCityMunicipalityLabel = watch(`address.${AddressFormEnum.MUNICIPALITY_CITY}`);
  const selectedBarangayLabel = watch(`address.${AddressFormEnum.BARANGAY}`);
  const selectedCountryLabel = watch(`address.${AddressFormEnum.COUNTRY}`);

  const [provinceSearchTerm, setProvinceSearchTerm] = useState<string>('');
  const [citySearchTerm, setCitySearchTerm] = useState<string>('');
  const [barangaySearchTerm, setBarangaySearchTerm] = useState<string>('');

  const countryOptions: Option[] = [{ label: 'Philippines', value: 'PH' }];
  const selectedCountry = countryOptions.find(({ label }) => label === selectedCountryLabel);

  const { data: regions = [] } = useGetAllRegions();
  const selectedRegion = regions.find(({ id }) => id === selectedRegionId) as Region;

  const { data: provincesData = [], isFetching: isFetchingProvinces } = useGetPSGCProvincesByRegion(
    selectedRegion?.code as string,
  );
  const provinces = selectedRegionId === NCRRegionId ? [NCR_PROVINCE] : provincesData;
  const provinceOptions: AutoCompleteOptionType[] = sortByKey(
    provinces.map((province) => ({
      content: <span>{province.name}</span>,
      text: province.name,
      id: province.code,
    })),
    sortKey,
  );

  const selectedProvince = provinceOptions.find(({ text }) => text === selectedProvinceLabel);
  const isProvinceMetroManila = selectedProvinceLabel === metroManilaProvice;
  const selectedProvinceId: string = selectedProvince?.id || '';
  const {
    data: citiesMunicipalitiesByProvince = [],
    isFetching: isFetchingCitiesMunicipalitiesByProvince,
  } = useGetPSGCCitiesMunicipalitiesByProvince(!isProvinceMetroManila ? selectedProvinceId : '');

  const {
    data: citiesMunicipalitiesByRegion = [],
    isFetching: isFetchingCitiesMunicipalitiesByRegion,
  } = useGetPSGCCitiesMunicipalitiesByRegion(!isProvinceMetroManila ? selectedProvinceId : '');

  const citiesMunicipalities =
    selectedProvince?.id === NCR_PROVINCE.code
      ? citiesMunicipalitiesByRegion
      : citiesMunicipalitiesByProvince;

  const metroManilaCityOptions: AutoCompleteOptionType[] = sortByKey(
    metroManilaCities.map((city) => ({
      content: <span>{city.name}</span>,
      text: city.name,
      id: city.code,
    })),
    sortKey,
  );

  const citiesMunicipalitiesOptions: AutoCompleteOptionType[] = sortByKey(
    citiesMunicipalities.map((cityMunicipality) => ({
      content: <span>{cityMunicipality.name}</span>,
      text: cityMunicipality.name,
      id: cityMunicipality.code,
    })),
    sortKey,
  );

  const cityOptions = isProvinceMetroManila ? metroManilaCityOptions : citiesMunicipalitiesOptions;
  const selectedCity = cityOptions.find(({ text }) => text === selectedCityMunicipalityLabel);

  const { data: barangays = [], isFetching: isFetchingBarangays } =
    useGetPSGCBarangaysByCitiesMunicipalities(selectedCity?.id || '');

  const barangaysWithOthers = [...barangays, { code: OTHERS_VALUE, name: OTHERS_LABEL }];

  const barangayOptions: AutoCompleteOptionType[] = sortByKey(
    barangaysWithOthers.map((barangay) => ({
      content: <span>{barangay.name}</span>,
      text: barangay.name,
      id: barangay.code,
    })),
    sortKey,
  );

  const selectedBarangay = barangayOptions.find(({ text }) => text === selectedBarangayLabel);

  const isFetchingCitiesAndMunicipalities =
    isFetchingCitiesMunicipalitiesByProvince || isFetchingCitiesMunicipalitiesByRegion;

  const isFetchAddressData =
    isFetchingCitiesAndMunicipalities ||
    isFetchingBarangays ||
    isFetchingCitiesMunicipalitiesByProvince ||
    isFetchingProvinces;

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpenTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOnRegionChange =
    (onChange: (...event: unknown[]) => void) => (value?: number | undefined) => {
      onChange(value);
      setValue(`address.${AddressFormEnum.PROVINCE}`, '');
      setValue(`address.${AddressFormEnum.MUNICIPALITY_CITY}`, '');
      setValue(`address.${AddressFormEnum.BARANGAY}`, '');
    };

  const handleOnProvinceChange =
    (onChange: (...event: unknown[]) => void) => (option: AutoCompleteOptionType) => {
      onChange(option.text);

      setValue(`address.${AddressFormEnum.MUNICIPALITY_CITY}`, '');
      setValue(`address.${AddressFormEnum.BARANGAY}`, '');
    };

  const handleOnCityChange =
    (onChange: (...event: unknown[]) => void) => (option: AutoCompleteOptionType) => {
      onChange(option.text);
      setValue(`address.${AddressFormEnum.OTHER_MUNICIPALITY_CITY}`, '');
      setValue(`address.${AddressFormEnum.BARANGAY}`, '');
      setValue(`address.${AddressFormEnum.OTHER_BARANGAY}`, '');
    };

  const handleOnCityTextChange =
    (onChange: (...event: unknown[]) => void) => (option: ChangeEvent<HTMLInputElement>) => {
      onChange(option.target.value);
      setValue(`address.${AddressFormEnum.MUNICIPALITY_CITY}`, OTHERS_LABEL);
    };

  const handleOnBarangayChange =
    (onChange: (...event: unknown[]) => void) => (option: AutoCompleteOptionType) => {
      onChange(option.text);
      setValue(`address.${AddressFormEnum.OTHER_BARANGAY}`, '');
    };

  const handleOnBarangayTextChange =
    (onChange: (...event: unknown[]) => void) => (option: ChangeEvent<HTMLInputElement>) => {
      onChange(option.target.value);
      setValue(`address.${AddressFormEnum.BARANGAY}`, OTHERS_LABEL);
    };

  const getCityInitialValue = () => {
    if (selectedCity) {
      return selectedCity.id;
    }

    return !!selectedCityMunicipalityLabel && isProvinceMetroManila ? OTHERS_VALUE : undefined;
  };

  const getBarangayInitialValue = () => {
    if (selectedBarangay) {
      return selectedBarangay.id;
    }

    return !!selectedBarangayLabel && isProvinceMetroManila ? OTHERS_VALUE : undefined;
  };

  const canAddOtherCityMunicipality =
    (!selectedCity?.id || selectedCity?.id === OTHERS_VALUE) && selectedCityMunicipalityLabel;
  const canAddOtherBarangay =
    (!selectedBarangay?.id || selectedBarangay?.id === OTHERS_VALUE) && selectedBarangayLabel;

  function getOptionList(
    list: AutoCompleteOptionType[],
    searchTerm?: string,
  ): AutoCompleteOptionType[] {
    if (searchTerm && searchTerm.length) {
      const escapedSearchTerm = escapeRegExp(searchTerm);
      const regex = new RegExp(escapedSearchTerm, 'i');
      return list.filter((item: AutoCompleteOptionType) => {
        return regex.test(item.text);
      });
    }
    return list;
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:gap-6 lg:grid-cols-12">
        <TextInputController
          control={control as unknown as Control<FieldValues>}
          name={`address.${AddressFormEnum.LINE}`}
          label="Address Line"
          placeholder="Enter unit/room/floor number, building/lot/block/phase/house and street name"
          containerClassName="lg:col-span-12"
          labelClassName="text-sm leading-6"
          textInputClassName="mt-1.5 mb-0"
          isDisabled={isViewOnly}
        />
        <Controller
          control={control}
          defaultValue={countryOptions[0].value}
          name={`address.${AddressFormEnum.COUNTRY}`}
          render={({ field: { onChange } }) => (
            <Dropdown
              hasLeftRoundedBorders
              hasRightRoundedBorders
              showRequiredOnLabel={!isViewOnly}
              disabled={isViewOnly}
              errorMessage={errors?.address?.country?.message}
              label="Country"
              options={countryOptions}
              placeholder="Select Country"
              value={selectedCountry}
              onChange={(e) => onChange(e.label)}
              containerClassname="lg:col-span-6"
              labelClassName="mb-1.5"
            />
          )}
        />
        <Controller
          control={control}
          name={`address.${AddressFormEnum.REGION}`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="flex flex-col gap-1 lg:col-span-6">
              <RegionDropdown
                height="md"
                labelShown
                showRequiredOnLabel={!isViewOnly}
                disabled={isViewOnly}
                value={value}
                onChange={handleOnRegionChange(onChange)}
              />
              {error && <p className="text-sm text-red-500">This field is required</p>}
            </div>
          )}
        />
        <Controller
          control={control}
          name={`address.${AddressFormEnum.PROVINCE}`}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <div className="flex flex-col lg:col-span-4">
              <AutoComplete
                label="Province"
                isFetching={isFetchAddressData}
                showSkeletonLoader
                showRequiredOnLabel={!isViewOnly}
                initialValue={selectedProvince?.id}
                onChangeText={(query) => setProvinceSearchTerm(query)}
                onSelectedItem={handleOnProvinceChange(onChange)}
                options={getOptionList(provinceOptions, provinceSearchTerm)}
                placeholder="Search Province"
                disabled={!selectedRegionId || isViewOnly}
                errorMessage={error?.message}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name={`address.${AddressFormEnum.MUNICIPALITY_CITY}`}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <div className="flex flex-col lg:col-span-4">
              <AutoComplete
                label="City"
                isFetching={isFetchAddressData}
                showSkeletonLoader
                showRequiredOnLabel={!isViewOnly}
                initialValue={getCityInitialValue()}
                onChangeText={(query) => setCitySearchTerm(query)}
                onSelectedItem={handleOnCityChange(onChange)}
                options={getOptionList(cityOptions, citySearchTerm)}
                placeholder="Search City"
                disabled={!selectedProvinceId || isViewOnly}
                errorMessage={error?.message}
              />
              {canAddOtherCityMunicipality && (
                <Controller
                  control={control}
                  defaultValue={selectedCityMunicipalityLabel}
                  rules={{
                    required: 'This field is required',
                  }}
                  name={`address.${AddressFormEnum.OTHER_MUNICIPALITY_CITY}`}
                  render={({ field: { value, onChange }, fieldState: { error: errorOther } }) => (
                    <TextInput
                      className="mt-2"
                      placeholder="Enter City"
                      type="text"
                      value={value}
                      disabled={!selectedProvinceId || isViewOnly}
                      errorMessage={errorOther?.message}
                      onChange={handleOnCityTextChange(onChange)}
                    />
                  )}
                />
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name={`address.${AddressFormEnum.BARANGAY}`}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <div className="flex flex-col lg:col-span-4">
              <AutoComplete
                label="Barangay"
                isFetching={isFetchAddressData}
                showSkeletonLoader
                initialValue={getBarangayInitialValue()}
                onChangeText={(query) => setBarangaySearchTerm(query)}
                onSelectedItem={handleOnBarangayChange(onChange)}
                options={getOptionList(barangayOptions, barangaySearchTerm)}
                placeholder="Search Barangay"
                disabled={!selectedCityMunicipalityLabel || isViewOnly}
                errorMessage={error?.message}
              />
              {canAddOtherBarangay && (
                <Controller
                  control={control}
                  name={`address.${AddressFormEnum.OTHER_BARANGAY}`}
                  defaultValue={selectedBarangayLabel}
                  render={({ field: { value, onChange }, fieldState: { error: errorOther } }) => (
                    <TextInput
                      className="mt-2"
                      placeholder="Enter Barangay"
                      type="text"
                      value={value}
                      disabled={!selectedCityMunicipalityLabel || isViewOnly}
                      errorMessage={errorOther?.message}
                      onChange={handleOnBarangayTextChange(onChange)}
                    />
                  )}
                />
              )}
            </div>
          )}
        />
      </div>
      {showCoordinates && (
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-4 gap-5 md:grid-cols-12">
            <TextInput
              labelShown
              labelClassName="text-sm leading-6"
              textInputClassName="mt-1.5 mb-0"
              {...register(`address.${AddressFormEnum.COORDINATES}`)}
              containerClassName="col-span-4 md:col-span-4"
              errorMessage={errors?.address?.coordinates?.message}
              label="Latitude & Longitude"
              placeholder="0.0000, 0.0000"
              type="text"
              disabled={isViewOnly}
            />
            <div className="relative col-span-4 flex w-full items-end md:col-span-8">
              <span
                onClick={() => setIsOpenTooltip(true)}
                className="cursor-pointer pb-4 text-[12px] font-medium text-neutral-500 underline underline-offset-2"
              >
                How to find Latitude & Longitude?
              </span>
              {isOpenTooltip && (
                <div
                  ref={wrapperRef}
                  className="absolute top-10 z-10 w-72 scale-100 rounded-md bg-neutral-500 p-4 text-xs text-white drop-shadow-md group-hover:scale-100 lg:top-20"
                >
                  <div className="flex flex-col space-y-4">
                    <p>This will help your clients find your clinic easily.</p>
                    <p className="font-bold">How to get your latitude and longitude: </p>
                    <div className="flex flex-col space-y-1">
                      <span>1. Open Google Maps.</span>
                      <span>2. Find your clinic.</span>
                      <span>
                        3. Right-click (on desktop) or tap and hold (on mobile) on the the exact
                        location on the map to see the coordinates, example: 14.5897, 121.0676
                        (latitude, longitude).
                      </span>
                    </div>
                    <div className="w-20">
                      <Button
                        variant="outlined"
                        className="whitespace-nowrap"
                        onClick={() => setIsOpenTooltip(false)}
                      >
                        <span className="text-primary-500">Got it!</span>
                      </Button>
                    </div>
                  </div>
                  <div className="absolute -top-1 left-32 flex h-3 w-3 rotate-45 flex-col space-y-4 bg-neutral-500" />
                </div>
              )}
            </div>
          </div>
          <div>
            <TextInput
              labelShown
              labelClassName="text-sm leading-6"
              textInputClassName="mt-1.5 mb-0"
              {...register(`address.${AddressFormEnum.GOOGLE_MAPS_URL}`)}
              containerClassName="col-span-4 md:col-span-4"
              errorMessage={errors?.address?.googleMapsUrl?.message}
              label="Google Maps URL"
              additionalLabel="Google Maps URL will allow your provider to be discoverable via Maps."
              additionalLabelClassName="relative -top-1.5"
              placeholder="https://goo.gl/maps/..."
              type="text"
              disabled={isViewOnly}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AddressFormFields;
