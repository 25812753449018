import { Fragment, useState } from 'react';

import { format } from 'date-fns';
import { DateObject } from 'react-multi-date-picker';

import CustomDatePicker from './DatePicker';
import LoadingSpinner from './LoadingSpinner';
import NoFeedBack from './NoFeedBack';
import ReviewAndRatingItem from './ReviewAndRatingItem';
import { FULL_DATE_FORMAT } from '../constants';
import { Routes } from '../enums';
import { useFindMultipleFeedback, useRouter } from '../hooks';

type Props = {
  branchId: string;
  userId?: string;
};

export default function ReviewAndRatingList(props: Props) {
  const { branchId, userId } = props;

  const router = useRouter();

  const [dateRange, setDateRange] = useState<DateObject[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const { data: multipleFeedback, isLoading } = useFindMultipleFeedback(
    branchId,
    userId,
    startDate,
    endDate,
  );

  const handleClearDateRange = () => {
    setDateRange([]);
    setStartDate(undefined);
    setEndDate(undefined);
    handleSubmitDate([]);
  };

  const handleChangeDate = (date: DateObject[]) => {
    setDateRange(date);
    handleSubmitDate(date);

    if (date === null) {
      handleClearDateRange();
    }
  };

  const handleSubmitDate = (date: DateObject[]) => {
    if (!!date?.[0]?.format().length && !!date?.[1]?.format().length) {
      setDateRange(date);
      setStartDate(date[0].format());
      setEndDate(date[1].format());
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex h-96 w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      );
    }

    if (!multipleFeedback || multipleFeedback.length === 0) {
      return <NoFeedBack />;
    }

    return multipleFeedback.map((feedback, index) => {
      const { appointment, createdAt, rating, comment } = feedback;

      const handleViewAppointment = () => {
        router.push(`${Routes.APPOINTMENTS}/${appointment?.id}`);
      };

      const date = format(new Date(createdAt), FULL_DATE_FORMAT);

      return (
        <Fragment key={index}>
          <ReviewAndRatingItem
            isShowViewAppointment
            appointmentDetails={appointment}
            date={date}
            reviewDetails={comment ?? ''}
            reviewStatus={rating?.toString() ?? ''}
            onViewAppointment={handleViewAppointment}
          />
        </Fragment>
      );
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-end">
        <CustomDatePicker
          value={dateRange}
          handleOnChange={handleChangeDate}
          onClear={handleClearDateRange}
          onSubmit={handleSubmitDate}
          placeholder="Sort by date"
          isCalendarIcon
          iconColor="neutral"
          iconSize="md"
          className="w-[300px]"
        />
      </div>
      <div className="inline-flex flex-col items-start justify-start overflow-hidden rounded-lg border border-zinc-200 bg-white">
        <div className="inline-flex h-[76px] items-center justify-start self-stretch overflow-hidden border-b border-zinc-200 bg-white py-2">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start px-5 py-4">
            <div className="self-stretch font-['Poppins'] text-lg font-semibold leading-7 text-neutral-900">
              Reviews & Ratings
            </div>
          </div>
        </div>
        <div className="w-full divide-y divide-neutral-300 overflow-hidden">{renderContent()}</div>
      </div>
    </div>
  );
}
