import { RefObject, useRef, useState } from 'react';

import { PhotoIcon } from '@heroicons/react/24/solid';
import { Controller, useFormContext } from 'react-hook-form';
import { FaSpinner } from 'react-icons/fa';

import { uploadFile } from '../../api';
import { FILE_EXCEEDS_10MB_MESSAGE, PAYMENT_QR_CODE_PATH } from '../../constants';
import { ProviderFormEnum } from '../../enums';
import { checkImageSizeExceeds } from '../../utils';
import Button from '../Button';
import FileUpload from '../FileUpload';

function PaymentQrCodeFormField() {
  const { control, setError, clearErrors } = useFormContext<{
    [ProviderFormEnum.PAYMENT_QR_CODE_URL]: string;
  }>();

  const [isUpdatingImage, setIsUpdatingImage] = useState<boolean>(false);
  const uploadQrCodeRef = useRef<HTMLInputElement>(null);

  const handlePhotoChange = (onChange: (val: string) => void) => async (file: File[]) => {
    if (!file[0]) {
      return;
    }

    if (checkImageSizeExceeds(file[0].size)) {
      setError(ProviderFormEnum.PAYMENT_QR_CODE_URL, {
        type: 'max',
        message: FILE_EXCEEDS_10MB_MESSAGE,
      });

      return;
    }

    clearErrors(ProviderFormEnum.PAYMENT_QR_CODE_URL);
    setIsUpdatingImage(true);
    const loadUrl = await uploadFile(file[0], PAYMENT_QR_CODE_PATH + '/');

    setIsUpdatingImage(false);
    onChange(loadUrl.url);
  };

  const handleChangeImage = (inputRef: RefObject<HTMLInputElement>) => () => {
    inputRef?.current?.click();
  };

  const loader = (
    <div className="flex h-[80px] w-[80px] items-center justify-center">
      <FaSpinner className="h-10 w-10 animate-spin self-center text-neutral-500" />
    </div>
  );

  const paymentQrCodeDisplay = isUpdatingImage ? (
    loader
  ) : (
    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
  );

  return (
    <Controller
      name={ProviderFormEnum.PAYMENT_QR_CODE_URL}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="relative flex w-full flex-col">
          <div className="flex space-x-1">
            <label htmlFor="photo" className="block text-sm leading-6 text-gray-900">
              QR Code
            </label>
          </div>
          {value ? (
            isUpdatingImage ? (
              loader
            ) : (
              <div className="relative w-full">
                <img
                  className="mb-5 mt-5 block h-[600px] w-full items-center justify-center rounded-lg bg-gray-300 object-contain object-center md:mb-0 md:h-[600px]"
                  src={value}
                  height={1200}
                  width={450}
                  alt="paymentQrCode"
                />
                <div className="bottom-8 left-5 flex items-center space-x-2 self-end md:absolute">
                  <FileUpload
                    inputRef={uploadQrCodeRef}
                    label="Upload paymentQrCode"
                    labelClassName="sm:text-left text-primary4 font-medium text-[15px] hover:none hidden"
                    onFileChange={handlePhotoChange(onChange)}
                  />
                  <Button onClick={handleChangeImage(uploadQrCodeRef)} variant="cart">
                    Update
                  </Button>
                  <Button onClick={() => onChange('')} variant="error">
                    Remove
                  </Button>
                </div>
              </div>
            )
          ) : (
            <div className="w-full">
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="flex flex-col items-center justify-center">
                  {paymentQrCodeDisplay}
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="text-indigo-600 focus-within:ring-indigo-600 hover:text-indigo-500 relative cursor-pointer rounded-md bg-white font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
                    >
                      <FileUpload
                        label="Click here to Upload QR Code"
                        labelClassName="rounded-md border border-primary-500 bg-white px-3 py-1 text-sm mb-2 font-normal text-primary-500 hover:bg-gray24 disabled:border-gray24 disabled:bg-gray11 disabled:text-gray9 sm:!ml-0 sm:text-left font-medium text-[15px] hover:none"
                        onFileChange={handlePhotoChange(onChange)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
        </div>
      )}
    />
  );
}

export default PaymentQrCodeFormField;
