import { Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import {
  Button,
  Checkbox,
  clsxMerge,
  Modal,
  MultiSelectOptionType,
  toast,
  useDebounce,
} from '@mwell-healthhub/commons';
import { ChangeEvent, useState } from 'react';
import NavigationTitle from './NavigationTitle';

type Props = {
  fieldName: string;
  minSelections?: number;
  disabledText?: string;
  options: MultiSelectOptionType[];
  selectedOptions: MultiSelectOptionType[];
  modalState: boolean;
  title: string;
  onToggleModalState: () => void;
  onBack: () => void;
  onRemove: (updatedSelections: MultiSelectOptionType[]) => void;
  onSelect: (updatedSelections: MultiSelectOptionType[]) => void;
  onSubmit?: () => void;
  onClearAll?: () => void;
  onChangeText?: (search: string) => void;
};

const MultiSelectDropdownMobile = ({
  minSelections,
  disabledText = '',
  fieldName,
  options,
  selectedOptions,
  modalState,
  title,
  onBack,
  onToggleModalState,
  onRemove,
  onSelect,
  onSubmit,
  onClearAll,
  onChangeText,
}: Props) => {
  const [searchText, setSearchText] = useState('');
  const search = (query: string) => {
    onChangeText?.(query);
  };

  const debouncedSearch = useDebounce(search, 300);
  const handleOnChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
    setSearchText(event.target.value);
  };

  const filteredOptions = options?.filter(
    (option) => option.text?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const groupOptionsByOptGroup = (
    options: MultiSelectOptionType[],
  ): { [key: string]: MultiSelectOptionType[] } => {
    const groupedOptions: { [key: string]: MultiSelectOptionType[] } = {};
    // Group the options by their optGroup property if it exists
    options.forEach((option) => {
      const optGroup = option.optGroup || 'default';
      if (!groupedOptions[optGroup]) {
        groupedOptions[optGroup] = [];
      }
      groupedOptions[optGroup].push(option);
    });
    return groupedOptions;
  };
  const groupedOptions = groupOptionsByOptGroup(filteredOptions);
  const renderFilteredOptions = Object.keys(groupedOptions).map((category) => {
    if (category !== 'default') {
      return (
        <div key={category}>
          <div className="px-4 py-2.5 text-xs font-semibold leading-none tracking-tight text-gray-400">
            {category}
          </div>
          {groupedOptions[category].map((option) => renderOption(option))}

          <hr className="my-2 text-neutral-300" />
        </div>
      );
    } else {
      return groupedOptions[category].map((option) => renderOption(option));
    }
  });

  const handleOptionClick = (option: MultiSelectOptionType) => {
    const isAlreadySelected = selectedOptions.some((selected) => selected.value === option.value);
    let updatedOptions: MultiSelectOptionType[];
    const isMinSelectionLimitReached =
      minSelections !== undefined && selectedOptions.length <= minSelections;

    if (isAlreadySelected) {
      if (isMinSelectionLimitReached) {
        toast({ message: `You must select at least ${minSelections} options`, type: 'error' });
        return;
      } else {
        updatedOptions = selectedOptions.filter((selected) => selected.value !== option.value);
      }
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    onSelect(updatedOptions);
    setSearchText('');
  };

  function getOptionClassName(option: MultiSelectOptionType) {
    return clsxMerge(
      'flex cursor-pointer items-center space-x-2 py-4 first:pt-0 last:pb-0 text-sm md:hover:bg-neutral-200',
      option.isDisabled && 'cursor-not-allowed',
    );
  }

  function getOptionContent(option: MultiSelectOptionType, disabledText: string) {
    if (option?.rightContent) {
      return (
        <div className="flex grow items-center">
          <span className={clsxMerge('grow', option.isDisabled && `text-gray-400`)}>
            {option?.text}
            {option.isDisabled && <small className="ml-1.5">{disabledText}</small>}
          </span>
          <span>{option?.rightContent}</span>
        </div>
      );
    } else {
      return (
        <span className={clsxMerge('grow', option.isDisabled && `text-gray-400`)}>
          {option.text}
          {option.isDisabled && <small className="ml-1.5">{disabledText}</small>}
        </span>
      );
    }
  }

  function renderOption(option: MultiSelectOptionType) {
    const isSelected = selectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    const handleSelect = option.isDisabled ? undefined : () => handleOptionClick(option);

    return (
      <div key={option.value} className={getOptionClassName(option)} onClick={handleSelect}>
        {getOptionContent(option, disabledText)}
        <Checkbox className="h-4 w-4 text-primary-500" checked={isSelected} />
      </div>
    );
  }
  return (
    <Transition show={modalState}>
      <div className="absolute left-0 top-0 z-50 flex h-full w-full flex-col overflow-hidden bg-neutral-50 md:hidden">
        <NavigationTitle title={title} onLeftIconClick={onBack} />
        <div className="flex flex-col gap-4 px-6 pt-5">
          <div className="relative">
            <button
              type="submit"
              className="focus:shadow-outline absolute inset-y-0 left-0 px-2 focus:outline-none"
            >
              <MagnifyingGlassIcon className="h-5 w-5 text-neutral-400" />
            </button>
            <input
              type="search"
              name={fieldName}
              className="w-full rounded-lg border border-solid border-neutral-300 px-3 py-2 pl-9 text-sm focus:border-primary-500"
              placeholder="Search"
              value={searchText}
              onChange={handleOnChangeText}
            />
          </div>
        </div>
        <div className="h-full grow basis-full divide-y divide-solid divide-neutral-300 overflow-y-auto px-6 py-5 pb-[80px]">
          {renderFilteredOptions}
        </div>
        <div className="fixed bottom-0 left-0 z-10 flex w-screen gap-x-3 border-t border-[#E9EAED] bg-neutral-50 px-6 pb-2.5 pt-5">
          <Button
            onClick={() => {
              onClearAll?.();
              if (onClearAll) {
                onToggleModalState();
              }
              onRemove([]);
            }}
            className="w-1/2"
            size="lg"
            variant="secondaryGray"
          >
            Clear All
          </Button>
          <Button
            onClick={() => {
              onSubmit?.();
              onToggleModalState();
            }}
            className="w-1/2"
            size="lg"
            variant="primary"
          >
            Done
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default MultiSelectDropdownMobile;
