import { useState } from 'react';

import { PaperClipIcon } from '@heroicons/react/24/solid';

import { uploadFile } from '../../api';
import { FileSizeLimitEnum, FileTypeExtensionEnum } from '../../constants';
import { truncateMiddle } from '../../utils';
import Button from '../Button';
import FileUpload from '../FileUpload';
import LoadingSpinner from '../LoadingSpinner';
import toast from '../toast';

type Props = {
  acceptedFileTypes: FileTypeExtensionEnum[];
  error?: string;
  filePath?: string;
  label: string;
  placeholder?: string;
  showRequiredOnLabel?: boolean;
  showLabel?: boolean;
  value?: string;
  onChange: (value: string) => void;
};

export default function AttachmentUploader(props: Readonly<Props>) {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const {
    acceptedFileTypes,
    error,
    filePath,
    label: fieldLabel,
    placeholder = 'Tap to upload attachment',
    showRequiredOnLabel,
    showLabel = true,
    value,
    onChange: onChangeHandler,
  } = props;

  let label = (
    <div className="flex-col rounded-md border-2 border-dashed border-neutral-300 bg-neutral-100 p-2 text-center text-neutral-500">
      <p className="text-sm">{placeholder}</p>
      <span className="text-xs text-neutral-400">
        Upload{' '}
        {acceptedFileTypes.map((acceptedFileType) => acceptedFileType.toUpperCase()).join('/')} up
        to 5MB
      </span>
    </div>
  );

  if (value) {
    label = (
      <div className="flex items-center rounded-md border border-neutral-300 bg-white">
        <PaperClipIcon className="m-4 h-5 w-5 flex-shrink-0 text-neutral-400" />
        <p className="line-clamp-1 flex-1 whitespace-nowrap text-sm text-neutral-600">
          {truncateMiddle(value, 20)}
        </p>
        <Button type="button" variant="label" className="pointer-events-none">
          <span className="font-medium">Change</span>
        </Button>
      </div>
    );
  }

  const handlePhotoChange = (onChange: (url: string) => void) => async (files: File[]) => {
    if (!files[0]) {
      return;
    }

    setIsUploading(true);

    try {
      const loadUrl = await uploadFile(files[0], filePath);

      onChange(loadUrl.url);
    } catch (_error) {
      toast({
        message: 'Something went wrong',
        type: 'error',
      });
    } finally {
      setIsUploading(false);
    }
  };

  let fileUploaderDisplay = (
    <FileUpload
      acceptedFileTypes={acceptedFileTypes}
      label={label}
      labelClassName="ml-0 sm:ml-0 whitespace-normal"
      maxSizeLimit={FileSizeLimitEnum._5MB}
      onFileChange={handlePhotoChange(onChangeHandler)}
    />
  );

  if (isUploading) {
    fileUploaderDisplay = (
      <div className="cursor-not-allowed flex-col rounded-md border-2 border-dashed border-neutral-300 bg-neutral-100 p-2 text-center text-neutral-500">
        <div className="inline-block">
          <LoadingSpinner size="md" />
        </div>
        <p className="text-sm">Uploading</p>
      </div>
    );
  }

  return (
    <div>
      {showLabel && (
        <label
          className="mb-1.5 block text-sm font-normal leading-6 text-neutral-600"
          htmlFor="attachment"
        >
          {fieldLabel}
          {showRequiredOnLabel && <span className="text-red-600"> *</span>}
        </label>
      )}
      {fileUploaderDisplay}
      {error && <div className="mt-1 text-sm text-red-600">{error}</div>}
    </div>
  );
}
