import { UserSex } from '../enums';
import { OperationUserType, ProviderUserType, UserType } from '../types';

export const UserTypeLabels = {
  [UserType.CONCIERGE]: 'Concierge',
  [UserType.CONSULTANT]: 'Consultant',
  [UserType.FINANCE]: 'Finance',
  [UserType.FULFILLMENT_CENTER_ADMIN]: 'Fulfullment Center Admin',
  [UserType.HOSPITAL_ADMIN]: 'Hospital Admin',
  [UserType.HOSPITALIST]: 'Hospitalist',
  [UserType.IMAGING_ADMIN]: 'Imaging Admin',
  [UserType.IMAGING_USER]: 'Imaging User',
  [UserType.LABORATORY_ADMIN]: 'Laboratory Admin',
  [UserType.LABORATORY_USER]: 'Laboratory Technician',
  [UserType.PATIENT]: 'Patient',
  [UserType.PHARMACY_ADMIN]: 'Pharmacy Admin',
  [UserType.PHARMACY_USER]: 'Pharmacy User',
  [UserType.SECRETARY]: 'Secretary',
  [UserType.SUPER_ADMIN]: 'Super Admin',
  [UserType.PROVIDER_ADMIN]: 'Provider Admin',
  [UserType.PROVIDER_DOCTOR]: 'Provider Doctor',
  [UserType.PROVIDER_STAFF]: 'Provider Staff',
};

export const OperationUserTypeLabels = {
  [OperationUserType.ADMIN]: 'Admin',
  [OperationUserType.MARKETING]: 'Marketing',
  [OperationUserType.FINANCE]: 'Finance',
  [OperationUserType.MERCHANT]: 'Merchant',
};

export const ProviderUserTypeLabels = {
  [ProviderUserType.BRANCH_ADMIN]: 'Branch Manager',
  [ProviderUserType.BRAND_ADMIN]: 'Brand Admin',
  [ProviderUserType.SPECIALIST]: 'Branch Specialist',
  [ProviderUserType.STAFF]: 'Branch Staff',
  [ProviderUserType.ESHOP_MERCHANT]: 'Eshop Merchant',
  [ProviderUserType.SUPER_ADMIN]: 'Super Admin',
  [ProviderUserType.PROVIDER_ADMIN]: 'Branch Manager', // deprecated. use BRANCH_ADMIN moving forward
};

export const UserSexTypeLabels = {
  [UserSex.MALE]: 'Male',
  [UserSex.FEMALE]: 'Female',
};

export const BRAND_LEVEL_USERS = [ProviderUserType.SUPER_ADMIN, ProviderUserType.BRAND_ADMIN];

export const BRANCH_LEVEL_USERS = [
  ProviderUserType.SPECIALIST,
  ProviderUserType.BRANCH_ADMIN,
  ProviderUserType.STAFF,
];

export const BRAND_ADMIN_USERS = [
  ProviderUserType.SUPER_ADMIN,
  ProviderUserType.BRAND_ADMIN,
  OperationUserType.ADMIN,
];
