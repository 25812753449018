import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  ActionModal,
  Modal,
  ProviderBulkUploadServices,
  Variants,
  useToggle,
} from '@mwell-healthhub/commons';

import Button from './Button';
import { EntityType, Provider } from '@healthhub/api-lib';

type Props = {
  provider: Provider;
  categories: EntityType[];
  providerServiceId?: number;
  onSuccess?: () => void;
  isDisabled?: boolean;
};

export default function BulkUploadServices(props: Props) {
  const { provider, categories, onSuccess, isDisabled } = props;
  const { handleToggle, isOpen } = useToggle();

  return (
    <>
      <Button
        data-testid="add-button"
        variant="secondary"
        className="flex gap-2 px-[14px] py-[10px] font-normal"
        onClick={handleToggle}
        disabled={isDisabled}
      >
        <ArrowDownTrayIcon height={18} width={18} className="mr-2" /> Bulk Upload Services
      </Button>

      <Modal open={isOpen}>
        <div className="flex flex-col items-center justify-between gap-4 sm:gap-6">
          <div className="flex w-full items-center justify-between pb-4">
            <p className="text-xl font-semibold">Bulk Upload Services</p>
            <Button
              onClick={handleToggle}
              className="m-0 p-0 hover:bg-white hover:text-primary-300"
              size="xs"
              variant="tertiary"
            >
              <XMarkIcon className="h-6 w-6" />
            </Button>
          </div>
          <div className="flex w-full flex-col space-y-6">
            <ProviderBulkUploadServices
              provider={provider}
              categories={categories}
              onClose={handleToggle}
              onSuccess={onSuccess}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
