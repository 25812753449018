import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { classNames, clsxMerge } from '../../utils';
import InputLoadingSpinner from '../InputLoadingSpinner';

const BUTTON_BACKGROUND_CLASS = {
  primary: 'bg-primary1',
  secondary: 'bg-lightGray2',
  tertiary: 'bg-lightGray1 bg-opacity-5 color-lightGray2',
  plain: 'bg-transparent',
};

const VARIANT_CLASS_NAMES = {
  tertiary: 'text-primary1',
  plain: 'text-lightGray4',
};

enum IconSize {
  'sm' = 16,
  'md' = 20,
  'lg' = 24,
}

type Props = {
  className?: string;
  inputClassName?: string;
  defaultValue?: string;
  hasLeftRoundedBorders?: boolean;
  hasRightRoundedBorders?: boolean;
  hasNoRightBorder?: boolean;
  iconVariant?: 'primary' | 'secondary' | 'tertiary' | 'plain';
  iconSize?: 'sm' | 'md' | 'lg';
  placeholder?: string;
  value?: string;
  errorMessage?: string;
  onChange: (value?: string) => void;
  onSearch?: () => void;
  isHiddenIcon?: boolean;
  isLoading?: boolean;
  searchButtonRef?: MutableRefObject<HTMLDivElement>;
};

const SearchInput = (props: Props) => {
  const {
    className,
    inputClassName,
    defaultValue,
    hasLeftRoundedBorders = true,
    hasNoRightBorder = false,
    hasRightRoundedBorders = true,
    iconVariant = 'secondary',
    iconSize = 'md',
    placeholder,
    value = '',
    errorMessage,
    onChange,
    onSearch,
    isHiddenIcon = false,
    isLoading = false,
    searchButtonRef,
  } = props;

  const [internalValue, setInternalValue] = useState(defaultValue || '');
  const inputRef = useRef(null);
  const initialValueRef = useRef<string>();

  useEffect(() => {
    if (!initialValueRef.current) {
      initialValueRef.current = defaultValue ?? '';

      setInternalValue(initialValueRef.current);
    }
  }, [defaultValue]);

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      setInternalValue(event.target.value);
    }
    onChange(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch?.();
    }
  };

  const background = BUTTON_BACKGROUND_CLASS[iconVariant];
  const iconColor = VARIANT_CLASS_NAMES[iconVariant as keyof typeof VARIANT_CLASS_NAMES];

  const containerClassNames = classNames(
    'relative h-10',
    hasLeftRoundedBorders && 'rounded-l-md',
    hasRightRoundedBorders && 'rounded-r-md',
    className,
  );
  const inputClassNames = classNames(
    'block border-none px-4 h-10 w-full ring-1 ring-neutral-300 py-1.5 pr-10 text-neutral-600 placeholder:text-neutral-400 sm:text-sm sm:leading-6 focus:ring-primary-500',
    hasLeftRoundedBorders && 'rounded-l-md',
    hasRightRoundedBorders && 'rounded-r-md',
    hasNoRightBorder && 'border-r-0',
    inputClassName,
  );
  const iconDisplay = (
    <MagnifyingGlassIcon className={iconColor || 'text-white'} height={IconSize[iconSize]} />
  );
  const inputValue = value || internalValue;

  return (
    <>
      <div className={clsxMerge(containerClassNames, { 'mb-2': errorMessage })}>
        <input
          autoComplete="none"
          className={clsxMerge(inputClassNames)}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleSearchQueryChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
        <div
          className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
            isHiddenIcon ? 'hidden' : 'block'
          }`}
        >
          <div
            ref={searchButtonRef}
            className={`flex h-5 w-5 items-center justify-center rounded-full bg-primary1 hover:cursor-pointer ${background}`}
            onClick={onSearch}
          >
            {isLoading ? <InputLoadingSpinner isAbsolute={false} /> : iconDisplay}
          </div>
        </div>
      </div>
      {errorMessage && (
        <span className="absolute top-full !mt-[-8px] block text-sm text-red-600">
          {errorMessage}
        </span>
      )}
    </>
  );
};

export default forwardRef(SearchInput);
