import {
  ProviderServiceSettings,
  ProviderServiceSettingsApi,
  UpdateProviderServiceSettingsDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';

const providerServiceSettingsApi = HealthHubApiClient.use(ProviderServiceSettingsApi);

export const getProviderServiceSettings = async (
  config?: AxiosRequestConfig,
): Promise<ProviderServiceSettings> => {
  try {
    const { data } =
      await providerServiceSettingsApi.providerServiceSettingsControllerFindOne(config);

    return data;
  } catch (error) {
    throw new Error('Failed to fetch provider service settings');
  }
};

export const updateProviderServiceSettings = async (
  updateProviderClientNotesDto: UpdateProviderServiceSettingsDto,
): Promise<ProviderServiceSettings> => {
  try {
    const response = await providerServiceSettingsApi.providerServiceSettingsControllerUpdate(
      updateProviderClientNotesDto,
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch provider service settings');
  }
};
