import isEmpty from 'lodash.isempty';

export function areAllPropertiesEmpty(obj: Record<string, any>): boolean {
  return Object.values(obj).every((value) => isEmpty(value));
}

export function getQueryParams(stringSearchParams: string) {
  const searchParams = new URLSearchParams(stringSearchParams);

  const params: Record<string, string> = {};

  for (const param of searchParams) {
    params[param[0]] = param[1];
  }

  return params;
}

export function ensureObject(ob: Record<string, any> | null): Record<string, any> {
  if (typeof ob === 'object' && ob !== null) {
    return ob;
  } else {
    return {};
  }
}

export function getEnumKeyByValue<T>(enumObj: T, value: any): keyof T | undefined {
  const keys = Object.keys(enumObj as any).find((key) => enumObj[key as keyof T] === value);
  return keys as keyof T | undefined;
}
