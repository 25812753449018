import {
  EntityTypeApi,
  EntityType,
  CreateEntityTypeDto,
  UpdateEntityTypeDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { EntityTypeEnum } from '../enums';
import { CustomError } from '../errors';

export const categoryApi = HealthHubApiClient.use(EntityTypeApi);

export type FetchCategoriesParams = {
  includeRelation?: boolean;
  search?: string;
  providerTypeId?: number;
  providerTypeIds?: string;
};

export async function fetchCategories(
  params?: FetchCategoriesParams,
  config?: AxiosRequestConfig,
): Promise<EntityType[]> {
  try {
    const { data } = await categoryApi.entityTypeControllerFindAll(
      EntityTypeEnum.Category,
      params?.includeRelation,
      params?.search,
      params?.providerTypeId,
      params?.providerTypeIds,
      config,
    );

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getCategoryById(
  id: string,
  config?: AxiosRequestConfig,
): Promise<EntityType> {
  try {
    const { data } = await categoryApi.entityTypeControllerFindOne(id, config);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createCategory(createCategory: CreateEntityTypeDto): Promise<EntityType> {
  try {
    const { data } = await categoryApi.entityTypeControllerCreate(createCategory);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateCategory(
  id: string,
  updateCategory: UpdateEntityTypeDto,
): Promise<EntityType> {
  try {
    const { data } = await categoryApi.entityTypeControllerUpdate(id, updateCategory);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function deleteCategory(id: string): Promise<void> {
  try {
    await categoryApi.entityTypeControllerRemove(id);
  } catch (error) {
    throw new CustomError(error);
  }
}
