import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { GET_PROVIDER_SERVICE_SETTINGS } from '@mwell-healthhub/commons';
import { ProviderServiceSettings } from '@healthhub/api-lib';
import { getProviderServiceSettings } from '@mwell-healthhub/commons/api/providerServiceSettingsService';

export const useGetProviderServiceSettings = (
  options?: UseQueryOptions<ProviderServiceSettings>,
) => {
  return useQuery<ProviderServiceSettings>(
    [GET_PROVIDER_SERVICE_SETTINGS],
    () => getProviderServiceSettings(),
    options,
  );
};
