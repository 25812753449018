import { Fragment, useContext } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { User } from '@healthhub/api-lib';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import isEmpty from 'lodash.isempty';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import {
  AppLogo,
  Avatar,
  Button,
  SHOW_FAVOURITES,
  SHOW_MEDICAL_RECORDS,
  SHOW_MESSAGE_THREADS,
  SHOW_PROVIDER_PROFILE_V2,
  SizeVariant,
  useGetClientFavourite,
  useGetCurrentUser,
  useRouter,
} from '@mwell-healthhub/commons';
import { useUserLogout } from '@mwell-healthhub/commons/hooks/mutations/userMutations';
import { trackEvent, EventName } from '@mwell-healthhub/commons/utils/analytics';

import { Routes } from '../../constants';
import { ProviderContext, ProviderContextActions } from '../../contexts/provider';

type Props = {
  isAppointmentHeader?: boolean;
};

export default function DesktopHeader(props: Props) {
  const { isAppointmentHeader } = props;
  const router = useRouter();
  const { isLoading, user } = useGetCurrentUser() as { isLoading: boolean; user: User };
  const isUserLoggedIn = !isEmpty(user);

  const handleRedirectToAuth = () => {
    trackEvent(EventName.CLICK_REGISTER_OR_LOGIN);

    router.push(Routes.AUTH);
  };

  const handleRedirectPartner = () => {
    router.push(Routes.PARTNER);
  };

  const handleRedirectToAppointments = () => {
    trackEvent(EventName.CLICK_REDIRECT_APPOINTMENTS_IN_HEADER);

    router.push(Routes.APPOINTMENTS);
  };

  const handleRedirectToRecords = () => {
    trackEvent(EventName.CLICK_REDIRECT_RECORDS_IN_HEADER);

    router.push(Routes.RECORDS);
  };

  const handleRedirectToMessages = () => {
    trackEvent(EventName.CLICK_REDIRECT_MESSAGES_IN_HEADER);

    router.push(Routes.MESSAGES);
  };

  const handleRedirectToFavourites = () => {
    trackEvent(EventName.CLICK_REDIRECT_RECORDS_IN_HEADER);

    router.push(Routes.PROFILE_FAVOURITES);
  };

  const { dispatch } = useContext(ProviderContext);

  const { mutate: logoutUser } = useUserLogout({
    onSuccess: () => {
      dispatch({
        payload: null,
        type: ProviderContextActions.HARD_RESET,
      });

      window.location.href = '/';
    },
  });

  const handleClickLogout = () => {
    logoutUser({});
  };

  const handleCancelAppointment = () => {
    const { providerId } = router.query;

    trackEvent(EventName.CLICK_CANCEL_APPOINTMENT_IN_HEADER);

    dispatch({
      type: ProviderContextActions.CANCEL_APPOINTMENT,
      payload: null,
    });
    router.replace(Routes.PROVIDER.replace(':id', (providerId as string) ?? ''));
  };

  const loggedOutActions = (
    <>
      <Button className="rounded" onClick={handleRedirectToAuth}>
        Register or Login
      </Button>
    </>
  );

  function handleManageProfile() {
    trackEvent(EventName.DROPDOWN_PROFILE, {
      title: 'Manage Profile',
    });
  }

  function handleClickItem(title: string, close: () => void) {
    trackEvent(EventName.DROPDOWN_PROFILE, {
      title,
    });

    close();
  }

  const loggedInActions = user && (
    <>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button>
              <div className="flex items-center">
                <Avatar
                  className="mr-0 flex-none md:mr-1"
                  imageSrc={user.displayPhotoUrl}
                  size={SizeVariant.SM}
                />
                <span className="ml-4 inline-flex gap-1">
                  <span className="hidden md:block">Welcome Back, </span>
                  {user.firstName}
                </span>
                <ChevronDownIcon
                  width={20}
                  height={20}
                  className={twMerge('ml-4 duration-100 ease-linear', open && 'rotate-180')}
                />
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-2 top-12 z-50 w-80 rounded-md bg-white shadow-authCard">
                <Menu.Item as="a" href={Routes.PROFILE_VIEW} onClick={handleManageProfile}>
                  <div className="flex flex-row gap-3 border-b border-neutral-300 px-4 py-6 align-middle">
                    <Avatar
                      className="border-3 mr-0 flex-none rounded-full md:mr-1"
                      imageSrc={user?.displayPhotoUrl}
                      size={SizeVariant.MD}
                    />
                    <div className="flex grow flex-col justify-center">
                      <span className="text-lg">
                        {user?.firstName} {user?.lastName}
                      </span>
                      <span className="text-sm">Manage Profile</span>
                    </div>
                    <div className="flex flex-col justify-center">
                      <ChevronRightIcon width={20} height={20} />
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item as="div" className="flex flex-col gap-2 px-4 py-6">
                  {({ close }) => (
                    <>
                      {SHOW_FAVOURITES && (
                        <Link
                          href={Routes.PROFILE_FAVOURITES}
                          onClick={() => handleClickItem('My Favorites', close)}
                          className="py-2 text-sm"
                        >
                          My Favorites
                        </Link>
                      )}
                      <Link
                        href={Routes.CLIENT_PROFILES}
                        onClick={() => handleClickItem('Family and Relatives', close)}
                        className="py-2 text-sm"
                      >
                        Family and Relatives
                      </Link>
                      <Link
                        href={Routes.PRIVACY_POLICY}
                        onClick={() => handleClickItem('Privacy Policy', close)}
                        className="py-2 text-sm"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                      <Link
                        href={Routes.TERMS_AND_CONDITIONS}
                        onClick={() => handleClickItem('Terms and Conditions', close)}
                        className="py-2 text-sm"
                        target="_blank"
                      >
                        Terms and Conditions
                      </Link>
                      <Button height={24} variant="secondary" onClick={handleClickLogout}>
                        Logout
                      </Button>
                    </>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );

  let actionsSection = isUserLoggedIn ? (loggedInActions as JSX.Element) : null;

  if (!isUserLoggedIn && !isLoading) {
    actionsSection = loggedOutActions;
  }

  const appointmentButton = isUserLoggedIn && (
    <p
      className="mt-1 hidden cursor-pointer text-primary-500 sm:block"
      onClick={handleRedirectToAppointments}
    >
      Appointments
    </p>
  );

  const medicalRecordsButton = isUserLoggedIn && SHOW_MEDICAL_RECORDS && (
    <p
      className="mt-1 hidden cursor-pointer text-primary-500 sm:block"
      onClick={handleRedirectToRecords}
    >
      Records
    </p>
  );

  const messagesButton = isUserLoggedIn && SHOW_MESSAGE_THREADS && (
    <p
      className="mt-1 hidden cursor-pointer text-primary-500 sm:block"
      onClick={handleRedirectToMessages}
    >
      Messages
    </p>
  );

  const favouritesButton = isUserLoggedIn && (
    <p
      className="mt-1 hidden cursor-pointer text-primary-500 sm:block"
      onClick={handleRedirectToFavourites}
    >
      Favorites
    </p>
  );

  return (
    <div
      className={twMerge(
        'w-full bg-white',
        SHOW_PROVIDER_PROFILE_V2 && 'border-b-[0.5px] border-[#EBEDEE]',
      )}
    >
      <div className="mx-auto flex justify-between px-12 py-4 xl:px-[75px] min-[1440px]:w-[1440px]">
        <div className="flex items-center gap-4">
          <div>
            <AppLogo height={22} isClickable={!isAppointmentHeader} />
          </div>
          {/* <p className="text-primary-500 mt-1 cursor-pointer hidden sm:block">Find Clinics</p> TODO */}
          {!isAppointmentHeader && appointmentButton}
          {!isAppointmentHeader && medicalRecordsButton}
          {!isAppointmentHeader && messagesButton}
          {!isAppointmentHeader && favouritesButton}
        </div>

        <div className="relative flex gap-1 md:gap-4">
          {/* <Button variant="primary" className="rounded hidden md:block">Get The App</Button> TODO */}

          {isAppointmentHeader ? (
            <Button variant="plainNoOutline" onClick={handleCancelAppointment}>
              Cancel Appointment
            </Button>
          ) : (
            <>
              <Link
                className="ease group whitespace-nowrap px-[10px] pb-[8px] pt-[10px] text-center text-sm font-medium text-primary-500 transition-all duration-300"
                href={Routes.PARTNER}
                target="_blank"
              >
                Be a Partner Provider
                <span className="block h-0.5 max-w-0 bg-sky-600 transition-all duration-500 group-hover:max-w-full" />
              </Link>
              {actionsSection}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
