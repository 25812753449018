import { RegionApi } from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { transformBooleanToString } from '../utils';

export const regionApi = HealthHubApiClient.use(RegionApi);

export async function getAllRegions(hasHospitals?: boolean, hasLabs?: boolean) {
  try {
    const { data } = await regionApi.regionControllerFindAll(
      transformBooleanToString(hasHospitals),
      transformBooleanToString(hasLabs),
    );
    return data;
  } catch (error) {
    console.error('Error while fetching regions:', error);
    throw new Error('Failed to fetch regions');
  }
}
