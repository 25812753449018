import { ChangeEvent, forwardRef, HTMLProps, Ref, useEffect, useId, useRef, useState } from 'react';

import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export interface SelectProps extends HTMLProps<HTMLSelectElement> {
  label?: string;
  labelShown?: boolean;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  showRequiredOnLabel?: boolean;
  labelClassName?: string;
  selectClassName?: string;
  showPlaceholder?: boolean;
  errorMessage?: string;
}

function Select(props: SelectProps, forwardedRef?: Ref<HTMLSelectElement>) {
  const [isEmpty, setIsEmpty] = useState(true);
  const {
    label,
    labelShown = false,
    children,
    placeholder,
    className = '',
    onChange,
    title,
    disabled,
    id: idProp,
    showRequiredOnLabel = false,
    selectClassName = '',
    labelClassName,
    showPlaceholder = true,
    errorMessage,
    ...etcProps
  } = props;
  const defaultId = useId();
  const id = idProp ?? defaultId;
  const defaultRef = useRef<HTMLSelectElement>(null);
  const ref = forwardedRef ?? defaultRef;

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    onChange?.(e);
    setIsEmpty((e.currentTarget.value ?? '') === '');
  }

  useEffect(() => {
    if (typeof ref === 'object' && ref !== null) {
      setIsEmpty((ref.current?.value ?? '') === '');
    }
  }, [ref]);

  const commonClassNames = 'w-full pl-3 pr-10 sm:text-sm sm:leading-6';

  return (
    <div>
      <label
        htmlFor={id}
        className={clsx(
          'mb-2 block text-sm leading-6 text-gray-700',
          {
            'sr-only': !labelShown,
          },
          labelClassName || '',
        )}
      >
        {label}
        {showRequiredOnLabel && <span className="text-red-500">*</span>}
      </label>
      <div className="relative">
        <select
          {...etcProps}
          disabled={disabled}
          ref={ref}
          id={id}
          title={title ?? label}
          className={twMerge(
            selectClassName,
            commonClassNames,
            'border-1 block h-10 rounded-md border-none text-gray-900 ring-1 ring-neutral-300 focus:ring-primary-500 disabled:cursor-not-allowed disabled:text-slate-400',
            className,
          )}
          onChange={handleChange}
        >
          {showPlaceholder && (
            <option value="" className="text-gray-400">
              {placeholder}
            </option>
          )}
          {children}
        </select>
        {isEmpty && !etcProps.value && (
          <span
            className={clsx(
              commonClassNames,
              'pointer-events-none absolute left-0 top-0 flex h-full items-center py-2',
              { 'opacity-50': disabled },
            )}
          >
            <span className="flex h-full items-center bg-white text-gray-400">{placeholder}</span>
          </span>
        )}
      </div>
      {errorMessage && <div className="mt-1 text-sm text-red-600">{errorMessage}</div>}
    </div>
  );
}

export default forwardRef(Select);
