import { useCallback, useEffect, useRef, useState, ChangeEvent } from 'react';

import './styles.css';
import clsx from 'clsx';

interface Props {
  min: number;
  max: number;
  label?: string;
  onChange: (value: { min: number; max: number }) => void;
}

export default function MinMaxRangeSlider(props: Props) {
  const { min, max, label, onChange } = props;

  const [minVal, setMinVal] = useState<number>(min);
  const [maxVal, setMaxVal] = useState<number>(max);
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);
  const [sliderRangeMinPercent, setSliderMinPercent] = useState<number>(0);
  const [sliderRangeMaxPercent, setSliderMaxPercent] = useState<number>(100);

  const getPercent = useCallback(
    (value: number): number => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(Number(maxValRef.current.value));

      if (range.current) {
        setSliderMinPercent(minPercent);
        setSliderMaxPercent(maxPercent);
      }
    }
  }, [minVal]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(Number(minValRef.current.value));
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal]);

  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  const handleMinValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(Number(event.target.value), maxVal - 1);
    setMinVal(value);
    event.target.value = value.toString();
  };

  const handleMaxValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(Number(event.target.value), minVal + 1);
    setMaxVal(value);
    event.target.value = value.toString();
  };

  return (
    <div className="flex w-full flex-col gap-2">
      {label && <label className="text-sm text-neutral-600">{label}</label>}
      <div className="flex w-full items-center gap-4">
        <div className="w-4 text-sm text-neutral-600">{minVal}</div>
        <div className="relative w-full">
          <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            ref={minValRef}
            onChange={handleMinValueChange}
            className={clsx('thumb thumb--zindex-3', {
              'thumb--zindex-5': minVal > max - 100,
            })}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            ref={maxValRef}
            onChange={handleMaxValueChange}
            className="thumb thumb--zindex-4"
          />
          <div className="slider__track" />
          <div
            ref={range}
            className="absolute z-[2] h-[5px] rounded bg-secondary-500"
            style={{
              width: `${sliderRangeMaxPercent - sliderRangeMinPercent}%`,
              left: `${sliderRangeMinPercent}%`,
            }}
          />
        </div>
        <div className="w-4 text-sm text-neutral-600">{maxVal}+</div>
      </div>
    </div>
  );
}
