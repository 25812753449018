export enum ProviderStatusEnum {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export enum ProviderOnboardingStatusEnum {
  PENDING = 'PENDING', // open street maps nearby search result
  DIRECTORY = 'DIRECTORY', // approved by admin from PENDING
  SELF_ONBOARDED = 'SELF_ONBOARDED',
  ONBOARDED = 'ONBOARDED', // officially a provider
}

export enum ProviderListType {
  BRAND = 'BRAND',
  BRANCH = 'BRANCH',
}
