import { useQuery } from '@tanstack/react-query';

import { getApiFields } from '../../api';
import { ApiFields } from '../../types';

export const GET_ALL_API_FIELDS = 'GET_ALL_API_FIELDS';

export const useGetAllApiFields = () => {
  return useQuery<ApiFields[]>([GET_ALL_API_FIELDS], async () => getApiFields());
};
