/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';

import { clsxMerge } from '@mwell-healthhub/commons';

import { RATINGS } from '../../constants';

type Rating = {
  label: string;
  value: number;
};

type RatingIconProps = {
  isSelected: boolean;
  rating: Rating;
  onClick: () => void;
};

// Better rendered both ahead so that no request will be made when selecting a rating
const RatingIcon: React.FC<RatingIconProps> = ({ isSelected, onClick, rating }) => (
  <button type="button" onClick={onClick}>
    <img
      className={clsxMerge('block', isSelected && 'hidden')}
      alt={rating.label}
      src={`/static-assets/images/feedback/${rating.value}.svg`}
    />
    <img
      className={clsxMerge('hidden', isSelected && 'block')}
      alt={rating.label}
      src={`/static-assets/images/feedback/${rating.value}-active.svg`}
    />
  </button>
);

type FeedbackRatingBarProps = {
  defaultValue?: Rating;
  onChange: (rating: Rating) => void;
};

const FeedbackRatingBar: React.FC<FeedbackRatingBarProps> = (
  props: Readonly<FeedbackRatingBarProps>,
) => {
  const { defaultValue, onChange } = props;
  const [selectedRating, setSelectedRating] = useState<Rating | null>(null);

  useEffect(() => {
    if (!selectedRating && defaultValue) {
      setSelectedRating(defaultValue);
    }
  }, [defaultValue]);

  const handleRatingClick = (rating: Rating) => {
    setSelectedRating(rating);
    onChange(rating);
  };

  return (
    <div>
      <div className="flex w-full justify-center gap-2 md:gap-4">
        {RATINGS.map((rating, index) => (
          <RatingIcon
            isSelected={selectedRating?.value === rating.value}
            key={index}
            rating={rating}
            onClick={() => handleRatingClick(rating)}
          />
        ))}
      </div>
      {selectedRating && (
        <p className="mt-2 text-center text-sm font-medium text-neutral-400">
          {selectedRating.label}
        </p>
      )}
    </div>
  );
};

export default FeedbackRatingBar;
