export const PAYMENT_ERROR_CODES = {
  PY0009: 'Payment does not exist',
  PY0076: 'Payment does not exist',
  PY0017: 'Merchant not found',
  PY0019: 'Forbidden',
  PY0037: 'Currency is not supported',
  PY0044: 'Payment is invalid',
  PY0045: 'Payment is not available for void',
  PY0055: 'Payment is not available for void',
  PY0046: 'Refund does not exist',
  PY0047: 'Payment is ineligible for refund',
  PY0048: 'Requested refund amount is greater than the original amount',
  PY0057: 'The payment has expired and cannot be processed',
  PY0058: 'The merchant indicated does not have P2M services enabled',
  PY0063: 'Void does not exist',
  PY0065: 'This merchant has no customizations present',
  PY0068: 'Payment not executed due to authorization failure',
  PY0070: 'Scheme is unsupported by merchant',
  PY0072: 'Transaction cannot be processed. Cannot refund a transaction before cut off time',
  PY0073: 'Transaction cannot be processed. Cannot void a transaction after cut off time',
  PY0082: 'Refund already exists',
  PY0093: 'Payment has already been updated',
  PY0094: 'Payment is not available for capture',
  PY0095: 'Amount must be less than or equal to amount authorized',
  PY0096: 'Amount must be equal to amount authorized',
  PY0100: 'Authentication failed',
  PY0101: 'Acquirer decline due to high risk',
  PY0103: 'Payment is already expired',
  PY0104: 'Reference number is linked to multiple payments',
  PY0113: 'Partial refund is not allowed for this transaction',
  PY0116: 'Transaction could not be completed',
  PY0124: 'Transaction could not be verified',
  PY0137: 'Decline due to high risk',
  PY0138: 'Acquirer decline',
  PY0146: 'Login cancelled by user',
  PY0002: 'Card is expired',
  PY0007: 'Invalid token status',
  PY0008: 'Token is invalid',
  PY0021: 'Failed to add customer',
  PY0023: 'Customer does not exist',
  PY0024: 'Failed to delete customer',
  PY0025: 'Failed to update customer details',
  PY0026: 'Failed to update card details',
  PY0027: 'Card does not exist',
  PY0028: 'Failed to delete card',
  PY0029: 'No card found for customer',
  PY0030: 'Invalid card token status',
  PY0036: 'Card is not supported',
  PY0043: 'Card already exists',
  PY0105: 'Account has insufficient balance to perform this transaction',
  PY0117: 'Card is invalid',
  PY0119: 'Issuer declined card or account',
  PY0120: 'Issuer decline',
  PY0121: 'Card is expired',
  PY0123: 'Account limit exceeded',
  PY0127: 'Please update incomplete customer records',
  PY0136: 'Account / Card is compromised',
  '2523': 'Missing/invalid parameters',
  '2553': 'Missing/invalid parameters',
  PY0001: 'Generic system error',
  PY0064: 'Invalid JSON Format',
  PY0013: 'System has encountered a systematic error or is unreachable / timed out',
  PY0040: 'System has encountered a systematic error or is unreachable / timed out',
  PY0059: 'System has encountered a systematic error or is unreachable / timed out',
  PY0060: 'System has encountered a systematic error or is unreachable / timed out',
  PY0066: 'System has encountered a systematic error or is unreachable / timed out',
  PY0067: 'System has encountered a systematic error or is unreachable / timed out',
  PY0069: 'System has encountered a systematic error or is unreachable / timed out',
  PY0122: 'System has encountered a systematic error or is unreachable / timed out',
  PY0143: 'System has encountered a systematic error or is unreachable / timed out',
  PY0144: 'System has encountered a systematic error or is unreachable / timed out',
  PY9999: 'System has encountered a systematic error or is unreachable / timed out',
  PY0114: 'WeChat Service unreachable / timed out',
  PY0115: 'WeChat Service error',
  PY0129: 'GCash Service unreachable / timed out',
  PY0130: 'GCash Service error',
  PY0134: 'ShopeePay Service unreachable / timed out',
  PY0135: 'ShopeePay Service error',
  PY0038: 'Webhook does not exist',
  PY0039: 'Webhook already exists',
  PY0091: 'Payment state is invalid for sending webhooks',
  PY0092: "Merchant's registered webhook is unreachable",
  PY0049: 'Subscription does not exist',
  PY0051: 'Failed to update subscription details',
  PY0052: 'Failed to cancel subscription',
  PY0054: 'Subscription already charged',
};
