import { ScheduleResponse } from '@healthhub/api-lib';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import LoadingSpinner from './LoadingSpinner';
import { SHORT_DATE_US_FORMAT } from '../constants';
import { useGetFilteredTimeSlots } from '../hooks';
import { FullSlot, SelectedDateSchedule } from '../types';
import { convertIsoFormatTime, getDateWithTimeObject, getTimeFromDate } from '../utils';

type Props = {
  disabledTimeSlots?: ScheduleResponse[];
  isLoading?: boolean;
  schedule: SelectedDateSchedule;
  selectedDate: Date;
  shouldShowNumRemainingSlots?: boolean;
  onSelect: (date: Date, slot?: FullSlot) => void;
};

const TimeSlots = (props: Props) => {
  const {
    onSelect,
    schedule,
    selectedDate,
    shouldShowNumRemainingSlots = false,
    disabledTimeSlots = [],
    isLoading = false,
  } = props;

  const { date: selectedScheduleDate } = schedule;
  const filteredTimeSlots = useGetFilteredTimeSlots(schedule, disabledTimeSlots, selectedDate);
  const isSameDate = selectedDate
    ? format(selectedDate, SHORT_DATE_US_FORMAT) ===
      format(selectedScheduleDate, SHORT_DATE_US_FORMAT)
    : false;

  const selectedTime = selectedDate && getTimeFromDate(selectedDate);

  const handleSelectTime = (time: FullSlot) => {
    const newDate = getDateWithTimeObject(selectedScheduleDate, time.startTime);

    onSelect(new Date(newDate), time);
  };

  const hasTimeSlots = filteredTimeSlots.length > 0;

  if (!hasTimeSlots) {
    return (
      <span className="text-md mt-4 flex w-full justify-center rounded-lg bg-gray-300 py-2 text-center leading-5 text-gray-700">
        No timeslot available
      </span>
    );
  }

  if (isLoading) {
    return (
      <span className="mt-4 flex w-full justify-center py-2">
        <LoadingSpinner />
      </span>
    );
  }

  return (
    <div className="mt-4 grid grid-cols-3 gap-2">
      {filteredTimeSlots.map((timeSlot) => {
        const { id, startMilitaryTime, startTime } = timeSlot;

        const isTimeSelected = convertIsoFormatTime(startTime) === selectedTime;
        const isTimeDisabled = disabledTimeSlots.some(
          (disabledTimeSlot) => disabledTimeSlot.startTime === startMilitaryTime.toString(),
        );

        const className = twMerge(
          'w-full cursor-pointer overflow-hidden rounded-lg border border-gray24 px-3.5 py-2.5 text-center text-sm text-gray23',
          'md:hover:bg-cobalt md:hover:text-white',
          isTimeSelected && isSameDate && 'bg-cobalt text-white',
          isTimeDisabled && 'cursor-not-allowed bg-gray-300 text-gray-700',
        );

        const handleOnClick = () => {
          handleSelectTime(timeSlot);
        };

        return (
          <div key={id} className={className} onClick={handleOnClick} data-cy="active-time-slot">
            {startTime}
            {shouldShowNumRemainingSlots && ` (${timeSlot.remainingSlots})`}
          </div>
        );
      })}
    </div>
  );
};

export default TimeSlots;
