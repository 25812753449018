import { ChangeEvent, HTMLProps, useState, forwardRef, Ref, useRef, MouseEvent } from 'react';

export interface ImageUploadAreaProps extends Omit<HTMLProps<HTMLInputElement>, 'type' | 'accept'> {
  defaultPreviewUrl?: string;
}

function ImageUploadAreaBase(props: ImageUploadAreaProps, passedRef: Ref<HTMLInputElement> | null) {
  const { placeholder, className = '', defaultPreviewUrl, onChange, ...etcProps } = props;
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(defaultPreviewUrl);
  const defaultRef = useRef<HTMLInputElement>(null);
  const ref = passedRef ?? defaultRef;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const [file] = Array.from(event.currentTarget.files ?? []);

    return new Promise<void>((resolve, reject) => {
      if (file) {
        const reader = new FileReader();

        reader.addEventListener('load', (readerEvent) => {
          if (readerEvent.target) {
            setPreviewUrl(readerEvent.target.result as string);
            resolve();
            onChange?.(event);
            return;
          }
          reject(new Error('readerEvent target is null'));
        });

        reader.addEventListener('error', (error) => {
          reject(error);
        });

        reader.readAsDataURL(file);
      }
    });
  }

  function clearFile(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setPreviewUrl(undefined);
    if (typeof ref !== 'function' && ref.current) {
      ref.current.value = '';
    }
  }

  return (
    <div
      className={`focus-within:ring-indigo-600 relative overflow-hidden rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset ${className}`.trim()}
    >
      <label className="block h-full w-full cursor-pointer">
        <input
          {...etcProps}
          ref={ref}
          type="file"
          accept="image/*"
          className="sr-only"
          onChange={handleChange}
        />
        {previewUrl && (
          <img src={previewUrl} alt="" className="block h-full w-full object-cover object-center" />
        )}
        {!previewUrl && (
          <span className="absolute flex h-full w-full items-center justify-center">
            {placeholder}
          </span>
        )}
      </label>
      {previewUrl && (
        <button
          className="absolute right-2 top-2 flex h-6 w-6 items-center justify-center rounded-full bg-black bg-opacity-50 text-white"
          onClick={clearFile}
        >
          &times;
        </button>
      )}
    </div>
  );
}

const ImageUploadArea = forwardRef(ImageUploadAreaBase);

ImageUploadArea.displayName = 'ImageUploadArea';

export default ImageUploadArea;
