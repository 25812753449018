import React from 'react';

import { Controller, useForm } from 'react-hook-form';

import Button from '../Button';
import Modal from '../Modal';
import TextArea from '../TextArea';

type Props = {
  isLoading: boolean;
  onCancelClick: () => void;
  onProceedClick: (reason: string) => void;
};

const DeclineModal = (props: Props) => {
  const { isLoading, onCancelClick, onProceedClick } = props;
  const { control, handleSubmit } = useForm<{ reason: string }>();

  const handleSave = handleSubmit(async (data) => {
    onProceedClick(data.reason);
  });

  return (
    <Modal size="lg" open={true} setOpen={() => null}>
      <form onSubmit={handleSave}>
        <div className="pb-3">
          <span className="font-bold">Decline</span> this appointment?
        </div>
        <div className="pb-3 text-sm text-gray-500">
          Are you sure you want to decline this appointment? If so, let your client know why you are
          declining.
        </div>
        <div>
          <Controller
            control={control}
            name="reason"
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextArea
                labelShown
                showRequiredOnLabel
                label="Reason for declining"
                value={value}
                errorMessage={error ? 'This field is required.' : ''}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="flex justify-end pt-6">
          <div className="flex gap-x-3">
            <Button className="w-[100px]" variant="outlined" type="button" onClick={onCancelClick}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              className="w-[100px]"
              variant="error"
              type="submit"
              disabled={isLoading}
            >
              Proceed
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default DeclineModal;
