import { isAxiosError, HttpStatusCode } from 'axios';

import { BadRequestMessageType, ErrorResponseType } from '../types/ErrorResponse.type';

export class CustomError implements ErrorResponseType {
  statusCode: number;
  error: string;
  message: string;
  path: string;
  timestamp: string;
  traceId: string;
  badRequestMessages?: Array<BadRequestMessageType>;

  constructor(error: unknown) {
    if (isAxiosError(error)) {
      const {
        statusCode,
        error: err,
        message,
        path,
        timestamp,
        traceId,
      } = error.response?.data as ErrorResponseType;

      this.statusCode = statusCode || 500;

      if (this.statusCode === HttpStatusCode.BadRequest) {
        this.message = !Array.isArray(message) ? message : message ?? err;
        this.badRequestMessages = message as unknown as Array<BadRequestMessageType>;
      } else {
        this.message = message;
      }

      this.error = err;
      this.path = path;
      this.timestamp = timestamp;
      this.traceId = traceId;

      Object.setPrototypeOf(this, new.target.prototype);
    } else {
      throw error;
    }
  }
}
