import React from 'react';

import BreadcrumbItem from './BreadcrumbItem';
import { BreadcrumbType } from '../../types/Common.type';
import { useRouter } from '@mwell-healthhub/commons';

interface Props {
  breadcrumbs: BreadcrumbType[];
  hasHome?: boolean;
}

const Breadcrumbs: React.FC<Props> = (props: Props) => {
  const { breadcrumbs, hasHome = true } = props;
  const router = useRouter();

  const handleClickBreadcrumb = (breadcrumb: BreadcrumbType) => {
    router.push(breadcrumb.path);
  };

  const handleClickHome = () => {
    router.push('/');
  };

  const displayBreadcrumbs = breadcrumbs.map((breadcrumb, index) => (
    <BreadcrumbItem
      key={`${breadcrumb.path}-${breadcrumb.name}}`}
      breadcrumb={breadcrumb}
      isLast={index === breadcrumbs.length - 1}
      isFirstAndNoHome={index === 0 && !hasHome}
      onClick={handleClickBreadcrumb}
    />
  ));

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        {hasHome && (
          <li className="leading-none">
            <button
              className="text-sm font-medium text-gray-500 hover:text-gray-700"
              onClick={handleClickHome}
            >
              Home
              <span className="sr-only">Home</span>
            </button>
          </li>
        )}
        {displayBreadcrumbs}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
