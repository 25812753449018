import { useEffect, useState } from 'react';

import {
  Appointment,
  ProviderClientNotes,
  ProviderService,
  UserOperationUserTypeEnum,
  UserProviderUserTypeEnum,
} from '@healthhub/api-lib';
import isEmpty from 'lodash.isempty';

import {
  AppointmentCustomFieldMetadata,
  Back,
  ProviderClientNotesList,
  ProviderCustomFieldMetadata,
  ProviderOnboardingStepEnum,
  Routes,
  SHOW_MEDICAL_RECORDS,
  clsxMerge,
} from '@mwell-healthhub/commons';
import useIsMobile from '@mwell-healthhub/commons/hooks/useIsMobile';

import AppointmentDetails from './AppointmentDetails';
import BookingStatusButton from './BookingStatusButton';
import PaymentDetails from './PaymentDetails';
import { useGetAppointmentReferralFormSignedUrl, useRouter, useToggle } from '../../hooks';
import ProviderClientMedicalRecordsList from '../ProviderClientMedicalRecordsList';
import ProviderClientNoteModal from '../ProviderClientNoteModal';

enum ActiveTabEnum {
  APPOINTMENT = 'appointment',
  NOTES = 'notes',
}

type Props = Readonly<{
  backUrl?: string;
  booking: Appointment;
  bookingFollowUpUrl?: string;
  canEditClientNotes?: boolean;
  isLoading?: boolean;
  providerId?: string;
  providerServices?: ProviderService[];
  showActions?: boolean;
  showClientNotes?: boolean;
  showProviderName?: boolean;
  showReschedule?: boolean;
  refetch?: () => void;
  userType?: UserOperationUserTypeEnum | UserProviderUserTypeEnum;
  userCanViewMessageThreads?: boolean;
  isOnboarding?: boolean;
  handleFinishOnboardingProgressionActionStep?: (step: ProviderOnboardingStepEnum) => void;
}>;

export default function AppointmentPage(props: Props) {
  const {
    canEditClientNotes = false,
    backUrl,
    booking,
    bookingFollowUpUrl,
    isLoading = false,
    providerId,
    providerServices,
    showActions = false,
    showClientNotes = false,
    showProviderName = false,
    showReschedule = false,
    refetch,
    userType,
    userCanViewMessageThreads,
    isOnboarding,
    handleFinishOnboardingProgressionActionStep,
  } = props;

  const { data: appointmentReferralFormFileLink } = useGetAppointmentReferralFormSignedUrl(
    booking.id,
    booking.referralFormFileId,
    !!booking.id && !!booking.referralFormFileId,
  );

  const router = useRouter();
  const { query, asPath } = router;
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.APPOINTMENT);
  const [providerClientnotesListKey, setProviderClientnotesListKey] = useState<number>(0);
  const [isEditingNote, setIsEditingNote] = useState<boolean>(false);
  const [currentProviderClientNote, setCurrentProviderClientNote] = useState<ProviderClientNotes>();

  const {
    isOpen: isOpenProviderClientNoteModal,
    handleToggle: handleProviderClientNoteModalToggle,
  } = useToggle();

  const isMobile = useIsMobile();
  const isReferral = !isEmpty(booking.referral);

  useEffect(() => {
    if (query.id) {
      const hash = asPath.split('#')[1];
      switch (hash) {
        case 'notes':
          if (!showClientNotes) {
            router.replace(`${Routes.APPOINTMENTS}/${query.id}`);
            break;
          }
          setActiveTab(ActiveTabEnum.NOTES);
          break;
        default:
          setActiveTab(ActiveTabEnum.APPOINTMENT);
          break;
      }
    }
  }, [query, asPath]);

  function handleClickBack() {
    if (backUrl) {
      router.replace(backUrl);
      return;
    }

    router.back();
  }

  const handleChangeTab = (tab: ActiveTabEnum) => {
    return () => {
      setActiveTab(tab);
    };
  };

  const handleShowProviderClientNoteForm = (
    isEditingNote: boolean,
    providerClientNote?: ProviderClientNotes,
  ) => {
    setIsEditingNote(isEditingNote);
    handleProviderClientNoteModalToggle();
    if (providerClientNote) {
      setCurrentProviderClientNote(providerClientNote);
      return;
    }
    setCurrentProviderClientNote(undefined);
  };

  const filteredCustomFields =
    booking.customFields
      ?.map((field) => field as AppointmentCustomFieldMetadata)
      ?.filter((field) => !!field.value) || [];

  return (
    <>
      {isOpenProviderClientNoteModal && (
        <ProviderClientNoteModal
          isOpen={true}
          providerClientProfileId={booking.providerClientProfile.id}
          appointmentId={booking.id}
          currentProviderClientNote={currentProviderClientNote}
          isEditing={isEditingNote}
          toggle={handleProviderClientNoteModalToggle}
          handleProviderClientNotesSaved={() => {
            setProviderClientnotesListKey((prev) => prev + 1);
          }}
        />
      )}
      <Back type="textIcon" className="mb-8" />
      <div className="mb-1 flex flex-col items-center md:flex-row">
        <h1
          className={clsxMerge('mb-4 flex-auto text-xl font-semibold md:mb-0 md:py-2 md:text-2xl', {
            'md:mb-8': !showClientNotes,
          })}
        >
          Appointment Information
        </h1>
        {showActions && (
          <div>
            <BookingStatusButton
              handleFinishOnboardingProgressionActionStep={
                handleFinishOnboardingProgressionActionStep
              }
              isOnboarding={isOnboarding}
              booking={booking}
              bookingFollowUpUrl={bookingFollowUpUrl}
              refetch={refetch}
              userCanViewMessageThreads={userCanViewMessageThreads}
            />
          </div>
        )}
      </div>
      <div className="mx-0 flex flex-col gap-y-6 pt-4">
        <AppointmentDetails
          isDisabled={isOnboarding}
          appointment={booking}
          providerId={providerId}
          providerServices={providerServices}
          showReschedule={showReschedule}
          showProviderName={showProviderName}
          showActions={showActions}
          isReferral={isReferral}
          appointmentReferralFormFileLink={appointmentReferralFormFileLink}
          refetch={refetch}
        />
        {SHOW_MEDICAL_RECORDS && (
          <ProviderClientMedicalRecordsList appointment={booking} isDisabled={isOnboarding} />
        )}
        <ProviderClientNotesList
          isDisabled={isOnboarding}
          isEditable={canEditClientNotes}
          isAppointmentNotes
          client={booking.providerClientProfile}
          appointment={booking}
          handleShowProviderClientNoteForm={handleShowProviderClientNoteForm}
        />
        {!!filteredCustomFields.length && (
          <div className="flex flex-col justify-between gap-6 md:flex-row lg:flex-col lg:gap-8 xl:flex-row">
            <div className="w-full rounded-md border border-neutral-300 bg-white p-8">
              <h3 className="mb-6 text-base font-semibold md:text-lg">Custom Fields</h3>
              {filteredCustomFields.map((field) => {
                const providerField = booking.provider?.customFields
                  ?.map((cf) => cf as ProviderCustomFieldMetadata)
                  .find((cf) => cf.id === field.id);

                if (!providerField) {
                  return;
                }

                return (
                  <div key={field.id} className="mb-4">
                    <p className="ellipsis truncate text-sm font-semibold text-neutral-600 md:text-base">
                      {providerField?.label}
                    </p>
                    <p className="text-sm font-normal text-tertiary-blue-4">{field.value}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <PaymentDetails booking={booking} userType={userType} />
      </div>
    </>
  );
}
