import { HTMLProps, ReactNode } from 'react';

import { Menu } from 'iconoir-react';
import Link from 'next/link';

type Props = HTMLProps<HTMLDivElement> & {
  children: ReactNode;
  logoDisplay?: ReactNode;
  onOpenSidebar?: () => void;
};

function TopNav({ children, logoDisplay, onOpenSidebar }: Props) {
  return (
    <header className="flex h-[70px] w-full border border-l-0 border-r-0 border-t-0 border-b-neutral-300 bg-white px-6 py-[13px] text-primary7">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          {onOpenSidebar && (
            <button onClick={onOpenSidebar} className="z-50 mr-1.5 block p-2 md:mr-3 lg:hidden">
              <Menu />
            </button>
          )}
          <div className="w-2/3 min-w-[20%] md:w-64">
            <Link href="/">
              {logoDisplay ?? <img src="/static-assets/images/healthhub-logo.svg" alt="logo" />}
            </Link>
          </div>
        </div>
        <nav>{children}</nav>
      </div>
    </header>
  );
}

export default TopNav;
