import { useQuery } from '@tanstack/react-query';

import { getMerchantByEmail } from '../../api';
import { GET_ESHOP_MERCHANT_BY_ID } from '../../constants';
import { Merchant } from '../../types';

export const useGetMerchantByEmail = (email: string) => {
  return useQuery<Merchant>([GET_ESHOP_MERCHANT_BY_ID, email], () => getMerchantByEmail(email), {
    enabled: !!email,
  });
};
