import {
  EntityTypeApi,
  CreateEntityTypeDto,
  EntityType,
  UpdateEntityTypeDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { EntityTypeEnum } from '../enums';
import { CustomError } from '../errors';

export const providerTypeApi = HealthHubApiClient.use(EntityTypeApi);

export type CreateProviderType = Omit<
  CreateEntityTypeDto,
  'type' | 'description' | 'providerTypeId'
>;
export type UpdateProviderType = Omit<
  UpdateEntityTypeDto,
  'description' | 'type' | 'providerTypeId'
>;
export type FetchProviderTypeParams = {
  includeRelation?: boolean;
  search?: string;
};

export async function createProviderType(
  createProviderTypeDto: CreateProviderType,
): Promise<EntityType> {
  try {
    const { data } = await providerTypeApi.entityTypeControllerCreate({
      ...createProviderTypeDto,
      type: EntityTypeEnum.Provider,
    });

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateProviderType(
  id: string,
  updateProviderTypeDto: UpdateProviderType,
): Promise<EntityType> {
  try {
    const { data } = await providerTypeApi.entityTypeControllerUpdate(id, {
      ...updateProviderTypeDto,
      type: EntityTypeEnum.Provider,
    });

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function fetchProviderTypes(
  params?: FetchProviderTypeParams,
  config?: AxiosRequestConfig,
): Promise<EntityType[]> {
  try {
    const { data } = await providerTypeApi.entityTypeControllerFindAll(
      EntityTypeEnum.Provider,
      params?.includeRelation,
      params?.search,
      undefined,
      undefined,
      config,
    );

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function fetchProviderType(
  id: string,
  config?: AxiosRequestConfig,
): Promise<EntityType> {
  try {
    const { data } = await providerTypeApi.entityTypeControllerFindOne(id, config);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}
export async function deleteProviderType(id: string): Promise<void> {
  try {
    await providerTypeApi.entityTypeControllerRemove(id);
  } catch (error) {
    throw new CustomError(error);
  }
}
