import { useRef, useState } from 'react';

import { Provider, UpdateProviderServiceDtoUpdateTypeEnum } from '@healthhub/api-lib';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import {
  Checkbox,
  Modal,
  Variants,
  classNames,
  formatProviderDisplayNameWithBranch,
} from '@mwell-healthhub/commons';
import Button from '@mwell-healthhub/commons/components/Button';

type UserDesignationModalProps = {
  branches?: Provider[];
  providerServiceBranchIds: number[];
  onClose: () => void;
  label?: string;
  onSubmit: (
    updateType: UpdateProviderServiceDtoUpdateTypeEnum,
    selectedBranchIds: number[],
  ) => void;
};

export default function ProviderServiceDeleteActionModal(props: UserDesignationModalProps) {
  const { branches, onClose, onSubmit, label, providerServiceBranchIds } = props;

  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>([]);
  const [updateType, setUpdateType] = useState<UpdateProviderServiceDtoUpdateTypeEnum | null>(null);
  const hasMultipleBranches = providerServiceBranchIds.length > 1;

  const branchesToDelete = branches?.filter(
    (branch) => providerServiceBranchIds?.includes(branch.id),
  );

  const confirmSubmission = () => {
    if (!updateType && hasMultipleBranches) {
      return false;
    }

    if (hasMultipleBranches) {
      onSubmit(
        updateType as UpdateProviderServiceDtoUpdateTypeEnum,
        updateType === UpdateProviderServiceDtoUpdateTypeEnum.All
          ? providerServiceBranchIds
          : selectedBranchIds,
      );
    } else {
      onSubmit(
        UpdateProviderServiceDtoUpdateTypeEnum.OverrideSelectedBranches,
        providerServiceBranchIds,
      );
    }
    // onClose?.();
  };

  const renderProviderAssignedBranches = () =>
    branchesToDelete?.map((provider) => {
      return (
        <Checkbox
          key={`${provider.id}-branch-to-override`}
          className="text-secondary-500"
          checked={selectedBranchIds.includes(provider.id)}
          onChange={(e) => {
            const branchServices = selectedBranchIds || [];
            const isChecked = (e.target as HTMLInputElement).checked;
            const updatedBranches = isChecked
              ? [...branchServices, provider.id]
              : branchServices.filter((id: number) => id !== provider.id);

            setSelectedBranchIds(updatedBranches);
          }}
          label={
            <div className="text-neutral-500">{formatProviderDisplayNameWithBranch(provider)}</div>
          }
        />
      );
    });

  const renderNoBranches = (!branchesToDelete || branchesToDelete.length === 0) && (
    <div className="bg-neutral-200 p-2 text-sm text-neutral-400">
      No branches have been added. Please add a branch first.
    </div>
  );

  const renderBranches = updateType ===
    UpdateProviderServiceDtoUpdateTypeEnum.OverrideSelectedBranches && (
    <div className="px-7 py-3">
      <div className="flex flex-col">{renderProviderAssignedBranches()}</div>
      {renderNoBranches}
    </div>
  );

  return (
    <Modal open>
      <div className="mt-10 flex flex-col gap-4 px-6 pb-4 pt-6 md:mt-0 md:flex-row">
        <div
          className={twMerge(
            'flex h-fit w-10 items-center justify-center rounded-full p-2',
            'bg-red-100 text-red-600',
          )}
        >
          <ExclamationTriangleIcon height={24} width={24} />
        </div>
        <div className="pt-[6px]">
          <div className="pb-2 text-lg font-medium leading-[28px] text-gray-900">
            Remove this service?
          </div>
          <div>
            <p className="text-sm text-gray-500">
              Are you sure you want to remove <span className="font-bold">{label}</span>
              {!hasMultipleBranches && branchesToDelete && (
                <span>
                  {' '}
                  for <strong>{formatProviderDisplayNameWithBranch(branchesToDelete[0])}</strong>
                </span>
              )}
              ?
            </p>
          </div>
          {hasMultipleBranches && (
            <div className="mt-5 pr-2">
              <div className={classNames('mt-1.5 flex flex-col gap-5')}>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value={UpdateProviderServiceDtoUpdateTypeEnum.All}
                    className="text-secondary-500 focus:ring-transparent"
                    onClick={() => {
                      setUpdateType(UpdateProviderServiceDtoUpdateTypeEnum.All);
                    }}
                    checked={updateType === UpdateProviderServiceDtoUpdateTypeEnum.All}
                  />
                  <span className="pl-3 text-sm text-neutral-900">
                    Apply changes to all selected branches
                  </span>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value={UpdateProviderServiceDtoUpdateTypeEnum.OverrideSelectedBranches}
                    className="text-secondary-500 focus:ring-transparent"
                    onClick={() => {
                      setUpdateType(
                        UpdateProviderServiceDtoUpdateTypeEnum.OverrideSelectedBranches,
                      );
                    }}
                    checked={
                      updateType === UpdateProviderServiceDtoUpdateTypeEnum.OverrideSelectedBranches
                    }
                  />
                  <span className="pl-3 text-sm text-neutral-900">
                    Choose branch to remove service to
                  </span>
                </div>
                {renderBranches}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-3 rounded-bl-md rounded-br-md px-6 py-4 md:justify-end">
        <Button
          variant="outlined"
          type="button"
          className="h-[52px] w-full md:h-full md:w-fit"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          controls
          disabled={false}
          variant={Variants.ERROR}
          type="button"
          onClick={confirmSubmission}
          isLoading={false}
          className="h-[52px] w-full md:h-full md:w-fit"
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}
