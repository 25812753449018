import { Controller, useForm } from 'react-hook-form';

import { Button, Modal, TextInput } from '@mwell-healthhub/commons';
import DatePicker, { DateObject, DatePickerRef } from 'react-multi-date-picker';
import { BanknotesIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';

export type AppointmentInPersonPaymentDetails = {
  paymentDate: Date;
  paymentMethod: InPersonPaymentMethod;
  eWallet: string;
  transactionNo: string;
};

export enum InPersonPaymentMethod {
  Card = 'Card',
  Cash = 'Cash',
  EWallet = 'EWallet',
}

type Props = {
  isOpen: boolean;
  onUpdate: (paymentData: AppointmentInPersonPaymentDetails) => void;
  toggle: () => void;
  isEditing: boolean;
  isUpdating: boolean;
  inPersonPayment: AppointmentInPersonPaymentDetails;
};

export default function UpdatePaymentDetailsModal({
  isOpen,
  toggle,
  onUpdate,
  isEditing,
  isUpdating,
  inPersonPayment,
}: Props) {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<AppointmentInPersonPaymentDetails>({
    defaultValues: {
      paymentDate: inPersonPayment.paymentDate,
      paymentMethod: inPersonPayment.paymentMethod,
      eWallet: inPersonPayment.eWallet,
      transactionNo: inPersonPayment.transactionNo,
    },
  });

  const handleUpdate = (data: AppointmentInPersonPaymentDetails) => {
    onUpdate(data);
  };

  const paymentDatePickerRef = useRef<DatePickerRef>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        paymentDatePickerRef.current &&
        !paymentDatePickerRef.current.contains(event.target as Node)
      ) {
        paymentDatePickerRef.current?.closeCalendar();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      <Modal open={isOpen} setOpen={toggle} size="md">
        <form onSubmit={handleSubmit(handleUpdate)}>
          <div className="grid grid-cols-auto-fr items-center gap-4 pb-9">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-50">
              <BanknotesIcon width={24} height={24} className="text-green-700" />
            </div>
            <span className="text-lg font-semibold text-gray-900">
              {isEditing ? 'Edit' : 'Add'} Payment Details
            </span>
            <div className="col-start-2 row-start-2 flex w-full flex-col gap-y-5">
              <div className="flex flex-col gap-y-1.5">
                <span className="text-sm text-neutral-600">Payment Date</span>
                <Controller
                  name="paymentDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="flex w-full flex-row flex-nowrap items-center gap-x-3 rounded-md border border-neutral-300 bg-neutral-50 px-3 py-2">
                      <DatePicker
                        ref={paymentDatePickerRef}
                        value={value}
                        format="MM/DD/YYYY"
                        maxDate={new Date()}
                        onChange={(date, { input, isTyping }) => {
                          const dateObj = date as DateObject;
                          if (!isTyping) {
                            return onChange(dateObj.toDate());
                          }

                          const inputEl = input as HTMLInputElement;

                          const strings = inputEl.value.split('/');
                          const numbers = strings.map(Number);
                          const [year, month, day] = numbers;

                          if (inputEl.value && numbers.some((number) => isNaN(number))) {
                            return false;
                          }

                          if (month > 12 || month < 0) {
                            return false;
                          }

                          if (dateObj && 'day' in dateObj && (day < 0 || day > dateObj.day)) {
                            return false;
                          }
                          if (strings.some((val) => val.startsWith('00'))) {
                            return false;
                          }

                          onChange(dateObj.toDate());
                        }}
                        containerClassName="w-full"
                        offsetY={8}
                        inputClass="border-none text-sm text-neutral-600 h-auto w-full p-0 box-border outline-none focus:outline-none focus:ring-0"
                      />
                      <CalendarIcon width={20} height={20} className="text-neutral-400" />
                    </div>
                  )}
                />
              </div>
              <div className="flex flex-col gap-y-3">
                <span className="text-sm text-neutral-600">Payment Method</span>
                <Controller
                  name="paymentMethod"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="grid grid-cols-auto-fr items-center gap-x-3 gap-y-2">
                      <input
                        type="radio"
                        id="cash"
                        name="paymentMethod"
                        value={InPersonPaymentMethod.Cash}
                        onChange={onChange}
                        checked={InPersonPaymentMethod.Cash === value}
                        className="border border-neutral-400 text-secondary-500 checked:bg-secondary-500 focus:bg-secondary-500 focus:ring-transparent"
                      />
                      <label htmlFor="cash">Cash</label>
                    </div>
                  )}
                />
                <Controller
                  name="paymentMethod"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="grid grid-cols-auto-fr items-center gap-x-3 gap-y-2">
                      <input
                        type="radio"
                        id="card"
                        name="paymentMethod"
                        value={InPersonPaymentMethod.Card}
                        onChange={onChange}
                        checked={InPersonPaymentMethod.Card === value}
                        className="border border-neutral-400 text-secondary-500 checked:bg-secondary-500 focus:bg-secondary-500 focus:ring-transparent"
                      />
                      <label htmlFor="card">Card</label>
                    </div>
                  )}
                />
                <Controller
                  name="paymentMethod"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="grid grid-cols-auto-fr items-center gap-x-3 gap-y-2">
                      <input
                        type="radio"
                        id="ewallet"
                        name="paymentMethod"
                        value={InPersonPaymentMethod.EWallet}
                        onChange={onChange}
                        checked={InPersonPaymentMethod.EWallet === value}
                        className="col-span-1 col-start-1 row-start-1 border border-neutral-400 text-secondary-500 checked:text-secondary-500 focus:bg-secondary-500 focus:ring-transparent"
                      />
                      <label htmlFor="ewallet" className="col-span-1 col-start-2 row-span-1">
                        E-wallet
                      </label>
                      {InPersonPaymentMethod.EWallet === value ? (
                        <div className="col-span-1 col-start-2 row-start-2 flex w-full flex-col gap-y-1.5">
                          <span className="text-xs text-neutral-500">
                            (Optional) Specify details, ex. GCash, Maya
                          </span>
                          <Controller
                            name="eWallet"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                type="text"
                                value={value}
                                onChange={onChange}
                                className="border border-neutral-400"
                              />
                            )}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                />
              </div>
              <div className="flex flex-col gap-y-1.5">
                <span className="text-sm text-neutral-600">Transaction Number</span>
                <Controller
                  name="transactionNo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextInput onChange={onChange} value={value} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-full rounded-b-md">
            <div className="flex items-center justify-end gap-2">
              <Button variant="secondaryGray" className="px-4 py-2" onClick={toggle}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" isLoading={isUpdating} className="px-8 py-2">
                {isEditing ? 'Save' : 'Add'}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
