import { Banner, CreateBannerDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  BannerUpdateParams,
  createBanner,
  deleteBanner,
  updateBanner,
} from '../../api/bannerService';

export const useCreateBannerMutation = (
  mutationOptions?: MutationOptions<Banner, AxiosError<{ message: string }>, CreateBannerDto>,
) => {
  return useMutation<Banner, AxiosError<{ message: string }>, CreateBannerDto>(
    createBanner,
    mutationOptions,
  );
};

export const useUpdateBannerMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, BannerUpdateParams>,
) => {
  return useMutation<void, AxiosError<{ message: string }>, BannerUpdateParams>(
    updateBanner,
    mutationOptions,
  );
};

export const useDeleteBannerMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, number>,
) => {
  return useMutation<void, AxiosError<{ message: string }>, number>(
    (id) => deleteBanner(id),
    mutationOptions,
  );
};
