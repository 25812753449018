export const TIME_FORMAT = 'h:mm a';
export const FULL_DATE_FORMAT = 'MMM dd, yyyy';
export const CALENDAR_DATE_FORMAT = 'yyyy-MM-dd';
export const WEEKDAY_FORMAT = 'EEEE';
export const SINGLE_DAY_FORMAT = 'd';
export const COMPLETE_DATE_FORMAT = `${WEEKDAY_FORMAT}, ${FULL_DATE_FORMAT}, ${TIME_FORMAT}`;
export const SHORT_DATE_US_FORMAT = 'MM/dd/yyyy';
export const MONTH_YEAR_FORMAT = 'MMM-yyyy';
export const FULL_MONTH_YEAR_FORMAT = 'MMMM yyyy';
export const DAY_MONTH_YEAR_FORMAT = 'dd MMM yyyy';
export const EVENT_DATE_TIME_FORMAT = 'd MMM yyyy • hh:mm a';
export const MONTH_DAY_YEAR_FORMAT = 'MMM dd, yyy';
export const ISO_18601_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const AM_PM_DATE_FORMAT = 'yyyy-MM-dd hh:mm aa';
export const TWELVE_HOUR_TIME_FORMAT = 'hh:mm a';
export const DATE_TIME_FORMAT_WITH_SINGLE_DIGIT_HOUR = 'd MMM yyyy • h:mm a';
export const MONTH_WORD_FORMAT = 'MMMM';
export const DATE_AND_TIME_FORMAT = 'MMM dd, yyyy hh:mm aa';
export const PLAIN_DATE_FORMAT = 'MMM d yyyy';
export const DATE_AND_TIME = 'MMM dd, yyyy hh:mm aa';
export const DATE_WITH_PERIOD_COMMA = 'MMM. d, yyyy';
export const DATE_WITH_COMMA = 'MMM d, yyyy';
export const DATE_TIME_WITH_DOT = 'MMM. d, yyyy • h:mm aa';
export const DATE_TIME_LOCAL = 'yyyy-MM-dd HH:mm:ss';
export const ISO_8601_FORMAT = "yyyy-MM-dd'T'HH:mm";
export const HOUR_FORMAT = 'H';
export const MILITARY_TIME_FORMAT = 'HH:mm';
export const MILITARY_TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';

// react-multi-date-picker compatible
export const DATE_PICKER_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_PICKER_DATE_WITH_COMMA = 'MMM D, YYYY';
