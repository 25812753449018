import {
  CreateProviderDirectoryDto,
  Provider,
  UpdateProviderDirectoryDto,
} from '@healthhub/api-lib';
import { MutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  GetManyProviderDirectoryParams,
  getProviderDirectories,
  getProviderDirectory,
  approveProvider,
  deleteProvider,
  updateProviderDirectory,
  createProviderDirectory,
} from '../api/providerDirectoryService';
import { GET_MANY_PROVIDER_DIRECTORY, GET_PROVIDER_DIRECTORY_BY_ID } from '../constants';

export const useGetManyProviderDirectoryQuery = (params: GetManyProviderDirectoryParams) => {
  const { searchQuery, status, limit, page, providerTypeId, longitude, latitude } = params;

  return useQuery(
    [
      GET_MANY_PROVIDER_DIRECTORY,
      searchQuery,
      status,
      limit,
      page,
      providerTypeId,
      longitude,
      latitude,
    ],
    () => getProviderDirectories(params),
    {
      enabled: true,
    },
  );
};

export const useGetProviderDirectory = (id: string) => {
  return useQuery([GET_PROVIDER_DIRECTORY_BY_ID, id], () => getProviderDirectory(id), {
    enabled: !!id,
  });
};

export const useApproveProviderDirectoryMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, string>,
) => useMutation(approveProvider, mutationOptions);

export const useDeleteProviderDirectoryMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, string>,
) => useMutation(deleteProvider, mutationOptions);

export const useUpdateProviderDirectoryMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    { id: number; dto: UpdateProviderDirectoryDto }
  >,
) => useMutation(({ id, dto }) => updateProviderDirectory(id.toString(), dto), mutationOptions);

export const useCreateProviderDirectoryMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    CreateProviderDirectoryDto
  >,
) => useMutation((dto) => createProviderDirectory(dto), mutationOptions);
