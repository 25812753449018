export const REGISTRATION_CHARACTER_LIMIT = {
  USERNAME: 125,
  PASSWORD: 125,
  EMAIL: 125,
  LAST_NAME: 50,
  FIRST_NAME: 50,
  MIDDLE_NAME: 50,
  CITY_MUNICIPALITY: 125,
  SUFFIX_EXTENSION_NAME: 125,
};
