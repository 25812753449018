export enum DiscountMethodEnum {
  FixedPrice = 'FIXED_PRICE',
  PercentOffPrice = 'PERCENT_OFF_PRICE',
  PesoOffPrice = 'PESO_OFF_PRICE',
}

export enum DiscountDurationStatusEnum {
  UPCOMING = 'Upcoming',
  ONGOING = 'Ongoing',
  ENDED = 'Ended',
}

export enum DiscountPlatformEnum {
  Healthhub = 'healthhub',
  // Eshop = 'eshop',
}

export enum DiscountType {
  Product = 'PRODUCT',
  Order = 'ORDER',
}

export enum DiscountValueEnum {
  'PERCENT_OFF_PRICE' = 'Percent off price',
  'PESO_OFF_PRICE' = 'Peso off price',
  'FIXED_PRICE' = 'Fixed price',
}

export enum DiscountBranchSelectionEnum {
  ALL_BRANCHES = 'All branches',
  SELECTED_BRANCHES = 'Only selected branches',
}

export enum DiscountServiceSelectionEnum {
  ALL_SERVICES = 'All services',
  SELECTED_SERVICES = 'Only selected services',
}

export enum DiscountActiveDaysTypeEnum {
  ALL_DAYS = 'All days',
  SELECTED_DAYS = 'Only selected days',
}

export enum DiscountActiveHoursTypeEnum {
  ALL_HOURS = 'All hours',
  SPECIFIC_HOURS = 'Only specific hours',
}

export enum DiscountStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DiscountDaysEnum {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export const DiscountTypeLabels = {
  [DiscountType.Product]: 'Per product discount',
  [DiscountType.Order]: 'Per order discount',
};

export enum DiscountFormEnum {
  ACTIVE_DAYS = 'activeDays',
  ACTIVE_DAYS_TYPE = 'activeDaysType',
  ACTIVE_HOURS_END = 'activeHoursEnd',
  ACTIVE_HOURS_START = 'activeHoursStart',
  ACTIVE_HOURS_TYPE = 'activeHoursType',
  BRANCH_SELECTION_TYPE = 'branchSelectionType',
  CATEGORY_ID = 'categoryId',
  END_DATE = 'endDate',
  END_TIME = 'endTime',
  HAS_END_DATE = 'hasEndDate',
  METHOD = 'method',
  NAME = 'name',
  PROVIDER_ID = 'providerId',
  SELECTED_BRANCHES = 'selectedBranches',
  SELECTED_SERVICES = 'selectedServices',
  SERVICE_SELECTION_TYPE = 'serviceSelectionType',
  START_DATE = 'startDate',
  START_TIME = 'startTime',
  STATUS = 'status',
  VALUE = 'value',
}
