import { useEffect } from 'react';

import {
  CreateServiceScheduleDto,
  CreateServiceScheduleDtoBranchSelectionTypeEnum,
  CreateServiceScheduleDtoDaysEnum,
  Provider,
  ProviderService,
  ProviderServiceSchedule,
  ScheduleDayEnum,
  TimeSlot,
  UserOperation,
  UserProvider,
} from '@healthhub/api-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import get from 'lodash.get';
import { FormProvider, Resolver, useForm } from 'react-hook-form';

import {
  FormPageLayout,
  OperationUserType,
  ProviderUserType,
  toast,
  useToggle,
  BRAND_ADMIN_USERS,
  formatToHumanReadable,
  MultiSelectOptionType,
  useRouter,
  CustomServiceScheduleEnum,
  formatProviderDisplayNameWithBranch,
  GET_SERVICE_SCHEDULE,
  BranchSelectionEnum,
} from '@mwell-healthhub/commons';

import ConfirmRemoveServiceScheduleModal from './ConfirmRemoveServiceScheduleModal';
import ConfirmReplaceExistingScheduleModal from './ConfirmReplaceExistingScheduleModal';
import ProviderSelectServicesFormField from './ProviderSelectServicesFormField';
import ProviderServiceCustomScheduleAvailabilityFormFields from './ProviderServiceCustomScheduleAvailabilityFormFields';
import ProviderServiceCustomScheduleSetupFormFields from './ProviderServiceCustomScheduleSetupFormFields';
import {
  useCreateServiceSchedule,
  useDeleteServiceSchedule,
  useUpdateServiceSchedule,
  useCheckServiceScheduleExistence,
} from '../hooks/mutations/providerServiceScheduleMutations';
import { serviceScheduleSchema } from '../validators/service-schedule';

export type ProviderServiceCustomScheduleFormValues = {
  isAddingToExisting?: boolean;
  isEditing?: boolean;
  isCurrentUserSuperAdmin?: boolean;
  [CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS]?: MultiSelectOptionType[];
  [CustomServiceScheduleEnum.PROVIDER_SERVICES_IDS]?: number[];
  [CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE]: CreateServiceScheduleDtoBranchSelectionTypeEnum;
  [CustomServiceScheduleEnum.SERVICES]: MultiSelectOptionType[];
  [CustomServiceScheduleEnum.TIMESLOT_INTERVAL_IN_MINUTES]: number;
  [CustomServiceScheduleEnum.SLOTS]: number;
  [CustomServiceScheduleEnum.PROVIDER_ID]?: number;
  [CustomServiceScheduleEnum.TIMESLOTS]: TimeSlot[];
  [CustomServiceScheduleEnum.DAYS]: MultiSelectOptionType[];
};

type Props = {
  providerService?: ProviderService;
  brand: Provider;
  branch: Provider; // provider where to save the service
  currentUser: UserProvider | UserOperation;
  onSuccess?: () => void;
  showBackButton?: boolean;
  isUserBrandLevelAdmin?: boolean;
  serviceSchedule?: ProviderServiceSchedule;
  defaultAddToServiceScheduleValues?: {
    [CustomServiceScheduleEnum.DAYS]?: MultiSelectOptionType[];
    [CustomServiceScheduleEnum.SERVICES]?: MultiSelectOptionType[];
    [CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS]?: MultiSelectOptionType[];
  };
};

function ProviderCustomServiceScheduleForm(props: Readonly<Props>) {
  const {
    providerService,
    brand,
    currentUser,
    branch,
    onSuccess,
    showBackButton = true,
    serviceSchedule,
    defaultAddToServiceScheduleValues,
  } = props;

  const router = useRouter();
  const queryClient = useQueryClient();
  const isCurrentUserSuperAdmin = BRAND_ADMIN_USERS.includes(
    currentUser?.userType as ProviderUserType | OperationUserType,
  );

  const { handleToggle: handleToggleExistingScheduleModal, isOpen: isOpenExistingScheduleModal } =
    useToggle();
  const { handleToggle: handleToggleRemoveModal, isOpen: isOpenRemoveModal } = useToggle();

  const isEditing = !!serviceSchedule;
  const isAddingToExisting = !!defaultAddToServiceScheduleValues;

  const addToExistingDefaultService = defaultAddToServiceScheduleValues?.services;
  const addToExistingDefaultDays = defaultAddToServiceScheduleValues?.days;
  const addToExistingDefaultBranchIds = defaultAddToServiceScheduleValues?.providerServiceBranchIds;

  const defaultService: MultiSelectOptionType = {
    text: serviceSchedule?.providerService?.label || '', // Use Branch Service Label to make it easier for providers to figure out the service, for minimal code changes
    value: serviceSchedule?.parentProviderService?.id.toString() || '', // Use Brand Service ID
  };
  const defaultDay: MultiSelectOptionType = {
    text:
      Object.keys(ScheduleDayEnum).find(
        (key) => ScheduleDayEnum[key as keyof typeof ScheduleDayEnum] === serviceSchedule?.day,
      ) || '',
    value: serviceSchedule?.day || '',
  };
  const formMethods = useForm<ProviderServiceCustomScheduleFormValues>({
    resolver: yupResolver(
      serviceScheduleSchema,
    ) as unknown as Resolver<ProviderServiceCustomScheduleFormValues>,
    defaultValues: {
      isAddingToExisting,
      isCurrentUserSuperAdmin,
      isEditing,
      [CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS]: isEditing
        ? [
            {
              text: formatProviderDisplayNameWithBranch(serviceSchedule?.provider),
              value: serviceSchedule?.provider.id.toString(),
            } as MultiSelectOptionType,
          ]
        : isAddingToExisting
        ? addToExistingDefaultBranchIds
        : undefined,
      [CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE]:
        isEditing || isAddingToExisting ? BranchSelectionEnum.SPECIFIC : undefined,
      [CustomServiceScheduleEnum.SERVICES]: isEditing
        ? [defaultService]
        : isAddingToExisting
        ? addToExistingDefaultService
        : undefined,
      [CustomServiceScheduleEnum.TIMESLOTS]: isEditing
        ? serviceSchedule.timeSlots
        : [{ start: '', end: '' }],
      [CustomServiceScheduleEnum.TIMESLOT_INTERVAL_IN_MINUTES]: isEditing
        ? serviceSchedule.timeSlotIntervalInMinutes
        : undefined,
      [CustomServiceScheduleEnum.SLOTS]: isEditing ? serviceSchedule.slots : undefined,
      [CustomServiceScheduleEnum.DAYS]: isEditing
        ? [defaultDay]
        : isAddingToExisting
        ? addToExistingDefaultDays
        : undefined,
    },
  });

  const {
    watch,
    handleSubmit,
    formState: { errors },
    setError,
  } = formMethods;
  useEffect(() => {
    if (!providerService) {
      return;
    }
  }, [providerService]);

  const { services } = watch();

  function handleSuccess() {
    queryClient.invalidateQueries([GET_SERVICE_SCHEDULE]);
    onSuccess?.();
  }

  function handleError(err: Error) {
    const errorDetails = err.message;

    if (Array.isArray(errorDetails)) {
      errorDetails.forEach((errorDetail) => {
        setError(errorDetail.errorProperty, {
          type: 'exists',
          message: errorDetail.errorMessage,
        });
      });

      return;
    }

    toast({ type: 'error', message: 'Something went wrong' });
  }

  const handleCancel = () => {
    router.back();
  };

  const { mutate: checkServiceScheduleExistence, isLoading: isCheckingServiceScheduleExistence } =
    useCheckServiceScheduleExistence({});

  const { mutate: createProviderServiceSchedule, isLoading: isSavingServiceSChedule } =
    useCreateServiceSchedule({
      onSuccess: () => {
        toast({
          type: 'success',
          message: 'Service created.',
        });
        handleSuccess();
      },
      onError: (error) => {
        toast({
          message: get(error, 'response.data.message', 'Something went wrong'),
          type: 'error',
        });
      },
    });

  const { mutate: updateProviderServiceSchedule, isLoading: isUpdatingServiceSChedule } =
    useUpdateServiceSchedule({
      onSuccess: () => {
        toast({
          type: 'success',
          message: 'Service updated.',
        });
        handleSuccess();
      },
      onError: (error) => {
        toast({
          message: get(error, 'response.data.message', 'Something went wrong'),
          type: 'error',
        });
      },
    });

  const { mutate: deleteServiceSchedule, isLoading: deletingServiceSchedule } =
    useDeleteServiceSchedule({
      onSuccess: () => {
        toast({
          type: 'success',
          message: 'Service removed.',
        });
        handleSuccess();
      },
      onError: (error) => {
        toast({
          message: get(error, 'response.data.message', 'Something went wrong'),
          type: 'error',
        });
      },
    });

  const isLoading = isSavingServiceSChedule || isUpdatingServiceSChedule || deletingServiceSchedule;

  const onSubmit = async (newSchedule: ProviderServiceCustomScheduleFormValues) => {
    const {
      timeSlotIntervalInMinutes,
      timeSlots,
      days,
      slots,
      providerServiceBranchIds,
      branchSelectionType,
      services,
    } = newSchedule;

    const formatDaysValue: CreateServiceScheduleDtoDaysEnum[] = days?.map(
      (day) => day.value as CreateServiceScheduleDtoDaysEnum,
    );
    const formatProviderServiceBranchIds: number[] =
      providerServiceBranchIds?.map((id) => +id.value) ?? [];
    const formatServices = services.map((service) => Number(service.value));

    const data: CreateServiceScheduleDto = {
      timeSlotIntervalInMinutes: +timeSlotIntervalInMinutes,
      timeSlots,
      slots,
      branchIds: formatProviderServiceBranchIds,
      brandId: brand.id?.toString() ?? '',
      providerServiceIds: formatServices,
      days: formatDaysValue,
      branchSelectionType,
    };

    if (isEditing) {
      updateProviderServiceSchedule({
        id: serviceSchedule.id.toString(),
        updateServiceScheduleDto: data,
      });
    } else {
      checkServiceScheduleExistence(
        {
          providerServiceIds: formatServices,
          branchIds: formatProviderServiceBranchIds,
          days: formatDaysValue,
        },
        {
          onSuccess: (response) => {
            if (response === false) {
              createServiceSchedule();
            } else {
              handleToggleExistingScheduleModal();
            }
          },
        },
      );
    }
  };

  const createServiceSchedule = () => {
    const {
      timeSlotIntervalInMinutes,
      timeSlots,
      days,
      slots,
      providerServiceBranchIds,
      branchSelectionType,
      services,
    } = watch();

    if (isOpenExistingScheduleModal) {
      handleToggleExistingScheduleModal();
    }

    const formatDaysValue: CreateServiceScheduleDtoDaysEnum[] = days?.map(
      (day) => day.value as CreateServiceScheduleDtoDaysEnum,
    );
    const formatProviderServiceBranchIds: number[] =
      providerServiceBranchIds?.map((id) => +id.value) ?? [];
    const formatServices = services.map((service) => Number(service.value));

    const data: CreateServiceScheduleDto = {
      timeSlotIntervalInMinutes: +timeSlotIntervalInMinutes,
      timeSlots,
      slots,
      branchIds: formatProviderServiceBranchIds,
      brandId: brand.id?.toString() ?? '',
      providerServiceIds: formatServices, //TODO: check if brand level service Ids
      days: formatDaysValue,
      branchSelectionType,
    };

    createProviderServiceSchedule(data);
  };

  const handleRemoveServiceSchedule = () => {
    if (!serviceSchedule) {
      return;
    }
    handleToggleRemoveModal();

    deleteServiceSchedule({ id: serviceSchedule.id.toString() });
  };

  const formTitlePrefix = isAddingToExisting ? 'Add to Existing' : isEditing ? 'Edit' : 'Set';

  const formTitle = `${formTitlePrefix} Service Schedule`;
  const submitButtonText = isEditing ? 'Save' : 'Set Service Schedule';

  const shouldShowBranchApplyMessage = branch?.isBranch && isCurrentUserSuperAdmin;
  const servicesList = services?.map((service) => service.text).join(', ') || [];

  const isCurrentUserBranchUser =
    currentUser && 'assignedBranches' in currentUser && !isCurrentUserSuperAdmin;

  return (
    <FormProvider {...formMethods}>
      <FormPageLayout
        showBackButton={showBackButton}
        isLoading={isLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit(onSubmit)}
        onDelete={isEditing ? handleToggleRemoveModal : undefined}
        submitButtonText={submitButtonText}
        deleteButtonText="Remove"
        title={formTitle}
        subtitle="Customize the schedule of a service. This will not override your regular schedule"
        headerClass="flex-col"
      >
        <div className="relative z-[1] flex flex-col gap-8">
          <div className="rounded-lg border border-gray39 bg-white px-6 py-4 drop-shadow-sm md:p-8">
            <div className="flex flex-col gap-4 md:gap-5">
              <h4 className="text-base font-semibold md:text-lg">Apply Schedule to</h4>
              {serviceSchedule && (
                <div className="rounded-md bg-blue-50 p-4 text-sm text-primary-500">
                  <span className="font-semibold">
                    Last updated:{' '}
                    {formatToHumanReadable(serviceSchedule.updatedAt || serviceSchedule.createdAt)}.
                  </span>{' '}
                  &nbsp;
                  {shouldShowBranchApplyMessage && (
                    <span>Changes made to this service will only apply to this branch.</span>
                  )}
                </div>
              )}
              <ProviderServiceCustomScheduleAvailabilityFormFields
                brand={brand}
                isBranch={branch?.isBranch}
                providerService={providerService}
                serviceSchedule={serviceSchedule}
                currentUser={currentUser}
                assignedBranches={isCurrentUserBranchUser ? currentUser?.assignedBranches : []}
                isEditing={isEditing || isAddingToExisting}
              />
              <ProviderSelectServicesFormField
                brand={brand}
                branch={branch}
                isCurrentUserBranchUser={isCurrentUserBranchUser}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <div className="rounded-lg border border-gray39 bg-white px-6 py-4 drop-shadow-sm md:p-8">
            <div className="flex flex-col gap-4 md:gap-5">
              <h4 className="text-base font-semibold md:text-lg">Schedule</h4>
              <div className="text-sm font-normal leading-tight tracking-tight text-gray-600">
                Customize when the selected service(s) is available to clients
              </div>
              <ProviderServiceCustomScheduleSetupFormFields />
            </div>
          </div>
        </div>
      </FormPageLayout>
      {isOpenExistingScheduleModal && (
        <ConfirmReplaceExistingScheduleModal
          isOpen={isOpenExistingScheduleModal}
          toggle={handleToggleExistingScheduleModal}
          onSave={createServiceSchedule}
        >
          {servicesList}
        </ConfirmReplaceExistingScheduleModal>
      )}
      {isOpenRemoveModal && (
        <ConfirmRemoveServiceScheduleModal
          isOpen={isOpenRemoveModal}
          toggle={handleToggleRemoveModal}
          onSave={handleRemoveServiceSchedule}
        />
      )}
    </FormProvider>
  );
}

export default ProviderCustomServiceScheduleForm;
