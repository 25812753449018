import {
  Appointment,
  BulkCancelDto,
  BulkRescheduleDto,
  CreateAppointmentDto,
  CreateGroupBookingDto,
  CreateMultiScheduleAppointmentDto,
  RescheduleAppointmentDto,
  UpdateAppointmentDto,
  UpdateInPersonPaymentDto,
} from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  addAppointment,
  addGroupAppointment,
  addAppointmentTags,
  approveAppointment,
  cancelAppointment,
  completeAppointment,
  declineAppointment,
  noShowAppointment,
  ongoingAppointment,
  rescheduleAppointment,
  updateInPersonPayment,
  bulkRescheduleAppointments,
  bulkCancelAppointments,
  bookMultiScheduleAppointment,
} from '../../api';

export const useBookAppointmentMutation = (
  mutationOptions?: MutationOptions<
    Appointment,
    AxiosError<{ message: string }>,
    CreateAppointmentDto
  >,
) => useMutation(addAppointment, mutationOptions);

export const useBookGroupAppointmentMutation = (
  mutationOptions?: MutationOptions<
    Appointment[],
    AxiosError<{ message: string }>,
    CreateGroupBookingDto
  >,
) => useMutation(addGroupAppointment, mutationOptions);

export const useBulkRescheduleAppointments = (
  mutationOptions?: MutationOptions<
    Appointment[],
    AxiosError<{ message: string }>,
    BulkRescheduleDto
  >,
) => useMutation(bulkRescheduleAppointments, mutationOptions);

export const useBookMultiScheduleAppointment = (
  mutationOptions?: MutationOptions<
    Appointment[],
    AxiosError<{ message: string }>,
    CreateMultiScheduleAppointmentDto
  >,
) => useMutation(bookMultiScheduleAppointment, mutationOptions);

export const useBulkCancelAppointments = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, BulkCancelDto>,
) => useMutation(bulkCancelAppointments, mutationOptions);

export const useCancelAppointmentMutation = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    { id: number; appointmentDto: UpdateAppointmentDto }
  >,
) => useMutation(cancelAppointment, mutationOptions);

export const useRescheduleAppointmentMutation = (
  mutationOptions?: MutationOptions<
    number,
    AxiosError<{ message: string }>,
    { id: number; rescheduleAppointmentDto: RescheduleAppointmentDto }
  >,
) => useMutation(rescheduleAppointment, mutationOptions);

export const useAddAppointmentTagMutation = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    { id: string; updateAppointmentDto: UpdateAppointmentDto }
  >,
) => useMutation(addAppointmentTags, mutationOptions);

export const useOngoingAppointmentMutation = (
  mutationOptions?: MutationOptions<number, AxiosError<{ message: string }>, number>,
) => useMutation(ongoingAppointment, mutationOptions);

export const useNoShowAppointmentMutation = (
  mutationOptions?: MutationOptions<number, AxiosError<{ message: string }>, number>,
) => useMutation(noShowAppointment, mutationOptions);

export const useCompleteAppointmentMutation = (
  mutationOptions?: MutationOptions<number, AxiosError<{ message: string }>, number>,
) => useMutation(completeAppointment, mutationOptions);

export const useApproveAppointmentMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, number>,
) => useMutation(approveAppointment, mutationOptions);

export const useDeclineAppointmentMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, number>,
) => useMutation(declineAppointment, mutationOptions);

export const useUpdateInPersonPaymentMutation = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    { id: string; updateInPersonPaymentDto: UpdateInPersonPaymentDto }
  >,
) => useMutation(updateInPersonPayment, mutationOptions);
