import { ProviderClientNotes } from '@healthhub/api-lib';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  findProviderClientNotes,
  findProviderClientNotesById,
  GetProviderClientNotesDto,
} from '../../api';
import {
  GET_PROVIDER_CLIENT_NOTES,
  GET_PROVIDER_CLIENT_NOTES_BY_ID,
} from '../../constants/queryKeys';
import { Pagination } from '../../types';

export const useFindProviderClientNotesById = (
  id: string,
  options?: UseQueryOptions<ProviderClientNotes>,
) => {
  return useQuery<ProviderClientNotes>(
    [GET_PROVIDER_CLIENT_NOTES_BY_ID, { id }],
    () => findProviderClientNotesById(id),
    options,
  );
};

export const useFindProviderClientNotes = (
  params: GetProviderClientNotesDto,
  options?: UseQueryOptions<Pagination<ProviderClientNotes>>,
) => {
  const { endDate, startDate, query, limit, page, providerClientProfileId, appointmentId } = params;
  return useQuery<Pagination<ProviderClientNotes>>(
    [
      GET_PROVIDER_CLIENT_NOTES,
      {
        startDate,
        endDate,
        query,
        limit,
        page,
        providerClientProfileId,
        appointmentId,
      },
    ],
    () => findProviderClientNotes(params),
    options,
  );
};
