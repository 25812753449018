import { Announcement, CreateAnnouncementDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  UpdateAnnouncementParam,
  createAnnouncement,
  deleteAnnouncement,
  updateAnnouncement,
} from '../../api/announcementService';

export const useCreateAnnouncementMutation = (
  mutationOptions?: MutationOptions<
    Announcement,
    AxiosError<{ message: string }>,
    CreateAnnouncementDto
  >,
) => useMutation(createAnnouncement, mutationOptions);

export const useUpdateAnnouncementMutation = (
  mutationOptions?: MutationOptions<
    Announcement,
    AxiosError<{ message: string }>,
    UpdateAnnouncementParam
  >,
) => useMutation(updateAnnouncement, mutationOptions);

export const useRemoveAnnouncementMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, string>,
) => useMutation(deleteAnnouncement, mutationOptions);
