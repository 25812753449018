import { Schedule, ScheduleDataDto, ScheduleResponse } from '@healthhub/api-lib';
import {
  addDays,
  addHours,
  addMinutes,
  addWeeks,
  differenceInMinutes,
  differenceInYears,
  eachHourOfInterval,
  eachMinuteOfInterval,
  endOfDay,
  endOfWeek,
  format,
  getDay,
  getHours,
  getMinutes,
  getSeconds,
  isSameDay,
  isToday,
  isValid,
  parse,
  set,
  startOfDay,
  startOfWeek,
  subMinutes,
} from 'date-fns';
import chunk from 'lodash.chunk';
import groupBy from 'lodash.groupby';
import isEmpty from 'lodash.isempty';

import { toTitleCase } from './string';
import {
  AM_PM_DATE_FORMAT,
  CALENDAR_DATE_FORMAT,
  COMPLETE_DATE_FORMAT,
  DAYS_OF_WEEK,
  FULL_DATE_FORMAT,
  ISO_18601_FORMAT,
  MAX_HOUR,
  MONTH_WORD_FORMAT,
} from '../constants';
import { MonthEnum } from '../enums';
import { DateRange, Slot } from '../types';

const MILITARY_TIME_FORMAT = 'HH:mm';
const MILITARY_TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';
const TWELVE_HOUR_TIME_FORMAT = 'hh:mm aa';

export function formatFullDateTime(date: Date) {
  return format(date, 'MMM dd, yyyy h:mm a');
}

export function convertDateByFormat(date: Date | string, formatToConvert: string): string {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    const formattedDate = new Date(date);
    return format(formattedDate, formatToConvert);
  }
  return format(date, formatToConvert);
}

export function addWeeksToDate(date: Date, weeks: number) {
  return addWeeks(date, weeks);
}

export function addMinutesToDate(date: Date, minutes: number): Date {
  return addMinutes(date, minutes);
}

export function subMinutesToDate(date: Date, minutes: number): Date {
  return subMinutes(date, minutes);
}

export function subDaysToDate(date: Date, days: number): Date {
  return subMinutes(date, days);
}

export function formatDateTimeWithDay(date: Date, timeSeparator = ' — ') {
  return format(date, `EEEE, MMMM dd, yyyy ${timeSeparator} hh:mm a`);
}

export function convertIsoFormatTime(time: string) {
  if (!time || !isTimeStringValid(time)) {
    return '';
  }

  const timeParts = time.split(':');

  let hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  const date = new Date();

  const isPM = time.toLowerCase().includes('pm');

  if (isPM && hours !== 12) {
    hours += 12;
  }

  date.setHours(hours);
  date.setMinutes(minutes);

  const formattedTime = format(date, TWELVE_HOUR_TIME_FORMAT);

  return formattedTime;
}

function isTimeStringValid(time: string) {
  const formats = ['HH:mm', 'hh:mm a', 'HH:mm:ss'];

  for (const format of formats) {
    const parsedTime = parse(time, format, new Date());

    if (isValid(parsedTime)) {
      return true;
    }
  }

  return false;
}

export function calculateAge(birthDate: Date): number {
  const currentDate = new Date();
  return differenceInYears(currentDate, birthDate);
}

export function calculateAgeHumanReadable(birthDate: Date): string {
  const currentDate = new Date();
  const ageInYears = differenceInYears(currentDate, birthDate);

  if (ageInYears <= 1 || isNaN(ageInYears)) {
    return `${isNaN(ageInYears) ? 0 : ageInYears}y`;
  } else {
    return `${ageInYears}y`;
  }
}

export function groupTimeSlots(timeSlots: Slot[], size = 3) {
  return chunk(timeSlots, size);
}

export function getAllDaysInRange(dayRange: string): string[] {
  const [startDay, endDay] = dayRange.split(' - ');

  const startIndex = DAYS_OF_WEEK.indexOf(startDay);
  const endIndex = DAYS_OF_WEEK.indexOf(endDay);

  const allDays: string[] = [];

  if (startIndex !== -1 && endIndex !== -1) {
    if (startIndex <= endIndex) {
      for (let i = startIndex; i <= endIndex; i++) {
        allDays.push(DAYS_OF_WEEK[i]);
      }
    } else {
      for (let i = startIndex; i < DAYS_OF_WEEK.length; i++) {
        allDays.push(DAYS_OF_WEEK[i]);
      }
      for (let i = 0; i <= endIndex; i++) {
        allDays.push(DAYS_OF_WEEK[i]);
      }
    }
  }

  return allDays;
}

export const formatDateTimeRange = (dateString: string) => {
  const date = new Date(dateString);

  const formattedStartTime = format(date, TWELVE_HOUR_TIME_FORMAT);

  if (isToday(date)) {
    return `Today ${formattedStartTime}`;
  } else {
    const formattedDate = format(date, 'MMM d, yyyy');

    return `${formattedDate} ${formattedStartTime}`;
  }
};

export const getDateOnly = (date?: Date): string => {
  const formattedDate = format(date ? new Date(date) : new Date(), 'yyyy-MM-dd');
  return formattedDate;
};

export const getTimeOnly = (date?: Date): string => {
  const formattedTime = format(date ? new Date(date) : new Date(), TWELVE_HOUR_TIME_FORMAT);
  return formattedTime;
};

export const appendDateAndTime = (date: Date, time?: string): Date => {
  const formattedDate = getDateOnly(date);

  if (time) {
    const dateTimeString = `${formattedDate} ${time}`;
    const dateTimeParts = dateTimeString.split(/[- :]/);

    if (dateTimeParts.length === 6) {
      const year = parseInt(dateTimeParts[0]);
      const month = parseInt(dateTimeParts[1]) - 1;
      const day = parseInt(dateTimeParts[2]);
      const hours = parseInt(dateTimeParts[3]);
      const minutes = parseInt(dateTimeParts[4]);
      const seconds = parseInt(dateTimeParts[5]);

      if (
        !isNaN(year) &&
        !isNaN(month) &&
        !isNaN(day) &&
        !isNaN(hours) &&
        !isNaN(minutes) &&
        !isNaN(seconds)
      ) {
        return new Date(year, month, day, hours, minutes, seconds);
      }
    }
  }

  return date;
};

export const getDateWithTimeObject = (date: Date, time: string): Date => {
  const formattedDate = getDateOnly(date);
  const dateTimeString = `${formattedDate} ${time}`;

  return parse(dateTimeString, AM_PM_DATE_FORMAT, new Date());
};

export const formatDateTime = (date: Date): string => {
  return format(date, AM_PM_DATE_FORMAT);
};

export const getDateWithMilitaryTimeObject = (date: Date, time: string): Date => {
  const formattedDate = getDateOnly(date);
  const dateTimeString = `${formattedDate} ${time}`;
  return parse(dateTimeString, ISO_18601_FORMAT, new Date());
};

export const formatToHumanReadable = (date: Date | string, formatString?: string) => {
  if (!isValid(new Date(date))) {
    return '';
  }

  return format(new Date(date), formatString ?? FULL_DATE_FORMAT);
};

export const getTimeFromDate = (date: Date) => {
  return format(date, TWELVE_HOUR_TIME_FORMAT);
};

export const convertTimeTo12HourFormat = (time: string) => {
  if (!time || !isTimeStringValid(time)) {
    return '';
  }
  const currentDate = getDateOnlyFromCurrentDateTime();
  const date = new Date(`${currentDate}T${time}`);

  return format(date, 'hh:mm aa');
};

export const convertTimeTo24HourFormat = (time: string) => {
  if (!time || !isTimeStringValid(time)) {
    return '';
  }
  const currentDate = getDateOnlyFromCurrentDateTime();
  const date = new Date(`${currentDate}T${time}`);

  return format(date, MILITARY_TIME_FORMAT_WITH_SECONDS);
};

export const formatDateToDatabaseTimestamp = (date: string, time: string): string => {
  // Split the time into hours and minutes
  const [hours, minutes, seconds = '00'] = time.split(':');

  // Create a new Date object using the provided date and time values
  const formattedDate = new Date(`${date}T${hours}:${minutes}:${seconds}.000Z`);

  // Format the date to the database timestamp format "YYYY-MM-DD HH:mm:ss"
  const formattedTimestamp = formattedDate.toISOString().slice(0, 19).replace('T', ' ');

  return formattedTimestamp;
};

export const getTotalMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
};

export const isTimeSlotAllowed = (
  date: Date,
  availableSchedule?: Record<string, string[]>,
): boolean => {
  if (!availableSchedule) {
    return true;
  }

  const day = getDay(date);
  const time = format(date, MILITARY_TIME_FORMAT);

  const isDayRestricted = isDaySlotRestricted(day, availableSchedule);

  if (!isDayRestricted) return false;

  return !isDayRestricted(time);
};

export const isDaySlotRestricted = (day: number, availableSchedule?: Record<string, string[]>) => {
  if (!availableSchedule) return;

  const dayOfWeek = DAYS_OF_WEEK[day];
  const daySlots = availableSchedule[dayOfWeek];

  if (!daySlots) {
    return false;
  }

  return (time: string) => !daySlots.includes(time);
};

export const formatMilitaryTimeToAmPm = (
  time: string,
  alwaysShowMinute = false,
  alwaysShowAmPm = true,
) => {
  const [hour, minutes] = time.split(':');
  const date = new Date();
  date.setHours(parseInt(hour, 10));
  date.setMinutes(parseInt(minutes, 10));

  let formatString = alwaysShowAmPm ? 'haaa' : 'h';
  if (alwaysShowMinute || minutes !== '00') {
    formatString = alwaysShowAmPm ? 'h:mmaaa' : 'h:mm';
  }
  return format(date, formatString);
};

export const getTimeObjectFromMilitaryTime = (militaryTime: string) => {
  const [hours, minutes] = militaryTime.split(':').map(Number);
  const date = new Date();

  date.setHours(hours, minutes, 0, 0);

  return date;
};

export const getDateTimesWithInterval = (
  startDate: Date,
  stepsType: 'hour' | 'minute',
  steps: number,
  offset = 0,
  timeFormat = MILITARY_TIME_FORMAT,
  endDate?: Date,
) => {
  const interval = { start: startDate, end: endDate ?? endOfDay(startDate) };
  if (stepsType === 'hour') {
    if (offset) {
      interval.start = addHours(interval.start, offset);
    }
    return eachHourOfInterval(interval, { step: steps }).map((date) => ({
      value: date.getHours(),
      text: format(date, timeFormat),
    }));
  } else if (stepsType === 'minute') {
    return eachMinuteOfInterval(interval, { step: steps }).map((date) => ({
      value: date.getMinutes(),
      text: format(date, timeFormat),
    }));
  }
};

export const getTimeOptions = (
  from = 0,
  min = 0,
  sec = 0,
  steps = 5,
  to = MAX_HOUR,
  timeFormat = MILITARY_TIME_FORMAT,
) => {
  const startDate = new Date();

  startDate.setHours(from, min, sec);

  const endTime = to;

  let endDate = new Date();

  if (from >= 1 && from < 5) {
    endDate.setHours(endTime, 59, 59);
  } else {
    endDate = addDays(endDate, 1);
    endDate.setHours(endTime, 59, 59);
  }

  const interval = { start: startDate, end: endDate };
  const minutes = eachMinuteOfInterval(interval, { step: steps > 0 ? steps : 5 });
  const times = minutes.map((minute) => format(minute, timeFormat));

  return times;
};

export const segregateHoursMinsSecs = (timeString: string) => {
  const parsedTime = parse(timeString, 'H:mm', new Date());
  const hours = getHours(parsedTime);
  const minutes = getMinutes(parsedTime);
  const seconds = getSeconds(parsedTime);
  return { hours, minutes, seconds };
};

export const getHourFromMilitaryTime = (militaryTime: string) => {
  const date = parse(militaryTime, MILITARY_TIME_FORMAT, new Date());

  const hour = getHours(date);
  return hour;
};

export const removeSecondsFromTime = (militaryTime: string) => {
  const parsedTime = parse(militaryTime, MILITARY_TIME_FORMAT_WITH_SECONDS, new Date());
  const formattedTime = format(parsedTime, MILITARY_TIME_FORMAT);

  return formattedTime;
};

export const getDateOnlyFromCurrentDateTime = (): string => {
  const currentDate = getCurrentPhilippineDateTime();
  const dateOnly = currentDate.toISOString().split('T')[0];
  return dateOnly;
};

export const isTimeRangeValid = (
  newSchedule: ScheduleDataDto,
  existingSchedules: ScheduleResponse[],
): [boolean, string | undefined] => {
  const { day, startTime, endTime, date } = newSchedule;
  const formattedDate = date
    ? format(new Date(date), 'MMM d, yyyy')
    : format(new Date(), 'MMM d, yyyy');
  const currentDate = getDateOnlyFromCurrentDateTime();
  const newTimeRange = {
    start: new Date(`${currentDate}T${startTime}`),
    end: new Date(`${currentDate}T${endTime}`),
  };

  const formattedDay = toTitleCase(day);
  const newTimeRangeString = getTimeRange(startTime, endTime);

  if (newSchedule.isBlocked) {
    for (const existingSchedule of existingSchedules) {
      const existingDate = format(new Date(existingSchedule.date), 'MMM d, yyyy');

      const existingTimeRangeString = getTimeRange(
        existingSchedule.startTime,
        existingSchedule.endTime,
      );

      const isEqualDate = existingDate === formattedDate;

      const existingTimeRange = {
        start: new Date(`${currentDate}T${existingSchedule.startTime}`),
        end: new Date(`${currentDate}T${existingSchedule.endTime}`),
      };

      if (
        isEqualDate &&
        (isWithinTimeRange(newTimeRange, existingTimeRange) ||
          isWithinTimeRange(existingTimeRange, newTimeRange))
      ) {
        const message = `The new schedule (${formattedDay} ${newTimeRangeString}) is already within an \
          existing schedule (${formattedDay} ${existingTimeRangeString}).`;

        return [false, message];
      }

      if (isEqualDate && isOverlapping(newTimeRange, existingTimeRange)) {
        return [
          false,
          `The new schedule (${formattedDay} ${newTimeRangeString}) overlaps with an existing schedule \
          (${formattedDay} ${existingTimeRangeString}).`,
        ];
      }
    }
    return [true, undefined];
  }

  if (!newSchedule?.date) {
    for (const existingSchedule of existingSchedules) {
      if (existingSchedule.day === day) {
        const existingTimeRangeString = getTimeRange(
          existingSchedule.startTime,
          existingSchedule.endTime,
        );
        const newTimeRange = {
          start: new Date(`${currentDate}T${startTime}`),
          end: new Date(`${currentDate}T${endTime}`),
        };
        const existingTimeRange = {
          start: new Date(`${currentDate}T${existingSchedule.startTime}`),
          end: new Date(`${currentDate}T${existingSchedule.endTime}`),
        };

        if (isWithinTimeRange(newTimeRange, existingTimeRange)) {
          const message = `The new schedule (${formattedDay} ${newTimeRangeString}) is already within an \
          existing schedule (${formattedDay} ${existingTimeRangeString}).`;

          return [false, message];
        }

        //Check reverse
        if (isWithinTimeRange(existingTimeRange, newTimeRange)) {
          const message = `An existing schedule (${formattedDay} ${existingTimeRangeString}) is within the \
          given new schedule (${formattedDay} ${newTimeRangeString}).`;

          return [false, message];
        }

        if (isOverlapping(newTimeRange, existingTimeRange)) {
          return [
            false,
            `The new schedule (${formattedDay} ${newTimeRangeString}) overlaps with an existing schedule \
          (${formattedDay} ${existingTimeRangeString}).`,
          ];
        }
      }
    }
  }

  return [true, undefined];
};

const getTimeRange = (startTime: string, endTime: string) => {
  return formatMilitaryTimeToAmPm(startTime) + ' to ' + formatMilitaryTimeToAmPm(endTime);
};

const isWithinTimeRange = (dateRange: DateRange, dateRangeToCompare: DateRange) => {
  return dateRange.start >= dateRangeToCompare.start && dateRange.end <= dateRangeToCompare.end;
};

const isOverlapping = (dateRange: DateRange, dateRangeToCompare: DateRange) => {
  return (
    (dateRange.start >= dateRangeToCompare.start && dateRange.start < dateRangeToCompare.end) ||
    (dateRange.end > dateRangeToCompare.start && dateRange.end <= dateRangeToCompare.end)
  );
};

export const getTimeAgo = (timestamp: string): string => {
  const currentTime: Date = new Date();
  const targetTime: Date = new Date(timestamp);

  const elapsedSeconds: number = Math.floor((currentTime.getTime() - targetTime.getTime()) / 1000);
  const elapsedMinutes: number = Math.floor(elapsedSeconds / 60);
  const elapsedHours: number = Math.floor(elapsedMinutes / 60);
  const elapsedDays: number = Math.floor(elapsedHours / 24);

  if (elapsedSeconds < 60) {
    return 'Just now';
  } else if (elapsedMinutes < 60) {
    return `${elapsedMinutes}m ago`;
  } else if (elapsedHours < 24) {
    return `${elapsedHours}h ago`;
  } else if (elapsedDays === 1) {
    return 'Yesterday';
  }
  return `${elapsedDays}d ago`;
};

export const convertToWeekRange = (weekdays: string): string => {
  const weekdaysArray = weekdays
    .split(',')
    .map((day) => parseInt(day.trim()))
    .sort((a, b) => a - b);

  const weekdaysMap: { [key: number]: string } = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun',
  };

  if (weekdaysArray.length === 1) {
    return weekdaysMap[weekdaysArray[0]];
  }

  let rangeStart = weekdaysArray[0];
  let rangeEnd = weekdaysArray[0];
  const ranges = [];

  for (let i = 1; i < weekdaysArray.length; i++) {
    if (weekdaysArray[i] === rangeEnd + 1) {
      rangeEnd = weekdaysArray[i];
    } else {
      if (rangeStart === rangeEnd) {
        ranges.push(weekdaysMap[rangeStart]);
      } else if (rangeStart + 1 === rangeEnd) {
        ranges.push(weekdaysMap[rangeStart], weekdaysMap[rangeEnd]);
      } else {
        ranges.push(`${weekdaysMap[rangeStart]} - ${weekdaysMap[rangeEnd]}`);
      }
      rangeStart = weekdaysArray[i];
      rangeEnd = weekdaysArray[i];
    }
  }

  if (rangeStart === rangeEnd) {
    ranges.push(weekdaysMap[rangeStart]);
  } else if (rangeStart + 1 === rangeEnd) {
    ranges.push(weekdaysMap[rangeStart], weekdaysMap[rangeEnd]);
  } else {
    ranges.push(`${weekdaysMap[rangeStart]} - ${weekdaysMap[rangeEnd]}`);
  }

  return ranges.join(', ');
};

export const getDifferenceInMinutes = (startTime: string, endTime: string) => {
  const startMinutes = getTotalMinutes(startTime as string);
  const endMinutes = getTotalMinutes(endTime as string);

  const currentDate = new Date();
  let endDate = currentDate;
  if (endMinutes < startMinutes) {
    endDate = addDays(currentDate, 1);
  }
  const startTimeDate = parse(startTime, 'HH:mm:ss', currentDate);
  const endTimeDate = parse(endTime, 'HH:mm:ss', endDate);

  const intervalInMinutes = differenceInMinutes(endTimeDate, startTimeDate);

  return intervalInMinutes;
};

export const getNextDay = (date: Date): Date => {
  return addDays(date, 1);
};

export const isLocked = (date: Date, validityInMinutes: number): boolean => {
  return differenceInMinutes(new Date(), date) <= validityInMinutes;
};

export const getCurrentDate = (): Date => {
  return new Date();
};

export const getCurrentPhilippineDateTime = (): Date => {
  return getTodayUTCPhilippineTime();
};

export const convertToDate = (date: string | Date): Date => {
  if (typeof date === 'string') {
    date = date.replace(' ', 'T').substring(0, 16); // Convert to format Safari can parse
  }

  return new Date(date);
};
export const getLocalDateFromISO = (date: string): Date => {
  const startTime = new Date(date);

  return new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);
};

export function getStartAndEndDateOfWeek(currentDate: Date) {
  const monday = startOfWeek(currentDate, { weekStartsOn: 1 });

  const sunday = endOfWeek(currentDate, { weekStartsOn: 1 });

  const formattedMonday = formatDateToDatabaseTimestamp(format(monday, 'yyyy-MM-dd'), '00:00');

  const formattedSunday = formatDateToDatabaseTimestamp(format(sunday, 'yyyy-MM-dd'), '23:59');

  return { monday: formattedMonday, sunday: formattedSunday };
}

export const getTimeList = () => {
  const startTime = new Date(0, 0, 0, 6, 0); // 06:00 AM
  const endTime = new Date(0, 0, 1, 5, 59); // 05:59 AM the next day
  const timeList = [];

  let currentTime = startTime;
  while (currentTime <= endTime) {
    const minutes = currentTime.getMinutes();
    const timeString = minutes === 0 ? format(currentTime, 'hh a') : '-';
    timeList.push(timeString);

    currentTime = addMinutes(currentTime, 30);
  }

  return timeList;
};

export const getWeekDates = (currentWeekStart: Date) => {
  const weekStart = startOfWeek(currentWeekStart, { weekStartsOn: 1 });
  const dates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = addDays(weekStart, i);
    dates.push(format(currentDate, 'd'));
  }
  return dates;
};

export const doTimeRangesOverlap = (
  blockedStartTime: Date,
  blockedEndTime: Date,
  startTime: Date,
  endTime: Date,
) => {
  return blockedStartTime < endTime && blockedEndTime > startTime;
};

export const identifyOverrideSchedules = (
  allSchedules: Schedule[],
  blockedSchedules: Schedule[],
): Schedule[] => {
  const uniqueTimeCombinations = new Set(); // To store unique time combinations
  const filteredSchedules: Schedule[] = [];

  if (blockedSchedules.length === 0) {
    return allSchedules;
  }

  const currentDate = getDateOnlyFromCurrentDateTime();

  for (const item of allSchedules) {
    const timeCombination = `${item.day}-${item.startTime}-${item.endTime}-${item.isBlocked}`;
    if (!uniqueTimeCombinations.has(timeCombination)) {
      uniqueTimeCombinations.add(timeCombination);
      let isBlocked = false;
      for (const blockItem of blockedSchedules) {
        if (
          item.id !== blockItem.id &&
          item.day === blockItem.day &&
          doTimeRangesOverlap(
            new Date(`${currentDate}T${item.startTime}`),
            new Date(`${currentDate}T${item.endTime}`),
            new Date(`${currentDate}T${blockItem.startTime}`),
            new Date(`${currentDate}T${blockItem.endTime}`),
          )
        ) {
          isBlocked = true;
          break;
        }
      }
      if (!(isBlocked && !item.isBlocked))
        filteredSchedules.push({
          ...item,
        });
    }
  }

  return filteredSchedules;
};

const convertToSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};

export const filterSchedulesWithOverlap = (schedules: Schedule[]) => {
  const schedulesWithDate: Schedule[] = [];
  const schedulesWithoutDate = [];

  for (const schedule of schedules) {
    if (schedule.date) {
      schedulesWithDate.push(schedule);
    } else {
      schedulesWithoutDate.push(schedule);
    }
  }

  const specificSchedule = schedulesWithDate.find((schedule) => !schedule.isBlocked);

  let filteredSchedulesWithoutDate: Schedule[] = [];

  if (isEmpty(specificSchedule)) {
    filteredSchedulesWithoutDate = schedulesWithoutDate.filter((scheduleWithoutDate) => {
      return !schedulesWithDate.some((scheduleWithDate) => {
        const startRegular = convertToSeconds(scheduleWithoutDate.startTime);
        const endRegular = convertToSeconds(scheduleWithoutDate.endTime);
        const startToOverlap = convertToSeconds(scheduleWithDate.startTime);
        const endToOverlap = convertToSeconds(scheduleWithDate.endTime);
        const isWithIn =
          (startRegular <= startToOverlap && startToOverlap < endRegular) ||
          (startRegular < endToOverlap && endToOverlap <= endRegular) ||
          (startRegular < endToOverlap && startRegular > startToOverlap);
        return isWithIn;
      });
    });
  }

  const filteredSchedules = schedulesWithDate.concat(filteredSchedulesWithoutDate);

  return filteredSchedules;
};

export const getStartOrEndDateTime = (currentDate: Date, time: string, isEndTime: boolean) => {
  return set(currentDate, {
    hours: Number(time?.split(':')[0]),
    minutes: Number(time?.split(':')[1]),
    seconds: isEndTime ? 59 : 0,
    milliseconds: isEndTime ? 999 : 0,
  });
};

export const getStartAndEndOfMonth = (): { startDate: Date; endDate: Date } => {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Start of the current month
  const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // End of the current month

  return { startDate, endDate };
};

export const filterSchedule = (schedules: ScheduleResponse[]) => {
  let schedulesWithDate: ScheduleResponse[] = [];
  let schedulesWithoutDate: ScheduleResponse[] = [];

  for (const schedule of schedules) {
    if (schedule.date) {
      schedulesWithDate = [...schedulesWithDate, schedule];
    } else {
      schedulesWithoutDate = [...schedulesWithoutDate, schedule];
    }
  }

  const specificSchedule = schedulesWithDate.find((schedule) => !schedule.isBlocked);

  if (isEmpty(specificSchedule)) {
    schedulesWithDate.forEach((scheduleWithDate) => {
      schedulesWithoutDate = [
        ...schedulesWithoutDate.filter((scheduleWithoutDate) => {
          const startRegular = convertToSeconds(scheduleWithoutDate.startTime);
          const endRegular = convertToSeconds(scheduleWithoutDate.endTime);
          const startToOverlap = convertToSeconds(scheduleWithDate.startTime);
          const endToOverlap = convertToSeconds(scheduleWithDate.endTime);

          const isWithIn =
            (startToOverlap < startRegular && startRegular < endToOverlap) ||
            (startToOverlap < endRegular && endRegular <= endToOverlap) ||
            (startToOverlap <= startRegular &&
              endRegular <= endToOverlap &&
              endRegular !== 0 &&
              startToOverlap !== 0);
          return !isWithIn;
        }),
      ];
    });
  } else {
    schedulesWithoutDate = [];
  }

  const filteredSchedules = schedulesWithDate
    .filter((sched) => !sched.isBlocked)
    .concat(schedulesWithoutDate);

  return filteredSchedules;
};

export function getDateWithoutTimezone(dateString?: string) {
  if (!dateString) {
    return new Date();
  }

  return new Date(dateString.replace('Z', ''));
}

export function displayAppointmentDateTime(
  dateString?: string,
  formatString: string = COMPLETE_DATE_FORMAT,
) {
  if (!dateString) {
    return '';
  }

  return format(new Date(dateString.replace('Z', '')), formatString);
}

export function getTodayISOStringCalendarDateFormat() {
  return format(startOfDay(new Date()), CALENDAR_DATE_FORMAT);
}

export const getIsWholeDay = (startTime: string, endTime: string) =>
  startTime === '00:00' && endTime === '23:59';

export const formatDateToMonth = (date: Date): MonthEnum => {
  return format(date, MONTH_WORD_FORMAT).toLowerCase() as MonthEnum;
};

export function getNextAvailableSchedule(
  schedules?: ScheduleResponse[],
  nextAvailableDate?: string,
) {
  if (!schedules || !nextAvailableDate) {
    return null;
  }

  const nextAvailableDateSchedules = getNextAvailableDateSchedules(schedules, nextAvailableDate);

  if (!nextAvailableDateSchedules) {
    return null;
  }

  const futureSchedules = getFutureSchedules(nextAvailableDateSchedules);
  const nextAvailableSchedule = getNextAvailableScheduleFromFutureSchedules(futureSchedules);

  if (!nextAvailableSchedule) {
    return null;
  }

  return {
    nextAvailableScheduleDate: getDateWithoutTimezone(nextAvailableSchedule.dateWithTimeSlot),
    scheduleId: nextAvailableSchedule.id,
  };
}

function getNextAvailableDateSchedules(schedules: ScheduleResponse[], nextAvailableDate: string) {
  const groupedMonthSchedule = groupBy(schedules, 'startOfDayDate');
  const nextAvailableRawDateSchedules = Object.entries(groupedMonthSchedule).find(([key]) => {
    return isSameDay(new Date(key), new Date(nextAvailableDate));
  });
  const [_, nextAvailableDateSchedules] = nextAvailableRawDateSchedules ?? [];
  return nextAvailableDateSchedules;
}

function getFutureSchedules(nextAvailableDateSchedules: ScheduleResponse[]) {
  const now = new Date();
  const futureSchedules = nextAvailableDateSchedules?.filter(
    (schedule) => new Date(getDateWithoutTimezone(schedule?.dateWithTimeSlot) ?? new Date()) > now,
  );
  futureSchedules?.sort(
    (a, b) =>
      new Date(a?.dateWithTimeSlot ?? new Date()).getTime() -
      new Date(b?.dateWithTimeSlot ?? new Date()).getTime(),
  );
  return futureSchedules;
}

function getNextAvailableScheduleFromFutureSchedules(futureSchedules: ScheduleResponse[]) {
  return futureSchedules?.find(
    (schedule) => +(schedule.appointmentCount as number) < +(schedule?.slots as number),
  );
}

export function getTodayUTCPhilippineTime() {
  return new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Manila',
    }),
  );
}

export function getStartOfTodayUTCPhilippineTime() {
  const today = getTodayUTCPhilippineTime();

  return startOfDay(today);
}

export function isTodayPhilippineTime(dateSelected: Date) {
  const today = getStartOfTodayUTCPhilippineTime();
  const selectedDate = startOfDay(dateSelected);

  return isSameDay(today, selectedDate);
}

export function firebaseTimestampToDate(timestamp: any) {
  return new Date(timestamp._seconds * 1000);
}

// convert time string in hh:mm a format to hours in 0-24 format
export function convertTimeToHours(timeString: string) {
  const today = new Date();
  const dateString = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()} ${timeString}`;

  const date = parse(dateString, 'yyyy-MM-dd hh:mm a', new Date());

  return getHours(date);
}

export function convertMinutesToHours(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}
