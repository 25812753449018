import { ProviderClientProfile } from '@healthhub/api-lib';
import {
  GetProviderClientProfileByDto,
  getProviderProfileClientByParams,
} from '@mwell-healthhub/commons/api/providerClientProfileService';
import { GET_ALL_PROVIDER_CLIENT_PROFILE_BY_PARAMS } from '@mwell-healthhub/commons/constants';
import { useQuery } from '@tanstack/react-query';

export const useGetProviderProfileClientByParams = (params: GetProviderClientProfileByDto) => {
  return useQuery<ProviderClientProfile[]>(
    [GET_ALL_PROVIDER_CLIENT_PROFILE_BY_PARAMS, params],
    () => getProviderProfileClientByParams(params),
  );
};
