import React from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import Button from './Button';
import CommonModal from './Modal';
import { Variants, VariantType } from '../enums';

type ActionModalProps = {
  children?: React.ReactNode;
  description?: string | React.ReactNode;
  isLoading?: boolean;
  isPrimaryButtonHidden?: boolean;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  title: string;
  variant?: VariantType;
};

function ActionModal(props: ActionModalProps) {
  const {
    children,
    description,
    isLoading,
    isPrimaryButtonHidden,
    onClickPrimaryButton,
    onClickSecondaryButton,
    primaryButtonText = 'Proceed',
    secondaryButtonText = 'Cancel',
    title,
    variant,
  } = props;

  let iconClasses;

  switch (variant) {
    case Variants.PRIMARY:
      iconClasses = 'bg-blue-100 text-blue-600';
      break;
    case Variants.ERROR:
      iconClasses = 'bg-red-100 text-red-600';
      break;
  }

  return (
    <CommonModal open className="overflow-y-auto px-0 pb-0 pt-0 sm:p-0" size="xl">
      <div className="mt-10 flex flex-col gap-4 px-6 pb-4 pt-6 md:mt-0 md:flex-row">
        <div
          className={twMerge(
            'flex h-fit w-10 items-center justify-center rounded-full p-2',
            iconClasses,
          )}
        >
          <ExclamationTriangleIcon height={24} width={24} />
        </div>
        <div className="pt-[6px]">
          <div className="pb-2 text-lg font-medium leading-[28px] text-gray-900">{title}</div>
          {description && (
            <div className="text-sm font-normal leading-[20px] tracking-[0.2px] text-graniteGray">
              {description}
            </div>
          )}
          {children && <div className="pt-5">{children}</div>}
        </div>
      </div>
      <div className="flex gap-3 rounded-bl-md rounded-br-md px-6 py-4 md:justify-end">
        {onClickSecondaryButton && (
          <Button
            variant="outlined"
            type="button"
            className="h-[52px] w-full md:h-full md:w-fit"
            onClick={onClickSecondaryButton}
          >
            {secondaryButtonText}
          </Button>
        )}
        {!isPrimaryButtonHidden && (
          <Button
            disabled={isLoading}
            variant={variant}
            type="submit"
            isLoading={isLoading}
            onClick={onClickPrimaryButton}
            className="h-[52px] w-full md:h-full md:w-fit"
          >
            {primaryButtonText}
          </Button>
        )}
      </div>
    </CommonModal>
  );
}

export default ActionModal;
