import { FormEventHandler, useEffect, useState } from 'react';

import { UseFormRegister } from 'react-hook-form';

import Button from './Button';
import Checkbox from './Checkbox';
import TextInput from './TextInput';
import { EMAIL_OTP_RESEND_DURATION_IN_SECONDS } from '../constants';
import useTimeLeftTimer from '../hooks/useTimeLeftTimer';
import { clsxMerge } from '../utils';

type OtpFormData = {
  code: string;
  shouldRememberDevice: boolean;
};

export type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  handleClickCancel: () => void;
  onClickResend: () => void;
  register: UseFormRegister<OtpFormData>;
  isRememberDeviceHidden?: boolean;
  timeRemainingSeconds?: number;
  isLoading?: boolean;
};

function OtpForm(props: Readonly<Props>) {
  const {
    isRememberDeviceHidden,
    timeRemainingSeconds = EMAIL_OTP_RESEND_DURATION_IN_SECONDS,
    isLoading,
    onClickResend,
    onSubmit,
    register,
  } = props;

  const [startSeconds, setStartSeconds] = useState<number>(timeRemainingSeconds);
  const [triggerSwitch, setTriggerSwitch] = useState<boolean>(false);

  const timeLeft = useTimeLeftTimer({ startSeconds, triggerSwitch });

  const hasStopped = timeLeft === '0s';
  const resendText = !hasStopped ? `Resend OTP after ${timeLeft}` : 'Resend OTP';

  useEffect(() => {
    setStartSeconds(timeRemainingSeconds);
  }, [timeRemainingSeconds]);

  const handleClickResend = () => {
    if (hasStopped && !isLoading) {
      setStartSeconds(timeRemainingSeconds);
      setTriggerSwitch(!triggerSwitch);
      onClickResend();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <fieldset className="contents">
        <legend className="sr-only">OTP Form</legend>
        <div className="mb-4 flex flex-col gap-10">
          <TextInput
            {...register('code', { required: true })}
            type="number"
            pattern="[0-9]{6}"
            inputMode="numeric"
            className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            placeholder="Enter OTP"
            labelShown={true}
            labelSize="sm"
            labelFontWeight="sm"
            disabled={isLoading}
          />
        </div>
        {!isRememberDeviceHidden && (
          <Checkbox {...register('shouldRememberDevice')} label="Remember this device" />
        )}
        <div className="mt-7 flex gap-4">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            className="w-full font-medium"
            isLoading={isLoading}
          >
            Verify
          </Button>
        </div>

        <div className="mt-5 text-center text-sm text-neutral-500 md:text-base">
          Didn't get the email?{' '}
          <span
            onClick={hasStopped ? handleClickResend : undefined}
            className={clsxMerge(
              'cursor-pointer text-sm text-primary-500 md:text-base',
              !hasStopped ? 'cursor-text text-neutral-500' : '',
            )}
          >
            {resendText}
          </span>
        </div>
      </fieldset>
    </form>
  );
}

export default OtpForm;
