import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { ProductStatus } from '../../enums';
import { useGetAllEshopCategories, useUpdateProductsMutation } from '../../hooks';
import { EshopProduct, Option } from '../../types';
import { convertObjectsToOptions, truncateLastChars } from '../../utils';
import { verifyProductSchema } from '../../validators';
import Button from '../Button';
import Dropdown from '../Dropdown';
import Modal from '../Modal';
import toast from '../toast';

type Props = {
  data: EshopProduct[];
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function VerifyProduct(props: Props) {
  const { data, isOpen, onClose, onSuccess } = props;

  const formMethods = useForm({
    resolver: yupResolver(verifyProductSchema),
    defaultValues: {
      categoryId: data[0]?.category?.id,
      status: ProductStatus.Pending,
    },
    mode: 'onBlur',
  });
  const { handleSubmit, reset, control, setValue } = formMethods;

  useEffect(() => {
    if (data.length) {
      setValue('categoryId', data[0]?.category?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const { data: paginatedCategories } = useGetAllEshopCategories();
  const categories = paginatedCategories?.items ?? [];

  const { mutate: editProducts, isLoading } = useUpdateProductsMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  function handleSuccess() {
    const message = `Product${data.length === 1 ? '' : 's'} updated`;

    toast({ message: message, type: 'success' });
    onClose();
    onSuccess();
    reset();
  }

  function handleError(error: Error) {
    toast({ message: error.message, type: 'error' });
  }

  const categoryObjects = categories.map((category) => ({
    id: category.id,
    name: category.name,
  }));
  const categoryOptions = convertObjectsToOptions(categoryObjects, {
    includeNone: false,
  });

  const statusObjects = ['Pending', 'Active'].map((s) => ({
    id: s.toUpperCase(),
    name: s,
  }));
  const statusOptions = convertObjectsToOptions(statusObjects, {
    includeNone: false,
  });

  const onSubmit = (d: any) => {
    const payload = {
      products: data.map((product) => ({
        id: product.id,
        categoryId: d.categoryId,
        status: d.status,
      })),
    };

    editProducts(payload);
  };

  return (
    <Modal open={isOpen} size="md" onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <div>
            <h3 className="text-md font-semibold">Review Pending Product</h3>
          </div>
          {data.length > 1 && (
            <div className="flex flex-col gap-1 rounded-lg bg-primary600 bg-opacity-10 px-6 py-3">
              <span className="text-[13px] font-semibold text-neutral-600">
                {data.length} Product{data.length === 1 ? '' : 's'} Selected
              </span>
              <span className="text-xs text-primary-400">
                {truncateLastChars(data.map((product) => product.name).join(', '))}
              </span>
            </div>
          )}
          <Controller
            name="categoryId"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const handleCategorySelection = (providerStatus: Option) => {
                onChange(providerStatus.value);
              };

              return (
                <div className="relative flex w-full flex-col">
                  {
                    <label className="text-sm font-normal text-neutral-600">
                      Product Category {<span className="text-red-600"> *</span>}
                    </label>
                  }
                  <Dropdown
                    hasLeftRoundedBorders
                    hasRightRoundedBorders
                    placeholder="Product Category"
                    containerClassname="w-full"
                    className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                    options={categoryOptions}
                    value={categoryOptions.find((category) => value && category.value === value)}
                    onChange={handleCategorySelection}
                  />
                  {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                </div>
              );
            }}
          />

          <Controller
            name="status"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const handleStatusSelection = (providerStatus: Option) => {
                onChange(providerStatus.value);
              };

              return (
                <div className="relative flex w-full flex-col">
                  {
                    <label className="text-sm font-normal text-neutral-600">
                      Status {<span className="text-red-600"> *</span>}
                    </label>
                  }
                  <Dropdown
                    hasLeftRoundedBorders
                    hasRightRoundedBorders
                    placeholder="Status"
                    containerClassname="w-full"
                    className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                    options={statusOptions}
                    value={statusOptions.find((status) => value && status.value === value)}
                    onChange={handleStatusSelection}
                  />
                  {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                </div>
              );
            }}
          />
          <div className="mt-4 flex justify-end gap-2">
            <Button
              className="text-[15px] font-medium !text-gray5"
              variant="label"
              disabled={isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="text-sm font-normal"
              variant="primary"
              isLoading={isLoading}
              type="submit"
            >
              Update and Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default VerifyProduct;
