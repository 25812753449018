import { useMemo } from 'react';

import { UserOperation, UserProvider } from '@healthhub/api-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  TextInput,
  MaskedTextInput,
  REGISTRATION_CHARACTER_LIMIT,
  FormPageLayout,
  toast,
  NAME_PATTERN,
  useCreateUserProviderMutation,
  useUpdateUserProviderMutation,
  CustomError,
  Dropdown,
  ProviderUserTypeLabels,
  Option,
  BRAND_LEVEL_USERS,
  MultiSelectDropdown,
  MultiSelectOptionType,
  useGetAllBranches,
  BRAND_ADMIN_USERS,
  useRouter,
} from '../';
import { OperationUserType, ProviderUserType } from '../types';
import { passwordSchema } from '../validators';

type Props = {
  user?: UserProvider;
  currentUser: UserProvider | UserOperation;
  providerId: string;
  onSuccess?: () => void;
  showBackButton?: boolean;
};

export type UserDesignation = {
  id: number;
  userType: ProviderUserType;
  branch: string;
};

export type UserFormType = {
  isEditing: boolean;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  userType: ProviderUserType;
  selectedBranches: MultiSelectOptionType[];
};

const schema = yup.object().shape(
  {
    email: yup.string().email().required('Email is required.'),
    firstName: yup
      .string()
      .matches(NAME_PATTERN, 'Invalid first name format.')
      .required('First Name is required.'),
    lastName: yup
      .string()
      .matches(NAME_PATTERN, 'Invalid last name format.')
      .required('Last Name is required.'),
    userType: yup.string().required('User Type is required.'),
    selectedBranches: yup
      .array()
      .test('userType', 'Must select as least one branch.', function (selectedBranches) {
        const userType = this.parent.userType;
        if (BRAND_LEVEL_USERS.includes(userType)) {
          return true;
        } else {
          return (selectedBranches?.length || 0) > 0;
        }
      }),
    ...passwordSchema,
  },
  [['password', 'password']],
);

const USER_OPTIONS_MAP = {
  [OperationUserType.ADMIN]: [
    ProviderUserType.SUPER_ADMIN,
    ProviderUserType.BRAND_ADMIN,
    ProviderUserType.BRANCH_ADMIN,
    ProviderUserType.SPECIALIST,
    ProviderUserType.STAFF,
  ],
  [ProviderUserType.BRANCH_ADMIN]: [ProviderUserType.SPECIALIST, ProviderUserType.STAFF],
  [ProviderUserType.SUPER_ADMIN]: [
    ProviderUserType.SUPER_ADMIN,
    ProviderUserType.BRAND_ADMIN,
    ProviderUserType.BRANCH_ADMIN,
    ProviderUserType.SPECIALIST,
    ProviderUserType.STAFF,
  ],
};

function ProviderUserForm(props: Props) {
  const { user, currentUser, providerId: brandId, onSuccess, showBackButton } = props;
  const isEditing = !!user?.id;
  const currentUserType = currentUser?.userType as string;
  const isCurrentUserTypeBrandLevel = BRAND_ADMIN_USERS.includes(
    currentUserType as ProviderUserType,
  );

  const selectedBranches = user?.assignedBranches
    ?.filter((branch) => branch.isBranch)
    .map((branch) => ({
      text: `${branch?.displayName} (${branch?.branch})`,
      value: branch?.id,
    })) as unknown as MultiSelectOptionType[];

  const router = useRouter();
  const methods = useForm<UserFormType>({
    resolver: yupResolver(schema) as unknown as any,
    defaultValues: {
      isEditing,
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userType: user?.userType as ProviderUserType,
      password: '',
      confirmPassword: '',
      selectedBranches: selectedBranches,
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    formState: { errors },
  } = methods;

  const { mutate: createUser, isLoading: isSaving } = useCreateUserProviderMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: updateUser, isLoading: isUpdating } = useUpdateUserProviderMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const selectedUserType = watch('userType');
  const isSelectedUserBrandLevelUser = BRAND_LEVEL_USERS.includes(selectedUserType);
  const showBranchSelector = !isSelectedUserBrandLevelUser;

  const { data: brandBranches } = useGetAllBranches(
    { providerId: brandId, limit: 100 },
    isCurrentUserTypeBrandLevel,
  );

  const branches = isCurrentUserTypeBrandLevel
    ? brandBranches?.items
    : (currentUser as UserProvider)?.assignedBranches;

  const onSubmit = (data: UserFormType) => {
    const payload = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      userType: data.userType,
      branchIds: isSelectedUserBrandLevelUser
        ? [+brandId]
        : data.selectedBranches.map((branch) => +branch.value),
      providerId: brandId,
    };

    if (isEditing) {
      updateUser({
        id: user?.id as number,
        user: payload,
      });
    } else {
      createUser(payload);
    }
  };

  function handleSuccess() {
    const message = `User ${isEditing ? 'updated' : 'created'}`;

    toast({ message: message, type: 'success' });
    onSuccess?.();
    reset();
  }

  function handleError(error: CustomError) {
    toast({ message: error.message, type: 'error' });
  }

  const userTypeOptions: Option[] =
    USER_OPTIONS_MAP[currentUserType as keyof typeof USER_OPTIONS_MAP]?.map(
      (value: ProviderUserType) => ({
        label: ProviderUserTypeLabels[value as keyof typeof ProviderUserTypeLabels],
        value,
      }),
    ) || [];
  const branchOptions: MultiSelectOptionType[] = useMemo(() => {
    return branches?.map((branch) => ({
      text: `${branch?.displayName} (${branch?.branch})`,
      value: branch?.id,
    })) as unknown as MultiSelectOptionType[];
  }, [branches]);

  const isLoading = isSaving || isUpdating;
  const formTitle = `${isEditing ? 'Edit' : 'Add a new'} User`;
  const submitButtonText = isEditing ? 'Save' : 'Add User';

  const handleBack = () => {
    router.back();
  };

  return (
    <FormProvider {...methods}>
      <FormPageLayout
        isLoading={isLoading}
        title={formTitle}
        submitButtonText={submitButtonText}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleBack}
        showBackButton={showBackButton}
      >
        <div className="flex flex-col gap-6 rounded-md border border-gray39 bg-white px-6 py-4 drop-shadow-sm md:p-8">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <TextInput
              {...register('firstName')}
              labelShown
              showRequiredOnLabel
              label="First Name"
              errorMessage={errors?.firstName?.message}
            />
            <TextInput
              {...register('lastName')}
              labelShown
              showRequiredOnLabel
              label="Last Name"
              errorMessage={errors?.lastName?.message}
            />
          </div>
          <TextInput
            {...register('email')}
            labelShown
            showRequiredOnLabel
            label="Login Email"
            errorMessage={errors?.email?.message}
          />
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <MaskedTextInput
              {...register('password')}
              labelShown
              showRequiredOnLabel
              label="Password"
              placeholder="Enter password"
              maxLength={REGISTRATION_CHARACTER_LIMIT.PASSWORD}
              errorMessage={errors?.password?.message}
            />
            <MaskedTextInput
              {...register('confirmPassword')}
              labelShown
              showRequiredOnLabel
              label="Confirm Password"
              placeholder="Enter password"
              maxLength={REGISTRATION_CHARACTER_LIMIT.PASSWORD}
              errorMessage={errors?.confirmPassword?.message}
            />
          </div>
          <Controller
            name="userType"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Dropdown
                hasLeftRoundedBorders
                hasRightRoundedBorders
                showRequiredOnLabel
                label="User Type"
                placeholder="Select User Type"
                options={userTypeOptions}
                value={userTypeOptions.find((option) => option.value === value)}
                onChange={(e) => onChange(e.value)}
                errorMessage={errors.userType?.message || ''}
              />
            )}
          />
          {showBranchSelector && (
            <Controller
              name="selectedBranches"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div className="flex flex-col">
                  <MultiSelectDropdown
                    label="Branches"
                    placeholder="Select Branches"
                    options={branchOptions || []}
                    selectedOptions={value}
                    showRequiredOnLabel
                    onSelect={onChange}
                    onRemove={onChange}
                  />
                  {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                </div>
              )}
            />
          )}
        </div>
      </FormPageLayout>
    </FormProvider>
  );
}

export default ProviderUserForm;
