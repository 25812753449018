import { AppointmentStatusEnum, AppointmentTypeEnum } from '../enums';

export const AppointmentTypeNames: Record<AppointmentTypeEnum, string> = {
  [AppointmentTypeEnum.FACE_TO_FACE]: 'Face-to-face consultation',
  [AppointmentTypeEnum.VIDEO]: 'Video consultation',
  [AppointmentTypeEnum.LABORATORY]: 'Laboratory',
  [AppointmentTypeEnum.HOME]: 'Home',
};

export const AppointmentStatusLabels: Record<AppointmentStatusEnum, string> = {
  [AppointmentStatusEnum.CONFIRMED]: 'Confirmed',
  [AppointmentStatusEnum.ONGOING]: 'Ongoing',
  [AppointmentStatusEnum.NO_SHOW]: 'No Show',
  [AppointmentStatusEnum.COMPLETED]: 'Completed',
  [AppointmentStatusEnum.CANCELLED]: 'Cancelled',
};

export const GET_ALL_AVAILABLE_DOCTOR_SCHEDULES = 'GET_ALL_AVAILABLE_DOCTOR_SCHEDULES';
export const GET_ALL_HOSPITALS_BY_DOCTOR_ID = 'GET_ALL_HOSPITALS_BY_DOCTOR_ID';
export const GET_ALL_DOCTORS = 'GET_ALL_DOCTORS';
export const GET_DOCTOR_BY_ID = 'GET_DOCTOR_BY_ID';
export const GET_NEXT_AVAILABLE_DOCTOR_SCHEDULE = 'GET_NEXT_AVAILABLE_DOCTOR_SCHEDULE';

export const GET_ALL_DOCTOR_AFFILIATED_HMOS_BY_DOCTOR_ID =
  'GET_ALL_DOCTOR_AFFILIATED_HMOS_BY_DOCTOR_ID';

export const defaultSpecialNotes = {
  reservationFee: 'Reservation fee required. We will contact you for payment details.',
  priceAndProcedure: 'Prices and procedures may vary after consultation',
};
