import { CheckoutResponse, CreatePaymentDTO, PaymentApi } from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';

export const paymentApi = HealthHubApiClient.use(PaymentApi);

export async function createPayment(createPaymentDto: CreatePaymentDTO): Promise<CheckoutResponse> {
  try {
    const { data } = await paymentApi.paymentControllerCreateCheckout(createPaymentDto);

    return data;
  } catch (err) {
    throw new Error('Failed to create payment');
  }
}
