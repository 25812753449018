import React from 'react';

export default function SpecialistListLoadingSkeleton() {
  const skeletonItems = Array.from({ length: 4 }).map((_, index) => (
    <div className="w-full rounded-md border border-solid border-gray-300" key={index}>
      <div className="h-80 animate-pulse bg-gray-200"></div>

      <div className="p-2">
        <div className="mb-2 h-5 w-4/5 animate-pulse bg-gray-200"></div>
        <div className="mb-2 h-4 w-2/5 animate-pulse bg-gray-200"></div>
        <div className="mb-2 h-4 w-3/5 animate-pulse bg-gray-200"></div>
        <div className="mb-2 h-4 w-1/3 animate-pulse bg-gray-200"></div>
        <div className="w-7/10 mb-2 h-4 animate-pulse bg-gray-200"></div>
      </div>
    </div>
  ));

  return <div className="mt-2 w-full space-y-4">{skeletonItems}</div>;
}
