import {
  CreateProviderBranchDto,
  CreateProviderDto,
  Notification,
  OnboardProviderDto,
  Provider,
  ProviderApi,
  ProviderClientProfile,
  SelfOnboardDto,
  SuggestProviderDto,
  UpdateOnboardingDocumentsDto,
  UpdateProviderBranchDto,
  UpdateProviderDto,
  UpdateProviderSettingsDto,
  UserProvider,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash.get';

import HealthHubApiClient from './api-client';
import { BooleanEnum, ProviderListType, ProviderStatusEnum } from '../enums';
import { CustomError } from '../errors';
import { Pagination, ProviderCustomFieldMetadata } from '../types';
import { ProviderStatistics } from '../types/provider.type';
import { encodeDtoValue } from '../utils';

export const providerApi = HealthHubApiClient.use(ProviderApi);

export type UpdateProviderParams = {
  id: number;
  updateProviderDto: UpdateProviderDto;
};

export type UpdateProviderSettingsParams = {
  id: number;
  updateProviderSettingsDto: UpdateProviderSettingsDto;
};

export type UpdateProviderCustomFieldsParams = {
  id: number;
  customFields: ProviderCustomFieldMetadata[];
};

export type ProviderParams = {
  fetchingReferrals?: BooleanEnum;
  hmoFilters?: string[];
  latitude?: number;
  limit?: number;
  longitude?: number;
  page?: number;
  providerId?: string;
  providerTypeId?: string;
  providerTypeIds?: string[];
  query?: string;
  status?: ProviderStatusEnum;
  isMphProvider?: string;
};

export async function onboardProvider(
  id: string,
  onboardProviderDto: OnboardProviderDto,
): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerOnboardProvider(id, onboardProviderDto);

    return data;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to onboard provider');
  }
}

export async function onboardBranch(id: string, dto: CreateProviderBranchDto): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerOnboardBranch(id, dto);

    return data;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to onboard branch');
  }
}

export async function createProvider(createProviderDto: CreateProviderDto): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerCreate(createProviderDto);

    return data;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    const isDuplicateClinic = errorMessage.includes('idx_name_branch');
    const isDuplicateProvider = errorMessage.includes('Provider');
    const isDuplicateRepresentative = errorMessage.includes('Representative');
    if (isDuplicateClinic) {
      throw new Error('A provider with the same legal name and branch already exists');
    }
    if (isDuplicateProvider) {
      throw new Error('A provider admin with the same email already exists');
    }
    if (isDuplicateRepresentative) {
      throw new Error('A representative with the same email already exists');
    }
    throw new Error('Failed to create provider');
  }
}

export async function createBranch(createBranchDto: CreateProviderBranchDto): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerCreateBranch(createBranchDto);

    return data;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to create branch');
  }
}

export async function updateBranch(updateBranchDto: UpdateProviderBranchDto): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerUpdateBranch(
      updateBranchDto.id,
      updateBranchDto,
    );

    return data;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to update branch');
  }
}

export async function getAllProviders(
  params: ProviderParams,
  config?: AxiosRequestConfig,
): Promise<Pagination<Provider>> {
  try {
    const {
      fetchingReferrals,
      hmoFilters,
      latitude,
      limit,
      longitude,
      page,
      providerId,
      providerTypeId,
      providerTypeIds,
      query,
      status,
      isMphProvider,
    } = encodeDtoValue(params);
    const { data } = await providerApi.providerControllerFindAll(
      query,
      providerTypeId,
      providerTypeIds,
      providerId,
      ProviderListType.BRAND,
      fetchingReferrals,
      longitude,
      latitude,
      hmoFilters,
      status,
      isMphProvider,
      limit,
      page,
      config,
    );

    return data as unknown as Pagination<Provider>;
  } catch (err) {
    throw new Error('Failed to fetch providers');
  }
}

export async function getAllBranches(params: ProviderParams): Promise<Pagination<Provider>> {
  try {
    const {
      fetchingReferrals,
      hmoFilters,
      latitude,
      limit,
      longitude,
      page,
      providerId,
      providerTypeId,
      providerTypeIds,
      query,
      status,
      isMphProvider,
    } = params;

    const { data } = await providerApi.providerControllerFindAll(
      query,
      providerTypeId,
      providerTypeIds,
      providerId,
      ProviderListType.BRANCH,
      fetchingReferrals,
      longitude,
      latitude,
      hmoFilters,
      status,
      isMphProvider,
      limit,
      page,
    );

    return data as unknown as Pagination<Provider>;
  } catch (err) {
    throw new Error('Failed to fetch provider branches');
  }
}

export async function getProviderById(
  id: number | string,
  config?: AxiosRequestConfig,
  excludes?: string[],
): Promise<Provider> {
  try {
    let data;
    if (typeof Number(id) === 'number' && !isNaN(Number(id))) {
      const response = await providerApi.providerControllerFindOne(Number(id), excludes, config);
      data = response.data;
    } else {
      const slugName = String(id);
      const response = await providerApi.providerControllerFindOneBySlugName(slugName, config);
      data = response.data;
    }
    return data as Provider;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateProvider(params: UpdateProviderParams): Promise<Provider> {
  const { id, updateProviderDto } = params;

  try {
    const { data } = await providerApi.providerControllerUpdate(id, updateProviderDto);

    return data;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to update provider');
  }
}

export async function updateProviderSettingsDto(
  params: UpdateProviderSettingsParams,
): Promise<Provider> {
  const { id, updateProviderSettingsDto } = params;

  try {
    const { data } = await providerApi.providerControllerUpdateProviderSettings(
      id,
      updateProviderSettingsDto,
    );

    return data as Provider;
  } catch (err) {
    throw new Error('Failed to update provider accepted HMO/Health Insurance');
  }
}

export async function updateProviderCustomFields(
  params: UpdateProviderCustomFieldsParams,
): Promise<Provider> {
  const { id, customFields } = params;

  try {
    const { data } = await providerApi.providerControllerUpdateProviderCustomFields(id, {
      customFields,
    });

    return data as Provider;
  } catch (err) {
    throw new Error('Failed to update provider accepted HMO/Health Insurance');
  }
}

export async function getProviderPatientById(
  id: number,
  query?: string,
  sex?: string,
  limit?: number,
  page?: number,
): Promise<Pagination<ProviderClientProfile> | ProviderClientProfile[]> {
  try {
    const { data } = await providerApi.providerControllerFindPatients(id, query, sex, limit, page);

    /**
     * TODO: 'as unknown as Pagination<ProviderClientProfile>' should be removed if the command 'npm run update-sources' is working
     */
    if (page && limit) {
      return data as unknown as Pagination<ProviderClientProfile>;
    }

    return data as ProviderClientProfile[];
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateMerchantAgreement({ id }: { id: number }): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerUpdateMerchantAgreement(id);
    return data as unknown as Provider;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderNotifications(id: number): Promise<Notification[]> {
  try {
    const { data } = await providerApi.providerControllerFindNotifications(id);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderUnreadNotificationsCount(
  id: number,
): Promise<Record<string, any>> {
  try {
    const { data } = await providerApi.providerControllerFindUnreadNotificationCountByType(id);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderCounts(): Promise<ProviderStatistics> {
  try {
    const { data } = await providerApi.providerControllerGetProviderCounts();

    return data as unknown as ProviderStatistics;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function markAllProviderNotificationsAsRead(): Promise<void> {
  try {
    await providerApi.providerControllerMarkAllNotificationsAsRead();
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function suggestProvider(data: SuggestProviderDto): Promise<void> {
  try {
    await providerApi.providerControllerSuggestProvider(data);
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function toggleAutoApproveAppointment(id: string): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerToggleAutoApproveAppointment(id);

    return data as unknown as Provider;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderUsersByProviderId(id: number): Promise<UserProvider[]> {
  try {
    const { data } = await providerApi.providerControllerFindProviderUsers(id);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function selfOnboardProvider(dto: SelfOnboardDto): Promise<Provider> {
  try {
    const { data } = await providerApi.providerControllerSelfOnboard(dto);

    return data;
  } catch (err) {
    const errorMessage =
      get(err, 'response.data.message') ||
      'There was an error creating your account. Please try again.';

    if (Array.isArray(errorMessage)) {
      throw new Error(errorMessage[0].errorMessage);
    }

    throw new Error(errorMessage);
  }
}

export async function updateOnboardingProgression(): Promise<void> {
  try {
    await providerApi.providerControllerUpdateOnboardingProgression();
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function uploadOnboardingDocuments(dto: UpdateOnboardingDocumentsDto): Promise<void> {
  try {
    await providerApi.providerControllerUpdateOnboardingProgressionDocuments(dto);
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderOnboardingDocuments() {
  try {
    const response = await providerApi.providerControllerGetProviderOnboardingDocuments();

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createOnboardingSampleAppointment() {
  try {
    const response = await providerApi.providerControllerCreateOnboardingSampleAppointment();

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function approveOnboarding(id: number, dto: UpdateProviderDto) {
  try {
    await providerApi.providerControllerApproveOnboarding(id, dto);
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function rejectOnboarding(id: number, reason: string) {
  try {
    await providerApi.providerControllerRejectOnboarding(id, {
      reason,
    });
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function resubmitOnboarding(id: number, reasons: string[]) {
  try {
    await providerApi.providerControllerResubmitOnboarding(id, {
      reasons,
    });
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getOnboardingAgreementDocuments() {
  try {
    const response = await providerApi.providerControllerGetOnboardingAgreements();

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}
