import React from 'react';

import TabWithCountItem from './TabWithCountItem';
import { TabType } from '../../types';
import { clsxMerge } from '../../utils';

type Props = {
  className?: string;
  tabContainerClassName?: string;
  selectedTab?: TabType;
  tabs: TabType[];
  onClickTab: (tab: TabType) => void;
};

const TabsWithCount: React.FC<Props> = (props: Props) => {
  const { className, tabContainerClassName, selectedTab, tabs, onClickTab } = props;

  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full flex-wrap rounded-md border-gray-300 p-[10px] text-xs focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.value === selectedTab?.value)?.label}
          onChange={(event) => {
            const selectedLabel = event.target.value;
            const selectedTab = tabs.find((tab) => tab.label === selectedLabel);
            onClickTab(selectedTab as TabType);
          }}
        >
          {tabs
            .filter((tab) => !tab.isHidden)
            .map((tab) => (
              <option key={tab.label}>{tab.label}</option>
            ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className={clsxMerge('border-b border-gray-200', tabContainerClassName)}>
          <nav className="flex flex-wrap md:space-x-2 lg:space-x-3" aria-label="Tabs">
            {tabs
              .filter((tab) => !tab.isHidden)
              .map((tab) => (
                <TabWithCountItem
                  key={tab.label}
                  tab={tab}
                  isDisabled={tab?.isDisabled}
                  isActive={Boolean(tab.value === selectedTab?.value)}
                  onClick={() => onClickTab(tab)}
                />
              ))}
          </nav>
        </div>
      </div>
      {selectedTab?.content}
    </div>
  );
};

export default TabsWithCount;
