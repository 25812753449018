import { EshopApi, TransactionExternalPaymentStatusEnum } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { OrderParams } from './orderService';
import { OrderByEnum, OrderStatusEnum, PaymentStatusEnum } from '../enums';
import { CustomError } from '../errors';
import {
  Pagination,
  TransactionMerchant,
  TransactionMerchantProduct,
  TransactionStatistics,
} from '../types';

export const transactionApi = HealthHubApiClient.use(EshopApi);

export type TransactionParams = {
  limit?: number;
  page?: number;
  query?: string;
  orderBy?: OrderByEnum;
  status?: OrderStatusEnum;
  paymentStatus?: PaymentStatusEnum;
  externalPaymentStatus?: TransactionExternalPaymentStatusEnum;
  merchantId?: string;
  merchantEmail?: string;
  productId?: string;
  dateFrom?: string;
  dateTo?: string;
  deliveryDateTo?: string;
  deliveryDateFrom?: string;
};

export async function getAllTransactionMerchantProducts(
  params: OrderParams,
): Promise<Pagination<TransactionMerchantProduct>> {
  try {
    const {
      query,
      status,
      limit,
      page,
      externalPaymentStatus,
      merchantEmail,
      merchantId,
      paymentStatus,
      orderBy,
      productId,
      dateFrom,
      dateTo,
      deliveryDateFrom,
      deliveryDateTo,
    } = params;
    const { data } = await transactionApi.eshopControllerFindAllTransactionMerchantProducts(
      query,
      merchantEmail,
      externalPaymentStatus,
      status,
      paymentStatus,
      orderBy,
      dateFrom,
      deliveryDateTo,
      deliveryDateFrom,
      dateTo,
      merchantId,
      productId,
      limit,
      page,
    );

    return data as unknown as Pagination<TransactionMerchantProduct>;
  } catch (err) {
    throw new Error('Failed to fetch transaction merchant products');
  }
}

export async function getAllTransactionMerchants(
  params: OrderParams,
): Promise<Pagination<TransactionMerchant>> {
  try {
    const {
      query,
      status,
      limit,
      page,
      externalPaymentStatus,
      merchantEmail,
      merchantId,
      paymentStatus,
      orderBy,
      productId,
      dateFrom,
      dateTo,
      deliveryDateFrom,
      deliveryDateTo,
    } = params;
    const { data } = await transactionApi.eshopControllerFindAllTransactionMerchants(
      query,
      merchantEmail,
      externalPaymentStatus,
      status,
      paymentStatus,
      orderBy,
      dateFrom,
      deliveryDateTo,
      deliveryDateFrom,
      dateTo,
      merchantId,
      productId,
      limit,
      page,
    );

    return data as unknown as Pagination<TransactionMerchant>;
  } catch (err) {
    throw new Error('Failed to fetch transaction merchants');
  }
}

export async function getTransactionById(
  id: string,
  config?: AxiosRequestConfig,
): Promise<TransactionMerchantProduct> {
  try {
    const response = await transactionApi.eshopControllerFindOneOrder(id, config);
    const data = response.data;

    return data as unknown as TransactionMerchantProduct;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getTransactionCounts(merchantEmail = ''): Promise<TransactionStatistics> {
  try {
    let data;

    if (merchantEmail) {
      const response = await transactionApi.eshopControllerGetTransactionCounts(merchantEmail);
      data = response.data;
    } else {
      const response = await transactionApi.eshopControllerGetAllTransactionCounts();
      data = response.data;
    }

    return data as unknown as TransactionStatistics;
  } catch (error) {
    throw new CustomError(error);
  }
}
