import React, { useState } from 'react';

import { useUploadFileMutation } from './mutations/uploadMutations';
import toast from '../components/toast';

type Params = {
  acceptedFileTypes: string[];
  onSuccess: (fileUrl: string) => void;
};

export function useFileUploadHandler({ acceptedFileTypes, onSuccess }: Params) {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFileMutation({
    onSuccess: (fileData) => {
      setFileUrl(fileData.url);
      onSuccess(fileData.url);
    },
    onError: () => {
      setFileUrl(null);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const currentFile = files[0];

    if (!currentFile) {
      return;
    }

    if (!acceptedFileTypes.includes(currentFile.type)) {
      toast({
        message: `Please upload ${acceptedFileTypes.join(', ')}`,
        type: 'error',
      });

      return;
    }

    uploadFile(files[0]);
  };

  return {
    fileUrl,
    isUploading,
    handleFileChange,
  };
}
