export const prevHmoList = [
  {
    id: 1,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Asalus Corporation',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 2,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Asiancare Health Systems, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 3,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Avega Managed Care, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 4,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Carehealth Plus Systems, International Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 5,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Carewell Health Systems, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 6,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Cooperative Health Management Federation',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 7,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Dynamic Care Corporation',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 8,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'EastWest Healthcare, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 9,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Getwell Health Systems, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 10,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Health Care Development Corporation of the Philippines',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 11,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Health Delivery System, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 12,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Health Maintenance, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 13,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Health Plan Philippines, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 14,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'IMS Wellth Care, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 15,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Insular Health Care, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 16,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Kaiser International Healthgroup, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 17,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Life & Health HMP, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 18,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Maxicare Healthcare Corp.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 19,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Medicare Philippines, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 20,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Medicare Plus, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 21,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Medocare Health Systems, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 22,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Metrocare Health Systems, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 23,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Optimum Medical and HealthCare Services, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 24,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Pacific Cross Health Care, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 25,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'PhilhealthCare, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 26,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Stotsenberg Healthcare System Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 27,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Value Care Health Systems, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 28,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Wellcare Health Maintenance Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 29,
    address: '',
    description: '',

    email: '',
    imageUrl: '',
    name: 'Forticare Health Systems International, Inc.',
    phone: '',
    website: '',
    isActive: true,
  },
];

export const hmoList = [
  {
    id: 18,
    address: '',
    description: '',
    email: '',
    imageUrl: '',
    name: 'Maxicare', // same with Maxicare Healthcare Corp.
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 30,
    address: '',
    description: '',
    email: '',
    imageUrl: '',
    name: 'MediCard',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 1,
    address: '',
    description: '',
    email: '',
    imageUrl: '',
    name: 'Intellicare', // same with Asalus Corporation
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 31,
    address: '',
    description: '',
    email: '',
    imageUrl: '',
    name: 'Cocolife Healthcare',
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 25,
    address: '',
    description: '',
    email: '',
    imageUrl: '',
    name: 'PhilCare', // PhilhealthCare, Inc.
    phone: '',
    website: '',
    isActive: true,
  },
  {
    id: 32,
    address: '',
    description: '',
    email: '',
    imageUrl: '',
    name: 'Etiqa',
    phone: '',
    website: '',
    isActive: true,
  },
];
