import { Provider } from '@healthhub/api-lib';

import { SHOW_SELF_ONBOARDING } from '../constants';
import { DocumentReviewStatusEnum, ProviderOnboardingStatusEnum } from '../enums';

// add more repeating helper methods as needed
export default class ProviderHelper {
  provider: Provider;

  constructor(provider: Provider) {
    this.provider = provider;
  }

  get isBranch() {
    return this.provider.isBranch;
  }

  get isBrand() {
    return !this.provider.isBranch;
  }

  get logoUrl() {
    return this.provider.logoUrl || this.provider?.parentProvider?.logoUrl;
  }

  get isSelfOnboarding() {
    return (
      this.provider?.onboardingProgression &&
      this.provider?.onboardingStatus === ProviderOnboardingStatusEnum.SELF_ONBOARDED &&
      SHOW_SELF_ONBOARDING
    );
  }

  get isResubmittingDocuments() {
    return (
      this.isSelfOnboarding &&
      this.provider?.onboardingProgression?.documentReviewStatus ===
        DocumentReviewStatusEnum.REJECTED
    );
  }
}
