import { useEffect, useState } from 'react';

export const DEFAULT_DEBOUNCE_SECONDS = 300;

export const useDebounce = <T extends any[]>(
  func: (...args: T) => void,
  delay: number,
): ((...args: T) => void) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (...args: T) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      func(...args);
    }, delay);

    setTimer(newTimer);
  };
};
