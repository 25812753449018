import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type Props = {
  itemsPerPage: number;
  onPageChange: (newPage: number) => void;
  page: number;
  totalItems: number;
  totalPages: number;
};

const Pagination = (props: Props) => {
  const { onPageChange, page, totalItems, totalPages } = props;

  const isLastPage = page >= totalPages;

  const handlePreviousPage = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const handleNextPage = () => {
    if (isLastPage) {
      return;
    }

    onPageChange(page + 1);
  };

  const hasNoData = !totalItems;

  return (
    <nav
      className="flex items-center justify-between border-b border-gray-200 px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      {/* TODO fix pagination label "showing x out of x" */}

      <div className={twMerge('flex flex-1 justify-between sm:justify-end', hasNoData && 'hidden')}>
        <ul className="list-style-none flex items-center">
          <li>
            <ChevronLeftIcon
              onClick={handlePreviousPage}
              className={clsx('mr-4 h-6  w-6', {
                'text-neutral-300': 1 === page,
                'cursor-pointer text-primary-500': 1 !== page,
              })}
              aria-hidden="true"
            />
          </li>
          {[...Array(totalPages + 1).keys()].slice(1).map((currentPage) => (
            <li
              key={currentPage}
              onClick={() => onPageChange(currentPage)}
              className={clsx('cursor-pointer rounded-lg px-4 py-2 font-medium', {
                'border border-gray-300': currentPage === page,
                'text-primary-500': currentPage !== page,
                'text-neutral-500': currentPage === page,
              })}
            >
              {currentPage}
            </li>
          ))}
          <li>
            <ChevronRightIcon
              onClick={handleNextPage}
              className={clsx('ml-4 h-6  w-6', {
                'text-neutral-300': isLastPage,
                'cursor-pointer text-primary-500': !isLastPage,
              })}
              aria-hidden="true"
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Pagination;
