import React from 'react';

import TabWithBackgroundItem from './TabWithBackgroundItem';
import { TabType } from '../../types';

type Props = {
  selectedTab?: TabType;
  tabs: TabType[];
  onClickTab: (tab: TabType) => void;
};

const TabsWithBackground: React.FC<Props> = (props: Props) => {
  const { selectedTab, tabs, onClickTab } = props;

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const tab = tabs.find((tab) => tab.label === event.target.value);
    tab && onClickTab(tab);
  };

  return (
    <div className="w-100">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="focus:border-indigo-500 focus:ring-indigo-500 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none sm:text-sm"
          defaultValue={tabs.find((tab) => tab.value === selectedTab?.value)?.label}
          onChange={handleSelectChange}
        >
          {tabs.map((tab) => (
            <option key={tab.label}>{tab.label}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex rounded-lg" aria-label="Tabs">
          {tabs.map((tab) => (
            <TabWithBackgroundItem
              key={tab.label}
              tab={tab}
              isActive={Boolean(tab.value === selectedTab?.value)}
              onClick={() => onClickTab(tab)}
            />
          ))}
        </nav>
      </div>
    </div>
  );
};

export default TabsWithBackground;
