import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

type Props = {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  title: string;
  onRightIconClick?: () => void;
  containerClassName?: string;
};

const SubNavigation = (props: Props) => {
  const { rightIcon, title, onRightIconClick, containerClassName } = props;

  const rightIconDisplay = rightIcon || (
    <div>
      <ChevronRightIcon className="h-6 w-6" />
    </div>
  );

  return (
    <div
      className={twMerge('flex cursor-pointer', containerClassName ?? 'bg-white shadow-topNav')}
      onClick={onRightIconClick}
    >
      <h4 className="flex-auto truncate text-neutral-900">{title}</h4>
      {rightIconDisplay}
    </div>
  );
};

export default SubNavigation;
