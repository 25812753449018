/* eslint-disable @next/next/no-img-element */
import React from 'react';

import { ClientProfile, User } from '@healthhub/api-lib';

import {
  calculateAgeHumanReadable,
  formatToHumanReadable,
  formatUserNameToFull,
} from '@mwell-healthhub/commons';

import UserIconCircle from './UserIconCircle';

type Props = {
  clientProfile: ClientProfile | User;
  onClick: (clientProfile: ClientProfile | User) => void;
  selectorElement: React.ReactNode;
};

const ClientListItem = (props: Props) => {
  const { clientProfile, onClick, selectorElement } = props;

  const handleItemClick = () => {
    onClick(clientProfile);
  };

  return (
    <div
      className="flex cursor-pointer items-center justify-between rounded-lg border border-gray39 p-3"
      onClick={handleItemClick}
    >
      <div className="flex w-[80%] items-center gap-3">
        <div className="flex-none">
          {clientProfile.displayPhotoUrl ? (
            <img
              className="h-10 w-10 rounded-full"
              src={clientProfile.displayPhotoUrl}
              alt="Client profile"
            />
          ) : (
            <UserIconCircle />
          )}
        </div>
        <div className="flex w-[80%] flex-col gap-1">
          <h1 className="w-full truncate text-sm font-medium">
            {formatUserNameToFull(clientProfile)}
          </h1>
          <h2 className="w-full truncate text-sm text-graniteGray">
            {formatToHumanReadable(clientProfile.birthDate)} (
            {calculateAgeHumanReadable(new Date(clientProfile.birthDate))}) •{' '}
            <span className="capitalize">{clientProfile.sex}</span>
          </h2>
        </div>
      </div>
      {selectorElement}
    </div>
  );
};

export default ClientListItem;
