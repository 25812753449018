import { useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/solid';

import { Button, Card, Modal, downloadFileByUrl } from '@mwell-healthhub/commons';

type Props = {
  qrCodeUrl: string;
  providerName: string;
  providerType: string;
};

export default function ProviderDigitalId(props: Props) {
  const { qrCodeUrl, providerName, providerType } = props;
  const [isShowDigitalIdQRModal, setIsShowDigitalIdQRModal] = useState(false);

  const handleExportQRCode = async () => {
    try {
      await downloadFileByUrl(qrCodeUrl, providerName);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <>
      <Card className="space-y-6 p-8">
        <h1 className="text-lg font-semibold leading-7 text-black">Digital ID</h1>
        <div className="flex h-[156px] flex-col items-start justify-start gap-3 self-stretch">
          <span className="font-['Circular Std'] text-sm leading-normal text-[#111111]">
            QR Code
          </span>
          <div className="inline-flex items-center justify-start gap-6">
            <div className="flex h-[120px] w-[120px] items-center justify-center rounded-lg border border-[#dae3e5] p-[5px]">
              <img className="h-[110px] w-[110px]" src={qrCodeUrl} />
            </div>
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <Button variant="primary" className="w-full" onClick={handleExportQRCode}>
                Export QR Code
              </Button>
              <Button
                variant="secondary"
                className="w-full"
                onClick={() => setIsShowDigitalIdQRModal(true)}
              >
                View QR Code
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <Modal
        open={isShowDigitalIdQRModal}
        setOpen={setIsShowDigitalIdQRModal}
        onClose={() => {
          setIsShowDigitalIdQRModal(false);
        }}
        size="lg"
        className="relative flex flex-col gap-y-6"
      >
        <Button
          variant="plain"
          icon={XMarkIcon}
          className="absolute right-4 top-4 h-6 w-6 px-0 py-0"
          onClick={() => setIsShowDigitalIdQRModal(false)}
        />
        <div className="flex h-7 flex-col items-start justify-start gap-6 self-stretch">
          <h1 className="text-xl font-semibold leading-7 text-[#111111]">QR Code</h1>
        </div>
        <div className="relative">
          <img src="/static-assets/images/digital-id-qr-template.svg" alt="" />
          <div className="absolute left-1/2 top-1/2 flex min-h-[364px] w-auto -translate-x-1/2 -translate-y-[80px] flex-col items-center gap-6">
            <div className="flex h-[260px] w-[260px] items-center justify-center rounded-lg bg-neutral-50 p-4">
              <img src={qrCodeUrl} alt="QR Code" className="h-full w-full rounded-lg" />
            </div>
            <div className="flex flex-col items-center gap-1">
              <h2 className="whitespace-nowrap text-2xl font-semibold text-primary-500">
                {providerName}
              </h2>
              <span className="whitespace-nowrap text-base font-medium text-primary-400">
                {providerType}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
