export const Images = {
  Delivery: '/static-assets/images/motor.svg',
  Heart: '/static-assets/images/heart.png',
  Hospital: '/static-assets/images/hospital.png',
  PickUp: '/static-assets/images/bag.svg',
  Laboratory: '/static-assets/images/lab.svg',
  Location: '/static-assets/images/location.svg',
  Imaging: '/static-assets/images/imaging.svg',
  Calendar: '/static-assets/images/calendar.svg',
  Money: '/static-assets/images/money.svg',
  HeartPlaceholder: '/static-assets/images/heart-placeholder.svg',
  WhiteCheckmark: '/static-assets/images/white-checkmark.svg',
  Lock: '/static-assets/images/lock.svg',
};
