import { ChevronDownIcon, MapPinIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';

type Props = {
  isLoadingAddress: boolean;
  link: string;
  location: string;
  position: string;
  fontsize?: string;
  fontColor?: string;
};

const Location = (props: Props) => {
  const { isLoadingAddress, link, location, position, fontsize, fontColor } = props;
  const locationDisplay =
    isLoadingAddress || !location ? (
      <span className={twMerge('mx-1.5 font-semibold', fontsize, fontColor)}>
        Loading location...
      </span>
    ) : (
      <>
        <a
          href={`${link}&query=${location}&position=${position}`}
          className={twMerge('mx-1.5 font-semibold', fontsize, fontColor)}
          data-cy="location-link"
        >
          {location}
        </a>
        <span>
          <ChevronDownIcon className={twMerge('inline h-5 w-5', fontColor)} />
        </span>
      </>
    );

  return (
    <a
      className="flex cursor-pointer items-center sm:mt-0 md:mt-5"
      href={`${link}&query=${location}&position=${position}`}
    >
      <div>
        <MapPinIcon className={twMerge('mr-1.5 h-5 w-5', fontColor)} />
      </div>
      <p className="flex flex-nowrap items-center text-sm">{locationDisplay}</p>
    </a>
  );
};

export default Location;
