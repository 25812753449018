import {
  AppointmentsApi,
  CreateAppointmentDto,
  RescheduleAppointmentDto,
  UpdateAppointmentDto,
  Appointment,
  UpdateInPersonPaymentDto,
  CreateGroupBookingDto,
  BulkRescheduleDto,
  BulkCancelDto,
  CreateMultiScheduleAppointmentDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { Pagination } from '../types';

const appointmentApi = HealthHubApiClient.use(AppointmentsApi);

export type GetDocumentsDTO = {
  appointmentId: number;
};

export type GetProviderClientProfileAppointmentsDto = {
  startDate?: string;
  endDate?: string;
  query?: string;
  providerClientProfileId?: string;
  status?: string;
  limit?: number;
  page?: number;
  branchId?: string;
};

export type GetAppointmentsForExportDto = {
  startDate?: string;
  endDate?: string;
  clientId?: string;
  statuses?: string[];
  serviceIds?: number[];
  tagIds?: number[];
  sortBy?: string;
  branchIds?: number[];
};

export const addAppointmentTags = async ({
  id,
  updateAppointmentDto,
}: {
  id: string;
  updateAppointmentDto: UpdateAppointmentDto;
}): Promise<void> => {
  try {
    await appointmentApi.appointmentsControllerAddAppointmentTags(id, updateAppointmentDto);
    return;
  } catch (error) {
    throw new Error('Failed to update appointments');
  }
};

export const updateInPersonPayment = async ({
  id,
  updateInPersonPaymentDto,
}: {
  id: string;
  updateInPersonPaymentDto: UpdateInPersonPaymentDto;
}): Promise<void> => {
  try {
    await appointmentApi.appointmentsControllerUpdateInPersonPayment(id, updateInPersonPaymentDto);
    return;
  } catch (error) {
    throw new Error('Failed to update in-person payments');
  }
};

export const getAllAppointments = async (
  startDate?: string,
  endDate?: string,
  status?: string,
  query?: string,
  limit?: number,
  page?: number,
  providerTypeId?: string,
  providerServiceIds?: number[],
  providerTagIds?: number[],
  sortBy?: string,
  branchIds?: number[],
  brandId?: string,
  platform?: string,
): Promise<Pagination<Appointment>> => {
  try {
    const response = await appointmentApi.appointmentsControllerFindAll(
      startDate,
      endDate,
      status,
      query,
      providerTypeId,
      providerServiceIds,
      providerTagIds,
      sortBy,
      branchIds,
      brandId,
      platform,
      limit,
      page,
    );

    return response.data as Pagination<Appointment>;
  } catch (error) {
    throw new Error('Failed to fetch appointments');
  }
};

export const getAllAppointmentsForExport = async ({
  startDate,
  endDate,
  clientId,
  statuses,
  serviceIds,
  tagIds,
  sortBy,
  branchIds,
}: GetAppointmentsForExportDto): Promise<Appointment[]> => {
  try {
    const response = await appointmentApi.appointmentsControllerFindAllForExport(
      startDate,
      endDate,
      clientId,
      statuses,
      serviceIds,
      tagIds,
      sortBy,
      branchIds,
    );

    return response.data as Appointment[];
  } catch (error) {
    throw new Error('Failed to fetch appointments');
  }
};

export const getAllAppointmentsForReporting = async (
  startDate?: string,
  endDate?: string,
  status?: string,
  query?: string,
  providerTypeId?: string,
  brandId?: string,
): Promise<Appointment[]> => {
  try {
    const response = await appointmentApi.appointmentsControllerFindAllForReport(
      startDate,
      endDate,
      status,
      query,
      providerTypeId,
      undefined,
      undefined,
      undefined,
      undefined,
      brandId,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch Appointents');
  }
};

export const getAllAppointmentsByPage = async (
  startDate?: string,
  endDate?: string,
  status?: string,
  limit?: number,
  page?: number,
  query?: string,
  providerTypeId?: string,
  providerServiceIds?: number[],
  providerTagIds?: number[],
  sortBy?: string,
  branchIds?: number[],
  brandId?: string,
  platform?: string,
): Promise<Appointment> => {
  try {
    const response = await appointmentApi.appointmentsControllerFindAll(
      startDate,
      endDate,
      status,
      query,
      providerTypeId,
      providerServiceIds,
      providerTagIds,
      sortBy,
      branchIds,
      brandId,
      platform,
      limit,
      page,
    );

    return response.data as Appointment;
  } catch (error) {
    throw new Error('Failed to fetch appointments');
  }
};

export const fetchAppointmentById = async (
  id: string,
  config?: AxiosRequestConfig,
): Promise<Appointment> => {
  try {
    const response = await appointmentApi.appointmentsControllerFindOne(id, config);

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch appointment');
  }
};

export const fetchProviderClientProfileAppointments = async (
  params: GetProviderClientProfileAppointmentsDto,
): Promise<Pagination<Appointment>> => {
  try {
    const { startDate, endDate, status, providerClientProfileId, query, limit, page, branchId } =
      params;
    const response =
      await appointmentApi.appointmentsControllerFindProviderClientProfileAppointments(
        startDate,
        endDate,
        query,
        providerClientProfileId,
        status,
        branchId,
        limit,
        page,
      );

    return response.data as Pagination<Appointment>;
  } catch (error) {
    throw new Error('Failed to fetch appointment');
  }
};

export async function uploadDiagnosticFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await appointmentApi.appointmentsControllerUploadDiagnosticFile({
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
}

export async function getAppointmentReferralFormSignedUrl(
  id?: number,
  referralFormFileId?: number,
): Promise<string> {
  if (!id || !referralFormFileId) {
    return '';
  }

  try {
    const response = await appointmentApi.appointmentsControllerGetAppointmentReferralFormSignedUrl(
      id.toString(),
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch referral form signed URL');
  }
}

export async function addAppointment(appointment: CreateAppointmentDto): Promise<Appointment> {
  const { data } = await appointmentApi.appointmentsControllerCreate(appointment);

  return data;
}

export async function addGroupAppointment(dto: CreateGroupBookingDto): Promise<Appointment[]> {
  const { data } = await appointmentApi.appointmentsControllerCreateMany(dto);

  return data as unknown as Appointment[];
}

export async function bookMultiScheduleAppointment(
  dto: CreateMultiScheduleAppointmentDto,
): Promise<Appointment[]> {
  const { data } = await appointmentApi.appointmentsControllerCreateMultiSchedule(dto);

  return data as unknown as Appointment[];
}

export async function bulkRescheduleAppointments(dto: BulkRescheduleDto): Promise<Appointment[]> {
  const { data } = await appointmentApi.appointmentsControllerBulkReschedule(dto);

  return data as unknown as Appointment[];
}

export async function bulkCancelAppointments(dto: BulkCancelDto): Promise<void> {
  await appointmentApi.appointmentsControllerBulkCancel(dto);
}

export async function ongoingAppointment(id: number) {
  const { status } = await appointmentApi.appointmentsControllerOngoingAppointment(Number(id));

  return status;
}

export async function noShowAppointment(id: number) {
  const { status } = await appointmentApi.appointmentsControllerNoShowAppointment(Number(id));

  return status;
}

export async function completeAppointment(id: number) {
  const { status } = await appointmentApi.appointmentsControllerCompleteAppointment(Number(id));

  return status;
}

export async function rescheduleAppointment({
  id,
  rescheduleAppointmentDto,
}: {
  id: number;
  rescheduleAppointmentDto: RescheduleAppointmentDto;
}) {
  const { status } = await appointmentApi.appointmentsControllerReschedule(
    Number(id),
    rescheduleAppointmentDto,
  );

  return status;
}

export async function cancelAppointment({
  id,
  appointmentDto,
}: {
  id: number;
  appointmentDto: UpdateAppointmentDto;
}) {
  const { data } = await appointmentApi.appointmentsControllerCancelAppointment(
    id.toString(),
    appointmentDto,
  );

  return data;
}

export async function getAppointmentSchedule(id: string) {
  const { data } = await appointmentApi.appointmentsControllerFindOne(id);

  return data;
}

export async function getAdminAppointmentSummary(
  startDate?: string,
  endDate?: string,
  status?: string,
  query?: string,
  providerTypeId?: string,
  brandId?: string,
) {
  const isProviderTypeIdAll = providerTypeId === 'all';
  const newProviderTypeId = isProviderTypeIdAll ? undefined : providerTypeId;

  const { data } = await appointmentApi.appointmentsControllerGetAdminAppointmentSummary(
    startDate,
    endDate,
    status,
    query,
    newProviderTypeId,
    undefined,
    undefined,
    undefined,
    undefined,
    brandId,
  );

  return data;
}

export async function approveAppointment(id: number) {
  await appointmentApi.appointmentsControllerApproveAppointment(id.toString());
}

export async function declineAppointment(id: number) {
  await appointmentApi.appointmentsControllerDeclineAppointment(id.toString());
}
