import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import * as yup from 'yup';

import { COUNTRY_CODES, defaultCountryCode } from '../constants';

export const mobilePhoneNumberSchema = yup.string();

export function getPhoneNumberDetails(
  mobilePhoneNumber: string,
  dialCode = '+63',
): {
  completeMobileNumber?: string;
  countryCode?: string;
  numberWithoutCountryCode?: string;
  isValidPhoneNumber: boolean;
} {
  const defaultCountry = findCountryCodeFromDialCode(dialCode)?.code as unknown as CountryCode;
  const phoneNumber = parsePhoneNumber(mobilePhoneNumber, {
    defaultCountry,
  });

  return {
    completeMobileNumber: phoneNumber?.number,
    countryCode: phoneNumber?.countryCallingCode,
    numberWithoutCountryCode: phoneNumber?.nationalNumber,
    isValidPhoneNumber: !!phoneNumber?.isValid(),
  };
}

export function findCountryCodeFromDialCode(dialCode: string) {
  return COUNTRY_CODES.find((countryCode) => countryCode.dial_code === dialCode);
}

export const getMobilePhoneNumberValidationRule = (
  dialCode = defaultCountryCode.dial_code,
  shouldValidateEmptyPhoneNumber = true,
) => {
  return mobilePhoneNumberSchema.test('phone', 'Invalid phone number.', (phoneNumber = '') => {
    const isEmpty = !phoneNumber;

    if (isEmpty) {
      return !shouldValidateEmptyPhoneNumber;
    }

    // Always validate if phone number has value

    const isDigitOnly = /^\d+$/.test(phoneNumber);

    if (!isDigitOnly) {
      // Assuming phone number only has number in it
      return false;
    }

    const mobilePhoneNumber = getPhoneNumberDetails(
      phoneNumber,
      dialCode ?? defaultCountryCode.dial_code,
    );

    return mobilePhoneNumber.isValidPhoneNumber;
  });
};
