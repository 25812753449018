import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import {
  Button,
  clsxMerge,
  MultiSelectOptionType,
  toast,
  useDebounce,
} from '@mwell-healthhub/commons';

type Props = {
  className?: string;
  isLinkType?: boolean;
  disabledText?: string;
  dropdownClassName?: string;
  caretClassName?: string;
  label?: string;
  options: MultiSelectOptionType[];
  placeholder?: string;
  selectedOptions?: MultiSelectOptionType[];
  selectInnerClassName?: string;
  showRequiredOnLabel?: boolean;
  showSelectedCount?: boolean;
  clearLabel?: string;
  minSelections?: number;
  onRemove: (updatedSelections: MultiSelectOptionType[]) => void;
  onSelect: (updatedSelections: MultiSelectOptionType[]) => void;
  onChangeText?: (text: string) => void;
  onSubmit?: () => void;
  onClearAll?: () => void;
  pillVariant?: 'light' | 'gray';
  dropdownPopupState: boolean;
  toggleDropdownPopupState: () => void;
};

const MultiSelectDropdownPopup = (props: Props) => {
  const {
    selectedOptions = [],
    minSelections,
    disabledText = '',
    dropdownClassName,
    onRemove,
    onSelect,
    options = [],
    onChangeText,
    clearLabel = 'Clear',
    onSubmit,
    onClearAll,
    dropdownPopupState,
    toggleDropdownPopupState,
  } = props;

  const [searchText, setSearchText] = useState('');
  const dropdownPopupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownPopupRef.current && !dropdownPopupRef.current.contains(event.target as Node)) {
        toggleDropdownPopupState();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleSelectAll = () => {
    onSelect(options);
    setSearchText('');
    onSubmit?.();
  };

  const handleOptionClick = (option: MultiSelectOptionType) => {
    const isAlreadySelected = selectedOptions.some((selected) => selected.value === option.value);
    let updatedOptions: MultiSelectOptionType[];
    const isMinSelectionLimitReached =
      minSelections !== undefined && selectedOptions.length <= minSelections;

    if (isAlreadySelected) {
      if (isMinSelectionLimitReached) {
        toast({ message: `You must select at least ${minSelections} options`, type: 'error' });
        return;
      } else {
        updatedOptions = selectedOptions.filter((selected) => selected.value !== option.value);
      }
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    onSelect(updatedOptions);
    setSearchText('');
  };

  const search = (query: string) => {
    onChangeText?.(query);
  };

  const debouncedSearch = useDebounce(search, 300);

  const handleOnChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
    setSearchText(event.target.value);
  };

  const filteredOptions = options?.filter(
    (option) => option.text?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const groupOptionsByOptGroup = (
    options: MultiSelectOptionType[],
  ): { [key: string]: MultiSelectOptionType[] } => {
    const groupedOptions: { [key: string]: MultiSelectOptionType[] } = {};
    // Group the options by their optGroup property if it exists
    options.forEach((option) => {
      const optGroup = option.optGroup || 'default';
      if (!groupedOptions[optGroup]) {
        groupedOptions[optGroup] = [];
      }
      groupedOptions[optGroup].push(option);
    });
    return groupedOptions;
  };

  const groupedOptions = groupOptionsByOptGroup(filteredOptions);

  function getOptionClassName(option: MultiSelectOptionType) {
    return clsxMerge(
      'flex cursor-pointer items-center space-x-2 rounded-lg px-4 py-2.5 text-sm hover:bg-neutral-200',
      option.isDisabled && 'cursor-not-allowed',
    );
  }

  function getOptionContent(option: MultiSelectOptionType, disabledText: string) {
    if (option?.rightContent) {
      return (
        <div className="flex grow  items-center">
          <div className={clsxMerge('grow', option.isDisabled && `text-gray-400`)}>
            {option?.text}
            {option.isDisabled && <small className="ml-1.5">{disabledText}</small>}
          </div>
          <div>{option?.rightContent}</div>
        </div>
      );
    } else {
      return (
        <div className={clsxMerge('grow', option.isDisabled && `text-gray-400`)}>
          {option.text}
          {option.isDisabled && <small className="ml-1.5">{disabledText}</small>}
        </div>
      );
    }
  }

  const renderFilteredOptions = Object.keys(groupedOptions).map((category) => {
    if (category !== 'default') {
      return (
        <div key={category}>
          <div className="px-4 py-2.5 text-xs font-semibold leading-none tracking-tight text-gray-400">
            {category}
          </div>
          {groupedOptions[category].map((option) => renderOption(option))}

          <hr className="my-2 text-neutral-300" />
        </div>
      );
    } else {
      return groupedOptions[category].map((option) => renderOption(option));
    }
  });

  function renderOption(option: MultiSelectOptionType) {
    const isSelected = selectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    const handleSelect = option.isDisabled ? undefined : () => handleOptionClick(option);

    return (
      <div key={option.value} className={getOptionClassName(option)} onClick={handleSelect}>
        {getOptionContent(option, disabledText)}
        <CheckIcon
          className={clsxMerge('collapse h-5 w-5 text-green-700', { visible: isSelected })}
        />
      </div>
    );
  }

  return (
    <>
      {dropdownPopupState && (
        <div
          className={clsxMerge(
            'absolute z-10 mt-2 w-max min-w-[276px] divide-y divide-neutral-200 rounded-md border border-solid border-neutral-300 bg-white px-2 pb-1 pt-2 shadow-dropdown',
            dropdownClassName,
          )}
          ref={dropdownPopupRef}
        >
          <input
            type="text"
            className="top-0 mb-2 w-full rounded-lg border border-solid border-neutral-300 px-3 py-2 text-sm focus:border-primary-500"
            placeholder="Search..."
            value={searchText}
            onChange={handleOnChangeText}
          />
          <Button
            variant="secondaryGray"
            className="w-full justify-start border-none px-3 py-2.5 text-sm font-normal"
            onClick={handleSelectAll}
          >
            Select all
          </Button>
          <div className="max-h-60 overflow-y-auto">{renderFilteredOptions}</div>
          <div className="flex items-center justify-end space-x-2 p-2">
            {!minSelections && (
              <Button
                onClick={() => {
                  onClearAll?.();

                  if (onClearAll) {
                    toggleDropdownPopupState();
                  }

                  onRemove([]);
                }}
                variant="secondary"
              >
                {clearLabel}
              </Button>
            )}
            <Button
              onClick={() => {
                onSubmit?.();
                toggleDropdownPopupState();
              }}
              variant="primary"
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiSelectDropdownPopup;
