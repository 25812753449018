import { useImperativeHandle, useMemo, useState } from 'react';

import {
  Address,
  CreateProviderDto,
  Provider,
  UpdateProviderDto,
  UserOperation,
  UserProvider,
} from '@healthhub/api-lib';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, Resolver, useForm } from 'react-hook-form';

import ProviderAddressDetails from './forms/ProviderAddressDetails';
import ProviderAutoApproveAppointment from './forms/ProviderAutoApproveAppointment';
import ProviderBusinessDocuments from './forms/ProviderBusinessDocuments';
import ProviderContactDetails from './forms/ProviderContactDetails';
import ProviderDetails from './forms/ProviderDetails';
import ProviderStatus from './forms/ProviderStatus';
import ProviderUserDetails from './forms/ProviderUserDetails';
import { ActionModal, Checkbox, MultiSelectOptionType, ProviderDigitalId, toast } from '../..';
import { trackEvent, EventName } from '../..//utils/analytics';
import { validateUserProviderEmail } from '../../api';
import { COUNTRY_CODES, OTHERS_LABEL, SHOW_DIGITAL_IDS, SHOW_MPH_REFERRAL } from '../../constants';
import { ProviderFormEnum, ProviderStatusEnum, Routes, Variants } from '../../enums';
import {
  useCreateProviderMutation,
  useRouter,
  useToggle,
  useUpdateProviderMutation,
} from '../../hooks';
import { ProviderUserType, UserType } from '../../types';
import {
  ensureDecimalPlaces,
  formatToHumanReadable,
  formatProviderDisplayNameWithBranch,
} from '../../utils';
import ProviderHelper from '../../utils/ProviderHelper';
import { providerSchema } from '../../validators/provider-profile';
import { AddressFormType } from '../ProviderFormFields/AddressFormFields';
import { RepresentativeFormType } from '../ProviderFormFields/RepresentativeFormFields';
import Button from '../v2/Button';

export type AddUserProviderType = {
  id?: number;
  confirmPassword: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  isEditing: boolean;
};

export type AddProviderType = {
  address: AddressFormType;
  bannerUrl: string;
  branch: string;
  countryCode?: string;
  displayName: string;
  logoUrl: string;
  mobilePhoneNumber?: string;
  name: string;
  officeHours: string;
  providerTypeId?: number;
  providerTypeIds: number[];
  providerTypes?: MultiSelectOptionType[];
  representative: RepresentativeFormType;
  status: ProviderStatusEnum;
  userProvider: AddUserProviderType;
  isAutoApproveAppointmentEnabled?: boolean;
  isMphProvider?: boolean;
};

type Props = {
  provider?: Provider;
  showStatusSection?: boolean;
  currentUser?: UserOperation | UserProvider;
  isAddProvider?: boolean;
  formRef?: React.RefObject<any>;
  onSave?: () => void;
  onLogout?: () => void;
};

const PH_CODE = COUNTRY_CODES[0];

function ProviderProfile(props: Props) {
  const router = useRouter();

  const {
    currentUser,
    provider: initialProvider,
    showStatusSection,
    onSave,
    onLogout,
    isAddProvider,
    formRef,
  } = props;
  const isEditing = !!initialProvider?.id;

  const [provider, setProvider] = useState(initialProvider);
  const { isOpen, handleToggle } = useToggle();

  const superAdminUser = provider?.userProviders?.find(
    (userProvider) => userProvider.userType === ProviderUserType.SUPER_ADMIN,
  ) as UserProvider;

  const isCurrentUserSuperAdmin = currentUser?.userType === ProviderUserType.SUPER_ADMIN;

  const user = isCurrentUserSuperAdmin ? currentUser : superAdminUser;

  const currentUserProvider = (currentUser as UserProvider)?.provider;
  const isCurrentUserIsMphProvider = currentUserProvider?.isBranch
    ? currentUserProvider?.parentProvider?.isMphProvider
    : currentUserProvider?.isMphProvider;

  const [rep] = provider?.representatives || [];
  const address = provider?.address;
  const providerProviderTypes = provider?.providerTypes || [];

  const mappedProviderTypesIds: number[] = useMemo(() => {
    return providerProviderTypes.map((providerType: { id: any }) => providerType.id);
  }, [providerProviderTypes]);

  const mappedProviderTypes: MultiSelectOptionType[] = useMemo(() => {
    return providerProviderTypes.map((providerType: { name: any; id: any }) => ({
      text: providerType?.name,
      value: providerType?.id,
    })) as unknown as MultiSelectOptionType[];
  }, [providerProviderTypes]);

  const methods = useForm<AddProviderType>({
    resolver: yupResolver(providerSchema) as unknown as Resolver<AddProviderType>,
    defaultValues: {
      bannerUrl: provider?.bannerUrl,
      logoUrl: provider?.logoUrl,
      name: provider?.name,
      countryCode: provider?.countryCode,
      mobilePhoneNumber: provider?.mobilePhoneNumber ?? '',
      providerTypeIds: mappedProviderTypesIds,
      providerTypes: mappedProviderTypes,
      status: (provider?.status as ProviderStatusEnum) || ProviderStatusEnum.UNPUBLISHED,
      address: {
        line: address?.line,
        barangay: address?.barangay || '',
        country: address?.country || COUNTRY_CODES[0].name,
        coordinates: `${ensureDecimalPlaces(address?.latitude) || '0'}, ${
          ensureDecimalPlaces(address?.longitude) || '0'
        }`,
        municipalityCity: address?.municipalityCity,
        province: address?.province,
        region: address?.region?.id,
        googleMapsUrl: address?.googleMapsUrl,
      },
      representative: {
        id: rep?.id,
        email: rep?.email || '',
        firstName: rep?.firstName || '',
        lastName: rep?.lastName || '',
        middleName: rep?.middleName || '',
        countryCode: rep?.countryCode || PH_CODE.dial_code,
        phoneCountryCode: rep?.phoneCountryCode || PH_CODE.dial_code,
        mobileNumber: rep?.mobileNumber || '',
        phoneNumber: rep?.phoneNumber || '',
        title: rep?.title || '',
      },
      userProvider: {
        isEditing,
        id: !isAddProvider ? user?.id : undefined,
        confirmPassword: '',
        email: !isAddProvider ? user?.email : '',
        firstName: !isAddProvider ? user?.firstName : '',
        lastName: !isAddProvider ? user?.lastName : '',
        password: '',
      },
      isAutoApproveAppointmentEnabled: provider?.isAutoApproveAppointmentEnabled ?? true,
      isMphProvider: provider?.isMphProvider ?? false,
    },
  });

  useImperativeHandle(formRef, () => ({
    getFormValues: () => onSubmit(methods.getValues(), { shouldGetValuesOnly: true }),
  }));

  const handleSuccess = async (provider: Provider) => {
    toast({
      message: `Provider ${isEditing ? 'updated' : 'created'}`,
      type: 'success',
    });

    if (isEditing) {
      const { userProvider } = methods.getValues();
      const { confirmPassword, password } = userProvider;

      if (password && confirmPassword && userProvider.id === user?.id) {
        handleToggle();
      }

      trackEvent(EventName.UPDATE_PROVIDER_PROFILE);
    } else {
      trackEvent(EventName.ADD_NEW_PROVIDER);
    }

    onSave?.();
  };

  const { mutate: updateProvider, isLoading: isUpdating } = useUpdateProviderMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: createProvider, isLoading: isCreating } = useCreateProviderMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  function handleError(errorDetails: any) {
    if (Array.isArray(errorDetails)) {
      errorDetails.forEach((errorDetail) => {
        methods.setError(errorDetail.errorProperty, {
          type: 'exists',
          message: errorDetail.errorMessage,
        });
      });

      return;
    }

    toast({
      message: 'Something went wrong',
      type: 'error',
    });
  }

  const checkIfValidEmail = async (currentEmail: string, formEmail: string) => {
    if (formEmail === currentEmail) return true;

    const { isValid } = await validateUserProviderEmail(formEmail);
    return isValid;
  };

  const onSubmit = async (data: AddProviderType, options?: { shouldGetValuesOnly?: boolean }) => {
    const { shouldGetValuesOnly = false } = options || {};

    const isValidEmail = await checkIfValidEmail(user?.email, data.userProvider.email);
    const { otherBarangay, otherMunicipalityCity, coordinates, ...rest } = data.address;

    const isMunicipalityCityOthers = data.address.municipalityCity === OTHERS_LABEL;
    const isBarangayOthers = data.address.barangay === OTHERS_LABEL;

    const municipalityCity = isMunicipalityCityOthers
      ? otherMunicipalityCity
      : data.address.municipalityCity;
    const barangay = isBarangayOthers ? otherBarangay : data.address.barangay;

    const modifiedAddressData = { ...rest, municipalityCity, barangay };

    if (!isValidEmail) {
      methods.setError(ProviderFormEnum.USER_PROVIDER_EMAIL, {
        type: 'exists',
        message: 'Email already exists',
      });

      return;
    }

    const { isEditing: _isEditing, ...userProviderData } = data.userProvider;

    const selectedProviderTypesIds: number[] = (data?.providerTypes || []).map((providerType) =>
      Number(providerType.value),
    );

    const providerData = {
      address: modifiedAddressData as unknown as Partial<Address>,
      bannerUrl: data.bannerUrl,
      logoUrl: data.logoUrl,
      countryCode: data.countryCode,
      mobilePhoneNumber: data.mobilePhoneNumber,
      name: data.name,
      representatives: [data.representative],
      userProviders: [{ ...userProviderData, userType: UserType.SUPER_ADMIN }],
      providerTypeIds: selectedProviderTypesIds,
      isAutoApproveAppointmentEnabled: data.isAutoApproveAppointmentEnabled ?? true,
      isMphProvider: data.isMphProvider ?? false,
    };

    if (shouldGetValuesOnly) {
      return {
        ...providerData,
        userProviders: [],
      };
    }

    if (isEditing && provider?.id) {
      updateProvider({
        id: provider?.id,
        updateProviderDto: {
          ...providerData,
          status: data.status,
        } as UpdateProviderDto,
      });
    } else {
      createProvider(providerData as CreateProviderDto);
    }
  };

  const lastUpdatedDate = formatToHumanReadable(
    provider?.updatedAt || provider?.createdAt || new Date(),
  );

  const handleCancelSave = () => {
    router.push(Routes.ROOT);
  };

  const handleRedirectToLogin = () => {
    handleToggle();
    onLogout && onLogout();
  };

  const renderIsMphProviderCheckbox = !isCurrentUserIsMphProvider && !currentUserProvider && (
    <Checkbox
      className="cursor-pointer"
      label="Is MPH Provider"
      {...methods.register(ProviderFormEnum.IS_MPH_PROVIDER)}
    />
  );

  const { isSelfOnboarding } = new ProviderHelper(provider as Provider);

  const isLoading = isCreating || isUpdating;
  return (
    <FormProvider {...methods}>
      {isOpen && (
        <ActionModal
          onClickPrimaryButton={handleRedirectToLogin}
          title="Password Update Confirmation"
          primaryButtonText="Proceed to Login"
          variant={Variants.PRIMARY}
        >
          <p className="text-sm text-gray-500">
            Your password has been updated. To continue, please log in again using your new
            password.
          </p>
        </ActionModal>
      )}
      <form>
        <div className="flex flex-col gap-8">
          {isSelfOnboarding && (
            <div className="items-start justify-start rounded-md bg-orange-100 p-4">
              <div className="flex flex-row items-start gap-4">
                <ExclamationCircleIcon className="h-[30px] w-[30px] fill-orange-500" />
                <div className="pt-1">
                  <div className="mb-2 text-base font-semibold text-neutral-600">
                    Verify this Provider
                  </div>
                  <div className="whitespace-pre-line text-sm text-neutral-600">
                    Please review submitted documents and information of Provider below. After
                    verifying, you may either Publish, Ask to resubmit, or Decline this Provider.
                  </div>
                </div>
              </div>
            </div>
          )}
          <ProviderAutoApproveAppointment />
          {showStatusSection && <ProviderStatus isDisabled={isSelfOnboarding} />}
          {isSelfOnboarding && (
            <ProviderBusinessDocuments onboardingProgression={provider?.onboardingProgression} />
          )}
          <ProviderDetails provider={provider} />
          <ProviderAddressDetails />
          <ProviderContactDetails />
          {(isCurrentUserSuperAdmin || isAddProvider) && <ProviderUserDetails />}
          {SHOW_DIGITAL_IDS && (
            <ProviderDigitalId
              qrCodeUrl="https://api.qrserver.com/v1/create-qr-code/?size=185x185&ecc=L&qzone=1&data=http%3A%2F%2Fexample.com%2F"
              providerName={formatProviderDisplayNameWithBranch(provider as Provider)}
              providerType={
                provider?.providerTypes
                  ?.map((providerType) => providerType?.name)
                  .filter(Boolean)
                  .join(', ') || ''
              }
            />
          )}
          {SHOW_MPH_REFERRAL && renderIsMphProviderCheckbox}
        </div>
        <div className="flex-column mt-8 flex justify-center gap-2 md:justify-between">
          <div className="mb-[75px] text-xs text-neutral-500 md:mb-0">
            <span className="font-semibold">Last updated:</span> <span>{lastUpdatedDate}</span>
          </div>
          <div className="flex-column hidden gap-3 md:flex">
            <Button
              onClick={methods.handleSubmit((d) => onSubmit(d))}
              variant="primary"
              className="w-36"
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
          <div className="fixed bottom-0 z-[99] grid w-full grid-cols-2 gap-x-2 bg-white px-6 py-2 md:hidden">
            <Button
              onClick={handleCancelSave}
              variant="secondaryGray"
              className="col-span-1 w-full"
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={methods.handleSubmit((d) => onSubmit(d))}
              variant="primary"
              className="col-span-1 w-full"
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default ProviderProfile;
