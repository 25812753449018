import Head from 'next/head';

interface SEOHeadProps {
  description: string;
  title: string;
  viewportContent?: string;
  appName?: string;
}

function SEOHead({ description, title, viewportContent, appName }: SEOHeadProps) {
  const defaultContent =
    'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
  const content = viewportContent ? [defaultContent, viewportContent].join(', ') : defaultContent;

  const renderedTitle = appName ? `${title} | ${appName}` : title;

  return (
    <Head>
      <title>{renderedTitle}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content={content} />
    </Head>
  );
}

export default SEOHead;
