import { Fragment, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { clsxMerge } from '../utils';

export type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen?: (open: boolean) => void;
  shouldCloseOnEsc?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | string;
  className?: string;
  backdropClassName?: string;
  onClose?: () => void;
  type?: 'default' | 'rightDrawer' | 'leftDrawer';
};

export default function Modal(props: ModalProps) {
  const {
    children,
    open = false,
    setOpen,
    shouldCloseOnEsc = true,
    size = '2xl',
    className,
    backdropClassName = '',
    onClose,
    type = 'default',
  } = props;

  const modalRef = useRef<any>();

  const sizeMap = {
    xs: 'max-w-sm',
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
  };

  const typeMap = {
    default: 'justify-center',
    leftDrawer: 'justify-start !p-0',
    rightDrawer: 'justify-end !p-0',
  };

  function handleOnClose(open: boolean) {
    if (shouldCloseOnEsc) {
      setOpen && setOpen(open);
    }

    onClose?.();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        ref={modalRef}
        className="relative z-50"
        onClose={handleOnClose}
        initialFocus={modalRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsxMerge(
              'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity',
              backdropClassName,
            )}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto max-sm:bg-white">
          <div
            className={clsxMerge(
              'flex min-h-full items-end px-0 py-0 text-center max-sm:h-full sm:items-center sm:p-4',
              typeMap[type],
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsxMerge(
                  'relative transform px-4 pb-4 pt-5 text-left transition-all',
                  'shadow-xl rounded-lg bg-white',
                  'sm:my-8 sm:h-fit sm:p-6',
                  'max-sm:h-full max-sm:rounded-none',
                  sizeMap[size as keyof typeof sizeMap],
                  'w-full',
                  type !== 'default' && '!m-0 !h-screen rounded-none',
                  className,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
