import { useQuery } from '@tanstack/react-query';

import {
  getAllClientProfile,
  getClientProfileById,
  getClientProfileByUserId,
} from '../../api/clientProfileService';
import {
  GET_ALL_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_BY_ID,
  GET_CLIENT_PROFILE_BY_USER_ID,
} from '../../constants';

export const useGetAllClientProfileQuery = (params?: Record<string, string | number>) =>
  useQuery([GET_ALL_CLIENT_PROFILE, params], () => getAllClientProfile(params), {
    keepPreviousData: true,
    enabled: !params || !!params.userId,
  });

export const useGetClientProfileByIdQuery = (id: string) =>
  useQuery([GET_CLIENT_PROFILE_BY_ID, id], () => getClientProfileById(id), {
    enabled: !!id,
  });

export const useGetClientProfileByUserIdQuery = (userId: string) =>
  useQuery([GET_CLIENT_PROFILE_BY_USER_ID, userId], () => getClientProfileByUserId(userId), {
    enabled: !!userId,
  });
