import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';

import { useSetError } from './ErrorContext';
import { ErrorResponseType } from '../types/ErrorResponse.type';

type Props = {
  children: React.ReactNode;
};

export const QueryClientProvider = (props: Props) => {
  const setError = useSetError();

  function handleError(error: unknown) {
    setError(error as ErrorResponseType);
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        onError: handleError,
      },
      mutations: { onError: handleError },
    },
  });

  return <ReactQueryClientProvider client={queryClient}>{props.children}</ReactQueryClientProvider>;
};
