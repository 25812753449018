/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateUserDto, VerifyOtpNumberDto } from '@healthhub/api-lib';
import { useMutation, MutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  ACCESS_TOKEN,
  AuthAction,
  USER,
  USER_ABILITIES,
  setLocalStorageItem,
} from '@mwell-healthhub/commons';

import { loginWithPhoneNumber, registerWithPhoneNumber } from '../../services/authService';

export const useLoginWithPhoneNumber = (
  mutationOptions: MutationOptions<any, AxiosError<{ message: string }>, any>,
) => {
  return useMutation(loginWithPhoneNumber, mutationOptions);
};

export const useLoginVerifyOtpNumber = (
  mutationOptions: MutationOptions<any, AxiosError<{ message: string }>, any>,
) => {
  return useMutation(async (params: VerifyOtpNumberDto) => {
    const response = await fetch('/api/otp-verification', {
      body: JSON.stringify({
        ...params,
        action: AuthAction.LOGIN,
      }),
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Something went wrong');
    }

    const responseData = await response.json();

    setLocalStorageItem(ACCESS_TOKEN, responseData.token);
    setLocalStorageItem(USER, responseData.user);
    setLocalStorageItem(USER_ABILITIES, []);

    return responseData;
  }, mutationOptions);
};

export const useRegisterWithPhoneNumber = (
  mutationOptions: MutationOptions<any, AxiosError<{ message: string }>, any>,
) => {
  return useMutation(registerWithPhoneNumber, mutationOptions);
};

export const useRegisterVerifyOtpNumber = (
  mutationOptions: MutationOptions<any, AxiosError<{ message: string }>, any>,
) => {
  return useMutation(async (params: VerifyOtpNumberDto) => {
    const response = await fetch('/api/otp-verification', {
      body: JSON.stringify({
        ...params,
        action: AuthAction.REGISTER,
      }),
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Something went wrong');
    }

    const responseData = await response.json();

    return responseData;
  }, mutationOptions);
};

export const useRegisterSaveUserDetails = (
  mutationOptions: MutationOptions<any, AxiosError<{ message: string }>, any>,
) => {
  return useMutation(
    async (params: CreateUserDto & { mWellRefreshToken: string; mWellAccessToken: string }) => {
      const response = await fetch('/api/save-user-details', {
        body: JSON.stringify(params),
        method: 'POST',
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw responseData;
      }

      setLocalStorageItem(ACCESS_TOKEN, responseData.token);
      setLocalStorageItem(USER, responseData.user);
      setLocalStorageItem(USER_ABILITIES, []);

      return responseData;
    },
    mutationOptions,
  );
};
