import { ProviderType } from '@mwell-healthhub/commons';

import { Routes } from './routes';

export const quickLinks = [
  { text: 'Medical', url: `${Routes.PROVIDERS}?type=${ProviderType.MEDICAL}` },
  { text: 'Diagnostic', url: `${Routes.PROVIDERS}?type=${ProviderType.DIAGNOSTIC}` },
  { text: 'Dental', url: `${Routes.PROVIDERS}?type=${ProviderType.DENTAL}` },
  { text: 'Eye', url: `${Routes.PROVIDERS}?type=${ProviderType.EYE}` },
  { text: 'Skin', url: `${Routes.PROVIDERS}?type=${ProviderType.SKIN}` },
  { text: 'Maternity', url: `${Routes.PROVIDERS}?type=${ProviderType.MATERNITY}` },
  { text: 'Therapy', url: `${Routes.PROVIDERS}?type=${ProviderType.THERAPY}` },
  { text: 'Wellness Spa', url: `${Routes.PROVIDERS}?type=${ProviderType.WELLNESS_SPA}` },
  { text: 'Hair', url: `${Routes.PROVIDERS}?type=${ProviderType.HAIR}` },
];

export const supportLinks = [
  // { text: 'FAQs', url: '/faqs' },
  // { text: 'Contact Us', url: '/contact-us' },
  { text: 'Be a Partner Provider', url: Routes.PARTNER },
  { text: 'Privacy Policy', url: Routes.PRIVACY_POLICY },
  { text: 'Terms and Conditions', url: Routes.TERMS_AND_CONDITIONS },
];
