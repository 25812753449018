import { FilesApi } from '@healthhub/api-lib';
import { AxiosError } from 'axios';

import HealthHubApiClient from './api-client';
import { UserFileType } from '../enums';
import { CustomError } from '../errors';

export const fileApi = HealthHubApiClient.use(FilesApi);

export type UploadUserFile = {
  file: File;
  name?: string;
  type?: UserFileType;
};

export async function uploadMedicalRecordFile(createMedicalRecordFileDto: {
  file: File;
  appointmentId: string;
  name: string;
}) {
  try {
    const { file, name, appointmentId } = createMedicalRecordFileDto;

    const formData = new FormData();
    formData.append('file', file);

    const { data } = await fileApi.filesControllerUploadMedicalRecordFile(appointmentId, name, {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw new CustomError((response?.data as any)?.message || '');
  }
}

export async function getMedicalRecordFilesByAppointmentId(appointmentId: string) {
  try {
    const { data } =
      await fileApi.filesControllerFindAllMedicalRecordsByAppointmentId(appointmentId);

    return data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function removeMedicalRecordFileById(id: string) {
  try {
    const { data } = await fileApi.filesControllerRemoveMedicalRecordFileById(id);

    return data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function getMedicalRecordFilesOfUser() {
  try {
    const { data } = await fileApi.filesControllerFindAllMedicalRecordOfUser();

    return data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
}

export async function uploadOnboardingDocument(file: File) {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await fileApi.filesControllerUploadOnboardingDocumentFile({
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw new CustomError((response?.data as any)?.message || '');
  }
}
