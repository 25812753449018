import { CreateDiscountDto, DiscountApi, UpdateDiscountDto } from '@healthhub/api-lib';
import get from 'lodash.get';

import HealthHubApiClient from './api-client';
import { Pagination } from '../types';
import { DiscountFilter, IDiscount } from '../types/discount.type';

export const discountApi = HealthHubApiClient.use(DiscountApi);

export async function fetchDiscounts(
  discountFilter: DiscountFilter,
): Promise<Pagination<IDiscount>> {
  try {
    const { query, startsAt, endsAt, status, platform, limit, page, sortBy, includeProvider } =
      discountFilter;
    const { data } = await discountApi.discountControllerList(
      query,
      undefined,
      status,
      undefined,
      undefined,
      undefined,
      undefined,
      platform,
      startsAt,
      endsAt,
      sortBy,
      undefined,
      undefined,
      undefined,
      Boolean(includeProvider),
      limit,
      page,
    );

    return data as Pagination<IDiscount>;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to fetch discounts');
  }
}

export async function createDiscount(dto: CreateDiscountDto): Promise<IDiscount> {
  try {
    const { data } = await discountApi.discountControllerCreateDiscount(dto);

    return data as IDiscount;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to create discount');
  }
}

export async function updateDiscount(dto: UpdateDiscountDto): Promise<IDiscount> {
  try {
    const { data } = await discountApi.discountControllerUpdateDiscount(dto.id.toString(), dto);

    return data as IDiscount;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to create discount');
  }
}

export async function publishDiscount(id: string): Promise<IDiscount> {
  try {
    const { data } = await discountApi.discountControllerPublishDiscount(id);

    return data as IDiscount;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to publish discount');
  }
}

export async function unpublishDiscount(id: string): Promise<IDiscount> {
  try {
    const { data } = await discountApi.discountControllerUnpublishDiscount(id);

    return data as IDiscount;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to unpublish discount');
  }
}

export async function getDiscountById(id: string): Promise<IDiscount> {
  try {
    const { data } = await discountApi.discountControllerGetDiscountById(id);

    return data as IDiscount;
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to create discount');
  }
}
