import { useState } from 'react';

import { Appointment, ProviderService } from '@healthhub/api-lib';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';

import { CALENDAR_DATE_FORMAT } from '../../constants';
import { FullSlot, Option } from '../../types';
import { getTodayUTCPhilippineTime } from '../../utils';
import Button from '../Button';
import Calendar from '../Calendar';
import Modal from '../Modal';
import TextArea from '../TextArea';
import TimeBlocks from '../TimeBlocks';

type Props = {
  booking: Appointment;
  isLoading: boolean;
  onCancelClick: () => void;
  onProceedClick: (date: Date, time: string, reason: string, scheduleId?: number) => void;
  providerId: number;
  providerServices: ProviderService[];
};

enum FormEnum {
  DATE = 'date',
  TIME = 'time',
}

const today = getTodayUTCPhilippineTime();

const RescheduleModal = (props: Props) => {
  const { booking, isLoading, providerId, providerServices, onCancelClick, onProceedClick } = props;

  const [selectedScheduleId, setSelectedScheduleId] = useState<number | undefined>();
  const { control, watch, handleSubmit, setValue } = useForm<{
    date: string;
    time: Option;
    reason: string;
  }>({
    defaultValues: {
      date: booking.dateTime,
    },
  });
  const currentSchedule = watch(FormEnum.DATE);
  const selectedDate = new Date(currentSchedule ?? new Date(format(today, CALENDAR_DATE_FORMAT)));

  const handleSave = handleSubmit(async (data) => {
    onProceedClick(new Date(data.date), data.time.label, data.reason, selectedScheduleId);
  });

  const handleSelectDate = (selectedDate: Date, scheduleId?: number) => {
    setValue(FormEnum.DATE, selectedDate.toString());
    setSelectedScheduleId(scheduleId);
  };

  const handleSelectTimeSlot = (timeSlot: FullSlot) => {
    setValue(FormEnum.TIME, {
      label: timeSlot.startTime,
      value: timeSlot.id,
    });
  };

  return (
    <Modal size="3xl" open={true} setOpen={() => null}>
      <div className="flex flex-col items-start gap-4">
        <div className="flex h-10 min-w-[40px] flex-col items-center justify-center rounded-full bg-secondary-300">
          <ExclamationTriangleIcon className="w-6 text-primary-500" />
        </div>
        <form onSubmit={handleSave}>
          <div className="flex flex-col gap-5">
            <div>
              <div className="pb-3 text-lg">
                You are about to <span className="font-bold">Reschedule</span> this appointment
              </div>
              <div className="pb-3 text-sm text-gray-500">
                Are you sure you want to reschedule this appointment? If so, let your client know
                why you are rescheduling and update the schedule.
              </div>
            </div>
            <div>
              <Controller
                control={control}
                name="reason"
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextArea
                    labelShown
                    className="font-normal"
                    showRequiredOnLabel
                    label="Reason for reschedule"
                    value={value}
                    errorMessage={error ? 'This field is required.' : ''}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-start">
              <div className="min-w-[280px] border-r border-gray-200 pr-4 md:min-w-[300px]">
                <p className="text-base">Select Date</p>
                <Calendar
                  selectedDate={selectedDate}
                  selectedDateString={selectedDate.toString()}
                  onSelectDate={handleSelectDate}
                  providerId={providerId}
                  providerServices={providerServices}
                />
              </div>
              <div className="min-w-[280px] pl-4 md:min-w-[300px]">
                <p className="text-base">Select Time</p>
                <TimeBlocks
                  shouldShowNumRemainingSlots
                  providerId={providerId}
                  providerServices={providerServices}
                  selectedDate={selectedDate}
                  onSelectDate={handleSelectDate}
                  onSelectTimeSlot={handleSelectTimeSlot}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end pt-6">
            <div className="flex gap-x-3">
              <Button
                className="w-[100px]"
                variant="outlined"
                type="button"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                className="w-[100px]"
                type="submit"
                variant="primary"
              >
                Proceed
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default RescheduleModal;
