import {
  Appointment,
  GetOCRDto,
  ResponseProviderShareLinkDto,
  UpdateUserDto,
  UploadDisplayPhotoDto,
  User,
  UsersApi,
} from '@healthhub/api-lib';
import axios from 'axios';
import Cookies from 'js-cookie';

import HealthHubApiClient, { API_BASE_URL } from './api-client';
import { ACCESS_TOKEN, LocalStorageKey } from '../constants';
import { AppointmentPlatformEnum } from '../enums';
import { Pagination } from '../types';
import { removeLocalStorageItem } from '../utils/localStorage';

export type GetUsersDto = {
  name?: string;
};

export type GetUserAppointmentsDto = {
  status?: string;
  sortBy?: string;
  limit: number;
  platform?: AppointmentPlatformEnum;
};

const userApi = HealthHubApiClient.use(UsersApi);

export async function logoutUser() {
  try {
    await userApi.userControllerLogout();

    // Do not await
    fetch('/api/logout');

    Cookies.remove(ACCESS_TOKEN);

    Object.values(LocalStorageKey).forEach(removeLocalStorageItem);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getCurrentUser(requestInit: RequestInit = {}): Promise<User | null> {
  const response = await fetch(new URL('/api/v1/users/me', API_BASE_URL).toString(), {
    ...requestInit,
    credentials: 'include',
  });

  if (response.ok) {
    return response.json();
  }

  return null;
}

export async function syncCurrentUserWithMWellData() {
  try {
    return userApi.userControllerSyncCurrentUserWithMWellData();
  } catch (error) {
    // Fail silently
    console.error(error);
  }
}

export async function fetchCurrentUserAppointments(
  params: GetUserAppointmentsDto,
  requestInit: RequestInit = {},
): Promise<Pagination<Appointment>> {
  const response = await fetch(
    new URL(
      `/api/v1/users/me/appointments?status=${params.status}&sortBy=${params.sortBy}&limit=${
        params.limit
      }${params.platform ? '&platform=' + params.platform : ''}`,
      API_BASE_URL,
    ).toString(),
    {
      ...requestInit,
      credentials: 'include',
    },
  );

  return response.json();
}

export async function fetchCurrentUserNextAppointment(
  requestInit: RequestInit = {},
): Promise<Appointment> {
  const response = await fetch(
    new URL(
      '/api/v1/users/me/appointments?status=upcoming&limit=1&sortBy=dateTime:ASC&isNextAppointmentOnly=1',
      API_BASE_URL,
    ).toString(),
    {
      ...requestInit,
      credentials: 'include',
    },
  );

  const result = await response.json();

  return result.items?.[0];
}

export async function updateCurrentUser(params: UpdateUserDto) {
  const { data } = await userApi.userControllerUpdateCurrentUser(params);

  return data;
}

export async function updateUserDisplayPhoto(
  params: UploadDisplayPhotoDto,
): Promise<{ displayPhotoUrl: string }> {
  const { data } = await userApi.userControllerUploadDisplayPhoto(params);

  return data as unknown as { displayPhotoUrl: string };
}

export async function getProviderShareLinks(id: string): Promise<ResponseProviderShareLinkDto> {
  try {
    const { data } = await userApi.userControllerGetShareLinks(id);

    return data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data.message[0]?.errorMessage ?? error.response?.data.message,
      );
    }
    throw error;
  }
}

export async function getOCR(params: GetOCRDto) {
  try {
    const response = await userApi.userControllerGetOCR(params);

    const { data } = response;

    if (!data) {
      throw new Error('Invalid response');
    }

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}
