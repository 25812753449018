import { CreateProductDto, UpdateProductDto, UpdateProductsDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createProduct, deleteProduct, updateProduct, updateProducts } from '../../api';
import { EshopProduct } from '../../types/eshop';

export const useCreateProductMutation = (
  mutationOptions?: MutationOptions<
    EshopProduct,
    AxiosError<{ message: string }>,
    CreateProductDto
  >,
) => useMutation(createProduct, mutationOptions);

export const useUpdateProductMutation = (
  mutationOptions?: MutationOptions<
    EshopProduct,
    AxiosError<{ message: string }>,
    { id: string; updateCategoryDto: UpdateProductDto }
  >,
) => {
  return useMutation(
    (params) => updateProduct({ id: params.id, updateProductDto: params.updateCategoryDto }),
    mutationOptions,
  );
};

export const useUpdateProductsMutation = (
  mutationOptions?: MutationOptions<
    EshopProduct[],
    AxiosError<{ message: string }>,
    UpdateProductsDto
  >,
) => {
  return useMutation((updateProductsDto) => updateProducts(updateProductsDto), mutationOptions);
};

export const useDeleteProductMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, string>,
) => {
  return useMutation((id: string) => deleteProduct(id), mutationOptions);
};
