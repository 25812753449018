import { AppointmentService, UpdateMassAppointmentServiceDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { massUpdateAppointmentServices } from '../../api/appointmentServicesService';

export const useMassUpdateAppointmentServices = (
  mutationOptions?: MutationOptions<
    AppointmentService[],
    AxiosError<{ message: string }>,
    UpdateMassAppointmentServiceDto
  >,
) => useMutation(massUpdateAppointmentServices, mutationOptions);
