import React from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

type Props = {
  isHidden: boolean;
  onClick: () => void;
  showLabel?: boolean;
};

function TableDeleteButton(props: Readonly<Props>) {
  const { isHidden, onClick, showLabel = true } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onClick();
  };

  return (
    <button
      type="button"
      className="relative inline-flex items-center text-sm font-medium text-primary-500 focus:z-10"
      onClick={handleClick}
    >
      {isHidden ? (
        <>
          <EyeSlashIcon height={20} width={20} className="mr-2" />
          {showLabel && 'Unhide'}
        </>
      ) : (
        <>
          <EyeIcon height={20} width={20} className="mr-2" />
          {showLabel && 'Hide'}
        </>
      )}
    </button>
  );
}

export default TableDeleteButton;
