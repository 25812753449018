import { ChangeEvent, forwardRef, HTMLProps, Ref, useId } from 'react';

import useCurrentOS from '../../hooks/useCurrentOS';
import { clsxMerge } from '../../utils';

export interface TextInputPropsDefault extends HTMLProps<HTMLInputElement> {
  additionalLabel?: JSX.Element | string;
  additionalLabelClassName?: string;
  containerClassName?: string;
  defaultText?: string;
  errorMessage?: string;
  label?: any;
  labelElement?: JSX.Element;
  labelFontWeight?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  labelShown?: boolean;
  labelSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  showRequiredOnLabel?: boolean;
  showOptionalOnLabel?: boolean;
  textInputClassName?: string;
  labelClassName?: HTMLProps<HTMLElement>['className'];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: any) => void;
}

export type TextInputProps = TextInputPropsDefault &
  (
    | {
        showTextOnly: true;
        text?: string | number;
      }
    | {
        showTextOnly?: false;
        text?: undefined;
      }
  );

function TextInput(props: TextInputProps, forwardedRef?: Ref<HTMLInputElement>) {
  const os = useCurrentOS();
  const {
    label,
    labelElement,
    labelShown = false,
    showTextOnly = false,
    text,
    labelSize = 'sm',
    labelFontWeight = 'sm',
    additionalLabel,
    additionalLabelClassName,
    className = '',
    containerClassName = '',
    title,
    disabled,
    type = 'text',
    pattern,
    inputMode,
    id: idProp,
    errorMessage,
    defaultText,
    showRequiredOnLabel = false,
    showOptionalOnLabel = false,
    textInputClassName,
    labelClassName,
    ...etcProps
  } = props;
  const defaultId = useId();
  const id = idProp ?? defaultId;
  const fontWeights = {
    xs: 'font-light',
    sm: 'font-normal',
    md: 'font-medium',
    lg: 'font-semibold',
    xl: 'font-bold',
  };

  const labelTextSize = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  return (
    <div className={containerClassName}>
      <label
        htmlFor={id}
        className={clsxMerge(
          'block text-base !leading-6 text-neutral-600',
          { 'sr-only': !labelShown, 'mb-2': additionalLabel },
          labelTextSize[labelSize],
          fontWeights[labelFontWeight],
          labelClassName,
        )}
      >
        {label || labelElement}
        {showRequiredOnLabel && <span className="text-red-600"> *</span>}
        {showOptionalOnLabel && <span className="text-sm text-neutral-400"> (Optional)</span>}
      </label>
      {additionalLabel && (
        <label className={clsxMerge('text-xs text-neutral-500', additionalLabelClassName)}>
          {additionalLabel}
        </label>
      )}
      <div className={clsxMerge(errorMessage ? className : '', errorMessage && 'px-0 py-0')}>
        <input
          ref={forwardedRef}
          id={id}
          type={type}
          pattern={pattern}
          inputMode={inputMode}
          title={title ?? label}
          disabled={disabled}
          defaultValue={defaultText ?? ''}
          className={clsxMerge(
            'block h-10 w-full rounded-md border-none bg-neutral-50 px-3 py-2 text-sm text-neutral-600 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-inset focus:ring-primary-500 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400 sm:leading-6 md:text-sm',
            errorMessage ? '' : className,
            {
              'ring-red-600 focus:ring-red-600': errorMessage,
              hidden: showTextOnly,
              '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none':
                type === 'number',
            },
            textInputClassName,
          )}
          onKeyDown={(event) => {
            const validKeys = ['Backspace', 'Tab', 'Enter', 'Delete'];
            if (pattern) {
              const cleanedPattern = pattern.replace(/{\d+}/g, '');
              const patternRegex = new RegExp(cleanedPattern);
              const isMacOS = os === 'macOS';
              const isUndoEvent =
                (isMacOS ? event.metaKey : event.ctrlKey) && event.key.toLowerCase() === 'z';
              const isCutEvent =
                (isMacOS ? event.metaKey : event.ctrlKey) && event.key.toLowerCase() === 'x';
              const isCopyEvent =
                (isMacOS ? event.metaKey : event.ctrlKey) && event.key.toLowerCase() === 'c';
              const isPasteEvent =
                (isMacOS ? event.metaKey : event.ctrlKey) && event.key.toLowerCase() === 'v';
              const isSelectAllEvent =
                (isMacOS ? event.metaKey : event.ctrlKey) && event.key.toLowerCase() === 'a';

              if (
                !patternRegex.test(event.key) &&
                !validKeys.includes(event.key) &&
                !isUndoEvent &&
                !isCutEvent &&
                !isCopyEvent &&
                !isPasteEvent &&
                !isSelectAllEvent
              ) {
                event.preventDefault();
              }
            }
          }}
          {...etcProps}
        />
        {showTextOnly && (
          <div
            className={clsxMerge(
              'block h-10 w-full rounded-md py-2 sm:text-sm sm:leading-6',
              errorMessage ? '' : className,
            )}
          >
            {text}
          </div>
        )}
        {errorMessage && <div className="mt-1 text-sm text-red-600">{errorMessage}</div>}
      </div>
    </div>
  );
}

export default forwardRef(TextInput);
