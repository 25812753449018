const SUBSCRIPTION_KEY =
  process.env.AZURE_MAPS_SUBSCRIPTION_KEY ?? process.env.NEXT_PUBLIC_AZURE_MAPS_SUBSCRIPTION_KEY;
const BASE_URL = 'https://atlas.microsoft.com/search';
const API_VERSION = '1.0';

export async function searchLocation(query: string, country: string) {
  const apiUrl = `${BASE_URL}/poi/json?api-version=${API_VERSION}&query=${query}&subscription-key=${SUBSCRIPTION_KEY}&countrySet=${country}`;
  const result = await fetch(apiUrl);

  return result.json();
}

export async function getAddressFromLatLng(lat: number, lng: number) {
  const apiUrl = `${BASE_URL}/address/reverse/json?api-version=${API_VERSION}&query=${lat},${lng}&subscription-key=${SUBSCRIPTION_KEY}`;
  const result = await fetch(apiUrl);

  return result.json();
}
