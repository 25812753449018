import { Provider, UserProvider } from '@healthhub/api-lib';

import { BRAND_LEVEL_USERS } from '../constants';
import { ReferralsTabEnum } from '../enums';
import { ProviderUserType } from '../types';

type CheckIfIncomingReferral = {
  fromTab?: string;
  referredProviderId: number;
  selectedBranch?: Provider | null;
  userProvider?: UserProvider;
};

type CheckIfReferrer = {
  ownerProviderId: number;
  selectedBranch?: Provider | null;
  userProvider?: UserProvider;
};

export function checkIfIncomingReferral(params: CheckIfIncomingReferral) {
  const { fromTab, selectedBranch, referredProviderId, userProvider } = params;
  const isFromIncomingReferralTab = !fromTab || fromTab === ReferralsTabEnum.INCOMING;

  if (isFromIncomingReferralTab) {
    const userType = (userProvider?.userType ?? '') as ProviderUserType;

    if (BRAND_LEVEL_USERS.includes(userType)) {
      return true;
    }

    return Boolean(selectedBranch && selectedBranch.id === referredProviderId);
  }

  return false;
}

export function checkIfReferrer(params: CheckIfReferrer) {
  const { selectedBranch, ownerProviderId, userProvider } = params;

  const userType = (userProvider?.userType ?? '') as ProviderUserType;

  if (BRAND_LEVEL_USERS.includes(userType)) {
    return true;
  }

  return Boolean(selectedBranch && selectedBranch.id === ownerProviderId);
}
