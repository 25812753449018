import { TransactionStatistics } from '../../types';
import { formatToHumanReadable, formatToPeso } from '../../utils';

type Props = {
  statistics?: TransactionStatistics;
  dateFrom?: string;
  dateTo?: string;
};

const TransactionStatistics = (props: Props) => {
  const { dateFrom = new Date().toISOString(), dateTo, statistics } = props;
  const {
    completedTransactionCount = 0,
    grossTransactionRevenue = 0,
    netTransactionRevenue = 0,
    totalTransactionCount = 0,
  } = statistics ?? {};
  const dateToDisplay = dateTo ? `to ${formatToHumanReadable(new Date(dateTo))}` : '';
  const dateRangeDisplay = `${formatToHumanReadable(new Date(dateFrom))} ${dateToDisplay}`;

  return (
    <div className="overflow-hidden rounded-lg rounded-t-lg border border-chineseWhite">
      <div className="border-b border-chineseWhite px-5 py-4 text-xs font-medium uppercase">
        <span className="text-neutral-500">Transaction Summary: </span>
        <span className="text-primary-500">As of {dateRangeDisplay}</span>
      </div>
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col items-baseline justify-between gap-y-2 bg-white px-5 py-4">
          <div className="flex">
            <dt className="leading-1 flex flex-1 text-xs font-medium text-gray-500">
              Total No. of Orders
            </dt>
          </div>
          <dd className="flex w-full flex-1 flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
            {totalTransactionCount}
          </dd>
        </div>
        <div className="flex flex-col items-baseline justify-between gap-y-2 bg-white px-5 py-4 sm:rounded-tr-lg md:rounded-none">
          <div className="flex">
            <dt className="leading-1 flex-1 text-xs font-medium text-gray-500">Gross Revenue</dt>
          </div>
          <dd className="flex w-full flex-1  flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
            {formatToPeso(grossTransactionRevenue)}
          </dd>
        </div>
        <div className="flex flex-col items-baseline justify-between gap-y-2 bg-white px-5 py-4 sm:rounded-bl-lg md:rounded-none">
          <div className="flex">
            <dt className="leading-1 flex-1 text-xs font-medium text-gray-500">Completed Orders</dt>
          </div>
          <dd className="flex w-full  flex-1 flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
            {completedTransactionCount}
          </dd>
        </div>
        <div className="flex flex-col items-baseline justify-between gap-y-2 rounded-b-lg bg-white px-5 py-4 sm:rounded-bl-none sm:rounded-br-lg md:rounded-br-lg lg:last:rounded-br-lg">
          <div className="flex">
            <dt className="leading-1 flex-1 text-xs font-medium text-gray-500">MWELL Revenue</dt>
          </div>
          <dd className="flex w-full flex-1 flex-col justify-end text-2xl font-medium leading-10 tracking-tight text-gray-900">
            {formatToPeso(netTransactionRevenue)}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default TransactionStatistics;
