import StatusFormFields from '../../ProviderFormFields/StatusFormFields';

type Props = {
  isDisabled?: boolean;
};

function ProviderStatus(props: Props) {
  const { isDisabled } = props;

  return (
    <div className="rounded-md border border-neutral-300 bg-white px-6 py-4 md:p-8">
      <h2 className="mb-4 text-lg font-semibold">Provider Status</h2>
      <StatusFormFields isDisabled={isDisabled} />
    </div>
  );
}

export default ProviderStatus;
