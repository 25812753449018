import { ProviderOnboardingProgression } from '@healthhub/api-lib';
import { PaperClipIcon } from '@heroicons/react/20/solid';

import { formatToHumanReadable } from '../../../utils';
import Button from '../../Button';

type Props = {
  onboardingProgression?: ProviderOnboardingProgression;
};

function ProviderBusinessDocuments({ onboardingProgression }: Props) {
  const {
    dtiCertificateFile,
    birCertificateFile,
    mayorsPermitFile,
    dateSubmittedDocuments,
    dateResubmittedDocuments,
  } = onboardingProgression || {};

  return (
    <div className="rounded-md border border-neutral-300 bg-white px-6 py-4 md:p-8">
      <h2 className="mb-6 text-lg font-semibold">Business Documents</h2>
      <div className="mb-4 divide-y divide-neutral-300 rounded-md border border-neutral-300">
        <div className="flex items-center gap-x-3 px-5 py-3.5">
          <PaperClipIcon width={20} height={20} className="text-neutral-400" />
          <span className="text-sm text-neutral-600">{dtiCertificateFile?.name}</span>
          <Button
            as="a"
            variant="plainNoOutline"
            className="ml-auto h-auto p-0"
            label="View File"
            href={dtiCertificateFile?.url}
            target="_blank"
          >
            View File
          </Button>
        </div>
        <div className="flex items-center gap-x-3 px-5 py-3.5">
          <PaperClipIcon width={20} height={20} className="text-neutral-400" />
          <span className="text-sm text-neutral-600">{birCertificateFile?.name}</span>
          <Button
            as="a"
            variant="plainNoOutline"
            className="ml-auto h-auto p-0"
            label="View File"
            href={birCertificateFile?.url}
            target="_blank"
          >
            View File
          </Button>
        </div>
        {mayorsPermitFile?.url && (
          <div className="flex items-center gap-x-3 px-5 py-3.5">
            <PaperClipIcon width={20} height={20} className="text-neutral-400" />
            <span className="text-sm text-neutral-600">{mayorsPermitFile?.name}</span>
            <Button
              as="a"
              variant="plainNoOutline"
              className="ml-auto h-auto p-0"
              label="View File"
              href={mayorsPermitFile?.url}
              target="_blank"
            >
              View File
            </Button>
          </div>
        )}
      </div>
      {(dateSubmittedDocuments || dateSubmittedDocuments) && (
        <span className="text-sm font-normal text-neutral-600">
          Last updated: {formatToHumanReadable(dateResubmittedDocuments || dateSubmittedDocuments)}.
        </span>
      )}
    </div>
  );
}

export default ProviderBusinessDocuments;
