import {
  CreateProviderServiceDto,
  DeleteProviderServiceDto,
  ProviderService,
} from '@healthhub/api-lib';
import {
  UpdateProviderServiceParams,
  createProviderService,
  deleteProviderService,
  updateProviderService,
  updateHiddenProviderService,
  DeleteProviderServiceParams,
  updateBranchServiceAvailability,
  UpdateBranchAvailabilityParams,
} from '@mwell-healthhub/commons';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useCreateProviderService = (
  mutationOptions?: MutationOptions<
    ProviderService,
    AxiosError<{ message: string }>,
    CreateProviderServiceDto
  >,
) => useMutation(createProviderService, mutationOptions);

export const useUpdateProviderService = (
  mutationOptions?: MutationOptions<
    ProviderService,
    AxiosError<{ message: string }>,
    UpdateProviderServiceParams
  >,
) => useMutation(updateProviderService, mutationOptions);

export const useUpdateHiddenProviderService = (
  mutationOptions?: MutationOptions<ProviderService, AxiosError<{ message: string }>, number>,
) => useMutation(updateHiddenProviderService, mutationOptions);

export const useDeleteProviderService = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    DeleteProviderServiceParams
  >,
) => useMutation(deleteProviderService, mutationOptions);

export const useUpdateBranchServiceAvailability = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    { id: string; dto: UpdateBranchAvailabilityParams }
  >,
) => {
  return useMutation(
    (params) => updateBranchServiceAvailability(+params.id, params.dto),
    mutationOptions,
  );
};
