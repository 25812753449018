import { Fragment, useMemo, useState } from 'react';

import { ProviderService, UserOperation, UserProvider } from '@healthhub/api-lib';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash.debounce';

import ProviderServiceAvailabilityLabel from './ProviderServiceAvailabilityLabel';
import ProviderServicesTableMobile from './ProviderServicesTableMobile';
import {
  ActionModal,
  GET_ALL_PROVIDER_SERVICE_BY_USER_ID,
  Price,
  getServiceStringedPrice,
  Table,
  TableEditButton,
  useUpdateHiddenProviderService,
  Variants,
  TableHideButton,
  SearchInput,
  useQueryParamState,
  toast,
} from '..';
import { TableColumn } from '../components/Table';
import { trackEvent, EventName, getProviderServiceAnalyticsProps } from '../utils/analytics';

type Props = {
  onEditService?: (s: ProviderService) => void;
  onSuccess?: () => void;
  onSearch?: (query: string) => void;
  refetchServices?: () => void;
  providerId?: string;
  canEdit?: boolean;
  canHide?: boolean;
  currentUser?: UserProvider | UserOperation;
  isLoading?: boolean;
  services: ProviderService[] | undefined;
};

function ProviderServicesTable(props: Readonly<Props>) {
  const {
    providerId,
    services = [],
    isLoading,
    currentUser,
    onEditService,
    onSearch,
    canEdit,
    canHide,
    refetchServices,
  } = props;
  const [search, setSearch] = useQueryParamState('search');
  const queryClient = useQueryClient();

  const [selectedService, setSelectedService] = useState<ProviderService>();
  const [isConfirmActionModalOpen, setIsConfirmHideModalOpen] = useState<boolean>(false);

  const { mutate: hideProviderService, isLoading: isHiding } = useUpdateHiddenProviderService({
    onSuccess: () => {
      setSelectedService(undefined);

      const eventName = selectedService?.hidden
        ? EventName.CLICK_UNHIDE_SERVICE
        : EventName.CLICK_HIDE_SERVICE;

      trackEvent(eventName, getProviderServiceAnalyticsProps(selectedService));

      toast({
        type: 'success',
        message: 'Service updated.',
      });

      // Explicitly refetch
      if (refetchServices) {
        refetchServices();
      }

      queryClient.invalidateQueries([GET_ALL_PROVIDER_SERVICE_BY_USER_ID, providerId]);

      handleToggleActionServiceModal();
    },
  });

  const columns: TableColumn<ProviderService>[] = useMemo(
    () => [
      {
        header: 'Service Name',
        key: 'label',
        render: (data) => (
          <span onClick={handleActionEdit(data)} className="cursor-pointer font-semibold">
            {data?.label}
          </span>
        ),
      },
      {
        header: 'Description',
        key: 'description',
        className: 'min-w-[200px]',
        render: (data) => (
          <div className="line-clamp-3 whitespace-pre-wrap">
            {data?.description || data?.service?.description}
          </div>
        ),
      },
      { header: 'Category', key: 'serviceCategory.name' },
      {
        header: 'Availability',
        key: 'availability',
        render: (data) => <ProviderServiceAvailabilityLabel providerService={data} />,
      },
      {
        header: 'Price',
        key: 'address.province',
        render: (data) => {
          const { priceType } = data;
          const stringedPrice = getServiceStringedPrice(data);
          const price = new Price(stringedPrice, priceType);
          return price.priceInPesos;
        },
      },
      {
        header: 'Actions',
        key: 'action',
        headerClassName: 'text-center',
        className: 'justify-start',
        isColumnHidden: !canEdit && !canEdit,
        render: (data) => (
          <div className="flex gap-4">
            {canEdit && <TableEditButton showLabel={false} onClick={handleActionEdit(data)} />}
            {canHide && (
              <TableHideButton
                showLabel={false}
                isHidden={data.hidden}
                onClick={handleActionHide(data)}
              />
            )}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser],
  );

  const handleActionEdit = (providerService: ProviderService) => () => {
    if (!canEdit) {
      return;
    }

    trackEvent(
      EventName.CLICK_EDIT_SERVICE_PAGE,
      getProviderServiceAnalyticsProps(providerService),
    );

    onEditService?.(providerService);
  };

  const handleRowClick = (providerService: ProviderService) => {
    if (!canEdit) {
      return;
    }

    onEditService?.(providerService);
  };

  const handleActionHide = (providerService: ProviderService) => () => {
    setSelectedService(providerService);
    handleToggleActionServiceModal();
  };

  const handleHideService = () => {
    hideProviderService(selectedService?.id as number);
  };

  const handleToggleActionServiceModal = () => {
    setIsConfirmHideModalOpen(!isConfirmActionModalOpen);
  };

  const debouncedSetSearch = debounce((value) => {
    setSearch(value);
    onSearch?.(value);
  }, 300);

  const handleSearch = (value?: string) => {
    debouncedSetSearch(value);
  };

  return (
    <Fragment>
      {isConfirmActionModalOpen && (
        <ActionModal
          variant={Variants.ERROR}
          title={selectedService?.hidden ? 'Unhide Service' : 'Hide Service'}
          description={
            selectedService?.hidden
              ? 'Are you sure you want to unhide this service?'
              : 'Are you sure you want to hide this service?'
          }
          primaryButtonText={selectedService?.hidden ? 'Unhide' : 'Hide'}
          isLoading={isHiding}
          onClickPrimaryButton={handleHideService}
          onClickSecondaryButton={handleToggleActionServiceModal}
        />
      )}
      <SearchInput
        defaultValue={search}
        className="mb-5 w-full"
        placeholder="Search name or description"
        iconVariant="plain"
        onChange={handleSearch}
      />
      <div className="rounded-lg bg-white">
        <div className="hidden md:block">
          <Table
            columns={columns}
            data={services}
            isLoading={isLoading}
            noDataText="No services found. Click “Add Service” to get started."
            onRowClick={handleRowClick}
          />
        </div>
        <div className="block md:hidden">
          <ProviderServicesTableMobile providerServices={services} isLoading={isLoading || false} />
        </div>
      </div>
    </Fragment>
  );
}

function filterList(list: ProviderService[], searchTerm: string): ProviderService[] {
  const lowerCasedSearchTerm = searchTerm.toLowerCase();

  return list.filter(
    (item) =>
      item.label?.toLowerCase().includes(lowerCasedSearchTerm) ||
      item.description?.toLowerCase().includes(lowerCasedSearchTerm),
  );
}

export default ProviderServicesTable;
