import {
  ProviderClientNotes,
  ProviderClientNotesApi,
  CreateProviderClientNotesDto,
  UpdateProviderClientNotesDto,
} from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';
import { Pagination } from '../types';

const providerClientNotesApi = HealthHubApiClient.use(ProviderClientNotesApi);

export type GetProviderClientNotesDto = {
  startDate?: string;
  endDate?: string;
  query?: string;
  providerClientProfileId?: number;
  appointmentId?: number;
  limit?: number;
  page?: number;
};

export type ProviderClientNotesFormData = {
  title: string;
  remarks: string;
  appointmentId?: number;
  providerClientProfileId?: number;
};

export const createProviderClientNotes = async (
  createProviderClientNotesDto: CreateProviderClientNotesDto,
): Promise<ProviderClientNotes> => {
  try {
    const response = await providerClientNotesApi.providerClientNotesControllerCreate(
      createProviderClientNotesDto,
    );
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
};

export const updateProviderClientNotesById = async (
  updateProviderClientNotesDto: UpdateProviderClientNotesDto,
): Promise<ProviderClientNotes> => {
  try {
    const response = await providerClientNotesApi.providerClientNotesControllerUpdate(
      updateProviderClientNotesDto,
    );
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
};

export const deleteProviderClientNotesById = async (id: string): Promise<void> => {
  try {
    await providerClientNotesApi.providerClientNotesControllerDelete(id);
  } catch (error) {
    throw new CustomError(error);
  }
};

export const findProviderClientNotes = async (
  params: GetProviderClientNotesDto,
): Promise<Pagination<ProviderClientNotes>> => {
  try {
    const { startDate, endDate, query, providerClientProfileId, appointmentId, page, limit } =
      params;
    const response =
      await providerClientNotesApi.providerClientNotesControllerFindProviderClientNotes(
        startDate,
        endDate,
        query,
        providerClientProfileId,
        appointmentId,
        limit,
        page,
      );
    return response.data as Pagination<ProviderClientNotes>;
  } catch (error) {
    throw new Error('Failed to fetch appointment provider notes');
  }
};

export const findProviderClientNotesById = async (id: string): Promise<ProviderClientNotes> => {
  try {
    const response = await providerClientNotesApi.providerClientNotesControllerFind(id);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch appointment provider notes');
  }
};
