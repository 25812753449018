import { EntityType } from '@healthhub/api-lib';

import { convertToDate, getDateOnly } from './date';

export type FilterType = {
  endDate?: string;
  startDate?: string;
  query?: string;
  status?: string;
  providerTypeId?: string;
  page?: number;
  limit?: number;
  sex?: string;
  sortBy?: string;
  brandId?: string;
};

const defaultProviderType = 'All_Provider_Type_';

export const generateReportTitle = (
  prefix: string,
  filters: FilterType,
  providerTypes?: EntityType[],
): string => {
  const query = filters.query ? `${filters.query}_` : '';
  let providerType = '';
  if (providerTypes) {
    providerType = filters.providerTypeId
      ? getProviderTypeName(filters.providerTypeId, providerTypes)
      : defaultProviderType;
  }

  const convertedStartDate =
    filters.startDate && typeof filters.startDate === 'string'
      ? convertToDate(filters.startDate)
      : filters.startDate;

  const convertedEndDate =
    filters.endDate && typeof filters.endDate === 'string'
      ? convertToDate(filters.endDate)
      : filters.endDate;

  const hasDate = convertedStartDate && convertedEndDate;

  const date = hasDate
    ? `(${getDateOnly(convertedStartDate as Date)} to ${getDateOnly(convertedEndDate as Date)})`
    : 'All_Time';

  const sex = filters.sex ? `${filters.sex}_` : '';

  return `${prefix}_${query}${sex}${providerType}${date}`;
};

function getProviderTypeName(providerTypeID: string, providerType: EntityType[]) {
  return `${
    providerType.find((type: EntityType) => type.id === +providerTypeID)?.name ||
    defaultProviderType
  }_`;
}
