import { Provider, ProviderTag } from '@healthhub/api-lib';
import { differenceInYears, format, parseISO } from 'date-fns';

import { clsxMerge } from './clsxMerge';
import { formatToPeso } from './price';
import { AutoCompleteOptionType, MultiSelectOptionType } from '../types';

export const defaultDoctorsOptions = {
  id: 'other',
  text: 'Other (not with mWell)',
  content: <span>Other (not with mWell)</span>,
};

export function generateProviderAutoCompleteOptions(
  idKey: string,
  labelKey: string[],
  list: Provider[],
): AutoCompleteOptionType[] {
  const optionList: AutoCompleteOptionType[] = list.map((item: Provider) => {
    const label = generateLabel(labelKey, item);

    return {
      id: item[idKey as keyof Provider] as string,
      text: label,
      content: <span>{label}</span>,
    };
  });

  return optionList;
}

export function generateAutoCompleteOptions<T>(
  idKey: string,
  labelKey: string[],
  list: T[],
): AutoCompleteOptionType[] {
  const optionList: AutoCompleteOptionType[] = list.map((item: T) => {
    const label = generateLabel(labelKey, item);

    return {
      id: item[idKey as keyof T] as string,
      text: label,
      content: <span>{label}</span>,
    };
  });

  return optionList;
}

export function formatBirthdate(date: string): string {
  const parsedDate = parseISO(date);
  const age = differenceInYears(new Date(), parsedDate);
  const formattedDate = format(parsedDate, 'MMM d, yyyy ');
  return `${formattedDate}(${age}y)`;
}

export function generateMultiSelectOptions<T>(
  idKey: string[],
  labelKey: string[],
  list: T[],
  rightContentKey?: string,
): MultiSelectOptionType[] {
  const optionList: MultiSelectOptionType[] = list.map((item) => {
    const label = generateLabel(labelKey, item);
    const isPrice = rightContentKey === 'price';
    const rightContent = rightContentKey
      ? isPrice
        ? formatToPeso(+item[rightContentKey as keyof T])
        : `${item[rightContentKey as keyof T]}`
      : undefined;
    return {
      value: generateValue(idKey, item),
      text: label,
      rightContent: rightContent,
    };
  });

  return optionList;
}

function generateValue<T>(idKeys: string[], item: T): string {
  const value = idKeys
    .reduce((acc, key) => {
      acc += `${key}:${item[key as keyof T]},`;
      return acc;
    }, '')
    .trim()
    .slice(0, -1);
  return value;
}

function generateLabel<T>(labelKeys: string[], item: T): string {
  const label = labelKeys
    .reduce((acc, key) => {
      acc += `${item[key as keyof T]} `;
      return acc;
    }, '')
    .trim();
  return label;
}

export function parseToObject(input: string): {
  [key: string]: string | number;
} {
  const jsonString = `{${input.replace(/([^,:]+):([^,:]+)/g, '"$1":"$2"').replace(/,/g, ',')}}`;

  const resultObject = JSON.parse(jsonString);

  return resultObject;
}

export const generateTagList = (providerTags: ProviderTag[], type: 'Appointment' | 'Table') => {
  const isTypeAppointment = type === 'Appointment';
  return providerTags.map((tag, index) => (
    <div
      key={`appointmentTag-${index}`}
      className={clsxMerge(
        'box-border rounded-full border-0 border-neutral-300 bg-neutral-200 px-2 py-1',
        isTypeAppointment && 'px-3 py-1.5',
      )}
    >
      <div
        className={clsxMerge(
          'whitespace-nowrap text-xs font-medium text-neutral-600',
          isTypeAppointment && 'text-sm font-normal',
        )}
      >
        {tag.tag}
      </div>
    </div>
  ));
};
