import { useMemo } from 'react';

import { ProviderService, ScheduleResponse } from '@healthhub/api-lib';
import { format, isBefore } from 'date-fns';

import { useGetSchedules } from './queries';
import { DAYS_OF_WEEK_VALUE, ISO_18601_FORMAT, SHOW_CUSTOMIZED_SCHEDULE } from '../constants';
import { WeekDayEnum } from '../enums/common';
import { FullSlot, SelectedDateSchedule } from '../types';
import {
  convertIsoFormatTime,
  filterSchedule,
  getCommonSchedules,
  getDateWithTimeObject,
  getStartAndEndDateOfWeek,
  getTodayUTCPhilippineTime,
  isTodayPhilippineTime,
} from '../utils';

export function useGetSelectedDateSchedule(
  providerId: number,
  selectedDate: Date,
  providerServices: ProviderService[] = [],
) {
  const { monday, sunday } = getStartAndEndDateOfWeek(selectedDate);
  const providerServiceIds = providerServices.map(({ id }) => id);

  const { data: selectedDateSchedules = [], isLoading: isGetSchedulesLoading } = useGetSchedules({
    providerId,
    day: DAYS_OF_WEEK_VALUE[selectedDate.getDay()] as WeekDayEnum,
    startDate: monday,
    endDate: sunday,
    selectedDate: format(selectedDate, ISO_18601_FORMAT),
    providerServiceIds: providerServices.map(({ id }) => id) ?? [],
  });

  const timeSlots: FullSlot[] = useMemo(() => {
    if (!selectedDateSchedules.length) {
      return [];
    }

    let filteredSchedule = filterSchedule(selectedDateSchedules);

    if (SHOW_CUSTOMIZED_SCHEDULE && providerServiceIds?.length > 0) {
      // get common schedules between regular and customized schedules
      filteredSchedule = getCommonSchedules(filteredSchedule, providerServices);
    }

    const fullSlotList = filteredSchedule.map(
      (schedule) =>
        ({
          id: schedule.id,
          remainingSlots:
            ensureNotNaN(Number(schedule.slots)) - ensureNotNaN(Number(schedule.appointmentCount)),
          startMilitaryTime: schedule.startTime,
          startTime: convertIsoFormatTime(schedule.startTime),
        }) as FullSlot,
    );

    return fullSlotList;
  }, [selectedDateSchedules, providerServices]);

  const selectedDateSchedule: SelectedDateSchedule = {
    date: selectedDate,
    timeSlots,
  };

  const disabledTimeSlots = selectedDateSchedules.filter((schedule) => {
    const { appointmentCount, slots } = schedule;

    if (!appointmentCount || !slots) {
      return false;
    }

    return +appointmentCount >= +slots;
  });

  return {
    disabledTimeSlots,
    isGetSchedulesLoading: isGetSchedulesLoading && Boolean(providerId),
    selectedDateSchedule,
  };
}

export function useGetFilteredTimeSlots(
  schedule: SelectedDateSchedule,
  disabledTimeSlots: ScheduleResponse[],
  selectedDate: Date,
) {
  const isSelectedDateToday = isTodayPhilippineTime(selectedDate);
  const today = getTodayUTCPhilippineTime();
  const { timeSlots, date: selectedScheduleDate } = schedule;

  const filteredTimeSlots = timeSlots.filter((timeSlot, idx, currentTimeSlots) => {
    const { startMilitaryTime, startTime } = timeSlot;

    const selectedDateAndTime = getDateWithTimeObject(selectedScheduleDate, timeSlot.startTime);
    const isTimePassed = isBefore(selectedDateAndTime, today);

    // TODO: Rethink solution how we can solve if we click 12:00 PM, it shouldn't select 12:00 AM
    // cause if we do startTime === selectedTime in the isTimeSelected below, the default selection would become 12:00 AM
    const isTwelveAm = startTime === '12:00 AM';

    const hasDuplicate =
      idx !==
      currentTimeSlots.findIndex((currTimeSlot) => currTimeSlot.startTime === timeSlot.startTime);

    if (isTwelveAm || hasDuplicate || (isSelectedDateToday && isTimePassed)) {
      return false;
    }

    return !disabledTimeSlots.some(
      (disabledTimeSlot) => disabledTimeSlot.startTime === startMilitaryTime.toString(),
    );
  });

  return filteredTimeSlots;
}

export function useGetRemainingSlots(
  providerId: number,
  selectedDate: Date,
  providerServices: ProviderService[] = [],
) {
  const { disabledTimeSlots, isGetSchedulesLoading, selectedDateSchedule } =
    useGetSelectedDateSchedule(providerId, selectedDate, providerServices);

  const filteredTimeSlots = useGetFilteredTimeSlots(
    selectedDateSchedule,
    disabledTimeSlots,
    selectedDate,
  );

  const remainingSlot = providerId
    ? filteredTimeSlots.reduce((total, slot) => total + slot.remainingSlots, 0)
    : 0;
  const indicator = remainingSlot === 1 ? 'slot' : 'slots';

  return {
    remainingSlot,
    remainingSlotDisplay: isGetSchedulesLoading
      ? 'Calculating...'
      : `(${remainingSlot} ${indicator} available)`,
  };
}

export function ensureNotNaN(num: number) {
  return isNaN(num) ? 0 : num;
}
