import { Appointment, ProviderService } from '@healthhub/api-lib';

import { displayOtherConcern } from '@mwell-healthhub/commons';

export const getProviderServicesFromAppointment = (
  appointment?: Appointment,
): ProviderService[] => {
  const { appointmentServices } = appointment ?? {};
  const services = appointmentServices?.map(
    (appointmentService) =>
      (appointmentService as unknown as Record<string, ProviderService>).providerService,
  );

  return services ?? [];
};

export const getProviderServicesLabelFromAppointment = (appointment?: Appointment): string => {
  return `${getProviderServicesFromAppointment(appointment)
    .map((providerService) => providerService.label)
    .join(', ')} ${displayOtherConcern(
    appointment?.appointmentServices,
    appointment?.otherConcern,
  )}`;
};

export function getCategoryDataFromProviderServices(services: ProviderService[]) {
  const categoryInfo = services.map((service) => {
    const id = service.serviceCategory?.id;
    const name = service.serviceCategory?.name;
    return { id, name };
  });

  const uniqueCategoryInfo = [...new Map(categoryInfo.map((item) => [item['id'], item])).values()];

  return uniqueCategoryInfo;
}
