import React from 'react';

import { DocumentTextIcon } from '@heroicons/react/24/outline';

export default function NoFeedBack() {
  return (
    <div className="flex h-96 items-center justify-center">
      <div className="text-center">
        <DocumentTextIcon width={32} height={32} className="mx-auto text-gray-800" />
        <h2 className="mt-4 text-xl font-semibold text-gray-800">No Feedback Yet</h2>
        <p className="text-gray-600">We don&apos;t have any feedback to show right now.</p>
      </div>
    </div>
  );
}
