import { useQuery } from '@tanstack/react-query';

import { PLACES_SEARCH, REVERSE_GEOCODE_SEARCH } from '../../constants';
import { getAddressFromLatLng, searchLocation } from '../../services';

export const useSearchLocation = (query: string, country: string) =>
  useQuery([PLACES_SEARCH, query], () => searchLocation(query, country), {
    enabled: !!query,
  });

export const useReverseGeocode = (latitude?: number, longitude?: number) =>
  useQuery(
    [REVERSE_GEOCODE_SEARCH, latitude, longitude],
    () => getAddressFromLatLng(latitude as number, longitude as number),
    {
      enabled: latitude !== undefined && longitude !== undefined,
    },
  );
