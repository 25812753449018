import { useRef } from 'react';

import dynamic from 'next/dynamic';

const JoditEditor = dynamic(() => import('jodit-react'), { ssr: false });

type Props = {
  setValue: (value: string) => void;
  value?: string;
};

export default function RichTextEditor(props: Props) {
  const { setValue, value = '' } = props;
  const editor = useRef(null);

  return (
    <JoditEditor
      ref={editor}
      config={{
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'eraser',
          'ul',
          'ol',
          'font',
          'fontsize',
          'paragraph',
          'lineHeight',
          'superscript',
          'subscript',
          'link',
          'image',
          'spellcheck',
          'cut',
          'copy',
          'paste',
        ],
        tabIndex: 1,
      }}
      value={value}
      onBlur={setValue} // preferred to use only this option to update the content for performance reasons
    />
  );
}
