import { EXTENSION_BY_CONTENT_TYPE, IMAGE_FILE_EXTENSIONS, MimeTypesEnum } from '../constants';

export const ensureFileNameHasExtension = (fileName: string, contentType: string | null) => {
  const hasExtension = IMAGE_FILE_EXTENSIONS.some((fileExt) => fileName.includes(fileExt));

  if (hasExtension) {
    return fileName;
  }

  return [fileName, EXTENSION_BY_CONTENT_TYPE[(contentType ?? '') as MimeTypesEnum]]
    .filter(Boolean)
    .join('');
};

export const downloadFileByUrl = async (fileUrl: string, fileName: string): Promise<void> => {
  try {
    const response = await fetch(fileUrl);
    const contentType = response.headers.get('Content-Type');
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = ensureFileNameHasExtension(fileName, contentType);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

export const getContentType = async (fileUrl: string) => {
  try {
    const response = await fetch(fileUrl);

    return response.headers.get('Content-Type');
  } catch {
    return '';
  }
};

export const getExtensionByFileUrl = async (fileUrl: string | undefined) => {
  if (!fileUrl) {
    return '';
  }

  const contentType = await getContentType(fileUrl);

  return EXTENSION_BY_CONTENT_TYPE[(contentType ?? '') as MimeTypesEnum];
};
