import { useQuery } from '@tanstack/react-query';

import { getProvidersFromDirectory } from '../../api/providerDirectoryClientService';
import { GetManyProviderDirectoryParams } from '../../api/providerDirectoryService';
import { GET_CLIENT_PROVIDERS_FROM_DIRECTORY, SHOW_PROVIDER_DIRECTORY } from '../../constants';

export const useGetProvidersFromDirectoryQuery = (params: GetManyProviderDirectoryParams) => {
  const { searchQuery, limit, page, providerTypeId, longitude, latitude } = params;

  return useQuery(
    [
      GET_CLIENT_PROVIDERS_FROM_DIRECTORY,
      searchQuery,
      limit,
      page,
      providerTypeId,
      longitude,
      latitude,
    ],
    () => getProvidersFromDirectory(params),
    {
      enabled: SHOW_PROVIDER_DIRECTORY,
    },
  );
};
