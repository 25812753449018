import React from 'react';

type Props = {
  title: string;
  titleSize?: string;
  subtitle?: React.ReactNode;
  subtitleSize?: string;
};

const FormTitle = (props: Props) => {
  const { title, subtitle, titleSize, subtitleSize } = props;

  return (
    <div className="flex flex-col gap-2">
      <h1 className={`${titleSize || 'text-2xl'} font-bold`}>{title}</h1>
      {subtitle && <p className={`text-gray-500 ${subtitleSize}`}>{subtitle}</p>}
    </div>
  );
};

export default FormTitle;
