import React, { InputHTMLAttributes, RefObject, useEffect, useId, useMemo, useState } from 'react';

import { Trash } from 'iconoir-react';
import { twMerge } from 'tailwind-merge';

import { FileSizeLimitEnum, FileTypeExtensionEnum } from '../../constants';
import {
  clsxMerge,
  formatBytesToMegabytes,
  formatFileTypesForLabel,
  validateFiles,
} from '../../utils';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  acceptedFileTypes?: FileTypeExtensionEnum[];
  canDisplayError?: boolean;
  label?: string | React.ReactElement;
  labelClassName?: string;
  maxSizeLimit?: number;
  multiple?: boolean;
  showFiles?: boolean;
  onFileChange: (files: File[]) => void;
  inputRef?: RefObject<HTMLInputElement>;
};

const DEFAULT_SUPPORTED_FILETYPES = [
  FileTypeExtensionEnum.JPG,
  FileTypeExtensionEnum.JPEG,
  FileTypeExtensionEnum.PNG,
];
const DEFAULT_MAX_SIZE_LIMIT = FileSizeLimitEnum._50MB;

const FileUpload: React.FC<Props> = ({
  acceptedFileTypes = DEFAULT_SUPPORTED_FILETYPES,
  canDisplayError = true,
  label = 'Browse Files',
  labelClassName = '',
  maxSizeLimit = DEFAULT_MAX_SIZE_LIMIT,
  multiple = false,
  showFiles = false,
  onFileChange,
  inputRef,
  ...rest
}: Props) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const id = useId();

  const accept = useMemo(() => {
    return acceptedFileTypes.map((fileType) => `.${fileType}`).join(',');
  }, [acceptedFileTypes]);

  const errorComponent = useMemo(() => {
    return hasError && canDisplayError ? (
      <p className="mt-[6px] inline-block whitespace-pre-line text-left text-xs font-normal italic text-red-500 no-underline">
        The supported file types: {formatFileTypesForLabel(acceptedFileTypes)}
        {'\n'}
        The maximum file size limit: {formatBytesToMegabytes(maxSizeLimit)}
      </p>
    ) : null;
  }, [acceptedFileTypes, maxSizeLimit, hasError, canDisplayError]);

  useEffect(() => {
    onFileChange(selectedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setHasError(false);
      const files = Array.from(e.target.files as FileList);
      const validFiles = await validateFiles(files, { acceptedFileTypes });

      setSelectedFiles(validFiles);
      onFileChange(selectedFiles);
    } catch {
      setHasError(true);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFiles([]);
  };

  return showFiles && selectedFiles.length !== 0 ? (
    <div className="flex w-full items-center justify-between" key={selectedFiles?.[0]?.name}>
      <div className="w-4/5 break-all">{selectedFiles?.[0]?.name}</div>
      <div
        onClick={handleRemoveFile}
        className="cursor-pointer text-primary1 hover:text-primary1/60"
      >
        <Trash className="mx-1" />
      </div>
    </div>
  ) : (
    <div className="flex flex-col">
      <input
        ref={inputRef || undefined}
        id={`${id}-file-input`}
        multiple={multiple}
        type="file"
        accept={accept}
        className="hidden"
        onChange={handleFileChange}
        {...rest}
      />
      <label
        htmlFor={`${id}-file-input`}
        className={clsxMerge(
          'ml-0 mt-0 cursor-pointer whitespace-pre-line sm:ml-4',
          labelClassName,
        )}
      >
        {label}
        {'\n'}
        {errorComponent}
      </label>
    </div>
  );
};

export default FileUpload;
