import React, { useState, Children, cloneElement, ReactNode } from 'react';

import clsx from 'clsx';

type AccordionProps = {
  children: ReactNode;
};

type AccordionItemProps = {
  isActive?: boolean;
  title: string | ReactNode;
  children: ReactNode;
  onToggle?: () => void;
};

const Accordion: React.FC<AccordionProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="w-full">
      {Children.map(children, (child, index) =>
        cloneElement(child as React.ReactElement, {
          isActive: index === activeIndex,
          onToggle: () => handleToggle(index),
        }),
      )}
    </div>
  );
};

const AccordionItem: React.FC<AccordionItemProps> = ({ isActive, title, children, onToggle }) => (
  <div className="mb-2 rounded-md border border-gray3/20">
    <div className="flex cursor-pointer items-center justify-between p-3" onClick={onToggle}>
      {typeof title === 'string' ? (
        <h2 className="text-lg font-medium text-primary1">{title}</h2>
      ) : (
        title
      )}
      <svg
        className={clsx(
          'h-5 w-5 transition-transform duration-300',
          isActive ? 'rotate-180 transform' : '',
        )}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19 9l-7 7-7-7" />
      </svg>
    </div>
    {isActive && <div className="p-5">{children}</div>}
  </div>
);

export { Accordion, AccordionItem };
