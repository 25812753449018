import { ReactNode, useEffect, useRef, useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { Option } from '../../types';
import { clsxMerge } from '../../utils';
import InputLoadingSpinner from '../InputLoadingSpinner';
import LoadingSpinner from '../LoadingSpinner';

enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export type DropdownProps = {
  arrowColor?: string;
  className?: string;
  containerClassname?: string;
  optionsClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  errorMessage?: string;
  fontColor?: string;
  bgColor?: string;
  fontSize?: string;
  hasLeftRoundedBorders?: boolean;
  hasRightRoundedBorders?: boolean;
  hasScroll?: boolean;
  hasSearchInput?: boolean;
  height?: number;
  icon?: ReactNode;
  isLoading?: boolean;
  label?: JSX.Element | string;
  options: Option[] | unknown;
  showRequiredOnLabel?: boolean;
  placeholder?: string;
  value?: Option;
  width?: number;
  children?: React.ReactNode;
  onChange: (value: Option) => void;
  shouldOpen?: boolean;
  onDropDownClick?: () => void;
  onSearchInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconClassName?: string;
};

const Dropdown = (props: DropdownProps) => {
  const {
    label,
    className,
    containerClassname,
    optionsClassName,
    labelClassName,
    disabled = false,
    hasLeftRoundedBorders = true,
    hasRightRoundedBorders = true,
    hasScroll = true,
    hasSearchInput = false,
    icon,
    isLoading = false,
    placeholder,
    options,
    value,
    width,
    height,
    fontSize,
    fontColor,
    bgColor,
    arrowColor,
    onChange,
    errorMessage,
    showRequiredOnLabel,
    children,
    shouldOpen = false,
    onDropDownClick,
    onSearchInputChange,
    iconClassName,
  } = props;
  const updatedWidth = width ? `w-[${width}px]` : 'w-full';

  const updatedHeight = height ? `h-[${height}px]` : 'h-10';
  const updatedFontSize = fontSize ? `${fontSize}` : 'text-sm';
  const updatedColor = fontColor ? `${fontColor}` : 'text-gray-700';
  const updatedBgColor = bgColor ? `${bgColor}` : 'bg-neutral-50';
  const updatedArrowColor = arrowColor ? `${arrowColor}` : '';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<Position>(Position.RIGHT);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!shouldOpen) {
      setIsOpen(shouldOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpen]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleClick = () => {
    const updatedIsOpen = !isOpen;

    onDropDownClick?.();

    if (updatedIsOpen) {
      const dropdownElement = dropdownRef.current;
      if (!dropdownElement) return;

      const dropdownRect = dropdownElement.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      if (dropdownRect.left + dropdownRect.width / 2 > screenWidth / 2) {
        setDropdownPosition(Position.RIGHT);
      } else {
        setDropdownPosition(Position.LEFT);
      }
    }

    setIsOpen(updatedIsOpen);
  };

  const handleSelect = (option: Option) => {
    setIsOpen((previous) => !previous);
    onChange(option);
  };

  const searchInput = hasSearchInput && (
    <div className="sticky top-0 flex-grow bg-white p-2">
      <input
        type="text"
        className="w-full rounded-sm border border-gray-100 text-sm text-neutral-600 outline-none"
        placeholder="Search"
        onChange={onSearchInputChange}
      />
    </div>
  );

  const optionsPosition = dropdownPosition === Position.LEFT ? 'left-0' : 'right-0';
  const overflow = hasScroll ? 'overflow-y-scroll' : 'overflow-hidden';
  const renderOptions = isOpen && (
    <div
      className={clsxMerge(
        'shadow-lg absolute z-10 mt-2 max-h-96  w-full rounded-md bg-white ring-1 ring-black ring-opacity-5 focus-visible:outline-none',
        updatedWidth,
        optionsPosition,
        overflow,
        optionsClassName,
      )}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
    >
      <div className="relative" role="none">
        {searchInput}
        {Array.isArray(options)
          ? options.map((option, index) => (
              <div
                key={option.value}
                className={clsxMerge(
                  `block px-4 py-2 ${updatedHeight} ${updatedFontSize} truncate text-neutral-600 hover:cursor-pointer hover:bg-lightGray`,
                  { 'font-extrabold': option.value === value?.value },
                )}
                title={option.label}
                role="menuitem"
                tabIndex={-1}
                id={`menu-item-${index}`}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))
          : children}
      </div>
    </div>
  );

  return (
    <div
      className={clsxMerge(
        'relative inline-block text-left',
        containerClassname && containerClassname,
      )}
      ref={dropdownRef}
    >
      <div className="h-fit">
        {label && (
          <label
            className={clsxMerge(
              'font-regular neutral-600 block text-sm leading-6',
              labelClassName,
            )}
          >
            {label}
            {showRequiredOnLabel && <span className="text-red-600"> *</span>}
          </label>
        )}
        <button
          type="button"
          className={clsxMerge(
            `inline-flex ${updatedHeight} ${updatedColor} ${updatedFontSize} items-center justify-start ${updatedBgColor} px-3 py-2 ring-1 ring-inset ring-neutral-300 focus:ring-inset focus:ring-primary-500 disabled:bg-neutral-100 disabled:text-neutral-400 ${updatedWidth}`,
            {
              'rounded-l-md': hasLeftRoundedBorders,
              'rounded-r-md': hasRightRoundedBorders,
              'ring-red-600 focus:ring-red-600': errorMessage,
            },
            className,
          )}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={handleClick}
          disabled={isLoading || disabled}
        >
          {icon && icon}
          <span
            className={clsxMerge(
              `mr-2 inline-block w-full text-left text-neutral-400 ${updatedFontSize} truncate md:text-sm`,
              value?.value && 'text-neutral-600 disabled:text-neutral-400',
            )}
          >
            {value?.value ? value.label : placeholder}
          </span>
          {isLoading && <LoadingSpinner size="sm" className="mr-2" />}
          <ChevronDownIcon
            className={clsxMerge(
              `h-5 w-5 ${updatedArrowColor}`,
              iconClassName,
              isOpen && 'rotate-180',
            )}
          />
        </button>
        {errorMessage && <div className="mt-1 text-sm text-red-600">{errorMessage}</div>}
      </div>
      {isLoading && <InputLoadingSpinner />}
      {renderOptions}
    </div>
  );
};

export default Dropdown;
