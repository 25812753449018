import { MutationOptions, useMutation } from '@tanstack/react-query';
import { CreateServiceScheduleDto } from '@healthhub/api-lib';
import {
  createServiceSchedule,
  updateServiceSchedule,
  deleteServiceSchedule,
  UpdateServiceScheduleParams,
  DeleteServiceScheduleParams,
  checkServiceScheduleExistence,
  CheckServiceScheduleExistenceParams,
} from '@mwell-healthhub/commons';
import { AxiosError } from 'axios';

export const useCreateServiceSchedule = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, CreateServiceScheduleDto>,
) => {
  return useMutation<void, AxiosError<{ message: string }>, CreateServiceScheduleDto>(
    createServiceSchedule,
    mutationOptions,
  );
};

export const useUpdateServiceSchedule = (
  mutationOptions: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    UpdateServiceScheduleParams
  >,
) => {
  return useMutation<void, AxiosError<{ message: string }>, UpdateServiceScheduleParams>(
    updateServiceSchedule,
    mutationOptions,
  );
};

export const useDeleteServiceSchedule = (
  mutationOptions: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    DeleteServiceScheduleParams
  >,
) => {
  return useMutation<void, AxiosError<{ message: string }>, DeleteServiceScheduleParams>(
    deleteServiceSchedule,
    mutationOptions,
  );
};

export const useCheckServiceScheduleExistence = (
  mutationOptions: MutationOptions<
    boolean,
    AxiosError<{ message: string }>,
    CheckServiceScheduleExistenceParams
  >,
) => {
  return useMutation<boolean, AxiosError<{ message: string }>, CheckServiceScheduleExistenceParams>(
    checkServiceScheduleExistence,
    mutationOptions,
  );
};
