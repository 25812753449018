export enum UserTypeEnum {
  PATIENT = 'patient',
  DOCTOR = 'doctor',
  LABORATORY = 'laboratory',
  LABORATORY_TECHNICIAN = 'lab_technician',
  ADMIN = 'admin',
}

export enum HealthhubUserSelection {
  ALL = 'all',
  CLIENTS = 'clients',
  PROVIDERS = 'providers',
}

export enum UserSex {
  MALE = 'male',
  FEMALE = 'female',
}

export enum UserGender {
  ALL = 'all',
  MALE = 'male',
  FEMALE = 'female',
}

export enum UserBloodType {
  A_POSITIVE = 'A+',
  A_NEGATIVE = 'A-',
  B_POSITIVE = 'B+',
  B_NEGATIVE = 'B-',
  AB_POSITIVE = 'AB+',
  AB_NEGATIVE = 'AB-',
  O_POSITIVE = 'O+',
  O_NEGATIVE = 'O-',
}
