import { User } from '@healthhub/api-lib';

import { CLIENT_USER_FOR_AUTOMATIONS } from '../constants/automations';

export const checkIfAutomationUser = (user: User) => {
  return (
    user.mobilePhoneNumber === CLIENT_USER_FOR_AUTOMATIONS.mobilePhoneNumber &&
    user.countryCode === CLIENT_USER_FOR_AUTOMATIONS.countryCode
  );
};
