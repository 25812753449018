import React, { useRef, useState } from 'react';

import { UseFormReturn } from 'react-hook-form';

import { uploadFile } from '../../api';
import { AspectRatioErrors, FILE_EXCEEDS_10MB_MESSAGE } from '../../constants';
import { AspectRatio } from '../../enums';
import { checkImageSizeExceeds } from '../../utils';
import Button from '../Button';
import FileUpload from '../FileUpload';
import LoadingSpinner from '../LoadingSpinner';

type Props = {
  isDisabled?: boolean;
  formMethods: UseFormReturn<any>;
  fieldName?: string;
  aspectRatio?: number;
  value?: string;
  isRatioFixed?: boolean;
  onChange: (url: string) => void;
};

export default function ProductImageUploader(props: Props) {
  const {
    isDisabled,
    value,
    onChange,
    formMethods,
    fieldName = 'imageUrl',
    aspectRatio = AspectRatio._21_9,
    isRatioFixed,
  } = props;

  const { setError, clearErrors } = formMethods;

  const [isUpdatingImage, setIsUpdatingImage] = useState<boolean>(false);
  const uploadPhotoImageRef = useRef<HTMLInputElement>(null);

  function calculateAspectRatio(width: number, height: number) {
    return width / height;
  }

  function isCloserToAspectRatio(width: number, height: number, targetAspectRatio: number) {
    const actualAspectRatio = calculateAspectRatio(width, height);
    return Math.abs(actualAspectRatio - targetAspectRatio);
  }

  const validateImageDimensions = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        const ratioDifference = isCloserToAspectRatio(width, height, aspectRatio);
        const isValid = ratioDifference < 0.6;

        resolve(isValid);
      };
      img.onerror = () => resolve(false);
    });
  };

  const handlePhotoChange = (onChange: (val: string) => void) => async (file: any) => {
    clearErrors(fieldName);
    if (!file[0]) {
      return;
    }

    if (checkImageSizeExceeds(file[0].size)) {
      setError(fieldName, {
        type: 'max',
        message: FILE_EXCEEDS_10MB_MESSAGE,
      });

      return;
    }

    const isValidDimensions = await validateImageDimensions(file[0]);

    if (!isValidDimensions && isRatioFixed) {
      setError(fieldName, {
        type: 'dimension',
        message: AspectRatioErrors[`ERR${aspectRatio}`],
      });

      return;
    }

    clearErrors(fieldName);
    setIsUpdatingImage(true);
    const loadUrl = await uploadFile(file[0]);

    setIsUpdatingImage(false);
    onChange(loadUrl.url);
  };

  const handleChangeImage = (inputRef: React.RefObject<HTMLInputElement>) => () => {
    inputRef.current?.click();
  };

  const loader = (
    <div className="relative mt-2 h-32 w-full">
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    </div>
  );

  if (isUpdatingImage) {
    return loader;
  }

  if (value) {
    return (
      <div className="relative mt-2 max-h-48 w-full">
        <img
          className="block max-h-48 w-[800px] items-center justify-center rounded-lg bg-gray-300 object-cover object-center md:mb-0 md:h-[300px]"
          src={value}
          height={170}
          width={450}
          alt="photo"
        />
        <div className="group absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <FileUpload
            disabled={isDisabled}
            inputRef={uploadPhotoImageRef}
            label="Upload Photo"
            labelClassName="sm:text-left text-primary4 font-medium text-[15px] hover:none hidden"
            onFileChange={handlePhotoChange(onChange)}
          />
          <Button
            disabled={isDisabled}
            onClick={handleChangeImage(uploadPhotoImageRef)}
            variant="cart"
            className="opacity-0 group-hover:opacity-100"
          >
            Update
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="mt-2 flex max-h-32 justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="flex flex-col items-center justify-center">
          {isUpdatingImage && loader}
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="text-indigo-600 focus-within:ring-indigo-600 hover:text-indigo-500 relative cursor-pointer rounded-md bg-white font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
            >
              <FileUpload
                disabled={isDisabled}
                label="Upload"
                labelClassName="rounded-md border border-primary-500 bg-white px-3 py-1 text-sm mb-2 font-normal text-primary-500 hover:bg-gray24 disabled:border-gray24 disabled:bg-gray11 disabled:text-gray9 sm:!ml-0 sm:text-left font-medium text-[15px] hover:none"
                onFileChange={handlePhotoChange(onChange)}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
