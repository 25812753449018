import { Fragment, useContext, useEffect, useState } from 'react';

import { ProviderService } from '@healthhub/api-lib';
import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';

import {
  Badge,
  BookingPaymentRequirement,
  DiscountBadge,
  SHOW_PROVIDER_PROFILE_V2,
  SHOW_PROVIDER_SERVICE_SETTINGS,
} from '@mwell-healthhub/commons';
import { BadgeColorType } from '@mwell-healthhub/commons/components/Badge/Badge';
import ProviderServiceHelper from '@mwell-healthhub/commons/utils/ProviderServiceHelper';

import { ProviderContext } from '../../../contexts/provider';

type Props = {
  providerService: ProviderService;
  onAdd?: () => void;
  onSelectItem?: () => void;
  onOpenDesktopServiceModal?: () => void;
};

function ProcedureCard(props: Props) {
  const { providerService, onAdd, onSelectItem, onOpenDesktopServiceModal } = props;

  const { state } = useContext(ProviderContext);
  const [isAdded, setIsAdded] = useState<boolean>(false);

  const title = providerService?.label;
  const { discountBadgeLabel, hasDiscount, formattedPrice, formattedDiscountedPrice } =
    new ProviderServiceHelper(providerService);

  const { services } = state;
  const isAvailable = true;

  useEffect(() => {
    const isServiceAlreadyAdded =
      !!providerService &&
      services.some((service) => +service.id === +(providerService as ProviderService)?.id);

    setIsAdded(isServiceAlreadyAdded);
  }, [providerService, services]);

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onAdd) {
      onAdd();
    }

    setIsAdded(!isAdded);
  };

  const handleSelectItem = () => {
    if (!isAvailable) {
      return;
    }

    if (onSelectItem) {
      onSelectItem();
    }
  };

  const handleOpenDesktopServiceModal = () => {
    if (!isAvailable) {
      return;
    }

    if (onOpenDesktopServiceModal) {
      onOpenDesktopServiceModal();
    }
  };

  const containerClassName = twMerge(
    'relative flex w-full items-center justify-between rounded-lg border bg-white p-4 shadow-dropdown',
    isAdded ? 'border-darkCerulean' : 'border-columbiaBlue',
    isAdded && SHOW_PROVIDER_PROFILE_V2 ? 'bg-[#E6EDF5]' : '',
  );
  const buttonClassName = twMerge(
    'flex h-[32px] min-w-[32px] cursor-pointer items-center justify-center rounded',
    isAdded && 'bg-primary-500 text-white',
    SHOW_PROVIDER_PROFILE_V2 && !isAdded && 'bg-secondary-300',
    !SHOW_PROVIDER_PROFILE_V2 && 'border border-primary-500 shadow-dropdown',
  );

  const titleClassName = twMerge(
    'text-sm font-semibold leading-5',
    isAvailable ? 'text-primary-500' : 'text-neutral-400',
  );

  const desktopContainerClassName = twMerge(
    'flex flex-col justify-between rounded-md border p-4',
    isAdded ? 'border-darkCerulean' : 'border-columbiaBlue',
    SHOW_PROVIDER_PROFILE_V2 ? (isAdded ? 'bg-[#E6EDF5]' : 'bg-white') : '',
    isAvailable ? 'cursor-pointer' : 'bg-neutral-100',
    SHOW_PROVIDER_PROFILE_V2 && 'hover:border-darkCerulean hover:drop-shadow-md',
  );

  const renderBookingPaymentRequirement = (
    <>
      {providerService.bookingPaymentRequirement === BookingPaymentRequirement.DOWNPAYMENT && (
        <Badge
          label="REQUIRES DOWNPAYMENT"
          containerClassName="px-1 py-0"
          type={BadgeColorType.REQUIRED}
        />
      )}
      {providerService.bookingPaymentRequirement === BookingPaymentRequirement.FULLPAYMENT && (
        <Badge
          label="REQUIRES FULL PAYMENT"
          containerClassName="px-1 py-0"
          type={BadgeColorType.REQUIRED}
        />
      )}
    </>
  );

  const hasBookingPaymentRequirement =
    providerService.bookingPaymentRequirement &&
    providerService.bookingPaymentRequirement !== BookingPaymentRequirement.NONE;

  const canShowBookingPaymentRequirement =
    SHOW_PROVIDER_SERVICE_SETTINGS &&
    hasBookingPaymentRequirement &&
    !!providerService?.provider?.paymentQrCodeUrl;

  const renderDiscountBadge = hasDiscount && (
    <DiscountBadge label={discountBadgeLabel} className="absolute -left-1.5 -top-2.5 " />
  );

  const renderPriceWithDiscount = (
    <div className="flex flex-col">
      <div className="text-gray-400 line-through md:text-sm">{formattedPrice}</div>
      <div className="font-semibold text-orange-500">{formattedDiscountedPrice}</div>
    </div>
  );

  const renderBasicPrice = <p className="font-semibold text-primary-500">{formattedPrice}</p>;

  const renderPrice = isAvailable ? (
    <div className="md:text-semibold flex flex-row items-center gap-1 text-xs md:text-base">
      {hasDiscount ? renderPriceWithDiscount : renderBasicPrice}
      {canShowBookingPaymentRequirement ? renderBookingPaymentRequirement : null}
    </div>
  ) : (
    <p className="md:text-semibold text-xs text-neutral-400 md:text-base">Currently unavailable</p>
  );

  const mobileView = (
    <div className={containerClassName} data-cy="service-item">
      {renderDiscountBadge}
      <div className="flex w-full cursor-pointer flex-col gap-1.5" onClick={handleSelectItem}>
        <h1 className={titleClassName}>{title}</h1>
        {renderPrice}
      </div>
      {isAvailable && (
        <div className={buttonClassName} onClick={handleToggle}>
          <span className="m-0 p-0 text-2xl text-primary-500">
            {isAdded ? (
              <CheckIcon className="h-5 w-5 text-white" data-cy={`${title}-service-check-icon`} />
            ) : (
              <PlusIcon
                className="h-5 w-5 text-primary-700"
                data-cy={`${title}-service-plus-icon`}
              />
            )}
          </span>
        </div>
      )}
    </div>
  );

  const maxLength = 34;

  const isTwoLines = (text: string) => {
    return text.length >= maxLength;
  };

  const desktopView = (
    <div
      className={twMerge(
        'drop-shadow-s relative',
        providerService?.description ? 'h-[152px]' : 'max-h-[152px]',
        desktopContainerClassName,
      )}
      onClick={handleOpenDesktopServiceModal}
    >
      {renderDiscountBadge}
      <div>
        <p
          className={twMerge(
            'line-clamp-2 overflow-hidden text-base font-semibold capitalize',
            !isAvailable ? 'text-neutral-400' : '',
          )}
        >
          {title || 'Unnamed Service'}
        </p>
        {providerService?.description && (
          <p
            className={twMerge(
              'overflow-hidden whitespace-pre-wrap pt-3 text-sm text-neutral-500',
              isTwoLines(title || '') ? 'line-clamp-1' : 'line-clamp-2',
              isAvailable ? 'text-neutral-500' : 'text-neutral-400',
            )}
          >
            {providerService?.description}{' '}
          </p>
        )}
      </div>
      <div className="flex items-center justify-between pt-3">
        {renderPrice}
        {isAvailable && (
          <div className={buttonClassName} onClick={handleToggle}>
            <span className="m-0 p-0 text-2xl text-primary-500">
              {isAdded ? (
                <CheckIcon className="h-5 w-5 text-white" data-cy={`${title}-service-check-icon`} />
              ) : (
                <PlusIcon
                  className="h-5 w-5 text-primary-700"
                  data-cy={`${title}-service-plus-icon`}
                />
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="sm:hidden">{mobileView}</div>
      <div className="hidden sm:block">{desktopView}</div>
    </Fragment>
  );
}

export default ProcedureCard;
