import React from 'react';

import { TabType } from '../../types';
import { clsxMerge } from '../../utils';

interface Props {
  tab: TabType;
  isActive: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const TabWithCountItem: React.FC<Props> = (props: Props) => {
  const { tab, isActive, isDisabled, onClick } = props;
  const { label, count } = tab;

  const disabledClass = isDisabled ? 'cursor-not-allowed opacity-50 pointer-events-none' : '';

  return (
    <button
      className={clsxMerge(
        isActive
          ? 'border-cobalt text-cobalt'
          : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
        'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
        disabledClass,
      )}
      onClick={onClick}
    >
      <span>{label}</span>
      {count ? (
        <span
          className={clsxMerge(
            isActive ? 'bg-indigo-100 text-cobalt' : 'bg-gray-100 text-gray-900',
            'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
          )}
        >
          {count}
        </span>
      ) : null}
    </button>
  );
};

export default TabWithCountItem;
