/* eslint-disable @nx/enforce-module-boundaries */
import { Fragment, ReactNode } from 'react';

import dynamic from 'next/dynamic';
import { ClassNameValue, twMerge } from 'tailwind-merge';

import { HIDE_NAVIGATION, getLocalStorageItem } from '@mwell-healthhub/commons';

import { useResetScroll } from '../../hooks';
import DesktopHeader from '../molecules/DesktopHeader';
import Footer from '../organisms/Home/Footer';
import Navigation from '../organisms/Navigation';

type Props = {
  children?: ReactNode;
};

const AppointmentsLayout = (props: Props) => {
  const { children } = props;
  const shouldHideNavigation = getLocalStorageItem(HIDE_NAVIGATION);

  useResetScroll();

  const mobileView = (
    <div className="bg-gray-100">
      <div className="flex flex-col bg-white">
        {!shouldHideNavigation && <Navigation className="relative max-w-full" />}
        <main
          className={twMerge(
            'flex min-h-[calc(100vh-56px)] flex-auto flex-col bg-neutral-100',
            (shouldHideNavigation && 'pt-0') as ClassNameValue,
          )}
        >
          {children}
        </main>
      </div>
    </div>
  );

  const desktopView = (
    <div className="flex min-h-screen flex-col bg-neutral-100">
      <DesktopHeader />
      <div className="mx-4 h-full grow">{children}</div>
      <Footer containerClassName="bg-opacity-100 bg-neutral-50" />
    </div>
  );

  return (
    <Fragment>
      <div className="sm:hidden">{mobileView}</div>
      <div className="hidden sm:block">{desktopView}</div>
    </Fragment>
  );
};

export default dynamic(() => Promise.resolve(AppointmentsLayout), { ssr: false });
