import { MutationOptions, useMutation } from '@tanstack/react-query';

import { markNotificationAsRead } from '../../api';
import { CustomError } from '../../errors';

export const useMarkNotificationAsReadMutation = (
  options?: MutationOptions<void, CustomError, number>,
) => {
  return useMutation((id: number) => markNotificationAsRead(id), options);
};
