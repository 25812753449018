import { ProviderClientNotes } from '@healthhub/api-lib';
import { Modal, ProviderClientNotesForm } from '@mwell-healthhub/commons';

type Props = {
  providerClientProfileId: number;
  appointmentId: number;
  currentProviderClientNote?: ProviderClientNotes;
  isOpen: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  toggle: () => void;
  handleProviderClientNotesSaved: () => void;
};

const ProviderClientNoteModal = ({
  providerClientProfileId,
  appointmentId,
  currentProviderClientNote,
  isOpen,
  isEditing = false,
  toggle,
  handleProviderClientNotesSaved,
}: Props) => {
  return (
    <Modal open={isOpen} setOpen={toggle} className="!p-0" size="lg">
      <ProviderClientNotesForm
        hideAppointments
        isEditing={isEditing}
        className="space-y-0"
        formFieldWrapperClassName="space-y-5 p-6"
        formFieldContainerClassName="rounded-0 border-0 p-0"
        formControlContainerClassName="gap-3 rounded-b-md bg-gray-50 px-6 py-3"
        cancelButtonVariant="outlined"
        providerClientProfileId={providerClientProfileId}
        appointmentId={appointmentId}
        handleShowProviderClientNoteForm={toggle}
        providerClientNote={currentProviderClientNote}
        handleSaved={handleProviderClientNotesSaved}
      />
    </Modal>
  );
};

export default ProviderClientNoteModal;
