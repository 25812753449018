import { useState, useEffect } from 'react';

import { USER, USER_ABILITIES } from '../constants/common';
import { getLocalStorageItem, setLocalStorageItem } from '../utils';

/*
  directly using localstorage value in the component causes hydration mismatch error.
  see https://nextjs.org/docs/messages/react-hydration-error
*/
export function useGetUserFromLocalStorage<T>() {
  const [userData, setUserData] = useState<T>();

  useEffect(() => {
    const user = getLocalStorageItem('user') as T;

    setUserData(user);
  }, []);

  return userData || ({} as T);
}

export function useGetCurrentUser<T>() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<T>();

  useEffect(() => {
    fetch('/api/me')
      .then(async (response) => {
        const userData = await response.json();
        setIsLoading(false);
        setUserData(userData as T);

        if (!userData) {
          setLocalStorageItem(USER_ABILITIES, []);
          setLocalStorageItem(USER, {});
        }
      })
      .catch(() => {
        // error means token has expired. cleanup user data
        setUserData({} as T);
        setIsLoading(false);
        setLocalStorageItem(USER_ABILITIES, []);
        setLocalStorageItem(USER, {});
      });
  }, []);

  return {
    isLoading,
    user: userData || ({} as T),
  };
}
