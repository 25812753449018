import { useEffect, useState } from 'react';

import {
  CreateProviderServiceDto,
  EntityType,
  Provider,
  ProviderService,
  ProviderServiceAvailabilityEnum,
  ProviderServiceBookingPaymentRequirementEnum,
  UpdateProviderServiceDto,
  UpdateProviderServiceDtoUpdateTypeEnum,
  UserOperation,
  UserProvider,
} from '@healthhub/api-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash.isempty';
import {
  Controller,
  FormProvider,
  ControllerRenderProps,
  Resolver,
  useForm,
} from 'react-hook-form';

import ProviderServiceAvailabilityFormFields from './ProviderServiceAvailabilityFormFields';
import ProviderServiceDeleteActionModal from './ProviderServiceDeleteActionModal';
import UpdateProviderServiceConfirmationModal from './ProviderServiceEditActionModal';
import {
  AutoComplete,
  Checkbox,
  FormPageLayout,
  GET_ALL_PROVIDER_SERVICE_BY_USER_ID,
  MultilineTextInput,
  OperationUserType,
  PriceType,
  ProviderUserType,
  TextArea,
  TextInput,
  intToPrice,
  priceToInt,
  toast,
  useCreateProviderService,
  useDeleteProviderService,
  useGetAllCategories,
  useGetAllProviderService,
  useToggle,
  useUpdateProviderService,
  BookingPaymentRequirement,
  SHOW_PROVIDER_SERVICE_SETTINGS,
  escapeRegExp,
  BRAND_ADMIN_USERS,
  formatToHumanReadable,
  useRouter,
} from '..';
import { trackEvent, EventName, getProviderServiceAnalyticsProps } from '../utils/analytics';
import { generateAutoCompleteOptions } from '../utils/generateOptions';
import ProviderServiceHelper from '../utils/ProviderServiceHelper';
import { dynamicProviderServiceSchema } from '../validators';

export type ProviderServiceFormValues = {
  isEditing?: boolean;
  isCurrentUserSuperAdmin?: boolean;
  availability: ProviderServiceAvailabilityEnum;
  serviceCategoryId?: number;
  description?: string;
  label?: string;
  price?: number;
  maxPrice?: number;
  specialNotes?: string;
  procedurePreparation?: string;
  freePriceLabel?: string;
  priceType?: PriceType;
  isFeatured?: boolean;
  providerServiceBranchIds?: number[];
  parentProviderServiceId?: number;
  updateType?: UpdateProviderServiceDtoUpdateTypeEnum;
  providerServiceBranchesToOverride?: number[];
  bookingPaymentRequirement?: ProviderServiceBookingPaymentRequirementEnum;
  downPaymentAmount?: number;
};

type Props = {
  providerService?: ProviderService;
  brand: Provider;
  provider: Provider; // provider where to save the service
  currentUser: UserProvider | UserOperation;
  onSuccess?: () => void;
  showBackButton?: boolean;
  isUserBrandLevelAdmin?: boolean;
  assignedBranches?: Provider[];
  isFullServiceFeatures?: boolean;
};

function ProviderServiceForm(props: Readonly<Props>) {
  const {
    providerService,
    brand,
    currentUser,
    provider,
    onSuccess,
    showBackButton = true,
    assignedBranches,
    isFullServiceFeatures = true,
  } = props;

  const [priceType, setPriceType] = useState<PriceType>(PriceType.Fixed);
  const [bookingPaymentRequirement, setBookingPaymentRequirement] = useState<
    BookingPaymentRequirement | undefined
  >(undefined);
  const [searchCategory, setSearchCategory] = useState<string>('');

  const { branches: brandBranches } = brand || {};
  const branches = isEmpty(assignedBranches) ? brandBranches : assignedBranches;
  const router = useRouter();
  const queryClient = useQueryClient();
  const providerTypeIds: string =
    brand?.providerTypes?.map((providerType) => `${providerType.id}`).join(',') || '';
  const { data: categories = [] } = useGetAllCategories({
    includeRelation: true,
    providerTypeIds,
  });
  const isCurrentUserSuperAdmin = BRAND_ADMIN_USERS.includes(
    currentUser?.userType as ProviderUserType | OperationUserType,
  );

  const { data: brandServices = [] } = useGetAllProviderService(
    { providerId: brand?.id },
    { isEnabled: !isCurrentUserSuperAdmin },
  );

  const { handleToggle: handleDeleteServiceToggle, isOpen } = useToggle();
  const {
    handleToggle: toggleUpdateConfirmationModalVisibility,
    isOpen: isUpdateConfirmationModalOpen,
  } = useToggle();

  const isEditing = !!providerService?.id;
  const isFree = priceType === PriceType.Free;
  const providerServiceHelper = new ProviderServiceHelper(providerService as ProviderService);

  const formMethods = useForm<ProviderServiceFormValues>({
    resolver: yupResolver(
      dynamicProviderServiceSchema(priceType || PriceType.Free),
    ) as unknown as Resolver<ProviderServiceFormValues>,
    defaultValues: {
      isCurrentUserSuperAdmin,
      isEditing,
      serviceCategoryId: providerService?.serviceCategoryId,
      description: providerService?.description || providerService?.service?.description,
      label: providerService?.label,
      price: intToPrice(providerService?.price ?? 0),
      maxPrice: intToPrice(providerService?.maxPrice ?? 0),
      availability: isFullServiceFeatures
        ? providerService?.availability
        : ProviderServiceAvailabilityEnum.Available,
      procedurePreparation: providerService?.procedurePreparation,
      specialNotes: providerService?.specialNotes,
      freePriceLabel: providerService?.freePriceLabel,
      priceType: providerService?.priceType as PriceType,
      isFeatured: providerService?.isFeatured,
      providerServiceBranchIds: isEmpty(providerService?.parentProviderService)
        ? providerServiceHelper.availableBranchServices?.map((bs) => bs.provider.id) || []
        : [providerService?.provider.id],
      parentProviderServiceId: providerService?.parentProviderService?.id,
      bookingPaymentRequirement:
        providerService?.bookingPaymentRequirement as BookingPaymentRequirement,
      downPaymentAmount: intToPrice(providerService?.downPaymentAmount ?? 0),
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
    reset,
  } = formMethods;

  const currentlyAppliedBranchIds = isEmpty(providerService?.parentProviderService)
    ? providerService?.branchServices?.map<number>(({ provider }) => provider.id) ?? []
    : ([providerService?.provider.id] as number[]);

  useEffect(() => {
    if (!providerService) {
      return;
    }

    setPriceType(providerService.priceType as PriceType);
    setBookingPaymentRequirement(
      providerService.bookingPaymentRequirement as BookingPaymentRequirement,
    );
  }, [providerService]);

  const { mutate: createProviderService, isLoading: isSaving } = useCreateProviderService({
    onSuccess: () => {
      trackEvent(EventName.CLICK_ADD_SERVICE, getProviderServiceAnalyticsProps(providerService));
      toast({
        type: 'success',
        message: 'Service created.',
      });
      handleSuccess();
    },
    onError: handleError,
  });

  const { mutate: updateProviderService, isLoading: isUpdating } = useUpdateProviderService({
    onSuccess: () => {
      trackEvent(EventName.CLICK_EDIT_SERVICE, getProviderServiceAnalyticsProps(providerService));
      toast({
        type: 'success',
        message: 'Service updated.',
      });
      handleSuccess();
      handleCloseUpdateConfirmationModal();
    },
    onError: handleError,
  });

  const { mutate: deleteProviderService, isLoading: isDeleting } = useDeleteProviderService({
    onSuccess: () => {
      trackEvent(EventName.CLICK_DELETE_SERVICE, getProviderServiceAnalyticsProps(providerService));
      toast({
        type: 'success',
        message: 'Service deleted.',
      });

      handleSuccess();
      handleDeleteServiceToggle();
    },
    onError: handleError,
  });

  function handleSuccess() {
    queryClient.invalidateQueries([GET_ALL_PROVIDER_SERVICE_BY_USER_ID, brand?.id]);
    onSuccess?.();
  }

  function handleError(err: Error) {
    const errorDetails = err.message;

    if (Array.isArray(errorDetails)) {
      errorDetails.forEach((errorDetail) => {
        setError(errorDetail.errorProperty, {
          type: 'exists',
          message: errorDetail.errorMessage,
        });
      });

      return;
    }

    toast({ type: 'error', message: 'Something went wrong' });
  }

  const handleCancel = () => {
    router.back();
  };

  const isThisPriceType = (type: PriceType) => {
    return priceType === type;
  };

  const isThisPaymentRequirement = (paymentRequirement: BookingPaymentRequirement) => {
    return bookingPaymentRequirement === paymentRequirement;
  };

  const getPrice = (price?: number) => {
    switch (priceType) {
      case PriceType.Fixed:
        return priceToInt(price ?? 0);
      case PriceType.Range:
        return priceToInt(price ?? 0);
      case PriceType.Free:
        return 0;
      case PriceType.Hide:
        return 0;
      case PriceType.StartsAt:
        return priceToInt(price ?? 0);
      default:
        return 0;
    }
  };

  const handleCloseUpdateConfirmationModal = () => {
    if (isUpdateConfirmationModalOpen) {
      toggleUpdateConfirmationModalVisibility();
      setValue('updateType', undefined);
      setValue('providerServiceBranchesToOverride', undefined);
    }
  };

  const handleOpenUpdateConfirmationModal = () => {
    if (!isUpdateConfirmationModalOpen) {
      toggleUpdateConfirmationModalVisibility();
    } else {
      setError('updateType', {
        message: 'Please select an option.',
      });
    }
  };

  const onSubmit = (newService: ProviderServiceFormValues) => {
    if (isCurrentUserSuperAdmin && !newService.updateType && isEditing && !provider?.isBranch) {
      handleOpenUpdateConfirmationModal();
      return;
    } else {
      toggleUpdateConfirmationModalVisibility();
    }

    const price = getPrice(newService.price);
    let providerServiceBranchIds: Array<number> = newService.providerServiceBranchIds ?? [];
    if (newService?.availability === ProviderServiceAvailabilityEnum.Available) {
      providerServiceBranchIds = branches?.map((branch) => branch.id) as Array<number>;
    }
    const isPriceTypeFixed = priceType === PriceType.Fixed;

    const data: CreateProviderServiceDto | UpdateProviderServiceDto = {
      availability: newService?.availability,
      serviceCategoryId: newService.serviceCategoryId,
      description: newService?.description?.trim(),
      freePriceLabel: newService?.freePriceLabel,
      label: newService?.label,
      maxPrice: priceToInt(newService.maxPrice ?? 0),
      price,
      priceType,
      procedurePreparation: newService?.procedurePreparation?.trim(),
      providerId: Number(provider?.id),
      specialNotes: newService?.specialNotes?.trim(),
      isFeatured: newService?.isFeatured,
      providerServiceBranches: providerServiceBranchIds,
      parentProviderServiceId: newService?.parentProviderServiceId,
      updateType: newService?.updateType,
      providerServiceBranchesToOverride: newService?.providerServiceBranchesToOverride,
      bookingPaymentRequirement: isPriceTypeFixed
        ? newService?.bookingPaymentRequirement
        : undefined,
      downPaymentAmount:
        isPriceTypeFixed && isThisPaymentRequirement(BookingPaymentRequirement.DOWNPAYMENT)
          ? priceToInt(newService?.downPaymentAmount ?? 0)
          : undefined,
    };

    if (isEditing) {
      updateProviderService({
        id: providerService.id,
        updateProviderServiceDto: data as UpdateProviderServiceDto,
      });
    } else {
      createProviderService(data as CreateProviderServiceDto);
    }
  };

  const handlePriceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceType(event.target.value as PriceType);
  };

  const onDeleteSubmission = (
    updateType: UpdateProviderServiceDtoUpdateTypeEnum,
    selectedBranchesIds: number[],
  ) => {
    if (isEditing) {
      deleteProviderService({
        id: providerService.id,
        deleteProviderServiceDto: {
          updateType,
          providerServiceBranchesToDelete: selectedBranchesIds,
        },
      });
    }
  };

  const handleBookingPaymentRequirementChange = (
    checked: boolean,
    field: ControllerRenderProps<ProviderServiceFormValues, 'bookingPaymentRequirement'>,
    value: BookingPaymentRequirement,
  ) => {
    const paymentRequirement = checked ? value : BookingPaymentRequirement.NONE;
    field.onChange(paymentRequirement);
    setBookingPaymentRequirement(paymentRequirement);
  };

  const mappedCategories = generateAutoCompleteOptions<EntityType>(
    'id',
    ['name'],
    categories,
  ).filter((category) => {
    const escapedSearchTerm = escapeRegExp(searchCategory?.toLocaleLowerCase() ?? '');
    const regex = new RegExp(escapedSearchTerm, 'i');

    return regex.test(category.text);
  });

  const branchServicesOptions = generateAutoCompleteOptions<ProviderService>(
    'id',
    ['label'],
    brandServices,
  );

  // MHH-999 Remove autocomplete for Brand-level users
  const shouldHideServiceNameAutoComplete = BRAND_ADMIN_USERS.includes(
    currentUser?.userType as ProviderUserType,
  );

  const isLoading = isSaving || isUpdating || isDeleting;
  const formTitle = `${isEditing ? 'Edit' : 'Add'} Service`;
  const submitButtonText = isEditing ? 'Save' : 'Add Service';

  const shouldShowUpdateConfirmationModal =
    isEditing && isUpdateConfirmationModalOpen && isCurrentUserSuperAdmin && !provider?.isBranch;

  const lastUpdatedDate = formatToHumanReadable(provider?.updatedAt || provider?.createdAt);
  const shouldShowLastUpdatedDate = isEditing;
  const shouldShowBranchApplyMessage = provider?.isBranch && isCurrentUserSuperAdmin;

  return (
    <FormProvider {...formMethods}>
      <FormPageLayout
        showBackButton={showBackButton}
        isLoading={isLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit(onSubmit)}
        onDelete={isEditing ? handleDeleteServiceToggle : undefined}
        submitButtonText={submitButtonText}
        deleteButtonText="Remove"
        title={formTitle}
      >
        {shouldShowUpdateConfirmationModal && (
          <UpdateProviderServiceConfirmationModal
            branches={branches}
            onSubmit={onSubmit}
            onClose={handleCloseUpdateConfirmationModal}
          />
        )}
        <div id="add-service" className="flex flex-col gap-8">
          <div className="rounded-lg border border-gray39 bg-white px-6 py-4 drop-shadow-sm md:p-8">
            <div className="flex flex-col gap-4 md:gap-5">
              <h4 className="text-base font-semibold md:text-lg">Service Information</h4>
              {shouldShowLastUpdatedDate && (
                <div className="rounded-md bg-blue-50 p-4 text-sm text-primary-500">
                  <span className="font-semibold">Last updated: {lastUpdatedDate}.</span> &nbsp;
                  {shouldShowBranchApplyMessage && (
                    <span>Changes made to this service will only apply to this branch.</span>
                  )}
                </div>
              )}
              <Controller
                name="label"
                control={control}
                rules={{ required: 'Service Name is required' }}
                render={({ field: { value }, fieldState: { error } }) => {
                  let fieldDisplay = (
                    <AutoComplete
                      showRequiredOnLabel
                      isSearchEnabled
                      isSearchTermSelectable
                      hideOptions={false}
                      isFetching={false}
                      valueKey="text"
                      initialValue={value?.toString()}
                      label="Service Name"
                      options={branchServicesOptions}
                      placeholder="Service Name"
                      onChangeText={(text) => {
                        if (!text) {
                          setValue('parentProviderServiceId', '' as unknown as number);
                        }
                        setValue('label', text);
                      }}
                      onSelectedItem={(data) => {
                        setValue('label', data.text);
                        if (!data.id) {
                          setValue('parentProviderServiceId', '' as unknown as number);
                          return;
                        }
                        setValue('parentProviderServiceId', Number(data.id));
                        setValue('label', data.text);

                        const selectedParentProviderService = brandServices.find(
                          (service) => service.id === Number(data.id),
                        );

                        reset({
                          ...selectedParentProviderService,
                          price: intToPrice(selectedParentProviderService?.price ?? 0),
                          maxPrice: intToPrice(selectedParentProviderService?.maxPrice ?? 0),
                          priceType: selectedParentProviderService?.priceType as PriceType,
                          parentProviderServiceId: selectedParentProviderService?.id,
                        });
                      }}
                    />
                  );

                  if (shouldHideServiceNameAutoComplete) {
                    fieldDisplay = (
                      <TextInput
                        showRequiredOnLabel
                        labelShown
                        {...register('label')}
                        className="w-full text-sm"
                        errorMessage={error?.message}
                        label="Service Name"
                        labelClassName="mb-1.5"
                        placeholder="Enter service name"
                      />
                    );
                  }

                  return <div className="flex flex-col gap-y-2">{fieldDisplay}</div>;
                }}
              />
              <div className="flex flex-col">
                <Controller
                  name="serviceCategoryId"
                  control={control}
                  render={({ field: { value }, fieldState: { error } }) => (
                    <div>
                      <AutoComplete
                        showRequiredOnLabel
                        hideOptions={false}
                        isFetching={false}
                        initialValue={value?.toString()}
                        label="Service Category"
                        labelClassName="mb-1.5"
                        options={mappedCategories}
                        placeholder="Select Category"
                        errorMessage={error?.message}
                        onChangeText={(text) => {
                          if (!text) {
                            setValue('serviceCategoryId', null as unknown as number);
                          }
                          setSearchCategory(text);
                        }}
                        onBlur={() => {
                          if (mappedCategories.length === 0) {
                            setSearchCategory('');
                          }
                        }}
                        onSelectedItem={(data) => {
                          setValue('serviceCategoryId', Number(data.id));
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              <Controller
                name="description"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultilineTextInput
                    showRequiredOnLabel
                    errorMessage={errors?.description?.message}
                    label="Description"
                    labelShown
                    placeholder="Input service description"
                    rows={4}
                    value={value}
                    onChange={onChange}
                    highlightInvalidChars
                  />
                )}
              />
              <MultilineTextInput
                {...register('procedurePreparation')}
                errorMessage={errors?.procedurePreparation?.message}
                label="Special Instructions"
                labelShown
                placeholder="Enter special instructions"
                subLabel="To enter items as a list, start each line with numbers (1., 2., 3.) or dashes (-). This will be shown to your clients after they make an appointment."
                rows={4}
              />
              {isFullServiceFeatures && (
                <>
                  <ProviderServiceAvailabilityFormFields
                    provider={brand}
                    isBranch={provider?.isBranch}
                    providerService={providerService}
                    currentUser={currentUser}
                  />
                  <Controller
                    name="isFeatured"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        className="text-secondary-500"
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange((e.target as HTMLInputElement).checked);
                        }}
                        label={<p className="text-sm font-semibold">Feature this service?</p>}
                      />
                    )}
                  />
                </>
              )}
            </div>
          </div>
          <div className="rounded-lg border border-gray39 bg-white px-6 py-4 drop-shadow-sm md:p-8">
            <div className="flex flex-col gap-4 md:gap-5">
              <h4 className="text-lg font-semibold">Service Pricing</h4>
              <div>
                <label className="mb-1.5 block text-sm font-normal leading-6 text-neutral-600">
                  Service Price
                  <span className="text-red-600"> *</span>
                </label>
                <div className="flex flex-col gap-y-3">
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Fixed}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.Fixed)}
                    />
                    <div className="flex w-full flex-col gap-y-2 pl-3">
                      <span className="text-sm text-neutral-900">Fixed price</span>
                      {priceType === PriceType.Fixed && (
                        <>
                          <TextInput
                            showRequiredOnLabel={isThisPriceType(PriceType.Fixed)}
                            {...register('price')}
                            className="w-full text-sm"
                            errorMessage={errors.price?.message}
                            label="Price"
                            placeholder="0.00"
                          />
                          {SHOW_PROVIDER_SERVICE_SETTINGS ? (
                            <>
                              <Controller
                                name="bookingPaymentRequirement"
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    className="text-secondary-500"
                                    checked={field.value === BookingPaymentRequirement.FULLPAYMENT}
                                    onChange={(e) =>
                                      handleBookingPaymentRequirementChange(
                                        (e.target as HTMLInputElement).checked,
                                        field,
                                        BookingPaymentRequirement.FULLPAYMENT,
                                      )
                                    }
                                    label="Require full payment upon booking"
                                  />
                                )}
                              />
                              <Controller
                                name="bookingPaymentRequirement"
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    className="text-secondary-500"
                                    checked={field.value === BookingPaymentRequirement.DOWNPAYMENT}
                                    onChange={(e) =>
                                      handleBookingPaymentRequirementChange(
                                        (e.target as HTMLInputElement).checked,
                                        field,
                                        BookingPaymentRequirement.DOWNPAYMENT,
                                      )
                                    }
                                    label="Require down payment"
                                  />
                                )}
                              />
                            </>
                          ) : null}
                          {isThisPaymentRequirement(BookingPaymentRequirement.DOWNPAYMENT) && (
                            <TextInput
                              showRequiredOnLabel={isThisPaymentRequirement(
                                BookingPaymentRequirement.DOWNPAYMENT,
                              )}
                              {...register('downPaymentAmount')}
                              className="w-full text-sm"
                              errorMessage={errors.downPaymentAmount?.message}
                              label="Down Payment Amount"
                              placeholder="0.00"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Range}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.Range)}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Price range</span>
                      {isThisPriceType(PriceType.Range) && (
                        <div>
                          <div className="flex w-full flex-col items-start gap-4 sm:flex-row">
                            <div className="w-full text-sm">
                              <TextInput
                                showRequiredOnLabel={isThisPriceType(PriceType.Range)}
                                {...register('price')}
                                containerClassName="w-full"
                                label="Price"
                                placeholder="0.00"
                              />
                              {errors?.price?.message && (
                                <div className="mt-1 text-sm text-red-600">
                                  {errors.price.message}
                                </div>
                              )}
                            </div>
                            <span className="mt-[10px] hidden text-sm text-neutral-400 sm:block">
                              —
                            </span>
                            <span className="block text-sm text-neutral-400 sm:hidden">to</span>
                            <div className="w-full text-sm">
                              <TextInput
                                showRequiredOnLabel={priceType === PriceType.Range}
                                {...register('maxPrice')}
                                containerClassName="w-full"
                                label="Max Price"
                                placeholder="0.00"
                              />
                              {errors?.maxPrice?.message && (
                                <div className="mt-1 text-sm text-red-600">
                                  {errors.maxPrice.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Free}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isFree}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Free</span>
                      {isThisPriceType(PriceType.Free) && (
                        <div>
                          <TextInput
                            showRequiredOnLabel={isThisPriceType(PriceType.Free)}
                            {...register('freePriceLabel')}
                            className="w-full text-sm"
                            containerClassName="w-full"
                            placeholder="Enter disclaimer or condition statement"
                          />
                          <div className="text-sm text-red-600">
                            {errors?.freePriceLabel?.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Hide}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.Hide)}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Hide Price</span>
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.StartsAt}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.StartsAt)}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Starts At</span>
                      {isThisPriceType(PriceType.StartsAt) && (
                        <TextInput
                          showRequiredOnLabel
                          {...register('price')}
                          className="w-full text-sm"
                          errorMessage={errors?.price?.message}
                          label="Starts At Price"
                          placeholder="0.00"
                        />
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <div>
                <label className="block text-sm font-normal leading-6 text-neutral-600">
                  Special Notes
                </label>
                <span className="pt-1 text-xs text-neutral-500">
                  This will appear upon checkout.
                </span>
                <div className="mt-4 flex flex-col gap-4">
                  <Controller
                    name="specialNotes"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextArea
                        className="w-full text-sm"
                        label="Free"
                        placeholder="Input special notes"
                        maxLength={150}
                        value={value}
                        onChange={onChange}
                        errorMessage={errors?.specialNotes?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormPageLayout>
      {isOpen && (
        <ProviderServiceDeleteActionModal
          branches={branches}
          providerServiceBranchIds={currentlyAppliedBranchIds ?? []}
          onSubmit={onDeleteSubmission}
          onClose={handleDeleteServiceToggle}
          label={providerService?.label}
        />
      )}
    </FormProvider>
  );
}

export default ProviderServiceForm;
