import { useQuery, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';

import {
  fetchAppointmentById,
  getAllAppointmentsByPage,
  queryGetNextPageParam,
  getAppointmentSchedule,
} from '@mwell-healthhub/commons';

import { GET_APPOINTMENT_BY_ID, GET_APPOINTMENT_SCHEDULE } from '../../constants';

export const useGetAllAppointmentsQuery = (params: {
  endDate?: string;
  limit?: number;
  query?: string;
  startDate?: string;
  status?: string;
  branchIds?: number[];
}): UseInfiniteQueryResult => {
  const { startDate, endDate, query = '', limit, branchIds } = params;
  return useInfiniteQuery(
    [query as string, `${limit}`],
    ({ pageParam }) =>
      getAllAppointmentsByPage(
        startDate,
        endDate,
        status,
        limit,
        pageParam,
        query,
        undefined,
        undefined,
        undefined,
        undefined,
        branchIds,
      ),
    {
      getNextPageParam: queryGetNextPageParam,
    },
  );
};

export const useGetAppointmentById = (id: string) =>
  useQuery([GET_APPOINTMENT_BY_ID, id], () => fetchAppointmentById(id), {
    enabled: !!id,
  });

export const useGetAppointmentSchedule = (id: string) =>
  useQuery([GET_APPOINTMENT_SCHEDULE, id], () => getAppointmentSchedule(id), {
    enabled: !!id,
  });
