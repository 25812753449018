import * as yup from 'yup';

import { DisplayType, TagType } from '../enums';

export const tagSchema = yup.object().shape({
  name: yup.string().required(),
  imageUrl: yup.string().url().optional().nullable(),
  tagType: yup.mixed<TagType>().oneOf(Object.values(TagType)),
  displayType: yup.mixed<DisplayType>().oneOf(Object.values(DisplayType)),
  displayButtonText: yup.string().when('displayType', (displayType, schema) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayType[0] === (DisplayType.Grid as any)
      ? schema.optional()
      : schema.required('Display button text is required'),
  ),
});
