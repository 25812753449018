import { useCallback } from 'react';

import { ScheduleDayEnum } from '@healthhub/api-lib';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Plus } from 'iconoir-react';
import { Controller, ControllerRenderProps, useFieldArray, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import Button from './Button';
import MultiSelectDropdown from './MultiSelectDropdown';
import { ProviderServiceCustomScheduleFormValues } from './ProviderCustomServiceScheduleForm';
import Select from './Select';
import TextInput from './TextInput';
import { timeIntervals } from '../constants';
import { CustomServiceScheduleEnum } from '../enums';
import { MultiSelectOptionType } from '../types/Option.type';
import { formatMilitaryTimeToAmPm, getTimeOptions } from '../utils';

const startHour = 6; // 6 AM
type TimeField = ControllerRenderProps<
  Pick<
    ProviderServiceCustomScheduleFormValues,
    | CustomServiceScheduleEnum.SLOTS
    | CustomServiceScheduleEnum.TIMESLOT_INTERVAL_IN_MINUTES
    | CustomServiceScheduleEnum.TIMESLOTS
    | CustomServiceScheduleEnum.DAYS
    | 'isEditing'
  >,
  `${CustomServiceScheduleEnum.TIMESLOTS}.${number}.start`
>;

export default function ProviderServiceCustomScheduleSetupFormFields() {
  const { control, watch, setValue } =
    useFormContext<
      Pick<
        ProviderServiceCustomScheduleFormValues,
        | CustomServiceScheduleEnum.TIMESLOTS
        | CustomServiceScheduleEnum.SLOTS
        | CustomServiceScheduleEnum.TIMESLOT_INTERVAL_IN_MINUTES
        | CustomServiceScheduleEnum.DAYS
        | 'isEditing'
      >
    >();
  const { fields, append, remove } = useFieldArray({
    control,
    name: CustomServiceScheduleEnum.TIMESLOTS,
  });
  const isEditing = watch('isEditing');
  const timeSlots = watch(CustomServiceScheduleEnum.TIMESLOTS);
  const timeInterval = watch(CustomServiceScheduleEnum.TIMESLOT_INTERVAL_IN_MINUTES);

  const disableDeleteTime = timeSlots.length <= 1;
  const disableAddTime =
    timeSlots.length === 0 ||
    !timeSlots[timeSlots.length - 1].start ||
    !timeSlots[timeSlots.length - 1].end;

  const dayOptions: MultiSelectOptionType[] = Object.entries(ScheduleDayEnum)?.map(
    ([key, value]) => ({
      text: key,
      value: value,
    }),
  );

  const intervalOptions = timeIntervals?.map((timeInterval) => (
    <option
      className="capitalize"
      key={timeInterval.valueInMinutes}
      value={timeInterval.valueInMinutes}
    >
      {timeInterval.label}
    </option>
  ));

  function handleDeleteTime(index: number): void {
    remove(index);
  }

  function handleAddTime(): void {
    append({
      start: '',
      end: '',
    });
  }

  const handleChangeDays =
    (onChange: (...event: any[]) => void) => (option: MultiSelectOptionType[]) => {
      onChange(option);
    };

  const getFilteredToTimeOptions = useCallback(
    (index: number): { disableEndTime: boolean; filteredToTimeOptions: string[] } => {
      const fieldStartTime = watch(
        `${CustomServiceScheduleEnum.TIMESLOTS}.${index}.start`,
      ) as unknown as string;
      const [hour, minutes] = fieldStartTime.split(':').map(Number);

      const filteredToTimeOptions = getTimeOptions(hour, minutes, 0, timeInterval).filter(
        (time) => time > fieldStartTime,
      );

      const disableEndTime = !fieldStartTime;

      return {
        disableEndTime,
        filteredToTimeOptions,
      };
    },
    [timeInterval, watch],
  );

  const getFilteredFromTimeOptions = (
    field: TimeField,
    index: number,
  ): { startTimeOptions: string[] } => {
    let startTimeOptions = getTimeOptions(startHour, 0, 0, timeInterval);
    const isSucceedingTimeRow: boolean = index > 0;
    const hasPreviousStartValue = !!field.value;

    if (isSucceedingTimeRow) {
      const previousIndex = index - 1;
      const previousEndTime = watch(`${CustomServiceScheduleEnum.TIMESLOTS}.${previousIndex}.end`);
      const [hour, minutes] = (previousEndTime as string).split(':').map(Number);
      startTimeOptions = getTimeOptions(hour, minutes, 0, timeInterval);

      const startValueLessThanPrevEnd = field.value < previousEndTime;

      if (hasPreviousStartValue && startValueLessThanPrevEnd) {
        setValue(`${CustomServiceScheduleEnum.TIMESLOTS}.${index}.start`, '' as never);
      }
    }

    return { startTimeOptions };
  };

  return (
    <div className="flex flex-col gap-8">
      <div>
        <label className="block text-sm font-normal leading-6 text-neutral-600">
          Days
          <span className="text-red-600"> *</span>
        </label>

        <Controller
          name={CustomServiceScheduleEnum.DAYS}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <MultiSelectDropdown
              placeholder="Select Days"
              options={dayOptions}
              selectedOptions={value}
              onSelect={handleChangeDays(onChange)}
              onRemove={handleChangeDays(onChange)}
              errorMessage={error?.message}
              disabled={isEditing}
              className={twMerge(isEditing && 'cursor-not-allowed')}
              selectInnerClassName={twMerge(isEditing && 'bg-neutral-100')}
              clearLabel="Clear Selection"
            />
          )}
        />
      </div>

      <div>
        <label className="block text-sm font-normal leading-6 text-neutral-600">
          Slots per interval
          <span className="text-red-600"> *</span>
        </label>
        <Controller
          name={CustomServiceScheduleEnum.SLOTS}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <TextInput
                placeholder="Enter slots count"
                type="number"
                inputMode="numeric"
                defaultValue={value}
                onChange={onChange}
              />
              {error && <p className="mt-1 text-sm text-red-600">{error?.message}</p>}
            </>
          )}
        />
      </div>

      <div>
        <label className="block text-sm font-normal leading-6 text-neutral-600">
          Interval
          <span className="text-red-600"> *</span>
        </label>
        <Controller
          control={control}
          name={CustomServiceScheduleEnum.TIMESLOT_INTERVAL_IN_MINUTES}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Select
              className="mb-3 !h-10 w-full"
              value={value}
              onChange={onChange}
              placeholder="Time Interval"
              errorMessage={error?.message}
            >
              {intervalOptions}
            </Select>
          )}
        />
      </div>

      <div>
        <label className="block text-sm font-normal leading-6 text-neutral-600">
          Time
          <span className="text-red-600"> *</span>
        </label>

        <div className="space-y-5">
          {fields.map((item, index) => {
            return (
              <div key={item.id} className="flex items-start gap-3">
                <div className="w-[200px]">
                  <Controller
                    control={control}
                    name={`${CustomServiceScheduleEnum.TIMESLOTS}.${index}.start`}
                    render={({ field, fieldState: { error } }) => {
                      const { startTimeOptions } = getFilteredFromTimeOptions(field, index);

                      return (
                        <Select
                          onChange={field.onChange}
                          value={field.value || ''}
                          className="!h-10"
                          placeholder="From"
                          errorMessage={error?.message}
                        >
                          {startTimeOptions.map((time, idx) => (
                            <option key={idx} value={time}>
                              {formatMilitaryTimeToAmPm(time)}
                            </option>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </div>
                <div className="mt-2"> - </div>
                <div className="w-[200px]">
                  <Controller
                    control={control}
                    name={`${CustomServiceScheduleEnum.TIMESLOTS}.${index}.end`}
                    render={({ field, fieldState: { error } }) => {
                      const { disableEndTime, filteredToTimeOptions } =
                        getFilteredToTimeOptions(index);

                      return (
                        <Select
                          key={index}
                          disabled={disableEndTime}
                          onChange={field.onChange}
                          value={field.value || ''}
                          className="!h-10"
                          placeholder="To"
                          errorMessage={error?.message}
                        >
                          {filteredToTimeOptions.map((time, idx) => (
                            <option key={idx} value={time}>
                              {formatMilitaryTimeToAmPm(time)}
                            </option>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </div>
                <div className="min-w-[151px]">
                  <div className="flex w-full">
                    <Button
                      className="w-full whitespace-nowrap text-sky-800 sm:w-auto"
                      onClick={() => handleDeleteTime(index)}
                      variant="plain"
                      disabled={disableDeleteTime}
                    >
                      <TrashIcon width="1.3em" height="1.3em" style={{ marginRight: '2px' }} />{' '}
                      Delete Time
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <Button
            className="mt-3 w-full whitespace-nowrap text-sky-800 sm:w-auto"
            onClick={handleAddTime}
            variant="plain"
            disabled={disableAddTime}
          >
            <Plus />
            Add Time
          </Button>
        </div>
      </div>
    </div>
  );
}
