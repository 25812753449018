import { ProviderService, ProviderServiceAvailabilityEnum } from '@healthhub/api-lib';

import {
  BadgeType,
  getServiceStringedPrice,
  LoadingSpinner,
  Price,
  Routes,
  useRouter,
} from '@mwell-healthhub/commons';
import { Badge } from '@mwell-healthhub/commons/components/v2';

export type ClientsTableMobileProps = {
  isLoading: boolean;
  providerServices?: ProviderService[];
};

function ProviderServicesTableMobile(props: ClientsTableMobileProps) {
  const router = useRouter();
  const { isLoading, providerServices } = props;
  const isEmpty = providerServices?.length === 0;

  const appointmentsDisplay = providerServices?.map((providerService) => {
    const serviceCategoryName = providerService?.serviceCategory?.name;
    const stringedPrice = getServiceStringedPrice(providerService);
    const price = new Price(stringedPrice, providerService.priceType).priceInPesos;

    const badgeDisplay =
      providerService.availability === ProviderServiceAvailabilityEnum.Available ? (
        <Badge type={BadgeType.GREEN}>{providerService.availability}</Badge>
      ) : (
        <span>{providerService.availability}</span>
      );

    function handleClickProviderService() {
      router.push(`${Routes.SERVICES_EDIT.replace(':id', providerService.id.toString())}`);
    }

    return (
      <div
        key={`provider-service-mobile-row-${providerService.id}`}
        className="border-b-1 border-b border-solid border-neutral-300 p-3 last:border-b-0 hover:bg-blue-50"
        onClick={handleClickProviderService}
      >
        <div className="flex justify-between">
          <div>
            <div className="text-xs font-medium text-neutral-600">{serviceCategoryName}</div>
            <div className="text-sm font-semibold text-neutral-500">{providerService.label}</div>
            <div className="text-xs text-neutral-500">{price}</div>
          </div>
          <div className="flex items-start">{badgeDisplay}</div>
        </div>
      </div>
    );
  });

  const loadingDisplay = (
    <div className="flex items-center justify-center">
      <LoadingSpinner />
    </div>
  );

  const emptyPlaceholderDisplay = <div className="p-3">No Results Found</div>;

  const renderBody = isLoading
    ? loadingDisplay
    : isEmpty
    ? emptyPlaceholderDisplay
    : appointmentsDisplay;

  return (
    <div className="border-1 overflow-auto rounded-lg border border-chineseWhite bg-white">
      {renderBody}
    </div>
  );
}

export default ProviderServicesTableMobile;
