export enum ProviderFormEnum {
  PARENT_PROVIDER_ID = 'parentProviderId',
  COUNTRY_CODE = 'countryCode',
  MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
  LOGO_URL = 'logoUrl',
  BANNER_URL = 'bannerUrl',
  PROVIDER_TYPE = 'providerType',
  PROVIDER_TYPE_ID = 'providerTypeId',
  PROVIDER_TYPE_IDS = 'providerTypeIds',
  PROVIDER_TYPES = 'providerTypes',
  NAME = 'name',
  STATUS = 'status',
  ADDRESS_BUILDING_NAME = 'address.buildingName',
  ADDRESS_LOT_BLOCK_PHASE_HOUSE = 'address.lotBlockPhaseHouse',
  ADDRESS_STREET_NAME = 'address.streetName',
  ADDRESS_SUBDIVISION = 'address.subdivision',
  ADDRESS_BARANGAY = 'address.barangay',
  ADDRESS_MUNICIPALITY_CITY = 'address.municipalityCity',
  ADDRESS_PROVINCE = 'address.province',
  ADDRESS_REGION = 'address.region',
  ADDRESS_COUNTRY = 'address.country',
  ADDRESS_ZIP_CODE = 'address.zipCode',
  ADDRESS_LATITUDE = 'address.latitude',
  ADDRESS_LONGITUDE = 'address.longitude',
  PHONE = 'phone',
  EMAIL_ADDRESS = 'emailAddress',
  WEBSITE = 'website',
  LAB_TECHNICIAN_USERS = 'labTechnicianUsers',
  USER_PROVIDER_EMAIL = 'userProvider.email',
  USER_PROVIDER_PASSWORD = 'userProvider.password',
  USER_PROVIDER_CONFIRM_PASSWORD = 'userProvider.confirmPassword',
  USER_PROVIDER_FIRST_NAME = 'userProvider.firstName',
  USER_PROVIDER_LAST_NAME = 'userProvider.lastName',
  BRANCH = 'branch',
  OFFICE_HOURS = 'officeHours',
  DISPLAY_NAME = 'displayName',
  REPRESENTATIVE_EMAIL = 'representative.email',
  REPRESENTATIVE_MIDDLE_NAME = 'representative.middleName',
  REPRESENTATIVE_FIRST_NAME = 'representative.firstName',
  REPRESENTATIVE_LAST_NAME = 'representative.lastName',
  REPRESENTATIVE_TITLE = 'representative.title',
  REPRESENTATIVE_MOBILE_NUMBER = 'representative.mobileNumber',
  REPRESENTATIVE_DIAL_CODE_MOBILE_NUMBER = 'representative.countryCode',
  REPRESENTATIVE_PHONE_NUMBER = 'representative.phoneNumber',
  REPRESENTATIVE_DIAL_CODE_PHONE_NUMBER = 'representative.phoneCountryCode',
  IS_AUTO_APPROVE_APPOINTMENT_ENABLED = 'isAutoApproveAppointmentEnabled',
  IS_MPH_PROVIDER = 'isMphProvider',
  ACCEPTED_HMOS = 'acceptedHmos',
  PAYMENT_QR_CODE_URL = 'paymentQrCodeUrl',
}
