import { restrictedCharactersMap } from './restrictedCharactersMap';

export const ALPHANUMERIC_WITH_Ññ_PATTERN = /^[a-zA-Z0-9Ññ\s]+$/;

export const ALPHANUMERIC_NAME_PATTERN =
  /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'()&-]*$/g;

export const DESCRIPTION_PATTERN = /^[a-zA-Z0-9Ññ ,.:;&-\s]+$/;

export const ALPHANUMERIC_SPECIAL_PATTERN = /^[a-zA-Z0-9Ññ #@:;'"/,%.!?$%^&()\-+=\n\r]*$/g;

export const ALPHANUMERIC_SPECIAL_PATTERN_WITH_NEWLINE =
  /^[a-zA-Z0-9Ññ #@:;'"/,%.!?$%^&()\-\+=\n]*$/g;

export const COMMA_SEPARATED_LATLONG_PATTERN = /^[-+]?\d+(\.\d{4,})?(, ?[-+]?\d+(\.\d{4,})?)?$/;

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const ID_PATTERN = /^[a-zA-Z0-9-]+$/g;

export const passwordCriteria =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/;

export const NAME_PATTERN =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$/g;

export const PHONE_PATTERN = /^(?:[0-9\s+-]*)$/g;

export const WHITE_SPACE_AND_HYPEN_PATTERN = /^[-\s]+|[-\s]+$/g;

export const ALLOWED_CHARS_IN_FRONT_END = new RegExp(
  `^[a-zA-Z0-9 _.,!?\\-@#/₱$%^&=*\\[\\]()+Ññ:;'"\n©®™℠‘’“”„‛…‒–—―′″<>` +
    Object.keys(restrictedCharactersMap)
      .join('')
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&') +
    `]*$`,
);
