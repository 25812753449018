import {
  ProviderClientNotes,
  CreateProviderClientNotesDto,
  UpdateProviderClientNotesDto,
} from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';

import {
  createProviderClientNotes,
  updateProviderClientNotesById,
  deleteProviderClientNotesById,
} from '../../api';
import { CustomError } from '../../errors';

export const useCreateProviderClientNotes = (
  mutationOptions?: MutationOptions<ProviderClientNotes, CustomError, CreateProviderClientNotesDto>,
) => useMutation(createProviderClientNotes, mutationOptions);

export const useUpdateProviderClientNotesById = (
  mutationOptions?: MutationOptions<ProviderClientNotes, CustomError, UpdateProviderClientNotesDto>,
) => useMutation(updateProviderClientNotesById, mutationOptions);

export const useDeleteProviderClientNotesById = (
  mutationOptions?: MutationOptions<void, CustomError, string>,
) => useMutation(deleteProviderClientNotesById, mutationOptions);
