import { useQuery } from '@tanstack/react-query';

import { OrderParams, getAllOrders, getOrderById, getOrderCounts } from '../../api';
import { GET_ALL_ORDERS, GET_ORDER_BY_ID, GET_ORDER_COUNTS } from '../../constants/queryKeys';

export const useGetAllOrdersQuery = (params: OrderParams, isEnabled = true) => {
  const {
    query,
    status,
    limit,
    page,
    merchantEmail,
    paymentStatus,
    orderBy,
    productId,
    dateFrom,
    dateTo,
    deliveryDateFrom,
    deliveryDateTo,
  } = params;

  return useQuery(
    [
      GET_ALL_ORDERS,
      query,
      status,
      limit,
      page,
      merchantEmail,
      paymentStatus,
      orderBy,
      productId,
      dateFrom,
      dateTo,
      deliveryDateFrom,
      deliveryDateTo,
    ],
    () => getAllOrders(params),
    {
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
};

export const useGetOrderByIdQuery = (id: string) => {
  return useQuery([GET_ORDER_BY_ID, id], () => getOrderById(id), {
    enabled: !!id,
  });
};

export const useGetOrderCounts = (merchantEmail?: string) => {
  return useQuery([GET_ORDER_COUNTS, merchantEmail], () => getOrderCounts(merchantEmail));
};
