import { useQuery } from '@tanstack/react-query';

import {
  AnnouncementFiltersParam,
  getAllAnnouncements,
  getAnnouncement,
} from '../../api/announcementService';
import { GET_ALL_ANNOUNCEMENTS, GET_ANNOUNCEMENT_BY_ID } from '../../constants/queryKeys';

export const useGetAllAnnouncementsQuery = (filters?: AnnouncementFiltersParam) =>
  useQuery([GET_ALL_ANNOUNCEMENTS, filters], () => getAllAnnouncements(filters));

export const useGetAnnouncementById = (id: string) =>
  useQuery([GET_ANNOUNCEMENT_BY_ID, id], () => getAnnouncement(id), {
    enabled: !!id,
  });
