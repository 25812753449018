import { useRef } from 'react';

import TextInput from './TextInput';
import { Button } from './v2';

type Props = {
  value: string;
};

function CopyInput(props: Readonly<Props>) {
  const { value } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <div className="flex rounded-md border border-neutral-300">
      <TextInput
        containerClassName="w-full"
        ref={inputRef}
        value={value}
        readOnly
        className="rounded-r-none text-sm text-neutral-500 ring-0"
      />
      <Button
        variant="tertiary"
        onClick={handleCopy}
        leftIcon="copy"
        className="rounded-l-none border-l border-neutral-300 !py-2.5"
      >
        Copy
      </Button>
    </div>
  );
}

export default CopyInput;
