import { useMemo } from 'react';

import { AppointmentApprovalStatusEnum, AppointmentStatusEnum } from '@healthhub/api-lib';
import {
  ArrowPathIcon,
  CheckIcon,
  UserPlusIcon,
  XMarkIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';

import FieldTextHtmlFormatter from './FieldTextHtmlFormatter';
import { Button } from './v2';
import { TWELVE_HOUR_TIME_FORMAT } from '../constants';
import { NotificationTemplateEnum } from '../enums';
import { clsxMerge } from '../utils';
import { getTimeAgo } from '../utils/date';

export type NotificationItemProps = {
  appointmentApprovalStatus?: AppointmentApprovalStatusEnum;
  status?: AppointmentStatusEnum;
  dateTime: Date;
  details: string;
  isRead?: boolean;
  subject: string;
  action: string | React.ReactNode;
  onItemClick?: () => void;
  onMarkAsRead: () => void;
  onApproveAppointment?: () => void;
  onDeclineAppointment?: () => void;
  isAppointmentPastDate?: boolean;
  isMarkingAsRead?: boolean;
  isApprovingAppointment?: boolean;
  isDecliningAppointment?: boolean;
  customActions?: React.ReactNode[];
  iconVariant: 'check' | 'x' | 'downTray' | 'newUser' | 'rightLeft' | 'check';
  type?: NotificationTemplateEnum;
};

const iconCommonClasses = 'relative flex h-6 w-6 items-center justify-center rounded-full';
const ICON_VARIANTS: Record<string, React.ReactNode> = {
  check: (
    <div className={`${iconCommonClasses} bg-blue-100 text-blue-700`}>
      <CheckIcon height={16} width={16} />
    </div>
  ),
  x: (
    <div className={`${iconCommonClasses} bg-red-100 text-red-700`}>
      <XMarkIcon height={16} width={16} />
    </div>
  ),
  downTray: (
    <div className={`${iconCommonClasses} bg-yellow-100 text-yellow-800`}>
      <ArrowPathIcon height={16} width={16} />
    </div>
  ),
  newUser: (
    <div className={`${iconCommonClasses} bg-green-100 text-green-700`}>
      <UserPlusIcon height={16} width={16} />
    </div>
  ),
  rightLeft: (
    <div className={`${iconCommonClasses} bg-orange-100 text-orange-500`}>
      <ArrowsRightLeftIcon height={16} width={16} />
    </div>
  ),
};

function NotificationItem(props: NotificationItemProps) {
  const {
    appointmentApprovalStatus = AppointmentApprovalStatusEnum.Approved,
    status,
    dateTime,
    details,
    isMarkingAsRead,
    isRead,
    isAppointmentPastDate,
    isApprovingAppointment,
    isDecliningAppointment,
    onItemClick,
    onMarkAsRead,
    onApproveAppointment,
    onDeclineAppointment,
    action,
    subject,
    iconVariant,
    customActions,
    type,
  } = props;

  const date = format(dateTime, 'EEE, MMM d');
  const time = format(dateTime, TWELVE_HOUR_TIME_FORMAT);
  const timeAgo = getTimeAgo(dateTime.toISOString());

  const isAppointmentTypeAwaitingForApproval =
    type === NotificationTemplateEnum.APPOINTMENT_AWAITING_APPROVAL;
  const isAppointmentTypeRescheduled = type === NotificationTemplateEnum.APPOINTMENT_RESCHEDULED;
  const isAppointmentTypeReferral =
    type === NotificationTemplateEnum.REFERRED_TO_PROVIDER_BOOKED_APPOINTMENT;
  const isAppointmentApprovalPending =
    appointmentApprovalStatus === AppointmentApprovalStatusEnum.Pending;
  const isAppointmentApprovalDeclined =
    appointmentApprovalStatus === AppointmentApprovalStatusEnum.Declined;
  const isAppointmentApprovalApproved =
    appointmentApprovalStatus === AppointmentApprovalStatusEnum.Approved;
  const isAppointmentCancelled = status === AppointmentStatusEnum.Cancelled;
  const displayBookingConfirmationButtons =
    (isAppointmentTypeAwaitingForApproval ||
      isAppointmentTypeRescheduled ||
      isAppointmentTypeReferral) &&
    isAppointmentApprovalPending &&
    !isAppointmentCancelled &&
    !isAppointmentPastDate;
  const displayMarkAsReadButton = !displayBookingConfirmationButtons && !isRead;

  function handleMarkAsRead(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onMarkAsRead();
  }

  function handleConfirmAppointment(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onApproveAppointment?.();
  }

  function handleDeclineAppointment(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onDeclineAppointment?.();
  }

  const iconDisplay = useMemo(() => {
    if (isAppointmentApprovalDeclined) {
      return ICON_VARIANTS['x'];
    }
    if (isAppointmentTypeAwaitingForApproval) {
      return ICON_VARIANTS['check'];
    }

    return ICON_VARIANTS[iconVariant];
  }, [isAppointmentApprovalDeclined, isAppointmentTypeAwaitingForApproval, iconVariant]);

  const displayApprovedAwaitingApproval =
    isAppointmentApprovalApproved && isAppointmentTypeAwaitingForApproval;
  const displayApprovedRescheduled = isAppointmentApprovalApproved && isAppointmentTypeRescheduled;
  const displayDefaultNotification =
    !displayApprovedAwaitingApproval &&
    !displayApprovedRescheduled &&
    !isAppointmentApprovalDeclined;

  const mainTextColor = isRead ? 'text-neutral-500' : 'text-neutral-600';
  const subTextColor = isRead ? 'text-neutral-400' : 'text-neutral-600';
  const bgColor = isRead ? 'bg-neutral-50' : 'bg-blue-50';

  const displayButtons =
    displayMarkAsReadButton || Boolean(customActions?.length) || displayBookingConfirmationButtons;

  return (
    <div
      className={clsxMerge(
        'w-full border-b border-b-neutral-300 p-3 last:border-b-0 md:p-0 md:px-6 md:py-4',
        {
          'cursor-pointer': onItemClick,
          'cursor-default': !onItemClick,
        },
        bgColor,
      )}
      onClick={onItemClick}
    >
      {/* Desktop Version */}
      <div className="hidden w-full gap-x-7 md:flex">
        <div className="flex items-start justify-start gap-4">
          {iconDisplay}
          <div className="flex w-24 flex-col items-start justify-center gap-1 text-sm">
            <div className={clsxMerge('font-medium', mainTextColor)}>{date}</div>
            <div className="font-sm text-neutral-400">{time}</div>
          </div>
        </div>
        <div className="flex shrink grow basis-0 flex-col items-start justify-start gap-y-1">
          <div className="inline-flex items-baseline gap-2 self-stretch">
            {displayApprovedAwaitingApproval && (
              <p className={clsxMerge('text-sm', mainTextColor)}>
                You have confirmed&nbsp;
                <span className="font-semibold">{action}'s</span>
                &nbsp;appointment.
              </p>
            )}
            {displayApprovedRescheduled && (
              <p className={clsxMerge('text-sm', mainTextColor)}>
                You have confirmed
                <span className="font-semibold">&nbsp;{action}'s&nbsp;</span>
                rescheduled appointment.
              </p>
            )}
            {isAppointmentApprovalDeclined && (
              <p className={clsxMerge('text-sm', mainTextColor)}>
                You have declined
                <span className="font-semibold">&nbsp;{action}'s&nbsp;</span>
                {isAppointmentTypeRescheduled ? 'rescheduled appointment.' : 'appointment.'}
              </p>
            )}
            {displayDefaultNotification && (
              <p className={clsxMerge('text-sm', mainTextColor)}>
                <span className="font-semibold">{subject}&nbsp;</span>
                {action}
              </p>
            )}
            <div className="whitespace-nowrap text-xs leading-none text-neutral-400">{timeAgo}</div>
          </div>
          <div className="max-h-[62px] self-stretch overflow-y-hidden">
            <FieldTextHtmlFormatter
              remarks={details}
              isTruncated
              lineClamp="line-clamp-3"
              className={clsxMerge('text-sm', {
                'text-neutral-500': !isRead,
                'text-neutral-400': isRead,
              })}
            />
          </div>
        </div>
        {displayButtons && (
          <div className="flex flex-col items-center justify-start gap-2 md:items-end lg:items-center lg:justify-center xl:flex-row [&>*]:min-w-[145px] [&>*]:max-w-[145px] [&>*]:rounded-md">
            {displayMarkAsReadButton && (
              <Button
                variant="secondaryGray"
                onClick={handleMarkAsRead}
                className="px-3.5 py-2.5 text-xs"
                isLoading={isMarkingAsRead}
                isDisabled={isMarkingAsRead}
              >
                Mark as Read
              </Button>
            )}
            {displayBookingConfirmationButtons && (
              <>
                <Button
                  variant="primary"
                  onClick={handleConfirmAppointment}
                  className="gap-2 px-3.5 py-2.5 text-xs"
                  isLoading={isApprovingAppointment}
                  isDisabled={isApprovingAppointment || isDecliningAppointment}
                >
                  <CheckIcon height={20} width={20} />
                  Confirm
                </Button>
                <Button
                  variant="outlinedRed"
                  onClick={handleDeclineAppointment}
                  className="gap-2 px-3.5 py-2.5 text-xs"
                  isLoading={isDecliningAppointment}
                  isDisabled={isApprovingAppointment || isDecliningAppointment}
                >
                  <XMarkIcon height={20} width={20} />
                  Decline
                </Button>
              </>
            )}
            {customActions?.map((action) => action)}
          </div>
        )}
      </div>
      {/* Mobile version */}
      <div className={clsxMerge('flex w-full flex-col gap-y-1 md:hidden')}>
        <div className="flex justify-between">
          <span className={clsxMerge('text-xs font-medium', mainTextColor)}>
            {time} • {date}
          </span>
          <div className="text-[10px] text-neutral-400">{timeAgo}</div>
        </div>
        <p className={clsxMerge('flex flex-wrap items-start text-sm', mainTextColor)}>
          <span className="font-semibold">{subject}</span>&nbsp;{action}
        </p>
        <FieldTextHtmlFormatter
          remarks={details}
          isTruncated
          lineClamp="line-clamp-3"
          className={clsxMerge('text-xs', subTextColor)}
        />
        {displayButtons && (
          <div className="mt-1 flex w-full flex-col gap-y-2">
            {displayMarkAsReadButton && (
              <Button
                variant="secondaryGray"
                onClick={handleMarkAsRead}
                className="box-border w-full px-3.5 py-2.5 text-xs"
                isLoading={isMarkingAsRead}
                isDisabled={isMarkingAsRead}
              >
                Mark as Read
              </Button>
            )}
            {customActions?.map((action) => <div>{action}</div>)}
            {displayBookingConfirmationButtons && (
              <div className="flex gap-2">
                <Button
                  variant="primary"
                  onClick={handleConfirmAppointment}
                  className="w-1/2 px-3.5 py-2.5"
                  isLoading={isMarkingAsRead}
                  isDisabled={isMarkingAsRead}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlinedRed"
                  onClick={handleDeclineAppointment}
                  className="mt-2 w-1/2 px-3.5 py-2.5"
                  isLoading={isMarkingAsRead}
                  isDisabled={isMarkingAsRead}
                >
                  Decline
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationItem;
