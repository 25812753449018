import { GetOCRDto, UpdateUserDto, UploadDisplayPhotoDto, User } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  getOCR,
  logoutUser,
  updateCurrentUser,
  updateUserDisplayPhoto,
} from '../../api/userService';
import { CustomError } from '../../errors';
import { UseMutationOptionsType } from '../../types/ReactQuery.type';

export const useUserLogout = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(logoutUser, mutationOptions);

export const useCurrentUserUpdateMutation = (
  mutationOptions?: MutationOptions<User, AxiosError<{ message: string }>, UpdateUserDto>,
) => {
  return useMutation((params) => updateCurrentUser(params), mutationOptions);
};

export const useCurrentUserUpdateDisplayPhotoMutation = (
  mutationOptions?: MutationOptions<
    UploadDisplayPhotoDto,
    AxiosError<{ message: string }>,
    UploadDisplayPhotoDto
  >,
) => {
  return useMutation(updateUserDisplayPhoto, mutationOptions);
};

export const useGetOcrMutation = (
  mutationOptions?: MutationOptions<object, CustomError, GetOCRDto>,
) => {
  return useMutation((params: GetOCRDto) => getOCR(params), mutationOptions);
};
