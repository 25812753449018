import { ShareIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/nextjs';

import { Button, clsxMerge, toast, useGetProviderShareLinks } from '@mwell-healthhub/commons';
import { trackEvent, EventName } from '@mwell-healthhub/commons/utils/analytics';

type Props = {
  slugName?: string;
  displayName?: string;
  iconDimensions?: string;
  className?: string;
};

export const ShareProviderButton = ({
  slugName,
  displayName,
  iconDimensions = 'h-4 h-4',
  className,
}: Props) => {
  const { data, isLoading } = useGetProviderShareLinks(slugName ?? '');

  const providerUrl = data?.providerUrl;
  const name = data?.name;

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(providerUrl as string);
    toast({ message: 'Copied provider share link!', type: 'info' });
  };

  const handleShare = async () => {
    const shareData = {
      title: displayName,
      text: name,
      url: providerUrl,
    };

    try {
      if (navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData);
      } else {
        await copyToClipboard();
      }

      trackEvent(EventName.SHARE_PROVIDER, shareData);
    } catch (error) {
      console.error(error);

      if (error instanceof DOMException) {
        Sentry.captureException(error);
      } else if (error instanceof TypeError) {
        await copyToClipboard();
      } else {
        toast({ message: 'Device unsupported!', type: 'warning' });
      }
    }
  };
  return (
    <Button
      variant="outlined"
      className={clsxMerge(
        'flex h-auto flex-row gap-x-1.5 whitespace-nowrap px-3 py-2 text-xs font-medium text-neutral-600',
        className,
      )}
      onClick={handleShare}
    >
      <ShareIcon className={`inline ${iconDimensions}`} />
      Share Provider
    </Button>
  );
};

export default ShareProviderButton;
