export enum PatientDigitalIdTabEnum {
  MEDICAL_HISTORY = 'medical-history',
  MEDICAL_DOCUMENTS = 'medical-documents',
  SAVED_IDS = 'saved-ids',
}

export enum MedicalDocumentTypesEnum {
  PRESCRIPTION = 'Prescription',
  ULTRASOUND = 'Ultrasound',
  XRAY = 'X-Ray',
  LABORATORY_RESULTS = 'Laboratory Results',
}
