import { SEX_LABEL, SexEnum } from '../enums';

export function convertSex(value: string) {
  switch (value) {
    case SexEnum.MALE:
      return '1';
    case SexEnum.FEMALE:
      return '0';
    case '1':
      return SexEnum.MALE;
    case '0':
      return SexEnum.FEMALE;
    default:
      return null;
  }
}

export function renderSexLabel(sex?: SexEnum) {
  if (!sex) return 'Unknown';

  return SEX_LABEL[sex];
}
