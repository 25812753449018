import { useEffect } from 'react';

import { useRouter } from '@mwell-healthhub/commons';

import useIsMobile from './useIsMobile';

function useResetScroll() {
  const isMobile = useIsMobile();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMobile) {
        window.scrollTo(0, 0);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, isMobile]);
}

export default useResetScroll;
