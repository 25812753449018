import React from 'react';

interface LabelWithIconProps {
  label: string;
  icon: React.ElementType;
}

const LabelWithIcon: React.FC<LabelWithIconProps> = ({ label, icon }) => {
  const IconComponent = icon;

  return (
    <div className="flex items-center gap-4 font-bold">
      <IconComponent className="h-4 w-4" />
      <span>{label}</span>
    </div>
  );
};

export default LabelWithIcon;
