import { Appointment, Provider } from '@healthhub/api-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  DATE_TIME_FORMAT_WITH_SINGLE_DIGIT_HOUR,
  TextArea,
  displayAppointmentDateTime,
  formatProviderDisplayNameWithBranch,
  toast,
  useCreateFeedbackMutation,
  useRouter,
} from '@mwell-healthhub/commons';
import { ACCEPTED_STRING_SCHEMA } from '@mwell-healthhub/commons/validators';

import FeedbackRatingBar from './FeedbackRatingBar';
import { Routes } from '../../constants';

type Form = {
  comment: string;
  rating: number;
};

type Props = {
  appointment?: Appointment;
  onClickCancel?: () => void;
  onSuccess?: () => void;
};

const ADD_FEEDBACK_SCHEMA = yup.object().shape({
  comment: ACCEPTED_STRING_SCHEMA.notRequired(),
  rating: yup.number().required('Rating is required.'),
});

export default function AddAppointmentFeedbackForm(props: Props) {
  const { appointment, onClickCancel, onSuccess } = props;
  const router = useRouter();

  const handleError = () => {
    toast({
      message: 'Something went wrong',
      type: 'error',
    });
  };

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      router.replace({
        pathname: Routes.APPOINTMENTS,
        query: {
          status: 'past',
        },
      });
    }

    toast({
      message: 'Thank you for your feedback.',
      type: 'success',
    });
  };

  const { mutate: createFeedback, isLoading: isCreating } = useCreateFeedbackMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { control, handleSubmit } = useForm<Form>({
    resolver: yupResolver(ADD_FEEDBACK_SCHEMA) as unknown as Resolver<Form>,
  });

  const handleValidSubmission = (formData: Form) => {
    if (!appointment) {
      return;
    }

    createFeedback({
      appointmentId: appointment.id,
      branchId: (appointment.provider as Provider).id,
      comment: formData.comment,
      rating: formData.rating,
    });
  };

  return (
    <form
      className="flex flex-1 flex-col"
      method="POST"
      onSubmit={handleSubmit(handleValidSubmission)}
    >
      <div className="flex justify-between">
        <p className="text-sm font-medium text-neutral-500">Appointment ID</p>
        <p className="text-sm font-medium text-neutral-500">{appointment?.bookingNumber}</p>
      </div>

      {appointment?.provider && (
        <h2 className="line-clamp-1 py-1 text-lg font-semibold text-primary-500">
          {formatProviderDisplayNameWithBranch(appointment.provider as Provider)}
        </h2>
      )}

      {appointment?.dateTime && (
        <p className="text-sm text-neutral-600">
          Appointment:{' '}
          {displayAppointmentDateTime(
            appointment?.dateTime as unknown as string,
            DATE_TIME_FORMAT_WITH_SINGLE_DIGIT_HOUR,
          )}
        </p>
      )}

      <div className="mt-4 flex-1">
        <p className="mb-4 text-sm text-neutral-600">
          On a scale from &quot;Very Unsatisfied&quot; to &quot;Very Satisfied&quot; rate your
          satisfaction with our service.
        </p>

        <Controller
          name="rating"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <div>
              <FeedbackRatingBar onChange={(selectedRating) => onChange(selectedRating.value)} />
              {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
            </div>
          )}
        />

        <div className="h-6" />

        <Controller
          name="comment"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextArea
              labelShown
              label={
                <p className="mb-4 text-sm text-neutral-600">
                  What can we do better?
                  <small className="mt-1 block text-xs text-neutral-500">
                    Tell us about your experience with{' '}
                    {appointment?.provider &&
                      formatProviderDisplayNameWithBranch(appointment?.provider as Provider)}
                  </small>
                </p>
              }
              className="text-sm"
              errorMessage={error?.message}
              placeholder="This helps us create a better experience for you!"
              rows={6}
              showRequiredOnLabel={false}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </div>

      <div className="fixed bottom-0 left-0 flex w-full  flex-col gap-4 px-6 py-5 text-center sm:static sm:flex-row sm:px-0 sm:pb-0">
        <Button
          isLoading={isCreating}
          disabled={isCreating}
          size="xl"
          variant="primary"
          className="w-full"
          type="submit"
        >
          Send
        </Button>
        {onClickCancel && (
          <Button
            onClick={onClickCancel}
            disabled={isCreating}
            size="xl"
            variant="secondary"
            className="w-full"
            type="button"
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
}
