import * as yup from 'yup';

import { ACCEPTABLE_CHAR_SET } from '../constants';

const INVALID_TEXT_ERROR = 'Text contains forbidden characters';

export const ACCEPTED_STRING_SCHEMA = yup
  .string()
  .trim()
  .matches(ACCEPTABLE_CHAR_SET, INVALID_TEXT_ERROR);
