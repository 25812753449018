import { FocusEvent } from 'react';

import clsx from 'clsx';
import { Control, Controller, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import * as yup from 'yup';

import Card from './Card';
import MaskedTextInput from './MaskedTextInput';
import { REGISTRATION_CHARACTER_LIMIT } from '../constants';
import { passwordValidationComponents } from '../validators';

type Props = {
  required?: boolean;
  context?: string;
  isChangePassword?: boolean;
  control: Control<any>;
  trigger: UseFormTrigger<any>;
  watch: UseFormWatch<any>;
};

export default function PasswordForm({
  required = true,
  context,
  isChangePassword = false,
  control,
  trigger,
  watch,
}: Props) {
  async function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
    await trigger(event.target.name);
  }

  const passwordContext = context ? `${context}.password` : 'password';
  const email = watch(context ? `${context}.email` : 'email');
  const password = watch(passwordContext);

  const passwordLabelPlaceholder = isChangePassword ? 'New Password' : 'Password';

  const confirmPasswordLabelPlaceholder = isChangePassword
    ? 'Confirm New Password'
    : 'Confirm Password';

  return (
    <div className="w-full">
      <div className="w-full">
        <Controller
          name={passwordContext}
          rules={{
            required,
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <MaskedTextInput
                {...field}
                labelShown
                showRequiredOnLabel={required}
                label={passwordLabelPlaceholder}
                name={passwordContext}
                placeholder={passwordLabelPlaceholder}
                onBlur={handleOnBlur}
                maxLength={REGISTRATION_CHARACTER_LIMIT.PASSWORD}
              />
              {error && <p className="mt-2 text-sm text-red-600">This field is required</p>}
            </>
          )}
        />
      </div>
      <div className="my-5">
        <Controller
          name="confirmPassword"
          rules={
            password && {
              required,
              validate: (value) => value === password || 'Passwords do not match',
            }
          }
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <MaskedTextInput
                {...field}
                value={field.value || ''}
                showRequiredOnLabel={required}
                placeholder={confirmPasswordLabelPlaceholder}
                onBlur={handleOnBlur}
              />
              {error && <p className="mt-2 text-sm text-red-600">This field is required</p>}
            </>
          )}
        />
      </div>
      <div>
        <Card className="border-none">
          <div className="text-sm">
            <p className="mb-2 font-medium text-neutral-500">Password must contain at least:</p>
            <ul className="grid grid-cols-2">
              {Object.entries(passwordValidationComponents).map(([key, criterion]) => {
                const isPasswordCompliant =
                  key === 'notSameAsEmail'
                    ? criterion.validation(yup.string(), email).isValidSync(password)
                    : criterion.validation(yup.string()).isValidSync(password);

                return (
                  <li key={key}>
                    <div className="flex font-normal text-neutral-500 sm:text-xs">
                      {isPasswordCompliant ? (
                        <div className="pr-2 text-success no-underline">&#x2713;</div>
                      ) : (
                        <div className="pl-1 pr-3 text-neutral-300">&#8226;</div>
                      )}
                      <div
                        className={clsx({
                          'text-neutral-300 line-through': isPasswordCompliant,
                        })}
                      >
                        {criterion.requirementMessage}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Card>
      </div>
    </div>
  );
}
