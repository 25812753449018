import { CreateFeedbackDto, FeedbackApi } from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';

export const feedbackApi = HealthHubApiClient.use(FeedbackApi);

export const getMultipleFeedback = async (
  branchId: string,
  authorId?: string,
  startDate?: string,
  endDate?: string,
) => {
  const stringedStartDate = startDate ? new Date(startDate).toISOString() : undefined;
  const stringedEndDate = endDate ? new Date(endDate).toISOString() : undefined;

  const { data } = await feedbackApi.feedbackControllerGetMultipleFeedback(
    branchId,
    authorId,
    stringedStartDate,
    stringedEndDate,
  );

  return data;
};

export async function createFeedback(createFeedbackDto: CreateFeedbackDto) {
  try {
    const { data } = await feedbackApi.feedbackControllerCreateFeedback(createFeedbackDto);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}
