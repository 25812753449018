import { useMemo } from 'react';

import { Provider, ProviderServiceAvailabilityEnum } from '@healthhub/api-lib';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import MultiSelectDropdown from './MultiSelectDropdown';
import { ProviderServiceCustomScheduleFormValues } from './ProviderCustomServiceScheduleForm';
import { CustomServiceScheduleEnum } from '../enums';
import { useGetAllProviderService } from '../hooks';
import { MultiSelectOptionType } from '../types';

type Props = {
  brand: Provider;
  branch: Provider;
  isCurrentUserBranchUser: boolean;
};

export default function ProviderSelectServicesFormField({
  brand,
  branch,
  isCurrentUserBranchUser,
}: Props) {
  const { control, watch } =
    useFormContext<
      Pick<
        ProviderServiceCustomScheduleFormValues,
        | CustomServiceScheduleEnum.SERVICES
        | CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS
        | 'isEditing'
        | 'isAddingToExisting'
      >
    >();
  const selectedBranches = watch('providerServiceBranchIds') as MultiSelectOptionType[];
  const isEditing = watch('isEditing');
  const isAddingToExisting = watch('isAddingToExisting');
  const isDisabled =
    isEditing || isAddingToExisting || !selectedBranches || selectedBranches?.length === 0;

  const providerId = isDisabled && isCurrentUserBranchUser ? branch?.id : brand?.id;

  const { data: providerServices = [] } = useGetAllProviderService(
    {
      providerId: providerId,
    },
    { isEnabled: !!providerId },
  );

  const selectedBranchesId = selectedBranches?.map(({ value }) => +value);
  const mappedProviderServices: MultiSelectOptionType[] = useMemo(() => {
    return providerServices
      .filter((providerService) => {
        const branchIds = providerService.branchServices?.map(
          (branchService) => branchService.provider.id,
        );
        if (branchIds) {
          return branchIds.filter((id) => selectedBranchesId?.includes(id)).length > 0;
        }
        return false;
      })
      .map((providerService) => {
        const isUnavailable =
          providerService.availability === ProviderServiceAvailabilityEnum.Unavailable;
        const text = `${providerService?.label || providerService?.service?.name}`;

        return {
          text,
          value:
            isDisabled && isCurrentUserBranchUser
              ? providerService.parentProviderService?.id
              : providerService.id,
          isDisabled: isUnavailable,
        };
      })
      .sort((a, b) => a.text.localeCompare(b.text)) as unknown as MultiSelectOptionType[];
  }, [providerServices, selectedBranchesId]);

  return (
    <div className="col-span-4">
      <div className="flex flex-col">
        <span className="mb-1.5 block text-sm leading-6 text-neutral-600">
          Service<span className="text-red-500"> *</span>
        </span>
        <Controller
          name={CustomServiceScheduleEnum.SERVICES}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <MultiSelectDropdown
                disabledText="(Unavailable)"
                onRemove={onChange}
                onSelect={onChange}
                options={mappedProviderServices}
                placeholder="Select Service"
                selectedOptions={value}
                disabled={isDisabled}
                className={twMerge(isDisabled && 'cursor-not-allowed')}
                selectInnerClassName={twMerge(isDisabled && 'bg-neutral-100')}
                clearLabel="Clear Selection"
              />
              {error && <p className="mt-1 text-sm text-red-500">This field is required</p>}
            </>
          )}
        />
      </div>
    </div>
  );
}
