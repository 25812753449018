import { downloadFileByUrl } from '../../utils';
import Button, { ButtonProps } from '../Button/Button';

type FileDownloaderProps = Pick<ButtonProps, 'variant' | 'icon' | 'className'> & {
  fileUrl: string;
  fileName: string;
  text?: string;
};

const FileDownloader = (props: FileDownloaderProps) => {
  const { fileUrl, fileName, text, ...buttonProps } = props;

  const handleDownloadFile = async () => {
    downloadFileByUrl(fileUrl, fileName);
  };

  return (
    <Button {...buttonProps} onClick={handleDownloadFile}>
      {text}
    </Button>
  );
};

export default FileDownloader;
