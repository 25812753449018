import Badge from './Badge';
import { PaymentStatusEnum } from '../../enums';
import { BadgeType } from '../../enums/badgeType';
import { toTitleCase } from '../../utils/string';

type Props = {
  status: PaymentStatusEnum;
};

function PaymentStatusBadge(props: Props) {
  const { status } = props;

  let badgeType: BadgeType;

  switch (status) {
    case PaymentStatusEnum.PAID:
      badgeType = BadgeType.GREEN;
      break;
    case PaymentStatusEnum.NOT_PAID:
      badgeType = BadgeType.RED;
      break;
  }

  return <Badge type={badgeType}>{toTitleCase(status.replace('-', ' '))}</Badge>;
}

export default PaymentStatusBadge;
