import {
  CreateProviderBranchDto,
  CreateProviderDto,
  OnboardProviderDto,
  Provider,
  SelfOnboardDto,
  UpdateOnboardingDocumentsDto,
  UpdateProviderBranchDto,
  UpdateProviderDto,
} from '@healthhub/api-lib';
import { MutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  UpdateProviderParams,
  createProvider,
  updateProvider,
  createBranch,
  getAllProviders,
  ProviderParams,
  updateMerchantAgreement,
  markAllProviderNotificationsAsRead,
  updateBranch,
  toggleAutoApproveAppointment,
  UpdateProviderSettingsParams,
  updateProviderSettingsDto,
  UpdateProviderCustomFieldsParams,
  updateProviderCustomFields,
  onboardProvider,
  onboardBranch,
  selfOnboardProvider,
  updateOnboardingProgression,
  uploadOnboardingDocuments,
  createOnboardingSampleAppointment,
  approveOnboarding,
  rejectOnboarding,
  resubmitOnboarding,
} from '../../api/providerService';
import { CustomError } from '../../errors';

export const useCreateProviderMutation = (
  mutationOptions?: MutationOptions<Provider, AxiosError<{ message: string }>, CreateProviderDto>,
) => useMutation(createProvider, mutationOptions);

export const useOnboardProviderMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    { id: string; dto: OnboardProviderDto }
  >,
) => useMutation(({ id, dto }) => onboardProvider(id, dto), mutationOptions);

export const useOnboardBranchMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    { id: string; dto: CreateProviderBranchDto }
  >,
) => useMutation(({ id, dto }) => onboardBranch(id, dto), mutationOptions);

export const useCreateProviderBranchMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    CreateProviderBranchDto
  >,
) => useMutation(createBranch, mutationOptions);

export const useUpdateProviderBranchMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    UpdateProviderBranchDto
  >,
) => useMutation(updateBranch, mutationOptions);

export const useGetProvider = (params: ProviderParams) =>
  useQuery([params?.query], () => getAllProviders(params), {
    enabled: !!params?.query,
  });

export const useUpdateProviderMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    UpdateProviderParams
  >,
) => useMutation(updateProvider, mutationOptions);

export const useUpdateProviderSettingsMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    UpdateProviderSettingsParams
  >,
) => useMutation(updateProviderSettingsDto, mutationOptions);

export const useUpdateProviderCustomFieldsMutation = (
  mutationOptions?: MutationOptions<
    Provider,
    AxiosError<{ message: string }>,
    UpdateProviderCustomFieldsParams
  >,
) => useMutation(updateProviderCustomFields, mutationOptions);

export const useUpdateMerchantAgreement = (
  mutationOptions?: MutationOptions<Provider, AxiosError<CustomError>, { id: number }>,
) => useMutation(updateMerchantAgreement, mutationOptions);

export const useMarkAllProviderNotificationsAsRead = (
  mutationOptions?: MutationOptions<void, AxiosError<CustomError>>,
) => useMutation(markAllProviderNotificationsAsRead, mutationOptions);

export const useToggleAutoApproveAppointment = (
  mutationOptions?: MutationOptions<Provider, AxiosError<CustomError>, string>,
) => useMutation(toggleAutoApproveAppointment, mutationOptions);

export const useSelfOnboardProviderMutation = (
  mutationOptions?: MutationOptions<Provider, AxiosError<{ message: string }>, SelfOnboardDto>,
) => useMutation(selfOnboardProvider, mutationOptions);

export const useUpdateOnboardingProgressionMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, void>,
) => useMutation(updateOnboardingProgression, mutationOptions);

export const useUploadOnboardingDocumentsMutation = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    UpdateOnboardingDocumentsDto
  >,
) => useMutation(uploadOnboardingDocuments, mutationOptions);

export const useCreateOnboardingSampleAppointmentMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, void>,
) => useMutation(createOnboardingSampleAppointment, mutationOptions);

export const useApproveOnboardingMutation = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    { id: number; dto: UpdateProviderDto }
  >,
) => useMutation(({ id, dto }) => approveOnboarding(id, dto), mutationOptions);

export const useRejectOnboardingMutation = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    { id: number; reason: string }
  >,
) => useMutation(({ id, reason }) => rejectOnboarding(id, reason), mutationOptions);

export const useResubmitOnboardingMutation = (
  mutationOptions?: MutationOptions<
    void,
    AxiosError<{ message: string }>,
    { id: number; reasons: string[] }
  >,
) => useMutation(({ id, reasons }) => resubmitOnboarding(id, reasons), mutationOptions);
