import { useEffect, useMemo, useState } from 'react';

import { PlusIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import DeleteApiFieldModal from './DeleteApiFieldModal';
import { Routes } from '../../enums';
import { useGetAllApiFields, useToggle } from '../../hooks';
import { ApiFields } from '../../types/eshop';
import Button from '../Button';
import Table, { TableColumn } from '../Table';
import TableDeleteButton from '../TableDeleteButton';
import TableEditButton from '../TableEditButton';

function ApiFieldsList() {
  const { data: apiFields = [], isLoading: isApiFieldsLoading } = useGetAllApiFields();
  const router = useRouter();

  const { isOpen: isDeleteModalOpen, handleToggle: toggleDeleteModal } = useToggle(false);
  const [selectedRow, setSelectedRow] = useState<ApiFields>();

  const handleActionEdit = (rowData: ApiFields) => () => {
    router.push(`${Routes.API_FIELDS}/${rowData.id}/edit`);
  };

  const columns: TableColumn<ApiFields>[] = useMemo(
    () => [
      {
        header: 'Name',
        key: 'name',
        className: 'w-12',
        render: (data) => (
          <div
            className={clsx(
              'text-sm font-semibold text-gray-600',
              !data.isActive && 'text-opacity-60',
            )}
          >
            {data.name}
          </div>
        ),
      },
      {
        header: 'Action',
        key: 'action',
        className: 'w-[60px]',
        render: (data) => (
          <div className="flex flex-row gap-4">
            <TableEditButton onClick={handleActionEdit(data)} />
            <TableDeleteButton className="text-red-500" onClick={handleDeleteApiField(data)} />
          </div>
        ),
      },
    ],
    [apiFields],
  );

  useEffect(() => {
    if (apiFields) {
      setMutableApiFields(apiFields);
    }
  }, [apiFields]);

  const [mutableApiFields, setMutableApiFields] = useState<ApiFields[]>(apiFields);

  const handleAddCategory = () => {
    router.push(`${Routes.API_FIELDS}/add`);
  };

  const handleDeleteApiField = (apiField: ApiFields) => () => {
    setSelectedRow(apiField);
    toggleDeleteModal();
  };

  const handleCloseDeleteUserModal = () => {
    setSelectedRow(undefined);
    toggleDeleteModal();
  };

  return (
    <div>
      <div className="mb-6 mt-4 flex flex-row justify-between sm:flex sm:items-center">
        <div>
          <h1 className="text-4 text-2xl font-semibold">API Fields</h1>
        </div>
        <Button
          variant="primary"
          className="flex gap-2 px-[14px] py-[10px] font-normal"
          onClick={handleAddCategory}
        >
          <PlusIcon height={18} width={18} className="mr-2" /> Add new API
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col rounded-lg bg-white">
          <Table
            columns={columns}
            data={mutableApiFields}
            isLoading={isApiFieldsLoading}
            noDataText="No APIs found."
          />
        </div>
      </div>
      {selectedRow && (
        <DeleteApiFieldModal
          apiField={selectedRow}
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteUserModal}
        />
      )}
    </div>
  );
}

export default ApiFieldsList;
