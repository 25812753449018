import { clsxMerge } from '../utils';

type Props = {
  label: string;
  className?: string;
};

function DiscountBadge(props: Props) {
  return (
    <div
      className={clsxMerge(
        'py-0.5w w-fit items-center justify-center gap-2 rounded-br rounded-tl bg-orange-500 px-1.5',
        props.className || '',
      )}
    >
      <div className="text-xs font-medium leading-none tracking-tight text-white md:text-sm">
        {props.label}
      </div>
    </div>
  );
}

export default DiscountBadge;
