import { twMerge } from 'tailwind-merge';

import { Message, MessageTypeEnum } from '../../types';
import { firebaseTimestampToDate } from '../../utils';

type Props = {
  message: Message;
  prevMessage: Message;
  nextMessage: Message;
  isSentByMe: boolean;
  logoDisplay: string;
  senderUserId: string;
};

const MessageDisplay = (props: Props) => {
  const { message, isSentByMe, logoDisplay, senderUserId, prevMessage, nextMessage } = props;

  const isPrevMessageSentByMe = prevMessage?.senderUserId === senderUserId && !prevMessage.type;
  const isNextMessageSentByMe = nextMessage?.senderUserId === senderUserId && !nextMessage.type;

  const messageContainerClass = isSentByMe ? 'justify-end text-end' : 'justify-start text-start';
  const messageContainerMarginClass =
    prevMessage && prevMessage.senderUserId !== message.senderUserId ? 'mt-4' : '';
  const messageClass = isSentByMe ? 'bg-primary-300 text-white' : 'bg-gray-200';
  const borderRadiusClass = isSentByMe
    ? `rounded-sm rounded-tl-xl rounded-bl-xl ${
        prevMessage && isPrevMessageSentByMe && !prevMessage.type ? '' : 'rounded-tr-xl'
      } ${nextMessage && isNextMessageSentByMe && !nextMessage.type ? '' : 'rounded-br-xl'}`
    : `rounded-sm rounded-tr-xl rounded-br-xl ${
        prevMessage && !isPrevMessageSentByMe && !prevMessage.type ? '' : 'rounded-tl-xl'
      } ${nextMessage && !isNextMessageSentByMe && !nextMessage.type ? '' : 'rounded-bl-xl'}`;

  if (message.type === MessageTypeEnum.HealthhubSystemMessage) {
    return (
      <div
        key={message.id}
        title={firebaseTimestampToDate(message.createdAt).toLocaleString()}
        className={twMerge('my-4 flex justify-center')}
      >
        <div className={twMerge('w-full rounded-xl bg-gray-300 px-4 py-3 text-center')}>
          {message.value}
        </div>
      </div>
    );
  }

  const shouldDisplayAvatar =
    !isSentByMe &&
    (!nextMessage || nextMessage.senderUserId !== message.senderUserId || !!nextMessage.type);

  return (
    <div
      key={message.id}
      className={twMerge('my-1 flex', messageContainerClass, messageContainerMarginClass)}
    >
      <div className="mb-1 mr-1 flex w-8 items-end">
        {shouldDisplayAvatar && (
          <img src={logoDisplay} alt="Avatar" className="h-8 w-8 rounded-full" />
        )}
      </div>
      <div
        title={firebaseTimestampToDate(message.createdAt).toLocaleString()}
        className={twMerge(
          'max-w-[80%] px-3 py-2 md:max-w-[70%] md:px-4 md:py-2',
          messageClass,
          borderRadiusClass,
        )}
      >
        {message.value}
      </div>
    </div>
  );
};

export default MessageDisplay;
