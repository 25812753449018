import { ProviderService, ProviderServiceAvailabilityEnum } from '@healthhub/api-lib';
import { useQueryClient } from '@tanstack/react-query';

import toast from './toast';
import ToggleButton from './ToggleButton';
import { GET_ALL_PROVIDER_SERVICE_BY_USER_ID } from '../constants';
import { useUpdateBranchServiceAvailability } from '../hooks/mutations/providerServiceMutation';
import { useToggle } from '../hooks/queries';

type Props = {
  branchId: string;
  branchService?: ProviderService;
  parentService: ProviderService;
};

const { Available, Unavailable } = ProviderServiceAvailabilityEnum;

function BranchAvailabilityToggle(props: Props) {
  const { branchService, parentService, branchId } = props;

  const queryClient = useQueryClient();

  const { isOpen: hasAccess, handleToggle: toggle } = useToggle(
    branchService?.availability === Available,
  );

  const { mutate: updateBranchAvailability, isLoading } = useUpdateBranchServiceAvailability({
    onSuccess: () => {
      toast({ message: 'Branch service availability updated', type: 'success' });
      toggle();
      queryClient.invalidateQueries([GET_ALL_PROVIDER_SERVICE_BY_USER_ID]);
    },
    onError: () => {
      toast({
        message: 'Something went wrong',
        type: 'error',
      });
    },
  });

  function handleToggle() {
    const newAvailability = hasAccess ? Unavailable : Available;

    updateBranchAvailability({
      id: parentService.id.toString(),
      dto: {
        availability: newAvailability,
        branchId: branchId,
      },
    });
  }

  return <ToggleButton onToggle={handleToggle} isToggled={hasAccess} isDisabled={isLoading} />;
}

export default BranchAvailabilityToggle;
