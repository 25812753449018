import React from 'react';

import { UserIcon } from '@heroicons/react/20/solid';

type Props = {
  textColor?: string;
  bgColor?: string;
};

const UserIconCircle = ({ textColor = 'text-disabled', bgColor = 'bg-chineseWhite' }: Props) => (
  <div className={`flex h-10 w-10 items-center justify-center rounded-full ${bgColor}`}>
    <UserIcon height={20} width={20} className={textColor} />
  </div>
);

export default UserIconCircle;
