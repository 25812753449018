import {
  ClientFavourite,
  ClientFavouriteApi,
  ProviderServiceSettings,
  ProviderServiceSettingsApi,
  RemoveFavouriteDto,
  SaveFavouriteDto,
  UpdateProviderServiceSettingsDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';

const clientFavouriteApi = HealthHubApiClient.use(ClientFavouriteApi);

export const getClientFavouriteByClientProfileId = async (
  config?: AxiosRequestConfig,
): Promise<ClientFavourite> => {
  try {
    const { data } = await clientFavouriteApi.clientFavouriteControllerGetFavourites(config);

    return data;
  } catch (error) {
    throw new Error('Failed to fetch client favorites');
  }
};

export const removeClientFavourite = async (
  removeFavouriteDto: RemoveFavouriteDto,
): Promise<ClientFavourite> => {
  try {
    const response =
      await clientFavouriteApi.clientFavouriteControllerDeleteFavourite(removeFavouriteDto);
    return response.data;
  } catch (error) {
    throw new Error('Failed to remove favorite.');
  }
};

export const saveClientFavourite = async (
  saveFavouriteDto: SaveFavouriteDto,
): Promise<ClientFavourite> => {
  try {
    const response =
      await clientFavouriteApi.clientFavouriteControllerCreateFavourite(saveFavouriteDto);
    return response.data;
  } catch (error) {
    throw new Error('Failed to save favorite.');
  }
};
