import { UserProvider } from '@healthhub/api-lib';
import { useQueryClient } from '@tanstack/react-query';

import {
  ActionModal,
  toast,
  ToggleButton,
  Variants,
  useToggle,
  GET_ALL_PROVIDER_USERS,
  useActivateUserProviderMutation,
  useDeactivateUserProviderMutation,
} from '@mwell-healthhub/commons';

type Props = {
  user: UserProvider;
};

function ProviderUserAccessToggle(props: Props) {
  const { user } = props;

  const { isOpen: hasAccess, handleToggle: toggle } = useToggle(!user?.deletedAt);
  const queryClient = useQueryClient();
  const { isOpen: isDeactivateModalOpen, handleToggle: toggleDeactivateModal } = useToggle();

  const { mutate: activateUser } = useActivateUserProviderMutation({
    onSuccess: (_response, userId) => {
      toast({ message: 'User activated', type: 'success' });

      // this is a FE optimization to update state without fetching from BE again
      queryClient.setQueryData<UserProvider[]>(
        [GET_ALL_PROVIDER_USERS],
        (oldUsers) =>
          oldUsers?.map((oldUser) =>
            oldUser.id === userId ? { ...oldUser, deletedAt: undefined } : oldUser,
          ),
      );

      toggle();
    },
  });

  const { mutate: deactivateUser, isLoading: isDeactivatingAccount } =
    useDeactivateUserProviderMutation({
      onSuccess: (_response, userId) => {
        toast({ message: 'User deactivated', type: 'success' });

        // this is a FE optimization to update state without fetching from BE again
        queryClient.setQueryData<UserProvider[]>(
          [GET_ALL_PROVIDER_USERS],
          (oldUsers) =>
            oldUsers?.map((oldUser) =>
              oldUser.id === userId ? { ...oldUser, deletedAt: new Date().toISOString() } : oldUser,
            ),
        );

        toggle();
        toggleDeactivateModal();
      },
    });

  function handleToggle() {
    if (hasAccess) {
      toggleDeactivateModal();
    } else {
      activateUser(user.id);
    }
  }

  function handleDeactivate() {
    deactivateUser(user.id);
  }

  return (
    <>
      {isDeactivateModalOpen && (
        <ActionModal
          variant={Variants.PRIMARY}
          title="Deactivate this account?"
          description="Once deactivated, this account will lose access to the HealthHub Provider portal."
          primaryButtonText="Deactivate"
          isLoading={isDeactivatingAccount}
          onClickPrimaryButton={handleDeactivate}
          onClickSecondaryButton={toggleDeactivateModal}
        />
      )}
      <ToggleButton onToggle={handleToggle} isToggled={hasAccess} />
    </>
  );
}

export default ProviderUserAccessToggle;
