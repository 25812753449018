import React from 'react';

import { twMerge } from 'tailwind-merge';

import { MessageThreadWithMembersAndLastMessage } from '../../types';
import { getThreadLogo, getThreadName, getTimeOnly, firebaseTimestampToDate } from '../../utils';

type Props = {
  thread: MessageThreadWithMembersAndLastMessage;
  isSelected: boolean;
  onSelect: (thread: MessageThreadWithMembersAndLastMessage) => void;
  senderUserId: string;
  isProvider?: boolean;
  isBranch?: boolean;
};

function MessageThreadItem(props: Props) {
  const { thread, isSelected, onSelect, senderUserId, isProvider, isBranch } = props;
  const nameDisplay = getThreadName(thread, senderUserId);
  const logoDisplay = getThreadLogo(thread, senderUserId);
  const lastMessageValue = thread?.lastMessage?.value || 'No conversations yet.';
  const lastMessageTime = getTimeOnly(firebaseTimestampToDate(thread.updatedAt)) || '';

  const userHaveAlreadyReadLastMessage =
    isProvider && !isBranch ? true : thread.lastMessageReadByUserIds.includes(senderUserId);
  const lastMessageClass =
    thread?.lastMessage && !userHaveAlreadyReadLastMessage ? 'font-semibold' : '';
  const blueCircle = !userHaveAlreadyReadLastMessage && (
    <div className="h-4 w-4 rounded-full bg-blue-600"></div>
  );

  return (
    <div
      key={thread.id}
      className={twMerge(
        'shadow-md flex w-full cursor-pointer items-start justify-between gap-2 rounded-md bg-white p-2 py-4 hover:bg-gray-200',
        isSelected ? 'bg-gray-100' : '',
      )}
      onClick={() => onSelect(thread)}
    >
      <div className="flex items-center gap-2">
        <img src={logoDisplay} alt="Avatar" className="h-12 w-12 rounded-full" />
        <div className="w-[50%] grow">
          <div className="line-clamp-2 font-semibold">{nameDisplay}</div>
          <div className={twMerge('line-clamp-3 text-sm text-gray-500', lastMessageClass)}>
            {lastMessageValue}
          </div>
        </div>
      </div>

      {!!thread.lastMessage && (
        <div className="flex w-[60px] flex-col items-center gap-4">
          <div className="text-xs text-gray-500">{lastMessageTime}</div>
          {blueCircle}
        </div>
      )}
    </div>
  );
}

export default MessageThreadItem;
