import { useMemo } from 'react';

import { ProductStatus } from '../../enums';
import { BadgeType } from '../../enums/badgeType';
import { toTitleCase } from '../../utils';
import Badge from '../v2/Badge';

type Props = {
  status: ProductStatus;
};

function ProductStatusBadge(props: Props) {
  const { status } = props;

  const badgeType = useMemo(() => {
    switch (status) {
      case ProductStatus.Active:
        return BadgeType.GREEN;
      case ProductStatus.Inactive:
        return BadgeType.RED;
      case ProductStatus.Pending:
        return BadgeType.YELLOW;
      default:
        return undefined;
    }
  }, [status]);

  return <Badge type={badgeType}>{toTitleCase(status)}</Badge>;
}

export default ProductStatusBadge;
