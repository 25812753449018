import React, { useState } from 'react';

import {
  Appointment,
  ProviderTag,
  ProviderTagCategory,
  UpdateAppointmentDto,
} from '@healthhub/api-lib';
import { Check } from 'iconoir-react';
import isEmpty from 'lodash.isempty';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';
import TextInput from '../TextInput';

type Props = {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  providerTagCategories?: ProviderTagCategory[];
  appointment: Appointment;
  isDisabled?: boolean;
  handleAddTags: (appointmentId: string, updateAppointmentDto: UpdateAppointmentDto) => void;
};

export default function TagUpdatePopUp(props: Props): JSX.Element {
  const { providerTagCategories, handleSearch, appointment, handleAddTags, isDisabled } = props;
  const [selectedTags, setSelectedTags] = useState<ProviderTag[]>(appointment?.providerTags || []);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);

  const handleDone = () => {
    setOpenPopUp(false);
    const tagIds = selectedTags.map((tag) => tag.id.toString());
    handleAddTags(appointment.id.toString(), { tagIds: tagIds });
  };

  const isSelected = (tag: ProviderTag): boolean => {
    return !isEmpty(selectedTags.find((selectedTags) => selectedTags.id === tag.id));
  };

  const handleClickTag = (tag: ProviderTag) => {
    if (!isSelected(tag)) {
      setSelectedTags((prev) => [...prev, tag]);
    } else {
      const remainingTags = selectedTags.filter((selectedTag) => selectedTag.id !== tag.id);
      setSelectedTags(remainingTags);
    }
  };

  const handleClear = () => {
    setSelectedTags([]);
  };

  const isEmptyTags =
    providerTagCategories?.length === 0 ||
    providerTagCategories?.every((categories) => categories.providerTags.length === 0);

  const categories = isEmptyTags ? (
    <span className="text-center text-xs text-disabled">
      No tags found. Please go to "Tags" menu
    </span>
  ) : (
    providerTagCategories?.map((categories, categoryIndex) => {
      const tags =
        categories.providerTags.length === 0
          ? null
          : categories.providerTags.map((tag, index) => {
              return (
                <div
                  key={`tag-${tag}${index}`}
                  onClick={() => handleClickTag(tag)}
                  className="flex cursor-pointer items-center justify-between rounded-md px-2 py-1 text-sm font-normal text-neutral-600 hover:bg-neutral-300"
                >
                  <span>{tag.tag}</span>
                  {isSelected(tag) && <Check className="text-sm text-neutral-500" />}
                </div>
              );
            });

      if (tags === null) {
        // Skip rendering this category if it has no tags
        return null;
      }

      return (
        <div
          key={`category-${categories.category}${categoryIndex}`}
          className="border-b-1 flex flex-col border border-x-0 border-t-0 border-neutral-300"
        >
          <div className="flex items-center px-[12px] py-[5px]">
            <label className="grow text-sm font-medium capitalize text-primary-500">
              {categories.category}
            </label>
          </div>
          <div
            className={twMerge(
              'flex transform flex-col space-y-[5px] px-[10px] py-[10px] capitalize',
            )}
          >
            {tags}
          </div>
        </div>
      );
    })
  );

  const hasTags = !!appointment?.providerTags?.length;

  return (
    <div className="relative">
      <Button
        variant="plainNoOutline"
        className="h-auto p-0 font-medium underline md:h-full"
        onClick={() => setOpenPopUp((prev) => !prev)}
        size="sm"
        disabled={isDisabled}
      >
        {hasTags ? 'Edit Tags' : 'Add Tags'}
      </Button>
      {openPopUp && (
        <div className="absolute right-0 top-10 z-50 flex max-h-80 w-[85vw] flex-col space-y-[10px] rounded-md border border-neutral-300 bg-neutral-50 p-3 drop-shadow-md sm:w-72 md:right-0">
          <TextInput placeholder="Search Tag" onChange={handleSearch} />
          <div className="flex h-[70%] flex-col overflow-x-auto">{categories}</div>
          <div className="flex items-center space-x-2">
            <Button variant="outlined" className="w-1/2 whitespace-nowrap" onClick={handleClear}>
              Clear All
            </Button>
            <Button variant="primary" className="w-1/2" onClick={handleDone}>
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
