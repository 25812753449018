/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef, useState } from 'react';

import { UserProvider } from '@healthhub/api-lib';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import CsvDownloader from 'react-csv-downloader';
import { DateObject } from 'react-multi-date-picker';

import {
  ALL,
  DATE_AND_TIME,
  ORDER_STATUSES,
  PLAIN_DATE_FORMAT,
  SHOW_ESHOP_V2,
} from '../../constants';
import { OrderByEnum, OrderStatusEnum, PaymentStatusEnum, Routes } from '../../enums';
import {
  useGetAllOrdersQuery,
  useGetOrderCounts,
  useQueryParamState,
  useRouter,
} from '../../hooks';
import { Option, TransactionMerchant } from '../../types';
import {
  addInt,
  clsxMerge,
  convertObjectsToOptions,
  formatToHumanReadable,
  formatToPeso,
  getVariantLabel,
  multiplyInt,
  sortByKey,
  subtractInt,
  toTitleCase,
  truncateLastChars,
  truncateMiddle,
} from '../../utils';
import { searchValidator } from '../../validators';
import Button from '../Button';
import CustomDatePicker from '../DatePicker';
import Dropdown from '../Dropdown';
import OperationsPagination from '../OperationsPagination';
import OrderStatistics from '../OrderStatistics';
import SearchInput from '../SearchInput';
import Table, { TableColumn } from '../Table';
import { OrderStatusBadge, PaymentStatusBadge } from '../v2';

const PAYMENT_STATUSES = [
  { name: 'Paid', id: PaymentStatusEnum.PAID },
  { name: 'Not Paid', id: PaymentStatusEnum.NOT_PAID },
];
const LIMITS = [
  { name: '25 Entries', id: 25 },
  { name: '50 Entries', id: 50 },
  { name: '100 Entries', id: 100 },
];

type Props = {
  isLoadingUsers?: boolean;
  merchantEmail?: string;
  merchants?: UserProvider[];
};

const transformToExportedData = (data: TransactionMerchant[]) => {
  let rowNumber = 1;

  return data
    .map(
      (order, idx) =>
        order.transactionMerchantProducts?.map((tran, index) => {
          const transaction = data.filter(
            (dataOrder) => dataOrder.transaction.id === order.transaction.id,
          );
          const findIndex = transaction.findIndex((dataOrder) => dataOrder.id === order.id);

          const buyerDetails = tran.buyerDetails ?? order.transaction.buyerDetails;
          const isFirstOrderProduct = index === 0;
          const isFirstProductOfTransaction = findIndex === 0 && isFirstOrderProduct;
          const orderAmount = multiplyInt(tran.quantity, tran.variant?.price ?? tran.product.price);

          return {
            rowNumber: rowNumber++,
            transactionId: order.id,
            orderId: order.orderId,
            createdAt: formatToHumanReadable(order.transaction.createdAt, DATE_AND_TIME),
            userName: `${buyerDetails.firstName} ${buyerDetails.lastName}`,
            contactNumber: buyerDetails.contactNumber,
            email: buyerDetails.email,
            address: buyerDetails.postalCode
              ? `${buyerDetails.street}, ${buyerDetails.barangayTown}, ${buyerDetails.cityMunicipality}, ${buyerDetails.region}, ${buyerDetails.postalCode}`
              : '',
            birthday: buyerDetails.birthDate
              ? formatToHumanReadable(buyerDetails.birthDate, PLAIN_DATE_FORMAT)
              : '',
            gender: buyerDetails.gender,
            maritalStatus: buyerDetails.maritalStatus,
            purchaseFor: order.transactionMerchantProducts?.some((tran) => tran.isBuyAsGift)
              ? 'As a gift'
              : 'Myself',
            merchantName: order.merchant.username,
            sku: tran.variant?.sku ?? tran.product.productId,
            productName: getVariantLabel(tran),
            price: formatToPeso(tran.variant?.price ?? tran.product.price),
            quantity: tran.quantity,
            transactionFee: isFirstProductOfTransaction
              ? formatToPeso(order.transaction.transactionFee)
              : '',
            deliveryFee: isFirstOrderProduct ? formatToPeso(order.shippingFee ?? 0) : '',
            amount: formatToPeso(orderAmount),
            discount: isFirstOrderProduct ? `-${formatToPeso(order.discount)}` : '',
            totalAmount: formatToPeso(
              subtractInt(
                addInt(
                  orderAmount,
                  isFirstOrderProduct
                    ? addInt(
                        isFirstProductOfTransaction ? order.transaction.transactionFee : 0,
                        order.shippingFee,
                      )
                    : 0,
                ),
                isFirstOrderProduct ? order.discount : 0,
              ),
            ),
            paymentMethod: toTitleCase(order.transaction.paymentMethod),
            paymentReferenceNumber: order.transaction.paymentReferenceNumber,
            paymentStatus: order.transaction.paymentStatus,
            ...(SHOW_ESHOP_V2
              ? {
                  status:
                    order.status === OrderStatusEnum.Shipped
                      ? 'In Transit'
                      : toTitleCase(order.status),
                }
              : {
                  status: toTitleCase(order.status),
                }),
          };
        }),
    )
    .flat();
};

function OrdersList(props: Props) {
  const { isLoadingUsers = false, merchantEmail = '', merchants = [] } = props;
  const isMerchant = !!merchantEmail;
  const router = useRouter();
  const csvRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<OrderStatusEnum>();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<PaymentStatusEnum>();
  const [selectedMerchantEmail, setSelectedMerchantEmail] = useState<string>();
  const [selectedLimit, setSelectedLimit] = useState<number>(25);
  const [searchValidationError, setSearchValidationError] = useState<string>('');
  const [search, setSearch] = useQueryParamState('search');
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateObject[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [selectedOrderBy] = useState<OrderByEnum>(OrderByEnum.Desc);
  const { data: orderCounts } = useGetOrderCounts(merchantEmail);

  const allFilteredQueryParams = cleanUpFilters({
    ...(isMerchant && { merchantEmail }),
    ...(selectedMerchantEmail && { merchantEmail: selectedMerchantEmail }),
    ...(search && { query: search }),
    orderBy: selectedOrderBy,
    paymentStatus: selectedPaymentStatus ?? ALL,
    status: selectedStatus ?? ALL,
    dateFrom: startDate,
    dateTo: endDate,
  });
  const queryParams = {
    ...allFilteredQueryParams,
    limit: selectedLimit,
    page: currentPage,
  };
  const { data: paginatedOrders, isFetching: isGetOrdersLoading } =
    useGetAllOrdersQuery(queryParams);
  const { data: paginatedAllFilteredOrders, isFetching: isGetAllFilteredOrdersLoading } =
    useGetAllOrdersQuery(allFilteredQueryParams);

  const { items: orders = [], totalPages, itemsPerPage } = paginatedOrders ?? {};
  const { items: allFilteredOrders = [] } = paginatedAllFilteredOrders ?? {};

  const sortedOrders = useMemo(() => {
    return orders.length ? sortByKey(orders, 'product.name') : [];
  }, [orders]);

  const columns: TableColumn<TransactionMerchant>[] = useMemo(
    () => [
      {
        header: 'Order Number',
        key: 'orderId',
        render: (data) => truncateMiddle(data.orderId as string),
      },
      {
        header: 'Date Purchased',
        key: 'createdAt',
        render: (data) => formatToHumanReadable(data.transaction.createdAt, DATE_AND_TIME),
      },
      {
        header: 'Customer Name',
        key: 'user.name',
        render: (data) =>
          `${data.transaction.buyerDetails.firstName} ${data.transaction.buyerDetails.lastName}`,
      },
      {
        header: 'Product Name',
        key: 'product.name',
        render: (data) =>
          truncateLastChars(
            data.transactionMerchantProducts?.map((tran) => tran.product.name).join(', ') ?? '',
            20,
          ),
      },
      {
        header: 'Qty',
        key: 'quantity',
        render: (data) =>
          data.transactionMerchantProducts
            ?.map((tran) => tran.quantity)
            .reduce((acc, curr) => acc + curr, 0) ?? 0,
      },
      {
        header: 'Amount',
        key: 'amount',
        render: (data) =>
          formatToPeso(
            addInt(subtractInt(data.totalAmount, data.discount), data.transaction.transactionFee),
          ),
      },
      {
        header: 'Payment Status',
        key: 'paymentStatus',
        render: (data) => <PaymentStatusBadge status={data.transaction.paymentStatus} />,
      },
      {
        header: 'Order Status',
        key: 'status',
        render: (data) => <OrderStatusBadge status={data.status} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const orderStatusOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Status' }, ...ORDER_STATUSES],
    {
      includeNone: false,
    },
  );
  const merchantObjects = merchants.map((merchant) => ({
    id: merchant.email,
    name: merchant.username,
  }));
  const merchantOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Merchants' }, ...merchantObjects],
    {
      includeNone: false,
    },
  );
  const limitOptions = convertObjectsToOptions(LIMITS, {
    includeNone: false,
  });
  const paymentStatusOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Payment Status' }, ...PAYMENT_STATUSES],
    {
      includeNone: false,
    },
  );

  const handlePaymentStatusSelection = (paymentStatus: Option) => {
    setCurrentPage(1);
    if (paymentStatus.value === ALL) {
      setSelectedPaymentStatus(ALL as PaymentStatusEnum);
      return;
    }

    setSelectedPaymentStatus(paymentStatus.value);
  };

  const handleLimitSelection = (limit: Option) => {
    setCurrentPage(1);
    setSelectedLimit(limit.value);
  };

  const handleMerchantSelection = (merchant: Option) => {
    setCurrentPage(1);
    if (merchant.value === ALL) {
      setSelectedMerchantEmail(ALL);
      return;
    }

    setSelectedMerchantEmail(merchant.value);
  };

  const handleStatusSelection = (providerStatus: Option) => {
    setCurrentPage(1);
    if (providerStatus.value === ALL) {
      setSelectedStatus(ALL as OrderStatusEnum);
      return;
    }

    setSelectedStatus(providerStatus.value);
  };

  const handleOnRowClick = (data: TransactionMerchant) => {
    router.push(`${Routes.ORDERS}/${data.id}`);
  };

  const debouncedSetSearch = debounce(async (value) => {
    try {
      setSearchValidationError('');
      await searchValidator.validate(value.trim());
      setSearch(value.trim());
    } catch (error: any) {
      setSearchValidationError(error?.message);
    }
  }, 500);

  // Reset the page to 1 if filtered query results to empty and is in another page
  const hasFilters = Boolean(search || selectedStatus);

  if (hasFilters && currentPage !== 1 && sortedOrders.length === 0) {
    setCurrentPage(1);
  }

  // Export
  const isDownloadable = !isGetAllFilteredOrdersLoading && !isEmpty(allFilteredOrders);
  const exportColumnHeaders = [
    { id: 'rowNumber', displayName: 'No' },
    { id: 'transactionId', displayName: 'Order ID' },
    { id: 'orderId', displayName: 'Order Number' },
    { id: 'createdAt', displayName: 'Date Purchased' },
    { id: 'userName', displayName: 'Customer Name' },
    { id: 'contactNumber', displayName: 'Contact No.' },
    { id: 'email', displayName: 'Email' },
    { id: 'address', displayName: 'Address' },
    { id: 'birthday', displayName: 'Birthday' },
    { id: 'gender', displayName: 'Gender' },
    { id: 'maritalStatus', displayName: 'Marital Status' },
    { id: 'purchaseFor', displayName: 'Purchase for' },
    { id: 'merchantName', displayName: 'Merchant Name' },
    { id: 'sku', displayName: 'SKU' },
    { id: 'productName', displayName: 'Product Name' },
    { id: 'price', displayName: 'Price' },
    { id: 'quantity', displayName: 'Qty' },
    { id: 'transactionFee', displayName: 'Transaction Fee' },
    { id: 'deliveryFee', displayName: 'Delivery Fee' },
    { id: 'amount', displayName: 'Amount' },
    { id: 'discount', displayName: 'Discount' },
    { id: 'totalAmount', displayName: 'Total Amount' },
    { id: 'paymentMethod', displayName: 'Payment Method' },
    { id: 'paymentReferenceNumber', displayName: 'Payment Reference No.' },
    { id: 'paymentStatus', displayName: 'Payment Status' },
    { id: 'status', displayName: 'Order Status' },
  ];
  const dataForExport = transformToExportedData(allFilteredOrders);

  const handleExport = () => {
    setIsExporting(true);
    csvRef.current?.handleClick();
    setIsExporting(false);
  };

  const handleClearDateRange = () => {
    setDateRange([]);
    setStartDate(undefined);
    setEndDate(undefined);
    handleSubmitDate([]);
  };

  const handleChangeDate = (date: DateObject[]) => {
    setDateRange(date);
    handleSubmitDate(date);

    if (date === null) {
      handleClearDateRange();
    }
  };

  const handleSubmitDate = (date: DateObject[]) => {
    if (!!date?.[0]?.format().length && !!date?.[1]?.format().length) {
      setDateRange(date);
      const parsedStartDate = date[0].toDate();
      const parsedEndDate = date[1].toDate();
      parsedStartDate.setHours(0, 0, 0, 0);
      parsedEndDate.setHours(23, 59, 59, 999);
      setStartDate(parsedStartDate.toISOString());
      setEndDate(parsedEndDate.toISOString());
    }
  };

  return (
    <div>
      <div className="mb-6 mt-4 flex flex-row justify-between sm:flex sm:items-center">
        <div>
          <h1 className="text-4 text-2xl font-semibold">Orders</h1>
        </div>
      </div>
      <div className="mb-4">
        <OrderStatistics statistics={orderCounts} />
      </div>
      <div className="flex flex-col gap-4">
        <div
          className={clsxMerge('items-end gap-4 space-y-2 lg:flex lg:space-y-0', {
            'relative items-start': !!searchValidationError,
          })}
        >
          <SearchInput
            className="w-full"
            defaultValue={search}
            placeholder="Search name"
            onChange={debouncedSetSearch}
            errorMessage={searchValidationError}
            iconVariant="plain"
          />
          {!isMerchant && (
            <Dropdown
              hasLeftRoundedBorders
              hasRightRoundedBorders
              isLoading={isLoadingUsers}
              placeholder="Select Merchant"
              containerClassname="w-full lg:max-w-[190px]"
              className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
              options={merchantOptions}
              value={merchantOptions.find(
                (merchantOption) =>
                  selectedMerchantEmail && merchantOption.value === selectedMerchantEmail,
              )}
              onChange={handleMerchantSelection}
            />
          )}
          <Button
            variant="primary"
            className="flex w-full gap-2 whitespace-nowrap px-[14px] py-[10px] font-normal lg:max-w-[190px]"
            disabled={!isDownloadable || isExporting}
            isLoading={isExporting}
            onClick={handleExport}
          >
            Export to CSV <ArrowDownTrayIcon height={18} width={18} className="" />
          </Button>
        </div>
        <div className="grid grid-cols-2 items-end gap-x-4 gap-y-2 lg:flex lg:gap-4 lg:space-y-0">
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="No. of Entries"
            containerClassname="w-full"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={limitOptions}
            value={limitOptions.find(
              (limitOption) => selectedLimit && limitOption.value === selectedLimit,
            )}
            onChange={handleLimitSelection}
          />
          <CustomDatePicker
            value={dateRange}
            handleOnChange={handleChangeDate}
            onClear={handleClearDateRange}
            onSubmit={handleSubmitDate}
          />
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Select Payment Status"
            containerClassname="w-full"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={paymentStatusOptions}
            value={paymentStatusOptions.find(
              (paymentStatusOption) =>
                selectedPaymentStatus && paymentStatusOption.value === selectedPaymentStatus,
            )}
            onChange={handlePaymentStatusSelection}
          />
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Select Order Status"
            containerClassname="w-full"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={orderStatusOptions}
            value={orderStatusOptions.find(
              (orderStatusOption) => selectedStatus && orderStatusOption.value === selectedStatus,
            )}
            onChange={handleStatusSelection}
          />
        </div>
        <div className="rounded-lg bg-white">
          <Table
            columns={columns}
            data={orders}
            isLoading={isGetOrdersLoading}
            noDataText="No Orders found."
            onRowClick={handleOnRowClick}
          />
        </div>
      </div>
      <OperationsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        totalItems={sortedOrders.length}
        itemsCount={sortedOrders.length}
      />
      {isDownloadable && (
        <CsvDownloader
          className="hidden"
          columns={exportColumnHeaders}
          datas={dataForExport as any}
          extension=".csv"
          filename="Orders"
          ref={csvRef}
          separator=","
          wrapColumnChar={`"`}
        />
      )}
    </div>
  );
}

function cleanUpFilters(filters: Record<string, string | number | undefined>) {
  const clonedFilters = { ...filters };

  ['paymentStatus', 'status', 'merchantEmail'].forEach((key) => {
    if (clonedFilters[key] === ALL) {
      delete clonedFilters[key];
    }
  });

  return clonedFilters;
}

export default OrdersList;
