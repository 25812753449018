/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';

import { UserProvider } from '@healthhub/api-lib';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';
import { twMerge } from 'tailwind-merge';

import { Button, Kebab, OperationsPagination, useToggle } from '@mwell-healthhub/commons';

import ProductStatusBadge from './ProductStatusBadge';
import VerifyProduct from './VerifyProduct';
import { ALL } from '../../constants';
import { ProductStatus, Routes } from '../../enums';
import {
  useGetAllEshopCategories,
  useGetAllProductsQuery,
  useQueryParamState,
  useRouter,
} from '../../hooks';
import { EshopProduct, Option, OptionWithJsx } from '../../types';
import {
  convertObjectsToOptions,
  formatToPeso,
  getTextFromHtmlString,
  sortByKey,
  truncateLastChars,
} from '../../utils';
import { searchValidator } from '../../validators';
import Dropdown from '../Dropdown';
import SearchInput from '../SearchInput';
import Table, { TableColumn } from '../Table';

const PRODUCT_STATUSES = [
  { name: 'Active', id: ProductStatus.Active },
  { name: 'Inactive', id: ProductStatus.Inactive },
  { name: 'Pending', id: ProductStatus.Pending },
];

type Props = {
  isLoadingUsers?: boolean;
  merchantEmail?: string;
  merchants?: UserProvider[];
};

function ProductsList(props: Props) {
  const { isLoadingUsers = false, merchantEmail = '', merchants = [] } = props;
  const isMerchant = !!merchantEmail;
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedStatus, setSelectedStatus] = useState<ProductStatus>();
  const [selectedMerchantEmail, setSelectedMerchantEmail] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedProducts, setSelectedProducts] = useState<EshopProduct[]>([]);
  const [searchValidationError, setSearchValidationError] = useState<string>('');
  const [search, setSearch] = useQueryParamState('search');
  const { handleToggleOpen: handleReviewOpen, isOpen: isReviewOpen } = useToggle();

  const queryParams = cleanUpFilters({
    ...(search && { query: search }),
    ...(selectedCategory && { categoryId: selectedCategory }),
    merchantEmail: isMerchant ? merchantEmail : selectedMerchantEmail ?? '',
    status: selectedStatus ?? ALL,
    limit: 10,
    page: currentPage,
  });
  const {
    data: paginatedProducts,
    isFetching: isGetAllProductsLoading,
    refetch: refetchProducts,
  } = useGetAllProductsQuery(queryParams);
  const { data: paginatedCategories, isFetching: isGetAllCategoriesLoading } =
    useGetAllEshopCategories();

  const { items: products = [], totalPages, itemsPerPage } = paginatedProducts ?? {};
  const { items: categories = [] } = paginatedCategories ?? {};

  const tableData = products.map((product) => ({
    ...product,
    isSelectEnabled: product.status === ProductStatus.Pending,
  }));
  const sortedProducts = useMemo(() => {
    return products.length ? sortByKey(products, 'name') : [];
  }, [products]);

  const handleActionEdit = (rowData: EshopProduct) => () => {
    router.push(`${Routes.PRODUCTS}/${rowData.id}/edit`);
  };

  const handleAddProduct = () => {
    router.push(`${Routes.PRODUCTS}/add`);
  };

  const columns: TableColumn<EshopProduct>[] = useMemo(
    () => [
      {
        header: 'Product Name',
        key: 'name',
        render: (data) => <div className="text-sm font-semibold text-gray-600">{data.name}</div>,
      },
      {
        header: 'Description',
        key: 'description',
        render: (data) => truncateLastChars(getTextFromHtmlString(data.description)),
      },
      {
        header: 'Category',
        key: 'category',
        render: (data) => data.category?.name,
      },
      {
        header: 'Price',
        key: 'price',
        render: (data) =>
          formatToPeso(data.variants?.length ? data.variants?.[0].price : data.price),
      },
      {
        header: 'Merchant',
        key: 'merchant',
        render: (data) => data.merchant?.username ?? data.merchant?.firstName,
      },
      {
        header: 'Stock',
        key: 'stock',
        render: (data) =>
          data.variants?.length
            ? data.variants?.reduce((acc, variant) => acc + variant.stock, 0)
            : data.stock,
      },
      {
        header: 'Status',
        key: 'status',
        render: (data) => <ProductStatusBadge status={data.status} />,
      },
      {
        header: 'Action',
        key: 'action',
        className: 'w-[60px]',
        render: (data) => {
          const actionOptions: OptionWithJsx[] = [
            {
              label: (
                <span className="relative inline-flex items-center whitespace-nowrap text-sm font-medium text-neutral-600">
                  <PencilIcon height={20} width={20} className="mr-2 text-primary400" /> Edit
                  Product
                </span>
              ),
              value: 'edit',
            },
            ...(data.status === ProductStatus.Pending
              ? [
                  {
                    label: (
                      <span className="relative inline-flex items-center whitespace-nowrap text-sm font-medium text-neutral-600">
                        <ExclamationCircleIcon
                          height={20}
                          width={20}
                          className="mr-2 text-primary400"
                        />{' '}
                        Review Pending Product
                      </span>
                    ),
                    value: 'review',
                  },
                ]
              : []),
          ];

          return (
            <Kebab
              options={actionOptions}
              onChange={(option) => {
                if (option === 'edit') {
                  handleActionEdit(data)();
                } else if (option === 'review') {
                  setSelectedProducts([data]);
                  handleReviewOpen(true);
                }
              }}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const productStatusOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Status' }, ...PRODUCT_STATUSES],
    {
      includeNone: false,
    },
  );
  const merchantObjects = merchants.map((merchant) => ({
    id: merchant.email,
    name: merchant.username,
  }));
  const merchantOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Merchants' }, ...merchantObjects],
    {
      includeNone: false,
    },
  );
  const categoryObjects = categories.map((category) => ({
    id: category.id,
    name: category.name,
  }));
  const categoryOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Categories' }, ...categoryObjects],
    {
      includeNone: false,
    },
  );

  const handleMerchantSelection = (merchant: Option) => {
    setCurrentPage(1);
    if (merchant.value === ALL) {
      setSelectedMerchantEmail(ALL);
      return;
    }

    setSelectedMerchantEmail(merchant.value);
  };

  const handleCategorySelection = (category: Option) => {
    setCurrentPage(1);
    if (category.value === ALL) {
      setSelectedCategory(ALL);
      return;
    }

    setSelectedCategory(category.value);
  };

  const handleStatusSelection = (status: Option) => {
    setCurrentPage(1);
    if (status.value === ALL) {
      setSelectedStatus(ALL as ProductStatus);
      return;
    }

    setSelectedStatus(status.value);
  };

  const debouncedSetSearch = debounce(async (value) => {
    try {
      setSearchValidationError('');
      await searchValidator.validate(value.trim());
      setSearch(value.trim());
    } catch (error: any) {
      setSearchValidationError(error?.message);
    }
  }, 500);

  const handleClearProductSelection = () => {
    setSelectedProducts([]);
  };

  // Reset the page to 1 if filtered query results to empty and is in another page
  const hasFilters = Boolean(search || selectedStatus);

  if (hasFilters && currentPage !== 1 && sortedProducts.length === 0) {
    setCurrentPage(1);
  }

  return (
    <div>
      <div className="mb-6 mt-4 flex flex-row justify-between sm:flex sm:items-center">
        <div>
          <h1 className="text-4 text-2xl font-semibold">Products</h1>
        </div>
        <Button
          variant="primary"
          className="flex gap-2 px-[14px] py-[10px] font-normal"
          onClick={handleAddProduct}
        >
          <PlusIcon height={18} width={18} className="mr-2" /> Add Product
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <div
          className={twMerge(
            'gap-4 space-y-2 lg:flex lg:space-y-0',
            searchValidationError ? 'relative items-start' : 'items-end',
          )}
        >
          <SearchInput
            className="w-full"
            defaultValue={search}
            placeholder="Search name or description"
            onChange={debouncedSetSearch}
            errorMessage={searchValidationError}
            iconVariant="plain"
          />
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Select Category"
            containerClassname="w-full lg:max-w-[200px]"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={categoryOptions}
            isLoading={isGetAllCategoriesLoading}
            value={categoryOptions.find(
              (categoryOption) => selectedCategory && categoryOption.value === selectedCategory,
            )}
            onChange={handleCategorySelection}
          />
          {!isMerchant && (
            <Dropdown
              hasLeftRoundedBorders
              hasRightRoundedBorders
              isLoading={isLoadingUsers}
              placeholder="Select Merchant"
              containerClassname="w-full lg:max-w-[200px]"
              className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
              options={merchantOptions}
              value={merchantOptions.find(
                (merchantOption) =>
                  selectedMerchantEmail && merchantOption.value === selectedMerchantEmail,
              )}
              onChange={handleMerchantSelection}
            />
          )}
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Select Status"
            containerClassname="w-full lg:max-w-[200px]"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={productStatusOptions}
            value={productStatusOptions.find(
              (statusOption) => selectedStatus && statusOption.value === selectedStatus,
            )}
            onChange={handleStatusSelection}
          />
        </div>
        {!!selectedProducts.length && (
          <div className="flex justify-between rounded-lg bg-primary600 bg-opacity-10 px-6 py-3">
            <div className="flex items-center gap-2">
              <Button
                variant="label"
                className="h-fit !p-0 text-neutral-400"
                icon={XMarkIcon}
                onClick={handleClearProductSelection}
              />
              <span className="text-sm font-medium text-primary-400">
                {selectedProducts.length} Product{selectedProducts.length === 1 ? '' : 's'} Selected
              </span>
            </div>
            <Button
              variant="primary"
              className="flex gap-2 px-[14px] py-[10px] font-normal"
              onClick={() => handleReviewOpen(true)}
            >
              Review Pending Product{selectedProducts.length === 1 ? '' : 's'}
            </Button>
          </div>
        )}
        <div className="rounded-lg bg-white">
          <Table
            isMultiSelect
            shouldForceOverflow
            columns={columns}
            data={tableData}
            isLoading={isGetAllProductsLoading}
            noDataText="No Products found."
            selectedRows={selectedProducts}
            onSelectAllClick={(data) => setSelectedProducts(data)}
            onRowSelectClick={(data) => setSelectedProducts((prev) => [...prev, data])}
            onRowDeselectClick={(data) =>
              setSelectedProducts((prev) => prev.filter((p) => p.id !== data.id))
            }
          />
        </div>
      </div>
      <OperationsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        totalItems={sortedProducts.length}
        itemsCount={sortedProducts.length}
      />
      {!!selectedProducts.length && (
        <VerifyProduct
          data={selectedProducts}
          isOpen={isReviewOpen}
          onClose={() => handleReviewOpen(false)}
          onSuccess={() => {
            refetchProducts();
            setSelectedProducts([]);
          }}
        />
      )}
    </div>
  );
}

function cleanUpFilters(filters: Record<string, string | number>) {
  const clonedFilters = { ...filters };

  ['categoryId', 'status', 'merchantEmail'].forEach((key) => {
    if (clonedFilters[key] === ALL) {
      delete clonedFilters[key];
    }
  });

  return clonedFilters;
}

export default ProductsList;
