import { isToday } from 'date-fns';
import { VideoCamera } from 'iconoir-react';

import { formatToHumanReadable, formatToPeso } from '@mwell-healthhub/commons';
import { DoctorServices } from '@mwell-healthhub/commons/enums';
import { Icon as CheckoutIcon } from '@mwell-healthhub/commons/images/checkout-icons';
import { Schedule } from '@mwell-healthhub/commons/types';

import { Icon } from './Icon';

type Props = {
  schedule?: Schedule;
};

export default function NextAvailableSchedule(props: Props) {
  const { schedule } = props;

  const isFaceToFaceService = schedule?.service?.service_item_code === DoctorServices.FACE_TO_FACE;

  if (!schedule?.service || !schedule?.slots || schedule?.slots?.length === 0) {
    return null;
  }

  const nextAvailableSlot = schedule.slots?.[0];
  const formattedDate = formatToHumanReadable(new Date(schedule.schedule_date));
  const formattedDateDisplay = isToday(new Date(schedule.schedule_date)) ? 'Today' : formattedDate;
  const availableSlot = `${formattedDateDisplay} ${nextAvailableSlot.slot_start_time_string} - ${nextAvailableSlot.slot_end_time_string}`;
  const price = formatToPeso(schedule.service.price);

  return (
    <div className="flex flex-row flex-wrap gap-2">
      <div className="flex flex-row items-center gap-2">
        {isFaceToFaceService ? (
          <Icon name="faceToFace" color="#364452" />
        ) : (
          <VideoCamera className="mt-[2px] h-6 w-6 text-gray16" />
        )}
        <p className="text-sm font-semibold text-gray16">
          {isFaceToFaceService ? 'Face-to-face' : 'Teleconsult'}
        </p>
      </div>
      <div className="flex flex-row items-center gap-2">
        <CheckoutIcon name="calendar" color="#364452" />
        <p className="text-sm font-medium text-gray16">{availableSlot}</p>
      </div>
      <div className="flex flex-row items-center gap-2">
        <CheckoutIcon name="money" color="#364452" />
        <p className="text-sm font-medium text-gray16">{price}</p>
      </div>
    </div>
  );
}
