import { setTraceId } from '@healthhub/api-client';
import axios, { AxiosError, AxiosRequestConfig, HttpStatusCode } from 'axios';
import Cookies from 'js-cookie';

import { SHOW_SSO, SSO_TOKEN } from '../constants';
import { PortalEnum, Routes } from '../enums';
import { clearAuthLocalStorage, getUserToken } from '../utils';

const removeTrailingSlash = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url);

export const API_BASE_URL = removeTrailingSlash(
  process.env.NEXT_PUBLIC_API_BASE_URL ??
    process.env.HEALTHHUB_API_BASE_URL ??
    'http://localhost:3333',
);

const portal = removeTrailingSlash(process.env.NEXT_PUBLIC_PORTAL ?? process.env.PORTAL ?? '');

async function interceptErrorResponse(error: AxiosError) {
  if (error.response?.status === HttpStatusCode.Unauthorized) {
    clearAuthLocalStorage();

    if (SHOW_SSO && window.location.pathname.includes(Routes.AUTH)) {
      return Promise.reject(error);
    } else {
      await fetch('/api/logout');
      Cookies.remove(SSO_TOKEN);

      window.location.replace('/auth/login');
    }

    return Promise.reject(error);
  }

  return Promise.reject(error);
}

async function interceptAuthRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  let token;

  if (!token) {
    if (SHOW_SSO && portal === PortalEnum.OPERATION) {
      token = Cookies.get(SSO_TOKEN);
    } else {
      token = (await getUserToken()) as string;
    }
  }

  // By default all cookies will be included in the request
  config.withCredentials = true;

  // User logged in from client-side Provider & Operations Portal
  // so Authorization should come from Firebase ID Token
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };

    config.withCredentials = false;
  }

  setTraceId(config);

  return config;
}

export function createApiClient(baseURL: string) {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use((res) => res, interceptErrorResponse);
  axiosInstance.interceptors.request.use(interceptAuthRequest as any, interceptErrorResponse);

  return {
    use: <ApiType>(api: new (...args: any[]) => ApiType): ApiType =>
      new api(undefined, baseURL, axiosInstance),
  };
}

const HealthHubApiClient = createApiClient(API_BASE_URL || '');

export default HealthHubApiClient;
