import { Controller, useFormContext } from 'react-hook-form';

import Switch from '../../Switch';

function ProviderAutoApproveAppointment() {
  const { control } = useFormContext<{
    isAutoApproveAppointmentEnabled: boolean;
  }>();

  return (
    <div className="rounded-md border border-neutral-300 bg-white px-6 py-4 md:p-8">
      <h2 className="mb-4 text-lg font-semibold">Appointments</h2>
      <div className="flex w-full gap-x-3">
        <Controller
          name="isAutoApproveAppointmentEnabled"
          control={control}
          render={({ field }) => (
            <Switch checked={field.value as boolean} onChange={field.onChange} />
          )}
        />
        <div className="flex w-full flex-col gap-y-2">
          <label className="text-sm leading-6 text-neutral-900">Auto-approve appointments</label>
          <span className="text-sm text-neutral-500">
            All appointments will require approval before they’re confirmed
          </span>
        </div>
      </div>
    </div>
  );
}

export default ProviderAutoApproveAppointment;
