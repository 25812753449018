import { useQuery } from '@tanstack/react-query';

import {
  OrderParams,
  getAllTransactionMerchantProducts,
  getAllTransactionMerchants,
  getTransactionById,
  getTransactionCounts,
} from '../../api';
import {
  GET_ALL_TRANSACTION_MERCHANTS,
  GET_ALL_TRANSACTION_MERCHANT_PRODUCTS,
  GET_TRANSACTION_BY_ID,
  GET_TRANSACTION_COUNTS,
} from '../../constants/queryKeys';

export const useGetAllTransactionMerchantProductsQuery = (
  params: OrderParams,
  isEnabled = true,
) => {
  const {
    query,
    status,
    limit,
    page,
    merchantEmail,
    paymentStatus,
    orderBy,
    productId,
    dateFrom,
    dateTo,
    deliveryDateFrom,
    deliveryDateTo,
  } = params;

  return useQuery(
    [
      GET_ALL_TRANSACTION_MERCHANT_PRODUCTS,
      query,
      status,
      limit,
      page,
      merchantEmail,
      paymentStatus,
      orderBy,
      productId,
      dateFrom,
      dateTo,
      deliveryDateFrom,
      deliveryDateTo,
    ],
    () => getAllTransactionMerchantProducts(params),
    {
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
};

export const useGetAllTransactionMerchantsQuery = (params: OrderParams, isEnabled = true) => {
  const {
    query,
    status,
    limit,
    page,
    merchantEmail,
    paymentStatus,
    orderBy,
    productId,
    dateFrom,
    dateTo,
    deliveryDateFrom,
    deliveryDateTo,
  } = params;

  return useQuery(
    [
      GET_ALL_TRANSACTION_MERCHANTS,
      query,
      status,
      limit,
      page,
      merchantEmail,
      paymentStatus,
      orderBy,
      productId,
      dateFrom,
      dateTo,
      deliveryDateFrom,
      deliveryDateTo,
    ],
    () => getAllTransactionMerchants(params),
    {
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
};

export const useGetTransactionByIdQuery = (id: string) => {
  return useQuery([GET_TRANSACTION_BY_ID, id], () => getTransactionById(id), {
    enabled: !!id,
  });
};

export const useGetTransactionCounts = (merchantEmail?: string) => {
  return useQuery([GET_TRANSACTION_COUNTS, merchantEmail], () =>
    getTransactionCounts(merchantEmail),
  );
};
