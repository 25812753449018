import { NotificationApi } from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';

export const notificationApi = HealthHubApiClient.use(NotificationApi);

export async function markNotificationAsRead(id: number): Promise<void> {
  try {
    await notificationApi.notificationControllerMarkAsRead(id);
  } catch (err) {
    throw new CustomError(err);
  }
}
