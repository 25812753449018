import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { Provider } from '@healthhub/api-lib';
import debounce from 'lodash.debounce';
import uniqBy from 'lodash.uniqby';
import { Controller, useFormContext } from 'react-hook-form';

import { ProviderFormEnum } from '../../../enums';
import { useGetAllProviderTypesQuery, useGetProvider } from '../../../hooks';
import { AutoCompleteOptionType } from '../../../types';
import { generateProviderAutoCompleteOptions } from '../../../utils/generateOptions';
import AutoComplete from '../../AutoComplete/AutoComplete';

import TextInput from '../../TextInput';
import { AddProviderType } from '../ProviderProfile';
import {
  CountryCodeType,
  MobileNumberInput,
  MultiSelectDropdown,
  MultiSelectOptionType,
  SHOW_SMS_NOTIFICATION_CONFIG,
  findCountryCodeFromDialCode,
} from '@mwell-healthhub/commons';
import BannerFormField from '../../ProviderFormFields/BannerFormField';
import LogoFormField from '../../ProviderFormFields/LogoFormField';

type Props = {
  provider?: Provider;
};

function ProviderDetails(props: Props) {
  const { provider } = props;
  const {
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
    watch,
  } = useFormContext<AddProviderType>();

  const legalName = getValues(ProviderFormEnum.NAME);
  const [providerNameQuery, setProviderNameQuery] = useState<string>('');

  const { data: providerTypes = [] } = useGetAllProviderTypesQuery();
  const { data: paginatedProviderList, isLoading } = useGetProvider({
    query: providerNameQuery,
  });
  const [isNewProviderName, setIsNewProviderName] = useState<boolean>(false);
  const [selectedProviderOption, setSelectedProviderOption] = useState<AutoCompleteOptionType>();
  const [hideOptions, setHideOptions] = useState<boolean>(false);

  const { items: providerList = [] } = paginatedProviderList ?? {};
  const uniqueProviderList =
    uniqBy([...providerList, provider as Provider].filter(Boolean), ProviderFormEnum.NAME) || [];

  const debouncedSetProviderNameQuery = debounce((value) => {
    setProviderNameQuery(value);
  }, 600);

  useEffect(() => {
    if (legalName) {
      setProviderNameQuery(legalName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOnProviderTextChange(text: string): void {
    if (hideOptions) {
      setHideOptions(false);
    }
    if (isNewProviderName) {
      setIsNewProviderName(false);
    }
    setSelectedProviderOption(undefined);
    debouncedSetProviderNameQuery(text);
  }

  function handleOnProviderSelect(provider: AutoCompleteOptionType): void {
    setSelectedProviderOption(provider);
    setValue(ProviderFormEnum.NAME, provider.text);
    setProviderNameQuery(provider.text);
    handleFocusToBranch();
  }

  function getProviderOptions(providers: Provider[] = []): AutoCompleteOptionType[] {
    return generateProviderAutoCompleteOptions('id', ['name'], providers);
  }

  const options = isNewProviderName
    ? getProviderOptions([
        { id: providerNameQuery, name: providerNameQuery } as unknown as Provider,
      ])
    : getProviderOptions(uniqueProviderList);

  const matchedProvider = uniqueProviderList.find(({ name }) => name === providerNameQuery);

  const mappedProviderTypes: MultiSelectOptionType[] = useMemo(() => {
    return providerTypes.map((providerType) => ({
      text: providerType?.name,
      value: providerType?.id,
    })) as unknown as MultiSelectOptionType[];
  }, [providerTypes]);

  function handleAdd() {
    const hasNoSelectedProviderOption = !selectedProviderOption;
    if (hasNoSelectedProviderOption && providerNameQuery?.length > 0) {
      setIsNewProviderName(true);
      setValue(ProviderFormEnum.NAME, providerNameQuery);
      setHideOptions(true);

      handleFocusToBranch();
    }
  }

  const handleFocusToBranch = () => {
    const inputElement = document?.querySelector(
      `input[name="${ProviderFormEnum.BRANCH}"`,
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.focus();
    }
  };

  const defaultPhoneCountryCode = findCountryCodeFromDialCode(
    getValues(ProviderFormEnum.COUNTRY_CODE) as string,
  );

  return (
    <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
      <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
        Basic Information
      </span>
      <div className="grid grid-cols-1 gap-4 md:gap-6">
        <Controller
          name={ProviderFormEnum.NAME}
          control={control}
          render={({ fieldState: { error } }) => (
            <div>
              <AutoComplete
                showAdd
                showRequiredOnLabel
                handleAdd={handleAdd}
                hideOptions={hideOptions}
                initialValue={`${matchedProvider?.id || providerNameQuery}`}
                isFetching={isLoading}
                label="Provider's Legal Name"
                onChangeText={handleOnProviderTextChange}
                onSelectedItem={handleOnProviderSelect}
                options={options}
                placeholder="Provider's Legal Name"
              />
              {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
            </div>
          )}
        />
        <Controller
          name={ProviderFormEnum.PROVIDER_TYPES}
          rules={{ required: true }}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="flex flex-col">
              <MultiSelectDropdown
                label="Provider Type"
                placeholder="Select Provider Types"
                options={mappedProviderTypes}
                selectedOptions={value}
                showRequiredOnLabel
                onSelect={onChange}
                onRemove={onChange}
              />
              {error && <p className="mt-1 text-sm text-red-500">This field is required</p>}
            </div>
          )}
        />
      </div>
      {SHOW_SMS_NOTIFICATION_CONFIG && (
        <div className="grid grid-cols-2 gap-6">
          <Controller
            name={ProviderFormEnum.MOBILE_PHONE_NUMBER}
            control={control}
            render={({ field }) => (
              <MobileNumberInput
                label={
                  <>
                    Phone Number <small>(for SMS Notification)</small>
                  </>
                }
                defaultCountryCode={defaultPhoneCountryCode}
                errorMessage={errors?.mobilePhoneNumber?.message}
                value={field.value}
                onChange={field.onChange}
                onChangeCountry={(countryCode: CountryCodeType) => {
                  setValue(ProviderFormEnum.COUNTRY_CODE, countryCode.dial_code);
                }}
              />
            )}
          />
        </div>
      )}
      <div className="mb-5 flex flex-col items-center gap-4 sm:flex-row">
        <LogoFormField />
      </div>
      <div className="mb-0 flex flex-col items-center gap-4 sm:flex-row">
        <BannerFormField />
      </div>
    </div>
  );
}

export default ProviderDetails;
