import { FormEvent, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import Back from './Back';
import Button from './Button';
import { ButtonVariant } from './Button/Button';
import FormPageHeader from './FormPageHeader';

type Props = {
  title: string;
  subtitle?: string | ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  onDelete?: () => void;
  isLoading?: boolean;
  children: React.ReactNode;
  submitButtonText: string;
  backButtonText?: string;
  onClickBackButton?: () => void;
  showBackButton?: boolean;
  deleteButtonText?: string;
  cancelButtonVariant?: ButtonVariant;
  headerClass?: string;
};

function FormPageLayout(props: Readonly<Props>) {
  const {
    title,
    subtitle,
    onCancel,
    onSubmit,
    onDelete,
    children,
    isLoading,
    submitButtonText,
    backButtonText,
    onClickBackButton,
    deleteButtonText = 'Delete',
    showBackButton = true,
    cancelButtonVariant = 'tertiary',
    headerClass = '',
  } = props;

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSubmit?.();
  }

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 md:gap-8">
        {showBackButton && (
          <Back text={backButtonText} type="textIcon" onClick={onClickBackButton} />
        )}
        <FormPageHeader title={title} subtitle={subtitle} headerClass={headerClass} />
        {children}
      </div>
      <div
        className={twMerge('my-8 flex w-full gap-3', onDelete ? 'justify-between' : 'justify-end')}
      >
        {onDelete && (
          <div>
            <Button
              onClick={onDelete}
              variant="outlinedRed"
              className="min-w-[136px] font-medium"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {deleteButtonText}
            </Button>
          </div>
        )}
        <div className="flex gap-x-4">
          {onCancel && (
            <Button
              onClick={handleCancel}
              variant={cancelButtonVariant}
              className="min-w-[136px] font-medium"
            >
              Cancel
            </Button>
          )}
          {onSubmit && (
            <Button
              type="submit"
              variant="primary"
              className="min-w-[136px] font-medium"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {submitButtonText}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}

export default FormPageLayout;
