import { ProviderService, ScheduleResponse } from '@healthhub/api-lib';
import { addMinutes, format, isAfter, isBefore, parse, isEqual } from 'date-fns';
import groupBy from 'lodash.groupby';
import isEmpty from 'lodash.isempty';

import {
  convertIsoFormatTime,
  getDateWithTimeObject,
  getDateWithoutTimezone,
  getTodayUTCPhilippineTime,
} from './date';
import { CALENDAR_DATE_FORMAT, MILITARY_TIME_FORMAT } from '../constants';
import { ScheduleTypeEnum } from '../enums';
import { TimeSlot } from '../types/provider-service-schedule.type';

const today = getTodayUTCPhilippineTime();

function filterTimePastToday(date: string, schedules: ScheduleResponse[]) {
  const isValueArray = Array.isArray(schedules);

  if (!isValueArray) {
    return [];
  }

  return schedules.filter((item) => {
    const { dateWithTimeSlot } = item ?? {};

    if (!dateWithTimeSlot) {
      return false;
    }

    const dateWithoutTimeZone = getDateWithoutTimezone(dateWithTimeSlot);
    const hasTimePassed = isBefore(new Date(dateWithoutTimeZone), today);

    if (hasTimePassed) {
      return false;
    }

    return true;
  });
}

function preFilterSchedules(schedules: ScheduleResponse[]) {
  const blockedDates = schedules.filter((schedule) => !!schedule.isBlocked);
  const availableDates = schedules.filter((schedule) => !schedule.isBlocked);

  return availableDates.filter((schedule) => {
    const { appointmentCount, slots, dateWithTimeSlot } = schedule ?? {};

    const dateWithoutTimeZone = getDateWithoutTimezone(dateWithTimeSlot);

    const isDateBlocked = blockedDates.some((blockedDate) => {
      const { date, startTime, endTime } = blockedDate ?? {};
      const blockedDateWithoutTimeZone = getDateWithoutTimezone(date);
      const newStartTime = convertIsoFormatTime(startTime);
      const newEndtime = convertIsoFormatTime(endTime);

      const startDateTime = getDateWithTimeObject(blockedDateWithoutTimeZone, newStartTime);
      const endDateTime = getDateWithTimeObject(blockedDateWithoutTimeZone, newEndtime);

      const isDateBetween =
        isAfter(dateWithoutTimeZone, startDateTime) && isBefore(dateWithoutTimeZone, endDateTime);

      return isDateBetween;
    });

    if (!appointmentCount || !slots || isDateBlocked) {
      return false;
    }

    const isFull = +appointmentCount >= +slots;

    return !isFull;
  });
}

function formatGroupSchedule(groupedMonthSchedule: Record<string, ScheduleResponse[]>) {
  return Object.entries(groupedMonthSchedule).map(([key, value]) => {
    const schedules = preFilterSchedules(value);
    const newKeyDate = key ? new Date(key) : new Date();
    const formattedKeyDate = format(newKeyDate, CALENDAR_DATE_FORMAT);

    return [formattedKeyDate, schedules];
  });
}

function filterGroupSchedule(formattedGroupedMonthSchedule: (string | ScheduleResponse[])[][]) {
  return formattedGroupedMonthSchedule.filter((item) => {
    const [key, value] = item;
    const values = filterTimePastToday(key as string, value as ScheduleResponse[]);

    return values.length;
  });
}

export function getDaysWithSchedule(schedules?: ScheduleResponse[]) {
  if (!schedules) {
    return [];
  }

  const groupedMonthSchedule = groupBy(schedules, 'startOfDayDate');
  const formattedGroupedMonthSchedule = formatGroupSchedule(groupedMonthSchedule);
  const filteredGroupedMonthSchedule = filterGroupSchedule(formattedGroupedMonthSchedule);
  const filteredGroupedMonthScheduleObject = Object.fromEntries(filteredGroupedMonthSchedule);

  return Object.keys(filteredGroupedMonthScheduleObject);
}

export function generateTimeSlots(interval: number, timeSlots: TimeSlot[]): TimeSlot[] {
  const result: TimeSlot[] = [];

  timeSlots.forEach((slot) => {
    const { start, end } = slot;
    const startTime = parse(start, MILITARY_TIME_FORMAT, new Date());
    let endTime = parse(end, MILITARY_TIME_FORMAT, new Date());

    if (isBefore(endTime, startTime)) {
      // End time is on the next day
      endTime = addMinutes(endTime, 24 * 60); // Add 24 hours to end time
    }

    let currentStartTime = startTime;

    while (isBefore(currentStartTime, endTime)) {
      const nextEndTime = addMinutes(currentStartTime, interval);

      if (!isBefore(nextEndTime, endTime) && !isEqual(nextEndTime, endTime)) {
        break;
      }

      result.push({
        start: format(currentStartTime, MILITARY_TIME_FORMAT),
        end: format(nextEndTime, MILITARY_TIME_FORMAT),
      });

      currentStartTime = nextEndTime;
    }
  });

  return result;
}

export function getCommonSchedules(
  schedules: ScheduleResponse[],
  providerServices: ProviderService[] = [],
): ScheduleResponse[] {
  const filteredProviderServices = providerServices?.filter((service) => !!service?.id) || [];

  // no need to get common if only one provider service is selected
  if (filteredProviderServices.length === 1) {
    return schedules;
  }

  const regularSchedules = schedules.filter(({ type }) => type === ScheduleTypeEnum.REGULAR);
  const customSchedules = schedules.filter(({ type }) => type === ScheduleTypeEnum.CUSTOM);
  const [customServiceIds, regularServiceIds] = filteredProviderServices.reduce(
    ([customIds, regularIds], { id, schedules }) => {
      if (!isEmpty(schedules)) {
        customIds.push(id);
      } else {
        regularIds.push(id);
      }
      return [customIds, regularIds];
    },
    [[], []] as [number[], number[]],
  );

  if (isEmpty(regularSchedules) && !isEmpty(regularServiceIds)) {
    return [];
  }

  if (isEmpty(customSchedules) && !isEmpty(customServiceIds)) {
    return [];
  }

  const schedulesByServiceIds = groupBy(schedules, 'providerServiceId');
  const groupedSchedules = Object.values(schedulesByServiceIds);

  // Find common schedules using startTime, endTime, and day
  const commonSchedules = groupedSchedules.reduce((common, currentGroup, index) => {
    if (index === 0) return currentGroup;

    return common.filter((commonSchedule) =>
      currentGroup.some(
        (schedule) =>
          schedule.startTime === commonSchedule.startTime &&
          schedule.endTime === commonSchedule.endTime &&
          schedule.day === commonSchedule.day,
      ),
    );
  }, [] as ScheduleResponse[]);

  return commonSchedules;
}
