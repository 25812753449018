import { Fragment } from 'react';

import RadioButton from './RadioButton';
import TextArea from './TextArea';

type Props = {
  id: string;
  isSelected: boolean;
  label: string;
  hasAddtlInfo?: boolean;
  onSelect: (id: string) => void;
  onReasonForCancellationChange?: (id: string) => void;
};

export default function Selector(props: Props) {
  const { id, isSelected, label, hasAddtlInfo, onSelect, onReasonForCancellationChange } = props;

  const handleSelect = () => {
    onSelect(id);
  };

  const handleReasonForCancellationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onReasonForCancellationChange?.(e.target.value);
  };

  return (
    <Fragment>
      <div
        className="flex cursor-pointer items-center justify-between rounded-md border border-neutral-300 p-3"
        onClick={handleSelect}
      >
        <p className="m-0 p-0 text-sm text-neutral-600">{label}</p>
        <RadioButton isSelected={isSelected} />
      </div>
      {isSelected && hasAddtlInfo && (
        <div className="mt-2">
          <TextArea
            className="text-sm"
            onChange={handleReasonForCancellationChange}
            placeholder="We value your feedback..."
          />
        </div>
      )}
    </Fragment>
  );
}
