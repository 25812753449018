import * as yup from 'yup';

import { SHOW_ESHOP_FINANCE, SHOW_ESHOP_PRODUCT_VARIANT, SHOW_ESHOP_V2 } from '../constants';

const variantAttributeValuesSchema = yup.object().shape({
  key: yup.string().required('Attribute key is required.'),
  value: yup.string().required('Attribute value is required.'),
});

const productVariantSchema = yup.object().shape({
  sku: yup.string().required('SKU is required.'),
  price: yup
    .number()
    .typeError('Price must be a number.')
    .required('Price is required.')
    .test('price', 'Price must be greater than 0.', (value) => (value ?? 0) >= 0),
  stock: yup
    .number()
    .typeError('Stock must be a number.')
    .test('stock', 'Stock must be greater than or equal to 0.', (value) => (value ?? 0) >= 0)
    .required('Stock is required.'),
  description: yup.string().required('Description is required.'),
  image: yup.string().nullable(),
  status: yup.string().optional(),
  isDefault: yup.boolean().optional(),
  attributeValues: yup.array().of(variantAttributeValuesSchema),
});

export const getProductSchema = (isOps: boolean) => {
  return yup.object().shape({
    name: yup.string().required('Name is required.'),
    price: yup.number().required('Price is required.'),
    stock: yup.number().required('Stock is required.'),
    maxOrderQuantity: yup
      .number()
      .required('Max order quantity is required.')
      .test('maxOrderQuantity', 'Max order quantity should not exceed stock', function (value) {
        if (this.parent.variantAttributes?.length) {
          return true;
        }

        return value <= this.parent.stock;
      }),
    description: yup.string().required('Description is required.'),
    productId: yup.string().required('SKU is required.'),
    type: yup.string(),
    canBeGifted: yup.boolean(),
    imageUrl: yup.string().required('Image is required.'),
    categoryId: isOps ? yup.string().required('Category is required') : yup.string().optional(),
    merchantEmail: yup.string().required('Merchant is required.'),
    priority: yup.number().optional(),
    ...(SHOW_ESHOP_V2
      ? {
          isFreeShipping: yup.boolean().optional(),
          status: yup.string().optional(),
        }
      : {}),
    transactionFee: SHOW_ESHOP_V2
      ? yup.number().optional()
      : yup.number().required('Transaction Fee is required.'),
    ...(SHOW_ESHOP_FINANCE
      ? {
          vat: yup.number().required('VAT is required.'),
          cwtRate: SHOW_ESHOP_V2
            ? yup.number().optional()
            : yup.number().required('CWT Rate is required.'),
          sfRate: yup.number().required('SF Rate is required.'),
        }
      : {}),
    ...(SHOW_ESHOP_PRODUCT_VARIANT
      ? {
          variantAttributes: yup.array().of(yup.string().required('Attribute is required.')),
          variants: yup.array().of(productVariantSchema),
          bannerUrl: yup.string().required('Banner Image is required.'),
          displayFullGrid: yup.boolean().optional(),
        }
      : {}),
  });
};

export const verifyProductSchema = yup.object().shape({
  status: yup.string().required('Status is required.'),
  categoryId: yup.string().required('Category is required'),
});
