import React from 'react';

import {
  ArrowRightIcon,
  PlusIcon,
  PencilIcon,
  ArrowDownTrayIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';

import { clsxMerge } from '../../utils';
import LoadingSpinner from '../LoadingSpinner';

type Props = {
  children: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  leftIcon?: 'plus' | 'pencil' | 'downTray' | 'copy';
  rightIcon?: boolean;
  loadingText?: string;
  type?: string;
  variant:
    | 'primary'
    | 'secondary'
    | 'secondaryGray'
    | 'secondaryBlue'
    | 'tertiary'
    | 'tertiaryGray'
    | 'link'
    | 'outlinedRed';
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const defaultButtonClasses =
  'py-4 px-5 md:py-2.5 md:px-3.5 w-fit flex items-center justify-center gap-3 rounded-md text-sm font-medium border disabled:cursor-not-allowed';

const BUTTON_VARIANT_CLASSES: Record<string, string> = {
  primary:
    'bg-primary-500 border-primary-500 text-white hover:bg-primary-400 hover:border-primary-400',
  secondary:
    'bg-secondary-300 border-secondary-300 text-primary-500 hover:bg-secondary-400 hover:border-secondary-400 focus:bg-secondary-200 focus:border-secondary-200 focus:shadow-buttonHoveredSecondary',
  secondaryGray:
    'bg-neutral-50 border-neutral-300 text-neutral-500 hover:bg-neutral-300 hover:border-neutral-300 focus:bg-neutral-50 focus:border-neutral-300 focus:shadow-buttonHoveredGray',
  secondaryBlue: 'bg-white border-primary-500 text-primary-500',
  tertiary:
    'border-0 bg-neutral-50 text-primary-500 hover:bg-secondary-300 focus:bg-neutral-50 focus:shadow-buttonHoveredSecondary',
  tertiaryGray:
    'border-0 bg-neutral-50 text-neutral-500 hover:bg-neutral-300 focus:bg-neutral-50 focus:shadow-buttonHoveredGray',
  link: 'p-0 md:p-0 border-0 text-primary-500 hover:text-primary-400 focus:shadow-buttonHoveredSecondary',
  outlinedRed:
    'border border-red-700 bg-white text-red-700 font-normal hover:bg-red-700 hover:text-white disabled:border-gray24 disabled:bg-gray11 disabled:text-gray9',
};

const DISABLED_VARIANT_CLASSES: Record<string, string> = {
  primary: 'disabled:bg-neutral-200 disabled:border-neutral-200 disabled:text-neutral-400',
  secondary: 'disabled:bg-secondary-100 disabled:border-secondary-100 disabled:text-primary-200',
  secondaryGray: 'disabled:bg-neutral-200 disabled:border-neutral-300 disabled:text-neutral-400',
  tertiary: 'disabled:bg-neutral-50 disabled:text-neutral-300',
  tertiaryGray: 'disabled:bg-neutral-50 disabled:text-neutral-300',
  link: 'text-neutral-300 hover:text-neutral-300',
};

const LOADING_VARIANT_CLASSES: Record<string, string> = {
  primary: 'text-white/60 fill-white',
  secondary: 'text-primary-500/60 fill-primary-500',
  secondaryGray: 'text-neutral-500/60 fill-neutral-500',
  tertiary: 'text-primary-500/60 fill-primary-500',
  tertiaryGray: 'text-neutral-500/60 fill-neutral-500',
};

const ICON_VARIANT: Record<string, React.ReactNode> = {
  plus: <PlusIcon height={20} width={20} />,
  pencil: <PencilIcon height={20} width={20} />,
  downTray: <ArrowDownTrayIcon height={20} width={20} />,
  copy: <Square2StackIcon height={20} width={20} />,
};

const Button = (props: Props) => {
  const {
    children,
    className,
    isDisabled,
    isLoading,
    leftIcon = '',
    rightIcon,
    loadingText = 'Loading...',
    type = 'button',
    variant,
    ...extraProps
  } = props;
  const variantClass = BUTTON_VARIANT_CLASSES[variant];
  const loadingVariantClass = LOADING_VARIANT_CLASSES[variant];
  const disabledClass = isDisabled ? DISABLED_VARIANT_CLASSES[variant] : '';
  const iconVariant = ICON_VARIANT[leftIcon];
  const childDisplay = isLoading ? (
    <div className="flex items-center justify-center space-x-3">
      <LoadingSpinner size="md" className={loadingVariantClass} />
      <span>{loadingText}</span>
    </div>
  ) : (
    children
  );

  const isButtonDisabled = Boolean(isDisabled || isLoading);
  const buttonClassName = clsxMerge(defaultButtonClasses, variantClass, disabledClass, className);

  return (
    <button className={buttonClassName} disabled={isButtonDisabled} type={type} {...extraProps}>
      {leftIcon && iconVariant}
      {childDisplay}
      {rightIcon && <ArrowRightIcon height={20} width={20} />}
    </button>
  );
};

export default Button;
