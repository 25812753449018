import * as yup from 'yup';

type PasswordValidationSchemaBuilder = (
  schema: yup.StringSchema,
  email?: string,
) => yup.StringSchema;

interface PasswordCriterion {
  requirementMessage: string;
  validation: PasswordValidationSchemaBuilder;
}

export const passwordValidationComponents: Record<string, PasswordCriterion> = {
  length: {
    requirementMessage: '8 characters.',
    validation: (yup) => yup.min(8, 'Password must be at least 8 characters.'),
  },
  numeric: {
    requirementMessage: 'a number: 0-9',
    validation: (yup) => yup.matches(/[0-9]/, 'Password must contain at least one number.'),
  },
  uppercase: {
    requirementMessage: 'an uppercase letter: A-Z',
    validation: (yup) =>
      yup.matches(/[A-Z]/, 'Password must contain at least one uppercase letter.'),
  },
  special: {
    requirementMessage: 'a special character: @$!%*?&#',
    validation: (yup) =>
      yup.matches(/[@$!%*?&#]/, 'Password must contain at least one special character: @$!%*?&#'),
  },
  lowercase: {
    requirementMessage: 'a lowercase letter: a-z',
    validation: (yup) =>
      yup.matches(/[a-z]/, 'Password must contain at least one lowercase letter.'),
  },
  notSameAsEmail: {
    requirementMessage: 'does not contain part of email',
    validation: (yup, email) =>
      yup.test(
        'notSameAsEmail',
        'Password cannot contain part of the email',
        function (value: string | undefined) {
          const emailValue = email || this?.parent?.email;

          if (!emailValue || !value) {
            return false;
          }

          const emailParts = emailValue.split('@')[0].toLowerCase();
          return !value.toLowerCase().includes(emailParts);
        },
      ),
  },
};

export const confirmPasswordDefault = yup
  .string()
  .oneOf([yup.ref('password')], 'Passwords must match');

export const passwordSchema = {
  password: yup
    .string()
    .defined()
    .when(['isEditing', 'password'], (values: (boolean | string)[], schema) => {
      const [isEditing, password] = values;

      return isEditing && !password
        ? schema // No validation when isEditing is true
        : Object.values(passwordValidationComponents)
            .reduce((acc, criterion) => criterion.validation(acc), yup.string())
            .required('Password is required.'); // Validation when isEditing is false
    }),
  confirmPassword: yup
    .string()
    .when('isEditing', (isEditing: boolean[]) =>
      isEditing[0]
        ? confirmPasswordDefault
        : confirmPasswordDefault.required('Confirm Password is required.'),
    ),
};
