import { ClientFavouriteType } from '@healthhub/api-lib';
import { HeartIcon } from '@heroicons/react/20/solid';
import { HeartIcon as HeartIconOutline } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { ToastContainer, toast as toastify } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import {
  Button,
  GET_CLIENT_FAVOURITE,
  SHOW_PROVIDER_PROFILE_V2,
  clsxMerge,
  toast,
  useRemoveClientFavouriteMutation,
  useRouter,
  useSaveClientFavouriteMutation,
} from '@mwell-healthhub/commons';

import { Routes } from '../../constants';

export type ClientFavouriteData = {
  id: number;
  type: ClientFavouriteType;
};

interface Props extends React.HTMLProps<HTMLButtonElement> {
  isFavourite: boolean;
  favouriteData: ClientFavouriteData;
}

export default function SaveFavouriteButton({ isFavourite, favouriteData, className }: Props) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { mutate: saveClientFavourite, isLoading: isSavingClientFavourite } =
    useSaveClientFavouriteMutation({
      onSuccess: async () => {
        await queryClient.invalidateQueries([GET_CLIENT_FAVOURITE]);
        handleSaveFavouriteToast();
      },
      onError: () => {
        toast({ message: 'Failed to save orovider to favorites', type: 'error' });
      },
    });

  const { mutate: removeClientFavourite, isLoading: isRemovingClientFavourite } =
    useRemoveClientFavouriteMutation({
      onSuccess: async () => {
        await queryClient.invalidateQueries([GET_CLIENT_FAVOURITE]);
      },
      onError: (error) => {
        toast({ message: 'Failed to remove provider from favorites', type: 'error' });
      },
    });

  const handleUpdateClientFavourite = () => {
    if (isFavourite) {
      removeClientFavourite(favouriteData);
    } else {
      saveClientFavourite(favouriteData);
    }
  };

  const handleSaveFavouriteToast = () => {
    toastify.success(
      <div className="flex flex-row items-center justify-between">
        <span className="text-sm text-neutral-50">Saved</span>
        <Button
          variant="plainNoOutline"
          className="h-auto p-0 text-sm font-medium text-secondary-500"
          onClick={() => router.push(Routes.PROFILE_FAVOURITES)}
        >
          View Favorites
        </Button>
      </div>,
      { containerId: 'favorites-toast' },
    );
  };

  return (
    <>
      <ToastContainer
        hideProgressBar
        containerId="favorites-toast"
        icon={<HeartIcon width={20} height={20} className="text-neutral-50" />}
        position="top-center"
        theme="dark"
        closeButton={false}
        enableMultiContainer
      />
      <button
        className={clsxMerge(
          'absolute flex flex-row items-center gap-x-1.5 rounded-md px-3 py-2',
          !isFavourite && SHOW_PROVIDER_PROFILE_V2 ? 'bg-white' : 'bg-[#5C6466]',
          {
            'border border-zinc-200': SHOW_PROVIDER_PROFILE_V2,
            'border-[#DC2626] bg-[#DC2626]': isFavourite,
          },
          className,
        )}
        onClick={handleUpdateClientFavourite}
        disabled={isSavingClientFavourite || isRemovingClientFavourite}
      >
        {isFavourite ? (
          <>
            <HeartIcon width={16} height={16} className="text-neutral-50" />
            <span className="text-xs font-medium text-neutral-50">Saved to Favorites</span>
          </>
        ) : (
          <>
            <HeartIconOutline
              width={16}
              height={16}
              className={SHOW_PROVIDER_PROFILE_V2 ? 'text-gray-600' : 'text-neutral-50'}
            />
            <span
              className={twMerge(
                'text-xs font-medium',
                SHOW_PROVIDER_PROFILE_V2 ? 'text-gray-600' : 'text-neutral-50',
              )}
            >
              Save to Favorites
            </span>
          </>
        )}
      </button>
    </>
  );
}
