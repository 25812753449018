import { ReactNode } from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Cancel } from 'iconoir-react';

import { Button, Modal } from '@mwell-healthhub/commons';

type Props = {
  isOpen: boolean;
  children?: ReactNode;
  onSave: () => void;
  toggle: () => void;
};

const ConfirmReplaceExistingScheduleModal = (props: Props) => {
  const { isOpen, toggle, onSave, children } = props;

  const handleConfirm = () => {
    onSave();
  };

  const handleCancel = (): void => {
    toggle();
  };

  return (
    <Modal open={isOpen} setOpen={toggle} size="lg">
      <div className="flex">
        <ExclamationTriangleIcon className="mr-3 h-10 w-10 rounded-full bg-red-100 p-2 text-red-500" />
        <div className="mt-1 flex-1">
          <div className="flex text-xl font-bold text-gray17">
            <div className="self-stretch font-['Poppins'] text-lg font-semibold leading-7 text-gray-900">
              Replace existing custom schedule of selected service?
            </div>
            <Cancel onClick={toggle} className="cursor-pointer" />
          </div>
          <div>
            <span className="text-sm font-normal leading-tight tracking-tight text-gray-500">
              You already set a custom schedule for your selected service(s):
            </span>
            <span className="ml-2 text-sm font-normal leading-tight tracking-tight text-neutral-900">
              {children}
              <br />
            </span>
            <br />
            <span className="text-sm font-normal leading-tight tracking-tight text-gray-500">
              Are you sure you want to replace the entire existing custom schedule of selected
              service(s)?
            </span>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="mt-6 flex items-center justify-end gap-2">
          <Button variant="plainNoOutline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="error" onClick={handleConfirm}>
            Replace
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmReplaceExistingScheduleModal;
