import { CreateReferralDto, Referral, ReferralsApi, UpdateReferralDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';

export const referralsApi = HealthHubApiClient.use(ReferralsApi);

export type ProviderClientProfileDto = {
  firstName: string;
  lastName: string;
  middleName: string;
  sex: string;
  birthDate: Date;
};

export type GetReferralParams = {
  status: 'incoming' | 'outgoing';
  providerClientProfileId?: string;
  providerClientProfile?: ProviderClientProfileDto;
  providerId: string;
};

export type AppointmentHistoryParams = {
  appointmentId: number;
  providerClientProfileId: number;
};

export async function createReferral(createReferralDto: CreateReferralDto): Promise<Referral> {
  try {
    const response = await referralsApi.referralsControllerCreate(createReferralDto);
    return response.data as Referral;
  } catch (err) {
    throw new CustomError(err);
  }
}

export async function getAllReferrals(
  params: GetReferralParams,
  config?: AxiosRequestConfig,
): Promise<Referral[]> {
  try {
    const response = await referralsApi.referralsControllerFindAll(
      +params?.providerId,
      params?.status,
      params?.providerClientProfileId,
      params?.providerClientProfile?.firstName,
      params?.providerClientProfile?.lastName,
      params?.providerClientProfile?.middleName,
      params?.providerClientProfile?.sex,
      params?.providerClientProfile?.birthDate?.toString(),
      config,
    );
    return response.data as Referral[];
  } catch (err) {
    throw new CustomError(err);
  }
}

export async function getReferralById(id: string, config?: AxiosRequestConfig): Promise<Referral> {
  try {
    const response = await referralsApi.referralsControllerFindOne(id, config);
    return response.data as Referral;
  } catch (err) {
    throw new CustomError(err);
  }
}

export async function updateReferralById(
  id: string,
  updateReferralDto: UpdateReferralDto,
): Promise<Referral> {
  try {
    const response = await referralsApi.referralsControllerUpdate(id, updateReferralDto);
    return response.data as Referral;
  } catch (err) {
    throw new CustomError(err);
  }
}
