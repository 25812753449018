/* eslint-disable @next/next/no-img-element */
import { Address, ClientFavourite, ClientFavouriteType } from '@healthhub/api-lib';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ChatBubbleOvalLeftIcon,
} from '@heroicons/react/24/outline';

import {
  SHOW_PROVIDER_ADDRESS_DISTANCE,
  clsxMerge,
  formatCompleteAddress,
  MultiSelectOptionType,
  SHOW_FAVOURITES,
  Button,
  SHOW_MESSAGE_THREADS,
} from '@mwell-healthhub/commons';

import { useGetUser } from '../../../hooks/user/useGetUser';
import { ShareProviderButton, ViewInMapButton } from '../../atoms';
import { SaveFavouritesButton } from '../../molecules';

type Props = {
  providerId?: number;
  address?: Address;
  bannerUrl?: string;
  branch?: string;
  distance?: string;
  displayName?: string;
  officeHours?: string | null;
  slugName?: string;
  clientFavourite?: ClientFavourite;
  acceptedHmos?: MultiSelectOptionType[];
  onMessageProvider?: () => void;
  isMessageButtonLoading?: boolean;
};

function InfoCard(props: Readonly<Props>) {
  const {
    providerId,
    address,
    bannerUrl,
    branch,
    distance,
    displayName,
    officeHours,
    slugName,
    clientFavourite,
    acceptedHmos = [],
    onMessageProvider,
    isMessageButtonLoading,
  } = props;
  const { isUserLoggedIn, user } = useGetUser();

  const isProviderClientFavourite =
    clientFavourite?.providers?.some((p) => p.id === providerId) ?? false;

  const formattedAddress = formatCompleteAddress(address);

  const hasHmoPartners = acceptedHmos && !!acceptedHmos.length;

  const isRenderSaveFavouritesButton = isUserLoggedIn && providerId && SHOW_FAVOURITES;

  return (
    <>
      <div className="border-1 flex w-full cursor-pointer flex-col overflow-hidden rounded-lg border border-solid border-neutral-300 shadow-dropdown">
        <div className="relative h-[110px] border-solid bg-gray-200 sm:h-[260px]">
          <img
            className="max-h-[110px] w-full object-cover sm:max-h-[260px]"
            src={bannerUrl}
            alt={displayName ?? ''}
            loading="lazy"
          />
          {isRenderSaveFavouritesButton && (
            <SaveFavouritesButton
              isFavourite={isProviderClientFavourite}
              favouriteData={{
                id: providerId,
                type: ClientFavouriteType.Provider,
              }}
              className="right-2 top-2"
            />
          )}
        </div>
        <div className="text-deepBlue flex flex-col justify-between gap-2 rounded-lg bg-white px-4 py-3">
          <h1 className="text-xl font-semibold text-primary-500">{displayName}</h1>
          <p className="truncate font-medium">{branch}</p>
          {officeHours && <p className="truncate text-sm">{officeHours}</p>}
          <div className="flex flex-col gap-1">
            <p className="text-sm">{formattedAddress}</p>
            {distance && (
              <p
                className={clsxMerge(
                  'truncate text-xs text-neutral-500',
                  !SHOW_PROVIDER_ADDRESS_DISTANCE && 'hidden',
                )}
              >
                {distance} away
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 items-center gap-2">
            {SHOW_MESSAGE_THREADS && !!user && !!onMessageProvider && (
              <Button
                variant="primary"
                className="col-span-2 row-start-1 flex h-auto flex-row gap-x-1.5 whitespace-nowrap px-3 py-2 text-xs"
                onClick={onMessageProvider}
                isLoading={isMessageButtonLoading}
              >
                <ChatBubbleOvalLeftIcon className="inline h-4 w-4" />
                Send Message
              </Button>
            )}
            <ViewInMapButton address={address} />
            <ShareProviderButton slugName={slugName} displayName={displayName} />
          </div>
          {hasHmoPartners && (
            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-neutral-600">HMO/Health Insurance Partners:</span>
              <div className="flex flex-wrap gap-1.5">
                {acceptedHmos.map((hmo) => (
                  <span
                    key={hmo.text}
                    className="whitespace-nowrap rounded-md bg-blue-50 px-3 py-1.5 text-xs font-medium text-primary-500"
                  >
                    {hmo.text}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InfoCard;
