import React from 'react';

import { ChevronLeftIcon } from '@heroicons/react/20/solid';

type OnboardingHeaderProps = {
  onClickBack?: () => void;
};

const OnboardingHeader: React.FC<OnboardingHeaderProps> = (props) => {
  const { onClickBack } = props;

  return (
    <>
      <div className="border border-x-0 border-t-0 p-3">
        <div className="flex items-center">
          <ChevronLeftIcon
            onClick={onClickBack}
            type="onboarding"
            height={24}
            className="text-primary10"
          />
          <div className="flex flex-grow items-center justify-center">
            <h1 className="text-center text-lg font-medium text-primary10">Registration</h1>
          </div>
          <div className="w-6"></div>
        </div>
      </div>
    </>
  );
};

export default OnboardingHeader;
