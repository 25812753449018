import { ValidationError } from 'yup';

export function getErrorMessage(error?: Error, fieldName?: string) {
  if (!error) {
    return '';
  }

  if (!(error instanceof ValidationError)) {
    return '';
  }

  if (typeof fieldName === 'undefined') {
    return '';
  }

  const { inner } = error;
  const fieldError = inner.find((error) => error.path === fieldName);

  return fieldError?.message ?? '';
}
