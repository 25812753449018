import { useMutation } from '@tanstack/react-query';

import { sendVerificationEmail, verifyCode } from '../../api';
import { UseMutationOptionsType } from '../../types';

export const useUserVerificationMutation = (mutationOptions: UseMutationOptionsType) =>
  useMutation(sendVerificationEmail, mutationOptions);

export const useVerifyCodeMutation = (mutationOptions: UseMutationOptionsType) =>
  useMutation(verifyCode, mutationOptions);
