export enum ProductStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum ProductType {
  DIGITAL = 'DIGITAL',
  EMERGENCY = 'EMERGENCY',
  PHYSICAL = 'PHYSICAL',
}

export enum VariantStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  OutOfStock = 'out_of_stock',
}

export enum AspectRatio {
  _1_1 = 1,
  _21_9 = 21 / 9,
}
