import {
  CreateCategoryDto,
  CreateEntityTypeDto,
  EntityType,
  UpdateCategoryDto,
  UpdateEntityTypeDto,
} from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  createCategory,
  updateCategory,
  deleteCategory,
  createEshopCategory,
  updateEshopCategory,
  deleteEshopCategory,
  updateEshopCategoryBatch,
} from '../../api';
import { Category } from '../../types';

export const useCreateCategoryMutation = (
  mutationOptions?: MutationOptions<
    EntityType,
    AxiosError<{ message: string }>,
    CreateEntityTypeDto
  >,
) => useMutation(createCategory, mutationOptions);

export const useUpdateCategoryMutation = (
  mutationOptions?: MutationOptions<
    EntityType,
    AxiosError<{ message: string }>,
    { id: string; updateCategoryDto: UpdateEntityTypeDto }
  >,
) => {
  return useMutation(
    (params) => updateCategory(params.id, params.updateCategoryDto),
    mutationOptions,
  );
};

export const useCategoryDeleteMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, string>,
) => {
  return useMutation((id: string) => deleteCategory(id), mutationOptions);
};

export const useCreateEshopCategoryMutation = (
  mutationOptions?: MutationOptions<Category, AxiosError<{ message: string }>, CreateCategoryDto>,
) => useMutation(createEshopCategory, mutationOptions);

export const useUpdateEshopCategoryMutation = (
  mutationOptions?: MutationOptions<
    Category,
    AxiosError<{ message: string }>,
    { id: string; updateCategoryDto: UpdateCategoryDto }
  >,
) => {
  return useMutation((params) => updateEshopCategory(params), mutationOptions);
};

export const useUpdateEshopCategoryBatchMutation = (
  mutationOptions?: MutationOptions<
    Category[],
    AxiosError<{ message: string }>,
    UpdateCategoryDto[]
  >,
) => {
  return useMutation((categories) => updateEshopCategoryBatch(categories), mutationOptions);
};

export const useCategoryEshopDeleteMutation = (
  mutationOptions: MutationOptions<Category, AxiosError<{ message: string }>, string>,
) => {
  return useMutation((id: string) => deleteEshopCategory(id), mutationOptions);
};
