import { ProviderServiceSettings, UpdateProviderServiceSettingsDto } from '@healthhub/api-lib';
import { updateProviderServiceSettings } from '@mwell-healthhub/commons';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useUpdateProviderServiceSettings = (
  mutationOptions?: MutationOptions<
    ProviderServiceSettings,
    AxiosError<{ message: string }>,
    UpdateProviderServiceSettingsDto
  >,
) => useMutation(updateProviderServiceSettings, mutationOptions);
