/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import {
  ALL,
  DATE_AND_TIME,
  DISCOUNT_QUANTITY,
  DISCOUNTED_FROM_PRICE,
  ORDER_STATUSES,
  SHOW_ESHOP_V2,
} from '../../constants';
import { OrderStatusEnum, ProductType, Routes } from '../../enums';
import { useRouter, useUpdateOrderStatusMutation } from '../../hooks';
import { Option, TransactionMerchant, TransactionMerchantProduct } from '../../types';
import {
  addInt,
  convertObjectsToOptions,
  formatToDecimal,
  formatToHumanReadable,
  formatToPeso,
  getVariantLabel,
  priceToInt,
  subtractInt,
  toTitleCase,
} from '../../utils';
import { formatBirthdate } from '../../utils/generateOptions';
import { orderSchema } from '../../validators';
import Dropdown from '../Dropdown';
import FormPageLayout from '../FormPageLayout';
import Table, { TableColumn } from '../Table';
import TextInput from '../TextInput';
import toast from '../toast';

type Props = {
  order: TransactionMerchant;
  isMerchant?: boolean;
};

function OrderForm(props: Props) {
  const { order, isMerchant = false } = props;
  const router = useRouter();
  const isOps = !isMerchant;

  const { handleSubmit, control, register, formState } = useForm({
    resolver: yupResolver(orderSchema) as unknown as any,
    defaultValues: {
      status: order?.status,
      ...(SHOW_ESHOP_V2
        ? {
            deliveryLink: order?.deliveryLink,
            trackingNumber: order?.trackingNumber,
          }
        : {}),
    },
  });

  const { mutate: updateOrder, isLoading } = useUpdateOrderStatusMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const columns: TableColumn<TransactionMerchantProduct>[] = useMemo(
    () => [
      {
        header: 'Product Name',
        key: 'product.name',
        render: (data) => getVariantLabel(data),
      },
      {
        header: 'SKU',
        key: 'product.productId',
        render: (data) => data.variant?.sku ?? data.product.productId,
      },
      {
        header: 'Qty',
        key: 'quantity',
      },
      {
        header: 'Price',
        key: 'product.price',
        render: (data) => formatToPeso(data.variant?.price ?? data.product.price),
      },
      {
        header: 'Total Amount',
        key: 'totalAmount',
        render: (data) => formatToPeso((data.variant?.price ?? data.product.price) * data.quantity),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const orderStatusOptions = convertObjectsToOptions(ORDER_STATUSES, {
    includeNone: false,
  });

  const onSubmit = (data: Partial<TransactionMerchant>) => {
    const payload = {
      status: data.status,
      deliveryLink: data.deliveryLink,
      trackingNumber: data.trackingNumber,
    };

    updateOrder({ id: order.id, updateTransactionDto: payload });
  };

  function handleSuccess() {
    toast({ message: 'Order updated', type: 'success' });
    router.push(Routes.ORDERS);
  }

  function handleError(error: Error) {
    toast({ message: error.message, type: 'error' });
  }

  const buyerDetails =
    order.transactionMerchantProducts?.[0].buyerDetails ?? order.transaction.buyerDetails;

  const hasPhysicalProducts = order.transactionMerchantProducts?.some(
    (product) => product.product?.category?.productType === ProductType.PHYSICAL,
  );

  const isOrderCancelled = order.status === OrderStatusEnum.Cancelled;

  const getOrderStatusLabel = () => {
    const orderStatus = ORDER_STATUSES.find((orderStatus) => orderStatus.id === order.status);

    return orderStatus?.name ?? '';
  };

  return (
    <FormPageLayout
      isLoading={isLoading}
      title={`Order #${order.orderId}`}
      subtitle={
        <div>
          <div>Transaction ID: {order.id}</div>
          <div>
            Purchase Date: {formatToHumanReadable(order.transaction.createdAt, DATE_AND_TIME)}
          </div>
        </div>
      }
      submitButtonText="Save"
      onSubmit={!isOps ? handleSubmit(onSubmit) : undefined}
    >
      <div className="flex flex-col gap-6 rounded-md">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="flex flex-col gap-4">
            <div className="font-bold">Buyer Details</div>
            <div>
              Full Name: {buyerDetails.firstName} {buyerDetails.middleName} {buyerDetails.lastName}
            </div>
            <div>
              Birthday: {buyerDetails.birthDate ? formatBirthdate(buyerDetails.birthDate) : 'N/A'}
            </div>
            <div>Email: {buyerDetails.email}</div>
            <div>Contact No.: {buyerDetails.contactNumber}</div>
            <div>
              Shipping Address:{' '}
              {buyerDetails.postalCode
                ? `${buyerDetails.street}, ${buyerDetails.barangayTown}, ${buyerDetails.cityMunicipality}, ${buyerDetails.region}, ${buyerDetails.postalCode}`
                : ''}
            </div>
            <div>Gender: {toTitleCase(buyerDetails.gender ?? '')}</div>
            <div>Civil Status: {toTitleCase(buyerDetails.maritalStatus ?? '')}</div>
            <div>
              Purchase for:{' '}
              {order.transactionMerchantProducts?.some((transaction) => transaction.isBuyAsGift)
                ? 'Others'
                : 'Myself'}
            </div>
            {!!buyerDetails.ringDetails && <div>Ring Sizes: {buyerDetails.ringDetails}</div>}
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-bold">Transaction Details</div>
            <div>Payment Reference: {order.transaction.paymentReferenceNumber}</div>
            <div>Payment Method: {toTitleCase(order.transaction.paymentMethod)}</div>
            <div>
              Payment Status: {toTitleCase(order.transaction.paymentStatus.replace('-', ' '))}
            </div>
            <div className="flex items-center gap-1">
              <span className="whitespace-nowrap">Order Status:</span>
              {isMerchant && (
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    const handleStatusSelection = (providerStatus: Option) => {
                      if (providerStatus.value === ALL) {
                        onChange(null);
                        return;
                      }

                      onChange(providerStatus.value);
                    };

                    return (
                      <Dropdown
                        hasLeftRoundedBorders
                        hasRightRoundedBorders
                        placeholder="Select Order Status"
                        containerClassname="w-full"
                        className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                        options={orderStatusOptions}
                        value={orderStatusOptions.find(
                          (orderStatus) => value && orderStatus.value === value,
                        )}
                        onChange={handleStatusSelection}
                      />
                    );
                  }}
                />
              )}
              {isOps && <span>{getOrderStatusLabel()}</span>}
            </div>
            {SHOW_ESHOP_V2 && hasPhysicalProducts && !isOrderCancelled && !isOps && (
              <>
                <TextInput
                  {...register('deliveryLink')}
                  labelShown
                  label="Delivery Link"
                  errorMessage={formState.errors.deliveryLink?.message}
                />
                <TextInput
                  {...register('trackingNumber')}
                  labelShown
                  label="Tracking Number"
                  errorMessage={formState.errors.trackingNumber?.message}
                />
              </>
            )}
            {isOps && (
              <>
                <div>
                  Delivery Link:{' '}
                  <a
                    target="_blank"
                    href={order.deliveryLink}
                    className="text-primary1 hover:underline"
                    rel="noreferrer"
                  >
                    {order.deliveryLink}
                  </a>
                </div>
                <div>Tracking Number: {order.trackingNumber}</div>
              </>
            )}
          </div>
        </div>
        <div className="rounded-lg bg-white">
          <Table columns={columns} data={order.transactionMerchantProducts} />
        </div>
        <div className="flex flex-col items-end gap-4">
          <div>
            Total:{' '}
            {formatToPeso(
              order.transactionMerchantProducts?.reduce(
                (acc, curr) => acc + curr.price * curr.quantity,
                0,
              ) ?? 0,
            )}
          </div>
          {order.discount > 0 && (
            <div>
              Discount {'('}
              {DISCOUNT_QUANTITY} for {formatToDecimal(priceToInt(DISCOUNTED_FROM_PRICE))}
              {')'}: -{formatToPeso(order.discount)}
            </div>
          )}
          <div>Transaction Fee: {formatToPeso(order.transaction.transactionFee)}</div>
          <div>Delivery Fee: {formatToPeso(order.shippingFee ?? 0)}</div>
          <div className="border-0 border-t-2 pt-2 font-bold">
            Total Amount (Vat Inc.):{' '}
            {formatToPeso(
              addInt(
                subtractInt(order.totalAmount, order.discount),
                order.transaction.transactionFee,
              ),
            )}
          </div>
        </div>
      </div>
    </FormPageLayout>
  );
}

export default OrderForm;
