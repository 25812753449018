import { useEffect, useRef, useState } from 'react';

import { EllipsisHorizontalIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import { OptionWithJsx } from '../../types';
import { classNames } from '../../utils';

enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

type Props = {
  className?: string;
  isHorizontal?: boolean;
  isLoading?: boolean;
  label?: string;
  options: OptionWithJsx[];
  value?: OptionWithJsx;
  width?: number;
  onChange: (value: string | number) => void;
};

const Kebab = (props: Props) => {
  const {
    className,
    isHorizontal = false,
    isLoading = false,
    label,
    options,
    value,
    width,
    onChange,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<Position>(Position.RIGHT);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const displayValue = value ? value.label : label;
  const updatedWidth = width ? `w-[${width}px]` : 'w-full';

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  function handleClick() {
    const updatedIsOpen = !isOpen;

    if (updatedIsOpen) {
      const dropdownElement = dropdownRef.current;
      if (!dropdownElement) return;

      const dropdownRect = dropdownElement.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      if (dropdownRect.left + dropdownRect.width / 2 > screenWidth / 2) {
        setDropdownPosition(Position.RIGHT);
      } else {
        setDropdownPosition(Position.LEFT);
      }
    }

    setIsOpen(updatedIsOpen);
  }

  function handleSelect(option: OptionWithJsx) {
    setIsOpen((previous) => !previous);
    onChange(option.value);
  }

  const optionsPosition = dropdownPosition === Position.LEFT ? 'left-0' : 'right-0';
  const renderOptions = isOpen && (
    <div
      className={classNames(
        `shadow-lg absolute z-10 max-h-96 w-60 overflow-auto bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${optionsPosition} mt-2 rounded-lg`,
      )}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
    >
      <div className="" role="none">
        {options.map((option, index) => (
          <div
            key={option.value}
            className="block px-4 py-4 text-sm text-gray-700 hover:cursor-pointer hover:bg-lightGray"
            role="menuitem"
            tabIndex={-1}
            id={`menu-item-${index}`}
            onClick={() => handleSelect(option)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );

  const renderSpinner = isLoading && (
    <div className="pointer-events-none inset-y-0 right-1/2 flex h-11 items-center justify-between rounded-md bg-white p-2 text-primary3">
      <svg
        className="h-5 w-5 animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6s-6-2.691-6-6H6c0 2.21 1.791 4 4 4z"
        ></path>
      </svg>
    </div>
  );

  return (
    <div className={classNames('relative inline-block text-left', className)} ref={dropdownRef}>
      <div className="h-full">
        {isLoading ? (
          renderSpinner
        ) : (
          <button
            type="button"
            className={`inline-flex h-8 items-center justify-between px-3 py-2 text-sm text-gray10 hover:bg-gray-50 ${updatedWidth} rounded-md`}
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={handleClick}
            disabled={isLoading}
          >
            {label && <div className="mr-2 inline-block truncate">{displayValue}</div>}
            {isHorizontal ? (
              <EllipsisHorizontalIcon className="h-5 w-5 text-primary-400" />
            ) : (
              <EllipsisVerticalIcon className="h-5 w-5 text-primary-400" />
            )}
          </button>
        )}
      </div>
      {renderOptions}
    </div>
  );
};

export default Kebab;
