export enum CheckoutProgressSteps {
  BUYER = 'BUYER',
  DETAILS = 'DETAILS',
  SUCCESS = 'SUCCESS',
}

export enum DeliveryOption {
  PICKUP = 'pick-up',
  DELIVERY = 'delivery',
}
