import React, { useEffect, useRef } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import { SCREEN_SIZES } from '../constants';
import { useMediaQuery } from '../hooks';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onCloseSidebar?: () => void;
  onSidebarToggle?: () => void;
};

function ResponsiveSidebar(props: Props) {
  const wrapperRef = useRef(null);
  const { children, isOpen = false, onCloseSidebar, onSidebarToggle } = props;

  useEffect(() => {
    function handleClickOutside(event: any) {
      const didClickOutside =
        (wrapperRef as any).current && !(wrapperRef as any).current.contains(event.target);

      if (didClickOutside && onCloseSidebar) {
        onCloseSidebar();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const isLargeScreenOrWider = useMediaQuery(SCREEN_SIZES.lg);
  const isSmallScreen = !isLargeScreenOrWider;

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'flex flex-col items-end',
        isSmallScreen && 'fixed left-0 top-0 z-50 h-full',
        isSmallScreen && 'bg-neutral-50',
        'border border-y-0 border-l-0 border-r-neutral-300',
        isSmallScreen &&
          `transform transition-transform  ${isOpen ? 'translate-x-0' : '-translate-x-full'}`,
        isLargeScreenOrWider ? 'hidden lg:block' : 'block lg:hidden',
      )}
    >
      {isSmallScreen && (
        <button onClick={onSidebarToggle} className="mr-4 mt-4 p-2 text-lg">
          <XMarkIcon height={24} />
        </button>
      )}
      {children}
    </div>
  );
}

export default ResponsiveSidebar;
