export enum ColorClasses {
  default = 'bg-gray-500',
  info = 'bg-info',
  info2 = 'bg-primary50',
  warn = 'bg-warn',
  success = 'bg-success',
  success2 = 'bg-success2',
  danger = 'bg-red-500',
  partial = 'bg-partial',
  disabled = 'bg-gray36',
}

export enum ColorTextClasses {
  default = 'text-white',
  info2 = 'text-primary400',
  success2 = 'text-success3',
  partial = 'text-partial2',
  disabled = 'text-gray37',
  danger = 'text-red-500',
}

export enum UpcomingPreviousEnum {
  UPCOMING = 'upcoming',
  PREVIOUS = 'previous',
}
