import * as yup from 'yup';

import { CustomServiceScheduleEnum } from '../enums';

const timeSlotSchema = yup.object().shape({
  start: yup.string().required('This field is required'),
  end: yup.string().required('This field is required'),
});

export const serviceScheduleSchema = yup.object().shape({
  [CustomServiceScheduleEnum.BRANCH_SELECTION_TYPE]: yup
    .string()
    .required('This field is required'),

  [CustomServiceScheduleEnum.TIMESLOT_INTERVAL_IN_MINUTES]: yup
    .string()
    .required('This field is required'),

  [CustomServiceScheduleEnum.SERVICES]: yup
    .array()
    .of(
      yup.object().shape({
        text: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .required('This field is required')
    .min(1, 'At least one service is required'),

  [CustomServiceScheduleEnum.SLOTS]: yup
    .number()
    .typeError('This field is required')
    .required('Number of slots is required')
    .min(1, 'Number of slots should be greater than or equal to 1'),

  [CustomServiceScheduleEnum.DAYS]: yup
    .array()
    .of(
      yup.object().shape({
        text: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .required('This field is required')
    .min(1, 'At least one day is required'),

  [CustomServiceScheduleEnum.TIMESLOTS]: yup
    .array()
    .of(timeSlotSchema)
    .min(1, 'At least one time slot is required')
    .test('first-slot-not-empty', 'The first time slot must be filled out', (timeSlots) => {
      if (!timeSlots || timeSlots.length === 0) return false;
      const firstSlot = timeSlots[0];
      return firstSlot.start !== '' && firstSlot.end !== '';
    }),

  [CustomServiceScheduleEnum.PROVIDER_SERVICE_BRANCH_IDS]: yup
    .array()
    .of(
      yup.object().shape({
        text: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .min(1, 'At least one branch must be selected')
    .required('This field is required'),
});
