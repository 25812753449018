import { useEffect, useState } from 'react';

type Platform = 'Windows' | 'macOS' | 'Linux' | 'Android' | 'iOS' | 'Unknown OS';

function useCurrentOS() {
  const [os, setOS] = useState<Platform>('Unknown OS');

  useEffect(() => {
    const documentLoaded = async () => {
      const uaData = (navigator as any).userAgentData;
      if (uaData) {
        const ua = await uaData.getHighEntropyValues(['platform']);
        switch (ua.platform) {
          case 'Windows':
          case 'macOS':
          case 'Linux':
          case 'Android':
          case 'iOS':
            setOS(ua.platform as Platform);
            break;
          default:
            setOS('Unknown OS');
        }
      } else {
        const platform = navigator.platform.toLowerCase();
        if (platform.includes('win')) {
          setOS('Windows');
        } else if (platform.includes('mac')) {
          setOS('macOS');
        } else if (platform.includes('linux')) {
          setOS('Linux');
        } else if (platform.includes('iphone') || platform.includes('ipad')) {
          setOS('iOS');
        } else if (platform.includes('android')) {
          setOS('Android');
        } else {
          setOS('Unknown OS');
        }
      }
    };

    window.addEventListener('DOMContentLoaded', documentLoaded);

    return () => {
      window.removeEventListener('DOMContentLoaded', documentLoaded);
    };
  }, []);

  return os;
}

export default useCurrentOS;
