import { useQuery } from '@tanstack/react-query';

import { getMemberMessageThreads, getMessagesByThreadId, getThread } from '../../api';
import {
  GET_MEMBER_MESSAGE_THREADS,
  GET_MESSAGES_BY_THREAD_ID,
  GET_THREAD,
} from '../../constants/queryKeys';

export const useGetMemberMessageThreadsQuery = (branchId: string, isProvider: boolean) => {
  return useQuery(
    [GET_MEMBER_MESSAGE_THREADS, branchId, isProvider],
    () => getMemberMessageThreads(branchId),
    {
      enabled: !isProvider || (isProvider && !!branchId),
    },
  );
};

export const useGetMessagesByThreadIdQuery = (
  threadId: string,
  branchId: string,
  isProvider: boolean,
) => {
  return useQuery(
    [GET_MESSAGES_BY_THREAD_ID, threadId, branchId, isProvider],
    () => getMessagesByThreadId(threadId, branchId),
    {
      enabled: !!threadId && (!isProvider || (isProvider && !!branchId)),
    },
  );
};

export const useGetThreadQuery = (threadId: string) => {
  return useQuery([GET_THREAD, threadId], () => getThread(threadId));
};
