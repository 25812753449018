import * as yup from 'yup';

import { SHOW_ESHOP_V2 } from '../constants';
import { OrderStatusEnum } from '../enums';

export const orderSchema = yup.object().shape({
  status: yup.mixed<OrderStatusEnum>().oneOf(Object.values(OrderStatusEnum)),
  deliveryLink: yup.string().url('Invalid URL.').optional(),
  trackingNumber: yup.string().optional(),
});

export const categorySchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  productType: SHOW_ESHOP_V2 ? yup.string().required('Type is required.') : yup.string().optional(),
  defaultOrderStatus: yup.string().optional(),
});
