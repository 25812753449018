import { Timestamp } from '@google-cloud/firestore';

export enum MessagingCollections {
  MessageThreads = 'messageThreads',
  RefetchTriggers = 'refetchTriggers',
  Messages = 'messages',
}

export enum MessageTypeEnum {
  HealthhubSystemMessage = 'healthhub_system_message',
}

export enum MessageThreadMemberTypeEnum {
  HealthhubPatient = 'healthhub_patient',
  HealthhubProvider = 'healthhub_provider',
}

export type Message = {
  id: string;
  value: string;
  senderUserId: string;
  type?: MessageTypeEnum;
  createdAt: Timestamp;
  messageThreadId: string;
  metadata?: any;
};

export type MessageThread = {
  id: string;
  lastMessageId?: string;
  lastMessageReadByUserIds: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
  memberIds: string[];
  memberIdsKey: string;
  origin: string;
};

export type MessageThreadWithMembersAndLastMessage = MessageThread & {
  members: any[];
  lastMessage: Message;
};
