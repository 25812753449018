import { useQuery } from '@tanstack/react-query';

import { ProductParams, getAllProducts, getProductById } from '../../api';
import { GET_ALL_PRODUCTS, GET_PRODUCT_BY_ID } from '../../constants/queryKeys';

export const useGetAllProductsQuery = (params: ProductParams) => {
  const { query, status, limit, page, merchantEmail, orderBy, categoryId } = params;

  return useQuery(
    [GET_ALL_PRODUCTS, query, status, limit, page, merchantEmail, orderBy, categoryId],
    () => getAllProducts(params),
  );
};

export const useGetProductByIdQuery = (id: string) => {
  return useQuery([GET_PRODUCT_BY_ID, id], () => getProductById(id), {
    enabled: !!id,
  });
};
