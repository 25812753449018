import React from 'react';
import TextInput from './TextInput/TextInput';

interface InputWithMeasurementProps {
  measurement: string;
}

const InputWithMeasurement: React.FC<InputWithMeasurementProps> = ({ measurement }) => {
  const [value, setValue] = React.useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <TextInput />
    </div>
  );
};

export default InputWithMeasurement;
