import { Upload, Attachment } from 'iconoir-react';

import {
  Button,
  toast,
  useGetAllMedicalRecordFilesByAppointmentIdQuery,
  useRemoveMedicalRecordFileByIdMutation,
  useRouter,
  useToggle,
} from '@mwell-healthhub/commons';
import isEmpty from 'lodash.isempty';
import { twMerge } from 'tailwind-merge';
import ProviderClientMedicalRecordsCreateModal from './ProviderClientMedicalRecordsCreateModal';
import ProviderClientMedicalRecordsRemoveModal from './ProviderClientMedicalRecordsRemoveModal';
import { Appointment, MedicalRecordFile } from '@healthhub/api-lib';
import { useState } from 'react';

type Props = {
  appointment: Appointment;
  isDisabled?: boolean;
};

const ProviderClientMedicalRecordsList = (props: Props) => {
  const { appointment, isDisabled } = props;
  const router = useRouter();
  const isFetching = false;
  const { isOpen: isOpenUploadModal, handleToggle: handleToggleUploadModal } = useToggle();
  const { isOpen: isOpenRemoveModal, handleToggle: handleToggleRemoveModal } = useToggle();
  const { data: medicalRecords } = useGetAllMedicalRecordFilesByAppointmentIdQuery(
    appointment.id.toString(),
  );
  const { mutate: handleRemoveMedicalRecord, isLoading: isRemovingMedicalRecord } =
    useRemoveMedicalRecordFileByIdMutation();
  const [recordToRemove, setRecordToRemove] = useState<MedicalRecordFile | null>(null);

  const handleViewFileClick = (medicalRecord: MedicalRecordFile) => {
    window.open(medicalRecord.file.url, '_blank');
  };

  const handleRemoveFileClick = (medicalRecord: MedicalRecordFile) => {
    setRecordToRemove(medicalRecord);
    handleToggleRemoveModal();
  };

  const handleUploadFileClick = () => {
    handleToggleUploadModal();
  };

  const handleRemoveRecord = () => {
    if (!recordToRemove) {
      return;
    }

    handleRemoveMedicalRecord(
      {
        id: recordToRemove.id.toString(),
      },
      {
        onSuccess: () => {
          toast({
            message: 'Medical record has been removed',
            type: 'success',
          });
          handleToggleRemoveModal();
        },
      },
    );
  };

  const renderProviderClientMedicalRecords = medicalRecords?.map((medicalRecord, index) => {
    return (
      <div
        key={index}
        className={twMerge(
          'flex flex-row items-center justify-between gap-4 p-4 px-6',
          index !== medicalRecords.length - 1 && 'border-b border-gray-300',
        )}
      >
        <div className="flex min-w-0 flex-1 gap-4">
          <Attachment fontSize={14} className="text-gray-400" />
          <span className="text-md min-w-0 flex-1 truncate font-normal">{medicalRecord.name}</span>
        </div>
        <div className="flex items-center divide-x divide-gray-300">
          <button
            className="cursor-pointer pr-4 text-sm font-normal text-primary-500 underline disabled:cursor-not-allowed"
            onClick={() => handleViewFileClick(medicalRecord)}
            disabled={!router.isReady}
          >
            View File
          </button>
          <button
            className="cursor-pointer pl-4 text-sm font-normal text-primary-500 underline disabled:cursor-not-allowed"
            onClick={() => handleRemoveFileClick(medicalRecord)}
            disabled={!router.isReady}
          >
            Remove
          </button>
        </div>
      </div>
    );
  });

  const renderEmptyState = (
    <div className="flex flex-col items-center justify-center p-4">
      <span className="text-sm text-neutral-400">No uploaded records</span>
    </div>
  );

  return (
    <div className="w-full rounded-lg border border-neutral-300 bg-neutral-50">
      <div className="flex flex-row flex-nowrap items-start justify-between gap-4 px-8 pt-6">
        <div className="mt-2 flex flex-col gap-1">
          <span className="text-lg font-semibold">Medical Records</span>
          <span className="text-sm text-gray-400">
            Upload any medical files (ex. documents, x-rays, lab results) if necessary. They'll be
            securely shared with your client through the HealthHub app.
          </span>
        </div>
        <div className="flex items-center">
          <Button
            icon={Upload}
            variant="secondary"
            className="min-w-[150px] !px-5"
            onClick={handleUploadFileClick}
            disabled={!router.isReady || isDisabled}
          >
            Upload File
          </Button>
        </div>
      </div>
      <div className="m-8 rounded-lg border border-neutral-300">
        {isFetching || (isEmpty(medicalRecords) && renderEmptyState)}
        {renderProviderClientMedicalRecords}
      </div>
      {isOpenUploadModal && (
        <ProviderClientMedicalRecordsCreateModal
          appointment={appointment}
          isOpen={isOpenUploadModal}
          toggle={handleToggleUploadModal}
        />
      )}
      {isOpenRemoveModal && (
        <ProviderClientMedicalRecordsRemoveModal
          isLoading={isRemovingMedicalRecord}
          isOpen={isOpenRemoveModal}
          toggle={handleToggleRemoveModal}
          onRemove={handleRemoveRecord}
        />
      )}
    </div>
  );
};

export default ProviderClientMedicalRecordsList;
