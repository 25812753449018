import { Provider, ProviderApi, ProviderService } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';
import { Pagination } from '../types';

const providerApi = HealthHubApiClient.use(ProviderApi);

export type GetProviderServicesParams = {
  query?: string;
  page?: number;
  limit?: number;
  serviceCategoryId?: string;
};

export async function getProviderByIdV2(
  id: number | string,
  config?: AxiosRequestConfig,
): Promise<Provider> {
  try {
    let data;
    if (typeof Number(id) === 'number' && !isNaN(Number(id))) {
      const response = await providerApi.providerV2ControllerFindOne(Number(id), config);
      data = response.data;
    } else {
      const slugName = String(id);
      const response = await providerApi.providerV2ControllerFindOneBySlugName(slugName, config);
      data = response.data;
    }
    return data as Provider;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderServices(
  id: string,
  params: GetProviderServicesParams = { page: 1, limit: 20 },
  config: AxiosRequestConfig = {},
) {
  try {
    const response = await providerApi.providerV2ControllerFindServices(
      +id,
      params?.query || undefined,
      params?.serviceCategoryId || undefined,
      params?.limit,
      params?.page,
      config,
    );

    return response.data as Pagination<ProviderService>;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderServiceCategories(id: number) {
  try {
    const response = await providerApi.providerV2ControllerFindServiceCategories(id);

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}
