import { Referral } from '@healthhub/api-lib';
import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { GetReferralParams, getAllReferrals } from '../../api';
import { GET_ALL_REFERRALS } from '../../constants';

export const useGetAllReferrals = (params: GetReferralParams, config?: AxiosRequestConfig) => {
  return useQuery<Referral[]>(
    [GET_ALL_REFERRALS, params.status, params.providerId, params.providerClientProfileId],
    () => getAllReferrals(params, config),
    {
      enabled: !!params.providerId,
    },
  );
};
