import { HTMLProps, useState } from 'react';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { DocumentTextIcon } from '@heroicons/react/20/solid';

import { Button, Modal, useGetAppointmentReferralFormSignedUrl } from '@mwell-healthhub/commons';
import useIsMobile from '@mwell-healthhub/commons/hooks/useIsMobile';

type Props = HTMLProps<HTMLAnchorElement> & {
  appointmentId?: number;
  referralFormFileId?: number;
};

export default function ViewReferralFormButton({ appointmentId, referralFormFileId }: Props) {
  const isMobile = useIsMobile();
  const { data: appointmentReferralFormFile } = useGetAppointmentReferralFormSignedUrl(
    appointmentId,
    referralFormFileId,
    !!appointmentId && !!referralFormFileId,
  );

  const docs = appointmentReferralFormFile ? [{ uri: appointmentReferralFormFile }] : null;
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const handleCloseModal = () => {
    setIsPreviewModalOpen(false);
  };
  const handleClick = () => {
    if (isMobile) {
      setIsPreviewModalOpen(true);
    } else {
      window.open(appointmentReferralFormFile, '_blank');
    }
  };
  return (
    <>
      {appointmentReferralFormFile && (
        <Button className="h-auto w-full grow py-4" variant="outlinedPrimary" onClick={handleClick}>
          <DocumentTextIcon className="mr-1 h-5 w-5 flex-shrink-0 text-[#054F99]" />
          View Referral Form
        </Button>
      )}
      {isMobile && docs && (
        <>
          <style>
            {`
              #pdf-download {
                display: none;
              }
              #pdf-controls {
                width: 100vw;
                box-shadow: none;
                background-color: #054f99;
              }
            `}
          </style>
          <Modal className="px-0 pt-0" open={isPreviewModalOpen} onClose={handleCloseModal}>
            <div className="flex h-full w-full flex-col gap-4">
              <div className="min-h-[500px] flex-1 bg-gray-100">
                <DocViewer
                  className="h-full w-full"
                  documents={docs}
                  pluginRenderers={DocViewerRenderers}
                  config={{
                    header: {
                      disableHeader: true,
                    },
                  }}
                  style={{
                    overflow: 'auto',
                  }}
                />
              </div>
              <div className="flex-shrink-0 px-4">
                <Button
                  className="w-full"
                  variant="secondary"
                  type="button"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}
