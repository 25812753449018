export enum PaymentStatusEnum {
  PAID = 'paid',
  NOT_PAID = 'not-paid',
}

export enum OrderStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Processing = 'processing',
  Shipped = 'shipped',
  Confirmed = 'confirmed',
}

export enum OrderItemStatus {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  Paid = 'paid',
  Pending = 'pending',
  Shipped = 'shipped',
  ToBeShipped = 'to-be-shipped',
}

export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum PaymentMethodEnum {
  Paymaya = 'PAYMAYA',
}

export enum PaymentTypeEnum {
  PAY_LATER = 'pay-later',
  PAY_NOW = 'pay-now',
  PAY_NOW_FULL = 'pay-now-full',
}

export enum DiscountTypeEnum {
  SENIOR_CITIZEN = 'senior-citizen',
  PWD = 'pwd',
}

export enum ShippingType {
  STANDARD = 'ST',
  SAME_DAY = 'SD',
}
