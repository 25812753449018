import { RefObject, useRef, useState } from 'react';

import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';
import { Controller, useFormContext } from 'react-hook-form';
import { FaSpinner } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

import { uploadFile } from '../../api';
import { FILE_EXCEEDS_10MB_MESSAGE } from '../../constants';
import { ProviderFormEnum } from '../../enums';
import { checkImageSizeExceeds } from '../../utils';
import Button from '../Button';
import FileUpload from '../FileUpload';

function LogoFormField() {
  const { control, setError, clearErrors } = useFormContext<{
    [ProviderFormEnum.LOGO_URL]: string;
  }>();

  const [isUpdatingImage, setIsUpdatingImage] = useState<boolean>(false);
  const uploadProfileRef = useRef<HTMLInputElement>(null);

  const handlePhotoChange = (onChange: (val: string) => void) => async (file: any) => {
    if (!file[0]) {
      return;
    }

    if (checkImageSizeExceeds(file[0].size)) {
      setError(ProviderFormEnum.LOGO_URL, {
        type: 'max',
        message: FILE_EXCEEDS_10MB_MESSAGE,
      });

      return;
    }

    clearErrors(ProviderFormEnum.LOGO_URL);
    setIsUpdatingImage(true);
    const loadUrl = await uploadFile(file[0]);

    setIsUpdatingImage(false);
    onChange(loadUrl.url);
  };

  const handleChangeImage = (inputRef: RefObject<HTMLInputElement>) => () => {
    inputRef?.current?.click();
  };

  const loader = (
    <div className="flex h-[80px] w-[80px] items-center justify-center">
      <FaSpinner className="h-10 w-10 animate-spin self-center text-neutral-500" />
    </div>
  );

  const profileDisplay = isUpdatingImage ? (
    loader
  ) : (
    <BuildingStorefrontIcon className="w-10 text-gray-300" aria-hidden="true" />
  );
  return (
    <Controller
      name={ProviderFormEnum.LOGO_URL}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="col-span-full">
          <div className="flex space-x-1">
            <label htmlFor="photo" className="block text-sm leading-6 text-gray-900">
              Display Photo
            </label>
            <span className="text-red-600"> *</span>
          </div>
          <div className="mt-1 flex justify-center gap-x-4">
            <div className="border-1 flex h-20 w-20 items-center justify-center overflow-hidden rounded-full border border-neutral-300">
              {value ? (
                isUpdatingImage ? (
                  loader
                ) : (
                  <img
                    className="h-20 w-20 bg-gray-300 object-cover object-center"
                    src={value}
                    style={{
                      height: '80px',
                      width: '80px',
                    }}
                    alt="profile-picture"
                  />
                )
              ) : (
                profileDisplay
              )}
            </div>
            <div className="flex flex-col gap-2">
              <FileUpload
                inputRef={uploadProfileRef}
                label="Logo"
                labelClassName={twMerge(
                  'w-fit rounded-md border border-primary-500 bg-white px-3 py-1 text-sm font-normal text-primary-500 hover:bg-gray24 disabled:border-gray24 disabled:bg-gray11 disabled:text-gray9 sm:!ml-0',
                  value && 'hidden',
                )}
                onFileChange={handlePhotoChange(onChange)}
              />
              <div className="flex flex-col space-y-2">
                {value && (
                  <div className="flex items-center space-x-2">
                    <Button onClick={handleChangeImage(uploadProfileRef)} variant="cart">
                      Update
                    </Button>
                  </div>
                )}
                <span className="text-xs text-disabled md:text-sm">
                  For best results, upload a 200px × 200px PNG/JPG/GIF up to 10MB
                </span>
              </div>
            </div>
          </div>
          {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
        </div>
      )}
    />
  );
}

export default LogoFormField;
