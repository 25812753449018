import { EshopApi, UpdateAdminConfigDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { AdminConfig } from '../types';

export const configApi = HealthHubApiClient.use(EshopApi);

export type UpdateAdminConfigParams = {
  id: string;
  updateAdminConfigDto: UpdateAdminConfigDto;
};

export async function getAdminConfig(config?: AxiosRequestConfig): Promise<AdminConfig> {
  try {
    const response = await configApi.eshopControllerGetAdminConfig(config);
    const data = response.data;

    return data as unknown as AdminConfig;
  } catch (error) {
    throw new Error('Failed to fetch admin config');
  }
}

export async function updateAdminConfig(params: UpdateAdminConfigParams): Promise<AdminConfig> {
  const { id, updateAdminConfigDto } = params;

  try {
    const { data } = await configApi.eshopControllerUpdateAdminConfig(id, updateAdminConfigDto);

    return data as unknown as AdminConfig;
  } catch (err) {
    throw new Error('Failed to update admin config');
  }
}
