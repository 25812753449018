import { CreateTagDto, EshopApi, UpdateTagDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash.get';

import HealthHubApiClient from './api-client';
import { TagType } from '../enums';
import { CustomError } from '../errors';
import { Pagination, Tag } from '../types';

export const tagApi = HealthHubApiClient.use(EshopApi);

export type UpdateTagParams = {
  id: string;
  updateTagDto: UpdateTagDto;
};

export type TagParams = {
  limit?: number;
  page?: number;
  query?: string;
  tagType?: TagType;
};

export async function getAllTags(params: TagParams): Promise<Pagination<Tag>> {
  try {
    const { query, limit, page, tagType } = params;
    const { data } = await tagApi.eshopControllerFindAllTags(query, tagType, limit, page);

    return data as unknown as Pagination<Tag>;
  } catch (err) {
    throw new Error('Failed to fetch tags');
  }
}

export async function getTagById(id: string, config?: AxiosRequestConfig): Promise<Tag> {
  try {
    const response = await tagApi.eshopControllerFindOneTag(id, config);
    const data = response.data;

    return data as unknown as Tag;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createTag(createTagDto: CreateTagDto): Promise<Tag> {
  try {
    const { data } = await tagApi.eshopControllerCreateTag(createTagDto);

    return data as unknown as Tag;
  } catch (err) {
    throw new Error('Failed to create tag');
  }
}

export async function updateTag(params: UpdateTagParams): Promise<Tag> {
  const { id, updateTagDto } = params;

  try {
    const { data } = await tagApi.eshopControllerUpdateTag(id, updateTagDto);

    return data as unknown as Tag;
  } catch (err) {
    const errorMessage = get(err, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to update tag');
  }
}

export async function deleteTag(id: string): Promise<Tag> {
  try {
    const { data } = await tagApi.eshopControllerDeleteTag(id);

    return data as unknown as Tag;
  } catch (err) {
    throw new Error('Failed to delete tag');
  }
}
