import { AppointmentService } from '@healthhub/api-lib';

import { formatToPeso } from './price';
import { Price } from '../libs/price';

export const getServiceTotalAmount = (services: AppointmentService[]) => {
  return services.reduce((totalAmount, service) => {
    return totalAmount + service.price;
  }, 0);
};

export const getFinalAmountFromTransaction = (transaction: Record<string, number | string>) => {
  const { quotedMaxAmount, quotedMinAmount, totalAmount } = transaction;

  const isSameAmount = quotedMaxAmount === quotedMinAmount;
  const finalAmount = isSameAmount
    ? formatToPeso((totalAmount as number) ?? 0)
    : new Price(`${quotedMinAmount}-${quotedMaxAmount}`).priceInPesos;

  return finalAmount;
};
