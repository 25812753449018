import { PH_CITIES_MUNICIPALITIES, Region, Province } from '../constants/phCities';

export function getMunicipalityNames(): string[] {
  const municipalityNames: string[] = [];

  for (const regionCode in PH_CITIES_MUNICIPALITIES) {
    const region: Region = PH_CITIES_MUNICIPALITIES[regionCode];
    for (const provinceName in region.province_list) {
      const province: Province = region.province_list[provinceName];
      for (const municipalityName in province.municipality_list) {
        municipalityNames.push(municipalityName);
      }
    }
  }

  return municipalityNames;
}
