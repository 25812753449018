import { useMemo, useState } from 'react';

import { ProviderClientProfile, Referral } from '@healthhub/api-lib';
import { format } from 'date-fns';
import { EyeAlt } from 'iconoir-react';
import { twMerge } from 'tailwind-merge';

import Button from './Button';
import Table, { TableColumn } from './Table';
import { FieldTextHtmlFormatter, useRouter } from '..';
import { FULL_DATE_FORMAT } from '../constants';
import { Routes } from '../enums';

export enum IncomingOutgoingEnum {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

type Props = {
  client?: ProviderClientProfile;
  referrals: Referral[] | undefined;
  setReferralStatus: (status: IncomingOutgoingEnum) => void;
  referralActiveTab?: string;
};

const ProviderClientReferralsList = (props: Props) => {
  const {
    client,
    referrals,
    setReferralStatus,
    referralActiveTab = IncomingOutgoingEnum.INCOMING,
  } = props;
  const router = useRouter();

  const incomingColumns: TableColumn<Referral>[] = useMemo(
    () => [
      {
        header: 'Referral Date',
        key: 'referral_date',
        headerClassName: 'pl-8',
        className: 'pl-8',
        render: (referral) => {
          const { createdAt } = referral;
          return (
            <div className="text-sm font-medium leading-tight tracking-tight !text-gray-600">{`${format(
              new Date(createdAt),
              FULL_DATE_FORMAT,
            )}`}</div>
          );
        },
      },
      {
        header: 'Referring Provider',
        key: 'referring_provider',
        render: (referral) => {
          const {
            provider: { displayName },
          } = referral;
          return `${displayName}`;
        },
      },
      {
        header: 'Reason for Referral',
        key: 'reasonForReferral',
        headerClassName: 'text-left',
        className: 'justify-start',
        render: (referral) => {
          const { reasonForReferral } = referral;
          return (
            <div className=" max-w-[300px] text-sm font-medium leading-tight tracking-tight text-gray-600">
              <FieldTextHtmlFormatter remarks={reasonForReferral} />
            </div>
          );
        },
      },
      {
        header: 'Action',
        key: 'action',
        headerClassName: 'text-right pr-8',
        className: 'justify-end pr-8',
        render: (referral) => (
          <Button
            variant="label"
            className="h-fit !p-0 text-primary-500"
            icon={EyeAlt}
            onClick={() => router.push(`${Routes.REFERRAL}/${referral.id}`)}
          >
            <span className="font-normal">View</span>
          </Button>
        ),
      },
    ],
    [],
  );

  const outgoingColumns: TableColumn<Referral>[] = useMemo(
    () => [
      {
        header: 'Referral Date',
        key: 'referral_date',
        headerClassName: 'pl-8',
        className: 'pl-8',
        render: (referral) => {
          const { createdAt } = referral;
          return (
            <div className="text-sm font-medium leading-tight tracking-tight !text-gray-600">{`${format(
              new Date(createdAt),
              FULL_DATE_FORMAT,
            )}`}</div>
          );
        },
      },
      {
        header: 'Referred to Provider',
        key: 'referred_to_provider',
        render: (referral) => {
          const {
            referredProvider: { displayName },
          } = referral;
          return `${displayName}`;
        },
      },
      {
        header: 'Reason for Referral',
        key: 'reasonForReferral',
        headerClassName: 'text-left',
        className: 'justify-start',
        render: (referral) => {
          const { reasonForReferral } = referral;
          return (
            <div className=" max-w-[400px] text-sm font-medium leading-tight tracking-tight text-gray-600">
              <FieldTextHtmlFormatter remarks={reasonForReferral} />
            </div>
          );
        },
      },
      {
        header: 'Action',
        key: 'action',
        headerClassName: 'text-right pr-8',
        className: 'justify-end pr-8',
        render: (referral) => (
          <Button
            variant="label"
            className="h-fit !p-0 text-primary-500"
            icon={EyeAlt}
            onClick={() => router.push(`${Routes.REFERRAL}/${referral.id}`)}
          >
            <span className="font-normal">View</span>
          </Button>
        ),
      },
    ],
    [],
  );

  const columns =
    referralActiveTab === IncomingOutgoingEnum.INCOMING ? incomingColumns : outgoingColumns;

  const noDataText =
    referralActiveTab === IncomingOutgoingEnum.INCOMING
      ? 'Your incoming referrals from other providers will show up here.'
      : 'Your outgoing referrals to other providers will show up here.';

  return (
    <div>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="flex flex-col space-y-6 rounded-t-lg border border-chineseWhite bg-white px-8 pt-8">
            <span className="text-lg font-semibold">Referrals</span>
            <nav className="-mb-px flex space-x-5" aria-label="Tabs">
              <a
                href="#referrals?status=incoming"
                className={twMerge(
                  referralActiveTab === IncomingOutgoingEnum.INCOMING
                    ? 'border-b-2 border-primary1 text-primary1'
                    : 'text-neutral-500',
                  'whitespace-nowrap  px-1 py-4 text-sm font-medium',
                )}
                aria-current="page"
                onClick={() => setReferralStatus(IncomingOutgoingEnum.INCOMING)}
              >
                Incoming
              </a>

              <a
                href="#referrals?status=outgoing"
                className={twMerge(
                  referralActiveTab === IncomingOutgoingEnum.OUTGOING
                    ? 'border-b-2 border-primary1 text-primary1'
                    : 'text-neutral-500',
                  'whitespace-nowrap px-1 py-4 text-sm font-medium',
                )}
                aria-current="page"
                onClick={() => setReferralStatus(IncomingOutgoingEnum.OUTGOING)}
              >
                Outgoing
              </a>
            </nav>
          </div>
          <div className="overflow-hidden rounded-b-lg">
            <Table
              noDataText={noDataText}
              headerClassName="!bg-neutral-100 !font-medium !text-neutral-500"
              containerClassName="!rounded-t-none !border-t-0"
              columns={columns}
              data={referrals ?? []}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderClientReferralsList;
