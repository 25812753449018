import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { fetchDiscounts, getDiscountById } from '../../api';
import { GET_DISCOUNT, GET_DISCOUNTS } from '../../constants/queryKeys';
import { DiscountFilter, IDiscount, Pagination } from '../../types';

export const useFetchDiscounts = (params: DiscountFilter) => {
  return useQuery<Pagination<IDiscount>, AxiosError>([GET_DISCOUNTS, { ...params }], () =>
    fetchDiscounts(params),
  );
};

export const useFetchDiscountById = (id: string) => {
  return useQuery<IDiscount, AxiosError>([GET_DISCOUNT, { id }], () => getDiscountById(id), {
    enabled: Boolean(id),
  });
};
