import { ProviderServicePriceTypeEnum } from '@healthhub/api-lib';
import { format, isFuture, isPast } from 'date-fns';

import { convertTimeToHours, getDateWithoutTimezone, getTodayUTCPhilippineTime } from './date';
import { intToPrice } from './price';
import { WEEKDAY_FORMAT, HOUR_FORMAT } from '../constants/dateFormats';
import { SHOW_DISCOUNTS } from '../constants/featureFlags';
import { PriceType } from '../enums';
import {
  DiscountActiveDaysTypeEnum,
  DiscountActiveHoursTypeEnum,
  DiscountDaysEnum,
  DiscountDurationStatusEnum,
  DiscountMethodEnum,
  DiscountServiceSelectionEnum,
} from '../enums/discount.enum';
import { Price } from '../libs/price';
import { IDiscount } from '../types';

type DiscountHelperProps = {
  price: string; // i.e. 1000 or 1000-2000
  priceType: PriceType | ProviderServicePriceTypeEnum;
  discount: IDiscount;
  ignoreValidation?: boolean;
};

export default class DiscountHelper {
  _originalPrice: string;
  _originalPriceType: PriceType | ProviderServicePriceTypeEnum;
  _discount: IDiscount;
  _ignoreValidation: boolean;

  constructor(props: DiscountHelperProps) {
    this._originalPrice = props.price;
    this._originalPriceType = props.priceType || PriceType.Fixed;
    this._discount = props.discount;
    this._ignoreValidation = props.ignoreValidation || false;
  }

  get hasDiscount() {
    const price = new Price(this._originalPrice, this._originalPriceType);
    if (this._originalPrice?.includes('-') && this.isDiscountMethodFixed) {
      const minPrice = price.priceInCentsInMinMax[0];
      const maxPrice = price.priceInCentsInMinMax[1];
      if (
        minPrice < this.calculateDiscount(minPrice) ||
        maxPrice < this.calculateDiscount(maxPrice)
      ) {
        return false;
      }
    } else if (
      +price.priceInCents < this.calculateDiscount(+price.priceInCents) &&
      this.isDiscountMethodFixed
    ) {
      return false;
    }
    return !!this._discount && SHOW_DISCOUNTS && this.isValidByDateTime();
  }

  get isDiscountMethodFixed() {
    return this._discount?.method === DiscountMethodEnum.FixedPrice;
  }

  get isDiscountMethodPercent() {
    return this._discount?.method === DiscountMethodEnum.PercentOffPrice;
  }

  get isDiscountMethodPeso() {
    return this._discount?.method === DiscountMethodEnum.PesoOffPrice;
  }

  get formattedEndsAt() {
    if (!this._discount?.endsAt) {
      return '';
    }

    return format(getDateWithoutTimezone(this._discount?.endsAt), 'MMM d');
  }

  get originalPrice() {
    return new Price(this._originalPrice, this._originalPriceType);
  }

  get discountBadgeLabel() {
    let label = getDiscountBadgeLabel(this._discount);

    if (this._discount?.endsAt) {
      label += ` till ${this.formattedEndsAt}`;
    }

    return label;
  }

  get discountedPriceString() {
    if (this._originalPrice?.includes('-') && !this.isDiscountMethodFixed) {
      const [min, max] = this._originalPrice.split('-');

      const discountedMin = this.calculateDiscount(parseInt(min));
      const discountedMax = this.calculateDiscount(parseInt(max));

      return `${discountedMin} - ${discountedMax}`;
    }

    return this.calculateDiscount(parseInt(this._originalPrice)).toString();
  }

  get formattedDiscountedPrice() {
    const newDiscountedPrice = this.price;

    return newDiscountedPrice.priceInPesos;
  }

  get price() {
    let type;

    if (this.isDiscountedPriceFree()) {
      type = PriceType.Free;
    } else if (this.isDiscountMethodFixed) {
      type = PriceType.Fixed;
    } else {
      type = this._originalPriceType;
    }

    return new Price(this.discountedPriceString, type);
  }

  get savedAmountFromDiscountPrice(): Price {
    let amount = '0';

    if (this._originalPriceType === PriceType.Range) {
      const [discountedMin, discountedMax] = this.price.priceInCentsInMinMax;
      const [origMin, origMax] = this.originalPrice.priceInCentsInMinMax;

      const savedMin = this.sanitizeNumber(origMin - discountedMin);
      const savedMax = this.sanitizeNumber(origMax - discountedMax);

      amount = `${savedMin} - ${savedMax}`;
    } else {
      const val = this.sanitizeNumber(
        parseInt(this._originalPrice) - parseInt(this.price.priceInCents),
      );

      amount = val.toString();
    }

    return new Price(amount, this._originalPriceType);
  }

  private isDiscountedPriceFree() {
    if (this.discountedPriceString?.includes('-')) {
      const [_, max] = this.discountedPriceString.split('-');

      if (parseInt(max) === 0) {
        return true;
      }
    } else if (parseInt(this.discountedPriceString) === 0) {
      return true;
    }

    return false;
  }

  // price and discount value are still in cents except for %
  private calculateDiscount(price: number) {
    let result = 0;

    if (this.isDiscountMethodFixed) {
      result = this._discount.value;
    } else if (this.isDiscountMethodPercent) {
      result = price * (1 - this._discount.value / 100);
    } else if (this.isDiscountMethodPeso) {
      result = price - this._discount.value;
    } else {
      result = price;
    }

    return this.sanitizeNumber(result);
  }

  // Check if the result is NaN, negative, or not a finite number
  // TODO put in another util like number.ts
  private sanitizeNumber(number: number) {
    if (isNaN(number) || number < 0 || !isFinite(number)) {
      return 0;
    }

    return number;
  }

  private isValidByDateTime() {
    if (this._ignoreValidation) {
      return true;
    }

    const {
      startsAt: startsAtStr,
      endsAt: endsAtStr,
      activeDays,
      activeHoursEnd: activeHoursEndStr,
      activeHoursStart: activeHoursStartStr,
      metadata,
    } = this._discount;
    const { activeDaysType, activeHoursType } = metadata || {};

    const startsAt = getDateWithoutTimezone(startsAtStr);
    const endsAt = endsAtStr ? getDateWithoutTimezone(endsAtStr) : null;

    const dateNow = getTodayUTCPhilippineTime();
    const dayNow = format(dateNow, WEEKDAY_FORMAT).toUpperCase() as DiscountDaysEnum;
    const hourNow = parseInt(format(dateNow, HOUR_FORMAT));
    const activeHoursStart = convertTimeToHours(activeHoursStartStr);
    const activeHoursEnd = convertTimeToHours(activeHoursEndStr);

    // invalid if discount has not started yet
    if (dateNow <= startsAt) {
      return false;
    }

    // invalid if discount has end date and today is past its end date
    if (endsAt && dateNow > endsAt) {
      return false;
    }

    // invalid if active days is selected and today is not included
    if (
      activeDaysType === DiscountActiveDaysTypeEnum.SELECTED_DAYS &&
      !activeDays.includes(dayNow)
    ) {
      return false;
    }

    // invalid if time now is not within the active hours if specific hours is selected
    if (activeHoursType === DiscountActiveHoursTypeEnum.SPECIFIC_HOURS) {
      if (hourNow < activeHoursStart) {
        return false;
      }

      if (hourNow > activeHoursEnd) {
        return false;
      }
    }

    return true;
  }
}

export function getDiscountBadgeLabel(discount: IDiscount) {
  let label = '';

  if (discount.method === DiscountMethodEnum.FixedPrice) {
    label = 'On sale';
  } else if (discount.method === DiscountMethodEnum.PercentOffPrice) {
    label = `${discount.value}% OFF`;
  } else if (discount.method === DiscountMethodEnum.PesoOffPrice) {
    label = `₱${intToPrice(discount.value)} OFF`;
  }

  return label;
}

export function getDiscountSubLabel(discount: IDiscount) {
  let label = '';

  const isForAll =
    discount.metadata?.serviceSelectionType === DiscountServiceSelectionEnum.ALL_SERVICES;
  const suffix = isForAll ? 'all services' : 'specific services';

  if (discount.method === DiscountMethodEnum.FixedPrice) {
    label = `₱${intToPrice(discount.value)} only for `;
  } else if (discount.method === DiscountMethodEnum.PercentOffPrice) {
    label = `${discount.value}% OFF`;
  } else if (discount.method === DiscountMethodEnum.PesoOffPrice) {
    label = `₱${intToPrice(discount.value)} OFF`;
  }

  return `${label} ${suffix}`;
}

export function getDiscountStatus(discount: IDiscount): DiscountDurationStatusEnum {
  const { startsAt, endsAt } = discount;

  const startDate = getDateWithoutTimezone(startsAt);
  const endDate = endsAt ? getDateWithoutTimezone(endsAt) : undefined;

  const isUpcoming = isFuture(startDate);
  const hasEnded = endDate && isPast(endDate);

  if (isUpcoming) {
    return DiscountDurationStatusEnum.UPCOMING;
  }

  if (hasEnded) {
    return DiscountDurationStatusEnum.ENDED;
  }

  return DiscountDurationStatusEnum.ONGOING;
}

export function isDiscountEditable(discount: IDiscount) {
  return getDiscountStatus(discount) === DiscountDurationStatusEnum.UPCOMING;
}
