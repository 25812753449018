import { MouseEventHandler } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  label?: string;
  showIcon?: boolean;
  showLabel?: boolean;
  isDisabled?: boolean;
};

function TableEditButton(props: Props) {
  const { onClick, label = 'Edit', showIcon = true, showLabel = true } = props;

  return (
    <button
      type="button"
      className="relative inline-flex items-center text-sm font-medium text-primary-500 focus:z-10"
      onClick={onClick}
    >
      {showIcon && <PencilIcon height={20} width={20} className="mr-2" />}
      {showLabel && label}
    </button>
  );
}

export default TableEditButton;
