import { ClientProfile, ProviderClientProfile, User } from '@healthhub/api-lib';

export function formatUserNameToFull(
  user: User | null | ClientProfile | ProviderClientProfile | undefined,
): string {
  if (!user) {
    return 'No name';
  }
  const names = [
    user.firstName ?? '',
    user.middleName ?? '',
    user.lastName ?? '',
    user.suffix ?? '',
  ].filter((name) => name !== '');

  return names.join(' ');
}
