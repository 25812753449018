import React from 'react';

import { classNames } from '../utils';

type Props = {
  backgroundColor?: string;
  className?: string;
  children: React.ReactNode;
};

export default function Prompt(props: Props) {
  const { backgroundColor = 'bg-primary50', className, children } = props;

  return (
    <div
      className={classNames(
        'my-2 flex flex-col items-start gap-2 rounded-lg px-5 py-3.5 font-medium max-sm:mx-4',
        backgroundColor,
        className,
      )}
    >
      {children}
    </div>
  );
}
