import { ReactNode } from 'react';

import AppLogo from './AppLogo';
import { clsxMerge } from '../utils';

export interface AuthLayoutProps {
  children: ReactNode;
  isInvertIcon?: boolean;
}

function AuthLayout(props: AuthLayoutProps) {
  const { children, isInvertIcon } = props;

  const logoSubtitle = isInvertIcon ? 'FOR OPERATIONS' : 'FOR PROVIDERS';
  const logoSubtitleColor = isInvertIcon ? 'text-primary-200' : 'text-primary-300';

  return (
    <div className="box-border flex h-full w-full flex-col justify-center overflow-y-auto lg:flex-row lg:items-center">
      <div className="h-half relative mt-10 box-border md:mt-0 md:block lg:h-screen lg:w-[40%] xl:w-1/2">
        <div className="relative flex h-full w-full flex-col justify-center gap-1.5 px-12 lg:items-end xl:md:gap-3.5 xl:px-28">
          <AppLogo isClickable={false} width={501} isInvert={isInvertIcon} />
          <div
            className={clsxMerge(
              'text-md ml-4 font-semibold text-primary-200 md:text-xl md:tracking-widest',
              logoSubtitleColor,
            )}
          >
            {logoSubtitle}
          </div>
        </div>
      </div>
      <div className="box-border flex flex-col px-8 py-6 md:py-12 lg:w-[60%] lg:px-16 xl:px-20">
        <div className="rounded-xl bg-neutral-50 xl:h-[90%]">{children}</div>
      </div>
    </div>
  );
}

export default AuthLayout;
