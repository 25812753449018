export enum AppointmentTypeEnum {
  FACE_TO_FACE = 'face-to-face',
  VIDEO = 'video',
  LABORATORY = 'LABORATORY',
  HOME = 'HOME',
}

export enum AppointmentPatientEnum {
  SELF = 'self',
  OTHERS = 'others',
}

export enum AppointmentProgressSteps {
  DETAILS = 'DETAILS',
  PATIENT = 'PATIENT',
  Success = 'SUCCESS',
}

export enum AppointmentReceiver {
  DOCTOR = 'doctor',
  LABORATORY = 'laboratory',
}

export enum AppointmentStatusEnum {
  CONFIRMED = 'confirmed',
  ONGOING = 'ongoing',
  CANCELLED = 'cancelled',
  NO_SHOW = 'no-show',
  COMPLETED = 'completed',
}

export enum AppointmentPlatformEnum {
  WEB = 'web',
  MWELL_MOBILE = 'mwell-app',
  WEB_MPH_REFERRAL = 'web-mph-referral',
  PARTNER = 'partner',
}

export const APPOINTMENT_STATUS_USER_DISPLAY: Record<string, string> = {
  [AppointmentStatusEnum.CONFIRMED]: 'Confirmed',
  [AppointmentStatusEnum.ONGOING]: 'Ongoing',
  [AppointmentStatusEnum.CANCELLED]: 'Cancelled',
  [AppointmentStatusEnum.NO_SHOW]: 'Missed',
  [AppointmentStatusEnum.COMPLETED]: 'Completed',
};

export const APPOINTMENT_STATUS_BADGE_COLOR: Record<string, string> = {
  [AppointmentStatusEnum.CONFIRMED]: 'blue',
  [AppointmentStatusEnum.ONGOING]: 'grey',
  [AppointmentStatusEnum.CANCELLED]: 'red',
  [AppointmentStatusEnum.NO_SHOW]: 'yellow',
  [AppointmentStatusEnum.COMPLETED]: 'green',
};

export enum AppointmentStatusExtensionEnum {
  TODAY = 'today',
  PENDING = 'pending',
  UPCOMING = 'upcoming',
}

export const AppointmentTabStatusLabels: Record<string, string> = {
  [AppointmentStatusExtensionEnum.TODAY]: 'Today',
  [AppointmentStatusEnum.ONGOING]: 'Arrived',
  [AppointmentStatusExtensionEnum.UPCOMING]: 'Upcoming',
  [AppointmentStatusEnum.COMPLETED]: 'Completed',
  [AppointmentStatusExtensionEnum.PENDING]: 'Pending',
  [AppointmentStatusEnum.CANCELLED]: 'Cancelled',
};

export const APPOINTMENT_STATUS_DESCRIPTION: Record<string, string> = {
  [AppointmentStatusExtensionEnum.TODAY]: 'View appointments scheduled for the current day.',
  [AppointmentStatusEnum.ONGOING]: 'View appointments marked as arrived, currently in progress.',
  [AppointmentStatusExtensionEnum.UPCOMING]: 'View appointments scheduled for future dates.',
  [AppointmentStatusEnum.COMPLETED]: 'View appointments marked as completed.',
  [AppointmentStatusExtensionEnum.PENDING]:
    'View past-date appointments that have not been marked arrived, completed, or cancelled.',
  [AppointmentStatusEnum.CANCELLED]:
    'View appointments that have been cancelled and marked as no-show.',
};

export enum AppointmentUploadEnum {
  HMO = 'hmo',
  LABORATORY_REQUEST = 'laboratory-request',
}
