export enum LabRequestStatusEnum {
  OPEN = 'open',
  PARTIALLY_COMPLETE = 'partially-complete',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
}

export enum LabRequestTestType {
  LABORATORY = 'laboratory',
  HOME = 'home',
}
