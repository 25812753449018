import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import { GET_ALL_PRODUCTS } from '../../constants';
import { Routes } from '../../enums';
import { useDeleteProductMutation, useRouter } from '../../hooks';
import { EshopProduct } from '../../types';
import Button from '../Button';
import Modal from '../Modal';
import toast from '../toast';

type Props = {
  hasOrders: boolean;
  isOpen: boolean;
  onClose: () => void;
  product: EshopProduct;
};

function DeleteProductModal(props: Props) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { product, isOpen, hasOrders, onClose } = props;

  const { mutate: deleteProduct, isLoading } = useDeleteProductMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_PRODUCTS]);
      toast({ message: 'Product successfully deleted!', type: 'success' });
      router.push(Routes.PRODUCTS);
      onClose();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: AxiosError<{ message: string }, any>) => {
      toast({ message: error.message, type: 'error' });
    },
  });

  function handleDelete() {
    deleteProduct(product.id.toString());
  }

  const textDisplay = hasOrders ? (
    <p className="text-sm text-gray-500">
      There are existing orders for this product. Do you want to set this product to inactive?
      <br />
      <br />
      Inactive products are still available but not visible to the users.
    </p>
  ) : (
    <p className="text-sm text-gray-500">
      Are you sure you want to permanently delete this product?
    </p>
  );

  return (
    <Modal className="overflow-y-auto" open={isOpen} size="md" onClose={onClose}>
      <div className="flex flex-row gap-4">
        <div
          className={twMerge(
            'flex h-fit w-10 items-center justify-center rounded-full p-2',
            'bg-red-100 text-red-600',
          )}
        >
          <ExclamationTriangleIcon height={24} width={24} />
        </div>
        <div>
          <div className="mb-4">
            <div className="flex justify-between">
              <h3 className="text-xl font-bold">Delete this product?</h3>
            </div>
          </div>
          <div className="mt-4">{textDisplay}</div>
        </div>
      </div>
      <div className="mt-8 flex justify-end gap-2">
        <Button className="text-[15px] font-medium !text-gray5" variant="label" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="text-sm font-normal"
          variant="error"
          isLoading={isLoading}
          onClick={handleDelete}
        >
          Yes, Delete Product
        </Button>
      </div>
    </Modal>
  );
}

export default DeleteProductModal;
