import { twMerge } from 'tailwind-merge';

type Props = {
  totalSteps: number;
  currentStep: number;
  className?: string;
  containerClassName?: string;
};

const ProgressBar = (props: Props) => {
  const { totalSteps, currentStep, className, containerClassName } = props;

  // Check if currentStep is equal to totalSteps
  const isFullWidth = currentStep === totalSteps;

  return (
    <div
      className={twMerge('relative z-0 h-3 w-full rounded-full bg-[#CFDAE5]', containerClassName)}
    >
      <div
        className={twMerge(
          'absolute z-10 h-full rounded-full bg-primary3',
          isFullWidth ? 'w-full' : `w-${currentStep}/${totalSteps}`,
          className,
        )}
      />
    </div>
  );
};

export default ProgressBar;
