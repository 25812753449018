import { useQuery } from '@tanstack/react-query';

import {
  ProviderTagCategoryParams,
  getAllProviderTagCategories,
} from '../../api/providerTagCategoryService';
import { GET_PROVIDER_TAG_CATEGORY_BY_PARAMS } from '../../constants';

export function useGetAllProviderTagCategoryParams(
  providerTagCategoryParams: ProviderTagCategoryParams,
) {
  const { providerId, searchQuery } = providerTagCategoryParams;
  return useQuery(
    [GET_PROVIDER_TAG_CATEGORY_BY_PARAMS, { providerId, searchQuery }],
    () => getAllProviderTagCategories(providerTagCategoryParams),
    {
      enabled: !!providerId?.length,
    },
  );
}
