/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useReducer, useRef, useState } from 'react';

import { RADIUS_IN_METERS } from '../../constants/distances';

type Props = {
  center: number[];
  onChangeCenter: (latitude: number, longitude: number) => void;
  subscriptionKey: string;
};

const ZOOM = 11;

const MapComponent = (props: Props) => {
  const mapRef = useRef<any>();
  const markerRef = useRef<any>();
  const dataSourceRef = useRef<any>();
  const circleRef = useRef<any>();

  const { center, subscriptionKey, onChangeCenter } = props;
  const [currentZoom, setCurrentZoom] = useState(ZOOM);

  const atlasRef = useRef<any>(null);
  const forceUpdate = useReducer(() => ({}), {})[1];

  useEffect(() => {
    import('azure-maps-control').then((module) => {
      atlasRef.current = module;
      forceUpdate();
    });
  }, []);

  useEffect(() => {
    if (!atlasRef.current) {
      return;
    }

    function initializeMap() {
      mapRef.current = new atlasRef.current.Map('locationMap', {
        view: 'Auto',
        authOptions: {
          subscriptionKey,
          authType: atlasRef.current.AuthenticationType.subscriptionKey,
        },
      });
    }

    function addDataSourcesToMap() {
      dataSourceRef.current = new atlasRef.current.source.DataSource();
      mapRef.current.sources.add(dataSourceRef.current);
    }

    function addMarkerToMap() {
      markerRef.current = new atlasRef.current.HtmlMarker({
        htmlContent: "<div><div class='pin bounce'></div><div class='pulse'></div></div>",
        position: [0, 0],
        pixelOffset: [5, -18],
      });

      mapRef.current.markers.add(markerRef.current);
    }

    function addCircleToMap(position: number[]) {
      circleRef.current = new atlasRef.current.Shape(
        new atlasRef.current.data.Point(position),
        null,
        {
          subType: 'Circle',
          radius: RADIUS_IN_METERS,
        },
      );

      dataSourceRef.current.add(circleRef.current);

      mapRef.current.layers.add(
        new atlasRef.current.layer.PolygonLayer(dataSourceRef.current, null, {
          fillColor: '#B2D5FB',
          fillOpacity: 0.25,
        }),
      );
    }

    function setInitialLocation() {
      addCircleToMap(center);

      markerRef.current.setOptions({
        position: center,
      });

      mapRef.current?.setCamera({
        center,
        zoom: currentZoom,
      });

      onChangeCenter(center[1], center[0]);
    }

    function addMapEvents() {
      mapRef.current.events.add('ready', function () {
        addDataSourcesToMap();
        addMarkerToMap();
        setInitialLocation();
      });

      mapRef.current.events.add('drag', () => {
        const newCenter = mapRef.current.getCamera().center;

        circleRef.current.setCoordinates(newCenter);
        markerRef.current.setOptions({
          position: newCenter,
        });
      });

      mapRef.current.events.add('dragend', () => {
        const newCenter = mapRef.current.getCamera().center;

        onChangeCenter(newCenter[1], newCenter[0]);
      });

      mapRef.current.events.add('zoomend', () => {
        const newZoom = mapRef.current.getCamera().zoom;

        setCurrentZoom(newZoom);
      });
    }

    initializeMap();
    addMapEvents();
  }, [atlasRef.current]);

  useEffect(() => {
    if (center && circleRef.current && markerRef.current) {
      circleRef.current.setCoordinates(center);
      markerRef.current.setOptions({
        position: center,
      });

      mapRef.current?.setCamera({
        center,
        zoom: currentZoom,
      });
    }
  }, [center, circleRef, currentZoom, markerRef, mapRef]);

  return <div id="locationMap" className="bg-gray-100" />;
};

export default MapComponent;
