import {
  TextInput,
  TextInputPropsDefault,
  MultilineTextInput,
  Select,
  MaskedTextInput,
  MultilineTextInputProps,
  SelectProps,
  MaskedTextInputProps,
  Checkbox,
  CheckboxProps,
} from '@mwell-healthhub/commons';

import { getErrorMessage } from '../../utils';

interface FieldCommonProps {
  error?: Error;
  defaultValues?: Record<string, unknown>;
  handleInputChange?: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void;
}

interface TextInputFieldProps extends FieldCommonProps, TextInputPropsDefault {
  component: typeof TextInput;
}

interface MultilineTextInputFieldProps extends FieldCommonProps, MultilineTextInputProps {
  component: typeof MultilineTextInput;
}

interface SelectFieldProps extends FieldCommonProps, SelectProps {
  component: typeof Select;
}

interface MaskedTextInputFieldProps extends FieldCommonProps, MaskedTextInputProps {
  component: typeof MaskedTextInput;
}

interface CheckboxFieldProps extends FieldCommonProps, CheckboxProps {
  component: typeof Checkbox;
}

export type FieldProps =
  | TextInputFieldProps
  | MultilineTextInputFieldProps
  | SelectFieldProps
  | MaskedTextInputFieldProps
  | CheckboxFieldProps;

export type AllFieldProps = TextInputFieldProps &
  MultilineTextInputFieldProps &
  SelectFieldProps &
  MaskedTextInputFieldProps &
  CheckboxFieldProps;

export function Field(props: FieldProps) {
  const { component: FormComponent, error, defaultValues, name, handleInputChange } = props;
  const { component: _, ...etcProps } = props as unknown as Omit<AllFieldProps, 'ref'>;

  return (
    <>
      <FormComponent
        {...etcProps}
        name={name}
        onChange={handleInputChange}
        defaultValue={
          name ? (defaultValues?.[name] as string | number | readonly string[]) : undefined
        }
      />
      {getErrorMessage(error, name) && (
        <span className="text-sm text-error">{getErrorMessage(error, name)}</span>
      )}
    </>
  );
}
