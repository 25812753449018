'use client';

import { HTMLProps } from 'react';

import { Switch as TUISwitch } from '@headlessui/react';

import { clsxMerge } from '../utils';

type Props = HTMLProps<HTMLDivElement> & {
  checked: boolean;
  onChange: (checked: boolean) => void;
  variant?: 'plain' | 'icon';
  disabled?: boolean;
};

export default function Switch(props: Props) {
  const { checked, onChange, variant = 'icon', className, disabled = false } = props;

  return (
    <TUISwitch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={clsxMerge(
        checked ? 'bg-secondary-500' : 'bg-neutral-300',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 disabled:bg-neutral-400',
        className,
      )}
    >
      {variant === 'icon' ? (
        <>
          <span className="sr-only">Use setting</span>
          <span
            className={clsxMerge(
              checked ? 'translate-x-5' : 'translate-x-0',
              'shadow pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out',
            )}
          >
            <span
              className={clsxMerge(
                checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-neutral-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={clsxMerge(
                checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-pictonBlue" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </>
      ) : (
        <span
          aria-hidden="true"
          className={clsxMerge(
            checked ? 'translate-x-5' : 'translate-x-0',
            'shadow pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out',
          )}
        />
      )}
    </TUISwitch>
  );
}
