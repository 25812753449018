import { HTMLProps } from 'react';

export type CardProps = HTMLProps<HTMLDivElement>;

export default function Card(props: CardProps) {
  const { children, className = '', ...etcProps } = props;
  return (
    <div
      {...etcProps}
      className={`overflow-hidden rounded-lg border border-neutral-300 bg-white ${className}`.trim()}
    >
      {children}
    </div>
  );
}
