import {
  AppointmentService,
  ProviderService,
  ProviderServiceAvailabilityEnum,
} from '@healthhub/api-lib';

import DiscountHelper from './DiscountHelper';
import { getFormattedServicePrice, getServiceStringedPrice } from './price';
import { PriceType } from '../enums';
import { Price } from '../libs/price';
import { IDiscount } from '../types/discount.type';

export type ProviderServiceWithDiscount = ProviderService & { discounts?: IDiscount[] };

export default class ProviderServiceHelper {
  providerService?: ProviderServiceWithDiscount;
  discountHelper?: DiscountHelper;

  constructor(providerService?: ProviderServiceWithDiscount) {
    this.providerService = providerService;

    if (providerService?.discounts?.length) {
      this.discountHelper = new DiscountHelper({
        price: getServiceStringedPrice(providerService),
        priceType: providerService?.priceType,
        discount: providerService?.discounts?.[0],

        // ignore validation if service is from appointment (already booked)
        ignoreValidation: !!(providerService as unknown as AppointmentService)?.providerService,
      });
    }
  }

  get isBrandService() {
    return (this.providerService?.branchServices?.length || 0) > 0;
  }

  get isBranchService() {
    return !this.isBrandService;
  }

  get availableBranchServices() {
    return (
      this.providerService?.branchServices?.filter(
        ({ availability }) => availability !== ProviderServiceAvailabilityEnum.Unavailable,
      ) || []
    );
  }

  get availability() {
    if (this.isBranchService) {
      return this.providerService?.availability;
    } else {
      const providerBranchServicesCount = this.availableBranchServices.length || 0;

      const isAvailableInAllBranches =
        providerBranchServicesCount === this.providerService?.provider?.branches?.length;

      if (isAvailableInAllBranches) {
        return 'Available in All Branches';
      } else if (this.availableBranchServices?.length) {
        return `Available in ${providerBranchServicesCount} Branch${
          providerBranchServicesCount > 1 ? 'es' : ''
        }`;
      } else {
        return 'Unavailable';
      }
    }
  }

  get isOtherConcern() {
    return this.providerService?.label === 'Other Concern';
  }

  get formattedPrice() {
    if (!this.providerService) {
      return '';
    }

    return getFormattedServicePrice(this.providerService);
  }

  get price() {
    if (!this.providerService) {
      return new Price('0');
    }

    if (this.hasDiscount && this.discountHelper) {
      return this.discountHelper.price;
    } else {
      const stringedPrice = getServiceStringedPrice(this.providerService);

      return new Price(stringedPrice, this.providerService.priceType);
    }
  }

  get hasDiscount() {
    const hasDiscountablePriceType = ![PriceType.Free, PriceType.Hide].includes(
      this.providerService?.priceType as PriceType,
    );

    return this.discountHelper?.hasDiscount && hasDiscountablePriceType && !this.isOtherConcern;
  }

  get discount() {
    return this.discountHelper?._discount || {};
  }

  get discountBadgeLabel() {
    return this.discountHelper?.discountBadgeLabel || '';
  }

  get formattedDiscountedPrice() {
    return this.discountHelper?.formattedDiscountedPrice || '';
  }

  get savedAmountFromDiscount() {
    return (
      this.discountHelper?.savedAmountFromDiscountPrice ||
      new Price('0', this.providerService?.priceType)
    );
  }
}
