import clsx from 'clsx';
import { FaCalendarTimes } from 'react-icons/fa';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { colors } from '@mwell-healthhub/commons/theme';

type Props = {
  variant?: 'primary' | 'secondary';
};

export default function NoAvailableSchedule(props: Props) {
  const { variant = 'primary' } = props;

  const isSecondary = variant !== 'primary';

  return (
    <div
      className={clsx('flex w-full items-start gap-3 rounded-lg bg-gray7 px-3 py-2', {
        'bg-white p-0': isSecondary,
      })}
    >
      <FaCalendarTimes
        className="self-center"
        style={{ color: isSecondary ? colors.gray16 : colors.midgrey }}
      />
      <p
        className={clsx('flex items-center text-sm font-medium text-neutral-500', {
          'text-gray16': isSecondary,
        })}
      >
        No available schedule at the moment
      </p>
    </div>
  );
}
