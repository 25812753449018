import { Appointment, ProviderClientNotes } from '@healthhub/api-lib';
import {
  Button,
  FileTypeExtensionEnum,
  FileUpload,
  Modal,
  ProviderClientNotesForm,
  TextInput,
  formatBytesToMegabytes,
  formatFileTypesForLabel,
  toast,
  useUploadMedicalRecordFileMutation,
  validateFiles,
} from '@mwell-healthhub/commons';
import { Attachment } from 'iconoir-react';
import { ChangeEvent, useState } from 'react';

type Props = {
  appointment: Appointment;
  isOpen: boolean;
  toggle: () => void;
};

const ProviderClientMedicalRecordsModal = (props: Props) => {
  const { appointment, isOpen, toggle } = props;
  const [title, setTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [titleError, setTitleError] = useState('');
  const [hasFileError, setHasFileError] = useState(false);
  const { mutate: uploadMedicalRecordFile, isLoading: isUploadingMedicalRecordFile } =
    useUploadMedicalRecordFileMutation();
  const acceptedFileTypes = [
    FileTypeExtensionEnum.JPG,
    FileTypeExtensionEnum.JPEG,
    FileTypeExtensionEnum.PNG,
    FileTypeExtensionEnum.PDF,
  ];
  const maxSizeLimit = 50 * 1024 * 1024; // 50mb

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitleError('');
    setTitle(event.target.value);
  };

  const handleFileChange = async (files: File[]) => {
    setHasFileError(false);
    const file = files[0];

    if (!file) {
      return;
    }

    try {
      const validFiles = await validateFiles([file], { acceptedFileTypes });

      setSelectedFile(file);

      if (!title && file.name) {
        setTitle(file.name);
        setTitleError('');
      }
    } catch (error) {
      setHasFileError(true);
    }
  };

  const handleUpload = async () => {
    let valid = true;

    if (!title) {
      setTitleError('Title is required');
      valid = false;
    }

    if (!selectedFile) {
      setHasFileError(true);
      valid = false;
    }

    if (valid && selectedFile) {
      await uploadMedicalRecordFile(
        { file: selectedFile, appointmentId: appointment.id.toString(), name: title },
        {
          onSuccess: () => {
            toast({
              message: 'Medical record has been uploaded and shared with client!',
              type: 'success',
            });
            toggle();
          },
          onError: (error) => {
            toast({
              message: 'An error occurred while uploading the medical record. Please try again.',
              type: 'error',
            });
            toggle();
          },
        },
      );
    }
  };

  const enableUpload = (title && selectedFile) || isUploadingMedicalRecordFile;

  return (
    <Modal open={isOpen} setOpen={toggle} className="!p-0" size="lg">
      <div className="flex flex-col gap-6 p-6 font-normal">
        <div className="text-lg font-semibold">Upload Medical Record</div>
        <div className="flex flex-col gap-2">
          <div className="text-md">Name of Medical Record</div>
          <div className="text-xs text-gray-500">
            Describe the record, service, or procedure (ex. CBC, Progress Note, Lung X-ray)
          </div>
          <TextInput value={title} onChange={handleTitleChange} />
          {titleError && <p className="text-xs text-red-500">{titleError}</p>}
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-md">File</div>
          <div className="text-xs text-gray-500">Select a pdf, jpg, jpeg, or png up to 25mb</div>
          <div className="flex items-center justify-between gap-4 rounded-lg border border-gray-300 p-3 text-sm">
            <div className="flex gap-2 text-gray-400">
              <Attachment fontSize={12} />
              <div>{selectedFile?.name || 'No file selected'}</div>
            </div>
            <FileUpload
              acceptedFileTypes={acceptedFileTypes}
              canDisplayError={false}
              label="Select File"
              labelClassName="text-primary-500 underline"
              onFileChange={handleFileChange}
            />
          </div>
          {hasFileError && (
            <p className="inline-block whitespace-pre-line text-left text-xs font-normal text-red-500 no-underline">
              The supported file types: {formatFileTypesForLabel(acceptedFileTypes)}
              {'\n'}
              The maximum file size limit: {formatBytesToMegabytes(maxSizeLimit)}
            </p>
          )}
        </div>
        <div className="flex justify-end gap-4">
          <Button variant="outlined" onClick={toggle} disabled={isUploadingMedicalRecordFile}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={!enableUpload}
            isLoading={isUploadingMedicalRecordFile}
          >
            Upload
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProviderClientMedicalRecordsModal;
