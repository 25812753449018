import { useId, HTMLProps, forwardRef, Ref, useEffect, useRef } from 'react';

import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import {
  ALLOWED_CHARS_IN_FRONT_END,
  SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD,
} from '../../constants';

export interface MultilineTextInputProps extends HTMLProps<HTMLTextAreaElement> {
  errorMessage?: string;
  label?: string;
  labelShown?: boolean;
  showRequiredOnLabel?: boolean;
  subLabel?: string;
  highlightInvalidChars?: boolean;
}

const highlight = 'bg-red-200';
const commonClasses = 'block w-full px-3 py-2 sm:text-sm sm:leading-6';

const highlightInvalidCharacters = (input: string) => {
  return input
    .split('')
    .map((char) => {
      if (!ALLOWED_CHARS_IN_FRONT_END.test(char)) {
        return `<span class='${highlight}'>${char}</span>`;
      }
      return char;
    })
    .join('');
};

function MultilineTextInput(
  props: MultilineTextInputProps,
  forwardedRef?: Ref<HTMLTextAreaElement>,
) {
  const {
    id: idProp,
    className = '',
    errorMessage,
    label,
    labelShown = false,
    showRequiredOnLabel,
    subLabel,
    value,
    onChange,
    highlightInvalidChars = false,
    ...etcProps
  } = props;
  const defaultId = useId();
  const id = idProp ?? defaultId;
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const IS_ENHANCED_DESCRIPTION_FIELD =
    SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD && highlightInvalidChars;

  useEffect(() => {
    const textarea = textareaRef.current;
    const overlay = overlayRef.current;

    if (textarea && overlay) {
      const syncScroll = () => {
        overlay.scrollTop = textarea.scrollTop;
        overlay.scrollLeft = textarea.scrollLeft;
      };

      textarea.addEventListener('scroll', syncScroll);
      return () => {
        textarea.removeEventListener('scroll', syncScroll);
      };
    }
  }, [value]);

  return (
    <div>
      <label
        htmlFor={id}
        className={clsx('mb-1.5 block text-sm font-normal leading-6 text-neutral-600', {
          'sr-only': !labelShown,
        })}
      >
        {label}
        {showRequiredOnLabel && <span className="text-red-600"> *</span>}
      </label>
      {subLabel && <div className="mb-2 text-xs text-neutral-500">{subLabel}</div>}
      <div className="textarea-container relative">
        <textarea
          {...etcProps}
          id={id}
          ref={(node) => {
            if (textareaRef) {
              textareaRef.current = node;
            }
            if (typeof forwardedRef === 'function') {
              forwardedRef(node);
            } else if (forwardedRef) {
              (forwardedRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = node;
            }
          }}
          value={value}
          onChange={onChange}
          className={twMerge(
            clsx(
              'rounded-md border-none bg-neutral-50 text-neutral-600 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-inset focus:ring-primary-500 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400',
              commonClasses,
              className,
              { 'ring-red-600 focus:ring-red-600': errorMessage },
              {
                'relative bg-transparent text-transparent caret-black':
                  IS_ENHANCED_DESCRIPTION_FIELD,
              },
            ),
          )}
        />
        {IS_ENHANCED_DESCRIPTION_FIELD && (
          <div
            className={twMerge(
              'highlight-overlay pointer-events-none absolute left-0 top-0 h-full w-full overflow-hidden whitespace-pre-wrap break-words',
              commonClasses,
            )}
            ref={overlayRef}
            dangerouslySetInnerHTML={{
              __html: highlightInvalidCharacters((value || '') as string).replace(/\n/g, '<br>'),
            }}
          />
        )}
      </div>
      {errorMessage && <div className="mt-1 text-sm text-red-600">{errorMessage}</div>}
    </div>
  );
}

export default forwardRef(MultilineTextInput);
