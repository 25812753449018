/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';

import { PlusIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';
import { twMerge } from 'tailwind-merge';

import {
  ALL,
  Dropdown,
  OperationsPagination,
  TAG_TYPES,
  TableDeleteButton,
  TableEditButton,
  useGetAllTags,
} from '@mwell-healthhub/commons';

import DeleteTagModal from './DeleteTagModal';
import { Routes, TagType } from '../../enums';
import { useQueryParamState, useRouter, useToggle } from '../../hooks';
import { Option, Tag } from '../../types';
import { convertObjectsToOptions, sortByKey, toTitleCase } from '../../utils';
import { searchValidator } from '../../validators';
import Button from '../Button';
import SearchInput from '../SearchInput';
import Table, { TableColumn } from '../Table';

function cleanUpFilters(filters: Record<string, string | number | undefined>) {
  const clonedFilters = { ...filters };

  ['tagType'].forEach((key) => {
    if (clonedFilters[key] === ALL) {
      delete clonedFilters[key];
    }
  });

  return clonedFilters;
}

function TagsList() {
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedTagType, setSelectedTagType] = useState<TagType>();
  const [searchValidationError, setSearchValidationError] = useState<string>('');
  const [search, setSearch] = useQueryParamState('search');
  const [selectedRow, setSelectedRow] = useState<Tag>();

  const { isOpen: isDeleteModalOpen, handleToggle: toggleDeleteModal } = useToggle(false);

  const queryParams = cleanUpFilters({
    ...(search && { query: search }),
    tagType: selectedTagType ?? ALL,
    limit: 10,
    page: currentPage,
  });
  const { data: paginatedTags, isFetching: isGetAllTagsLoading } = useGetAllTags(queryParams);

  const { items: tags = [], totalPages, itemsPerPage } = paginatedTags ?? {};

  const sortedTags = useMemo(() => {
    return tags.length ? sortByKey(tags, 'name') : [];
  }, [tags]);

  const handleActionEdit = (rowData: Tag) => () => {
    router.push(`${Routes.TAGS}/${rowData.id}/edit`);
  };

  const handleAddTag = () => {
    router.push(`${Routes.TAGS}/add`);
  };

  const handleDeleteTag = (tag: Tag) => () => {
    setSelectedRow(tag);
    toggleDeleteModal();
  };

  const handleCloseDeleteTagModal = () => {
    setSelectedRow(undefined);
    toggleDeleteModal();
  };

  const columns: TableColumn<Tag>[] = useMemo(
    () => [
      {
        header: 'Name',
        key: 'name',
        render: (data) => <div className="text-sm font-semibold text-gray-600">{data.name}</div>,
      },
      {
        header: 'Type',
        key: 'tagType',
        render: (data) => toTitleCase(data.tagType ?? ''),
      },
      {
        header: 'Action',
        key: 'action',
        className: 'w-[60px]',
        render: (data) => (
          <div className="flex flex-row gap-4">
            <TableEditButton onClick={handleActionEdit(data)} />
            <TableDeleteButton className="text-red-500" onClick={handleDeleteTag(data)} />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tagTypesOptions = convertObjectsToOptions([{ id: ALL, name: 'All Tags' }, ...TAG_TYPES], {
    includeNone: false,
  });

  const handleTagTypeSelection = (status: Option) => {
    setCurrentPage(1);
    if (status.value === ALL) {
      setSelectedTagType(ALL as TagType);
      return;
    }

    setSelectedTagType(status.value);
  };

  const debouncedSetSearch = debounce(async (value) => {
    try {
      setSearchValidationError('');
      await searchValidator.validate(value.trim());
      setSearch(value.trim());
    } catch (error: any) {
      setSearchValidationError(error?.message);
    }
  }, 500);

  return (
    <div>
      <div className="mb-6 mt-4 flex flex-row justify-between sm:flex sm:items-center">
        <div>
          <h1 className="text-4 text-2xl font-semibold">Tags</h1>
        </div>
        <Button
          variant="primary"
          className="flex gap-2 px-[14px] py-[10px] font-normal"
          onClick={handleAddTag}
        >
          <PlusIcon height={18} width={18} className="mr-2" /> Add Tag
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <div
          className={twMerge(
            'items-end gap-4 space-y-2 lg:flex lg:space-y-0',
            searchValidationError ? 'relative items-start' : 'items-end',
          )}
        >
          <SearchInput
            className="w-full"
            defaultValue={search}
            placeholder="Search name"
            onChange={debouncedSetSearch}
            iconVariant="plain"
            errorMessage={searchValidationError}
          />
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Select Tag Type"
            containerClassname="w-full lg:max-w-[200px]"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={tagTypesOptions}
            value={tagTypesOptions.find(
              (tagTypesOption) => selectedTagType && tagTypesOption.value === selectedTagType,
            )}
            onChange={handleTagTypeSelection}
          />
        </div>
        <div className="rounded-lg bg-white">
          <Table
            columns={columns}
            data={tags}
            isLoading={isGetAllTagsLoading}
            noDataText="No Tags found."
          />
        </div>
      </div>
      <OperationsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        totalItems={sortedTags.length}
        itemsCount={sortedTags.length}
      />
      {selectedRow && (
        <DeleteTagModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteTagModal}
          tag={selectedRow}
        />
      )}
    </div>
  );
}

export default TagsList;
