import * as yup from 'yup';

import { passwordSchema } from './password';
import { ACCEPTED_STRING_SCHEMA } from './schema';
import {
  ALPHANUMERIC_NAME_PATTERN,
  COMMA_SEPARATED_LATLONG_PATTERN,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  MIDDLE_NAME_MAX_LENGTH,
  NAME_PATTERN,
  OTHERS_LABEL,
  REQUIRED_FIELD,
} from '../constants';
import {
  alphaNumericCharactersWithSpaceAndTrimRegexp,
  alphanumericWithSpacesRegex,
} from '../constants/characters';
import { ProviderStatusEnum } from '../enums';
import { getMobilePhoneNumberValidationRule, mobilePhoneNumberSchema } from '../utils/phoneNumber';

const MAX_CHARACTERS = 32;

export const addressSchema = {
  barangay: yup.string(),
  otherBarangay: yup.string().when('barangay', {
    is: (barangay: string) => barangay === OTHERS_LABEL,
    then: () => yup.string(),
    otherwise: () => yup.string(),
  }),
  country: yup.string().required(REQUIRED_FIELD),
  line: yup.string().required(REQUIRED_FIELD),
  municipalityCity: yup.string().required(REQUIRED_FIELD),
  otherMunicipalityCity: yup.string().when('municipalityCity', {
    is: (municipalityCity: string) => municipalityCity === OTHERS_LABEL,
    then: () => yup.string().required(REQUIRED_FIELD),
    otherwise: () => yup.string(),
  }),
  province: yup.string().required(REQUIRED_FIELD),
  region: yup.number().required(REQUIRED_FIELD),
};

export const alphaNumericCharactersAndRequiredValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .matches(alphaNumericCharactersWithSpaceAndTrimRegexp, 'Only alphanumeric characters allowed');

const representativeSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required(REQUIRED_FIELD),
  firstName: alphaNumericCharactersAndRequiredValidation,
  lastName: alphaNumericCharactersAndRequiredValidation,
  countryCode: yup.string(),
  middleName: yup
    .string()
    .matches(alphanumericWithSpacesRegex, 'Only alphanumeric characters allowed'),
  mobileNumber: mobilePhoneNumberSchema
    .required()
    .when('countryCode', (countryCode) => getMobilePhoneNumberValidationRule(countryCode[0])),
  phoneNumber: yup
    .string()
    .when('phoneCountryCode', (phoneCountryCode) =>
      getMobilePhoneNumberValidationRule(phoneCountryCode[0], false),
    ),
  title: yup.string(),
});

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const emailSchema = yup
  .string()
  .email()
  .optional()
  .nullable()
  .test('is-valid-email', 'Invalid email address format', (value) =>
    value ? emailRegex.test(value) : true,
  );

export const providerClientProfileSchema = ({
  emailTestValidator,
}: {
  emailTestValidator: yup.TestFunction<any, yup.AnyObject>;
}) =>
  yup.object().shape({
    isEmailChanged: yup.boolean(),
    email: yup.string().when(
      'isEmailChanged',
      (isEmailChanged: boolean[]) =>
        isEmailChanged[0]
          ? emailSchema.test('exists', 'Email already exists.', emailTestValidator)
          : emailSchema, // No validation when isEmailChange is false
    ),
    firstName: ACCEPTED_STRING_SCHEMA.required('First name is required.')
      .matches(NAME_PATTERN, 'Invalid first name format.')
      .max(FIRST_NAME_MAX_LENGTH, 'First name should be less than 50 characters'),
    lastName: ACCEPTED_STRING_SCHEMA.required('Last name is required.')
      .matches(NAME_PATTERN, 'Invalid last name format.')
      .max(LAST_NAME_MAX_LENGTH, 'Last name should be less than 50 characters'),
    middleName: ACCEPTED_STRING_SCHEMA.nullable()
      .matches(NAME_PATTERN, 'Invalid middle name format.')
      .max(MIDDLE_NAME_MAX_LENGTH, 'Middle name should be less than 50 characters'),
    sex: yup.string().required(REQUIRED_FIELD),
    birthDate: yup.string().required(REQUIRED_FIELD),
    countryCode: yup.string().nullable(),
    mobilePhoneNumber: yup.string().when('countryCode', (countryCode) => {
      return getMobilePhoneNumberValidationRule(countryCode[0] || null, false).nullable();
    }),
  });

export const multiSelectSchema = yup.object().shape({
  value: yup.string().required(),
  text: yup.string().required(),
  rightContent: yup.string().optional(),
});

export const userProviderSchema = yup.object().shape(
  {
    isEditing: yup.boolean(),
    email: yup.string().email('Invalid email address').required(REQUIRED_FIELD),
    firstName: alphaNumericCharactersAndRequiredValidation,
    lastName: alphaNumericCharactersAndRequiredValidation,
    ...passwordSchema,
  },
  [['password', 'password']],
);

export const providerSchema = yup.object().shape({
  name: yup
    .string()
    .matches(ALPHANUMERIC_NAME_PATTERN, 'Invalid name format.')
    .required(REQUIRED_FIELD),
  logoUrl: yup.string().required('Display Photo is required'),
  bannerUrl: yup.string().required('Display Banner is required'),
  countryCode: yup.string(),
  mobilePhoneNumber: yup
    .string()
    .when('countryCode', (countryCode) =>
      getMobilePhoneNumberValidationRule(countryCode[0], false),
    ),
  providerTypeIds: yup.array().of(yup.number()).required(REQUIRED_FIELD),
  providerTypes: yup
    .array()
    .of(multiSelectSchema)
    .min(1, 'At least one provider type must be selected')
    .required(REQUIRED_FIELD),
  status: yup.mixed().oneOf(Object.values(ProviderStatusEnum)).required(REQUIRED_FIELD),

  address: yup.object().shape(addressSchema),
  representative: representativeSchema,
  userProvider: userProviderSchema,
});

export const branchSchema = yup.object().shape({
  branch: yup
    .string()
    .matches(ALPHANUMERIC_NAME_PATTERN, 'Invalid name format.')
    .required(REQUIRED_FIELD),
  displayName: yup
    .string()
    .matches(ALPHANUMERIC_NAME_PATTERN, 'Invalid name format.')
    .required(REQUIRED_FIELD),
  bannerUrl: yup.string().required('Display Banner is required'),
  countryCode: yup.string(),
  mobilePhoneNumber: yup
    .string()
    .when('countryCode', (countryCode) =>
      getMobilePhoneNumberValidationRule(countryCode[0], false),
    ),
  officeHours: yup
    .string()
    .max(MAX_CHARACTERS, `Office hours must be at most ${MAX_CHARACTERS} characters`)
    .required(REQUIRED_FIELD),
  status: yup.mixed().oneOf(Object.values(ProviderStatusEnum)).required(REQUIRED_FIELD),
  address: yup.object().shape({
    ...addressSchema,
    coordinates: yup
      .string()
      .matches(COMMA_SEPARATED_LATLONG_PATTERN, 'Invalid coordinates format')
      .test('not-zero-coordinates', 'Invalid coordinates', (value) => {
        return value !== '0,0' && value !== '0, 0';
      })
      .test({
        name: 'valid-latitude',
        message: 'Latitude must be between -90 and 90.',
        test: function (value) {
          const [latitude, longitude] = (value as string).split(',').map(Number);

          if (latitude < -90 || latitude > 90) {
            return false;
          }

          return true;
        },
      })
      .test({
        name: 'valid-longitude',
        message: 'Longitude must be between -180 and 180.',
        test: function (value) {
          const [latitude, longitude] = (value as string).split(',').map(Number);

          if (longitude < -180 || longitude > 180) {
            return false;
          }

          return true;
        },
      })
      .required(REQUIRED_FIELD),
  }),

  representative: representativeSchema,
});
