import { CreateReferralDto, Referral, UpdateReferralDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createReferral, updateReferralById } from '../../api';

export const useCreateReferral = (
  mutationOptions?: MutationOptions<Referral, AxiosError<{ message: string }>, CreateReferralDto>,
) => useMutation(createReferral, mutationOptions);

export const useUpdateReferral = (
  mutationOptions?: MutationOptions<
    Referral,
    AxiosError<{ message: string }>,
    { id: string; updateReferralDto: UpdateReferralDto }
  >,
) => {
  return useMutation(
    (params) => updateReferralById(params.id, params.updateReferralDto),
    mutationOptions,
  );
};
