import { AppointmentApprovalStatusEnum, AppointmentStatusEnum } from '@healthhub/api-lib';

import { clsxMerge } from '../../utils/clsxMerge';

type Props = {
  approvalStatus: string;
  status: string;
  isAdmin?: boolean;
};

export default function StatusDisplay(props: Props) {
  const { approvalStatus, status, isAdmin } = props;
  const baseClassName =
    'inline-flex items-center whitespace-nowrap rounded-full px-2 py-1 text-xs font-medium';

  if (approvalStatus === AppointmentApprovalStatusEnum.Declined) {
    return <span className={clsxMerge('bg-red-100 text-red-700', baseClassName)}>Declined</span>;
  }

  if (status === AppointmentStatusEnum.Cancelled) {
    return <span className={clsxMerge('bg-red-100 text-red-700', baseClassName)}>Cancelled</span>;
  }

  if (approvalStatus === AppointmentApprovalStatusEnum.Pending) {
    return (
      <span className={clsxMerge('bg-gray-400 text-white', baseClassName)}>Awaiting Approval</span>
    );
  }

  if (status === AppointmentStatusEnum.NoShow) {
    return (
      <span className={clsxMerge('bg-yellow-100 text-yellow-700', baseClassName)}>No-show</span>
    );
  }

  if (status === AppointmentStatusEnum.Completed) {
    return (
      <span className={clsxMerge('bg-green-100 text-green-700', baseClassName)}>Completed</span>
    );
  }

  if (status === AppointmentStatusEnum.Ongoing) {
    return (
      <span className={clsxMerge('bg-gray-100 text-gray-700', baseClassName)}>
        {isAdmin ? 'Ongoing' : 'Arrived'}
      </span>
    );
  }

  return <span className={clsxMerge('bg-blue-100 text-blue-700', baseClassName)}>Confirmed</span>;
}
