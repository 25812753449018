import {
  CreateUserProviderDto,
  UpdateUserProviderDto,
  UserOperation,
  UserProvider,
  UserProvidersApi,
} from '@healthhub/api-lib';
import axios, { AxiosRequestConfig } from 'axios';

import HealthHubApiClient, { API_BASE_URL } from './api-client';
import { CustomError } from '../errors';

const userProviderApi = HealthHubApiClient.use(UserProvidersApi);

export async function validateUserProviderEmail(email: string): Promise<any> {
  try {
    const response = await userProviderApi.userProviderControllerValidateEmail({
      email,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function updateUserProvider(
  id: string,
  updateUserProviderDto: UpdateUserProviderDto,
): Promise<UserProvider> {
  try {
    const response = await userProviderApi.userProviderControllerUpdate(id, updateUserProviderDto);

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createUserProvider(userDetails: CreateUserProviderDto): Promise<any> {
  try {
    const response = await userProviderApi.userProviderControllerRegisterUser(userDetails);
    const { data } = response as any;

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getAllProviderUsers(isEshopMerchantsOnly?: boolean) {
  try {
    const { data } = await userProviderApi.userProviderControllerGetAll(isEshopMerchantsOnly);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getProviderUserById(id: string, config?: AxiosRequestConfig) {
  try {
    const { data } = await userProviderApi.userProviderControllerFindOne(id, config);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function deactivateProviderUser(id: number) {
  try {
    await userProviderApi.userProviderControllerDeactivate(id.toString());
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function activateProviderUser(id: number) {
  try {
    await userProviderApi.userProviderControllerActivate(id.toString());
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getCurrentProviderUser(config: RequestInit): Promise<UserOperation> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/operation-users/me`, config);
    const data = await response.json();

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}
