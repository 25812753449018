import { useState, useEffect, useRef, MutableRefObject } from 'react';

interface UseStickyResult {
  isSticky: boolean;
  elementRef: MutableRefObject<HTMLDivElement | null>;
}

const useSticky = (): UseStickyResult => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [initialTop, setInitialTop] = useState<number | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current && initialTop !== null) {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop >= initialTop && !isSticky) {
          setIsSticky(true);
        } else if (scrollTop <= initialTop && isSticky) {
          setIsSticky(false);
        }
      }
    };

    if (elementRef.current && initialTop === null) {
      const rect = elementRef.current.getBoundingClientRect();
      setInitialTop(rect.top + window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [initialTop, isSticky]);

  return { isSticky, elementRef };
};

export default useSticky;
