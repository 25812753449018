import AddressFormFields from '../../ProviderFormFields/AddressFormFields';

type Props = {
  showCoordinates?: boolean;
};

function ProviderAddressDetails(props: Props) {
  return (
    <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
      <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
        Company Address
      </span>
      <AddressFormFields showCoordinates={props.showCoordinates} />
    </div>
  );
}

export default ProviderAddressDetails;
