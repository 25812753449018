import {
  CreateProviderBranchDto,
  Provider,
  UpdateAddressDto,
  UpdateProviderBranchDto,
} from '@healthhub/api-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, Resolver, useForm } from 'react-hook-form';

import BranchDetails from './forms/BranchDetails';
import BranchPaymentMethods from './forms/BranchPaymentMethods';
import { COUNTRY_CODES, SHOW_DIGITAL_IDS, SHOW_PAYMENT_QR_CODE } from '../../constants';
import { ProviderStatusEnum, Routes } from '../../enums';
import {
  useCreateProviderBranchMutation,
  useRouter,
  useUpdateProviderBranchMutation,
} from '../../hooks';
import {
  ensureDecimalPlaces,
  formatProviderDisplayNameWithBranch,
  formatToHumanReadable,
  parseLatLongString,
} from '../../utils';
import { trackEvent, EventName } from '../../utils/analytics';
import { branchSchema } from '../../validators/provider-profile';
import ProviderDigitalId from '../ProviderDigitalId';
import AddressFormFields from '../ProviderFormFields/AddressFormFields';
import RepresentativeFormFields from '../ProviderFormFields/RepresentativeFormFields';
import StatusFormFields from '../ProviderFormFields/StatusFormFields';
import toast from '../toast';
import Button from '../v2/Button';

export type AddRepresentativeType = {
  countryCode: string;
  email: string;
  firstName: string;
  id?: number;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  phoneCountryCode: string;
  phoneNumber: string;
  title: string;
};

export type AddAddressType = {
  barangay: string;
  coordinates: string;
  country: string;
  googleMapsUrl: string;
  line: string;
  lotBlockPhaseHouse: string;
  municipalityCity: string;
  province: string;
  region?: number;
  streetName: string;
  unitRoomFloor: string;
};

export type AddBranchType = {
  address: AddAddressType;
  bannerUrl: string;
  branch: string;
  countryCode: string;
  displayName: string;
  logoUrl: string;
  mobilePhoneNumber: string;
  officeHours: string;
  parentProviderId: number;
  paymentQrCodeUrl: string;
  providerTypeIds: number[];
  representative: AddRepresentativeType;
  status: ProviderStatusEnum;
};

type Props = {
  initialBrandId?: number;
  parentProvider?: Provider;
  provider?: Provider;
  providers?: Provider[];
  showAddressCoordinates?: boolean;
  showProviderSelection?: boolean;
  showStatusSection?: boolean;
  onSave?: () => void;
};

const PH_CODE = COUNTRY_CODES[0];

function BranchForm(props: Props) {
  const router = useRouter();
  const {
    initialBrandId,
    parentProvider,
    provider,
    providers,
    showAddressCoordinates,
    showProviderSelection,
    showStatusSection,
    onSave,
  } = props;
  const isEditing = !!provider?.id;

  const methods = useForm<AddBranchType>({
    resolver: yupResolver(branchSchema) as unknown as Resolver<AddBranchType>,
    defaultValues: {
      parentProviderId: provider?.parentProvider?.id || parentProvider?.id || initialBrandId,
      ...tranformProviderToFormValues(getInitialFormProvider()),
    },
  });

  function getInitialFormProvider() {
    if (isEditing) {
      return provider;
    } else if (parentProvider) {
      return parentProvider;
    } else if (initialBrandId && providers) {
      return providers?.find((p) => p.id === initialBrandId);
    }
  }

  const handleSuccess = async (provider: Provider) => {
    if (provider.isBranch) {
      toast({
        message: `Branch ${isEditing ? 'updated' : 'created'}`,
        type: 'success',
      });

      if (isEditing) {
        trackEvent(EventName.UPDATE_PROVIDER_BRANCH_PROFILE);
      } else {
        trackEvent(EventName.ADD_NEW_PROVIDER_BRANCH);
      }

      onSave?.();

      return;
    }

    toast({
      message: `Provider ${isEditing ? 'updated' : 'created'}`,
      type: 'success',
    });

    if (isEditing) {
      trackEvent(EventName.UPDATE_PROVIDER_PROFILE);
    } else {
      trackEvent(EventName.ADD_NEW_PROVIDER);
    }

    onSave?.();
  };

  const { mutate: updateBranch, isLoading: isUpdating } = useUpdateProviderBranchMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: createBranch, isLoading: isCreating } = useCreateProviderBranchMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  function handleError(errorDetails: any) {
    let message = 'Something went wrong';

    if (Array.isArray(errorDetails)) {
      errorDetails.forEach((errorDetail) => {
        if (errorDetail.errorProperty === 'branch') {
          message = errorDetail.errorMessage;
        }
        methods.setError(errorDetail.errorProperty, {
          type: 'exists',
          message: errorDetail.errorMessage,
        });
      });
    }

    toast({
      message,
      type: 'error',
    });
  }

  const onSubmit = async (data: AddBranchType) => {
    const { coordinates, ...rest } = data.address;
    const { latitude, longitude } = parseLatLongString(coordinates);
    const modifiedAddressData = { ...rest, latitude, longitude } as unknown as UpdateAddressDto;

    const { id, providerTypes } =
      parentProvider || providers?.find((provider) => provider.id === data.parentProviderId) || {};

    if (!id || !providerTypes) {
      return;
    }

    const providerData = {
      address: modifiedAddressData,
      bannerUrl: data.bannerUrl,
      logoUrl: data.logoUrl,
      paymentQrCodeUrl: data.paymentQrCodeUrl,
      branch: data.branch,
      countryCode: data.countryCode,
      displayName: data.displayName,
      mobilePhoneNumber: data.mobilePhoneNumber,
      officeHours: data.officeHours,
      parentProviderId: id,
      providerTypeIds: providerTypes?.map((providerType) => providerType.id) || [],
      representatives: [data.representative],
    } as UpdateProviderBranchDto;

    if (isEditing && provider?.id) {
      updateBranch({
        ...providerData,
        id: +provider?.id,
        status: data.status,
      });
    } else {
      createBranch(providerData as CreateProviderBranchDto);
    }
  };

  function tranformProviderToFormValues(
    provider?: Provider,
  ): Omit<AddBranchType, 'parentProviderId' | 'providerTypeIds'> {
    const [rep] = provider?.representatives || [];
    const address = provider?.address;

    return {
      bannerUrl: provider?.bannerUrl || '',
      logoUrl: provider?.logoUrl || provider?.parentProvider?.logoUrl || '',
      displayName: provider?.displayName || '',
      branch: provider?.branch || '',
      officeHours: provider?.officeHours || '',
      status: (provider?.status as ProviderStatusEnum) || ProviderStatusEnum.UNPUBLISHED,
      mobilePhoneNumber: provider?.mobilePhoneNumber || '',
      countryCode: provider?.countryCode || PH_CODE.dial_code,
      paymentQrCodeUrl: provider?.paymentQrCodeUrl || '',
      address: {
        barangay: address?.barangay || '',
        country: address?.country || COUNTRY_CODES[0].name,
        coordinates: `${ensureDecimalPlaces(address?.latitude) || '0'}, ${
          ensureDecimalPlaces(address?.longitude) || '0'
        }`,
        line: address?.line || '',
        municipalityCity: address?.municipalityCity || '',
        province: address?.province || '',
        region: address?.region?.id,
        unitRoomFloor: address?.unitRoomFloor || '',
        lotBlockPhaseHouse: address?.lotBlockPhaseHouse || '',
        streetName: address?.streetName || '',
        googleMapsUrl: address?.googleMapsUrl ?? '',
      },
      representative: {
        id: isEditing ? rep?.id : undefined,
        email: rep?.email || '',
        firstName: rep?.firstName || '',
        lastName: rep?.lastName || '',
        middleName: rep?.middleName || '',
        countryCode: rep?.countryCode || PH_CODE.dial_code,
        phoneCountryCode: rep?.phoneCountryCode || PH_CODE.dial_code,
        mobileNumber: rep?.mobileNumber || '',
        phoneNumber: rep?.phoneNumber || '',
        title: rep?.title || '',
      },
    };
  }

  const handleChangeBrand = (brand?: Provider) => {
    if (isEditing || !brand) {
      return;
    }

    const formFields = tranformProviderToFormValues(brand);

    Object.keys(formFields).forEach((fieldName) => {
      methods.setValue(
        fieldName as keyof AddBranchType,
        formFields[fieldName as keyof typeof formFields],
      );
    });

    methods.trigger('address');
  };

  const lastUpdatedDate = formatToHumanReadable(
    provider?.updatedAt || provider?.createdAt || new Date(),
  );

  const handleCancelSave = () => {
    router.push(Routes.BRANCHES);
  };

  const brandOptions = initialBrandId
    ? providers?.filter((p) => p.id === initialBrandId)
    : providers;

  const isLoading = isCreating || isUpdating;
  return (
    <FormProvider {...methods}>
      <form>
        <div className="flex flex-col gap-8">
          {showStatusSection && (
            <div className="rounded-md border border-neutral-300 bg-white px-6 py-4 md:p-8">
              <h2 className="mb-4 text-lg font-semibold">Provider Status</h2>
              <StatusFormFields />
            </div>
          )}
          <BranchDetails
            providers={brandOptions}
            showProviderSelection={showProviderSelection}
            onChangeBrand={handleChangeBrand}
          />
          <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
            <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
              Branch Address
            </span>
            <AddressFormFields showCoordinates={showAddressCoordinates} />
          </div>
          <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
            <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
              Representative Information
            </span>
            <RepresentativeFormFields />
          </div>
          {SHOW_PAYMENT_QR_CODE && (
            <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
              <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
                Payment Methods
              </span>
              <BranchPaymentMethods />
            </div>
          )}
          {SHOW_DIGITAL_IDS && (
            <ProviderDigitalId
              qrCodeUrl="https://api.qrserver.com/v1/create-qr-code/?size=185x185&ecc=L&qzone=1&data=http%3A%2F%2Fexample.com%2F"
              providerName={formatProviderDisplayNameWithBranch(provider as Provider)}
              providerType={
                provider?.providerTypes
                  ?.map((providerType) => providerType?.name)
                  .filter(Boolean)
                  .join(', ') || ''
              }
            />
          )}
        </div>
        <div className="flex-column mt-8 flex justify-center gap-2 md:justify-between">
          <div className="mb-[75px] text-xs text-neutral-500 md:mb-0">
            {isEditing && (
              <>
                <span className="font-semibold">Last updated:</span> <span>{lastUpdatedDate}</span>
              </>
            )}
          </div>
          <div className="flex-column hidden gap-3 md:flex">
            <Button
              onClick={methods.handleSubmit(onSubmit)}
              variant="primary"
              className="w-36"
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
          <div className="fixed bottom-0 z-[99] grid w-full grid-cols-2 gap-x-2 bg-white px-6 py-2 md:hidden">
            <Button
              onClick={handleCancelSave}
              variant="secondaryGray"
              className="col-span-1 w-full"
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={methods.handleSubmit(onSubmit)}
              variant="primary"
              className="col-span-1 w-full"
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default BranchForm;
