import clsx from 'clsx';

import { CheckoutProgressSteps } from '../enums';
import { Icon } from '../images/checkout-icons';

type Props = {
  activeStep: string;
};

export default function CheckoutProgress(props: Props) {
  const { activeStep } = props;

  const steps = [
    {
      title: 'Buyer',
      id: CheckoutProgressSteps.BUYER,
      icon: <Icon name="personal" />,
      description: 'Details about buyer',
    },
    {
      title: 'Details',
      id: CheckoutProgressSteps.DETAILS,
      icon: <Icon name="account" />,
      description: 'Your payment details',
    },
    {
      title: 'Order',
      id: CheckoutProgressSteps.SUCCESS,
      icon: <Icon name="document" />,
      description: 'Your order confirmation',
    },
  ];
  const renderSteps = steps.map((step) => {
    const isActive = step.id === activeStep;
    const isCompleted =
      steps.findIndex((s) => s.id === activeStep) > steps.findIndex((s) => s.id === step.id);
    const isActiveStepSuccess = isActive && step.id === CheckoutProgressSteps.SUCCESS;

    return (
      <div
        key={step.id}
        className={clsx('flex flex-grow flex-col items-start gap-4 border-t-4', {
          'border-neutral-300 text-neutral-700': !isActive && !isCompleted,
          'text-primary1': isActive,
          'text-success1': isCompleted || isActiveStepSuccess,
        })}
      >
        <div className="gap-2 pt-4">
          <div className="flex items-start gap-1 sm:gap-3">
            <div
              className={clsx('flex h-full items-start justify-center p-[3px]', {
                'text-neutral-500': !isActive && !isCompleted,
              })}
            >
              {isCompleted || isActiveStepSuccess ? <Icon name="check" /> : step.icon}
            </div>
            <div className="flex flex-col items-start gap-1">
              <p
                className={clsx('ext-base max-md:text-sm', {
                  'font-bold': isActive && !isActiveStepSuccess,
                  'font-medium': !isActive || isActiveStepSuccess,
                })}
              >
                {step.title}
              </p>
              <p
                className={clsx('text-sm max-md:hidden lg:text-base', {
                  'font-semibold': isActive && !isActiveStepSuccess,
                  'font-normal': !isActive || isActiveStepSuccess,
                })}
              >
                {step.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return <div className="flex w-full items-start gap-6">{renderSteps}</div>;
}
