/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateCategoryDto, EshopApi, UpdateCategoryDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors';
import { Category, Pagination } from '../types';

export const eshopCategoryApi = HealthHubApiClient.use(EshopApi);

export type UpdateCategoryParams = {
  id: string;
  updateCategoryDto: UpdateCategoryDto;
};

export type CategoryParams = {
  limit?: number;
  page?: number;
  query?: string;
  isMwellCategory?: boolean;
};

export async function getAllEshopCategories(
  params?: CategoryParams,
): Promise<Pagination<Category>> {
  try {
    const { query, isMwellCategory, limit, page } = params ?? {};
    const { data } = await eshopCategoryApi.eshopControllerFindAllCategories(
      query,
      isMwellCategory ? isMwellCategory.toString() : undefined,
      limit,
      page,
    );

    return data as unknown as Pagination<Category>;
  } catch (err) {
    throw new Error('Failed to fetch categories');
  }
}

export async function getEshopCategoryById(
  id: string,
  config?: AxiosRequestConfig,
): Promise<Category> {
  try {
    const response = await eshopCategoryApi.eshopControllerFindOneCategory(id, config);
    const data = response.data;

    return data as unknown as Category;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createEshopCategory(createCategory: CreateCategoryDto): Promise<Category> {
  try {
    const { data } = await eshopCategoryApi.eshopControllerCreateCategory(createCategory);

    return data as Category;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateEshopCategory(params: UpdateCategoryParams): Promise<Category> {
  try {
    const response = await eshopCategoryApi.eshopControllerUpdateCategory(
      params.id,
      params.updateCategoryDto,
    );
    const data = response.data;

    return data as unknown as Category;
  } catch (error) {
    throw new Error('Failed to update category');
  }
}

export async function updateEshopCategoryBatch(params: UpdateCategoryDto[]): Promise<Category[]> {
  try {
    const response = await eshopCategoryApi.eshopControllerUpdateCategories({
      data: {
        categories: params,
      },
    });
    const data = response.data;

    return data as unknown as Category[];
  } catch (error) {
    throw new Error('Failed to update category');
  }
}

export async function deleteEshopCategory(
  id: string,
  config?: AxiosRequestConfig,
): Promise<Category> {
  try {
    const response = await eshopCategoryApi.eshopControllerDeleteCategory(id, config);
    const data = response.data;

    return data as unknown as Category;
  } catch (error) {
    throw new CustomError(error);
  }
}
