import { useEffect, useState } from 'react';

import { Provider } from '@healthhub/api-lib';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { collection, onSnapshot } from 'firebase/firestore';
import { twMerge } from 'tailwind-merge';

import MessageThreadItem from './MessageThreadItem';
import { useMessageServiceFirestore } from '../../auth/firebase';
import { useGetMemberMessageThreadsQuery, useRouter } from '../../hooks';
import {
  MessageThread,
  MessageThreadWithMembersAndLastMessage,
  MessagingCollections,
  Option,
} from '../../types';
import { getThreadName } from '../../utils';
import Dropdown from '../Dropdown';
import LoadingSpinner from '../LoadingSpinner';
import SearchInput from '../SearchInput';

type Props = {
  senderUserId: string;
  selectedThread?: MessageThread;
  onSelect: (thread: MessageThread) => void;
  className?: string;
  branchId?: string;
  isProvider?: boolean;
  isBranch?: boolean;
  branches?: Provider[];
  setSelectedBranchId?: React.Dispatch<React.SetStateAction<string>>;
};

const MessageThreadsListSection = (props: Props) => {
  const { firestore } = useMessageServiceFirestore();
  const router = useRouter();
  const initialThreadId = router.query.threadId ? String(router.query.threadId) : '';
  const {
    senderUserId,
    selectedThread,
    onSelect,
    className,
    branchId = '',
    isProvider = false,
    isBranch,
    branches = [],
    setSelectedBranchId,
  } = props;
  const {
    data: messageThreads,
    refetch,
    isInitialLoading,
    isFetching: isFetchingThreads,
  } = useGetMemberMessageThreadsQuery(branchId, isProvider);
  const [search, setSearch] = useState('');

  const filteredMessageThreads = messageThreads?.filter((messageThread) => {
    const thread = messageThread as MessageThreadWithMembersAndLastMessage;
    const nameDisplay = getThreadName(thread, senderUserId).toLocaleLowerCase();

    if (!nameDisplay) {
      return false;
    }

    return nameDisplay.includes(search.toLowerCase().trim());
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore, MessagingCollections.RefetchTriggers),
      (snapshot) => {
        refetch();
      },
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!isInitialLoading && initialThreadId) {
      const initialThread = messageThreads?.find((thread) => thread.id === initialThreadId);

      if (initialThread) {
        onSelect(initialThread);
      }
    }
  }, [isInitialLoading, initialThreadId]);

  const transformBranchesToDropdownOptions = branches?.map((provider) => {
    return {
      label: `${provider.displayName} (${provider.branch})`,
      value: provider.id,
    };
  });
  const initialOption =
    transformBranchesToDropdownOptions.find((option) => option.value === +branchId) ||
    transformBranchesToDropdownOptions[0];
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const shouldDisplayBranchDropdown = isProvider && !isBranch && branches.length > 0;
  const isLoading = isInitialLoading || (shouldDisplayBranchDropdown && isFetchingThreads);
  const handleProviderSelect = (option: Option) => {
    setSelectedOption(option);

    if (setSelectedBranchId) {
      setSelectedBranchId(option.value.toString());
    }
  };
  const searchPlaceholder = isProvider ? 'Search Name' : 'Search Provider';

  return (
    <div
      className={twMerge(
        'flex h-full flex-col gap-2 px-2 pt-2 sm:min-h-[50vh] sm:min-w-[300px] sm:max-w-[500px] ',
        className,
      )}
    >
      <div>
        <div className="ml-2 hidden text-2xl font-bold sm:block">Messages</div>
        {shouldDisplayBranchDropdown && (
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Gateway"
            className="mx-2 mt-2 max-w-[470px]"
            icon={<BuildingOfficeIcon className="mr-2 h-8 w-8 text-neutral-500" />}
            options={transformBranchesToDropdownOptions}
            value={selectedOption}
            onChange={(e) => {
              handleProviderSelect(e);
            }}
          />
        )}
      </div>
      <SearchInput
        placeholder={searchPlaceholder}
        onChange={(value) => setSearch(value || '')}
        iconSize="sm"
        iconVariant="plain"
        className="mx-2"
      />
      {isLoading && (
        <div className="mt-2 flex w-full justify-center">
          <LoadingSpinner />
        </div>
      )}
      <div className="flex max-h-[80vh] flex-col gap-2 overflow-y-scroll pb-24 md:pb-0">
        {!isLoading &&
          filteredMessageThreads?.map((messageThread) => (
            <MessageThreadItem
              key={messageThread.id}
              thread={messageThread as MessageThreadWithMembersAndLastMessage}
              isSelected={messageThread.id === selectedThread?.id}
              onSelect={onSelect}
              senderUserId={senderUserId}
              isProvider={isProvider}
              isBranch={isBranch}
            />
          ))}
      </div>
      {!isLoading && (!messageThreads || messageThreads.length === 0) && (
        <div className="flex grow justify-center text-gray-400">No message threads</div>
      )}
    </div>
  );
};

export default MessageThreadsListSection;
