/* eslint-disable @typescript-eslint/no-explicit-any */
import { RawRule } from '@casl/ability';
import {
  CreateUserDto,
  LoginWithPhoneNumberDto,
  RegisterWithPhoneNumberDto,
  UsersApi,
  VerifyOtpNumberDto,
  VerifyOtpNumberResponseDto,
} from '@healthhub/api-lib';
import axios from 'axios';
import Cookies from 'js-cookie';

import { ACCESS_TOKEN, USER, USER_ABILITIES, setLocalStorageItem } from '@mwell-healthhub/commons';
import { API_BASE_URL } from '@mwell-healthhub/commons/api/api-client';

import HealthHubApiClient from '../api-client/api-client';
import { UserCredentials } from '../types/auth.types';

export interface LoginResponse {
  accessToken: string;
  user: any;
  userAbilities: RawRule[];
}

export const cookieOptions = {
  expires: 1 / 3, // expires in 8 hours
};

const usersApi = HealthHubApiClient.use(UsersApi);

export async function loginByEmail(params: UserCredentials): Promise<any> {
  const { email, password } = params;

  let response;
  try {
    response = await usersApi.userControllerLoginUser({
      email,
      password,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    }
    throw error;
  }

  const { data } = response as any;

  if (!data || !('accessToken' in data)) {
    throw new Error('Invalid response: missing accessToken');
  }

  const { accessToken, user, userAbilities } = data as LoginResponse;

  setLocalStorageItem(ACCESS_TOKEN, accessToken);
  setLocalStorageItem(USER, user);
  setLocalStorageItem(USER_ABILITIES, userAbilities);

  Cookies.set(ACCESS_TOKEN, accessToken, cookieOptions);

  return data;
}

export async function loginWithPhoneNumber(params: LoginWithPhoneNumberDto): Promise<any> {
  const { data } = await usersApi.userControllerLoginWithPhoneNumber(params);

  return data;
}

// Using fetch here as Axios does not work in middleware
export async function loginAutomations(): Promise<any> {
  try {
    const url = new URL('/api/v1/users/login/automations', API_BASE_URL).toString();
    const response = await fetch(url, {
      method: 'POST',
    });

    const result = await response.json();

    return result;
  } catch (error) {
    // Fail silently
    return null;
  }
}

// Using fetch here as Axios does not work in middleware
export async function loginWithMWellAccessToken(
  mWellAccessToken: string,
  mwellRefreshToken: string,
  mWellPlatform: string,
  mWellEnv: string,
): Promise<any> {
  const url = new URL('/api/v1/users/login/mwell-access-token', API_BASE_URL).toString();
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'mwell-access-token': mWellAccessToken,
      'mwell-platform': mWellPlatform,
      'mwell-refresh-token': mwellRefreshToken,
      'mwell-env': mWellEnv,
    },
  });

  return response.json();
}

export async function loginVerifyOtpNumber(
  params: VerifyOtpNumberDto,
): Promise<VerifyOtpNumberResponseDto> {
  const { data } = await usersApi.userControllerLoginVerifyOtpNumber(params);

  return data;
}

export async function registerWithPhoneNumber(params: RegisterWithPhoneNumberDto): Promise<any> {
  const { data } = await usersApi.userControllerRegisterWithPhoneNumber(params);

  return data;
}

export async function registerVerifyOtpNumber(
  params: VerifyOtpNumberDto,
): Promise<VerifyOtpNumberResponseDto> {
  const { data } = await usersApi.userControllerRegisterVerifyOtpNumber(params);

  return data;
}

export async function registerSaveUserDetails(
  params: CreateUserDto & { mWellRefreshToken: string; mWellAccessToken: string },
): Promise<any> {
  const { mWellAccessToken, mWellRefreshToken, ...userData } = params;

  const { data } = await usersApi.userControllerSaveUserDetails(
    mWellAccessToken,
    mWellRefreshToken,
    userData,
  );

  return data;
}

export async function registerByEmail(userDetails: CreateUserDto): Promise<any> {
  let response;

  try {
    response = await fetch(new URL('/api/v1/users/register', API_BASE_URL).toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userDetails),
    });

    response = await response.json();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }

  if (!response || !('accessToken' in response)) {
    throw new Error('Invalid response: missing accessToken');
  }

  const { accessToken, user } = response as LoginResponse;

  setLocalStorageItem(ACCESS_TOKEN, accessToken);
  setLocalStorageItem(USER, user);

  Cookies.set(ACCESS_TOKEN, accessToken, cookieOptions);

  return response;
}
