import {
  CreateProviderClientProfileDto,
  ProviderClientProfile,
  UpdateProviderClientProfileDto,
} from '@healthhub/api-lib';
import { MutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  GetProviderClientProfileByDto,
  createProviderProfileClient,
  getProviderProfileClient,
  getProviderProfileClientByParams,
  updateProviderClientProfile,
} from '../../api/providerClientProfileService';

export const useCreateProviderClientProfileMutation = (
  mutationOptions?: MutationOptions<
    ProviderClientProfile,
    AxiosError<{ message: string }>,
    CreateProviderClientProfileDto
  >,
) => useMutation(createProviderProfileClient, mutationOptions);

export const useUpdateProviderClientProfileMutation = (
  mutationOptions?: MutationOptions<
    ProviderClientProfile,
    AxiosError<{ message: string }>,
    { id: string; updateProviderClientProfileDto: UpdateProviderClientProfileDto }
  >,
) =>
  useMutation(
    (params) => updateProviderClientProfile(params.id, params.updateProviderClientProfileDto),
    mutationOptions,
  );

export const useGetProviderClientProfileQuery = (
  id: string,
  options?: UseQueryOptions<ProviderClientProfile>,
) => {
  return useQuery<ProviderClientProfile>([id], () => getProviderProfileClient(id), options);
};

export const useGetProviderClientProfileByProvider = (
  params: GetProviderClientProfileByDto,
  options?: UseQueryOptions<ProviderClientProfile[]>,
) => {
  const { query, providerId, limit } = params;
  return useQuery<ProviderClientProfile[]>(
    [query, providerId, limit],
    () => getProviderProfileClientByParams(params),
    options,
  );
};
