import React, { DragEvent, Fragment, useEffect, useId, useMemo, useState } from 'react';

import clsx from 'clsx';
import { CloudUpload, Trash } from 'iconoir-react';

import { FileSizeLimitEnum, FileTypeExtensionEnum } from '../constants';
import { UploadType } from '../types';
import {
  formatBytesToMegabytes,
  formatFileTypesForLabel,
  formatFullDateTime,
  validateFiles,
} from '../utils';

type Props = {
  acceptedFileTypes?: FileTypeExtensionEnum[];
  value?: File[] | null;
  onFileChange: (files: File[]) => void;
  selectedFile?: File;
  maxSizeLimit?: number;
  isDisabled?: boolean;
  multiple?: boolean;
};

const DEFAULT_SUPPORTED_FILETYPES = [
  FileTypeExtensionEnum.JPG,
  FileTypeExtensionEnum.JPEG,
  FileTypeExtensionEnum.PNG,
];
const DEFAULT_MAX_SIZE_LIMIT = FileSizeLimitEnum._50MB;

const FileUpload: React.FC<Props> = ({
  acceptedFileTypes = DEFAULT_SUPPORTED_FILETYPES,
  value,
  onFileChange,
  maxSizeLimit = DEFAULT_MAX_SIZE_LIMIT,
  isDisabled,
  multiple,
  selectedFile,
}: Props) => {
  const [dragging, setDragging] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>(value || []);
  const [selectedDateTimes, setSelectedDateTimes] = useState<string[]>([]);
  const id = useId();

  const accept = useMemo(() => {
    return acceptedFileTypes.map((fileType) => `.${fileType}`).join(',');
  }, [acceptedFileTypes]);

  const errorComponent = useMemo(() => {
    return hasError ? (
      <div className="mt-1 text-sm text-red-500">
        The file you tried to upload is not supported.
      </div>
    ) : null;
  }, [hasError]);

  useEffect(() => {
    if (selectedFile && selectedFiles.length === 0) {
      setFileData([selectedFile], false);
    }
  }, [selectedFile]);

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);

    await handleFileChange(e.dataTransfer.files);
  };

  const handleBrowseFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await handleFileChange(e.target.files);
  };

  const setFileData = async (files: File[], shouldFileChange: boolean) => {
    try {
      setSelectedFiles(files);
      setSelectedDateTimes(files.map(() => formatFullDateTime(new Date())));
      if (shouldFileChange) {
        onFileChange(files);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const handleFileChange = async (data: FileList | null) => {
    try {
      setHasError(false);
      const files = Array.from(data as FileList);
      const validFiles = await validateFiles(files, { acceptedFileTypes });

      await setFileData(validFiles, true);
    } catch {
      setHasError(true);
    }
  };

  const handleRemoveFile = (idx?: number) => {
    if (idx !== undefined) {
      const filteredFiles = selectedFiles.filter((_, i) => i !== idx);
      const filteredDateTimes = selectedDateTimes.filter((_, i) => i !== idx);

      setSelectedFiles(filteredFiles);
      setSelectedDateTimes(filteredDateTimes);
      onFileChange(filteredFiles);
    } else {
      setSelectedFiles([]);
      setSelectedDateTimes([]);
      onFileChange([]);
    }
  };

  const hasNoFile = selectedFiles.length === 0;

  return (
    <Fragment>
      <div
        className={clsx(
          { 'rounded-md border-2 border-dashed p-4': !selectedFiles.length },
          { 'flex flex-col gap-2': !!selectedFiles.length },
          dragging ? 'border-primary' : 'border-gray-300',
          hasError ? 'border-red-500' : 'border-gray-300',
          { 'bg-gray21': hasNoFile },
        )}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {hasNoFile ? (
          <div className="flex flex-col p-8 text-center">
            <div>
              <p className="flex items-center justify-center">
                <CloudUpload />
              </p>
              <div className="text-gray17">
                Drag & drop file to upload or{' '}
                <input
                  id={`${id}-file-input`}
                  type="file"
                  className="hidden"
                  accept={accept}
                  onChange={handleBrowseFile}
                  disabled={isDisabled}
                  size={maxSizeLimit}
                  multiple={multiple}
                />
                <label htmlFor={`${id}-file-input`} className="cursor-pointer text-lightPrimary2">
                  Browse files
                </label>
              </div>
              <div>
                <p className="whitespace-pre-line text-xs italic text-gray-500">
                  The supported file types: {formatFileTypesForLabel(acceptedFileTypes)}
                  {'\n'}
                  The maximum file size limit: {formatBytesToMegabytes(maxSizeLimit)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          selectedFiles.map((file, idx) => (
            <div
              className={clsx(
                'flex w-full items-center justify-between rounded-md border-2 border-dashed p-4',
              )}
              key={file.name}
            >
              <div className="flex w-4/5 flex-col break-all text-sm font-semibold text-black">
                {file.name}
                <div className="w-4/5 break-all text-xs text-black">{selectedDateTimes?.[idx]}</div>
              </div>
              <div
                onClick={() => handleRemoveFile(idx)}
                className="cursor-pointer text-primary1 hover:text-primary1/60"
              >
                <Trash className="mx-1 text-red4" />
              </div>
            </div>
          ))
        )}
      </div>
      {errorComponent}
    </Fragment>
  );
};

export default FileUpload;
