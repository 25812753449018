import {
  CreateProviderClientProfileDto,
  ProviderClientProfile,
  ProviderClientProfileApi,
  UpdateProviderClientProfileDto,
} from '@healthhub/api-lib';
import axios from 'axios';

import HealthHubApiClient from './api-client';

export const providerApi = HealthHubApiClient.use(ProviderClientProfileApi);

export type GetProviderClientProfileByDto = {
  providerId?: string;
  query?: string;
  limit?: number;
};

export async function createProviderProfileClient(
  createProviderClientProfileDto: CreateProviderClientProfileDto,
): Promise<ProviderClientProfile> {
  try {
    const { data } = await providerApi.providerClientProfileControllerCreate(
      createProviderClientProfileDto,
    );

    return data;
  } catch (err: any) {
    const error = err.response?.data;

    if (error.message) {
      throw new Error(error.message);
    }

    throw new Error('Failed to create client');
  }
}

export async function getProviderProfileClient(id: string): Promise<ProviderClientProfile> {
  try {
    const { data } = await providerApi.providerClientProfileControllerFindOne(id);

    return data;
  } catch (err) {
    throw new Error('Failed to get provider client profile');
  }
}

export async function validateProviderClientEmail(email: string): Promise<any> {
  try {
    const response = await providerApi.providerClientProfileControllerValidateEmail(email);

    const { data } = response as any;

    if (!data) {
      throw new Error('Invalid response');
    }

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getProviderProfileClientByParams(
  params: GetProviderClientProfileByDto,
): Promise<ProviderClientProfile[]> {
  const { query, providerId, limit } = params;
  try {
    const { data } = await providerApi.providerClientProfileControllerFindAllByProviderParams(
      providerId,
      query,
      limit,
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error('Failed to get provder profile client');
    }
  }
}

export async function updateProviderClientProfile(
  id: string,
  updateProviderClientProfileDto: UpdateProviderClientProfileDto,
): Promise<ProviderClientProfile> {
  try {
    const { data } = await providerApi.providerClientProfileControllerUpdate(
      id,
      updateProviderClientProfileDto,
    );

    return data;
  } catch (err) {
    throw new Error('Failed to update client');
  }
}
