import isEmpty from 'lodash.isempty';
import queryString from 'querystring';

export const handleQuery = <T extends Record<string, any>>(
  filters: T,
  handleRoute: (query?: string) => void,
) => {
  const keys = Object.keys(filters);
  let newQuery = '';
  let mappedKeys = {};

  keys.forEach((key) => {
    const isString = typeof filters?.[key] === 'string';
    const isNotEmpty = isString ? !!filters?.[key]?.length : !!filters?.[key];
    mappedKeys = { ...mappedKeys, ...(isNotEmpty && { [key]: filters[key] }) };
  });

  if (!isEmpty(filters)) {
    newQuery = queryString.stringify(mappedKeys);
  }

  handleRoute(newQuery);
};
