/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef, useState } from 'react';

import { UserProvider } from '@healthhub/api-lib';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { EyeAlt } from 'iconoir-react';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import CsvDownloader from 'react-csv-downloader';
import { DateObject } from 'react-multi-date-picker';

import TransactionStatistics from './TransactionStatistics';
import ViewTransactionModal from './ViewTransactionModal';
import { ALL, ORDER_STATUSES, SHORT_DATE_US_FORMAT } from '../../constants';
import { OrderStatusEnum, PaymentStatusEnum } from '../../enums';
import {
  useGetAllTransactionMerchantsQuery,
  useGetTransactionCounts,
  useQueryParamState,
  useToggle,
} from '../../hooks';
import { Option, TransactionMerchant } from '../../types';
import {
  addInt,
  clsxMerge,
  convertObjectsToOptions,
  formatToHumanReadable,
  formatToPeso,
  getDateOnly,
  intToPrice,
  subtractInt,
  toTitleCase,
} from '../../utils';
import { searchValidator } from '../../validators';
import Button from '../Button';
import CustomDatePicker from '../DatePicker';
import Dropdown from '../Dropdown';
import OperationsPagination from '../OperationsPagination';
import SearchInput from '../SearchInput';
import Table, { TableColumn } from '../Table';
import { PaymentStatusBadge } from '../v2';

const PAYMENT_STATUSES = [
  { name: 'Paid', id: PaymentStatusEnum.PAID },
  { name: 'Not Paid', id: PaymentStatusEnum.NOT_PAID },
];

type Props = {
  isLoadingUsers?: boolean;
  merchantEmail?: string;
  merchants?: UserProvider[];
};

const transformToExportedData = (data: TransactionMerchant[]) => {
  return data
    .map(
      (tran) =>
        tran.transactionMerchantProducts
          ?.map((transaction) => ({
            createdAt: formatToHumanReadable(tran.transaction.createdAt, SHORT_DATE_US_FORMAT),
            transactionId: tran.transaction.id,
            status: tran.transaction.status,
            userId: tran.transaction.userId,
            userEmail: tran.transaction.buyerDetails.email,
            orderId: tran.id,
            orderStatus: tran.status,
            deliveryDate: tran.shippingDate
              ? formatToHumanReadable(tran.shippingDate, SHORT_DATE_US_FORMAT)
              : '-',
            merchantId: tran.merchantId,
            merchantName: tran.merchant?.firstName,
            productId: transaction.variant?.id ?? transaction.product.id,
            vatable: transaction.product.isVatable ? 'Y' : 'N',
            price: intToPrice(transaction.variant?.price ?? transaction.product.price),
            totalAmount: intToPrice(transaction.price),
            paymentId: tran.transaction?.paymentReferenceNumber,
            paymentMethod: tran.transaction?.paymentMethod,
            paymentStatus: tran.transaction?.paymentStatus,
            eShopRevenue: intToPrice(transaction.eshopRevenue),
            eShopOVat: intToPrice(transaction.eshopOVat),
            sfRate: `${transaction.product.sfRate * 100}%`,
            mWellSFNet: intToPrice(transaction.mWellSFNet),
            sfOVat: intToPrice(transaction.sfOVat),
            cwtRate: `${(tran.merchant.cwtRate ?? 0) * 100}%`,
            cwt: intToPrice(transaction.cwt),
            mWellCommission: intToPrice(transaction.mWellCommission),
          }))
          .concat({
            createdAt: formatToHumanReadable(tran.transaction.createdAt, SHORT_DATE_US_FORMAT),
            transactionId: tran.transaction.id,
            status: tran.transaction?.status,
            userId: tran.transaction?.userId,
            userEmail: tran.transaction.buyerDetails.email,
            orderId: tran.id,
            orderStatus: tran.status,
            deliveryDate: tran.shippingDate
              ? formatToHumanReadable(tran.shippingDate, SHORT_DATE_US_FORMAT)
              : '-',
            merchantId: tran.merchantId,
            merchantName: tran.merchant?.firstName,
            productId: 'Shipping Fee',
            vatable: 'N',
            price: intToPrice(tran.shippingFee),
            totalAmount: intToPrice(tran.shippingFee),
            paymentId: tran.transaction?.paymentReferenceNumber,
            paymentMethod: tran.transaction?.paymentMethod,
            paymentStatus: tran.transaction?.paymentStatus,
            eShopRevenue: 0,
            eShopOVat: 0,
            sfRate: '',
            mWellSFNet: 0,
            sfOVat: 0,
            cwtRate: '',
            cwt: 0,
            mWellCommission: 0,
          }),
    )
    .flat();
};

function TransactionsList(props: Props) {
  const { isLoadingUsers = false, merchantEmail = '', merchants = [] } = props;
  const isMerchant = !!merchantEmail;
  const csvRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<OrderStatusEnum>();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<PaymentStatusEnum>();
  const [selectedMerchantEmail, setSelectedMerchantEmail] = useState<string>();
  const [searchValidationError, setSearchValidationError] = useState<string>('');
  const [search, setSearch] = useQueryParamState('search');
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateObject[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [deliveryDateRange, setDeliveryDateRange] = useState<DateObject[]>([]);
  const [deliveryStartDate, setDeliveryStartDate] = useState<string>();
  const [deliveryEndDate, setDeliveryEndDate] = useState<string>();
  const [selectedTransactionMerchant, setSelectedTransactionMerchant] =
    useState<TransactionMerchant>();
  const { data: transactionCounts } = useGetTransactionCounts(merchantEmail);

  const allFilteredQueryParams = cleanUpFilters({
    ...(isMerchant && { merchantEmail }),
    ...(selectedMerchantEmail && { merchantEmail: selectedMerchantEmail }),
    ...(search && { query: search }),
    paymentStatus: selectedPaymentStatus ?? ALL,
    status: selectedStatus ?? ALL,
    dateFrom: startDate,
    dateTo: endDate,
    deliveryDateFrom: deliveryStartDate,
    deliveryDateTo: deliveryEndDate,
  });
  const queryParams = {
    ...allFilteredQueryParams,
    limit: 10,
    page: currentPage,
  };
  const { data: paginatedTransactions, isFetching: isGetTransactionsLoading } =
    useGetAllTransactionMerchantsQuery(queryParams);
  const {
    data: paginatedAllFilteredTransactions,
    isFetching: isGetAllFilteredTransactionsLoading,
  } = useGetAllTransactionMerchantsQuery(allFilteredQueryParams);
  const { handleToggle: handleToggleViewTransactionModal, isOpen: isViewTransactionModalOpen } =
    useToggle();

  const { items: transactions = [], totalPages, itemsPerPage } = paginatedTransactions ?? {};
  const { items: allFilteredTransactions = [] } = paginatedAllFilteredTransactions ?? {};

  const handleViewTransaction = (transaction: TransactionMerchant) => {
    setSelectedTransactionMerchant(transaction);
    handleToggleViewTransactionModal();
  };

  const columns: TableColumn<TransactionMerchant>[] = useMemo(
    () => [
      {
        header: 'Transaction Date',
        key: 'createdAt',
        render: (data) => (
          <div className="text-sm font-semibold text-gray-600">
            {getDateOnly(data.transaction.createdAt)}
          </div>
        ),
      },
      {
        header: 'Order Number',
        key: 'orderId',
      },
      {
        header: 'Order Status',
        key: 'status',
        render: (data) => <span>{toTitleCase(data.status)}</span>,
      },
      {
        header: 'Merchant Name',
        key: 'merchant.name',
        render: (data) => `${data?.merchant.username}`,
      },
      {
        header: 'Delivery Date',
        key: 'deliveryDate',
        render: (data) => (data?.shippingDate ? getDateOnly(data?.shippingDate) : '-'),
      },
      {
        header: 'User Email',
        key: 'user.email',
        render: (data) => data.transaction.buyerDetails.email,
      },
      {
        header: 'Amount',
        key: 'amount',
        render: (data) => (
          <span className="whitespace-nowrap">
            {formatToPeso(
              addInt(subtractInt(data.totalAmount, data.discount), data.transaction.transactionFee),
            )}
          </span>
        ),
      },
      {
        header: 'Payment Status',
        key: 'paymentStatus',
        render: (data) => <PaymentStatusBadge status={data.transaction?.paymentStatus} />,
      },
      {
        header: 'Action',
        key: 'status',
        render: (data) => (
          <Button
            variant="label"
            className="h-fit !p-0 text-primary-500"
            icon={EyeAlt}
            onClick={() => handleViewTransaction(data)}
          >
            <span className="font-normal">View</span>
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const transactionStatusOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Status' }, ...ORDER_STATUSES],
    {
      includeNone: false,
    },
  );
  const merchantObjects = merchants.map((merchant) => ({
    id: merchant.email,
    name: merchant.username,
  }));
  const merchantOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Merchants' }, ...merchantObjects],
    {
      includeNone: false,
    },
  );
  const paymentStatusOptions = convertObjectsToOptions(
    [{ id: ALL, name: 'All Payment Status' }, ...PAYMENT_STATUSES],
    {
      includeNone: false,
    },
  );

  const handlePaymentStatusSelection = (paymentStatus: Option) => {
    setCurrentPage(1);
    if (paymentStatus.value === ALL) {
      setSelectedPaymentStatus(ALL as PaymentStatusEnum);
      return;
    }

    setSelectedPaymentStatus(paymentStatus.value);
  };

  const handleMerchantSelection = (merchant: Option) => {
    setCurrentPage(1);
    if (merchant.value === ALL) {
      setSelectedMerchantEmail(ALL);
      return;
    }

    setSelectedMerchantEmail(merchant.value);
  };

  const handleStatusSelection = (providerStatus: Option) => {
    setCurrentPage(1);
    if (providerStatus.value === ALL) {
      setSelectedStatus(ALL as OrderStatusEnum);
      return;
    }

    setSelectedStatus(providerStatus.value);
  };

  const debouncedSetSearch = debounce(async (value) => {
    try {
      setSearchValidationError('');
      await searchValidator.validate(value.trim());
      setSearch(value.trim());
    } catch (error: any) {
      setSearchValidationError(error?.message);
    }
  }, 500);

  // Reset the page to 1 if filtered query results to empty and is in another page
  const hasFilters = Boolean(search || selectedStatus);

  if (hasFilters && currentPage !== 1 && transactions.length === 0) {
    setCurrentPage(1);
  }

  // Export
  const isDownloadable = !isGetAllFilteredTransactionsLoading && !isEmpty(allFilteredTransactions);
  const exportColumnHeaders = [
    { id: 'createdAt', displayName: 'Transaction Date' },
    { id: 'transactionId', displayName: 'Transaction ID' },
    { id: 'status', displayName: 'Transaction ID' },
    { id: 'userId', displayName: 'User ID' },
    { id: 'userEmail', displayName: 'User Email' },
    { id: 'orderId', displayName: 'Order ID' },
    { id: 'orderStatus', displayName: 'Order Status' },
    { id: 'deliveryDate', displayName: 'Delivery Date' },
    { id: 'merchantId', displayName: 'Merchant ID' },
    { id: 'merchantName', displayName: 'Merchant Name' },
    { id: 'productId', displayName: 'Product ID' },
    { id: 'vatable', displayName: 'Vatable' },
    { id: 'price', displayName: 'Product App Price' },
    { id: 'totalAmount', displayName: 'Amount Paid' },
    { id: 'paymentId', displayName: 'Payment ID' },
    { id: 'paymentMethod', displayName: 'Payment Method' },
    { id: 'paymentStatus', displayName: 'Payment Status' },
    { id: 'eShopRevenue', displayName: 'eShop Revenue' },
    { id: 'eShopOVat', displayName: 'eShop O-VAT' },
    { id: 'sfRate', displayName: 'SF Rate' },
    { id: 'mWellSFNet', displayName: 'MWELL SF, net' },
    { id: 'sfOVat', displayName: 'SF O-VAT' },
    { id: 'cwtRate', displayName: 'CWT Rate' },
    { id: 'cwt', displayName: 'CWT' },
    { id: 'mWellCommission', displayName: 'MWELL Commission' },
  ];
  const dataForExport = transformToExportedData(allFilteredTransactions);

  const handleExport = () => {
    setIsExporting(true);
    csvRef.current?.handleClick();
    setIsExporting(false);
  };

  const handleClearDateRange = () => {
    setDateRange([]);
    setStartDate(undefined);
    setEndDate(undefined);
    handleSubmitDate([]);
  };

  const handleClearDeliveryDateRange = () => {
    setDeliveryDateRange([]);
    setDeliveryStartDate(undefined);
    setDeliveryEndDate(undefined);
    handleSubmitDeliveryDate([]);
  };

  const handleChangeDate = (date: DateObject[]) => {
    setDateRange(date);
    handleSubmitDate(date);

    if (date === null) {
      handleClearDateRange();
    }
  };

  const handleChangeDeliveryDate = (date: DateObject[]) => {
    setDeliveryDateRange(date);
    handleSubmitDeliveryDate(date);

    if (date === null) {
      handleClearDeliveryDateRange();
    }
  };

  const handleSubmitDate = (date: DateObject[]) => {
    if (!!date?.[0]?.format().length && !!date?.[1]?.format().length) {
      setDateRange(date);
      const parsedStartDate = date[0].toDate();
      const parsedEndDate = date[1].toDate();
      parsedStartDate.setHours(0, 0, 0, 0);
      parsedEndDate.setHours(23, 59, 59, 999);
      setStartDate(parsedStartDate.toISOString());
      setEndDate(parsedEndDate.toISOString());
    }
  };

  const handleSubmitDeliveryDate = (date: DateObject[]) => {
    if (!!date?.[0]?.format().length && !!date?.[1]?.format().length) {
      setDeliveryDateRange(date);
      const parsedStartDate = date[0].toDate();
      const parsedEndDate = date[1].toDate();
      parsedStartDate.setHours(0, 0, 0, 0);
      parsedEndDate.setHours(23, 59, 59, 999);
      setDeliveryStartDate(parsedStartDate.toISOString());
      setDeliveryEndDate(parsedEndDate.toISOString());
    }
  };

  return (
    <div>
      <div className="mb-6 mt-4 flex flex-row justify-between sm:flex sm:items-center">
        <div>
          <h1 className="text-4 text-2xl font-semibold">Transactions</h1>
        </div>
      </div>
      <div className="mb-4 flex flex-row justify-between">
        <div />
        <Button
          variant="primary"
          className="flex w-full gap-2 whitespace-nowrap px-[14px] py-[10px] font-normal lg:max-w-[110px]"
          disabled={!isDownloadable || isExporting}
          isLoading={isExporting}
          onClick={handleExport}
        >
          <ArrowDownTrayIcon height={18} width={18} className="" /> Export
        </Button>
      </div>
      <div className="mb-4 flex flex-col gap-4">
        <div
          className={clsxMerge('items-end gap-4 space-y-2 lg:flex lg:space-y-0', {
            'relative items-start': !!searchValidationError,
          })}
        >
          <SearchInput
            className="w-full"
            defaultValue={search}
            placeholder="Search"
            onChange={debouncedSetSearch}
            errorMessage={searchValidationError}
            iconVariant="plain"
          />
          {!isMerchant && (
            <Dropdown
              hasLeftRoundedBorders
              hasRightRoundedBorders
              isLoading={isLoadingUsers}
              placeholder="Select Merchant"
              containerClassname="w-full lg:max-w-[220px]"
              className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
              options={merchantOptions}
              value={merchantOptions.find(
                (merchantOption) =>
                  selectedMerchantEmail && merchantOption.value === selectedMerchantEmail,
              )}
              onChange={handleMerchantSelection}
            />
          )}
          <CustomDatePicker
            className="lg:max-w-[220px]"
            placeholder="Transaction Date"
            value={dateRange}
            handleOnChange={handleChangeDate}
            onClear={handleClearDateRange}
            onSubmit={handleSubmitDate}
          />
        </div>
        <div className="grid grid-cols-1 items-end gap-x-4 gap-y-2 lg:flex lg:gap-4 lg:space-y-0">
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Order Status"
            containerClassname="w-full"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={transactionStatusOptions}
            value={transactionStatusOptions.find(
              (transactionStatusOption) =>
                selectedStatus && transactionStatusOption.value === selectedStatus,
            )}
            onChange={handleStatusSelection}
          />
          <CustomDatePicker
            placeholder="Delivery Date"
            value={deliveryDateRange}
            handleOnChange={handleChangeDeliveryDate}
            onClear={handleClearDeliveryDateRange}
            onSubmit={handleSubmitDeliveryDate}
          />
          <Dropdown
            hasLeftRoundedBorders
            hasRightRoundedBorders
            placeholder="Payment Status"
            containerClassname="w-full"
            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
            options={paymentStatusOptions}
            value={paymentStatusOptions.find(
              (paymentStatusOption) =>
                selectedPaymentStatus && paymentStatusOption.value === selectedPaymentStatus,
            )}
            onChange={handlePaymentStatusSelection}
          />
        </div>
        <div className="">
          <TransactionStatistics
            dateFrom={startDate}
            dateTo={endDate}
            statistics={transactionCounts}
          />
        </div>
        <div className="rounded-lg bg-white">
          <Table
            columns={columns}
            data={transactions}
            isLoading={isGetTransactionsLoading}
            noDataText="No Transactions found."
          />
        </div>
      </div>
      <OperationsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        totalItems={transactions.length}
        itemsCount={transactions.length}
      />
      {isDownloadable && (
        <CsvDownloader
          className="hidden"
          columns={exportColumnHeaders}
          datas={dataForExport as any}
          extension=".csv"
          filename="Transactions"
          ref={csvRef}
          separator=","
          wrapColumnChar={`"`}
        />
      )}
      {!!selectedTransactionMerchant && (
        <ViewTransactionModal
          transactionMerchant={selectedTransactionMerchant}
          isOpen={isViewTransactionModalOpen}
          onClose={handleToggleViewTransactionModal}
        />
      )}
    </div>
  );
}

function cleanUpFilters(filters: Record<string, string | number | undefined>) {
  const clonedFilters = { ...filters };

  ['paymentStatus', 'status', 'merchantEmail'].forEach((key) => {
    if (clonedFilters[key] === ALL) {
      delete clonedFilters[key];
    }
  });

  return clonedFilters;
}

export default TransactionsList;
