import React from 'react';

import clsx from 'clsx';

import { formatToPeso } from '../utils';

type Props = {
  className?: string;
  label: string;
  price: number;
};

function ItemAmount(props: Props) {
  const { className, label, price } = props;

  return (
    <div className={clsx('flex items-center justify-between', className)}>
      <p className="text-left">{label}</p>
      <p className="text-right">{formatToPeso(price)}</p>
    </div>
  );
}

export default ItemAmount;
