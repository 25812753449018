import React from 'react';

import { NavArrowRight } from 'iconoir-react';

import { BreadcrumbType } from '../../types/Common.type';

interface Props {
  breadcrumb: BreadcrumbType;
  isLast?: boolean;
  isFirstAndNoHome?: boolean;
  onClick: (breadcrumb: BreadcrumbType) => void;
}

const BreadcrumbItem: React.FC<Props> = (props: Props) => {
  const { breadcrumb, onClick, isLast, isFirstAndNoHome } = props;

  const handleClick = () => {
    onClick(breadcrumb);
  };

  return (
    <li className="-ml-1 max-sm:space-x-0" key={breadcrumb.name}>
      <div className="flex items-center">
        {!isFirstAndNoHome && (
          <NavArrowRight className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
        )}
        <button
          className={`text-sm font-medium sm:ml-1 ${
            isLast ? 'text-primary1' : ' text-gray-500 hover:text-gray-700'
          }`}
          onClick={handleClick}
        >
          {breadcrumb.name}
        </button>
      </div>
    </li>
  );
};

export default BreadcrumbItem;
