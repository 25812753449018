import { ChangeEvent, forwardRef, HTMLProps, Ref, useId } from 'react';

import { clsx } from 'clsx';

import { clsxMerge } from '../utils';

export interface TextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  label?: string | any;
  labelShown?: boolean;
  additionalLabel?: JSX.Element | string;
  additionalLabelClassName?: string;
  containerClassName?: string;
  errorMessage?: string;
  showRequiredOnLabel?: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

function TextArea(props: TextAreaProps, forwardedRef?: Ref<HTMLTextAreaElement>) {
  const {
    label,
    labelShown = false,
    additionalLabel,
    additionalLabelClassName,
    className = '',
    containerClassName = '',
    title,
    disabled,
    id: idProp,
    errorMessage,
    rows = 3,
    showRequiredOnLabel = false,
    ...etcProps
  } = props;
  const defaultId = useId();
  const id = idProp ?? defaultId;
  return (
    <div className={containerClassName}>
      <label
        htmlFor={id}
        className={clsx('block text-sm leading-6 text-gray-900', {
          'sr-only': !labelShown,
          'mb-2': additionalLabel,
        })}
      >
        {label}
        {showRequiredOnLabel && <span className="text-red-500"> *</span>}
      </label>
      {additionalLabel && (
        <label className={clsx('text-xs text-neutral-500', additionalLabelClassName)}>
          {additionalLabel}
        </label>
      )}
      <div className={errorMessage ? className : ''}>
        <textarea
          ref={forwardedRef}
          id={id}
          title={title ?? label}
          disabled={disabled}
          rows={rows}
          className={clsxMerge(
            'block w-full rounded-md border-0 px-3 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary1 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-slate-400 sm:text-sm sm:leading-6',
            errorMessage ? '' : className,
          )}
          {...etcProps}
        />
        {errorMessage && <div className="mt-1 text-sm text-red-500">{errorMessage}</div>}
      </div>
    </div>
  );
}

export default forwardRef(TextArea);
