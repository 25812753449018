import { useEffect, useState } from 'react';

import { Appointment, User } from '@healthhub/api-lib';

import {
  ensureObject,
  fetchCurrentUserAppointments,
  getLocalStorageItem,
  MILLISECONDS_IN_A_DAY,
  Modal,
  NO_FEEDBACK,
  PersistentLocalStorageKey,
  setLocalStorageItem,
  SHOW_FEEDBACK_FEATURE,
  useRouter,
} from '@mwell-healthhub/commons';

import AddAppointmentFeedbackForm from './AddAppointmentFeedbackForm';
import NavigationTitle from './NavigationTitle';
import { Routes } from '../../constants';

type Props = {
  user?: User;
};

export default function ShareFeedbackModal(props: Props) {
  const { user } = props;
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [completedAppointments, setCompletedAppointments] = useState<Appointment[]>([]);

  useEffect(() => {
    if (!SHOW_FEEDBACK_FEATURE || !user?.id) {
      return;
    }

    const lastFeedbackClosedTime: Record<string, string> = ensureObject(
      getLocalStorageItem(PersistentLocalStorageKey.LAST_FEEDBACK_CLOSED_TIME),
    );
    const userLastFeedbackClosedTime = lastFeedbackClosedTime[user.id];

    if (
      !userLastFeedbackClosedTime ||
      Date.now() - Number(userLastFeedbackClosedTime) > MILLISECONDS_IN_A_DAY
    ) {
      fetchCurrentUserAppointments({
        limit: 2,
        sortBy: 'dateTime:DESC',
        status: NO_FEEDBACK,
      }).then((data) => {
        if (data.itemCount) {
          setCompletedAppointments(data.items);
          setIsOpen(true);
        }
      });
    }
  }, [user, router.asPath]);

  const handleCloseModal = () => {
    setIsOpen(false);
    setLastFeedbackClosedTime();
  };

  const setLastFeedbackClosedTime = () => {
    if (!user) {
      return;
    }

    const lastFeedbackClosedTime = ensureObject(
      getLocalStorageItem(PersistentLocalStorageKey.LAST_FEEDBACK_CLOSED_TIME),
    );

    lastFeedbackClosedTime[user.id] = String(Date.now());

    setLocalStorageItem(
      PersistentLocalStorageKey.LAST_FEEDBACK_CLOSED_TIME,
      lastFeedbackClosedTime,
    );
  };

  const handleSuccess = () => {
    if (completedAppointments.length > 1) {
      router.replace({
        pathname: Routes.APPOINTMENTS,
        query: {
          status: 'past',
        },
      });
    }

    handleCloseModal();
  };

  if (!SHOW_FEEDBACK_FEATURE) {
    return null;
  }

  return (
    <Modal className="pt-0" size="3xl" open={isOpen} setOpen={() => null}>
      <NavigationTitle
        containerClassName="-mx-4 mb-4"
        title={<span className="text-primary-500">Share Feedback</span>}
        onLeftIconClick={handleCloseModal}
      />
      <AddAppointmentFeedbackForm
        appointment={completedAppointments[0]}
        onClickCancel={handleCloseModal}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
}
