import { Fragment, useMemo, useState } from 'react';

import { ProviderService, UserOperation, UserProvider } from '@healthhub/api-lib';
import { useQueryClient } from '@tanstack/react-query';
import { Plus } from 'iconoir-react';
import debounce from 'lodash.debounce';

import BranchAvailabilityToggle from './BranchAvailabilityToggle';
import ProviderServiceAvailabilityLabel from './ProviderServiceAvailabilityLabel';
import {
  ActionModal,
  GET_ALL_PROVIDER_SERVICE_BY_USER_ID,
  Price,
  getServiceStringedPrice,
  Table,
  TableEditButton,
  useUpdateHiddenProviderService,
  Variants,
  TableHideButton,
  SearchInput,
  useQueryParamState,
  toast,
  Button,
} from '..';
import { TableColumn } from '../components/Table';
import { trackEvent, EventName, getProviderServiceAnalyticsProps } from '../utils/analytics';

type Props = {
  onEditService?: (s: ProviderService) => void;
  onSuccess?: () => void;
  onSearch?: (query: string) => void;
  isLoading?: boolean;
  currentUser?: UserProvider | UserOperation;
  services: ProviderService[] | undefined;
  canEdit?: boolean;
  canHide?: boolean;
  onClickAdd?: () => void;
  branchId: string;
};

function BranchServicesTable(props: Readonly<Props>) {
  const {
    branchId,
    services = [],
    isLoading,
    currentUser,
    onEditService,
    onSearch,
    canEdit,
    canHide,
    onClickAdd,
  } = props;
  const [search, setSearch] = useQueryParamState('search');
  const queryClient = useQueryClient();

  const [selectedService, setSelectedService] = useState<ProviderService>();
  const [isConfirmActionModalOpen, setIsConfirmHideModalOpen] = useState<boolean>(false);

  const { mutate: hideProviderService, isLoading: isHiding } = useUpdateHiddenProviderService({
    onSuccess: () => {
      const eventName = selectedService?.hidden
        ? EventName.CLICK_UNHIDE_SERVICE
        : EventName.CLICK_HIDE_SERVICE;

      trackEvent(eventName, getProviderServiceAnalyticsProps(selectedService));

      toast({
        type: 'success',
        message: 'Service updated.',
      });

      queryClient.invalidateQueries([GET_ALL_PROVIDER_SERVICE_BY_USER_ID]);
      handleToggleActionServiceModal();
    },
  });

  const columns: TableColumn<ProviderService>[] = useMemo(
    () => [
      {
        header: 'Branch Availability',
        key: 'branch-availability',
        render: (brandService) => (
          <BranchAvailabilityToggle
            branchId={branchId}
            branchService={brandService.branchServices?.find(
              (s) => s.provider.id.toString() === branchId,
            )}
            parentService={brandService}
          />
        ),
      },
      {
        header: 'Service Name',
        key: 'label',
        render: (brandService) => {
          const branchService = brandService.branchServices?.find(
            (s) => s.provider.id.toString() === branchId,
          );

          return (
            <span
              onClick={handleActionEdit(branchService)}
              className="cursor-pointer font-semibold text-neutral-500"
            >
              {branchService?.label || brandService?.label}
            </span>
          );
        },
      },
      {
        header: 'Description',
        key: 'description',
        className: 'min-w-[200px]',
        render: (brandService) => {
          const branchService = brandService.branchServices?.find(
            (s) => s.provider.id.toString() === branchId,
          );

          return (
            <div className="line-clamp-3 whitespace-pre-wrap">
              {branchService?.description || brandService?.description}
            </div>
          );
        },
      },
      {
        header: 'Category',
        key: 'serviceCategory.name',
        render: (brandService) => {
          const branchService = brandService.branchServices?.find(
            (s) => s.provider.id.toString() === branchId,
          );

          return branchService?.serviceCategory?.name || brandService?.serviceCategory?.name;
        },
      },
      {
        header: 'Service Status',
        key: 'availability',
        render: (brandService) => (
          <ProviderServiceAvailabilityLabel
            providerService={brandService.branchServices?.find(
              (s) => s.provider.id.toString() === branchId,
            )}
          />
        ),
      },
      {
        header: 'Price',
        key: 'price',
        render: (brandService) => {
          const branchService = brandService.branchServices?.find(
            (s) => s.provider.id.toString() === branchId,
          );

          const service = branchService || brandService;

          const { priceType } = service;
          const stringedPrice = getServiceStringedPrice(service);
          const price = new Price(stringedPrice, priceType);
          return price.priceInPesos;
        },
      },
      {
        header: 'Actions',
        key: 'action',
        headerClassName: 'text-center',
        className: 'justify-start',
        isColumnHidden: !canEdit && !canEdit,
        render: (brandService) => {
          const branchService = brandService.branchServices?.find(
            (s) => s.provider.id.toString() === branchId,
          );

          if (!branchService) {
            return null;
          }

          return (
            <div className="flex gap-4">
              {canEdit && (
                <TableEditButton showLabel={false} onClick={handleActionEdit(branchService)} />
              )}
              {canHide && (
                <TableHideButton
                  showLabel={false}
                  isHidden={branchService.hidden}
                  onClick={handleActionHide(branchService)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [currentUser, branchId],
  );

  const handleActionEdit = (providerService?: ProviderService) => () => {
    if (!canEdit || !providerService) {
      return;
    }

    trackEvent(
      EventName.CLICK_EDIT_SERVICE_PAGE,
      getProviderServiceAnalyticsProps(providerService),
    );

    onEditService?.(providerService);
  };

  const handleActionHide = (providerService: ProviderService) => () => {
    setSelectedService(providerService);
    handleToggleActionServiceModal();
  };

  const handleHideService = () => {
    hideProviderService(selectedService?.id as number);
  };

  const handleToggleActionServiceModal = () => {
    setIsConfirmHideModalOpen(!isConfirmActionModalOpen);
  };

  const debouncedSetSearch = debounce((value) => {
    setSearch(value);
    onSearch?.(value);
  }, 300);

  const handleSearch = (value?: string) => {
    debouncedSetSearch(value);
  };

  return (
    <Fragment>
      {isConfirmActionModalOpen && (
        <ActionModal
          variant={Variants.ERROR}
          title={selectedService?.hidden ? 'Unhide Service' : 'Hide Service'}
          description={
            selectedService?.hidden
              ? 'Are you sure you want to unhide this service?'
              : 'Are you sure you want to hide this service?'
          }
          primaryButtonText={selectedService?.hidden ? 'Unhide' : 'Hide'}
          isLoading={isHiding}
          onClickPrimaryButton={handleHideService}
          onClickSecondaryButton={handleToggleActionServiceModal}
        />
      )}
      <SearchInput
        defaultValue={search}
        className="mb-5 w-full"
        placeholder="Search name or description"
        iconVariant="plain"
        onChange={handleSearch}
      />
      <div className="rounded-lg border border-neutral-300 bg-white">
        <div className="flex justify-between p-6">
          <div className="text-lg font-semibold">Services</div>
          <Button variant="primary" onClick={onClickAdd} icon={Plus}>
            Add Service
          </Button>
        </div>
        <div className="md:block">
          <Table
            columns={columns}
            data={services}
            isLoading={isLoading}
            containerClassName="rounded-b-lg rounded-t-none border border-neutral-300 border-r-0 border-l-0 border-b-0"
            headerClassName="bg-neutral-100 border border-neutral-300 border-r-0 border-l-0"
            noDataText="No services found. Click “Add Service” to get started."
          />
        </div>
        {/*  TODO mobile responsiveness */}
        {/* <div className="block md:hidden">
          <BranchServicesTableMobile providerServices={services} isLoading={isLoading || false} />
        </div> */}
      </div>
    </Fragment>
  );
}

function filterList(list: ProviderService[], searchTerm: string): ProviderService[] {
  const lowerCasedSearchTerm = searchTerm.toLowerCase();

  return list.filter(
    (item) =>
      item.label?.toLowerCase().includes(lowerCasedSearchTerm) ||
      item.description?.toLowerCase().includes(lowerCasedSearchTerm),
  );
}

export default BranchServicesTable;
