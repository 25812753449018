import { TransactionPaymentOptionEnum } from '@healthhub/api-lib';

import { ExternalPaymentStatusEnum } from '../enums';
import { clsxMerge } from '../utils';

type Props = {
  paymentStatus?: ExternalPaymentStatusEnum | string;
  paymentMethod?: string;
};
export default function ExternalPaymentStatusBadge({ paymentStatus, paymentMethod }: Props) {
  const defaultText =
    paymentMethod === TransactionPaymentOptionEnum.Qrph ? 'Awaiting Confirmation' : 'Processing';
  const baseClass = 'box-border rounded-full border px-[7px] py-[3px] text-xs capitalize w-fit';
  switch (paymentStatus) {
    case 'pending':
    case ExternalPaymentStatusEnum.PENDING_PAYMENT:
    case ExternalPaymentStatusEnum.PENDING_TOKEN:
    case ExternalPaymentStatusEnum.PAYMENT_CANCELLED:
      return (
        <span
          className={clsxMerge(baseClass, 'border-neutral-500 bg-neutral-200 text-neutral-500')}
        >
          Pending
        </span>
      );
    case 'paid':
    case ExternalPaymentStatusEnum.PAYMENT_SUCCESS:
      return (
        <span className={clsxMerge(baseClass, 'border-green-700 bg-green-50 text-green-700')}>
          Paid
        </span>
      );
    case ExternalPaymentStatusEnum.REFUNDED:
      return (
        <span
          className={clsxMerge(baseClass, 'border-neutral-500 bg-neutral-200 text-neutral-500')}
        >
          Refunded
        </span>
      );
    case ExternalPaymentStatusEnum.VOIDED:
      return (
        <span
          className={clsxMerge(baseClass, 'border-neutral-500 bg-neutral-200 text-neutral-500')}
        >
          Voided
        </span>
      );
    case 'failed':
    case ExternalPaymentStatusEnum.AUTH_FAILED:
    case ExternalPaymentStatusEnum.PAYMENT_EXPIRED:
    case ExternalPaymentStatusEnum.PAYMENT_FAILED:
      return (
        <span className={clsxMerge(baseClass, 'border-red-700 bg-red-50 text-red-700')}>
          Failed
        </span>
      );
    default:
      return (
        <span
          className={clsxMerge(baseClass, 'border-neutral-500 bg-neutral-200 text-neutral-500')}
        >
          {defaultText}
        </span>
      );
  }
}
