import {
  CreateProviderTagCategoryDto,
  ProviderTagCategory,
  ProviderTagCategoryApi,
  UpdateProviderTagCategoryDto,
} from '@healthhub/api-lib';

import HealthHubApiClient from './api-client';
import { CustomError } from '../errors/customError';

export const providerTagCategoryApi = HealthHubApiClient.use(ProviderTagCategoryApi);

export type ProviderTagCategoryParams = {
  providerId?: string;
  searchQuery?: string;
};

export async function createProviderTagCategory(
  createProviderTagCategoryDto: CreateProviderTagCategoryDto,
): Promise<ProviderTagCategory> {
  try {
    const { data } = await providerTagCategoryApi.providerTagCategoryControllerCreate(
      createProviderTagCategoryDto,
    );

    return data as ProviderTagCategory;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateProviderTagCategory(
  updateProviderTagCategoryDto: UpdateProviderTagCategoryDto,
): Promise<ProviderTagCategory> {
  try {
    const { data } = await providerTagCategoryApi.providerTagCategoryControllerUpdate(
      updateProviderTagCategoryDto.providerTagCategoryId as string,
      updateProviderTagCategoryDto,
    );

    return data as ProviderTagCategory;
  } catch (err) {
    throw new CustomError(err);
  }
}

export async function removeProviderTagCategory(id: string) {
  try {
    const { data } = await providerTagCategoryApi.providerTagCategoryControllerRemove(id);

    return data;
  } catch (err) {
    throw new Error('Failed to update provider tag categories');
  }
}

export async function getAllProviderTagCategories(
  params: ProviderTagCategoryParams,
): Promise<ProviderTagCategory[]> {
  try {
    const { providerId, searchQuery } = params;
    const { data } = await providerTagCategoryApi.providerTagCategoryControllerFindAllByParams(
      providerId,
      searchQuery,
    );

    return data as ProviderTagCategory[];
  } catch (err) {
    throw new Error('Failed to fetch provider tag categories');
  }
}
