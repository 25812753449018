export const RATINGS = [
  {
    label: 'Very Unsatisfied',
    value: 1,
  },
  {
    label: 'Unsatisfied',
    value: 2,
  },
  {
    label: 'Neutral',
    value: 3,
  },
  {
    label: 'Satisfied',
    value: 4,
  },
  {
    label: 'Very Satisfied',
    value: 5,
  },
];
