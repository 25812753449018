import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { UpdateOrderParams, updateOrderStatus } from '../../api';
import { TransactionMerchant } from '../../types';

export const useUpdateOrderStatusMutation = (
  mutationOptions: MutationOptions<
    TransactionMerchant,
    AxiosError<{ message: string }>,
    UpdateOrderParams
  >,
) => {
  return useMutation(updateOrderStatus, mutationOptions);
};
