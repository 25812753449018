import { useQuery } from '@tanstack/react-query';

import {
  getMedicalRecordFilesByAppointmentId,
  getMedicalRecordFilesOfUser,
} from '../../api/fileService';
import {
  GET_ALL_MEDICAL_RECORD_BY_APPOINTMENT_ID,
  GET_ALL_MEDICAL_RECORD_OF_USER,
} from '../../constants';

export const useGetAllMedicalRecordFilesByAppointmentIdQuery = (appointmentId: string) =>
  useQuery(
    [GET_ALL_MEDICAL_RECORD_BY_APPOINTMENT_ID],
    () => getMedicalRecordFilesByAppointmentId(appointmentId),
    {
      enabled: !!appointmentId,
    },
  );

export const useGetAllMedicalRecordFilesOfUserQuery = () =>
  useQuery([GET_ALL_MEDICAL_RECORD_OF_USER], () => getMedicalRecordFilesOfUser());
