// Source of Truth: If you want to add a new provider type to reference, add it here.

export enum ProviderType {
  DENTAL = 'dental',
  EYE = 'eye',
  SKIN = 'skin',
  WELLNESS_SPA = 'wellness-spa',
  MEDICAL = 'medical',
  DIAGNOSTIC = 'diagnostic',
  MATERNITY = 'maternity',
  THERAPY = 'therapy',
  HAIR = 'hair',
}

export const PROVIDER_TYPE_IDS = {
  [ProviderType.DENTAL]: 25,
  [ProviderType.EYE]: 29,
  [ProviderType.SKIN]: 26,
  [ProviderType.WELLNESS_SPA]: 32,
  [ProviderType.MEDICAL]: 33,
  [ProviderType.MATERNITY]: 31,
  [ProviderType.THERAPY]: 28,
  [ProviderType.HAIR]: 27,
  [ProviderType.DIAGNOSTIC]: 30,
};

export const PROVIDER_TYPE_LABELS = {
  [ProviderType.DENTAL]: 'Dental',
  [ProviderType.EYE]: 'Eye',
  [ProviderType.SKIN]: 'Skin',
  [ProviderType.WELLNESS_SPA]: 'Wellness Spa',
  [ProviderType.MEDICAL]: 'Medical',
  [ProviderType.DIAGNOSTIC]: 'Diagnostic',
  [ProviderType.MATERNITY]: 'Maternity',
  [ProviderType.THERAPY]: 'Therapy',
  [ProviderType.HAIR]: 'Hair',
};
