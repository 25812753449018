import get from 'lodash.get';

import { NEAR_ME, NEAR_METRO_MANILA } from '../constants';

// 'K' is kilometers
// 'N' is nautical miles
type Unit = 'K' | 'N';

export function getCenterFromGeocodedAddress(geocodedAddress: object) {
  const position = get(
    geocodedAddress,
    'addresses[0].position',
    get(geocodedAddress, 'position', undefined),
  );

  if (typeof position === 'string') {
    return (position as string).split(',').map(Number).reverse();
  }

  if (typeof position === 'object') {
    return [(position as Record<string, number>).lon, (position as Record<string, number>).lat];
  }

  return undefined;
}

export function computeDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: Unit = 'K',
) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === 'K') {
    return dist * 1.609344;
  }

  if (unit === 'N') {
    return dist * 0.8684;
  }

  return dist;
}

export function truncateCoordinate(coordinate: number): number {
  const truncatedValue = Math.round(coordinate * 1000) / 1000;

  return Number.isNaN(truncatedValue) ? 0 : truncatedValue;
}

export function getLocationAddress(selectedLocation?: string, hasAllowedLocation?: boolean) {
  if (selectedLocation) {
    return selectedLocation.startsWith('Near') ? selectedLocation : `Near ${selectedLocation}`;
  }

  if (hasAllowedLocation) {
    return NEAR_ME;
  }

  return NEAR_METRO_MANILA;
}
