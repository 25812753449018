import type AdjustType from '@adjustcom/adjust-web-sdk';

import { ADJUST_EVENT_TOKENS, EventName } from './analytics';

class Adjust {
  adjust: any;
  config: any;

  constructor(config: any) {
    this.config = config;
  }

  async initializeAdjust() {
    if (!this.adjust) {
      this.adjust = require('@adjustcom/adjust-web-sdk');
      await this.adjust.initSdk(this.config);
    }
  }

  async sendEvent(
    eventName: EventName,
    extraParams?: AdjustType.EventParams & Record<string, any>,
  ) {
    try {
      const eventToken = ADJUST_EVENT_TOKENS?.[eventName];

      if (eventToken) {
        const params = { ...extraParams, eventToken };

        if (eventName === EventName.PURCHASE) {
          params.currency = extraParams?.currency;
          params.revenue = extraParams?.value;
          params.deduplicationId = extraParams?.transaction_id;
        }

        this.adjust.trackEvent(params);
      }
    } catch (err) {
      console.error('Error occured while sending Adjust events', err);
    }
  }
}

export default new Adjust({
  appToken: process?.env?.NEXT_PUBLIC_ADJUST_TOKEN || '',
  environment: process?.env?.NEXT_PUBLIC_ADJUST_ENVIRONMENT || 'sandbox',
  logLevel: 'none',
});
