import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

import Button, { ButtonSize } from './Button/Button';
import Icon from './Icon';
import { useRouter } from '..';
import blueBack from '../images/blue-back-arrow.svg';
import xIcon from '../images/x-mark.svg';

type Props = {
  className?: string;
  iconWidth?: number;
  size?: ButtonSize;
  text?: string;
  type?: 'text' | 'icon' | 'xIcon' | 'textIcon' | 'iconandtext' | 'leftIconBorder';
  onClick?: () => void;
};

export default function Back(props: Props) {
  const { className, size = 'xs', type = 'text', iconWidth, text, onClick } = props;

  const router = useRouter();

  const isText = type === 'text';
  const isXIcon = type === 'xIcon';
  const isTextIcon = type === 'textIcon';
  const isLeftIconBorder = type === 'leftIconBorder';
  const width = iconWidth || 14;
  const bodyText = text || 'Back';

  const handleBack = () => {
    if (onClick) {
      onClick();
    } else {
      router.back();
    }
  };

  if (isText) {
    return (
      <Button className={className} onClick={handleBack} variant="secondary" size={size}>
        {bodyText}
      </Button>
    );
  }

  if (isXIcon) {
    return (
      <div onClick={handleBack} className="cursor-pointer">
        <Icon image={xIcon} />
      </div>
    );
  }

  if (isTextIcon) {
    return (
      <div
        onClick={handleBack}
        className={twMerge(
          'flex cursor-pointer gap-3 text-sm font-medium text-primary3',
          className,
        )}
      >
        <ArrowLeftIcon width={width} />
        {bodyText}
      </div>
    );
  }

  if (type === 'iconandtext') {
    return (
      <div onClick={handleBack} className="flex-column flex cursor-pointer items-center gap-2">
        <div>
          <Icon image={blueBack} height={width} />
        </div>
        <div className="text-sm font-medium text-primary3">{bodyText}</div>
      </div>
    );
  }

  if (isLeftIconBorder) {
    return (
      <div
        className="w-fit cursor-pointer rounded-md border border-neutral-300 p-2.5"
        onClick={handleBack}
      >
        <ArrowLeftIcon width={24} className="text-primary-500" />
      </div>
    );
  }

  return (
    <div onClick={handleBack}>
      <ChevronLeftIcon className={`w-8 ${className}`} />
    </div>
  );
}
