import { Dispatch, HTMLProps, SetStateAction } from 'react';

import { Control, Controller } from 'react-hook-form';

import TextInput from '../TextInput/TextInput';

type TextInputControllerProps = {
  containerClassName?: string;
  control: Control<any>;
  errorMessage?: string;
  isDisabled?: boolean;
  isLabelHidden?: boolean;
  isRequired?: boolean;
  label: string | React.ReactElement;
  name: string;
  placeholder: string;
  requiredText?: string;
  type?: string;
  pattern?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'search'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
  textInputClassName?: string;
  labelFontSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  labelFontWeight?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  labelClassName?: HTMLProps<HTMLElement>['className'];
  setErrorMessage?: Dispatch<SetStateAction<string>>;
} & HTMLProps<HTMLInputElement>;

const TextInputController = ({
  containerClassName,
  control,
  errorMessage,
  isDisabled,
  isLabelHidden,
  isRequired,
  label,
  name,
  placeholder,
  requiredText,
  type = 'text',
  pattern,
  textInputClassName,
  labelFontSize = 'sm',
  labelFontWeight = 'sm',
  labelClassName,
  inputMode,
  setErrorMessage,
}: TextInputControllerProps) => (
  <Controller
    name={name}
    control={control}
    rules={{ required: requiredText || isRequired }}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <TextInput
        labelShown
        className="mb-4 px-3 py-2"
        disabled={isDisabled}
        containerClassName={containerClassName}
        errorMessage={errorMessage || error?.message}
        label={isLabelHidden ? '' : label}
        labelClassName={labelClassName}
        labelFontWeight={labelFontWeight}
        labelSize={labelFontSize}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        defaultValue={value}
        value={value}
        placeholder={placeholder}
        type={type}
        pattern={pattern}
        inputMode={inputMode}
        showRequiredOnLabel={isRequired}
        textInputClassName={textInputClassName}
      />
    )}
  />
);

export default TextInputController;
