import { BookOpenIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '../hooks';

type MenuItem = {
  icon: React.ElementType;
  link: string;
  text: string;
};

type MenuCategory = {
  children: MenuItem[];
  title: string;
};

type Props = {
  className?: string;
  hasUserGuide?: boolean;
  items: MenuCategory[];
  onHideSidebar?: () => void;
};

function Sidebar({ className, hasUserGuide = true, items, onHideSidebar }: Props) {
  const router = useRouter();

  const handleHideSidebar = () => {
    onHideSidebar?.();
  };

  return (
    <aside
      className={twMerge(
        'relative w-max border-r border-r-neutral-300 bg-primary-500 p-6',
        className,
      )}
    >
      <h2 className="pb-6 text-lg text-white">Operations Portal</h2>
      <nav className="flex flex-col text-white">
        <div className="flex flex-col gap-6">
          {items.map((category) => (
            <div key={category.title} className="flex w-[270px] flex-col gap-2">
              <h1 className="text-xs font-semibold">{category.title}</h1>
              <ul className="flex flex-col gap-2 text-base font-medium">
                {category.children.map(({ text, link, icon: Icon }) => (
                  <li key={`${category.title}-${text}`}>
                    <Link
                      href={link}
                      className={twMerge(
                        'flex cursor-pointer items-center gap-4 rounded-lg p-3 hover:bg-sapphireBlue active:bg-sapphireBlue',
                        router.asPath.startsWith(link) && 'bg-sapphireBlue',
                      )}
                      onClick={handleHideSidebar}
                    >
                      <Icon height={24} />
                      <div>{text}</div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        {hasUserGuide && (
          <div className="absolute bottom-6 w-[270px]">
            <ul className="text-base font-medium">
              <li>
                <Link
                  href="https://sites.google.com/symph.co/hhopsuserguide?usp=sharing"
                  className="flex cursor-pointer items-center gap-4 rounded-lg p-3 hover:bg-sapphireBlue active:bg-sapphireBlue"
                  target="_blank"
                >
                  <BookOpenIcon className="h-6 w-6" />
                  <div>User Guide</div>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </aside>
  );
}

export default Sidebar;
