import { DigitalIDMedicalDocumentsApi, UpdateMedicalDocumentDto } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from './api-client';
import { MedicalDocumentTypesEnum } from '../enums';

export const digitalIdMedicalDocumentsApi = HealthHubApiClient.use(DigitalIDMedicalDocumentsApi);

export type GetMedicalDocumentsFilters = {
  perPage?: number;
  page?: number;
  tag?: string;
  filename?: string;
  endsAt?: string;
  startsAt?: string;
  type?: string;
};

export async function getMedicalDocumentsFromPatient(
  patientId: string,
  filters: GetMedicalDocumentsFilters,
  config?: AxiosRequestConfig,
) {
  const { perPage, page, tag, filename, type, startsAt, endsAt } = filters;

  try {
    const { data } =
      await digitalIdMedicalDocumentsApi.digitalIdMedicalDocumentsControllerFindAllMedicalDocumentsFromPatient(
        patientId,
        perPage,
        page,
        tag,
        filename,
        type,
        startsAt,
        endsAt,
        config,
      );

    return data;
  } catch (error) {
    console.error('Error uploading medical document:', error);
    throw error;
  }
}

export async function updateMedicalDocument(
  updatedData: UpdateMedicalDocumentDto,
  config?: AxiosRequestConfig,
) {
  try {
    const { data } =
      await digitalIdMedicalDocumentsApi.digitalIdMedicalDocumentsControllerUpdateMedicalDocument(
        updatedData,
        config,
      );

    return data;
  } catch (error) {
    console.error('Error uploading medical document:', error);
    throw error;
  }
}

export type UploadMedicalDocumentType = {
  file: File;
  filename: string;
  patientId: string;
  tags: MedicalDocumentTypesEnum;
};

export async function uploadMedicalDocument(
  dto: UploadMedicalDocumentType,
  config?: AxiosRequestConfig,
) {
  try {
    const { file, filename, patientId, tags } = dto;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('filename', filename);
    formData.append('patientId', patientId);
    formData.append('tags', tags);

    const response =
      await digitalIdMedicalDocumentsApi.digitalIdMedicalDocumentsControllerUploadMedicalDocument({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...config?.headers,
        },
        ...config,
      });

    return response.data;
  } catch (error) {
    console.error('Error uploading medical document:', error);
    throw error;
  }
}
