// prettier-ignore
export const restrictedCharactersMap: Record<string, string> = {
  '!': '[[exclamation-mark]]',
  '"': '[[quotation-mark]]',
  '#': '[[number-sign]]',
  '$': '[[dollar-sign]]',
  '%': '[[percent-sign]]',
  '&': '[[&]]',
  "'": '[[apostrophe]]',
  '(': '[[left-parenthesis]]',
  ')': '[[right-parenthesis]]',
  '*': '[[asterisk]]',
  '+': '[[plus-sign]]',
  ',': '[[comma]]',
  '-': '[[hyphen-minus]]',
  '.': '[[period]]',
  '/': '[[/]]',
  ':': '[[colon]]',
  ';': '[[semicolon]]',
  '<': '[[less-than-sign]]',
  '=': '[[equals-sign]]',
  '>': '[[greater-than-sign]]',
  '?': '[[question-mark]]',
  '@': '[[at-sign]]',
  '[': '[[left-square-bracket]]',
  '\\': '[[backslash]]',
  ']': '[[right-square-bracket]]',
  '^': '[[caret]]',
  '_': '[[underscore]]',
  '`': '[[grave-accent]]',
  '{': '[[left-curly-brace]]',
  '|': '[[vertical-bar]]',
  '}': '[[right-curly-brace]]',
  '~': '[[tilde]]',
  '¡': '[[inverted-exclamation-mark]]',
  '¢': '[[cent-sign]]',
  '£': '[[pound-sign]]',
  '¤': '[[currency-sign]]',
  '¥': '[[yen-sign]]',
  '¦': '[[broken-bar]]',
  '§': '[[section-sign]]',
  '¨': '[[diaeresis]]',
  '©': '[[copyright-sign]]',
  'ª': '[[feminine-ordinal-indicator]]',
  '«': '[[left-angle-quote]]',
  '¬': '[[not-sign]]',
  '­': '[[soft-hyphen]]',
  '®': '[[registered-sign]]',
  '¯': '[[macron]]',
  '°': '[[degree-sign]]',
  '±': '[[plus-minus-sign]]',
  '²': '[[superscript-two]]',
  '³': '[[superscript-three]]',
  '´': '[[acute-accent]]',
  'µ': '[[micro-sign]]',
  '¶': '[[pilcrow-sign]]',
  '·': '[[middle-dot]]',
  '¸': '[[cedilla]]',
  '¹': '[[superscript-one]]',
  'º': '[[masculine-ordinal-indicator]]',
  '»': '[[right-angle-quote]]',
  '¼': '[[one-quarter]]',
  '½': '[[one-half]]',
  '¾': '[[three-quarters]]',
  '¿': '[[inverted-question-mark]]',
  'À': '[[capital-a-grave]]',
  'Á': '[[capital-a-acute]]',
  'Â': '[[capital-a-circumflex]]',
  'Ã': '[[capital-a-tilde]]',
  'Ä': '[[capital-a-diaeresis]]',
  'Å': '[[capital-a-ring]]',
  'Æ': '[[capital-ae]]',
  'Ç': '[[capital-c-cedilla]]',
  'È': '[[capital-e-grave]]',
  'É': '[[capital-e-acute]]',
  'Ê': '[[capital-e-circumflex]]',
  'Ë': '[[capital-e-diaeresis]]',
  'Ì': '[[capital-i-grave]]',
  'Í': '[[capital-i-acute]]',
  'Î': '[[capital-i-circumflex]]',
  'Ï': '[[capital-i-diaeresis]]',
  'Ð': '[[capital-eth]]',
  'Ñ': '[[capital-n-tilde]]',
  'Ò': '[[capital-o-grave]]',
  'Ó': '[[capital-o-acute]]',
  'Ô': '[[capital-o-circumflex]]',
  'Õ': '[[capital-o-tilde]]',
  'Ö': '[[capital-o-diaeresis]]',
  '×': '[[multiplication-sign]]',
  'Ø': '[[capital-o-stroke]]',
  'Ù': '[[capital-u-grave]]',
  'Ú': '[[capital-u-acute]]',
  'Û': '[[capital-u-circumflex]]',
  'Ü': '[[capital-u-diaeresis]]',
  'Ý': '[[capital-y-acute]]',
  'Þ': '[[capital-thorn]]',
  'ß': '[[small-sharp-s]]',
  'à': '[[small-a-grave]]',
  'á': '[[small-a-acute]]',
  'â': '[[small-a-circumflex]]',
  'ã': '[[small-a-tilde]]',
  'ä': '[[small-a-diaeresis]]',
  'å': '[[small-a-ring]]',
  'æ': '[[small-ae]]',
  'ç': '[[small-c-cedilla]]',
  'è': '[[small-e-grave]]',
  'é': '[[small-e-acute]]',
  'ê': '[[small-e-circumflex]]',
  'ë': '[[small-e-diaeresis]]',
  'ì': '[[small-i-grave]]',
  'í': '[[small-i-acute]]',
  'î': '[[small-i-circumflex]]',
  'ï': '[[small-i-diaeresis]]',
  'ð': '[[small-eth]]',
  'ñ': '[[small-n-tilde]]',
  'ò': '[[small-o-grave]]',
  'ó': '[[small-o-acute]]',
  'ô': '[[small-o-circumflex]]',
  'õ': '[[small-o-tilde]]',
  'ö': '[[small-o-diaeresis]]',
  '÷': '[[division-sign]]',
  'ø': '[[small-o-stroke]]',
  'ù': '[[small-u-grave]]',
  'ú': '[[small-u-acute]]',
  'û': '[[small-u-circumflex]]',
  'ü': '[[small-u-diaeresis]]',
  'ý': '[[small-y-acute]]',
  'þ': '[[small-thorn]]',
  'ÿ': '[[small-y-diaeresis]]',
  '‒': '[[figure-dash]]',
  '–': '[[en-dash]]',
  '—': '[[em-dash]]',
  '―': '[[horizontal-bar]]',
  '‘': '[[left-single-quotation-mark]]',
  '’': '[[right-single-quotation-mark]]',
  '‛': '[[single-high-reversed-9-quotation-mark]]',
  '“': '[[left-double-quotation-mark]]',
  '”': '[[right-double-quotation-mark]]',
  '„': '[[double-low-9-quotation-mark]]',
  '‟': '[[double-high-reversed-9-quotation-mark]]',
  '…': '[[horizontal-ellipsis]]',
  '′': '[[prime]]',
  '″': '[[double-prime]]',
  '™': '[[trademark-sign]]',
  '℠': '[[service-mark-sign]]',
  '𝓐': '[[script-capital-a]]',
  '𝓑': '[[script-capital-b]]',
  '𝓒': '[[script-capital-c]]',
  'ⓘ': '[[circled-info]]',
  'ⓒ': '[[circled-c]]'
};
