import { Appointment } from '@healthhub/api-lib';
import { format } from 'date-fns';

import Button from './Button';
import { FULL_DATE_FORMAT } from '../constants';
import { clsxMerge, mapReviewStatusToDescription } from '../utils';

type Props = {
  isShowViewAppointment?: boolean;
  date: string;
  addedBy?: string;
  reviewStatus: string;
  reviewDetails: string;
  appointmentDetails?: Appointment;
  onViewAppointment: () => void;
};

export default function ReviewAndRatingItem(props: Props) {
  const {
    isShowViewAppointment = true,
    date,
    addedBy,
    reviewStatus,
    reviewDetails,
    appointmentDetails,
    onViewAppointment,
  } = props;

  const containerClasses = clsxMerge(
    'inline-flex w-full flex-col items-start justify-start bg-white px-5 py-3',
  );
  const formattedAppointmentDate = format(
    appointmentDetails?.createdAt ? new Date(appointmentDetails?.createdAt) : new Date(),
    FULL_DATE_FORMAT,
  );

  return (
    <div className={containerClasses}>
      <div className="inline-flex items-start justify-start gap-4 self-stretch py-4">
        <div className="inline-flex w-28 flex-col items-start justify-center gap-3">
          <p className="text-base text-neutral-500">{date}</p>
          {addedBy && <p className="text-xs text-neutral-400">Added by {addedBy}</p>}
        </div>
        <div className="min-h-6 relative w-6">
          <div className="absolute left-[6px] top-[6px] h-3 w-3 rounded-full border-2 border-gray-200 bg-neutral-50" />
        </div>
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-3">
          <div className="min-h-14 flex flex-col items-start justify-start gap-2 self-stretch">
            <div className="inline-flex items-center justify-start gap-2 self-stretch">
              <div className="shrink grow basis-0 text-base font-semibold leading-normal text-sky-800">
                {mapReviewStatusToDescription(+reviewStatus)}
              </div>
            </div>
            <div className="min-h-14 self-stretch text-sm font-normal leading-normal tracking-tight text-neutral-900">
              {reviewDetails}
            </div>
          </div>
          {appointmentDetails && (
            <div className="flex w-full items-center gap-1">
              <div className="flex items-center">
                <div className="text-sm font-normal leading-tight tracking-tight text-gray-600">
                  Appointment: {formattedAppointmentDate} (ID {appointmentDetails?.bookingNumber})
                </div>
              </div>
              <Button
                variant="plain"
                onClick={onViewAppointment}
                className={clsxMerge(
                  'cursor-pointer p-0',
                  isShowViewAppointment ? 'flex' : 'hidden',
                )}
              >
                <div className="text-sm font-medium leading-tight tracking-tight text-sky-800">
                  View Appointment
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
