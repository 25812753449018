export enum ProviderCustomFieldEnum {
  Text = 'Text',
  Dropdown = 'Dropdown',
}

export type ProviderCustomFieldMetadata = {
  id: number;
  label: string;
  subLabel: string;
  isRequired: boolean;
  isHidden: boolean;
  order: number;
  type: ProviderCustomFieldEnum;
  options?: string[];
};

export type AppointmentCustomFieldMetadata = {
  id: number;
  value: string | number | boolean;
};
