import { User } from '@healthhub/api-lib';

import { GUEST } from '@mwell-healthhub/commons';

export const getDoctorsPortalPDFDownloadURL = (fileName: string) => {
  return `${process.env.NEXT_PUBLIC_FORMS_API_BASE_URL}/api/v1/public/documents/download/${fileName}`;
};

export const checkIsMissingUserDetails = (user: User) => {
  return !user.firstName || user.firstName.toLowerCase().trim().startsWith(GUEST);
};
