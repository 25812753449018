import { useState, createContext, useContext } from 'react';

import { ERROR_MONITORING } from '@healthhub/api-client';
import * as Sentry from '@sentry/nextjs';

import ActionModal from '../components/ActionModal';
import ErrorBoundary from '../components/ErrorBoundary';
import { Variants } from '../enums/variant';
import { ErrorResponseType } from '../types/ErrorResponse.type';
import { generateUUID } from '../utils/string';

type SetError = (error: ErrorResponseType | null) => void;

const ErrorContext = createContext<SetError | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const ErrorProvider = (props: Props) => {
  const [error, setError] = useState<ErrorResponseType | null>(null);

  const closeErrorModal = () => setError(null);
  const hasError = !!error;

  function handleError(error: any) {
    if (!error?.traceId) {
      error.traceId = generateUUID();
    }

    Sentry.withScope((scope) => {
      scope.setTag(ERROR_MONITORING.TRACE_ID, error.traceId);

      Sentry.captureException(error);
    });

    setError(error);
  }

  function refreshPage() {
    window.location.reload();
  }

  const content = (
    <div className="mt-4 flex flex-col gap-4">
      <p>Tap below to refresh and continue with your health and wellness journey.</p>
      <p>
        If this keeps happening, share a screenshot with our support team at healthhub@mwell.com.ph
      </p>
      {error?.traceId && <p className="mt-8 text-xs font-light">Trace ID: {error?.traceId}</p>}
    </div>
  );

  return (
    <ErrorContext.Provider value={handleError}>
      <ErrorBoundary setError={handleError}>
        {hasError ? (
          <ActionModal
            isPrimaryButtonHidden
            description={content}
            title="Uh-oh, experiencing some issues?"
            secondaryButtonText="Reload Page"
            variant={Variants.ERROR}
            onClickPrimaryButton={closeErrorModal}
            onClickSecondaryButton={refreshPage}
          />
        ) : (
          props.children
        )}
      </ErrorBoundary>
    </ErrorContext.Provider>
  );
};

export const useSetError = (): SetError => {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error('useSetError must be used within an ErrorProvider');
  }

  return context;
};
