import { useState, useCallback, useEffect } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';

import { Pagination } from '../types';

interface UseInfiniteScrollListParams<T, TParams> {
  fetchFn: (params: TParams & { page: number; limit: number }) => Promise<Pagination<T>>;
  baseParams: TParams;
  options?: {
    limit?: number;
    disabled?: boolean;
    rootMargin?: string;
  };
}

interface UseInfiniteScrollListReturn<T, TParams> {
  items: T[];
  isLoading: boolean;
  error: Error | null;
  hasNextPage: boolean;
  sentryRef: (element: Element | null) => void;
  reset: () => void;
  fetchItems: (params?: TParams) => Promise<void>;
}

export function useInfiniteScrollList<T, TParams extends Record<string, any>>(
  params: UseInfiniteScrollListParams<T, TParams>,
): UseInfiniteScrollListReturn<T, TParams> {
  const { fetchFn, baseParams, options = {} } = params;
  const { limit = 20, disabled = false, rootMargin = '0px 0px 300px 0px' } = options;

  const [items, setItems] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);

  const fetchItems = useCallback(
    async (params?: TParams, nextPage = 1) => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetchFn({
          ...(params || baseParams),
          page: nextPage,
          limit,
        });

        if (nextPage === 1) {
          setItems(response.items);
        } else {
          setItems((prev) => [...prev, ...response.items]);
        }

        setHasNextPage(nextPage < response.totalPages);
        setPage(nextPage);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchFn, baseParams, limit],
  );

  useEffect(() => {
    fetchItems(baseParams);
  }, [JSON.stringify(baseParams)]);

  const loadMore = useCallback(() => {
    if (!isLoading && hasNextPage) {
      fetchItems(baseParams, page + 1);
    }
  }, [isLoading, hasNextPage, fetchItems, baseParams, page]);

  const reset = useCallback(() => {
    setPage(1);
    setItems([]);
    setHasNextPage(false);
    setError(null);
  }, []);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    disabled: disabled || !!error,
    onLoadMore: loadMore,
    rootMargin,
  });

  return {
    items,
    isLoading,
    error,
    hasNextPage,
    sentryRef,
    reset,
    fetchItems,
  };
}
