export const drawerWidth = 240;
export const IS_PUBLIC_KEY = 'isPublic';
export const IS_CRON_JOB = 'isCronJob';
export const IS_API_KEY = 'isApiKey';
export const IS_CENTRAL_AUTH = 'isCentralAuth';
export const IS_MAYA_WEBHOOK = 'isMayaWebhook';
export const REQUIRED_FIELD = 'This field is required';

// Used as local storage keys
export const ACCESS_TOKEN = 'access_token';
export const USER = 'user';
export const USER_ABILITIES = 'user_abilities';
export const HIDE_NAVIGATION = 'hide_navigation';
export const FROM_MWELL_APP = 'from_mwell_app';
export const OTP_TOKEN = 'otp_token';
export const SSO_OTP_TOKEN = 'sso_otp_token'; // used as access token for sso otp verification
export const SSO_TOKEN = 'sso_token'; // used as access token for sso auth
export const SSO_REFRESH_TOKEN = 'sso_refresh_token';

export const OTHERS_VALUE = 'others';
export const OTHERS_LABEL = 'Others';
export const ALL = 'all';
export const GUEST = 'guest';
export const EMAIL_VERIFIED_TO_OTHERS = 'Email already verified to other users';
export const LOGIN_TO_SEND_MESSAGE = 'Please log in to send a message to the provider.';
export const FILE_EXCEEDS_10MB_MESSAGE = 'File size exceeds 10MB limit.';
export const PAYMENT_QR_CODE_PATH = 'payment-qr-codes';
export const PROOF_OF_PAYMENT_PATH = 'proof-of-payment';
export const AUTH_CODE = 'auth_code';
export const DISCOUNT_QUANTITY = 2;
export const DISCOUNTED_FROM_PRICE = 14999;
export const REFRESH_TOKEN = 'refresh_token';
