import { ReactNode, useEffect, useState } from 'react';

import { CameraIcon, UserIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

import LoadingSpinner from './LoadingSpinner';
import { SizeVariant } from '../enums';

type FileUploaderConfig = {
  acceptedExtension: string;
  isLoading: boolean;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type AvatarProps = {
  backgroundColor?: string;
  children?: ReactNode;
  className?: string;
  fileUploaderConfig?: FileUploaderConfig;
  hasPadding?: boolean;
  imageSrc?: string;
  size?: SizeVariant;
};

type FileUploaderProps = Pick<AvatarProps, 'fileUploaderConfig'>;

const sizeMapper: Record<string, string> = {
  [SizeVariant.XS]: 'w-[20px] h-[20px]',
  [SizeVariant.SM]: 'w-[40px] h-[40px]',
  [SizeVariant.MD]: 'w-[60px] h-[60px]',
  [SizeVariant.LG]: 'w-[80px] h-[80px]',
  [SizeVariant.XL]: 'w-[100px] h-[100px]',
};

const iconSizeMapper: Record<string, string> = {
  [SizeVariant.XS]: 'w-2 h-2',
  [SizeVariant.SM]: 'w-5 h-5',
  [SizeVariant.MD]: 'w-8 h-8',
  [SizeVariant.LG]: 'w-12 h-12',
  [SizeVariant.XL]: 'w-14 h-14',
};

const FileUploader = (props: FileUploaderProps) => {
  const { fileUploaderConfig } = props;

  if (!fileUploaderConfig) {
    return null;
  }

  const { acceptedExtension, isLoading, name, onChange } = fileUploaderConfig;

  let iconDisplay = <CameraIcon className="h-5 w-5 fill-primary-500" />;

  if (isLoading) {
    iconDisplay = (
      <LoadingSpinner size={SizeVariant.SM} className="fill-gray-400 text-primary-500" />
    );
  }

  return (
    <label
      className={twMerge(
        'absolute left-0 top-0 h-full w-full cursor-pointer',
        isLoading && 'cursor-progress',
      )}
    >
      <div className="absolute -right-2 bottom-0 flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-secondary-300">
        {iconDisplay}
      </div>
      <input
        className="hidden"
        disabled={isLoading}
        name={name}
        type="file"
        accept={acceptedExtension}
        onChange={onChange}
      />
    </label>
  );
};

const Avatar = (props: AvatarProps) => {
  const {
    backgroundColor = 'bg-lightGray',
    children,
    className,
    fileUploaderConfig,
    hasPadding = true,
    imageSrc,
    size = SizeVariant.MD,
  } = props;

  const [isValid, setIsValid] = useState<boolean>(false);

  const childrenDisplay = children || (
    <UserIcon className={twMerge('fill-neutral-400', iconSizeMapper[size])} />
  );

  useEffect(() => {
    if (imageSrc) {
      const checkImage = async () => {
        try {
          const response = await fetch(imageSrc as string);
          setIsValid(response.ok);
        } catch (error) {
          setIsValid(false);
        }
      };

      checkImage();
    }
  }, [imageSrc]);

  return (
    <div
      className={twMerge(
        'relative flex items-center justify-center rounded-full',
        hasPadding && ![SizeVariant.XS, SizeVariant.SM].includes(size) && 'p-4',
        sizeMapper[size],
        backgroundColor,
        className,
      )}
    >
      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden rounded-full">
        {isValid ? (
          <div
            className="h-full w-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url('${imageSrc}')` }}
          />
        ) : (
          childrenDisplay
        )}
      </div>
      <FileUploader fileUploaderConfig={fileUploaderConfig} />
    </div>
  );
};

export default Avatar;
