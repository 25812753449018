import Link from 'next/link';

import { Routes } from '../enums';

function SignupLink() {
  return (
    <Link href={Routes.REGISTER} className="text-sm font-medium text-primary3">
      Sign up
    </Link>
  );
}

export default SignupLink;
