import { useEffect, useState } from 'react';

import { motion } from 'framer-motion';

import { ErrorIcon, SuccessIcon, WarningIcon, InfoIcon, DismissIcon } from './Icons';
import { AlertType } from '../../types';

export default function Alert(props: AlertType) {
  const { type = 'success', message, title } = props;

  const [isVisible, setIsVisible] = useState(true);

  const color = {
    success: 'green',
    error: 'red',
    warning: 'yellow',
    info: 'blue',
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleDismiss = () => setIsVisible(false);

  return isVisible ? (
    <motion.div
      id="alert"
      className={`fixed right-2 top-2 rounded-md p-4 bg-${color[type]}-50 min-w-50 w-auto`}
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {type === 'success' && <SuccessIcon />}
          {type === 'error' && <ErrorIcon />}
          {type === 'warning' && <WarningIcon />}
          {type === 'info' && <InfoIcon />}
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color[type]}-800`}>{title}</h3>
          <div className={`mt-2 text-sm text-${color[type]}-700`}>
            <span>{message}</span>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex rounded-md bg-${color[type]}-50 p-1.5 text-${color[type]}-500 hover:bg-${color[type]}-100 focus:outline-none focus:ring-2 focus:ring-${color[type]}-600 focus:ring-offset-2 focus:ring-offset-${color[type]}-50`}
              onClick={handleDismiss}
            >
              <span className="sr-only">Dismiss</span>
              <DismissIcon />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}
